import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    }
  }));

type Props = {title: string, settlementProxyFeeList: SettlementProxyFee[] }
const SettlementProxyFeeTable: FC<Props> = ({ title, settlementProxyFeeList}) => {
const classes = useStyles();

const [rowsPerPage, setRowsPerPage] = useState(10);
const [page, setPage] = useState(0);

const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
  setPage(page);
};

const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setRowsPerPage(Number(event.target.value));
};

if(settlementProxyFeeList.length === 0) {
  return (
    <Card>
    { !!title &&
      <CardHeader title={title}/>
    }
    <Divider />
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>조회된 내용이 없습니다.</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Card>
  );
}

  return (
      <Card>
        { !!title &&
          <CardHeader title={title}/>
        }
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>NO</TableCell>
                    <TableCell align="center" rowSpan={2}>정산년월</TableCell>
                    <TableCell align="center" rowSpan={2}>청구년월</TableCell>
                    <TableCell align="center" rowSpan={2}>국내외구분코드</TableCell>
                    <TableCell align="center" rowSpan={2}>서비스수수료코드</TableCell>
                    <TableCell align="center" rowSpan={2}>서비스수수료명</TableCell>
                    <TableCell align="center" rowSpan={2}>단위항목코드</TableCell>
                    <TableCell align="center" rowSpan={2}>단위항목명</TableCell>
                    <TableCell align="center" rowSpan={2}>단가</TableCell>
                    <TableCell align="center" rowSpan={2}>요율(%)</TableCell>
                    <TableCell align="center" rowSpan={2}>자동대사여부</TableCell>
                    <TableCell align="center" colSpan={2}>정산건수</TableCell>
                    <TableCell align="center" colSpan={2}>정산금액</TableCell>
                    <TableCell align="center" colSpan={3}>차*</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">BC</TableCell> 
                    <TableCell align="center">CHAI</TableCell> 
                    <TableCell align="center">BC</TableCell>
                    <TableCell align="center">CHAI</TableCell> 
                    <TableCell align="center">비율(%)</TableCell>
                    <TableCell align="center">건수</TableCell> 
                    <TableCell align="center">금액</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settlementProxyFeeList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((settlementProxyFee,index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                      <TableCell align="center">{chaiStringUtils.yyyyMMFormatDefault(settlementProxyFee.feeSettlementYm)}</TableCell>
                      <TableCell align="center">{chaiStringUtils.yyyyMMFormatDefault(settlementProxyFee.feeClaimYm)}</TableCell>
                      <TableCell align="center">{chaiStringUtils.codeName('domesticCode',settlementProxyFee.domesticCode)}</TableCell>
                      <TableCell align="center">{settlementProxyFee.proxyFeeCodeNo}</TableCell>
                      <TableCell align="center">{settlementProxyFee.proxyFeeNm}</TableCell>
                      <TableCell align="center">{settlementProxyFee.unitFeeCodeNo === '000000' ? '소계' : settlementProxyFee.unitFeeCodeNo}
                      </TableCell>
                      <TableCell align="center">{settlementProxyFee.unitFeeName}</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementProxyFee.applyUnitprc)} 원</TableCell>
                      <TableCell align="right">{settlementProxyFee.feeBaseRate} %</TableCell>
                      <TableCell align="center">{settlementProxyFee.feeAutoYn}</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementProxyFee.bcResultCount)} 건</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementProxyFee.chaiResultCount)} 건</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementProxyFee.bcResultAmount)} 원</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementProxyFee.chaiResultAmount)} 원</TableCell>
                      <TableCell align="right">{settlementProxyFee.feeAutoYn === 'Y' ? 
                                                (((Number(settlementProxyFee.chaiResultAmount)/Number(settlementProxyFee.bcResultAmount))-1)*100) >= 0 ? 
                                                <span style={{color:'blue'}}>{(((Number(settlementProxyFee.chaiResultAmount)/Number(settlementProxyFee.bcResultAmount))-1)*100).toFixed(2).concat(' %')}</span>
                                                :<span style={{color:'red'}}>{(((Number(settlementProxyFee.chaiResultAmount)/Number(settlementProxyFee.bcResultAmount))-1)*100).toFixed(2).concat(' %')}</span> 
                                                :''}
                      </TableCell>
                      <TableCell align="right">{settlementProxyFee.feeAutoYn === 'Y' ? 
                                               (Number(settlementProxyFee.chaiResultCount)-Number(settlementProxyFee.bcResultCount)) >= 0 ?
                                               <span style={{color:'blue'}}>{chaiStringUtils.moneyFormat(Number(settlementProxyFee.chaiResultCount)-Number(settlementProxyFee.bcResultCount)).concat(' 건')}</span>
                                               :<span style={{color:'red'}}>{chaiStringUtils.moneyFormat(Number(settlementProxyFee.chaiResultCount)-Number(settlementProxyFee.bcResultCount)).concat(' 건')}</span>
                                               : ''}
                      </TableCell>
                      <TableCell align="right">{settlementProxyFee.feeAutoYn === 'Y' ? 
                                               (Number(settlementProxyFee.chaiResultAmount)-Number(settlementProxyFee.bcResultAmount)) >= 0 ? 
                                               <span style={{color:'blue'}}>{chaiStringUtils.moneyFormat(Number(settlementProxyFee.chaiResultAmount)-Number(settlementProxyFee.bcResultAmount)).concat(' 원')}</span>
                                               :<span style={{color:'red'}}>{chaiStringUtils.moneyFormat(Number(settlementProxyFee.chaiResultAmount)-Number(settlementProxyFee.bcResultAmount)).concat(' 원')}</span>
                                               : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>     
        <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={settlementProxyFeeList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        </CardActions>  
      </Card>
  );
}

export default SettlementProxyFeeTable;
import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField,
  makeStyles,
  Theme
} from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type SearchInfo = {
  startDt: string;
  endDt: string;
  searchGubun: string;
  searchVal: string;
  nonInoutStatusCode: string; // 미입출상태코드 1:
  inoutDivisionCode: string; // 입출금상태 1:입금 2:출금
};

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<SearchInfo>>;
  onClickRow: (nonInoutSeqInput: number) => Promise<void>;
};

const NonInoutInfoToolbar: FC<Props> = ({ onSearch, onClickRow }) => {

  //검색조건
  const [searchForm, setSearchForm] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'), //시작일자
    endDt: format(new Date(), 'yyyy-MM-dd'), //종료일자
    searchGubun: chaiStringUtils.getSchParamGubun(), // 조회구분
    nonInoutStatusCode: '', // 미입출상태코드 1:
    inoutDivisionCode: '', // 입출금상태 1:입금 2:출금
    searchVal: chaiStringUtils.getSchParamSearchVal(
      chaiStringUtils.getSchParamGubun()
    )
  });

  const classes = useStyles();

  //변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  //목록 조회
  const onSearchClick = async () => {
    onClickRow(0);
    onSearch(searchForm);
    chaiStringUtils.setSchParamGubun(searchForm.searchGubun);
    chaiStringUtils.setSchParamSearchVal(
      searchForm.searchGubun,
      searchForm.searchVal
    );
  };

  return (
    <Card>
      <form autoComplete="off" noValidate>
        <CardContent>
          <Table>
            <TableHead />
            <TableBody>
              <TableRow>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    required
                    fullWidth
                    type="date"
                    label="시작일자"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="startDt"
                    value={searchForm.startDt}
                    onChange={handleChange}
                    onKeyPress={onKeyEnter}
                  />
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    required
                    fullWidth
                    type="date"
                    label="종료일자"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="endDt"
                    value={searchForm.endDt}
                    onChange={handleChange}
                    onKeyPress={onKeyEnter}
                  />
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    select
                    fullWidth
                    label="조회구분"
                    margin="dense"
                    SelectProps={{
                      native: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="searchGubun"
                    value={searchForm.searchGubun}
                    onChange={handleChange}>
                    <option value="1">휴대폰 번호</option>
                    <option value="2">카드번호</option>
                    <option value="3">고객번호</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    fullWidth
                    label="조회 값"
                    placeholder="조회 값"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="searchVal"
                    value={searchForm.searchVal}
                    onChange={handleChange}
                    onKeyPress={onKeyEnter}
                  />
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    select
                    fullWidth
                    label="미입출처리상태"
                    margin="dense"
                    SelectProps={{
                      native: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="nonInoutStatusCode"
                    value={searchForm.nonInoutStatusCode}
                    onChange={handleChange}>
                    <option value="">구분</option>
                    <option value="1">전액미입출금</option>
                    <option value="2">부분미입출금</option>
                    <option value="3">전액입출금</option>
                    <option value="4">잡손실</option>
                    <option value="5">잡수익</option>
                    <option value="6">중단</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <TextField
                    select
                    fullWidth
                    label="미입출처리상태"
                    margin="dense"
                    SelectProps={{
                      native: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="inoutDivisionCode"
                    value={searchForm.inoutDivisionCode}
                    onChange={handleChange}>
                    <option value="">구분</option>
                    <option value="1">입금</option>
                    <option value="2">출금</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.toolbar_cell}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onSearchClick}
                    style={{
                      marginTop: '8px'
                    }}>
                    Search
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </form>
    </Card>
  );
};

export default NonInoutInfoToolbar;

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    fontFamily: 'sans-serif',
    fontSize: 14
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    borderBottom: '0px solid white',
    margin: '0 auto',
    textAlign: 'center'
  },
  table_up: {
    borderBottom: '0px solid white',
    width: '45%'
  },
  table_down: {
    borderBottom: '0px solid white',
    width: '90%'
  },
  toolbar_cell: {
    borderBottom: '0px solid white'
  }
}));

import React, { FC, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalHistoryLogMutation } from '../../lib/apolloClient/mutations';
import { chaiPaymentCancel, chaiPaymentStatus } from '../../lib/chaiAPI'
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold'
  }
}));

type Props = {
  refundReqInfo: ApprovalCompareRefundSearch,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  approvalCompareRefundOpen: boolean,
  setApprovalCompareRefundOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const ApprovalHistoryLogRefund: FC<Props> = ({ refundReqInfo, refundCount, setRefundCount, approvalCompareRefundOpen, setApprovalCompareRefundOpen }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  // const [loading, setLoading] = useState(false);
  const initRefundData = {
    tradeDate : '',
    tradeApprovalSeq : 0,
    cardSeq : 0,
    salesKindDivisionCode : '',
    approvalDate : '',
    cardApprovalNo : '',
    originApprovalCancelDivisionCode: '',
    originApprovalCancelDate : '',
    cardMemberNo : '',
    cardApprovalAmount : 0,
    salesAmount : 0,
    taxAmount : 0,
    serviceFee : 0,
    merchantNo : '',
    merchantName : '',
    approvalTime : '',
    approvalProcessStatusCode : '',
    cardApprovalResponseCode : '',
    accountProcessSeq : 0,
    cardNo : '',
    cardNoMasked: '',
    paymentAcno : '',
    accountProductCode : '',
    inoutDate : '',
    inoutProcessCode : '',
    inoutProcessSeq : '',
    paymentId : '',
    refundDate : '',
    refundProcessCode : '',
    refundProcessSeq : '',
    refundPaymentId : '',
    memberinfo : {
      userKorName: '',
      phoneNo1: '',
      phoneNo2: '',
      phoneNo3: ''
    },
    cardinfo : {
      cardNo: '',
      cardProductCode: ''
    },
    cancelinfo : {
      tradeDate: '',
      tradeApprovalSeq: 0,
      approvalDate: '',
      cardApprovalNo: '',
      cardApprovalAmount: 0,
      approvalProcessStatusCode: '',
      cardApprovalResponseCode: '',
      accountProcessSeq: 0,
      nonInoutRequestYn : '',
      nonInoutRegisteredDate : '',
      nonInoutSeq : 0,
      inoutDate : '',
      inoutProcessCode : '',
      inoutProcessSeq : '',
      paymentId : '',      
      refundDate : '',
      refundProcessCode : '',
      refundProcessSeq : '',
      refundPaymentId : ''   
    },
    productinfo : {
      cardProductCodeName : ''
    },
    chaimoneyinfo : {
      tradeDate : '',
      idempotencyKey: '',
      paymentId : '',
      paymentAmount : 0
    },
    chaimoneycnclinfo : {
      tradeDate : '',
      idempotencyKey: '',
      paymentId : '',
      paymentAmount : 0
    }
  };

  const [refundData, setRefundData] = useState(initRefundData);
  const [isApprovalExist, setIsApprovalExist] = useState(false);

  const { showDialog, hideDialog } = useDialog();

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);
      // 승인기준의 데이타를 가져온다.
      await apolloClient.mutate({
        variables: {input : {
          cardNo : refundReqInfo.cardNo, 
          approvalDate : refundReqInfo.approvalDate,
          cardApprovalNo :  refundReqInfo.cardApprovalNo}
        },
        mutation: approvalHistoryLogMutation.selectApprovalCompareRefundInfo
      }).then(res => {  
        console.log('result=',res.data.selectApprovalCompareRefundInfo);
        //setDetailData(res.data.selectApproval);
        const result = res.data.selectApprovalCompareRefundInfo;
        if (result) {
          setIsApprovalExist(true);
          setRefundData({
            ...refundData,
            ...result
          });
        }
        else {
          setIsApprovalExist(false);
          setRefundData({
            ...refundData,
            ...initRefundData
          });
        }
      }).catch(e => {
        console.log('error', e);
        showToast(e.message, 'error');
      });
      // setLoading(false);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundReqInfo]);


  const isDisabled = () => {

    console.log('refundData=', refundData);
    let isRefund = true;

    if (!isApprovalExist) {
      isRefund = false;
    }
    else {
      if (!refundData.cardinfo) {
        isRefund = false;
      }
      else if (!refundData.memberinfo) {
        isRefund = false;
      }
      else if (!refundData.cancelinfo) {
        // 승인오류이고 차이머니거래가 존재하는 경우 환불가능
        if (refundData.approvalProcessStatusCode !== '1' && refundData.chaimoneyinfo) {
          isRefund = true;
        }
        else 
          isRefund = false;
      }
      else {
        // 승인취소 정상처리된 경우에는 환불버튼 disabled
        /***
        if (refundData.cancelinfo.approvalProcessStatusCode === '1' ||
          refundData.cancelinfo.cardApprovalResponseCode === '02') {
          isRefund = false;
        }
        ***/
        // 승인오류이고 차이머니차감내역이 존재하고 승인취소오류면 환불가능
        if (refundData.approvalProcessStatusCode !== '1' && refundData.chaimoneyinfo && refundData.cancelinfo.approvalProcessStatusCode !== '1') {
          isRefund = true;
        }
        // 승인정상이고 승인취소오류이면 환불가능
        else if (refundData.approvalProcessStatusCode === '1' && refundData.cancelinfo.approvalProcessStatusCode !== '1') {
          isRefund = true;
        }
        else {
          isRefund = false;
        }
      }
      // 이미 환불처리되었으면 환불버튼 disabled
      if (refundData.refundProcessCode === '1' || (refundData.cancelinfo && refundData.cancelinfo.refundProcessCode === '1')) {
        isRefund = false;
      }
    }
    return isRefund;
  };

  const displayProcessStatus = (processStatus:string) => {
    
    let displayStatus = '';
    if (processStatus === '1') displayStatus = '정상';
    else if (processStatus === '2') displayStatus = '오류';
    else displayStatus = '처리중';

    return displayStatus;
  };


  const displayInoutProcessCode = (inoutProcessCode:string) => {
    
    let displayProcessCode = '';
    if (inoutProcessCode === '0') displayProcessCode = '입출금요청';
    else if (inoutProcessCode === '1') displayProcessCode = '입출금성공';
    else if (inoutProcessCode === '2') displayProcessCode = '입출금실패';
    else displayProcessCode = '';

    return displayProcessCode;
  };
  
  const handleApprovalCompareRefundClose = () => {
    setApprovalCompareRefundOpen(false);
  };


  const handleConfirmRefund = async () => {

    try {
      const refundReq = {} as ChaiMoneyRefundReq;
      refundReq.paymentId = refundData.paymentId ? refundData.paymentId : refundData.chaimoneyinfo.paymentId;
      refundReq.idempotencyKey = refundData.inoutProcessSeq ? refundData.inoutProcessSeq : 'AP'+ refundData.tradeDate + refundData.tradeApprovalSeq.toString().padStart(16, '0');
      refundReq.checkoutAmount =  refundData.cardApprovalAmount;
      refundReq.cancelAmount = refundData.cancelinfo ? refundData.cancelinfo.cardApprovalAmount : refundData.cardApprovalAmount;
      refundReq.merchantNo = refundData.merchantNo; // 가맹점정보는 원승인의 정보를 이용
      refundReq.merchantName = refundData.merchantName;
      /**
      refundReq.groupMerchantIds  = refundData.merchantinfo.map(function(groupMerchant) {
          return groupMerchant['groupMerchantNo'];
      });
      **/

      console.log('refundReq=', refundReq);
      // 차이머니 결제상태 조회
      const paymentStatus = await chaiPaymentStatus(refundReq);
      console.log('status=', paymentStatus);
      let paymentId = refundReq.paymentId;

      // 차이머니 결제상태가 canceled가 아닐때 취소API 호출
      if (paymentStatus.status !== 'canceled') {
        // 차이머니 환불 호출
        const rslt = await chaiPaymentCancel(refundReq);    
        paymentId = rslt.paymentId;
      }
      // 승인오류가 발생했을때는 승인에 대해서 환불처리한다. 승인이 오류가 발생하면 승인취소도 없으니까....
      // 승인오류시 차이머니거래로그가 존재할때만 환불처리된다.
      let reqSalesKindDivisionCode;
      if (refundData.approvalProcessStatusCode !== '1' && refundData.chaimoneyinfo) {
        reqSalesKindDivisionCode = '05';
      }
      else {
        reqSalesKindDivisionCode = '15';
      }

      await apolloClient.mutate({
        variables: {input : {
          tradeDate: reqSalesKindDivisionCode === '05' ? refundData.tradeDate : refundData.cancelinfo.tradeDate, 
          tradeApprovalSeq: reqSalesKindDivisionCode === '05' ? refundData.tradeApprovalSeq : refundData.cancelinfo.tradeApprovalSeq,
          salesKindDivisionCode: reqSalesKindDivisionCode,
          orgnlTradeDate : refundData.tradeDate,
          orgnlTradeApprovalSeq : refundData.tradeApprovalSeq,
          cardNo: refundData.cardNo,
          paymentAcno: refundData.paymentAcno,
          cardApprovalAmount: refundData.cardApprovalAmount,
          merchantName:refundData.merchantName,
          refundProcessSeq: refundReq.idempotencyKey, 
          refundPaymentId: paymentId,
          accountProcessSeq: reqSalesKindDivisionCode === '05' ? refundData.accountProcessSeq : refundData.cancelinfo.accountProcessSeq} },
        mutation: approvalHistoryLogMutation.updateApprovalCompare
      }).then(res => {     
        console.log(res.data);
        setRefundCount(refundCount+1);
        hideDialog();
        showDialog(
          '승인대사',
          '입금환불처리되었습니다.\n\n',
          () => {
            // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
            hideDialog();
          }
        )   
    
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });

    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setApprovalCompareRefundOpen(false);
}


  return (
    <Dialog
      open={approvalCompareRefundOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title"><b>승인대사결과 상세조회 및 고객환불 입금처리</b></DialogTitle>
      <Divider />
      <DialogContent>
        <div>
            <Chip label="카드승인내역" color="primary"  size="small"  className={classes.chipStyle}/>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundReqInfo.approvalDate)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>원거래승인번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundReqInfo.cardApprovalNo}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="카드기본정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>고객번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cardMemberNo}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>고객명</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.memberinfo && refundData.memberinfo.userKorName}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>휴대폰번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.memberinfo && refundData.memberinfo.phoneNo1+'-'+refundData.memberinfo.phoneNo2+'-'+refundData.memberinfo.phoneNo3}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>카드번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cardinfo && refundData.cardNoMasked}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>상품명</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.productinfo && refundData.productinfo.cardProductCodeName}</TableCell>
                  <TableCell align="center" className={classes.thStyle}></TableCell>
                  <TableCell align="center" className={classes.tdStyle}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="거래정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인시각</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateTimeFormat(refundData.approvalTime)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인합계</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.cardApprovalAmount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인금액</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.salesAmount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}></TableCell>
                  <TableCell align="center" className={classes.tdStyle}></TableCell>                  
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>부가세</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.taxAmount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>봉사료</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.serviceFee)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>처리상태</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayProcessStatus(refundData.approvalProcessStatusCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>응답코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cardApprovalResponseCode}</TableCell>                  
                </TableRow> 
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>입출금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundData.inoutDate)}</TableCell>    
                  <TableCell align="center" className={classes.thStyle}>입출금처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(refundData.inoutProcessCode)}</TableCell>  
                  <TableCell align="center" className={classes.thStyle}>입금처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.inoutProcessSeq}</TableCell>                                                
                  <TableCell align="center" className={classes.thStyle}>입출금결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.paymentId}</TableCell>
                </TableRow>  
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>환불입금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundData.refundDate)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(refundData.refundProcessCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.refundProcessSeq}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.refundPaymentId} 
                  </TableCell>                  
                </TableRow>                                                
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="취소정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>취소일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.tradeDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>원거래승인일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.approvalDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>원거래승인번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? refundData.cancelinfo.cardApprovalNo : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}> 처리상태</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? displayProcessStatus(refundData.cancelinfo.approvalProcessStatusCode) : ''}</TableCell>                    
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>응답코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? refundData.cancelinfo.cardApprovalResponseCode : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>미입출처리요청여부</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? (refundData.cancelinfo.nonInoutRequestYn === 'Y' ? '미입출처리요청' : '') : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>미입출등록일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.nonInoutRegisteredDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>미입출처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? refundData.cancelinfo.nonInoutSeq : ''} 
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>입출금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.inoutDate) : ''}</TableCell>    
                  <TableCell align="center" className={classes.thStyle}>입출금처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? displayInoutProcessCode(refundData.cancelinfo.inoutProcessCode) : ''}</TableCell>  
                  <TableCell align="center" className={classes.thStyle}>입금처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.inoutProcessSeq : ''}</TableCell>                                                
                  <TableCell align="center" className={classes.thStyle}>입출금결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.paymentId : ''}</TableCell>
                </TableRow>                    
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>환불입금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.refundDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? displayInoutProcessCode(refundData.cancelinfo.refundProcessCode) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? refundData.cancelinfo.refundProcessSeq : ''} 
                  </TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    {refundData.cancelinfo ? refundData.cancelinfo.refundPaymentId : ''} 
                  </TableCell>                  
                </TableRow>                                    
              </TableBody>
            </Table>
        </div> 
        <br/>
        <div>
            <Chip label="차이머니거래내역" color="primary"  size="small"  className={classes.chipStyle}/>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? chaiStringUtils.dateFormatDefault(refundData.chaimoneyinfo.tradeDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>idempotencyKey</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? refundData.chaimoneyinfo.idempotencyKey : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>paymentId</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? refundData.chaimoneyinfo.paymentId : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>처리금액</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? chaiStringUtils.moneyFormat(refundData.chaimoneyinfo.paymentAmount) : ''}</TableCell>                  
                </TableRow>
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="차이머니취소거래내역" color="primary"  size="small"  className={classes.chipStyle}/>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycnclinfo ? chaiStringUtils.dateFormatDefault(refundData.chaimoneycnclinfo.tradeDate) : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>idempotencyKey</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycnclinfo ? refundData.chaimoneycnclinfo.idempotencyKey : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>paymentId</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycnclinfo ? refundData.chaimoneycnclinfo.paymentId : ''}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>처리금액</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycnclinfo ? chaiStringUtils.moneyFormat(refundData.chaimoneycnclinfo.paymentAmount) : ''}</TableCell>                  
                </TableRow>
              </TableBody>
            </Table>
        </div>                    
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
            disabled={!isDisabled()}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '승인대사',
              '입금처리 하시겠습니까?',
              handleConfirmRefund
            )}
          >
            입금처리 
        </Button>
        <Button onClick={handleApprovalCompareRefundClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalHistoryLogRefund;

import axios from "axios";
let CARD_API_HOST = `https://${process.env.REACT_APP_CARD_API_HOST}`;

if(process.env.REACT_APP_CARD_API_HOST === undefined){
  CARD_API_HOST = 'https://local.chai.finance:3030/';
}

const client = axios.create({  
  baseURL: CARD_API_HOST,  
  responseType: "json"
});

export default client;
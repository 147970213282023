import React, { FC } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
  } from '@material-ui/core';

type SearchInfo = {
    searchType        :string; // 검색조건
    searchValue       :string; // 검색값
    searchDateGubun   :string; // 조회일자구분 (매출일자, 청구일자, 승인일자)
    searchDate        :string; // 검색일자 
} 

type Props = {
    onSearch: React.Dispatch<React.SetStateAction<any>>,
    searchForm: SearchInfo,
    setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}

const OvrsUseFeeHistoryToolBar : FC<Props> = ({onSearch, searchForm, setSearchForm}) => {

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name] : e.target.value
        });
    };

    // 엔터 이벤트 처리
    const onKeyEnter = async (event : React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter") {
            onSearchClick();
        }
    };

    // 목록 조회
    const onSearchClick =async() => {
        onSearch(searchForm);
    };


    return (
        <Card>
            <form
                autoComplete="off"
                noValidate
            >
            <CardContent>
            <Grid
                container
                spacing={1}
            >
                <Grid
                item
                md={2}
                xs={6}
                >
                <TextField
                    select
                    fullWidth
                    label="검색조건"
                    margin="dense"
                    SelectProps={{
                    native: true,
                    }}
                    InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                name="searchType"
                value={searchForm.searchType}
                onChange={handleChange}
                >
                <option value="1">고객번호</option>
                <option value="2">카드번호</option>
                <option value="3">카드일렬번호</option>
                </TextField>
                </Grid>
                <Grid
                item
                md={2}
                xs={6}
                >
                <TextField
                    fullWidth
                    label="검색값"
                    placeholder="검색값"
                    margin="dense"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    variant="outlined"
                    name="searchValue"
                    value={searchForm.searchValue}
                    onChange={handleChange}
                    onKeyPress={onKeyEnter}
                />
                </Grid>
                <Grid
                item
                md={2}
                xs={6}
                >
                <TextField
                    select
                    fullWidth
                    label="조회일자구분"
                    margin="dense"
                    SelectProps={{
                    native: true,
                    }}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    variant="outlined"
                    name="searchDateGubun"
                    value={searchForm.searchDateGubun}
                    onChange={handleChange}
                >
                    <option value="1">매출일자</option>
                    <option value="2">청구일자</option>
                    <option value="3">승인일자</option>
                </TextField>
                </Grid>
                <Grid
                item
                md={2}
                xs={6}
                >
                <TextField
                    required
                    fullWidth
                    type="date"
                    label="조회일자"
                    margin="dense"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    variant="outlined"
                    name="searchDate"
                    value={searchForm.searchDate}
                    onChange={handleChange}
                    onKeyPress={onKeyEnter}
                />
                </Grid>
                <Grid
                item
                md={2}
                xs={6}
                >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onSearchClick}
                    style = {{
                    marginTop: '8px'
                    }}
                >
                    Search 
                </Button>
                </Grid>
            </Grid>
            </CardContent>
            </form>
        </Card>
    )

}

export default OvrsUseFeeHistoryToolBar;



import React, { FC, ChangeEvent, useState } from 'react';
import clsx from 'clsx';

import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  CardActions,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

import apolloClient, { cardBinMutation } from '../../lib/apolloClient';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  position: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    float: 'right',
  },
}));

type Props = {
  className?: string;
  cardBinNo: CardBinNo;
  updateClick: boolean;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  setCardBinNo: React.Dispatch<React.SetStateAction<CardBinNo>>;
};

const CardBinDetails: FC<Props> = ({ className, cardBinNo, updateClick, setUpdateClick, setCardBinNo }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();
  // eslint-disable-next-line
  const [isEmptyForm, setIsEmptyForm] = useState(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCardBinNo({
      ...cardBinNo,
      [e.target.name]: e.target.value,
    });
  };
  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    if ((isEmpty(cardBinNo.binNo) || isEmpty(cardBinNo.cardProductCode) || isEmpty(cardBinNo.useYn)) === false) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  };

  const checkFormEmpty = () => {
    if (
      (isEmpty(cardBinNo.binNo) &&
        isEmpty(cardBinNo.cardProductCode) &&
        isEmpty(cardBinNo.brandDivisionCode) &&
        isEmpty(cardBinNo.startCardMediaSeq) &&
        isEmpty(cardBinNo.endCardMediaSeq) &&
        isEmpty(cardBinNo.nowCardMediaSeq) &&
        isEmpty(cardBinNo.useYn) &&
        isEmpty(cardBinNo.endDate)) === true
    ) {
      setIsEmptyForm(true);
    } else {
      setIsEmptyForm(false);
    }
  };

  const handleConfirmAddConfirm = async () => {
    //등록
    if (cardBinNo.cardProductCode === null || cardBinNo.cardProductCode === '') {
      showToast('카드상품코드를 입력하세요.');
      hideDialog();
      return;
    } else if (cardBinNo.binNo === null || cardBinNo.binNo === '') {
      showToast('bin번호를 입력하세요.');
      hideDialog();
      return;
    } else if (brandDivisionCode === null || cardBinNo.brandDivisionCode === '') {
      showToast('브랜드가 선택되지 않았습니다.');
      hideDialog();
      return;
    }
    let check = false;
    await apolloClient
      .mutate({
        variables: {
          input: {
            binNo: cardBinNo.binNo,
            cardProductCode: cardBinNo.cardProductCode,
            brandDivisionCode: cardBinNo.brandDivisionCode,
            startCardMediaSeq: cardBinNo.startCardMediaSeq,
            endCardMediaSeq: cardBinNo.endCardMediaSeq,
            nowCardMediaSeq: cardBinNo.nowCardMediaSeq,
            useYn: cardBinNo.useYn,
            endDate: cardBinNo.endDate,
          },
        },
        mutation: cardBinMutation.insertCardBinNo,
      })
      .then(res => {
        console.log(res);

        if (res.data.insertCardBinNo.useYn === '검증실패') {
          check = true;
        }
        if (check) {
          hideDialog();
          showDialog('BIN정보', '시작일련번호가 기존 종료일련번호보다 작을수 없습니다.\n\n', () => {
            hideDialog();
          });
          return;
        } else {
          hideDialog();
          showDialog('공통코드정보', '저장되었습니다.\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'Error');
      });
    setCardBinNo({
      binNo: '',
      cardProductCode: '',
      brandDivisionCode: '',
      startCardMediaSeq: '',
      endCardMediaSeq: '',
      nowCardMediaSeq: '',
      useYn: '',
      endDate: '',
      enrollmentSeq: 0,
    });
    check = false;
    setUpdateClick(!updateClick);
    setIsValidForm(false);
  };

  const handleConfirmUpdConfirm = async () => {
    //수정
    console.log('###### Update Product Code');

    if (brandDivisionCode === null || cardBinNo.brandDivisionCode === '') {
      showToast('브랜드가 선택되지 않았습니다.');
      hideDialog();
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          input: {
            binNo: cardBinNo.binNo,
            cardProductCode: cardBinNo.cardProductCode,
            brandDivisionCode: cardBinNo.brandDivisionCode,
            startCardMediaSeq: cardBinNo.startCardMediaSeq,
            endCardMediaSeq: cardBinNo.endCardMediaSeq,
            nowCardMediaSeq: cardBinNo.nowCardMediaSeq,
            useYn: cardBinNo.useYn,
            endDate: cardBinNo.endDate,
            enrollmentSeq: cardBinNo.enrollmentSeq,
          },
        },
        mutation: cardBinMutation.updateCardBinNo,
      })
      .then(res => {
        setCardBinNo({
          binNo: '',
          cardProductCode: '',
          brandDivisionCode: '',
          startCardMediaSeq: '',
          endCardMediaSeq: '',
          nowCardMediaSeq: '',
          useYn: '',
          endDate: '',
          enrollmentSeq: 0,
        });
        if (res.data.updateCardBinNo.cardProductCode === 'now') {
          hideDialog();
          showDialog('종료일자', '종료일자가 지났습니다.\n\n', () => {
            hideDialog();
          });
          return;
        } else {
          hideDialog();
          showDialog('BIN정보', '수정되었습니다\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.messgae, 'Error');
      });
    setUpdateClick(!updateClick);
    setIsValidForm(false);
  };

  const isEmpty = (input: string) => {
    return input === '';
  };

  const initTextFields = () => {
    setCardBinNo({
      ...cardBinNo,
      binNo: '',
      cardProductCode: '',
      brandDivisionCode: '',
      startCardMediaSeq: '',
      endCardMediaSeq: '',
      nowCardMediaSeq: '',
      useYn: '',
      endDate: '',
    });
    setIsEmptyForm(false);
    setUpdateClick(!updateClick);
  };
  const brandDivisionCode = [
    { value: '', label: '선택' },
    { value: '0', label: 'GLOBAL BC' },
    { value: '1', label: 'LOCAL' },
    { value: '2', label: 'MC' },
    { value: '3', label: 'VISA' },
    { value: '4', label: 'JCB' },
  ];

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="<BIN정보>" />
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell>BIN번호</TableCell>
            <TableCell>
              <TextField
                label="BIN번호"
                margin="dense"
                name="binNo"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                required
                value={cardBinNo.binNo}
                error={isEmpty(cardBinNo.binNo)}
                helperText={isEmpty(cardBinNo.binNo) ? 'Required' : ''}
              />
            </TableCell>
            <TableCell>카드상품코드</TableCell>
            <TableCell>
              <TextField
                label="카드상품코드"
                margin="dense"
                name="cardProductCode"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                required
                value={cardBinNo.cardProductCode}
                error={isEmpty(cardBinNo.cardProductCode)}
                helperText={isEmpty(cardBinNo.cardProductCode) ? 'Required' : ''}
              />
            </TableCell>
            <TableCell>종료일자</TableCell>
            <TableCell>
              <TextField
                label="종료일자"
                margin="dense"
                name="endDate"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={cardBinNo.endDate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>시작일련번호</TableCell>
            <TableCell>
              <TextField
                label="시작일련번호"
                margin="dense"
                name="startCardMediaSeq"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={cardBinNo.startCardMediaSeq}
              />
            </TableCell>
            <TableCell>종료일련번호</TableCell>
            <TableCell>
              <TextField
                label="종료일련번호"
                margin="dense"
                name="endCardMediaSeq"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={cardBinNo.endCardMediaSeq}
              />
            </TableCell>
            <TableCell>현재일련번호</TableCell>
            <TableCell>
              <TextField
                label="현재일련번호"
                margin="dense"
                name="nowCardMediaSeq"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={cardBinNo.nowCardMediaSeq}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>브랜드</TableCell>
            <TableCell>
              <TextField
                label="브랜드"
                margin="dense"
                name="brandDivisionCode"
                variant="outlined"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={cardBinNo.brandDivisionCode}
                select
                SelectProps={{ native: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {brandDivisionCode.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </TableCell>
            <TableCell>사용여부</TableCell>
            <TableCell>
              <RadioGroup
                name="useYn"
                value={cardBinNo.useYn}
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
              >
                <FormControlLabel value="Y" control={<Radio />} label="Y" />
                <FormControlLabel value="N" control={<Radio />} label="N" />
              </RadioGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button //신규
          color="primary"
          variant="contained"
          onClick={() => showDialog('BIN정보', '해당 정보로 저장하시겠습니까?', handleConfirmAddConfirm)}
        >
          ADD
        </Button>

        <Button //수정
          color="primary"
          variant="contained"
          onClick={() => showDialog('BIN정보', '해당 정보로 수정하시겠습니까?', handleConfirmUpdConfirm)}
          disabled={!isValidForm}
        >
          EDIT
        </Button>

        <Button //초기화
          color="primary"
          variant="contained"
          onClick={initTextFields}
        >
          RESET
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardBinDetails;

import gql from 'graphql-tag';

export const selectOverseasPurchasedAggrHistoryAdjustProcessList = gql`
  mutation selectOverseasPurchasedAggrHistoryAdjustProcessList (
    $startReceve_base_date : String!,
    $endReceve_base_date : String!,
    $page: Float, 
    $rowsPerPage: Float
  ) {
    selectOverseasPurchasedAggrHistoryAdjustProcessList (
      startReceve_base_date : $startReceve_base_date,
      endReceve_base_date : $endReceve_base_date,
      page : $page, 
      rowsPerPage : $rowsPerPage
    ) {
      totalCount
      list {
        merchant_eng_name
        overseas_cdsl_usd_amount
        overseas_cdsl_fee_usd_amount
        overseas_cdsl_fee_krw_amount
        overseas_cash_usd_amount
        overseas_cash_fee_usd_amount
        overseas_settlement_usd_amount
        overseas_settlement_krw_amount
        purchased_usd_origin_amount
        approval_request_usd_origin_amount
        overseas_use_fee_base_usd_amount
        approval_apply_exchange_rate
        purchased_apply_exchange_rate
        deal_base_exchange_rate
        bill_date
        reversal_yn
        overseas_trade_krw_amount
        local_trade_currency_code
        overseas_bc_purchased_seq
        onus_division_code
        revolving_division_code
        revolving_no
        local_trade_amount
        merchant_city_name
        mcc_merchant_code
        approval_date
        overseas_card_approval_no
        trade_approval_seq
        trade_date
        overseas_use_krw_fee
        overseas_atm_trade_krw_fee
        check_sales_division_code
        government_purchase_card_division_code
        text_brand_usd_fee
        purchased_brand_usd_fee
        purchased_brand_krw_fee
        purchased_overseas_use_usd_fee
        purchased_overseas_use_krw_fee
        purchased_overseas_withdrawal_usd_fee
        purchased_overseas_withdrawal_krw_fee
        approval_brand_usd_fee
        approval_brand_krw_fee
        approval_overseas_use_usd_fee
        approval_overseas_use_krw_fee
        approval_overseas_withdrawal_usd_fee
        approval_overseas_withdrawal_krw_fee
        approval_request_withdrawal_usd_amount
        approval_real_withdrawal_krw_amount
        origin_receve_no
        no_approval_purchased_division_code
        purchased_process_division_code
        forced_purchased_yn
        origin_approval_ledger_exist_yn
        local_diff_amount
        purchased_usd_amount
        purchased_krw_amount
        withdrawal_usd_diff_amount
        withdrawal_krw_diff_amount
        account_department_identifier
        inout_process_code
        inout_usd_amount
        inout_krw_amount
        inout_process_seq
        data_process_division_code
        overseas_purchased_error_reason 
        exchange_rate_pfls_division_code
        purchased_exchange_rate_diff_amount
        trade_process_code
        adjust_process_status_division_code
        adjust_process_reason
        last_operate_trade_code
        last_operate_admin_id
        receve_seq
        receve_base_date
      }
    }
  }
`;

export const selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo = gql`
  mutation selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo (
    $receve_seq : String,
    $merchant_eng_name : String,
    $receve_base_date : String
  ) {
    selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo(
      receve_seq : $receve_seq,
      merchant_eng_name : $merchant_eng_name,
      receve_base_date : $receve_base_date
    ) {
      adjustmentPurchaseDetails
      {
        purchased_process_division_code
        adjust_process_reason
        data_process_division_code
        adjust_process_status_division_code
        trade_process_code
        last_operate_admin_id
        last_operate_trade_code
        card_no
        card_no_masked
        local_trade_currency_code
        local_trade_amount
        sales_date
        merchant_nation_code
        merchant_eng_name
        merchant_city_name
        account_division_code
        sales_kind_division_code
        purchased_process_no
        bc_sales_division_code
        approval_date
        overseas_card_approval_no
        trade_approval_seq
        trade_date
        overseas_cdsl_usd_amount 
        overseas_cdsl_fee_usd_amount
        overseas_cdsl_fee_krw_amount 
        overseas_settlement_usd_amount
        overseas_cash_usd_amount
        overseas_cash_fee_usd_amount 
        overseas_cash_fee_krw_amount
        overseas_settlement_krw_amount
        purchased_apply_exchange_rate 
        deal_base_exchange_rate
        purchased_usd_origin_amount
        approval_request_usd_origin_amount
        overseas_use_fee_base_usd_amount
      }
      approvalHistoryInformations
      {
        trade_approval_seq
        trade_date
        card_seq
        sales_kind_division_code
        approval_date
        overseas_card_approval_no
        card_no
        card_no_masked
        overseas_approval_use_no
        mcc_merchant_code
        issuer_currency_code
        payment_currency_code
        local_trade_amount
        bc_nation_no
        approval_city_name
        merchant_eng_name
        brand_division_code
        text_approval_request_usd_amount
        brand_fee_calcu_division_code
        origin_approval_cancel_division_code
        approval_cancel_date
        approval_request_usd_amount
        approval_request_usd_origin_amount
        overseas_use_fee_impose_base_amount
        overseas_brand_usd_fee
        overseas_brand_krw_fee
        overseas_use_usd_fee
        overseas_use_krw_fee
        overseas_withdrawal_usd_fee
        overseas_withdrawal_krw_fee
        request_withdrawal_usd_amount
        real_withdrawal_krw_amount
        approval_apply_exchange_rate
        account_process_seq
      }
    }
  }
`;
import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<string>>
}

const PrecedenceBatchInfoListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {  

  const [batchName, setBatchName] = useState('');


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setBatchName(event.target.value);
  };

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onSearch(batchName);
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }
  
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={4}
            xs={10}
          >
            <TextField
              fullWidth
              label="배치명"
              placeholder="배치명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="batchName"
              value={batchName}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
        
        <Grid
          container
          spacing={1}
        >
        </Grid>
      </CardContent>
    </Card>   
  );
};

export default PrecedenceBatchInfoListToolbar;

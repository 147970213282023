import { Button, makeStyles } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import apolloClient from '../../lib/apolloClient';
import { overseasPurchasedHistoryMutation } from '../../lib/apolloClient/mutations';
import OverseasPurchasedHistoryDetail from './OverseasPurchasedHistoryDetail';
import OverseasPurchasedHistoryTable from './OverseasPurchasedHistoryTable';
import OverseasPurchasedHistoryToolBar from './OverseasPurchasedHistoryToolBar';


const useStyles = makeStyles((theme: any) => ({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    detail: {
      marginBottom: theme.spacing(3)
    }
}));


type Props = {
  ovrsPurchasedHistoryDetail : ovrsPurchasedHistorySumInfo
}

const OverseasPurchasedHistory : FC<Props> = ({ovrsPurchasedHistoryDetail}) => {

  const classes = useStyles();
  
  const [searchForm, setSearchForm] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    dateDivisionCode: '1',
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),
    salesKindDivCode : '',
    accountDivCode : '',
    purchasedProcNo : '',
    accountProcSeq : ''
  });

  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState(ovrsPurchasedHistoryDetail);
  const [isExcelDown, setExcelDown] = useState(false);

  useEffect(() => {
    console.log('# selectOvrsPurchasedHistorySumInfo 실행 ');
    const getDetail = async() => {
      await apolloClient.mutate({
        mutation : overseasPurchasedHistoryMutation.selectOvrsPurchasedHistorySumInfo,
        variables : {input : {
          searchType : searchForm.searchType,
          searchValue : searchForm.searchValue,
          dateDivisionCode : searchForm.dateDivisionCode,
          startDt : searchForm.startDt.replace(/-/g, ''), 
          endDt : searchForm.endDt.replace(/-/g, ''),
          salesKindDivCode : searchForm.salesKindDivCode,
          accountDivCode : searchForm.accountDivCode,
          purchasedProcNo : searchForm.purchasedProcNo,
          accountProcSeq : searchForm.accountProcSeq
        }
      }
      }).then (res => {
        setDetailData(res.data.selectOvrsPurchasedHistorySumInfo)
        console.log('@@ selectOvrsPurchasedHistorySumInfo.Mut data : ', res.data.selectOvrsPurchasedHistorySumInfo);
      }).catch(console.error);
    }
    getDetail()
  }, [searchForm])

  useEffect(() => {
    console.log('# selectOvrsPurchasedHistoryList 실행 ');
    const getList = async() => {
      await apolloClient.mutate({
        mutation : overseasPurchasedHistoryMutation.selectOvrsPurchasedHistoryList,
        variables : {input : {
          searchType : searchForm.searchType,
          searchValue : searchForm.searchValue,
          dateDivisionCode : searchForm.dateDivisionCode,
          startDt : searchForm.startDt.replace(/-/g, ''), 
          endDt : searchForm.endDt.replace(/-/g, ''),
          salesKindDivCode : searchForm.salesKindDivCode,
          accountDivCode : searchForm.accountDivCode,
          purchasedProcNo : searchForm.purchasedProcNo,
          accountProcSeq : searchForm.accountProcSeq
        }
      }
      }).then (res => {
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectOvrsPurchasedHistoryList);
        } else {
          setData(res.data.selectOvrsPurchasedHistoryList);
          console.log('@@ selectOvrsPurchasedHistoryList.Mut data : ', res.data.selectOvrsPurchasedHistoryList);
        }
      }).catch(console.error);
    }
    getList()
  }, [searchForm, isExcelDown]);

  const excelClick = () => {
    setExcelDown(true);
  }
  
  const excelDown = (excelData : ovrsPurchasedHistoryList[]) => {

    console.log('@ excelDown >> ');
    console.log('@ get data :  >> ', excelData[0].overseasProcessDate);
    let rowData = [];
    for(let i=0; i<excelData.length; i++){
      rowData.push({
        '처리일자' : `${excelData[i].overseasProcessDate}`,
        '카드계정구분' : `${chaiStringUtils.codeName('accountDivision', excelData[i].accountDivisionCode)}`,
        '매출종류구분' : `${chaiStringUtils.codeName('salesKindDivisionCode', excelData[i].salesKindDivisionCode)}`,
        '카드번호' : `${excelData[i].cardNoMasked}`,
        '매입접수번호' : `${excelData[i].purchasedProcessNo}`,
        '가맹점영문명' : `${excelData[i].merchantEngName}`,
        '해외신판미화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCdslUsdAmount)}`,
        '해외신판수수료미화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCdslFeeUsdAmount)}`,
        '해외신판수수료원화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCdslFeeKrwAmount)}`,
        '해외현금미화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCashUsdAmount)}`,
        '해외현금수수료미화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCashFeeUsdAmount)}`,
        '해외현금수수료원화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasCashFeeKrwAmount)}`,
        '정산미화금액' : `${chaiStringUtils.moneyFormat(excelData[i].overseasSettlementUsdAmount)}`,
        '거래유형' : `${excelData[i].tradeProcessCode}`
      });
    }
    utils.exportToCSV("해외매입내역", rowData);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외매입내역조회" />
      <OverseasPurchasedHistoryToolBar  onSearch={setSearchForm}/>
      <div className={classes.content}>
        <OverseasPurchasedHistoryDetail className={classes.detail} detailInfo={detailData}/>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <OverseasPurchasedHistoryTable  ovrsPurcahsedHistList={data} />
      </div>
    </div>
  );

};

export default OverseasPurchasedHistory;
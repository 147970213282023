import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import { useToast } from '../../contexts/Toast';
import {format} from 'date-fns';

import OvrsUseFeeHistoryToolBar from './OverseasUseFeeHistoryToolBar';
import OvrsUseFeeHistoryTable from './OverseasUseFeeHistoryTable';
import { overseasUseFeeHistoryMutation } from '../../lib/apolloClient/mutations';
import * as utils from '../../common/utils';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    registerButton: {
        margin: theme.spacing(1), 
        marginTop: theme.spacing(3),
        float: "right"
    },
    hyperLinkButton: {
    textDecoration: 'none',
    color: 'white'
    }
  }));

type SearchInfo = {
    searchType        :string; // 검색조건
    searchValue       :string; // 검색값
    searchDateGubun   :string; // 조회일자구분 (매출일자, 청구일자, 승인일자)
    searchDate        :string; // 검색일자 
} 

type Props = {
    overseasUseFeeHistoryList: overseasUseFeeHistoryList
}

const OvrsUseFeeHistory: FC<Props> = ({overseasUseFeeHistoryList}) => {

    const classes = useStyles();
    const [useFeeHistoryList, setUseFeeHistoryList] = useState(overseasUseFeeHistoryList);
    // const [loading, setLoading] = useState(true);
    const { showToast } = useToast();

    const [searchForm, setSearchForm] = useState ({
        searchType : '1',
        searchValue : '',
        searchDateGubun : '1',
        searchDate : format(new Date(), 'yyyy-MM-dd')
    });

    // 페이징
    const default_page = 0;
    const default_rowsPerPage = 10;
    const [paging, setPaging] = useState({
        page: default_page,
        rowsPerPage: default_rowsPerPage
    });

    // 엑셀다운로드
    const [isExcelDown, setExcelDown] = useState(false);
    const [isFlag, setFlag] = useState(true);
    
    useEffect(() => {
        onSearch(searchForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging, isFlag]);

    // 해외이용수수료내역 조회
    const onSearch = async (searchForm: SearchInfo) => {
        console.log('<<< 해외이용수수료내역 실행 searchForm: ', searchForm);

        if(!isExcelDown){
            // setLoading(true);
        }

        await apolloClient.mutate({
            variables : {
                searchType : searchForm.searchType,
                searchValue : searchForm.searchValue,
                searchDataGubun : searchForm.searchDateGubun,
                searchDate : searchForm.searchDate,
                page : paging.page,
                rowsPerPage : paging.rowsPerPage,
                isExcelDown: isExcelDown
            },
            mutation :  overseasUseFeeHistoryMutation.overseasUseFeeHistory 
        }).then( res => {
            console.log('결과 : ', res.data.overseasUseFeeHistoryList);
            if(isExcelDown){
                setExcelDown(false);
                if(Number(res.data.overseasUseFeeHistoryList.totalCount)>100000){
                    showToast('10만건 이상 엑셀다운로드 할 수 없습니다.','error');
                }else{
                    excelDown(res.data.overseasUseFeeHistoryList.list);
                }
            } else {
                setUseFeeHistoryList(res.data.overseasUseFeeHistoryList);
            }
        }).catch(console.error);

        // setLoading(false);
    }

    // 엑셀다운로드 버튼 클릭
    const excelClick = () => {
        if(Number(useFeeHistoryList.totalCount) > 100000) {
            showToast('10만건이상 엑셀다운로드할 수 없습니다.','error');
            return;
        }

        setFlag(!isFlag);
        setExcelDown(true);
    }

    const excelDown = (csvData: overseasUseFeeHistory[]) => {
        let rowData = []
        for(let i=0; i < csvData.length; i++){
            rowData.push({
                'No':`${i+1}`,
                '수신일자':`${csvData[i].overseasPurchasedReceiveBaseDate}`,
                '매출종류구분':`${csvData[i].salesKindDivisionCode}`,
                '매출일자':`${csvData[i].salesDate}`,
                '청구일자':`${csvData[i].billDate}`,
                '승인일자':`${csvData[i].approvalDate}`,
                '카드번호':`${csvData[i].cardNo}`,
                '가맹점':`${csvData[i].merchantEngName}`,
                '해외이용원화수수료':`${csvData[i].overseasUseKrwFee}`,
                'ATM출금원화수수료':`${csvData[i].overseasAtmTradeKrwFee}`,
                '승인실인출원화금액':`${csvData[i].lastWithdrawalKrwAmount}`,
                '매입처리구분':`${csvData[i].purchasedProcessDivisionCode}`,
                '이용금액':`${csvData[i].tradeKrwAmount}`
            });
        }

        utils.exportToCSV("해외이용수수료내역",rowData);
    }
    
    return (
        <div className={classes.root}>
            <MenuTitleToolbar title="해외이용수수료내역" />
            <OvrsUseFeeHistoryToolBar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm}/>

            <div className={classes.registerButton}>
              {isExcelDown ? <span style={{marginRight: 20}}>Loading...</span> : ''}
              <Button 
                variant="contained" 
                color="primary" 
                onClick={(event) => {excelClick(); }} 
              >
                  Excel
              </Button>
            </div>  

            <br/>
            <br/>
            <br/>
            
            <div className={classes.content}>
                <OvrsUseFeeHistoryTable useFeeHistoryList={useFeeHistoryList} paging={paging} setPaging={setPaging} />
            </div>
        </div>
    );

};

export default OvrsUseFeeHistory; 


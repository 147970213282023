import gql from 'graphql-tag';

// 해외정산조회
// 해외정산내역 조회
export const overseasCalculateBreakdown = gql`
    mutation overseasCalculateBreakdownList(
        $settlementDate: String
    ) {
        overseasCalculateBreakdownList(
            settlementDate: $settlementDate
        ){
            aggregationDate
            accountDivisionCode
            accountDepartmentIdentifier
            cardTypeCode
            settlementUsdWithdrawalCount
            settlementUsdDepositCount
            settlementUsdWithdrawalTotalAmount
            settlementUsdDepositTotalAmount
            settlementUsdTradeTotalAmount
            settlementKrwWithdrawalTotalAmount
            settlementKrwDepositTotalAmount
            settlementKrwTradeTotalAmount
            settlementExchangeRateDiffTotalAmount
            purchasedExchangeRateDiffTotalAmount
            settlementExchangeRateApplyAmount
            accountProcessSeq
            dealBaseExchangeRate
            settlementBaseExchangeRate
            settlementRemitFee
            lastRemitKrwAmount
            settlementProcessAdminId
            settlementProcessStatusCode
            settlementProcessDate
            settlementProcessTime
            settlementErrorDescription
            settlementDate
            settlementCycleDivisionCode
            firstOperateAdminId
            firstOperateDetailDate
            firstOperateTradeCode
            lastOperateAdminId
            lastOperateDetailDate
            lastOperateTradeCode
        }
    }
`;


export const updateOverseasSettlementAggregationHistory = gql`
  mutation updateOverseasSettlementAggregationHistory($input : overseasSettlementInput) {
    updateOverseasSettlementAggregationHistory(input : $input) {
        aggregationDate
        accountDivisionCode
        accountDepartmentIdentifier
        cardTypeCode
    }
  }
`;
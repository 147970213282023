import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import 'date-fns';
import { format, subMonths } from 'date-fns';
import React, { FC, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<HanaPurchasedHistorySearch>>;
  page: number;
  rowsPerPage: number;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

const PurchasedHistoryListToolbar: FC<Props> = ({ onSearch, page, rowsPerPage, setPaging }) => {
  const [pSearchGubun, setSearchGubun] = useState(chaiStringUtils.getSchParamGubun());
  const [pSearchText, setSearchText] = useState(
    chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun())
  );
  const [pStartReceveBaseDate, setStartReceveBaseDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [pEndReceveBaseDate, setEndReceveBaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [pIsOverseasPurchase, setIsOverseasPurchase] = useState('');
  const [pType, setType] = useState('');
  const [pApprovalNumber, setApprovalNumber] = useState('');
  const [pMerchantId, setMerchantId] = useState('');

  const { showToast } = useToast();
  const { hideDialog } = useDialog();

  // 매입시작일자
  const handleStartReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartReceveBaseDate(e.target.value);
  };
  // 매입종료일자
  const handleEndReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndReceveBaseDate(e.target.value);
  };

  const onSearchClick = async () => {
    const purchasedHistorySearch: HanaPurchasedHistorySearch = {} as any;

    if (pStartReceveBaseDate == null) {
      showToast('매입시작일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    if (pEndReceveBaseDate == null) {
      showToast('매입종료일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    const paging: Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    purchasedHistorySearch.searchGubun = pSearchGubun;
    purchasedHistorySearch.searchText = pSearchText;
    purchasedHistorySearch.startReceveBaseDate = chaiStringUtils.dateFormat(pStartReceveBaseDate, 'yyyyMMdd');
    purchasedHistorySearch.endReceveBaseDate = chaiStringUtils.dateFormat(pEndReceveBaseDate, 'yyyyMMdd');
    purchasedHistorySearch.isOverseasPurchase = pIsOverseasPurchase;
    purchasedHistorySearch.type = pType;
    purchasedHistorySearch.approvalNumber = pApprovalNumber;
    purchasedHistorySearch.merchantId = pMerchantId;

    purchasedHistorySearch.isExcelDown = false;

    // 공통파라미터 저장
    chaiStringUtils.setSchParamGubun(pSearchGubun);
    chaiStringUtils.setSchParamSearchVal(pSearchGubun, pSearchText);

    onSearch(purchasedHistorySearch);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  const commonFormProps: {
    fullWidth: boolean;
    InputLabelProps: { shrink: boolean };
    margin: 'dense';
    variant: 'outlined';
  } = {
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    margin: 'dense',
    variant: 'outlined',
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              select
              SelectProps={{
                native: true,
              }}
              label="검색조건"
              name="pSearchGubun"
              value={pSearchGubun}
              onChange={e => setSearchGubun(e.target.value)}
              {...commonFormProps}
            >
              <option value="1">휴대폰번호</option>
              <option value="2">카드번호</option>
              <option value="3">고객번호</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="검색값"
              placeholder="검색값"
              name="pSearchText"
              value={pSearchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={onKeyEnter}
              {...commonFormProps}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              type="date"
              label="매입시작일자"
              name="pStartReceveBaseDate"
              value={pStartReceveBaseDate}
              onChange={handleStartReceveBaseDateChange}
              onKeyPress={onKeyEnter}
              {...commonFormProps}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              type="date"
              label="매입종료일자"
              InputProps={{
                inputProps: {
                  min: pStartReceveBaseDate ? format(new Date(pStartReceveBaseDate), 'yyyy-MM-dd') : undefined,
                },
              }}
              name="pEndReceveBaseDate"
              value={pEndReceveBaseDate}
              onChange={handleEndReceveBaseDateChange}
              onKeyPress={onKeyEnter}
              {...commonFormProps}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="구분"
              SelectProps={{
                native: true,
              }}
              name="pIsOverseasPurchase"
              value={pIsOverseasPurchase}
              onChange={e => setIsOverseasPurchase(e.target.value)}
              {...commonFormProps}
            >
              <option value="">전체</option>
              <option value="0">국내</option>
              <option value="1">해외</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              SelectProps={{
                native: true,
              }}
              label="거래 형태"
              name="pType"
              value={pType}
              onChange={e => setType(e.target.value)}
              {...commonFormProps}
            >
              <option value="">전체</option>
              <option value="confirm">정상</option>
              <option value="canceled">취소</option>
              <option value="partial_canceled">부분취소</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="승인번호"
              placeholder="승인번호"
              name="pApprovalNumber"
              value={pApprovalNumber}
              onChange={event => setApprovalNumber(event.target.value)}
              onKeyPress={onKeyEnter}
              {...commonFormProps}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="가맹점번호"
              placeholder="가맹점번호"
              name="pMerchantId"
              value={pMerchantId}
              onChange={event => setMerchantId(event.target.value)}
              onKeyPress={onKeyEnter}
              {...commonFormProps}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button color="primary" variant="contained" onClick={onSearchClick} style={{ marginTop: '9px' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PurchasedHistoryListToolbar;

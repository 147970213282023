import axios from "axios";

let CHAIMONEY_API_HOST = `https://api-dev.chai.finance`;

if (process.env.REACT_APP_ENV === 'prod') {
  CHAIMONEY_API_HOST = `https://api.chai.finance/`;
}
else if (process.env.REACT_APP_ENV === 'staging') {
  CHAIMONEY_API_HOST = `https://api-dev.chai.finance`;
}

const clientChaiMoney = axios.create({
  //baseURL: "https://card-staging.chai.finance",
  //local server URL로 변경하기-!
  baseURL: CHAIMONEY_API_HOST,
  responseType: "json"
});

export default clientChaiMoney;
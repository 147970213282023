import gql from 'graphql-tag';

// 해외원화환차손집계내역조회 
export const selectOvrsExchRateDiffProcHist = gql`
    mutation selectOvrsExchRateDiffProcHist($input : ovrsExchRateDiffSearch) {
        selectOvrsExchRateDiffProcHist (input : $input){
            aggregationDate
            setUsdWdTotalAmount
            setUsdDpTotalAmount
            setUsdTdTotalAmount
            setKrwWdTotalAmount
            setKrwDpTotalAmount
            setKrwTdTotalAmount
            setExRtDifTotalAmount
            purExRtDifTotalAmount
            setExRtApplyAmount
            dealBaseExchangeRate
            settlementBaseExchangeRate
        }
    }
`;

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { settlementListMutation } from '../../lib/apolloClient/mutations';
import SettlementListToolbar from './SettlementListToolbar';
import SettlementTable from './SettlementTable';
import { MenuTitleToolbar } from '../../components';
import {format, subMonths} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SettlementList = () => {
  const classes = useStyles();
  const [settlementList, setSettlementList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState({
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          startDate: inputSearch.startDate.replace(/-/g,''),
          endDate: inputSearch.endDate.replace(/-/g,'') }
        },
        mutation: settlementListMutation.selectSettlementList   
      }).then(res => {
        // console.log(res.data);  
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectSettlementList);
        }
        else {        
          setSettlementList(res.data.selectSettlementList);
        }
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, isFlag])

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: SettlementInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
            '집계일자': `${csvData[i].aggregationDate === '99999999' ? '합계' : chaiStringUtils.dateFormatDefault(csvData[i].aggregationDate)}`,
            '매출금액': `${chaiStringUtils.moneyFormat(csvData[i].total210Amount+csvData[i].total220Amount+csvData[i].totalFeeAmount)}`,
            'BC정산금액': `${chaiStringUtils.moneyFormat(csvData[i].total210Amount+csvData[i].total220Amount)}`,
            'Chai 가맹점수수료': `${chaiStringUtils.moneyFormat(csvData[i].totalFeeAmount)}`,
            '일반가맹점 정산금액': `${chaiStringUtils.moneyFormat(csvData[i].total210Amount)}`,
            '국세/영세 선정산액': `${chaiStringUtils.moneyFormat(csvData[i].total220Amount)}`,
            '온라인사업자 재정산액': `${chaiStringUtils.moneyFormat(csvData[i].total225Amount)}`,
            '가맹점 수수료': `${chaiStringUtils.moneyFormat(csvData[i].totalMerchantFeeAmount)}`,
            'BC대행수수료': `${chaiStringUtils.moneyFormat(csvData[i].totalProxyFeeAmount)}`,
            '차이매출': `${chaiStringUtils.moneyFormat(csvData[i].totalFeeAmount)}`, 
            '차이매출 부가세': `${chaiStringUtils.moneyFormat(csvData[i].totalFeeTaxAmount)}`          
        });
    }

    utils.exportToCSV("국내정산대금내역", rowData);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내정산대금내역" />
      <SettlementListToolbar onSearch={setInputSearch}/>    
      <div className={classes.content}>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <SettlementTable settlementList={settlementList}/>
      </div>   
    </div>
  );

};

export default SettlementList;

import React, { FC, ChangeEvent } from 'react';
import { Card, CardContent, Grid, Button, TextField } from '@material-ui/core';

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<any>>,
  searchForm: CardOverseaFeeBaseSearchForm,
  setSearchForm: React.Dispatch<React.SetStateAction<CardOverseaFeeBaseSearchForm>>
}

const CardOverseaFeeBaseToolbar: FC<Props> = ({onSearch, searchForm, setSearchForm}) => {

  // 변경이벤트 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
        ...searchForm,
        [e.target.name] : e.target.value
      });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async(event : React.KeyboardEvent<HTMLInputElement>) => {
      if(event.key === "Enter") {
          onSearchClick();
      }
  };

  // 목록 조회
  const onSearchClick = async() => {
      onSearch(searchForm);
  };

  // Reset
  const toolbarResetClick = async() => {
    setSearchForm({
      ...searchForm,
      cardProductGroupCode: '',
      cardProductCode: '',
      brandDivisionCode: '',
      overseasFeeDivisionCode: '',
      useYn: '',
      waivedYn:'',
      startDt: '',
      endDt: ''
    });
  }
  
  return (
    
      <Card>
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  label="카드상품그룹"
                  placeholder="카드상품그룹"
                  size="small"
                  select
                  margin="dense"
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="cardProductGroupCode"
                  value={searchForm.cardProductGroupCode}
                  onChange={handleChange}
                  >
                    <option value="">전체</option>
                    <option value="000002">국제체크카드</option>
                  </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="카드상품코드"
                  size="small"
                  margin="dense"
                  label="카드상품코드"
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="cardProductCode"
                  value={searchForm.cardProductCode}
                  onChange={handleChange} 
                  onKeyPress={onKeyEnter}
                  />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  label="브랜드구분"
                  placeholder="브랜드구분"
                  size="small"
                  margin="dense"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  select
                  SelectProps={{
                    native:true
                  }}
                  name="brandDivisionCode"
                  value={searchForm.brandDivisionCode}
                  onChange={handleChange} 
                   >
                     <option value="">전체</option>
                     <option value="3">MC</option>
                     </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="면제여부"
                  margin="dense"
                  placeholder="면제여부"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    native: true
                  }}
                  name="waivedYn"
                  value={searchForm.waivedYn}
                  onChange={handleChange} 
                  >
                    <option value="">전체</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                    </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="해외수수료구분"
                  margin="dense"
                  placeholder="해외수수료구분"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="overseasFeeDivisionCode"
                  value={searchForm.overseasFeeDivisionCode}
                  onChange={handleChange} 
                  >
                    <option value="">전체</option>
                    <option value="001">ATM출금수수료</option>
                    <option value="002">ATM조회수수료</option>
                    <option value="003">해외이용수수료</option>
                    <option value="004">브랜드수수료</option>
                    <option value="005">해외이용수수료(ATM)</option>
                    <option value="006">ATM브랜드수수료</option>
                    </TextField>
              </Grid>
              <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="수수료적용시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={searchForm.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="수수료적용종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={searchForm.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="사용여부"
                  margin="dense"
                  placeholder="사용여부"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    native: true
                  }}
                  name="useYn"
                  value={searchForm.useYn}
                  onChange={handleChange} 
                  >
                    <option value="">전체</option>
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                    </TextField>
              </Grid>
              
              <Grid item md={2} xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={toolbarResetClick}
                  style={{
                    marginTop: '8px',
                    marginRight: '8px'
                  }}>
                  Reset
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSearchClick}
                  style={{
                    marginTop: '8px'
                  }}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    
  );
};

export default CardOverseaFeeBaseToolbar;

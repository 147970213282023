import { Button, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import { useToast } from '../../contexts/Toast';
import apolloClient, { plccQuery } from '../../lib/apolloClient';
import { merchantInfoMutation } from '../../lib/apolloClient/mutations';
import MerchantDetail from './MerchantDetail';
import MerchantInfoListToolbar from './MerchantInfoListToolbar';
import MerchantTable from './MerchantTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
}));

type SearchInfo = {
  merchantNo: string;
  businessNumber: string;
};

type Props = {
  className?: string;
  merchantDetail: GetHanaMerchantDetail;
  listInfo: HanaMerchantListReturnType;
};
export const HanaMerchantList: FC<Props> = ({ className, merchantDetail, listInfo }) => {
  const classes = useStyles();
  const [data, setData] = useState(listInfo);
  const [loading, setLoading] = useState(false); // 가맹점 목록 조회 loading
  const [detailLoading, setDetailLoading] = useState(false); // 상세조회 로딩
  const { showToast } = useToast();

  // 엑셀다운로드
  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  // 목록조회
  const [searchForm, setSearchForm] = useState<SearchInfo>({
    merchantNo: '',
    businessNumber: '',
  });

  // 상세정보
  const [inputDetailMerchantNo, setInputDetailMerchantNo] = useState('');
  const [detailData, setDetailData] = useState<GetHanaMerchantDetail | undefined>(merchantDetail);
  const [bcDetailData, setBcDetailData] = useState<GetMerchantDetail | undefined>();

  // 데이터 목록 조회 로직
  const fetch = async () => {
    // 검색어 입력필수
    if (utils.isNullOrBlank(searchForm.merchantNo) && utils.isNullOrBlank(searchForm.businessNumber)) {
      showToast('검색어 입력은 필수입니다.', 'error');
      return;
    }

    // 상세조회 초기화
    setInputDetailMerchantNo('');

    // 이미 로딩 중이면 리턴
    if (loading) {
      return;
    }

    if (!isExcelDown) {
      setLoading(true);
    }

    await apolloClient
      .query({
        // merchantNo: 가맹점번호 또는 가맹점명
        // merchantBizNo: 가맹점사업자등록번호
        variables: {
          merchantNo: searchForm.merchantNo,
          merchantBizNo: searchForm.businessNumber,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: isExcelDown,
        },
        query: plccQuery.getHanaMerchantList,
      })
      .then(res => {
        if (isExcelDown) {
          setExcelDown(false);
          if (Number(res.data.getHanaMerchantList.totalCount) > 100000) {
            showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
          } else {
            excelDown(res.data.getHanaMerchantList.list);
          }
        } else {
          setData(res.data.getHanaMerchantList);
        }
      })
      .catch(console.error);

    setLoading(false);
  };

  // 데이터 상세 조회 로직
  useEffect(() => {
    // 가맹점 상세조회 초기화
    setDetailData(merchantDetail);

    if (inputDetailMerchantNo !== '') {
      const fetch = async () => {
        setDetailLoading(true);

        await apolloClient
          .query({
            variables: { merchantNo: inputDetailMerchantNo },
            query: plccQuery.getHanaMerchantInfo,
          })
          .then(res => {
            if (res.data.getHanaMerchantInfo) {
              setDetailData(res.data.getHanaMerchantInfo);
            } else {
              setDetailData(undefined);
            }
          })
          .catch(console.error);

        setDetailLoading(false);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDetailMerchantNo]);

  // 페이징
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, isFlag]);

  const fetchBcDetail = async (targetId: string) => {
    await apolloClient
      .mutate({
        variables: { type: targetId },
        mutation: merchantInfoMutation.selectMerchantInfo,
      })
      .then(res => {
        setBcDetailData(res.data.selectMerchantInfo);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (detailData && ['matched', 'on_ready'].includes(detailData.mappingStatus)) {
      fetchBcDetail(detailData.mappingId);
    }
  }, [detailData]);

  // 엑셀다운로드 버튼 클릭
  const excelClick = () => {
    // 엑셀다운로드 시 검색어 입력필수
    if (utils.isNullOrBlank(searchForm.merchantNo) && utils.isNullOrBlank(searchForm.businessNumber)) {
      showToast('엑셀 다운로드 시 검색어 입력은 필수입니다.', 'error');
      return;
    }

    if (Number(data.totalCount) > 100000) {
      showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
      return;
    }

    setFlag(!isFlag);
    setExcelDown(true);
  };

  const excelDown = (csvData: GetHanaMerchantDetail[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        가맹점번호: `${csvData[i].id}`,
        가맹점명: `${csvData[i].name}`,
        사업자등록번호: `${chaiStringUtils.bizNoFormat(csvData[i].businessNumber)}`,
      });
    }

    utils.exportToCSV('가맹점목록', rowData);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="하나 가맹점정보" />
      <MerchantInfoListToolbar
        fetch={fetch}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        paging={paging}
        setPaging={setPaging}
      />
      <div className={classes.content}>
        <div className={classes.registerButton}>
          {isExcelDown ? <span style={{ marginRight: 20 }}>Loading...</span> : ''}
          <Button
            variant="contained"
            color="primary"
            onClick={event => {
              excelClick();
            }}
          >
            Excel
          </Button>
        </div>

        <br />
        <br />
        <br />

        {loading ? (
          utils.Loading()
        ) : (
          <MerchantTable
            merchantList={data}
            setInputDetailMerchantNo={setInputDetailMerchantNo}
            paging={paging}
            setPaging={setPaging}
          />
        )}
        <br />
        {detailLoading ? utils.Loading() : <MerchantDetail merchantInfo={detailData} bcMerchantInfo={bcDetailData} />}
      </div>
    </div>
  );
};

import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import apolloClient from '../../lib/apolloClient';
import { overseasCsTrmMgmtMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  },
}));

type Props = {
  className?: string;
  statusCount: number;
  setStatusCount: React.Dispatch<React.SetStateAction<number>>;
};
const OverseasCsTrmMgmtDetails: FC<Props> = ({ className, statusCount, setStatusCount }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const initOverseasCsTrm = {
    trmDate: format(new Date(), 'yyyy-MM-dd'),
    trmUsdAmount: 0.0,
    trmKrwAmount: 0,
    trmApplyExchangeRate: 0.0,
    remarkDescription: '',
  };

  const [overseasCsTrmInfo, setOverseasCsTrmInfo] = useState(initOverseasCsTrm);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOverseasCsTrmInfo({
      ...overseasCsTrmInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [isValidForm, setIsValidForm] = useState(false);

  const checkFormValidation = () => {
    let isValid = true;
    if (isEmpty(overseasCsTrmInfo.trmDate)) isValid = false;
    if (overseasCsTrmInfo.trmUsdAmount <= 0) isValid = false;
    if (overseasCsTrmInfo.trmApplyExchangeRate <= 0) isValid = false;
    if (
      overseasCsTrmInfo.trmKrwAmount !==
      Math.floor(overseasCsTrmInfo.trmUsdAmount * overseasCsTrmInfo.trmApplyExchangeRate)
    )
      isValid = false;
    setIsValidForm(isValid);
  };

  useEffect(() => {
    checkFormValidation();
    // console.log('isValidForm', isValidForm);
    // eslint-disable-next-line
  }, [overseasCsTrmInfo]);

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
    await apolloClient
      .mutate({
        variables: {
          input: {
            trmDate: overseasCsTrmInfo.trmDate.replace(/-/g, ''),
            trmUsdAmount: Number(overseasCsTrmInfo.trmUsdAmount),
            trmKrwAmount: Number(overseasCsTrmInfo.trmKrwAmount),
            trmApplyExchangeRate: Number(overseasCsTrmInfo.trmApplyExchangeRate),
            remarkDescription: overseasCsTrmInfo.remarkDescription,
          },
        },
        mutation: overseasCsTrmMgmtMutation.insertOverseasCsTrm,
      })
      .then(res => {
        // console.log(res.data);
        setOverseasCsTrmInfo({
          ...overseasCsTrmInfo,
          ...initOverseasCsTrm,
        });
        setStatusCount(statusCount + 1);
        hideDialog();
        showDialog('해외민원전금', '저장되었습니다.\n\n', () => {
          hideDialog();
        });
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
  };

  const handleCalcForm = () => {
    // 전금원화금액 = 전금미화금액 * 전금적용환율
    const trmKrwAmount = Math.floor(overseasCsTrmInfo.trmUsdAmount * overseasCsTrmInfo.trmApplyExchangeRate);
    setOverseasCsTrmInfo({
      ...overseasCsTrmInfo,
      trmKrwAmount: trmKrwAmount,
    });
  };

  const handleResetForm = () => {
    setOverseasCsTrmInfo({
      ...overseasCsTrmInfo,
      ...initOverseasCsTrm,
    });
  };

  const isEmpty = (input: string) => {
    return input === '';
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader title="해외민원전금 등록" />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="전금원화금액"
                margin="dense"
                name="trmKrwAmount"
                onChange={handleChange}
                required
                value={overseasCsTrmInfo.trmKrwAmount}
                variant="outlined"
                error={overseasCsTrmInfo.trmKrwAmount <= 0}
                helperText={overseasCsTrmInfo.trmKrwAmount <= 0 ? 'Required' : ''}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="전금미화금액"
                margin="dense"
                name="trmUsdAmount"
                onChange={handleChange}
                required
                value={overseasCsTrmInfo.trmUsdAmount}
                variant="outlined"
                error={overseasCsTrmInfo.trmUsdAmount <= 0}
                helperText={overseasCsTrmInfo.trmUsdAmount <= 0 ? 'Required' : ''}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="전금적용환율"
                margin="dense"
                name="trmApplyExchangeRate"
                onChange={handleChange}
                required
                value={overseasCsTrmInfo.trmApplyExchangeRate}
                variant="outlined"
                error={overseasCsTrmInfo.trmApplyExchangeRate <= 0}
                helperText={overseasCsTrmInfo.trmApplyExchangeRate <= 0 ? 'Required' : ''}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                required
                fullWidth
                type="date"
                label="전금일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="trmDate"
                value={overseasCsTrmInfo.trmDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="비고내용"
                margin="dense"
                name="remarkDescription"
                onChange={handleChange}
                value={overseasCsTrmInfo.remarkDescription ? overseasCsTrmInfo.remarkDescription : ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" onClick={() => handleCalcForm()}>
            계산
          </Button>
          <Button
            disabled={!isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog('해외민원전금', '해당 정보로 저장하시겠습니까?', handleConfirmAddConfirm)}
          >
            New
          </Button>
          <Button color="primary" variant="contained" onClick={() => handleResetForm()}>
            Reset
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default OverseasCsTrmMgmtDetails;

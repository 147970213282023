import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
}
from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar'
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  thStyle: {
    backgroundColor: '#fafafa'
  }
}));

type overseasApprHistoryLogAggrInfo = {
  cardNormalApprovalAmountSum: number,
  cardNormalApprovalAmountCount: number,
  cardAbnormalApprovalAmountSum: number,
  cardAbnormalApprovalCount: number,
  cardNormalApprovalCancelAmountSum: number,
  cardNormalApprovalCancelCount: number,
  cardAbnormalApprovalCancelAmountSum: number,
  cardAbnormalApprovalCancelCount: number,
  cardChaimoneyNormalApprovalAmountSum: number,
  cardChaimoneyNormalApprovalCount: number,
  cardChaimoneyAbnormalApprovalAmountSum: number,
  cardChaimoneyAbnormalApprovalCount: number,
  cardChaimoneyNormalApprovalCancelAmountSum: number,
  cardChaimoneyNormalApprovalCancelCount: number,
  cardChaimoneyAbnormalApprovalCancelAmountSum: number,
  cardChaimoneyAbnormalApprovalCancelCount: number,
  cardBcNormalApprovalAmountSum: number,
  cardBcNormalApprovalCount: number,
  cardBcAbnormalApprovalAmountSum: number,
  cardBcAbnormalApprovalCount: number,
  cardBcNormalApprovalCancelAmountSum: number,
  cardBcNormalApprovalCancelCount: number,
  cardBcAbnormalApprovalCancelAmountSum: number,
  cardBcAbnormalApprovalCancelCount: number,
  atmNormalApprovalAmountSum: number,
  atmNormalApprovalCount: number,
  atmAbnormalApprovalAmountSum: number,
  atmAbnormalApprovalCount: number,
  atmNormalApprovalCancelAmountSum: number,
  atmNormalApprovalCancelCount: number,
  atmAbnormalApprovalCancelAmountSum: number,
  atmAbnormalApprovalCancelCount: number,
  atmChaimoneyNormalApprovalAmountSum: number,
  atmChaimoneyNormalApprovalCount: number,
  atmChaimoneyAbnormalApprovalAmountSum: number,
  atmChaimoneyAbnormalApprovalCount: number,
  atmChaimoneyNormalApprovalCancelAmountSum: number,
  atmChaimoneyNormalApprovalCancelCount: number,
  atmChaimoneyAbnormalApprovalCancelAmountSum: number,
  atmChaimoneyAbnormalApprovalCancelCount: number,
  atmBcNormalApprovalAmountSum: number,
  atmBcNormalApprovalCount: number,
  atmBcAbnormalApprovalAmountSum: number,
  atmBcAbnormalApprovalCount: number,
  atmBcNormalApprovalCancelAmountSum: number,
  atmBcNormalApprovalCancelCount: number,
  atmBcAbnormalApprovalCancelAmountSum: number,
  atmBcAbnormalApprovalCancelCount: number
}

type Props = {
  overseasApprHistoryLogAggrInfo: overseasApprHistoryLogAggrInfo
}

const OverseasApprHistoryLogAggrTable: FC<Props> = ({ overseasApprHistoryLogAggrInfo }) => {
  const classes = useStyles();
  
  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" rowSpan={2} colSpan={2}>구분</TableCell>
                  <TableCell align="center" colSpan={3}>신판승인</TableCell>
                  <TableCell align="center" colSpan={3}>신판승인취소</TableCell>
                  <TableCell align="center" colSpan={3}>ATM인출승인</TableCell>
                  <TableCell align="center" colSpan={3}>ATM인출승인취소</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle} rowSpan={2}>정상</TableCell>
                  <TableCell align="center" className={classes.thStyle}>건수</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardNormalApprovalAmountCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyNormalApprovalCount)}</TableCell>

                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyNormalApprovalCancelCount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>금액</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyNormalApprovalAmountSum)}</TableCell>

                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyNormalApprovalCancelAmountSum)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle} rowSpan={2}>비정상</TableCell>
                  <TableCell align="center" className={classes.thStyle}>건수</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyAbnormalApprovalCount)}</TableCell>

                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyAbnormalApprovalCancelCount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>금액</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyAbnormalApprovalAmountSum)}</TableCell>

                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardBcAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.cardChaimoneyAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmBcAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(overseasApprHistoryLogAggrInfo.atmChaimoneyAbnormalApprovalCancelAmountSum)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default OverseasApprHistoryLogAggrTable;
import { Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  entireInfo: ovrsSettlementAggrListDetail[];
};

const OverseasSettlementAggrListEntire: FC<Props> = ({ entireInfo }) => {
  console.log('# OverseasSettlementAggrListDetail !!!!! : ', entireInfo);
  console.log('# detailInfo - length :', entireInfo.length);
  console.log(
    '@@ get data value :',
    entireInfo.slice(0, 1).map(aa => aa.accountDivisionCode)
  );

  return (
    <Card>
      <CardHeader title=" 정산집계항목 " />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>
              {' '}
              No{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              정산일자{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              카드계정구분{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              출금건수{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              입금건수{' '}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {' '}
              미화금액{' '}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              {' '}
              원화금액{' '}
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {' '}
              환율차이총액{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              실정산일자{' '}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {' '}
              정산처리상태{' '}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"> 출금총액 </TableCell>
            <TableCell align="center"> 입금총액 </TableCell>
            <TableCell align="center"> 거래총액 </TableCell>
            <TableCell align="center"> 출금총액 </TableCell>
            <TableCell align="center"> 입금총액 </TableCell>
            <TableCell align="center"> 거래총액 </TableCell>
            <TableCell align="center"> 매입 </TableCell>
            <TableCell align="center"> 정산 </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entireInfo.slice(0, entireInfo.length).map((item, index) => (
            <TableRow hover key={index}>
              <TableCell align="center"> {index + 1} </TableCell>
              <TableCell align="center"> {chaiStringUtils.dateFormatDefault(item.aggregationDate)} </TableCell>
              <TableCell align="center">
                {' '}
                {chaiStringUtils.codeName('accountDivisionCode', item.accountDivisionCode)}{' '}
              </TableCell>
              <TableCell align="center"> {item.settlementUsdWithdrawalCount} </TableCell>
              <TableCell align="center"> {item.settlementUsdDepositCount} </TableCell>
              <TableCell align="right">
                {' '}
                {chaiStringUtils.moneyFormat(item.settlementUsdWithdrawalTotalAmount)}{' '}
              </TableCell>
              <TableCell align="right"> {chaiStringUtils.moneyFormat(item.settlementUsdDepositTotalAmount)} </TableCell>
              <TableCell align="right"> {chaiStringUtils.moneyFormat(item.settlementUsdTradeTotalAmount)} </TableCell>
              <TableCell align="right">
                {' '}
                {chaiStringUtils.moneyFormat(item.settlementKrwWithdrawalTotalAmount)}{' '}
              </TableCell>
              <TableCell align="right"> {chaiStringUtils.moneyFormat(item.settlementKrwDepositTotalAmount)} </TableCell>
              <TableCell align="right"> {chaiStringUtils.moneyFormat(item.settlementKrwTradeTotalAmount)} </TableCell>
              <TableCell align="right">
                {' '}
                {chaiStringUtils.moneyFormat(item.purchasedExchangeRateDiffTotalAmount)}{' '}
              </TableCell>
              <TableCell align="right">
                {' '}
                {chaiStringUtils.moneyFormat(item.settlementExchangeRateDiffTotalAmount)}{' '}
              </TableCell>
              <TableCell align="center"> {chaiStringUtils.dateFormatDefault(item.settlementProcessDate)} </TableCell>
              <TableCell align="center">
                {' '}
                {chaiStringUtils.codeName('overseasSettlementProcessStatusCode', item.settlementProcessStatusCode)}{' '}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default OverseasSettlementAggrListEntire;

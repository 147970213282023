import gql from 'graphql-tag';

export const updateHanaMerchantMapping = gql`
  mutation updateHanaMerchantMappingList($variables: JSON!, $original: JSON) {
    updateHanaMerchantMappingList(variables: $variables, original: $original) {
      changedTotal
      errors
    }
  }
`;

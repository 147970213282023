import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { overseasCsListMutation } from '../../lib/apolloClient/mutations';
import OverseasCsListToolbar from './OverseasCsListToolbar';
import OverseasCsTable from './OverseasCsTable';
import { MenuTitleToolbar } from '../../components';
import {format, subMonths} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OverseasCsList = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const default_page = 0;
  const default_rowsPerPage = 100;
  const [inputSearch, setInputSearch] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),
    csRewardYn: '',  
    overseasCsReceiptCode: '',
    overseasCsReasonDivisionCode: '',
    overseasCsEndDivisionCode: '',
    salesKindDivisionCode: '',
    cardNo: '',
    purchasedProcessNo: '',
    userKorName: ''
  });

  const [statusCount,setStatusCount] = useState(1);

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });


  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {    
          startDt : inputSearch.startDt.replace(/-/g,''),
          endDt : inputSearch.endDt.replace(/-/g,''),
          csRewardYn : inputSearch.csRewardYn,
          overseasCsReceiptCode : inputSearch.overseasCsReceiptCode,
          overseasCsReasonDivisionCode : inputSearch.overseasCsReasonDivisionCode, 
          overseasCsEndDivisionCode : inputSearch.overseasCsEndDivisionCode, 
          salesKindDivisionCode : inputSearch.salesKindDivisionCode, 
          cardNo : inputSearch.cardNo,
          purchasedProcessNo : inputSearch.purchasedProcessNo,
          userKorName : inputSearch.userKorName,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: false}},
        mutation: overseasCsListMutation.selectOverseasCsList
      }).then(res => {
        console.log(res.data);  
        setData(res.data.selectOverseasCsList);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
  }, [inputSearch, statusCount, paging])

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외민원내역" />
      <OverseasCsListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setInputSearch}/>      
      <div className={classes.content}>
        <OverseasCsTable paging={paging} setPaging={setPaging} statusCount={statusCount} setStatusCount={setStatusCount} overseasCsList={data}/>
      </div>
    </div>
  );

};

export default OverseasCsList;

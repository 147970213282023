import gql from 'graphql-tag';

// 해외이용수수료내역
// 해외이용수수료내역 조회
export const overseasUseFeeHistory = gql`
    mutation overseasUseFeeHistoryList(
        $searchType: String
        $searchValue: String
        $searchDataGubun: String
        $searchDate: String
        $page: Float
        $rowsPerPage: Float
        $isExcelDown: Boolean
    ) {
        overseasUseFeeHistoryList(
            searchType: $searchType
            searchValue: $searchValue
            searchDataGubun: $searchDataGubun
            searchDate: $searchDate
            page: $page
            rowsPerPage: $rowsPerPage
            isExcelDown: $isExcelDown
        ){
            totalCount
            list{
                overseasPurchasedReceiveBaseDate
                salesKindDivisionCode
                salesDate
                billDate
                approvalDate
                cardNo
                merchantEngName
                overseasUseKrwFee
                overseasAtmTradeKrwFee
                lastWithdrawalKrwAmount
                purchasedProcessDivisionCode
                tradeKrwAmount
            }
        }
    }
`;
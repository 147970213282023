import {
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import { accident } from './cardApi';

const StyledTableCell = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
}));

// 사고등록 폼
type registerForm = {
  acdntCd: string; // 사고코드
  lostDt?: string; // 분실일자
  accidentStatusDivisionCode: string; // 등해제구분
};

type Props = {
  className?: string;
  title: string;
  cardNo: string;
  cardNoMasked: string;
  cardListFetch: any;
};
const CardAccidentRegister: FC<Props> = ({ className, title, cardNo, cardNoMasked, cardListFetch }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  // 등/해제구분 disabled
  const [disabled, setDisabled] = useState(false);

  // 사고등록 폼
  const [registerForm, setRegisterForm] = useState({
    acdntCd: '',
    lostDt: format(new Date(), 'yyyy-MM-dd'),
    accidentStatusDivisionCode: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterForm({
      ...registerForm,
      [e.target.name]: e.target.value,
    });

    //  등/해제구분이 필요하지 않은 코드에대해선 disabled
    if (e.target.name === 'acdntCd') {
      if (
        e.target.value === '011' ||
        e.target.value === '007' ||
        e.target.value === '005' ||
        e.target.value === '043' ||
        e.target.value === '087'
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  // 등/해제구분 disabled시 값 초기화
  useEffect(() => {
    if (disabled) {
      registerForm.accidentStatusDivisionCode = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  // 사고등록 처리
  const handleAccidentRegister = async () => {
    // 카드번호 검증
    if (cardNo === null || cardNo === '') {
      showToast('카드번호가 선택되지 않았습니다.');
      hideDialog();
      return;
    }

    // 사고코드검증
    if (registerForm.acdntCd === null || registerForm.acdntCd === '') {
      showToast('사고유형이 선택되지 않았습니다.');
      hideDialog();
      return;
    }

    // 등/해제구분 검증
    if (
      registerForm.acdntCd === '011' ||
      registerForm.acdntCd === '007' ||
      registerForm.acdntCd === '005' ||
      registerForm.acdntCd === '043' ||
      registerForm.acdntCd === '087'
    ) {
      if (registerForm.accidentStatusDivisionCode === null || registerForm.accidentStatusDivisionCode === '') {
        showToast('등/해제구분이 선택되지 않았습니다.');
        hideDialog();
        return;
      }
    }

    // 사고코드 조합
    let accidentCode: string = registerForm.acdntCd + registerForm.accidentStatusDivisionCode;

    // 분실신고시 분실일자 필수
    let lostDt = '';
    if (accidentCode === '0111') {
      if (registerForm.lostDt === null || registerForm.lostDt === '') {
        showToast('분실신고시 분실일자는 필수 항목 입니다.');
        hideDialog();
        return;
      } else {
        lostDt = registerForm.lostDt.replace(/-/gi, '');
      }
    }

    console.log('카드번호[cardNo]: ', cardNo); // 카드번호
    console.log('사고코드[accidentCode]: ', accidentCode); // 사고코드
    console.log('분실일자[lostDt]: ', lostDt); // 분실일자

    try {
      const result = await accident({
        cardNo: cardNo,
        RegAcciCode: accidentCode,
        LossDate: lostDt,
      });

      console.log('결과 : ', result);

      const status = result.STATUS;
      // const resData = result.resData;

      // 결과 Toast 메세지
      if (status.success === 'Y') {
        showToast(status.message);

        // 카드목록 리로드
        cardListFetch();
      } else {
        showToast(status.message + ` 에러코드: (${status.code})`, 'error');
      }
    } catch (e) {
      let errorMessage = '';
      if (e.message.substring(e.message.length - 3) === '401') {
        errorMessage = '올바르지 않은 카드번호입니다. 에러코드: (79)';
      } else if (e.message.substring(e.message.length - 3) === '500') {
        errorMessage = '시스템 장애 에러코드 (89)';
      } else {
        errorMessage = '서버와의 통신이 원활하지 않습니다. \n관리자에게 문의하시기 바랍니다.';
      }
      showToast(errorMessage, 'error');
    }

    hideDialog();
  };

  return (
    <div>
      <Card className={clsx(classes.root, className)}>
        <form>
          {!!title && <CardHeader title={title} />}
          <Divider />
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>선택한 카드번호</StyledTableCell>
                <StyledTableCell>{cardNoMasked}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>사고종류</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    select
                    fullWidth
                    label="사고종류"
                    margin="dense"
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="acdntCd"
                    value={registerForm.acdntCd}
                    onChange={handleChange}
                  >
                    <option value="">선택</option>
                    <option value="0069">사용등록</option>
                    <option value="011">분실</option>
                    <option value="007">일시정지</option>
                    <option value="0811">카드해지</option>
                    <option value="0061">배송정지</option>
                    <option value="005">반송</option>
                    <option value="043">해외결제제한</option>
                    <option value="087">해외DCC결제제한(해외원화결제제한)</option>
                  </TextField>
                </StyledTableCell>
                <StyledTableCell>등/해제구분</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    select
                    fullWidth
                    label="등/해제구분"
                    margin="dense"
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="accidentStatusDivisionCode"
                    value={registerForm.accidentStatusDivisionCode}
                    onChange={handleChange}
                    disabled={disabled}
                  >
                    <option value="">선택</option>
                    <option value="1">등재</option>
                    <option value="9">해제</option>
                  </TextField>
                </StyledTableCell>
                <StyledTableCell>분실일자</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    type="date"
                    label="분실일자"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="lostDt"
                    value={registerForm.lostDt}
                    onChange={handleChange}
                  />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Button
            className={classes.registerButton}
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() =>
              showDialog('사고 등록', '사고 등록을 진행 하시겠습니까?', () => {
                handleAccidentRegister();
              })
            }
          >
            사고 등록
          </Button>
          <Divider />
        </form>
      </Card>
    </div>
  );
};

export default CardAccidentRegister;

import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  merchantList: HanaMerchantListReturnType;
  setInputDetailMerchantNo: React.Dispatch<React.SetStateAction<string>>;
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};
const MerchantTable: FC<Props> = ({ merchantList, setInputDetailMerchantNo, paging, setPaging }) => {
  const classes = useStyles();

  let list = merchantList === undefined ? [] : merchantList['list'];
  let totalCount = merchantList === undefined ? 0 : merchantList['totalCount'];

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    setPaging({
      ...paging,
      page: inPage,
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value),
    });
  };

  // 가맹점 번호 클릭 시 상세화면
  const handleDetailClick = (target: string) => {
    setInputDetailMerchantNo(target);
  };

  if (list.length === 0) {
    return (
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>가맹점번호</TableCell>
                  <TableCell>가맹점명</TableCell>
                  <TableCell>사업자등록번호</TableCell>
                  <TableCell>그룹가맹점번호</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(merchant => (
                  <TableRow hover key={merchant.id}>
                    <TableCell>
                      <Button
                        onClick={() => handleDetailClick(merchant.id)}
                        variant="text"
                        color="primary"
                        size="small"
                      >
                        {merchant.id}
                      </Button>
                    </TableCell>
                    <TableCell>{merchant.name}</TableCell>
                    <TableCell>{chaiStringUtils.bizNoFormat(merchant.businessNumber)}</TableCell>
                    <TableCell>{merchant.groupIds && merchant.groupIds.join(',')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default MerchantTable;

import gql from 'graphql-tag';



export const selectSettlementList = gql`
  mutation selectSettlementList ($input : SettlementListSearch){
    selectSettlementList (input : $input) {
      aggregationDate
      total210Amount
      total220Amount
      total225Amount
      totalFeeAmount
      totalFeeTaxAmount
      totalProxyFeeAmount
      totalMerchantFeeAmount
    }
  }
`;


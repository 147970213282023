import React, { useState, useEffect } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import { purchasedTypeHistoryMutation } from '../../lib/apolloClient/mutations';
import PurchasedTypeHistoryListToolbar from './PurchasedTypeHistoryListToolbar';
import PurchasedTypeHistoryTable from './PurchasedTypeHistoryTable';
import PurchasedTypeHistoryAggrTable from './PurchasedTypeHistoryAggrTable';
import {format, subMonths} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PurchasedTypeHistoryList = () => {
  const classes = useStyles();
  const default_page = 0;
  const default_rowsPerPage = 100;
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [aggrLoading, setAggrLoading] = useState(false);
  const [purchasedTypeHistorySearch, setPurchasedTypeHistorySearch] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),
    salesKindDivisionCode: '',
    approvalProcessStatusCode: ''
  });

  const [purchasedAggrData, setPurchasedAggrData] = useState({
    normalApprovalAmountSum: 0,
    normalApprovalCount:	0,
    normalApprovalCancelAmountSum: 0,
    normalApprovalCancelCount: 0
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          searchType: purchasedTypeHistorySearch.searchType,
          searchValue: purchasedTypeHistorySearch.searchValue,
          startDt: purchasedTypeHistorySearch.startDt.replace(/-/g,''),
          endDt: purchasedTypeHistorySearch.endDt.replace(/-/g,''),
          salesKindDivisionCode: purchasedTypeHistorySearch.salesKindDivisionCode,
          approvalProcessStatusCode: purchasedTypeHistorySearch.approvalProcessStatusCode,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: isExcelDown
        }},
        mutation: purchasedTypeHistoryMutation.selectPurchasedTypeHistoryList     
      }).then(res => {
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectPurchasedTypeHistoryList);
        }else{
          setData(res.data.selectPurchasedTypeHistoryList);
        }
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasedTypeHistorySearch, paging, isFlag])

  useEffect(() => {
    const fetch = async () => {
      // setAggrLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          searchType: purchasedTypeHistorySearch.searchType,
          searchValue: purchasedTypeHistorySearch.searchValue,
          startDt: purchasedTypeHistorySearch.startDt.replace(/-/g,''),
          endDt: purchasedTypeHistorySearch.endDt.replace(/-/g,''),
          salesKindDivisionCode: purchasedTypeHistorySearch.salesKindDivisionCode,
          approvalProcessStatusCode: purchasedTypeHistorySearch.approvalProcessStatusCode }
        },
        mutation: purchasedTypeHistoryMutation.selectPurchasedTypeHistoryAggregation
      }).then(res => {
        setPurchasedAggrData(res.data.selectPurchasedTypeHistoryAggregation); 
      }).catch(console.error);

      // setAggrLoading(false)

    }
    fetch()
  }, [purchasedTypeHistorySearch])

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: PurchasedTypeHistoryInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
            '카드번호': `${csvData[i].cardNoMasked}`,
            '매출구분': `${chaiStringUtils.codeName('salesKindDivisionCode', csvData[i].salesKindDivisionCode)}(${csvData[i].salesKindDivisionCode})`,
            '승인금액': `${chaiStringUtils.moneyFormat(csvData[i].cardApprovalAmount)}`,
            '승인일자': `${chaiStringUtils.dateFormatDefault(csvData[i].approvalDate)}`,
            '승인번호': `${csvData[i].cardApprovalNo}`,
            '가맹점명': `${csvData[i].merchantName}`
        });
    }

    utils.exportToCSV("이용내역", rowData);    
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="이용내역" />
      <PurchasedTypeHistoryListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setPurchasedTypeHistorySearch}/>   
      <div className={classes.content}>
        <PurchasedTypeHistoryAggrTable purchasedTypeHistoryAggrInfo={purchasedAggrData}/>
      </div>  
      <div className={classes.content}>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <PurchasedTypeHistoryTable paging={paging} setPaging={setPaging} purchasedTypeHistoryList={data}/>
      </div>
    </div>
  );

};

export default PurchasedTypeHistoryList;

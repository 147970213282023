import React, { FC, useState, useEffect } from 'react';
import apolloClient, { plccQuery } from '../../lib/apolloClient';
import PurchasedHistoryListToolbar from './PurchasedHistoryListToolbar';
import PurchasedHistoryTable from './PurchasedHistoryTable';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { MenuTitleToolbar } from '../../components';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { convertPurchaseType } from './helper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  detail: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  listInfo: HanaPurchasedHistoryList;
};

export const HanaPurchasedList: FC<Props> = ({ listInfo }) => {
  const default_page = 0;
  const default_rowsPerPage = 100;
  const classes = useStyles();
  const [listData, setListData] = useState(listInfo);
  const [listSearch, setListSearch] = useState({
    searchGubun: chaiStringUtils.getSchParamGubun(),
    searchText: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startReceveBaseDate: format(subMonths(new Date(), 1), 'yyyyMMdd'),
    endReceveBaseDate: format(new Date(), 'yyyyMMdd'),
    isOverseasPurchase: '',
    type: '',
    approvalNumber: '',
    merchantId: '',
    isExcelDown: false,
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const getList = async () => {
      await apolloClient
        .query({
          query: plccQuery.getHanaPurchasedList,
          variables: {
            input: {
              searchGubun: listSearch.searchGubun,
              searchText: listSearch.searchText,
              startReceveBaseDate: listSearch.startReceveBaseDate,
              endReceveBaseDate: listSearch.endReceveBaseDate,
              isOverseasPurchase: listSearch.isOverseasPurchase,
              type: listSearch.type,
              approvalNumber: listSearch.approvalNumber,
              merchantId: listSearch.merchantId,
              page: paging.page,
              rowsPerPage: paging.rowsPerPage,
              isExcelDown: isExcelDown,
            },
          },
        })
        .then(res => {
          console.log(res.data);
          if (isExcelDown) {
            setExcelDown(false);
            excelDown(res.data.getHanaPurchasedList.list);
          } else {
            setListData(res.data.getHanaPurchasedList);
          }
        })
        .catch(console.error);
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSearch, paging, isFlag]);

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  };

  const excelDown = (csvData: HanaPurchasedHistoryInfo[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        매입일자: `${chaiStringUtils.dateFormatDefault(csvData[i].purchaseDate)}`,
        승인일자: `${chaiStringUtils.dateFormatDefault(csvData[i].approvalDate)}`,
        구분: `${csvData[i].isOverseasPurchase ? '해외' : '국내'}`,
        거래형태: convertPurchaseType(csvData[i].type),
        카드번호: `****-****-****-${csvData[i].cardLastNumber}`,
        가맹점명: `${csvData[i]._merchant?.name ?? '-'}`,
        가맹점번호: `${csvData[i]._merchant?.businessNumber}`,
        '매출합계액 (원)': `${csvData[i].amount?.toLocaleString() ?? '-'}`,
        승인번호: `${csvData[i].approvalNumber}`,
      });
    }

    utils.exportToCSV('신용카드 매입내역', rowData);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="신용카드 매입내역 조회" />
      <PurchasedHistoryListToolbar
        /** 검색조건 */ onSearch={setListSearch}
        page={default_page}
        rowsPerPage={default_rowsPerPage}
        setPaging={setPaging}
      />
      <div className={classes.content}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto', marginBottom: 10 }}
          onClick={event => {
            excelClick();
          }}
        >
          Excel
        </Button>
        <PurchasedHistoryTable
          /** 매입내역LIST */ dataList={listData ?? { totalCount: 0, list: [] }}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          setPaging={setPaging}
        />
      </div>
    </div>
  );
};

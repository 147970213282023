import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { useToast } from '../../contexts/Toast';
import NonInoutInfoToolbar from './NonInoutInfoToolbar';
import NonInoutInfoTable from './NonInoutInfoTable';
import NonInoutInfoHistory from './NonInoutHistory';
import { MenuTitleToolbar } from '../../components';
import { nonInoutInfoMutation } from '../../lib/apolloClient/mutations';

type Props = {
  className?: string;
  nonInoutHistoryReturn: NonInoutHistoryReturn[]; // 카드사고 이력
};

const NonInoutInfo: FC<Props> = ({
  className,
  nonInoutHistoryReturn
}) => {
  const classes = useStyles();
  const [data, setData] = useState<Array<NonInoutInfoReturn>>([]);
  const [nonInoutHistory, setNonInoutHistory] = useState(nonInoutHistoryReturn);
  // const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [updateClick, setUpdateClick] = useState(false);
  const [searchForm, setSearchForm] = useState({
    startDt: '',
    endDt: '',
    searchGubun: '',
    searchVal: '',
    nonInoutStatusCode: '', // 미입출상태코드 1:
    inoutDivisionCode: '' // 입출금상태 1:입금 2:출금
  });

  const [allCheckYn, setAllCheckYn] = useState(false);

  //회원정보조회

  useEffect(() => {
    // setLoading(true);
    const fetch = async () => {
      await apolloClient
        .mutate({
          variables: {
            input: {
              startDt: searchForm.startDt,
              endDt: searchForm.endDt,
              searchGubun: searchForm.searchGubun,
              searchVal: searchForm.searchVal,
              nonInoutStatusCode: searchForm.nonInoutStatusCode, // 미입출상태코드 1:
              inoutDivisionCode: searchForm.inoutDivisionCode
            }
          },
          mutation: nonInoutInfoMutation.selectNonInoutInfo
        })
        .then(res => {
          setData(res.data.selectNonInoutInfo);
        })
        .catch(console.error);

      // setLoading(false);
    };

    fetch();
  }, [searchForm, updateClick]);

  const onClickRow = async (nonInoutSeqInput: number) => {
    // setLoading(true);
    await apolloClient
      .mutate({
        variables: {
          nonInoutSeq: nonInoutSeqInput
        },
        mutation: nonInoutInfoMutation.selectNonInoutHistory
      })
      .then(res => {
        console.log(res.data.selectNonInoutHistory);
        setNonInoutHistory(res.data.selectNonInoutHistory);
        if (
          (res.data.selectNonInoutHistory === undefined ||
            res.data.selectNonInoutHistory === null ||
            res.data.selectNonInoutHistory.length === 0) &&
          nonInoutSeqInput !== 0
        ) {
          showToast('미입출금 처리이력이 존재하지 않습니다.', 'error');
        }
      })
      .catch(console.error);
    // setLoading(false);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="미입출금내역조회" />
      <div className={classes.content}>
        <NonInoutInfoToolbar onSearch={setSearchForm} onClickRow={onClickRow} />
      </div>
      <div className={classes.content}>
        <NonInoutInfoTable
          allCheckYn={allCheckYn}
          setAllCheckYn={setAllCheckYn}
          nonInoutInfo={data}
          onClickRow={onClickRow}
          setNonInoutInfo={setData}
          setUpdateClick={setUpdateClick}
          updateClick={updateClick}
        />
      </div>
      <div className={classes.content}>
        <NonInoutInfoHistory nonInoutHistory={nonInoutHistory} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

export default NonInoutInfo;

import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<PurchasedTypeHistorySearch>>
}

const PurchasedTypeHistoryListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {  

  const [purchasedTypeHistorySearch, setPurchasedTypeHistorySearch] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),
    salesKindDivisionCode: '',
    approvalProcessStatusCode: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchasedTypeHistorySearch({
      ...purchasedTypeHistorySearch,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
    
    // 공통 검색 파라미터 저장
    chaiStringUtils.setSchParamGubun(purchasedTypeHistorySearch.searchType);
    chaiStringUtils.setSchParamSearchVal(purchasedTypeHistorySearch.searchType, purchasedTypeHistorySearch.searchValue);
    onSearch(purchasedTypeHistorySearch);
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="검색조건"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchType"
                value={purchasedTypeHistorySearch.searchType}
                onChange={handleChange}
              >
                <option value="1">휴대폰번호</option>
                <option value="2">카드번호</option>
                <option value="3">고객번호</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="검색값"
                placeholder="검색값"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchValue"
                value={purchasedTypeHistorySearch.searchValue}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="조회시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={purchasedTypeHistorySearch.startDt}
                onChange={handleChange}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="조회종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={purchasedTypeHistorySearch.endDt}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
          >              
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="매출종류"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="salesKindDivisionCode"
                value={purchasedTypeHistorySearch.salesKindDivisionCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="05">정상매출</option>
                <option value="15">취소매출</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="처리구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="approvalProcessStatusCode"
                value={purchasedTypeHistorySearch.approvalProcessStatusCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="1">정상</option>
                <option value="2">오류</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default PurchasedTypeHistoryListToolbar;

import React, { FC, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns'
import apolloClient from '../../lib/apolloClient';
import {approvalAggrMutation} from '../../lib/apolloClient/mutations';

type ProductInfo = {
  cardProductCode :string;
  cardProductCodeName :string;
}

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<ApprovalHistoryLogAggrSearch>>
}

const ApprovalHistoryLogAggrToolbar: FC<Props> = ({onSearch}) => {  
  
  const [productList, setProductList] = useState<ProductInfo[]>([]);
  const [inputSearch, setInputSearch] = useState({
    aggregationDivisionCode: '2',
    cardProductCode: '',
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch({
      ...inputSearch,
      [e.target.name]: e.target.value
    });
  };

  // 카드상품 목록 조회
  useEffect(() => {
    const fetch = async () => {
      await apolloClient.mutate({
        variables: { useYn: 'Y' },
        mutation: approvalAggrMutation.selectProductList
      }).then(res => {
        setProductList(res.data.selectProductList);
      }).catch(console.error);
    }
    fetch()
  }, []);

  const onSearchClick = async () => {
    onSearch(inputSearch);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };
  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="카드상품"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardProductCode"
                value={inputSearch.cardProductCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {productList.map(item => (
                  <option
                    key={item.cardProductCode}
                    value={item.cardProductCode}
                  >
                    {item.cardProductCodeName}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDate"
                value={inputSearch.startDate}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDate"
                value={inputSearch.endDate}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default ApprovalHistoryLogAggrToolbar;

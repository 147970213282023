import gql from 'graphql-tag';

// 대행수수료 정산내역 목록 조회
export const selectSettlementAuto = gql`
    mutation selectSettlementAutoList(
        $feeSettlementYm: String,
        $proxyFeeCodeNo: String,
        $domesticCode: String
    ) {
        selectSettlementAutoList(
            feeSettlementYm: $feeSettlementYm            
            proxyFeeCodeNo: $proxyFeeCodeNo          
            domesticCode: $domesticCode  

       ){
        feeSettlementYm
        feeClaimYm
        domesticCode
        proxyFeeCodeNo
        proxyFeeNm
        unitFeeCodeNo
        unitFeeName
        applyUnitprc
        feeBaseRate
        feeAutoYn
        resultCount
        BCTotalResultCount
        BCResultAmount
       }  
    }
`;

// 위임수수료(조회조건) 목록 조회 
export const selectSettlementAutoNoNm = gql`
    mutation selectSettlementAutoNoNm{
        selectSettlementAutoNoNm{
            proxyFeeCodeNo
            proxyFeeNm
        }
    }
`;

// 대행수수료 정산내역 합계 조회
export const selectSettlementAutoAggr = gql`
    mutation selectSettlementAutoAggr(
        $feeSettlementYm: String
    ) {
        selectSettlementAutoAggr(
            feeSettlementYm: $feeSettlementYm  
        ){
            totalAmountBC      
            totalAmountCHAI 
            totalCountBC 
            totalCountCHAI 
        }
    }
`;
import gql from 'graphql-tag';

export const selectOverseasApprList = gql`
  mutation selectOverseasApprList(
    $searchType: String
    $searchValue: String
    $startDate: String
    $endDate: String
    $salesDivisionCode: String
    $apprProcessStatusCode: String
    $cardApprNo: String
    $paymentId: String
    $adminErrorCode: String
    $page: Float
    $rowsPerPage: Float
    $isExcelDown: Boolean
  ) {
    selectOverseasApprList(
      searchType: $searchType
      searchValue: $searchValue
      startDate: $startDate
      endDate: $endDate
      salesDivisionCode: $salesDivisionCode
      apprProcessStatusCode: $apprProcessStatusCode
      cardApprNo: $cardApprNo
      paymentId: $paymentId
      adminErrorCode: $adminErrorCode
      page: $page
      rowsPerPage: $rowsPerPage
      isExcelDown: $isExcelDown
    ) {
      totalCount
      list {
        overseasCardApprovalNo
        overseasApprovalUseNo
        paymentId
        approvalDate
        approvalTime
        tradeDate
        cardNo
        cardNoMasked
        merchantEngName
        overseasMerchantNo
        salesKindDivisionCode
        requestWithdrawalUsdAmount
        realWithdrawalKrwAmount
        approvalProcessStatusCode
        slipPurchaseYn
        tradeApprovalSeq
        adminErrorCode
        refundPaymentId
        textApprovalRequestUsdAmount
        inoutProcessSeq
        accountProcessSeq
        refundProcessCode
        nonInoutRequestYn
        cancelAfterUsdBalance
        approvalCancelStatusDivisionCode
        originCancelApplyUsdBalance
        originCancelApplyKrwBalance
        cardApprovalResponseCode
        _member {
          userKorName
          userId
        }
      }
    }
  }
`;

export const selectOverseasApprDetail = gql`
  mutation selectOverseasApprDetail($tradeDate: String, $tradeApprovalSeq: Float) {
    selectOverseasApprDetail(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      overseasCardApprovalNo
      approvalDate
      approvalTime
      cardTypeCode
      cardNo
      cardNoMasked
      merchantEngName
      overseasMerchantNo
      textSendDate
      textSendTime
      textApprovalRequestUsdAmount
      approvalRequestUsdAmount
      approvalRequestUsdOriginAmount
      overseasBrandUsdFee
      originOverseasBrandUsdFee
      overseasBrandKrwFee
      originOverseasBrandKrwFee
      overseasUseUsdFee
      originOverseasUseUsdFee
      overseasUseKrwFee
      originOverseasUseKrwFee
      overseasWithdrawalUsdFee
      originOverseasWithdrawalUsdFee
      overseasWithdrawalKrwFee
      originOverseasWithdrawalKrwFee
      textTradeUniqNo
      slipPurchaseYn
      approvalProcessStatusCode
      approvalRequestMethodCode
      approvalAgencyIdentifier
      salesKindDivisionCode
      requestWithdrawalUsdAmount
      beforeRequestWithdrawalUsdAmount
      realWithdrawalKrwAmount
      beforeRealWithdrawalKrwAmount
      registeredTerminalNo
      inoutDate
      inoutProcessCode
      inoutProcessSeq
      paymentId
      adminErrorCode
      adminErrorMessage
      refundDate
      refundProcessCode
      refundProcessSeq
      refundPaymentId
      accountInoutYn
      nonInoutSeq
      nonInoutRegisteredDate
      nonInoutRequestYn
      approvalProcessCode
      overseasApprovalUseNo
      _card {
        validateTermYm
      }
      _member {
        userKorName
        userId
      }
    }
  }
`;

export const updateOverseasApprPaymentId = gql`
  mutation updateOverseasApprPaymentId($tradeDate: String, $tradeApprovalSeq: Float, $paymentId: String) {
    updateOverseasApprPaymentId(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq, paymentId: $paymentId) {
      result
    }
  }
`;

export const selectOverseasApprOrigin = gql`
  mutation selectOverseasApprOrigin($cardNo: String, $approvalDate: String, $overseasCardApprovalNo: String) {
    selectOverseasApprOrigin(cardNo: $cardNo, approvalDate: $approvalDate, overseasCardApprovalNo: $overseasCardApprovalNo) {
      tradeDate
      tradeApprovalSeq
      inoutProcessSeq
      paymentId
      approvalProcessStatusCode
    }
  }
`;

export const selectOverseasApprCncl = gql`
  mutation selectOverseasApprCncl($cardNo: String, $approvalDate: String, $overseasCardApprovalNo: String, $cancelAfterUsdBalance: Float) {
    selectOverseasApprCncl(
      cardNo: $cardNo
      approvalDate: $approvalDate
      overseasCardApprovalNo: $overseasCardApprovalNo
      cancelAfterUsdBalance: $cancelAfterUsdBalance
    ) {
      tradeDate
      tradeApprvalSeq
    }
  }
`;

export const updateOverseasApprStatus = gql`
  mutation updateOverseasApprStatus($tradeDate: String, $tradeApprovalSeq: Float) {
    updateOverseasApprStatus(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      result
    }
  }
`;

export const updateOverseasApprRefundInfo = gql`
  mutation updateOverseasApprRefundInfo($tradeDate: String, $tradeApprovalSeq: Float) {
    updateOverseasApprRefundInfo(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      result
    }
  }
`;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import MerchantTable from './MerchantTable';
import MerchantDetail from './MerchantDetail';
import MerchantInfoListToolbar from './MerchantInfoListToolbar';
import { merchantInfoMutation } from '../../lib/apolloClient/mutations';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';
import { MenuTitleToolbar } from '../../components';

import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
}));

type SearchInfo = {
  merchantNo: string;
  merchantBizNo: string;
};

type Props = {
  className?: string;
  merchantDetail: GetMerchantDetail;
  listInfo: MerchantListReturnType;
};
const MerchantList: FC<Props> = ({ className, merchantDetail, listInfo }) => {
  const classes = useStyles();
  const [data, setData] = useState(listInfo);
  const [loading, setLoading] = useState(false); // 가맹점 목록 조회 loading
  const [detailLoading, setDetailLoading] = useState(false); // 상세조회 로딩
  const { showToast } = useToast();

  // 엑셀다운로드
  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  // 목록조회
  const [searchForm, setSearchForm] = useState({
    merchantNo: '',
    merchantBizNo: '',
  });

  // 상세정보
  const [inputDetailMerchantNo, setInputDetailMerchantNo] = useState('');
  const [detailData, setDetailData] = useState(merchantDetail);

  // 데이터 목록 조회 로직
  const fetch = async () => {
    console.log('목록조회');

    // 검색어 입력필수
    if (utils.isNullOrBlank(searchForm.merchantNo) && utils.isNullOrBlank(searchForm.merchantBizNo)) {
      showToast('검색어 입력은 필수입니다.', 'error');
      return;
    }

    // 상세조회 초기화
    setInputDetailMerchantNo('');

    // 이미 로딩 중이면 리턴
    if (loading) {
      return;
    }

    if (!isExcelDown) {
      setLoading(true);
    }

    await apolloClient
      .mutate({
        // merchantNo: 가맹점번호 또는 가맹점명
        // merchantBizNo: 가맹점사업자등록번호
        variables: {
          merchantNo: searchForm.merchantNo,
          merchantBizNo: searchForm.merchantBizNo,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: isExcelDown,
        },
        mutation: merchantInfoMutation.selectMerchantInfoListPaging,
      })
      .then(res => {
        console.log('목록 조회 결과 : ', res.data.selectMerchantInfoListPaging);
        if (isExcelDown) {
          setExcelDown(false);
          if (Number(res.data.selectMerchantInfoListPaging.totalCount) > 100000) {
            showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
          } else {
            excelDown(res.data.selectMerchantInfoListPaging.list);
          }
        } else {
          setData(res.data.selectMerchantInfoListPaging);
        }
      })
      .catch(console.error);

    setLoading(false);
  };

  // 데이터 상세 조회 로직
  useEffect(() => {
    // 가맹점 상세조회 초기화
    setDetailData(merchantDetail);

    if (inputDetailMerchantNo !== '') {
      const fetch = async () => {
        setDetailLoading(true);

        await apolloClient
          .mutate({
            variables: { type: inputDetailMerchantNo },
            mutation: merchantInfoMutation.selectMerchantInfo,
          })
          .then(res => {
            console.log('상세정보 조회 결과 : ', res.data.selectMerchantInfo);
            setDetailData(res.data.selectMerchantInfo);
          })
          .catch(console.error);

        setDetailLoading(false);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDetailMerchantNo]);

  // 페이징
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, isFlag]);

  // 엑셀다운로드 버튼 클릭
  const excelClick = () => {
    // 엑셀다운로드 시 검색어 입력필수
    if (utils.isNullOrBlank(searchForm.merchantNo) && utils.isNullOrBlank(searchForm.merchantBizNo)) {
      showToast('엑셀 다운로드 시 검색어 입력은 필수입니다.', 'error');
      return;
    }

    if (Number(data.totalCount) > 100000) {
      showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
      return;
    }

    setFlag(!isFlag);
    setExcelDown(true);
  };

  const excelDown = (csvData: GetMerchantDetail[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        가맹점번호: `${csvData[i].merchantNo}`,
        가맹점명: `${csvData[i].merchantName}`,
        사업자등록번호: `${chaiStringUtils.bizNoFormat(csvData[i].merchantBizNo)}`,
        대표자명: `${csvData[i].officerName}`,
        전화번호: `${csvData[i].telNo1}  ${csvData[i].telNo2} ${csvData[i].telNo3}`,
        주소: `(${chaiStringUtils.nvlDefault(csvData[i].merchantPostNo)}) ${csvData[i].merchantRoadAddress01} ${
          csvData[i].merchantRoadAddress02
        }`,
        해지일자: `${chaiStringUtils.dateFormat(csvData[i].terminateDate, null)}`,
        EDC가맹점여부: `${csvData[i].edcMerchantYn}`,
        대금지급주기: `${csvData[i].settlementProcessCycle}일`,
        수수료율: `${csvData[i].feeRate}%`,
        체크카드수수료율: `${csvData[i].cardFeeRate}%`,
        거래정지여부: `${csvData[i].tradeBlockYn}`,
      });
    }

    utils.exportToCSV('가맹점목록', rowData);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="가맹점정보" />
      <MerchantInfoListToolbar
        fetch={fetch}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        paging={paging}
        setPaging={setPaging}
      />
      <div className={classes.content}>
        <div className={classes.registerButton}>
          {isExcelDown ? <span style={{ marginRight: 20 }}>Loading...</span> : ''}
          <Button
            variant="contained"
            color="primary"
            onClick={event => {
              excelClick();
            }}
          >
            Excel
          </Button>
        </div>

        <br />
        <br />
        <br />

        {loading ? (
          utils.Loading()
        ) : (
          <MerchantTable
            merchantList={data}
            setInputDetailMerchantNo={setInputDetailMerchantNo}
            paging={paging}
            setPaging={setPaging}
          />
        )}
        <br />
        {detailLoading ? utils.Loading() : <MerchantDetail merchantInfo={detailData} />}
      </div>
    </div>
  );
};

export default MerchantList;

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';

import OverseasCalculateToolbar from './OverseasCalculateToolbar';
import OverseasCalculateTable from './OverseasCalculateTable';
import OverseasCalculateExchangeRateToolbar from './OverseasCalculateExchangeRateToolbar';
import OverseasSettleExchangeRateTable from './OverseasSettleExchangeRateTable';

import { overseasCalculateMutation } from '../../lib/apolloClient/mutations';

import {format} from 'date-fns';
import apolloClient from '../../lib/apolloClient';
import { useToast } from '../../contexts/Toast';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    registerButton: {
        margin: theme.spacing(1), 
        marginTop: theme.spacing(3),
        float: "right"
      }
}));

type SearchInfo = {
    settlementDate:string,        // 정산일자
}



const OverseasCalculate = () => {
    const classes = useStyles();
    const { showToast } = useToast();
    const[calculateBreakdownList,setCalculateBreakdownList] = useState([] as overseasCalculateBreakdownList[]);

    // 검색조건
    const [searchForm, setSearchForm] = useState({
        settlementDate: format(new Date(), 'yyyy-MM-dd')  // 정산일자
    });

    const [exchangeRateForm, setExchangeRateForm] = useState({
        calculateExchangeRate: 0 // 정산환율   
    });

    useEffect(() => {
        onSearch(searchForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 해외정산 조회
    const onSearch = async (searchForm: SearchInfo) => {

        await apolloClient.mutate({
            variables:{
                settlementDate: searchForm.settlementDate
            },
            mutation: overseasCalculateMutation.overseasCalculateBreakdown
        }).then(res => {
            for(let index = 0; index < res.data.overseasCalculateBreakdownList.length ; index++){
                const element = res.data.overseasCalculateBreakdownList[index];
                switch (element.accountDivisionCode) {
                        case '250':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '가맹점대금 정산금액(250)'
                            break;
                        case '251':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '신판금액(251)'
                            break;
                        case '252':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '현금금액(252)'
                            break;
                        case '450':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '발급사보존수수료(450)'
                            break;
                        case '451':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = 'ATM출금수수료(451)'
                            break;
                        case '452':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '해외이용수수료(452)'
                            break;
                        case '453':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '현금수수료(453)'
                            break;
                        case '454':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '매입기준_해외원화환차(454)'
                            break;
                        case '455':
                            res.data.overseasCalculateBreakdownList[index].accountDivisionCode = '정산기준_해외원화환차(455)'
                            break;                                                   
                        default:
                            break;
                }
                
            }
            
            setCalculateBreakdownList(res.data.overseasCalculateBreakdownList)
        }).catch(console.error);
    }



    const[exchangeRate,setExchangeRate] = useState(0);
    const calculateExchangeRateSearch = async(exchangeRateForm: CalculateExchangeRateSearchInfo) => {

        if(calculateBreakdownList[0]){
            if(exchangeRateForm.calculateExchangeRate){

                for (let index = 0; index < calculateBreakdownList.length; index++) {

                    const element = calculateBreakdownList[index];

                    // 1. 정산환율적용금액 = 정산미화금액거래총액 * 정산환율
                    const exchageRateData = Math.floor((element.settlementUsdTradeTotalAmount)*(exchangeRateForm.calculateExchangeRate));

                    if(element.accountDivisionCode === '250'){
                        setExchangeRate(exchageRateData);
                    }
                    
                    // 2. 정산환율차이총액 = 정산원화금액거래총액 - 정산환율적용금액
                    const settlementExchangeRateDiffTotalAmount = Math.floor(element.settlementKrwTradeTotalAmount - exchageRateData);

                    // 3. 정산처리상태코드 2 : 정산금액최종확정 및 정산환율적용금액, 정산환율차이금액 업데이트
                    await apolloClient.mutate({
                        variables: {input : {
                            aggregationDate: element.aggregationDate
                            ,accountDivisionCode: element.accountDivisionCode
                            ,accountDepartmentIdentifier: element.accountDepartmentIdentifier
                            ,cardTypeCode: element.cardTypeCode
                            ,settlementExchangeRateDiffTotalAmount: settlementExchangeRateDiffTotalAmount
                            ,settlementExchangeRateApplyAmount: exchageRateData
                            ,settlementBaseExchangeRate: parseFloat(exchangeRateForm.calculateExchangeRate)
                     }
                         },
                     mutation: overseasCalculateMutation.updateOverseasSettlementAggregationHistory
                    }).then(res => {     
                        console.log(res.data);                    
                    });
                }
                onSearch(searchForm);
                


            } else{
                showToast ('정산환율을 입력해주세요.','error');
            }
        }else {
            console.log('<<<error');
        }
    }
    return (
        <div className={classes.root}>
            <MenuTitleToolbar title="해외정산조회"/>
            <OverseasCalculateToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm}/>
            <div className={classes.content}>
                <OverseasCalculateTable title="해외정산내역" calculateBreakdownList={calculateBreakdownList} />
            </div>
            <div className={classes.content}>
                <OverseasCalculateExchangeRateToolbar calculateBreakdownList={calculateBreakdownList} calculateExchangeRateSearch={calculateExchangeRateSearch} exchangeRateForm={exchangeRateForm} setExchangeRateForm={setExchangeRateForm}/>
            </div>
            <div className={classes.content}>
              <OverseasSettleExchangeRateTable exchangeRate={exchangeRate}/>
            </div>
        </div>
    )
}

export default OverseasCalculate;
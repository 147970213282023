import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee'
  }
}));

type Props = {
  popInfo : any,
  isPopYn : boolean,
  setIsPopYn : React.Dispatch<React.SetStateAction<boolean>>
}

const OverseasPurchasedHistoryPop : FC<Props> = ({popInfo, isPopYn, setIsPopYn}) => {

  const classes = useStyles();

  const handleClose = () => {
    setIsPopYn(false);
  };

  
  return(
    <Dialog
      open={isPopYn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle><MenuTitleToolbar title="해외매입내역 상세조회" /></DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={classes.thStyle}>수신기준일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.receveBaseDate)}</TableCell>
                <TableCell className={classes.thStyle}>수신일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.receveSeq}</TableCell>
                <TableCell className={classes.thStyle}>해외처리일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.overseasPocessDate)}</TableCell>
                <TableCell className={classes.thStyle}>전송일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.sendDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>계정구분</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('accountDivisionCode', popInfo.accountDivisionCode)}({popInfo.accountDivisionCode})</TableCell>
                <TableCell className={classes.thStyle}>매출종류</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('salesKindDivisionCode', popInfo.salesKindDivisionCode)}({popInfo.salesKindDivisionCode})</TableCell>
                <TableCell className={classes.thStyle}>BC판매구분</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.bcSalesDivisionCode)}</TableCell>
                <TableCell className={classes.thStyle}>매입접수번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.purchasedProcessNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>카드번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.cardNoMasked}</TableCell>
                <TableCell className={classes.thStyle}>최초카드번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.firstCardNoMasked}</TableCell>
                <TableCell className={classes.thStyle}>체크카드회원번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.cardMemberNo}</TableCell>
                <TableCell className={classes.thStyle}>개인법인구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.personalCorporationDivisionCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>카드구분</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.cardTypeCode}</TableCell>
                <TableCell className={classes.thStyle}>카드상품코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.cardProductCode}</TableCell>
                <TableCell className={classes.thStyle}>브랜드구분</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.brandDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>매출일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.salesDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>가맹점국가코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.merchantNationCode}</TableCell>
                <TableCell className={classes.thStyle}>영문가맹점명</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.merchantEngName}</TableCell>
                <TableCell className={classes.thStyle}>해외신판미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCdslUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>해외신판수수료미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCdslFeeUsdAmount)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>해외신판수수료원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCdslFeeKrwAmount)}</TableCell>
                <TableCell className={classes.thStyle}>해외현금미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCashUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>해외현금수수료미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCashFeeUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>해외현금수수료원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasCashFeeKrwAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>해외정산미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasSettlementUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>해외정산미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasSettlementKrwAmount)}</TableCell>
                <TableCell className={classes.thStyle}>승인적용환율</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.approvalApplyExchangeRate}</TableCell>
                <TableCell className={classes.thStyle}>매입적용환율</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.purchasedApplyExchangeRate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>매매기준환율</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.dealBaseExchangeRate}</TableCell>
                <TableCell className={classes.thStyle}>청구일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.billDate)}</TableCell>
                <TableCell className={classes.thStyle}>리버설여부</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.reversalYn}</TableCell>
                <TableCell className={classes.thStyle}>해외거래원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasTradeKrwAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>현지거래통화코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.localTradeCurrencyCode}</TableCell>
                <TableCell className={classes.thStyle}>MCC가맹점코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.mccMerchantCode}</TableCell>
                <TableCell className={classes.thStyle}>해외BC매입일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.overseasBcPurchasedSeq}</TableCell>
                <TableCell className={classes.thStyle}>ONUS구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.onusDivisionCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>리볼빙구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.revolvingDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>리볼빙번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.revolvingNo}</TableCell>
                <TableCell className={classes.thStyle}>현지거래금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.localTradeAmount)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점도시명</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.merchantCityName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>승인일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.approvalDate)}</TableCell>
                <TableCell className={classes.thStyle}>해외카드승인번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.overseasCardApprovalNo}</TableCell>
                <TableCell className={classes.thStyle}>거래승인일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.tradeApprovalSeq}</TableCell>
                <TableCell className={classes.thStyle}>환율손익구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.exchangeRatePflsDivisionCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>거래일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(popInfo.tradeDate)}</TableCell>
                <TableCell className={classes.thStyle}>체크매출구분</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.checkSalesDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>정부구매카드구분</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.governmentPurchaseCardDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>전문브랜드미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.textBrandUsdFee)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>매입브랜드미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedBrandUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>매입브랜드원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedBrandKrwFee)}</TableCell>
                <TableCell className={classes.thStyle}>매입해외이용미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedOverseasUseUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>매입해외이용원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedOverseasUseKrwFee)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>매입해외출금미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedOverseasWithdrawalUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>매입해외출금원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedOverseasWithdrawalKrwFee)}</TableCell>
                <TableCell className={classes.thStyle}>승인브랜드미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalBrandUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>승인브랜드원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalBrandKrwFee)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>승인브랜드미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalOverseasUseUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>승인해외이용원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalOverseasUseKrwFee)}</TableCell>
                <TableCell className={classes.thStyle}>승인해외출금미화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalOverseasWithdrawalUsdFee)}</TableCell>
                <TableCell className={classes.thStyle}>승인해외출금원화수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalOverseasWithdrawalKrwFee)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>승인요청인출미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalRequestWithdrawalUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>승인실인출원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalRealWithdrawalKrwAmount)}</TableCell>
                <TableCell className={classes.thStyle}>원매입접수번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.originReceveNo}</TableCell>
                <TableCell className={classes.thStyle}>무승인매입구분</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.noApprovalPurchasedDivisionCode}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell className={classes.thStyle}>매입처리구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.purchasedProcessDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>강제매입여부</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.forcedPurchasedYn}</TableCell>
                <TableCell className={classes.thStyle}>강제매입직원번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.forcedPurchasedAdminId}</TableCell>
                <TableCell className={classes.thStyle}>강제매입거래코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.forcedPurchasedTradeCode}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell className={classes.thStyle}>원승인원장존재여부</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.originApprovalLedgerExistYn}</TableCell>
                <TableCell className={classes.thStyle}>현지차이금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.localDiffAmount)}</TableCell>
                <TableCell className={classes.thStyle}>매입미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>매입원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedKrwAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>인출미화차이금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.withdrawalUsdDiffAmount)}</TableCell>
                <TableCell className={classes.thStyle}>인출원화차이금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.withdrawalKrwDiffAmount)}</TableCell>
                <TableCell className={classes.thStyle}>계좌부서식별자</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.accountDepartmentIdentifier}</TableCell>
                <TableCell className={classes.thStyle}>입출금처리코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.inoutProcessCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>Payment Id</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.paymentId}</TableCell>
                <TableCell className={classes.thStyle}>입출금처리일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.inoutProcessSeq}</TableCell>
                <TableCell className={classes.thStyle}>입출미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.inoutUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>입출원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.inoutKrwAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>계정처리순번</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.accountProcessSeq}</TableCell>
                <TableCell className={classes.thStyle}>자료처리구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.dataProcessDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>해외매입에러사유</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.overseasPurchasedErrorReason}</TableCell>
                <TableCell className={classes.thStyle}>매입환율차이금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedExchangeRateDiffAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>브랜드수수료보정미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.brandFeeCorrectUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>브랜드수수료보정원화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.brandFeeCorrectKrwAmount)}</TableCell>
                <TableCell className={classes.thStyle}>거래유형코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.tradeProcessCode}</TableCell>
                <TableCell className={classes.thStyle}>조정처리상태구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.adjustProcessStatusDivisionCode}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell className={classes.thStyle}>조정처리사유</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.adjustProcessReason}</TableCell>
                <TableCell className={classes.thStyle}>조정처리거래코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.adjustProcessTradeCode}</TableCell>
                <TableCell className={classes.thStyle}>매입미화원금금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.purchasedUsdOriginAmount)}</TableCell>
                <TableCell className={classes.thStyle}>승인요청미화원금금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.approvalRequestUsdOriginAmount)}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell className={classes.thStyle}>해외이용수수료기준미화금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(popInfo.overseasUseFeeBaseUsdAmount)}</TableCell>
                <TableCell className={classes.thStyle}>최초조작직원번호</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.firstOperateAdminId}</TableCell>
                <TableCell className={classes.thStyle}>최초조작상세일시</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.firstOperateDetailDate}</TableCell>
                <TableCell className={classes.thStyle}>최종조작상세일시</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.lastOperateAdminId}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell className={classes.thStyle}>최종조작거래코드</TableCell>
                <TableCell className={classes.tdStyle}>{popInfo.lastOperateDetailDate}</TableCell>
              </TableRow> 
            </TableBody>
          </Table>
          <Divider />
        </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">닫기</Button>
      </DialogActions>
    </Dialog>  
  );

};

export default OverseasPurchasedHistoryPop;
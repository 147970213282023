import { Button, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import apolloClient from '../../lib/apolloClient';
import { approvalListMutation } from '../../lib/apolloClient/mutations';
import ApprovalListToolbar from './ApprovalListToolbar';
import ApprovalPdf from './ApprovalPdf';
import ApprovalTable from './ApprovalTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type MatchParams = { searchDt: string; paymentId: string };
const ApprovalList: React.SFC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { searchDt, paymentId } = match.params;
  let startDate = new Date();
  if (searchDt) {
    const startDt = searchDt.replace(/[^0-9]/g, '');
    startDate = new Date(
      Number(startDt.substring(0, 4)),
      Number(startDt.substring(4, 6)) - 1,
      Number(startDt.substring(6, 8))
    );
  }

  const classes = useStyles();
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchForm, setSearchForm] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDt: format(startDate, 'yyyy-MM-dd'),
    endDt: format(startDate, 'yyyy-MM-dd'),
    salesKindDivisionCode: '',
    approvalProcessStatusCode: '',
    cardApprovalNo: '',
    paymentId: paymentId ? paymentId : '',
    adminErrorCode: '',
  });
  // const [loading, setLoading] = useState(true);
  const [refundCount, setRefundCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isPdfDown, setPdfDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      // 이미 로딩 중이면 리턴
      //if(loading) {
      //  return;
      //}

      // setLoading(true);
      console.log('실행!');
      await apolloClient
        .mutate({
          variables: {
            input: {
              searchType: searchForm.searchType,
              searchValue: searchForm.searchValue,
              startDt: searchForm.startDt,
              endDt: searchForm.endDt,
              salesKindDivisionCode: searchForm.salesKindDivisionCode,
              approvalProcessStatusCode: searchForm.approvalProcessStatusCode,
              cardApprovalNo: searchForm.cardApprovalNo,
              paymentId: searchForm.paymentId,
              adminErrorCode: searchForm.adminErrorCode,
              page: paging.page,
              rowsPerPage: paging.rowsPerPage,
              isExcelDown: isExcelDown,
              isPdfDown: isPdfDown,
            },
          },
          mutation: approvalListMutation.selectApprovalList,
        })
        .then(res => {
          // console.log('결과: ',res.data.selectApprovalList);
          if (isExcelDown) {
            setExcelDown(false);
            excelDown(res.data.selectApprovalList.list);
          } else if (isPdfDown) {
            setPdfDown(false);
            pdfDown(res.data.selectApprovalList.list);
          } else {
            setData(res.data.selectApprovalList.list);
            setTotalCount(res.data.selectApprovalList.totalCount); // totalCount 세팅
          }
        })
        .catch(console.error);

      // setLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm, refundCount, paging, isFlag]);

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  };

  const pdfClick = () => {
    setFlag(!isFlag);
    setPdfDown(true);
  };

  const displayProcessStatus = (processStatusCode: string) => {
    if (processStatusCode === '1') return '정상';
    else if (processStatusCode === '2') return '오류';
    else return '처리중';
  };

  const displayPdfButton = () => {
    if (searchForm.searchType === '2' && searchForm.searchValue) {
      return (
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 10, marginBottom: 10 }}
          onClick={event => {
            pdfClick();
          }}
        >
          PDF
        </Button>
      );
    } else return;
  };

  const excelDown = (csvData: ApprovalInfo[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        카드승인번호: `${csvData[i].cardApprovalNo}`,
        머니승인번호: `${csvData[i].paymentId}`,
        승인일시: `${
          chaiStringUtils.dateFormatDefault(csvData[i].approvalDate) +
          ' ' +
          chaiStringUtils.dateTimeFormat(csvData[i].approvalTime)
        }`,
        거래일자: `${chaiStringUtils.dateFormatDefault(csvData[i].tradeDate)}`,
        고객번호: `${csvData[i]._member.userId}`,
        성명: `${csvData[i]._member.userKorName}`,
        카드번호: `${csvData[i].cardNoMasked}`,
        가맹점명: `${csvData[i].merchantName}`,
        매출종류: `${chaiStringUtils.codeName('salesKindDivisionCode', csvData[i].salesKindDivisionCode)}(${
          csvData[i].salesKindDivisionCode
        })`,
        승인금액: `${chaiStringUtils.moneyFormat(csvData[i].cardApprovalAmount)}`,
        처리구분: `${displayProcessStatus(csvData[i].approvalProcessStatusCode)}`,
        매입여부: `${csvData[i].slipPurchaseYn === 'Y' ? '매입' : ''}`,
      });
    }

    utils.exportToCSV('국내승인내역', rowData);
  };

  const pdfDown = (pdfList: ApprovalInfo[]) => {
    const doc = <ApprovalPdf pdfList={pdfList} searchInfo={searchForm} />;
    let fileName = '차이카드_증빙용 거래내역';
    if (pdfList.length > 0) {
      fileName = fileName + '_' + pdfList[0]._member.userKorName;
    }
    utils.exportToPDF(fileName, doc);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내승인내역" />
      <ApprovalListToolbar paging={paging} setPaging={setPaging} searchInfo={searchForm} onSearch={setSearchForm} />
      <div className={classes.content}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto', marginBottom: 10 }}
          onClick={event => {
            excelClick();
          }}
        >
          Excel
        </Button>
        {displayPdfButton()}
        <ApprovalTable
          paging={paging}
          setPaging={setPaging}
          totalCount={totalCount}
          refundCount={refundCount}
          setRefundCount={setRefundCount}
          list={data}
        />
      </div>
    </div>
  );
};

export default ApprovalList;

import React, { FC, useState } from 'react';
import { Card, CardContent, Grid, Button, TextField } from '@material-ui/core';

import { getCardUserInfo, getCardUserChangeInfo, getCardList } from '../../lib/cardApi'
import { useToast } from '../../contexts/Toast';

import chaiStringUtils from '../../common/chaiStringUtils.js';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));
**/

type Props = {
  className?: string,
 // sNm: string,
 // sVl: string,

  setInputSearchGubun: React.Dispatch<React.SetStateAction<string>>,
  setInputSearchVal: React.Dispatch<React.SetStateAction<string>>

  //회원정보
  cardUserInfo: GetCardUserInfoReturn,
  setCardUserInfo: React.Dispatch<React.SetStateAction<GetCardUserInfoReturn>>,
  
  //회원정보변경이력목록
  changeInfo: CardUserChangeInfo[],
  setChangeInfo: React.Dispatch<React.SetStateAction<CardUserChangeInfo[]>>,

  //카드리스트
  cards: CardInfo[],
  setCards: React.Dispatch<React.SetStateAction<CardInfo[]>>,

  //회원정보 노출여부
  isAccountDetailsVisible: boolean,
  setIsAccountDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>,

  //카드상세정보 노출여부
  isCardDetailsVisible: boolean,
  setIsCardDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>,
}

//searchName : default값 = csno
const UsersToolbar: FC<Props> = ({ className, /*sNm='csno', sVl='',*/ setInputSearchGubun, setInputSearchVal, cardUserInfo, setCardUserInfo, changeInfo, setChangeInfo, setCards, isAccountDetailsVisible, setIsAccountDetailsVisible, isCardDetailsVisible, setIsCardDetailsVisible }) => {
  // const classes = useStyles();
  const { showToast } = useToast();

  //const [searchName, setSearchName] = useState(sNm);
  //const [searchValue, setSearchValue] = useState(sVl);

  const [searchGubun, setSearchGubun] = useState(chaiStringUtils.getSchParamGubun());
  const [searchVal, setSearchVal] = useState(chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun())); // 조회 값

  //input name setting
  const selectChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    //setSearchName(event.target.value);
    setSearchGubun(event.target.value as string);
  };

  //input value setting
  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {         
    //setSearchValue(event.target.value); 
    setSearchVal(event.target.value);  
  };

  const appKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      console.log('enter click');
      onclick();
    }
  }

  const viewReset = () => {
    console.log('viewReset 진입');
    setIsAccountDetailsVisible(false);
    setIsCardDetailsVisible(false);
  }

  const onclick = async() => {
    console.log('onclick start')

    setInputSearchGubun(searchGubun);  // 조회 구분
    setInputSearchVal(searchVal);  // 조회 값
    chaiStringUtils.setSchParamGubun(searchGubun);
    chaiStringUtils.setSchParamSearchVal(searchGubun, searchVal);
  
    try{
      //화면 초기화
      viewReset();

      setDetail(searchGubun);
    } catch (e) {
      showToast(e.message, 'error')
    }
  }

  const setDetail = async (searchGubun: string) => {
    try {
      /** 어떤 값이 들어왔는지 확인하기-! */
      console.log(searchGubun+'='+searchVal);

      if(searchGubun === '3') {
        //회원정보조회
        const cardUserInfoRes = await getCardUserInfo({ csno : searchVal });
        setCardUserInfo({
          ...cardUserInfo,
          ...cardUserInfoRes
        })

        //회원정보변경이력목록
        const changeInfoRes = await getCardUserChangeInfo({ cardMemberNo : cardUserInfoRes.cardMemberNo});
        setChangeInfo(changeInfoRes);

        /** isAccountDetailsVisible: 회원정보 노출 여부 */
        setIsAccountDetailsVisible(true); //회원정보 조회완료 되었으니 true로 변경처리-!

        try{
          //보유카드목록조회
          const cardsRes = await getCardList({ type: 'list', csno: searchVal });
          setCards(cardsRes);
        }catch(e){
          showToast(e.message, 'error');
          setCards([]);
        }
      } else if(searchGubun === '2') {        
        //회원정보조회
        const cardUserInfoRes = await getCardUserInfo({ cardNo: chaiStringUtils.encrypt(searchVal) });
        setCardUserInfo({
          ...cardUserInfo,
          ...cardUserInfoRes
        })

        //회원정보변경이력목록
        const changeInfoRes = await getCardUserChangeInfo({ cardMemberNo : cardUserInfoRes.cardMemberNo});
        setChangeInfo(changeInfoRes);

        setIsAccountDetailsVisible(true); //회원정보 조회완료 되었으니 true로 변경처리-!

        try{
          //보유카드목록조회
          const cardsRes = await getCardList({ type: 'list', cardNo: chaiStringUtils.encrypt(searchVal) });
          setCards(cardsRes);
        }catch(e){
          showToast(e.message, 'error');
          setCards([]);
        }        
      } else if(searchGubun === '1') {
        console.log('hpNo')
        //회원정보조회
        const cardUserInfoRes = await getCardUserInfo({ hpNo: searchVal });        
        setCardUserInfo({
          ...cardUserInfo,
          ...cardUserInfoRes
        })

        //회원정보변경이력목록
        const changeInfoRes = await getCardUserChangeInfo({ cardMemberNo : cardUserInfoRes.cardMemberNo});
        setChangeInfo(changeInfoRes);
        
        setIsAccountDetailsVisible(true); //회원정보 조회완료 되었으니 true로 변경처리-!

        console.log('csno:::'+cardUserInfoRes.csno);
        try{
          //보유카드목록조회
          const cardsRes = await getCardList({ type: 'list', csno: cardUserInfoRes.csno });
          setCards(Object.values(cardsRes));
        }catch(e){
          showToast(e.message, 'error');
          setCards([]);
        }
      } else {
        console.log('error');
      }
      

    } catch (e) {
      showToast(e.message, 'error')
    }
  };  

  return (    
    <Card>
      <form
        autoComplete="off"
        noValidate
        onSubmit={(e) => {e.preventDefault();}} //EnterKey 입력 시, 자동 Submit막기-!
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >            
                        
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="조회구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchGubun"         
                value={searchGubun}       
                onChange={selectChange}
              >
                <option value="1">휴대폰번호</option>
                <option value="2">카드번호</option>
                <option value="3">고객번호</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={6}
            >
              <TextField
                fullWidth
                label="조회 값"
                placeholder="조회 값"
                margin="dense"   
                InputLabelProps={{
                  shrink: true,
                }}             
                variant="outlined"
                name="searchVal"
                value={searchVal}                
                onChange={inputChange}
                onKeyPress={appKeyPress}
              />                            
            </Grid>            
            <Grid
              item
              md={1}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onclick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>              
            </Grid>
            <Grid
              item
              md={1}
              xs={6}
            >
              <Button
                variant="contained"
                onClick={viewReset}
                style = {{
                  marginTop: '8px'
                }}
              >
                Reset 
              </Button>
            </Grid>
          </Grid>  
          <Grid
            container
            spacing={1}
          >
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default UsersToolbar;

import React, { FC, useState, useEffect } from 'react';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { batchInfoMutation } from '../../lib/apolloClient/mutations';
import BatchProcessHistoryListToolbar from './BatchProcessHistoryListToolbar';
import BatchProcessHistoryTable from './BatchProcessHistoryTable';
import {format, subMonths} from 'date-fns';

type Props = {
  batchSeqNo: number,
  batchProcessHistoryOpen: boolean,
  setBatchProcessHistoryOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const BatchProcessHistory: FC<Props> = ({ batchSeqNo, batchProcessHistoryOpen, setBatchProcessHistoryOpen }) => {
  const default_page = 0;
  const default_rowsPerPage = 100;
  // const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [batchProcessHistorySearch, setBatchProcessHistorySearch] = useState({
    fromDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    processStatus: '',
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          batchSeqNo: batchSeqNo, 
          fromDate: batchProcessHistorySearch.fromDate.replace(/-/g,''), 
          endDate: batchProcessHistorySearch.endDate.replace(/-/g,''), 
          processStatus: batchProcessHistorySearch.processStatus, 
          page: paging.page,
          rowsPerPage: paging.rowsPerPage } },
        mutation: batchInfoMutation.selectBatchProcessHistoryList
      }).then(res => {     
        setData(res.data.selectBatchProcessHistoryList.list);
        setTotalCount(res.data.selectBatchProcessHistoryList.totalCount);
      }).catch(e => {
        console.log('error', e);
      });

      // setLoading(false)

    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchSeqNo, batchProcessHistorySearch, paging]);

  const handleBatchProcessHistoryClose = () => {
    setBatchProcessHistoryOpen(false);
    setBatchProcessHistorySearch({
      ...batchProcessHistorySearch,
      fromDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      processStatus: ''
    });
    setPaging({
      ...paging,
      page: default_page,
      rowsPerPage : default_rowsPerPage
    })
  };

  return (
    <Dialog
      open={batchProcessHistoryOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle id="alert-dialog-title">배치처리 내역</DialogTitle>
      <DialogContent>
        <div>
          <div>
            <BatchProcessHistoryListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setBatchProcessHistorySearch}/>  
          </div>
          <div>
            <BatchProcessHistoryTable paging={paging} setPaging={setPaging} totalCount={totalCount} batchlist={data}/>
          </div>
        </div>   
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBatchProcessHistoryClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BatchProcessHistory;

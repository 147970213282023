export function getOverseasFeeCodeName(code) {
  switch (code) {
    case '001':
      return 'ATM출금수수료';
    case '002':
      return 'ATM조회수수료';
    case '003':
      return '해외이용수수료';
    case '004':
      return '브랜드수수료';
    case '005':
      return '해외이용수수료(ATM)';
    case '006':
      return 'ATM브랜드수수료';
    default:
      return code;
  }
}

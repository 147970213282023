import gql from 'graphql-tag';

export const selectAccountProcAggr = gql`
  mutation selectAccountProcAggr($searchGubun: String, $searchDate: String, $searchAccountNo: String, $searchAccountingProcessDivisionCode: String) {
    selectAccountProcAggr(
      searchGubun: $searchGubun
      searchDate: $searchDate
      searchAccountNo: $searchAccountNo
      searchAccountingProcessDivisionCode: $searchAccountingProcessDivisionCode
    ) {
      accountProcessDate
      accountNo
      accountingProcessDivisionCode
      inCount
      inAmount
      outCount
      outAmount
      totalCount
      totalAmount
    }
  }
`;

export const selectAccountProcAggrDetailList = gql`
  mutation selectAccountProcAggrDetailList($accountProcessDate: String, $accountNo: String, $inoutDivisionCode: String) {
    selectAccountProcAggrDetailList(accountProcessDate: $accountProcessDate, accountNo: $accountNo, inoutDivisionCode: $inoutDivisionCode) {
      accountProcessSeq
      accountProcessDetailSeq
      accountDivisionCode
      inoutDivisionCode
      accountProcessDate
      accountProcessTime
      inoutAmount
      cardNo
      cardNoMasked
      accountingProcessDivisionCode
      merchantName
      accountNo
      paymentId
      summaryType
      slipNo
      processCancelYn
    }
  }
`;

import React, {FC, useState} from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import {format} from 'date-fns';


type SearchInfo = {
    searchDate : string;
}

type Props = {
    onSearch : React.Dispatch<React.SetStateAction<SearchInfo>>
}

const OverseasSettlementAggrListToolBar : FC<Props> = ({onSearch}) => {

    const [searchForm, setSearchForm] = useState({
        searchDate : format(new Date(), 'yyyy-MM-dd')
    });

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm, 
            [e.target.name] : e.target.value
        });
    };

    const onKeyEnter = (e : React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter"){
            onSearchClick();
        }
    };

    const onSearchClick = async() => {
        onSearch(searchForm);
    };

    return (
        <Card>
            <form autoComplete="off" noValidate >
                <CardContent>
                    <Grid container spacing={3} >
                        <Grid item md={2} xs={6}>
                            <TextField required fullWidth type="date" variant="outlined" margin="dense" 
                            label="정산기준일자"InputLabelProps={{ shrink: true }} 
                            name="searchDate" value={searchForm.searchDate}
                            onChange={handleChange} onKeyPress={onKeyEnter} /> 
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Button color="primary" variant="outlined" style = {{marginTop : '8px'}}
                            onClick={onSearchClick}> Search </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </form>
        </Card>
    );
};

export default OverseasSettlementAggrListToolBar;
import React, { FC } from 'react';
import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { getOverseasFeeCodeName } from '../../helpers/index.js';

type Props = {
  title: string,
  historyList: overseasFeeBaseHistoryList,
  pagingHistory: Paging,
  setPagingHistory: React.Dispatch<React.SetStateAction<Paging>>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

const CardOverseaFeeBaseHistory : FC<Props> = ({ title, historyList, pagingHistory, setPagingHistory}) => {
    const classes = useStyles();

    // 페이징
    let list = (historyList === undefined)? []:historyList["list"];
    let totalCount = (historyList === undefined)? 0:historyList["totalCount"];

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
      setPagingHistory({
        ...pagingHistory,
        page: inPage
      });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPagingHistory({
        ...pagingHistory,
        rowsPerPage: Number(event.target.value)
      });
    };

    return (
      <Card className={classes.content}>
        { !!title &&
          <CardHeader title={title}/>
        }
      <CardContent>
          <PerfectScrollbar>
              <div className={classes.inner}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell align="center">No.</TableCell>
                              <TableCell align="center">변경일자</TableCell>
                              <TableCell align="center">변격시각</TableCell>
                              <TableCell align="center">카드상품그룹</TableCell>
                              <TableCell align="center">카드상품코드</TableCell>
                              <TableCell align="center">브랜드구분</TableCell>
                              <TableCell align="center">해외수수료구분</TableCell>
                              <TableCell align="center">수수료적용일자</TableCell>
                              <TableCell align="center">수수료적용구분</TableCell>
                              <TableCell align="center">기본수수료값</TableCell>
                              <TableCell align="center">사용여부</TableCell>
                              <TableCell align="center">면제여부</TableCell>
                              <TableCell align="center">수정자</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {list.map((item, index)=>(
                          <TableRow>
                              <TableCell align="center">{(pagingHistory.rowsPerPage * (pagingHistory.page + 1)) - (pagingHistory.rowsPerPage - (index + 1))}</TableCell>
                              <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.changeDate)}</TableCell>
                              <TableCell align="center">{chaiStringUtils.dateTimeFormat(item.changeTime)}</TableCell>

                              <TableCell align="center">{item.cardProductGroupCode }</TableCell>
                              <TableCell align="center">{item.cardProductCode }</TableCell>
                              <TableCell align="center">{item.brandDivisionCode}</TableCell>
                              <TableCell align="center">{getOverseasFeeCodeName(item.overseasFeeDivisionCode)}</TableCell>
                              <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.feeApplyDate)}</TableCell>
                              <TableCell align="center">{item.feeApplyDivisionCode === '1' ? '정액($)' : '정률(%)'}</TableCell>
                              <TableCell align="center">{item.feeApplyDivisionCode === '1' ? String(item.basicFeeValue).concat(' $') : String(item.basicFeeValue).concat(' %') }</TableCell>
                              <TableCell align="center">{item.useYn}</TableCell>
                              <TableCell align="center">{item.waivedYn}</TableCell>
                              <TableCell align="center">{item.lastOperateAdminId}</TableCell>
                          </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </div>
          </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={pagingHistory.page}
        rowsPerPage={pagingHistory.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      </CardActions>
  </Card>
    );
}

export default CardOverseaFeeBaseHistory;

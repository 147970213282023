import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  TableHead,
  CardActions,
  TablePagination
} from '@material-ui/core';

import NumberFormat from 'react-number-format';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as util from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  }
}));

type Props = { 
  detailListPop: boolean,
  setDetailListPopOpen: React.Dispatch<React.SetStateAction<boolean>>,
  loading: boolean
  data: any[]
}
const SalesTableDetailPop: FC<Props> = ({ detailListPop, setDetailListPopOpen, loading, data }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleClose = () => {
    setDetailListPopOpen(false);
    setPage(0);
    setRowsPerPage(10);
  };

  // 카드상태코드Map
  const cardStatusCodeMap = new Map<String, String>();
  cardStatusCodeMap.set('0', '정지(카드배송)');
  cardStatusCodeMap.set('1', '정상');
  cardStatusCodeMap.set('2', '해지');
  cardStatusCodeMap.set('3', '폐기');

  return (
    <Dialog
      open={detailListPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title">발급집계내역 상세목록</DialogTitle>
        <DialogContent>
          {
            loading
            ? util.Loading()
            : 
              <Card>
                <CardContent className={classes.content}>
                  <PerfectScrollbar>
                    <div className={classes.inner}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">No.</TableCell>
                            <TableCell align="center">구분</TableCell>
                            <TableCell align="center">발급/최초승인 일자</TableCell>
                            <TableCell align="center">카드번호</TableCell>
                            <TableCell align="center">상품명</TableCell>
                            <TableCell align="center">유효기간</TableCell>
                            <TableCell align="center">상태</TableCell>
                            <TableCell align="center">해지일자</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                            <TableRow
                              hover
                              key={index}
                            >
                              <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                              <TableCell align="center">{item.gb}</TableCell>
                              <TableCell align="center">
                                {chaiStringUtils.dateFormat(item.tradeDate, 'yyyy.MM.dd')}
                              </TableCell>                              
                              <TableCell align="center">
                                {item.cardNoMasked}
                              </TableCell>
                              <TableCell align="center">{item.prdtName}</TableCell>                              
                              <TableCell align="center">
                                <NumberFormat format = "####/##" value={item.valdTermYm} displayType={'text'}/>    
                              </TableCell>
                              <TableCell align="center">
                                {cardStatusCodeMap.get(item.cardStat)}
                              </TableCell>
                              <TableCell align="center">
                                {chaiStringUtils.dateFormat(item.terminateDate, 'yyyy.MM.dd')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={data.length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </CardActions>
              </Card>
          }
        </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalesTableDetailPop;
import gql from 'graphql-tag';

export const selectAllOfFeeCodeList = gql`
mutation selectAllOfFeeCodeList($input: AllCodeSearchInput) {
    selectAllOfFeeCodeList(input: $input) {
      unitFeeCodeNo
      unitFeeName
      feeClaimMethodDivisionCode
      applyUnitprc
      feeBaseRate
      feeProofDivisionCode
      feeAutoYn
      validateStartDate
      validateEndDate
      deleteYn
      domesticBrandDivisionCode
      proxyFeeCodeNo
      domesticCode
      proxyFeeGroupCode
      proxyFeeNm
      feeInfoDivisionCode
  }
}
`;

export const selectProxyFeeCodeList = gql`
mutation selectProxyFeeCodeList($input: AllCodeSearchInput) {
    selectProxyFeeCodeList (input: $input) {
      proxyFeeCodeNo
      proxyFeeNm
      validateStartDate
      validateEndDate
      deleteYn
      domesticCode
      domesticBrandDivisionCode
      proxyFeeGroupCode
      feeInfoDivisionCode
  }
}
`;

export const selectUnitFeeCodeList = gql`
mutation selectUnitFeeCodeList($input: AllCodeSearchInput) {
    selectUnitFeeCodeList (input: $input) {
      unitFeeCodeNo
      unitFeeName
      feeClaimMethodDivisionCode
      applyUnitprc
      feeBaseRate
      feeProofDivisionCode
      feeAutoYn
      validateStartDate
      validateEndDate
      deleteYn
      domesticBrandDivisionCode
      proxyFeeCodeNo
      domesticCode
      proxyFeeGroupCode
      proxyFeeNm
      feeInfoDivisionCode
  }
}
`;

export const insertProxyFeeCodeInfo = gql`
mutation insertProxyFeeCodeInfo($input: ProxyFeeCodeListInput) {
    insertProxyFeeCodeInfo (input: $input) {
      proxyFeeCodeNo
      proxyFeeNm
      validateStartDate
      validateEndDate
      deleteYn
      domesticCode
      domesticBrandDivisionCode
      proxyFeeGroupCode
      bcFeeRemarkDescription
  }
}
`;

export const insertUnitFeeCodeInfo = gql`
mutation insertUnitFeeCodeInfo($input: ProxyFeeCodeListInput) {
    insertUnitFeeCodeInfo (input: $input) {
      proxyFeeCodeNo
      unitFeeCodeNo
      unitFeeName
      feeClaimMethodDivisionCode
      applyUnitprc
      feeBaseRate
      feeProofDivisionCode
      feeAutoYn
      validateStartDate
      validateEndDate
      deleteYn
      domesticBrandDivisionCode
      dataItemNm
      bcFeeRemarkDescription
  }
}
`;

export const updateProxyFeeCodeInfo = gql`
mutation updateProxyFeeCodeInfo($input: ProxyFeeCodeListInput) {
    updateProxyFeeCodeInfo (input: $input) {
      proxyFeeCodeNo
      proxyFeeNm
      validateStartDate
      validateEndDate
      deleteYn
      domesticCode
      domesticBrandDivisionCode
      proxyFeeGroupCode
  }
}
`;

export const updateUnitFeeCodeInfo = gql`
mutation updateUnitFeeCodeInfo($input: ProxyFeeCodeListInput) {
    updateUnitFeeCodeInfo (input: $input) {
      proxyFeeCodeNo
      unitFeeCodeNo
      unitFeeName
      feeClaimMethodDivisionCode
      applyUnitprc
      feeBaseRate
      feeProofDivisionCode
      feeAutoYn
      validateStartDate
      validateEndDate
      deleteYn
      domesticBrandDivisionCode
      dataItemNm
      bcFeeRemarkDescription
  }
}
`;

export const deleteProxyFeeCodeInfo = gql`
mutation deleteProxyFeeCodeInfo($input: ProxyFeeCodeListInput) {
    deleteProxyFeeCodeInfo (input: $input) {
      deleteYn
      proxyFeeCodeNo
  }
}
`;

export const deleteUnitFeeCodeInfo = gql`
mutation deleteUnitFeeCodeInfo($input: ProxyFeeCodeListInput) {
    deleteUnitFeeCodeInfo (input: $input) {
      deleteYn
      unitFeeCodeNo
  }
}
`;

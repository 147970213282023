import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { list: ApprovalHistoryLogAggrInfo[] }
const ApprovalHistoryLogAggrTable: FC<Props> = ({ list }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">거래일자</TableCell>
                  <TableCell align="center">카드상품명</TableCell>
                  <TableCell align="center">정상건수</TableCell>
                  <TableCell align="center">정상금액</TableCell>
                  <TableCell align="center">취소건수</TableCell>
                  <TableCell align="center">취소금액</TableCell>
                  <TableCell align="center">불능건수</TableCell>
                  <TableCell align="center">불능금액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                  <TableRow
                    hover
                    key={item.tradeDate+''+item.cardProductCode}
                  >
                    <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.tradeDate)}</TableCell>
                    <TableCell align="center">{item.cardProductName}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.approvalCount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.approvalAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.cancelCount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.cancelAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.inabledCount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.inabledAmount)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default ApprovalHistoryLogAggrTable;

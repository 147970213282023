import client from './client';
import { get, orderBy } from 'lodash';

export default async (input: GetCardUserChangeInfoInput): Promise<CardUserChangeInfo[]> => {
  console.log('cardMemberNo:'+input.cardMemberNo);
  
  if (!input.cardMemberNo) {
    throw new Error(`회원정보변경 이력목록 조회 실패 - 입력값이 없습니다`)
  }

  return client.post(`/root/card/cardMemberChangeListSelect`, input).then(res => {
    const cardUserInfoList = get(res, 'data.CardMemberChangeInfo');
    console.log('cardList::'+JSON.stringify(cardUserInfoList));

    if (!cardUserInfoList) {
      throw new Error(`회원정보변경 이력목록 조회 실패`)
    }

    return orderBy(cardUserInfoList, ['changeReceveDate'], ['desc']);
    //throw new Error(`회원정보변경 이력목록 조회 실패`)
  });
}
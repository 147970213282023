import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MenuTitleToolbar } from '../../components';
import { useToast } from '../../contexts/Toast';
import apolloClient, { plccMutation, plccQuery } from '../../lib/apolloClient';
import { PlccMerchantMappingList } from './PlccMerchantMappingList';
import PlccMerchantMappingListToolbar from './PlccMerchantMappingListToolbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: { padding: theme.spacing(4) },
  content: { marginTop: theme.spacing(2) },
  detail: { marginBottom: theme.spacing(3) },
}));

export type PlccMerchantMappingSearchInfo = {
  searchType: string;
  searchValue: string;
};

export const PlccMerchantMapping = () => {
  const classes = useStyles();
  const { showToast } = useToast();
  const [data, setData] = useState<any>({ list: [], totalCount: 0 });
  const [loading, setLoading] = useState(false);

  const [searchForm, setSearchForm] = useState({
    searchType: '1',
    searchValue: '',
  });

  const [selects, setSelect] = useState<{ [k: string]: string | number }>({});
  const [originalSelects, setOriginalSelect] = useState<{ [k: string]: string | number }>({});
  const [paging, setPaging] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  const setSelects = (merchantNo: number, idx: number) => {
    if (selects[merchantNo] === idx) {
      // 재선택 시 선택취소
      setSelect({ ...selects, [merchantNo]: -1 });
    } else {
      setSelect({ ...selects, [merchantNo]: idx });
    }
  };

  const clearSelect = () => {
    setSelect({});
    setOriginalSelect({});
  };

  // 리스트 쿼리
  const fetch = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    await apolloClient
      .query({
        variables: {
          filter: searchForm.searchValue,
          mappingStatus: searchForm.searchType,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
        },
        query: plccQuery.getHanaMerchantMappingList,
      })
      .then(res => {
        const result = res.data.getHanaMerchantMappingList;
        setData(result || { list: [], totalCount: 0 });
        clearSelect();
        if (result.list.length > 0) {
          const newSelect: { [k: string]: number | string } = {};
          result.list.forEach((row: any) => {
            if (row.mappingStatus === 'none') {
              newSelect[row.id] = 0;
            } else if (row.mappingStatus === 'not_found') {
              newSelect[row.id] = -1;
            } else {
              newSelect[row.id] = row.mappingId || -1;
            }
          });
          setOriginalSelect(newSelect);
          setSelect(newSelect);
        }
      })
      .catch(console.error);

    setLoading(false);
  };

  // 변경사항 저장 뮤테이션
  const confirm = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const changes: any = {};
    const original: any = {};
    Object.keys(originalSelects || [])
      .filter(original => selects[original] !== originalSelects[original])
      .forEach(key => {
        original[key] = originalSelects[key];
        changes[key] = selects[key];
      });
    // const changeLength = Object.keys(changes).length;

    await apolloClient
      .mutate({
        variables: {
          variables: changes || {},
          original: original || {},
        },
        mutation: plccMutation.updateHanaMerchantMapping,
      })
      .then((res: any) => {
        if (res?.data?.updateHanaMerchantMappingList?.errors?.length > 0) {
          const errors = res?.data?.updateHanaMerchantMappingList?.errors;
          showToast(`${errors.length}개의 항목의 업데이트에 문제가 생겼습니다. ${JSON.stringify(errors)}`, 'error');
          console.log(errors);
        } else {
          showToast(`${res?.data?.updateHanaMerchantMappingList?.changedTotal}개의 항목을 업데이트했습니다.`);
        }
      })
      .catch(console.error);

    setLoading(false);
    fetch();
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging?.page, paging?.rowsPerPage]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="가맹점매핑" />
      <PlccMerchantMappingListToolbar
        selects={selects}
        originalSelects={originalSelects}
        onSearch={async () => {
          setPaging({ ...paging, page: 0 });
          fetch();
        }}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        onConfirmClick={confirm}
      />
      <div className={classes.content}>
        <PlccMerchantMappingList
          merchantList={data}
          paging={paging}
          selects={selects}
          setPaging={setPaging}
          setSelect={setSelects}
        />
      </div>
    </div>
  );
};

import React, { FC,useState } from 'react';
import { TextField, CardContent, Grid } from '@material-ui/core';
import {
    Card,
    Button
  } from '@material-ui/core';

type Props ={
    onSearch: React.Dispatch<React.SetStateAction<CardBinNo>>
}

const SearchCardBin: FC<Props> =({onSearch}) =>{

    const [binNo, setBinNo] = useState('');

    const [brandDivisionCode, setBrandDivisionCode] = useState('');
    


    const handlebinNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBinNo(event.target.value);
      };
    const handlebrand = (event: React.ChangeEvent<{ value: unknown }>) => {
      setBrandDivisionCode(event.target.value as string);
      };
      

const onSearchClick = async () => {
    
    const cardBinNoSearch:CardBinNo = {} as any;

    cardBinNoSearch.binNo = binNo;
    cardBinNoSearch.brandDivisionCode = brandDivisionCode;

    onSearch(cardBinNoSearch);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
    if(event.key ==="Enter"){
      onSearchClick();
    }

  }

    
    return(
            <Card>
                <CardContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                  item
                  md={2}
                  xs={6}
                >
                <TextField label="브랜드구분" margin="dense" name="brandDivisionCode"
                variant="outlined" 
                select
                fullWidth
                SelectProps={{native : true}}
                InputLabelProps={{
                    shrink: true,
                  }}
                value={brandDivisionCode}
                onChange={handlebrand}  
                  >
                 <option value="">전체</option>   
                 <option value="0">GLOBAL BC</option>
                 <option value="1">LOCAL</option>
                 <option value="2">MC</option>
                 <option value="3">VISA</option>
                 <option value="4">JCB</option>
                                              
                </TextField>
                </Grid>
                <Grid
                item
                md={2}
                xs={6}
                > 
                <TextField  placeholder="BIN번호" variant="outlined" margin="dense" label="BIN번호"
                InputLabelProps={{shrink: true,}}
                fullWidth
                onChange={handlebinNo} value={binNo} onKeyPress={onKeyEnter} />
                 </Grid>
                 <Grid
                item
                md={2}
                xs={6}
                >  
                <Button variant="contained" color="primary" onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
                >SEARCH
                </Button>
                </Grid>
                  </Grid>
                </CardContent>
            </Card>

    )

};

export default SearchCardBin;
import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type Props = {
  title :string;
  adjustmentPurchaseDetails: AdjustmentPurchaseDetails;
}

const OverseasPurchasedAggrHistoryAdjustProcessAdjustmentPurchaseDetails: FC<Props> = ({ title, adjustmentPurchaseDetails }) => {
  const classes = useStyles();
  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content} style={{paddingBottom: '0'}}>
        <div className={classes.inner}>
          <Table size="small">
          <TableBody>
            <TableRow>
                  <TableCell align="center" className={classes.thStyle}>매입처리구분</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(adjustmentPurchaseDetails.purchased_process_division_code == null) ? 0 : adjustmentPurchaseDetails.purchased_process_division_code}</TableCell>
            </TableRow>
            <TableRow>
                  <TableCell align="center" className={classes.thStyle}>조정처리사유</TableCell>
                  <TableCell align="left" colSpan={4} className={classes.tdStyle}>{(adjustmentPurchaseDetails.adjust_process_reason == null) ? 0 : adjustmentPurchaseDetails.adjust_process_reason}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="center" className={classes.thStyle}>자료처리구분코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.data_process_division_code == null) ? 0 : adjustmentPurchaseDetails.data_process_division_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>조정처리상태구분코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.adjust_process_status_division_code == null) ? 0 : adjustmentPurchaseDetails.adjust_process_status_division_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>거래유형코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.trade_process_code == null) ? 0 : adjustmentPurchaseDetails.trade_process_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>최종조작직원번호</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.last_operate_admin_id == null) ? 0 : adjustmentPurchaseDetails.last_operate_admin_id}</TableCell>
                <TableCell align="center" className={classes.thStyle}>최종조작거래코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.last_operate_trade_code == null) ? 0 : adjustmentPurchaseDetails.last_operate_trade_code}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>카드번호</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.card_no == null) ? 0 : adjustmentPurchaseDetails.card_no_masked}</TableCell>
                <TableCell align="center" className={classes.thStyle}>현지거래통화코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.local_trade_currency_code == null) ? 0 : adjustmentPurchaseDetails.local_trade_currency_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>거래통화</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.local_trade_currency_code == null) ? 0 : adjustmentPurchaseDetails.local_trade_currency_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>현지거래금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.local_trade_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.local_trade_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매출일자</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.sales_date == null) ? 0 : chaiStringUtils.dateFormatDefault(adjustmentPurchaseDetails.sales_date)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>가맹점국가코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.merchant_nation_code == null) ? 0 : adjustmentPurchaseDetails.merchant_nation_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>영문가맹점명</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.merchant_eng_name == null) ? 0 : adjustmentPurchaseDetails.merchant_eng_name}</TableCell>
                <TableCell align="center" className={classes.thStyle}>가맹점도시명</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.merchant_city_name == null) ? 0 : adjustmentPurchaseDetails.merchant_city_name}</TableCell>
                <TableCell align="center" className={classes.thStyle}>계정구분코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.account_division_code == null) ? 0 : adjustmentPurchaseDetails.account_division_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매출종류구분코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.sales_kind_division_code == null) ? 0 : adjustmentPurchaseDetails.sales_kind_division_code}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>매입접수번호</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.purchased_process_no == null) ? 0 : adjustmentPurchaseDetails.purchased_process_no}</TableCell>
                <TableCell align="center" className={classes.thStyle}>BC판매구분코드</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.bc_sales_division_code == null) ? 0 : adjustmentPurchaseDetails.bc_sales_division_code}</TableCell>
                <TableCell align="center" className={classes.thStyle}>승인일자</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.approval_date == null) ? 0 : chaiStringUtils.dateFormatDefault(adjustmentPurchaseDetails.approval_date)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>카드해외승인번호</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.overseas_card_approval_no == null) ? 0 : adjustmentPurchaseDetails.overseas_card_approval_no}</TableCell>
                <TableCell align="center" className={classes.thStyle}>거래승인순번</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.trade_approval_seq == null) ? 0 : adjustmentPurchaseDetails.trade_approval_seq}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.trade_date == null) ? 0 : chaiStringUtils.dateFormatDefault(adjustmentPurchaseDetails.trade_date)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외신판미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_cdsl_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cdsl_usd_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외신판수수료미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_cdsl_fee_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cdsl_fee_usd_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외신판수수료원화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(adjustmentPurchaseDetails.overseas_cdsl_fee_krw_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cdsl_fee_krw_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외정산미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_settlement_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_settlement_usd_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>해외현금미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_cash_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cash_usd_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외현금수수료미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_cash_fee_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cash_fee_usd_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외현금수수료원화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(adjustmentPurchaseDetails.overseas_cash_fee_krw_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_cash_fee_krw_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외정산원화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(adjustmentPurchaseDetails.overseas_settlement_krw_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_settlement_krw_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매입적용환율</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.purchased_apply_exchange_rate == null) ? 0 : adjustmentPurchaseDetails.purchased_apply_exchange_rate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>매매기준환율</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(adjustmentPurchaseDetails.deal_base_exchange_rate == null) ? 0 : adjustmentPurchaseDetails.deal_base_exchange_rate}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매입미화원금금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.purchased_usd_origin_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.purchased_usd_origin_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>승인요청미화원금금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.approval_request_usd_origin_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.approval_request_usd_origin_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외이용수수료기준미화금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(adjustmentPurchaseDetails.overseas_use_fee_base_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(adjustmentPurchaseDetails.overseas_use_fee_base_usd_amount)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverseasPurchasedAggrHistoryAdjustProcessAdjustmentPurchaseDetails;

import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    },
    thStyle: {
      backgroundColor: '#fafafa',
    },
    thSumStyle: {
      backgroundColor: '#eeeeee',
    }, 
  }));

  type Props = {
    setHolidayInformation: React.Dispatch<React.SetStateAction<holidayInformation>>,
    setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
    title: string ,
    HolidayInformationList: holidayInformation[] }

  const HolidayInformationTable: FC<Props> = ({ 
    title, 
    HolidayInformationList,
    setHolidayInformation,
    setUpdateClick
  }) => {
  const classes = useStyles();
  
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  
  if(HolidayInformationList.length === 0) {
    return (
      <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>조회된 내용이 없습니다.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </Card>
    );
  }
  
    return (
        <Card>
          { !!title &&
            <CardHeader title={title}/>
          }
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">NO</TableCell>
                      <TableCell align="center">기준일자</TableCell>
                      <TableCell align="center">요일</TableCell>
                      <TableCell align="center">휴일구분</TableCell>
                      <TableCell align="center">영업일</TableCell>
                      <TableCell align="center">익영업일</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {HolidayInformationList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((HolidayInformation,index) => (
                      <TableRow 
                        hover 
                        key={index}
                        onClick={() => {setHolidayInformation(HolidayInformation); setUpdateClick(true); console.log(HolidayInformation);}}
                      >
                        <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                        <TableCell align="center">{chaiStringUtils.dateFormatDefault(HolidayInformation.baseDate)}</TableCell>
                        <TableCell align="center">{HolidayInformation.dayOfWeekCodeName}</TableCell>
                        <TableCell align="center">{HolidayInformation.holidayCodeName}</TableCell>
                        <TableCell align="center">{chaiStringUtils.dateFormatDefault(HolidayInformation.businessDay)}</TableCell>
                        <TableCell align="center">{chaiStringUtils.dateFormatDefault(HolidayInformation.nextBusinessDay)}</TableCell>
                     </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>     
          <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={HolidayInformationList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
          </CardActions>  
        </Card>
    );
  }
  
  export default HolidayInformationTable;
import { Button, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import apolloClient, { plccQuery } from '../../lib/apolloClient';
import ApprovalListToolbar from './ApprovalListToolbar';
import ApprovalPdf from './ApprovalPdf';
import ApprovalTable from './ApprovalTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type MatchParams = { searchDt: string; paymentId: string };
export const HanaApprovalList: React.SFC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { searchDt, paymentId } = match.params;
  let startDate = new Date();
  if (searchDt) {
    const startDt = searchDt.replace(/[^0-9]/g, '');
    startDate = new Date(
      Number(startDt.substring(0, 4)),
      Number(startDt.substring(4, 6)) - 1,
      Number(startDt.substring(6, 8))
    );
  }

  const classes = useStyles();
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [data, setData] = useState([]);
  const [searchForm, setSearchForm] = useState<HanaApprovalInfoForm>({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDt: format(startDate, 'yyyy-MM-dd'),
    endDt: format(startDate, 'yyyy-MM-dd'),
    type: '',
    cardApprovalNo: '',
    paymentId: paymentId ? paymentId : '',
  });
  const [loading, setLoading] = useState(false);
  const [refundCount, setRefundCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isPdfDown, setPdfDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      // 이미 로딩 중이면 리턴
      if (loading) {
        return;
      }

      await apolloClient
        .query({
          variables: {
            input: {
              searchType: searchForm.searchType,
              searchValue: searchForm.searchValue,
              startDt: searchForm.startDt,
              endDt: searchForm.endDt,
              type: searchForm.type,
              cardApprovalNo: searchForm.cardApprovalNo,
              paymentId: searchForm.paymentId,
              page: paging.page,
              rowsPerPage: paging.rowsPerPage,
              isExcelDown: isExcelDown,
              isPdfDown: isPdfDown,
            },
          },
          query: plccQuery.getHanaApprovalList,
        })
        .then(res => {
          if (isExcelDown) {
            setExcelDown(false);
            excelDown(res.data.getHanaApprovalList.list);
          } else if (isPdfDown) {
            setPdfDown(false);
            pdfDown(res.data.getHanaApprovalList.list);
          } else {
            setData(res.data.getHanaApprovalList.list);
            setTotalCount(res.data.getHanaApprovalList.totalCount); // totalCount 세팅
          }
        })
        .catch(console.error);

      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm, refundCount, paging, isFlag]);

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  };

  const pdfClick = () => {
    setFlag(!isFlag);
    setPdfDown(true);
  };

  const displayProcessStatus = (processStatusCode: string) => {
    switch (processStatusCode) {
      case 'confirm':
        return '정상';
      case 'canceled':
        return '취소';
    }
    return '알 수 없음';
  };

  const displayPdfButton = () => {
    if (searchForm.searchType === '2' && searchForm.searchValue) {
      return (
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 10, marginBottom: 10 }}
          onClick={event => {
            pdfClick();
          }}
        >
          PDF
        </Button>
      );
    } else return;
  };

  const excelDown = (csvData: HanaApprovalInfo[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        카드승인번호: `${csvData[i].approvalNumber}`,
        머니승인번호: `${csvData[i].paymentId}`,
        승인일시: `${chaiStringUtils.dateFormatDefault(csvData[i].approvalDate)}`,
        고객번호: `${csvData[i].cardUserId}`,
        성명: `${csvData[i]._user.user_kor_name}`,
        카드번호: `****-****-****-${csvData[i].cardLastNumber}`,
        가맹점명: `${csvData[i]._merchant.name}`,
        승인금액: `${chaiStringUtils.moneyFormat(csvData[i].amount)}`,
        승인KRW금액: `${chaiStringUtils.moneyFormat(csvData[i].amountKRW ?? 0)}`,
        승인USD금액: `${chaiStringUtils.moneyFormat(csvData[i].amountUSD ?? 0)}`,
        '해외브랜드미화수수료(면제전)': `${csvData[i].brandFee ?? 0}`,
        처리구분: `${displayProcessStatus(csvData[i].type)}`,
      });
    }
    utils.exportToCSV('국내승인내역', rowData);
  };

  const pdfDown = (pdfList: HanaApprovalInfo[]) => {
    const doc = <ApprovalPdf pdfList={pdfList} searchInfo={searchForm} />;
    let fileName = '차이카드_증빙용 거래내역';
    if (pdfList.length > 0) {
      fileName = fileName + '_' + pdfList[0]._user.user_kor_name;
    }
    utils.exportToPDF(fileName, doc);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내승인내역" />
      <ApprovalListToolbar paging={paging} setPaging={setPaging} searchInfo={searchForm} onSearch={setSearchForm} />
      <div className={classes.content}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto', marginBottom: 10 }}
          onClick={event => {
            excelClick();
          }}
        >
          Excel
        </Button>
        {displayPdfButton()}
        <ApprovalTable
          paging={paging}
          setPaging={setPaging}
          totalCount={totalCount}
          refundCount={refundCount}
          setRefundCount={setRefundCount}
          list={data}
        />
      </div>
    </div>
  );
};

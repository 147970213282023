import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { foreignSndrvLogMutation } from '../../lib/apolloClient/mutations';

import ForeignSndrvLogListToolbar from './ForeignSndrvLogListToolbar';
import ForeignSndrvLogListTable from './ForeignSndrvLogListTable';
import { MenuTitleToolbar } from '../../components';

import * as utils from '../../common/utils';
import {format} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type SearchInfo = {
  tradeDate:string,           // 거래일자
  operateTradeCode:string,    // 전문번호
  startTradeTime:string,      // 거래시작시각
  endTradeTime:string,        // 거래종료시각
  tradeUniqNo:string          // 전문거래고유번호
}

type Props = { className?:string }
const ForeignSndrvLogList: FC<Props> = ({ className }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    tradeDate: format(new Date(), 'yyyy-MM-dd'),  // 거래일자
    operateTradeCode: '',                         // 전문번호
    startTradeTime: '000000',                     // 거래시작시각
    endTradeTime: '240000',                       // 거래종료시각
    tradeUniqNo: ''                               // 전문거래고유번호
  });

  useEffect(() => {
    onSearch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 대외송수신로그목록 조회
  const onSearch = async (searchForm: SearchInfo) => {

    if(loading) {
      return;
    }
    
    // 초기화 
    setData([]);

    console.log('대외송수신로그목록 조회 INPUT: ', searchForm);

    setLoading(true);
    
    await apolloClient.mutate({
      variables: {
        tradeDate: searchForm.tradeDate,                  // 거래일자
        operateTradeCode: searchForm.operateTradeCode,    // 전문번호
        startTradeTime: searchForm.startTradeTime,        // 거래시작시각
        endTradeTime: searchForm.endTradeTime,            // 거래종료시각
        tradeUniqNo: searchForm.tradeUniqNo               // 전문거래고유번호
      },
      mutation: foreignSndrvLogMutation.selectForeignSndrvLogList
    }).then(res => {     
      console.log('목록 조회 결과 : ', res.data.selectForeignSndrvLogList);
      setData(res.data.selectForeignSndrvLogList);
    }).catch(console.error);

    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="대외송수신로그" />
      <ForeignSndrvLogListToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm}/>
      <div className={classes.content}>
        {loading
          ? 
            utils.Loading()
          :
          <ForeignSndrvLogListTable title='대외송수신로그목록' foreignSndrvLogs={data} />
        }
      </div>
    </div>
  )
}

export default ForeignSndrvLogList;
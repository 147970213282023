import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { approvalAggrMutation } from '../../lib/apolloClient/mutations';
import ApprovalAggrDetailListPop from './ApprovalAggrDetailListPop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type ApprovalAggrInfo = {
  tradeDate	                :string; // 거래일자
  aggregationDivisionCode	  :string; // 집계구분코드
  cardProductCode	          :string; // 카드상품코드
  cardProductName	          :string; // 카드상품명
  approvalCount	            :number; // 승인건수
  approvalAmount	          :number; // 승인금액
  cancelCount	              :number; // 취소건수
  cancelAmount	            :number; // 취소금액
  inabledCount	            :number; // 불능건수
  inabledAmount	            :number; // 불능금액
  bcRejectCount	            :number; // BC선거절건수
  bcRejectAmount	          :number; // BC선거절금액
}

type Props = { list: ApprovalAggrInfo[] }
const ApprovalAggrTable: FC<Props> = ({ list }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 상세팝업 오픈여부
  const [detailListPop, setDetailListPopOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // 정상/취소/불능건수 클릭 시 상세팝업오픈
  const handleCountClick = (tradeDate: string, cardProductCode: string, salesKindDivisionCodeGubun: string) => {
    // 조회
    fetch(tradeDate, cardProductCode, salesKindDivisionCodeGubun);

    // 팝업 오픈
    setDetailListPopOpen(true);
  }

  // 상세목록 조회
  const fetch = async (tradeDate: string, cardProductCode: string, salesKindDivisionCodeGubun: string) => {
    console.log('승인집계 상세 목록 조회 - INPUT: ', tradeDate, cardProductCode, salesKindDivisionCodeGubun);

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        tradeDate: tradeDate,
        cardProductCode: cardProductCode,
        salesKindDivisionCodeGubun: salesKindDivisionCodeGubun
      },
      mutation: approvalAggrMutation.selectApprovalDetailList
    }).then(res => {
      console.log('승인집계 상세 목록 조회 결과: ', res.data.selectApprovalDetailList);
      setData(res.data.selectApprovalDetailList);
    }).catch(console.error);

    setLoading(false);
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">거래일자</TableCell>
                  <TableCell align="center">카드상품명</TableCell>
                  <TableCell align="center">정상건수</TableCell>
                  <TableCell align="center">정상금액</TableCell>
                  <TableCell align="center">취소건수</TableCell>
                  <TableCell align="center">취소금액</TableCell>
                  <TableCell align="center">불능건수</TableCell>
                  <TableCell align="center">불능금액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                  <TableRow
                    hover
                    key={item.tradeDate+''+item.cardProductCode}
                  >
                    <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.tradeDate)}</TableCell>
                    <TableCell align="center">{item.cardProductName}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="정상건수 상세목록">
                        <a href="#!" onClick={() => handleCountClick(item.tradeDate, item.cardProductCode, '1')}>
                          {item.approvalCount && item.approvalCount.toLocaleString()} 건
                        </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">{item.approvalAmount && item.approvalAmount.toLocaleString()} 원</TableCell>

                    <TableCell align="right">
                      <Tooltip title="취소건수 상세목록">
                        <a href="#!" onClick={() => handleCountClick(item.tradeDate, item.cardProductCode, '2')}>
                          {item.cancelCount && item.cancelCount.toLocaleString()} 건
                        </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">{item.cancelAmount && item.cancelAmount.toLocaleString()} 원</TableCell>

                    <TableCell align="right">
                      <Tooltip title="불능건수 상세목록">
                        <a href="#!" onClick={() => handleCountClick(item.tradeDate, item.cardProductCode, '3')}>
                          {item.inabledCount && item.inabledCount.toLocaleString()} 건
                        </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">{item.inabledAmount && item.inabledAmount.toLocaleString()} 원</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <ApprovalAggrDetailListPop detailListPop={detailListPop} setDetailListPopOpen={setDetailListPopOpen} loading={loading} data={data} />
    </Card>
  );
};

export default ApprovalAggrTable;

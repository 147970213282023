import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';


type SearchInfo = {
    startDt : string;
    endDt : string;
}

type Props = {
    onSearch : React.Dispatch<React.SetStateAction<SearchInfo>>
}

const OverseasExchRateDiffToolBar : FC<Props> = ({onSearch}) => {  

    const [searchForm, setSearhForm] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd')
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearhForm({
        ...searchForm,
        [e.target.name]: e.target.value
    });
    };

    const onKeyEnter = async (event : React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter") {
          onSearchClick();
        }
    };

    const onSearchClick = async () => {
        onSearch(searchForm);
    };

    return (
    <Card>
        <form
        autoComplete="off"
        noValidate
        >
        <CardContent>
            <Grid
            container
            spacing={1}
            >
            <Grid
                item
                md={2}
                xs={6}
            >
                <TextField
                required
                fullWidth
                type="date"
                label="정산시작일자"
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={searchForm.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
                />
            </Grid>
            <Grid
                item
                md={2}
                xs={6}
            >
                <TextField
                required
                fullWidth
                type="date"
                label="정산종료일자"
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={searchForm.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
                />
            </Grid>          
            <Grid
                item
                md={2}
                xs={6}
            >
                <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                    marginTop: '8px'
                }}
                >
                Search 
                </Button>
            </Grid>
            </Grid>
        </CardContent>
        </form>
    </Card>
    );
};

export default OverseasExchRateDiffToolBar;

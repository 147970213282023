import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightBorder: {
    borderRightWidth: 1,
    borderRightColor: '#eee',
    borderRightStyle: 'solid',
  },
}));

type Props = {
  merchantList: HanaMerchantMappingListReturnType;
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
  setSelect: any;
  selects: any;
};

const MappingStatusString = {
  matched: '매칭완료',
  on_ready: '매칭완료',
  not_found: '매칭대기',
  none: '매칭없음',
};

export const PlccMerchantMappingList: FC<Props> = ({ merchantList, paging, setPaging, selects, setSelect }) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);

  let list = merchantList === undefined ? [] : merchantList['list'] || [];
  let totalCount = merchantList === undefined ? 0 : merchantList['totalCount'] || 0;

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    if (scrollRef?.current) scrollRef.current.scrollTop = 0;
    setPaging({
      ...paging,
      page: inPage,
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (scrollRef?.current) scrollRef.current.scrollTop = 0;
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value),
    });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {/* 상단 테이블 위쪽 + 하단 페이지부분 + 패딩부분 */}
            <TableContainer style={{ maxHeight: 'calc(100vh - 308px - 68px - 32px)' }} ref={scrollRef}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={5} className={classes.rightBorder}>
                      하나카드
                    </TableCell>
                    <TableCell align="center" colSpan={5}>
                      BC카드
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '140px' }}>가맹점번호</TableCell>
                    <TableCell>가맹점명</TableCell>
                    <TableCell style={{ width: '140px' }}>사업자등록번호</TableCell>
                    <TableCell style={{ width: '90px' }}>업종코드</TableCell>
                    <TableCell style={{ width: '125px' }} className={classes.rightBorder}>
                      매핑상태
                    </TableCell>
                    <TableCell style={{ width: '140px' }}>가맹점번호</TableCell>
                    <TableCell padding="none" style={{ width: '250px' }}>
                      가맹점명
                    </TableCell>
                    <TableCell style={{ width: '90px' }}>업종코드</TableCell>
                    <TableCell style={{ width: '90px' }}>매핑율</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((merchant, idx) => (
                    <TableRow hover key={merchant.id} style={{ cursor: 'pointer', verticalAlign: 'top' }}>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{merchant.id}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{merchant.name}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>
                        {chaiStringUtils.bizNoFormat(merchant.businessNumber)}
                      </TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{merchant.businessCode}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }} className={classes.rightBorder}>
                        {merchant.mappingStatus === 'on_ready' || merchant.mappingStatus === 'matched'
                          ? `${MappingStatusString[merchant.mappingStatus]} (${merchant.mappingId})`
                          : MappingStatusString[merchant.mappingStatus] || '-'}
                      </TableCell>
                      <TableCell colSpan={5} padding="none" style={{ paddingTop: 6, paddingBottom: 6 }}>
                        <TableContainer style={{ height: '200px' }}>
                          <Table size="small" stickyHeader>
                            <TableBody>
                              <TableRow
                                hover
                                key={`${merchant.id}-sub-0`}
                                style={selects?.[merchant.id] === 0 ? { backgroundColor: '#90caf9' } : {}}
                                onClick={() => setSelect(merchant.id, 0)}
                              >
                                <TableCell colSpan={5}>매핑 가맹점 없음</TableCell>
                              </TableRow>
                              {merchant.suggest.map((suggest, subIdx) => (
                                <TableRow
                                  hover
                                  key={`${merchant.id}-sub-${subIdx}`}
                                  style={
                                    selects?.[merchant.id] === suggest.bcMerchantId
                                      ? { backgroundColor: '#90caf9' }
                                      : {}
                                  }
                                  onClick={() => setSelect(merchant.id, suggest.bcMerchantId)}
                                >
                                  <TableCell style={{ width: '140px' }}>{suggest.bcMerchantId}</TableCell>
                                  <TableCell padding="none">{suggest.bcMerchantName}</TableCell>
                                  <TableCell style={{ width: '90px' }}>{suggest.bcBusinessCode}</TableCell>
                                  <TableCell style={{ width: '90px' }}>{suggest.similarity / 100}%</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

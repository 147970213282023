import React, {FC} from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
  
const useStyles = makeStyles((theme: Theme) => ({
    content: { padding: 0 },
    actions: { justifyContent: 'flex-end' },
    inner: { minWidth: 1050 },
    innerLine : { border: '1px solid #dbdbdb'} 
}));

type Props ={
    detailInfo : ovrsSettlementAggrListDetail[]
}


const OverseasSettlementAggrListDetail : FC<Props> = ({detailInfo}) => {

    console.log('# OverseasSettlementAggrListDetail !!!!! : ', detailInfo);

    console.log('# detailInfo - length :' , detailInfo.length);
    console.log('@@ get data value :', detailInfo.slice(0, 1).map(aa=>aa.accountDivisionCode));
  

    const classes = useStyles();

    return(
        <Card>
            <CardHeader title=" 정산내역 (가맹점대금 정산 관련 항목)  " />
            <Table className={classes.innerLine}>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' rowSpan={3} className={classes.innerLine}> No </TableCell>
                        <TableCell align='center' rowSpan={3} className={classes.innerLine}> 항목 </TableCell>
                        <TableCell align='center' colSpan={8} className={classes.innerLine}> 일정산대금 </TableCell>
                        <TableCell align='center' rowSpan={3} className={classes.innerLine}> 정산환차금액 </TableCell>
                        <TableCell align='center' rowSpan={3} className={classes.innerLine}> 정산환율적용금액 </TableCell>
                        <TableCell align='center' colSpan={8} className={classes.innerLine}> 발급사수수료 </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='center' colSpan={3} className={classes.innerLine}> BC지급 </TableCell>
                        <TableCell align='center' colSpan={3} className={classes.innerLine}> 당사입금 </TableCell>
                        <TableCell align='center' rowSpan={2} className={classes.innerLine}> 미화차액 <p><span style={{color:'blue'}}>(A-B)</span></p> </TableCell>
                        <TableCell align='center' rowSpan={2} className={classes.innerLine}> 원화차액 <p><span style={{color:'blue'}}>(C-D)</span></p> </TableCell>
                        <TableCell align='center' colSpan={3} className={classes.innerLine}> 수익발생 </TableCell>
                        <TableCell align='center' colSpan={3} className={classes.innerLine}> 수익취소 </TableCell>
                        <TableCell align='center' rowSpan={2} className={classes.innerLine}> 미화차액 <p><span style={{color:'blue'}}>(E-F)</span></p> </TableCell>
                        <TableCell align='center' rowSpan={2} className={classes.innerLine}> 원화차액 <p><span style={{color:'blue'}}>(G-H)</span></p> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='center' > 건수 </TableCell>
                        <TableCell align='center' > 미화금액 <span style={{color:'blue'}}>(A)</span> </TableCell>
                        <TableCell align='center' > 원화금액 <span style={{color:'blue'}}>(C)</span> </TableCell>
                        <TableCell align='center' > 건수 </TableCell>
                        <TableCell align='center' > 미화금액 <span style={{color:'blue'}}>(B)</span> </TableCell>
                        <TableCell align='center' > 원화금액 <span style={{color:'blue'}}>(D)</span> </TableCell>
                        <TableCell align='center' > 건수</TableCell>
                        <TableCell align='center' > 미화금액 <span style={{color:'blue'}}>(E)</span> </TableCell>
                        <TableCell align='center' > 원화금액 <span style={{color:'blue'}}>(G)</span> </TableCell>
                        <TableCell align='center' > 건수 </TableCell>
                        <TableCell align='center' > 미화금액 <span style={{color:'blue'}}>(F)</span> </TableCell>
                        <TableCell align='center' > 원화금액 <span style={{color:'blue'}}>(H)</span> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detailInfo.slice(0, detailInfo.length).map((item, index) => (
                        <TableRow hover key={index}>
                            <TableCell align='center'> {index+1} </TableCell>
                            <TableCell align='center'> {chaiStringUtils.codeName('accountDivisionCode', item.accountDivisionCode)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : item.settlementUsdDepositCount} </TableCell>
                            <TableCell align='center'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementUsdDepositTotalAmount)} </TableCell>
                            <TableCell align='center'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementKrwDepositTotalAmount)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : item.settlementUsdWithdrawalCount} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementUsdWithdrawalTotalAmount)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementKrwWithdrawalTotalAmount)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementUsdTradeTotalAmount)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementKrwTradeTotalAmount)} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : chaiStringUtils.moneyFormat(item.settlementExchangeRateDiffTotalAmount)} </TableCell>
                            
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? 0 : 
                                (item.settlementExchangeRateApplyAmount > 0 ? 
                                    <span style={{color:'#0045e8'}} >{chaiStringUtils.moneyFormat(item.settlementExchangeRateApplyAmount)}</span>
                                    : (item.settlementExchangeRateApplyAmount < 0 ? 
                                        <span style={{color:'#cc1f1f'}} >{chaiStringUtils.moneyFormat(item.settlementExchangeRateApplyAmount)}</span>
                                        : chaiStringUtils.moneyFormat(item.settlementExchangeRateApplyAmount)))} </TableCell>

                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementUsdDepositCount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementUsdDepositTotalAmount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementKrwDepositTotalAmount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementUsdWithdrawalCount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementUsdWithdrawalTotalAmount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementKrwWithdrawalTotalAmount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementUsdTradeTotalAmount) : 0} </TableCell>
                            <TableCell align='right'> {item.accountDivisionCode === '450' ? chaiStringUtils.moneyFormat(item.settlementKrwTradeTotalAmount) : 0} </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default OverseasSettlementAggrListDetail;
import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer',
  },
}));

type Props = {
  title: string;
  cards: CardDeliveryInfoCardInfoReturn[];
  setCardInfo: React.Dispatch<React.SetStateAction<CardDeliveryInfoCardInfoReturn>>;
};
const CardList: FC<Props> = ({ title, cards, setCardInfo }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 테이블 행 클릭 이벤트
  function handleRowClick(card: CardDeliveryInfoCardInfoReturn, cardAccidentHistorys: never[]) {
    setCardInfo(card); // set [카드정보]
  }

  // 카드상태코드Map
  const cardStatusCodeMap = new Map<String, String>();
  cardStatusCodeMap.set('0', '정지(카드배송)');
  cardStatusCodeMap.set('1', '정상');
  cardStatusCodeMap.set('2', '해지');
  cardStatusCodeMap.set('3', '폐기');

  if (cards.length === 0) {
    return (
      <Card>
        {!!title && <CardHeader title={title} />}
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <Card>
      {!!title && <CardHeader title={title} />}
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>카드번호</TableCell>
                  <TableCell>회원명</TableCell>
                  <TableCell>상품명</TableCell>
                  <TableCell>유효기한</TableCell>
                  <TableCell>등록일자</TableCell>
                  <TableCell>최종카드여부</TableCell>
                  <TableCell>상태</TableCell>
                  <TableCell>사고등재여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((card, index) => (
                  <TableRow hover key={card.cardNo} className={classes.cursor_pointer}>
                    <TableCell>
                      <a href="#!" onClick={() => handleRowClick(card, card.cardAccidentReceiptHistory)}>
                        {card.cardNoMasked}
                      </a>
                    </TableCell>
                    <TableCell>{card.cardOwnrName}</TableCell>
                    <TableCell>{card.cardProduct.cardProductCodeName}</TableCell>
                    <TableCell>{card.validateTermYm}</TableCell>
                    <TableCell>{chaiStringUtils.dateFormat(card.issueDate, null)}</TableCell>
                    <TableCell>
                      <div className={classes.isFinalContainer}>{card.lastCardYn}</div>
                    </TableCell>
                    <TableCell>{chaiStringUtils.codeName('cardStatusCode', card.cardStatusCode)}</TableCell>
                    <TableCell>
                      {card.cardAccidentReceiptHistory.length > 0
                        ? card.cardAccidentReceiptHistory.some(
                            (cardAccidentReceiptHistory: { accidentStatusDivisionCode: string }) =>
                              cardAccidentReceiptHistory.accidentStatusDivisionCode === '0'
                          )
                          ? 'Y'
                          : 'N'
                        : 'N'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={cards.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default CardList;

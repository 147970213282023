export function convertPurchaseType(type: string) {
  switch (type) {
    case 'confirm':
      return '정상';
    case 'canceled':
      return '취소';
    case 'partial_canceled':
      return '부분취소';
    default:
      return '분류없음';
  }
}

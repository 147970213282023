import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import { approvalHistoryLogAggrMutation } from '../../lib/apolloClient/mutations';
import ApprovalHistoryLogAggrToolbar from './ApprovalHistoryLogAggrToolbar';
import ApprovalHistoryLogAggrTable from './ApprovalHistoryLogAggrTable';
import {format, subMonths} from 'date-fns'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ApprovalAggr = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [inputSearch, setInputSearch] = useState({
    aggregationDivisionCode: '2',
    cardProductCode: '',
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          aggregationDivisionCode: inputSearch.aggregationDivisionCode,  /* 집계구분 - 2:승인대사로그집계 */
          cardProductCode: inputSearch.cardProductCode,
          startDate: inputSearch.startDate.replace(/-/g,''),
          endDate: inputSearch.endDate.replace(/-/g,'') }
        },
        mutation: approvalHistoryLogAggrMutation.selectApprovalHistoryLogAggr
      }).then(res => {
        setData(res.data.selectApprovalHistoryLogAggr);
      }).catch(console.error);

      // setLoading(false);
    }
    fetch()
  }, [inputSearch]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내승인대사로그집계" />
      <ApprovalHistoryLogAggrToolbar onSearch={setInputSearch}/>
      <div className={classes.content}>
        <ApprovalHistoryLogAggrTable list={data} />
      </div>
    </div>
  );

};

export default ApprovalAggr;

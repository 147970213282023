import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import {
  Theme,
  makeStyles,
  Card,
  CardContent,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination
} from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { proxyFeeCodeMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { format, addMonths } from 'date-fns';


// 대행수수료코드상세내역

type Props = {
  className?: string;
  proxyFeeCodeInfo: FeeCodeInfoReturn;
  setProxyFeeCodeInfo: React.Dispatch<React.SetStateAction<FeeCodeInfoReturn>>;
  updateClick: boolean;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  checkFormValidation: VoidFunction;
  isValidForm: boolean;
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmptyForm: React.Dispatch<React.SetStateAction<boolean>>;
  isEmptyForm: boolean;
  checkFormEmpty: VoidFunction;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
  isNew: boolean;
};

const ProxyFeeCodeDetails: FC<Props> = ({
  className,
  proxyFeeCodeInfo,
  setProxyFeeCodeInfo,
  updateClick,
  setUpdateClick,
  checkFormValidation,
  isValidForm,
  setIsValidForm,
  setIsEmptyForm,
  isEmptyForm,
  checkFormEmpty,
  isNew,
  setIsNew
}) => {
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchProxyFeeCode, SetSearchProxyFeeCode] = useState({
      proxyFeeCodeNm: '',
      proxyFeeCodeNo: ''
  })

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };
  
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  const isEmpty = (input: string) => {
    return input === '' || input === null || input === undefined;
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProxyFeeCodeInfo({
      ...proxyFeeCodeInfo,
      [event.target.name]: event.target.value
    });
    console.log(proxyFeeCodeInfo);
  };

  const handleAddProxyFeeCode = async () => {
    let check = false;

    if (proxyFeeCodeInfo.feeInfoDivisionCode === '1') {
        console.log('#############    Add Proxy Fee Code');
      await apolloClient
        .mutate({
          variables: {
            input: {
              proxyFeeCodeNo: proxyFeeCodeInfo.proxyFeeCodeNo,
              proxyFeeNm: proxyFeeCodeInfo.proxyFeeNm,
              validateStartDate: proxyFeeCodeInfo.validateStartDate,
              validateEndDate: proxyFeeCodeInfo.validateEndDate,
              deleteYn: proxyFeeCodeInfo.deleteYn,
              domesticCode: proxyFeeCodeInfo.domesticCode,
              domesticBrandDivisionCode:
            proxyFeeCodeInfo.domesticBrandDivisionCode,
              proxyFeeGroupCode: proxyFeeCodeInfo.proxyFeeGroupCode
            }
          },
          mutation: proxyFeeCodeMutation.insertProxyFeeCodeInfo
        })
        .then(res => {
          if (res.data.insertProxyFeeCodeInfo.proxyFeeCodeNo === `xxx`) {
            check = true;
          }
          if (check) {
            hideDialog();
            showDialog(
              '대행 수수료 코드 정보',
              '동일한 수수료 코드는 저장할 수 없습니다.\n\n',
              () => {
                hideDialog();
              }
            );
            return;
          } else {
            hideDialog();
            showDialog('대행 수수료 코드 정보', '저장되었습니다.\n\n', () => {
              hideDialog();
            });
          }
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          showToast(error.message, 'Error');
        });

      setProxyFeeCodeInfo({
        proxyFeeGroupCode: "1", // 서비스 수수료 그룹
        proxyFeeCodeNo: '', // 서비스 수수료 코드
        proxyFeeNm: '', // 수수료명
        unitFeeCodeNo: '', // 단위항목코드
        unitFeeName: '', // 단위항목명
        feeClaimMethodDivisionCode: "1", // 수수료청구방식
        applyUnitprc: 0, // 적용단가
        feeBaseRate: 0.0, // 수수료 기준율
        feeProofDivisionCode: '', // 수수료 증빙 구분
        feeAutoYn: "Y", // 자동대사 여부
        validateStartDate: format(new Date(), 'yyyyMMdd'), // 유효시작일자
    validateEndDate: format(addMonths(new Date(), 1),'yyyyMMdd'), // 유효종료일자
        deleteYn: "N", // 삭제여부
        domesticCode: "1", // 국내외 구분
        feeInfoDivisionCode: '', // 대행 수수료 구분
        domesticBrandDivisionCode: "1" // 국내외 상품 구분 - details만
      });
    } else {
        console.log('#############    Add Unit Fee Code');

      await apolloClient
        .mutate({
          variables: {
            input: {
              proxyFeeCodeNo: proxyFeeCodeInfo.proxyFeeCodeNo,
              unitFeeCodeNo: proxyFeeCodeInfo.unitFeeCodeNo,
              unitFeeName: proxyFeeCodeInfo.unitFeeName,
              feeClaimMethodDivisionCode:
              proxyFeeCodeInfo.feeClaimMethodDivisionCode,
              applyUnitprc: proxyFeeCodeInfo.applyUnitprc,
              feeBaseRate: proxyFeeCodeInfo.feeBaseRate,
              feeProofDivisionCode: proxyFeeCodeInfo.feeProofDivisionCode,
              feeAutoYn: proxyFeeCodeInfo.feeAutoYn,
              validateStartDate: proxyFeeCodeInfo.validateStartDate,
              validateEndDate: proxyFeeCodeInfo.validateEndDate,
              deleteYn: proxyFeeCodeInfo.deleteYn,
              domesticBrandDivisionCode:
                proxyFeeCodeInfo.domesticBrandDivisionCode
            }
          },
          mutation: proxyFeeCodeMutation.insertUnitFeeCodeInfo
        })
        .then(res => {
          if (res.data.insertUnitFeeCodeInfo.unitFeeCodeNo === `xxx`) {
            check = true;
          }
          if (check) {
            hideDialog();
            showDialog(
              '대행 수수료 코드 정보',
              '동일한 수수료 코드는 저장할 수 없습니다.\n\n',
              () => {
                hideDialog();
              }
            );
            return;
          } else {
            hideDialog();
            showDialog('대행 수수료 코드 정보', '저장되었습니다.\n\n', () => {
              hideDialog();
            });
          }
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          console.log('sdafsadfdsa');
          showToast(error.message, 'Error');
        });

      setProxyFeeCodeInfo({
        proxyFeeGroupCode: "1", // 서비스 수수료 그룹
        proxyFeeCodeNo: '', // 서비스 수수료 코드
        proxyFeeNm: '', // 수수료명
        unitFeeCodeNo: '', // 단위항목코드
        unitFeeName: '', // 단위항목명
        feeClaimMethodDivisionCode: "1", // 수수료청구방식
        applyUnitprc: 0, // 적용단가
        feeBaseRate: 0.0, // 수수료 기준율
        feeProofDivisionCode: '', // 수수료 증빙 구분
        feeAutoYn: "Y", // 자동대사 여부
        validateStartDate: format(new Date(), 'yyyyMMdd'), // 유효시작일자
    validateEndDate: format(addMonths(new Date(), 1),'yyyyMMdd'), // 유효종료일자
        deleteYn: "N", // 삭제여부
        domesticCode: "1", // 국내외 구분
        feeInfoDivisionCode: '', // 대행 수수료 구분
        domesticBrandDivisionCode: "1" // 국내외 상품 구분 - details만
      });
    }
    check = false;
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsValidForm(false); // 삽입 후 신규, 수정 버튼 비활성화
    initTextFields();
    setIsNew(false);
    setIsEmptyForm(true);
  };

  const handleUpdateProxyFeeCode = async () => {
    // 수정 버튼 클릭 시 textField 값들을 db에서 찾아 수정
    console.log('###### Update Proxy Fee Code');
    if (proxyFeeCodeInfo.feeInfoDivisionCode === '1') {
      await apolloClient
        .mutate({
          //  textField 값들을 변수로 지정하여 수정
          variables: {
            input: {
              proxyFeeCodeNo: proxyFeeCodeInfo.proxyFeeCodeNo,
              proxyFeeNm: proxyFeeCodeInfo.proxyFeeNm,
              validateStartDate: proxyFeeCodeInfo.validateStartDate,
              validateEndDate: proxyFeeCodeInfo.validateEndDate,
              deleteYn: proxyFeeCodeInfo.deleteYn,
              domesticCode: proxyFeeCodeInfo.domesticCode,
              domesticBrandDivisionCode:
                proxyFeeCodeInfo.domesticBrandDivisionCode,
              proxyFeeGroupCode: proxyFeeCodeInfo.proxyFeeGroupCode
            }
          },
          mutation: proxyFeeCodeMutation.updateProxyFeeCodeInfo
        })
        .then(res => {
          console.log(res.data);
          if (res.data.updateProxyFeeCodeInfo.proxyFeeCodeNo === 'xxx') {
            hideDialog();
            showDialog(
              ' 대행 수수료 코드 정보',
              '존재하지 않는 위임수수료번호의 정보는 수정할 수 없습니다.\n\n',
              () => {
                hideDialog();
              }
            );
          } else {
            hideDialog();
            showDialog('대행 수수료 코드 정보', '수정되었습니다\n\n', () => {
              hideDialog();
            });
          }
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          showToast(error.message, 'Error');
        });
    } else {
      await apolloClient
        .mutate({
          //  textField 값들을 변수로 지정하여 수정
          variables: {
            input: {
              proxyFeeCodeNo: proxyFeeCodeInfo.proxyFeeCodeNo,
              unitFeeCodeNo: proxyFeeCodeInfo.unitFeeCodeNo,
              unitFeeName: proxyFeeCodeInfo.unitFeeName,
              feeClaimMethodDivisionCode:
                proxyFeeCodeInfo.feeClaimMethodDivisionCode,
              applyUnitprc: proxyFeeCodeInfo.applyUnitprc,
              feeBaseRate: proxyFeeCodeInfo.feeBaseRate,
              feeProofDivisionCode: proxyFeeCodeInfo.feeProofDivisionCode,
              feeAutoYn: proxyFeeCodeInfo.feeAutoYn,
              validateStartDate: proxyFeeCodeInfo.validateStartDate,
              validateEndDate: proxyFeeCodeInfo.validateEndDate,
              deleteYn: proxyFeeCodeInfo.deleteYn,
              domesticBrandDivisionCode:
                proxyFeeCodeInfo.domesticBrandDivisionCode
            }
          },
          mutation: proxyFeeCodeMutation.updateUnitFeeCodeInfo
        })
        .then(res => {
          console.log(res.data);
          if (res.data.updateUnitFeeCodeInfo.unitFeeCodeNo === 'xxx') {
            hideDialog();
            showDialog(
              ' 대행 수수료 코드 정보',
              '존재하지 않는 단위수수료번호의 정보는 수정할 수 없습니다.\n\n',
              () => {
                hideDialog();
              }
            );
          } else {
            hideDialog();
            showDialog('대행 수수료 코드 정보', '수정되었습니다\n\n', () => {
              hideDialog();
            });
          }
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          showToast(error.message, 'Error');
        });
    }
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsValidForm(false); // 삽입 후 신규, 수정 버튼 비활성화
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsNew(false);
    setIsEmptyForm(true);
  };

  const handleDeleteProxyFeeCode = async () => {
    // 수정 버튼 클릭 시 textField 값들을 db에서 찾아 수정
    console.log('###### Delete Proxy Fee Code');
    if (proxyFeeCodeInfo.feeInfoDivisionCode === '1') {
      await apolloClient
        .mutate({
          //  textField 값들을 변수로 지정하여 수정
          variables: {
            input: {
              proxyFeeCodeNo: proxyFeeCodeInfo.proxyFeeCodeNo,
              deleteYn: proxyFeeCodeInfo.deleteYn === 'Y' ? 'N' : 'Y'
            }
          },
          mutation: proxyFeeCodeMutation.deleteProxyFeeCodeInfo
        })
        .then(res => {
          setProxyFeeCodeInfo({
            proxyFeeGroupCode: "1", // 서비스 수수료 그룹
            proxyFeeCodeNo: '', // 서비스 수수료 코드
            proxyFeeNm: '', // 수수료명
            unitFeeCodeNo: '', // 단위항목코드
            unitFeeName: '', // 단위항목명
            feeClaimMethodDivisionCode: "1", // 수수료청구방식
            applyUnitprc: 0, // 적용단가
            feeBaseRate: 0.0, // 수수료 기준율
            feeProofDivisionCode: '', // 수수료 증빙 구분
            feeAutoYn: "Y", // 자동대사 여부
            validateStartDate: format(new Date(), 'yyyyMMdd'), // 유효시작일자
    validateEndDate: format(addMonths(new Date(), 1),'yyyyMMdd'), // 유효종료일자
            deleteYn: "N", // 삭제여부
            domesticCode: "1", // 국내외 구분
            feeInfoDivisionCode: '', // 대행 수수료 구분
            domesticBrandDivisionCode: "1" // 국내외 상품 구분 - details만
          });
          hideDialog();
          showDialog('대행 수수료 코드 정보', '삭제되었습니다\n\n', () => {
            hideDialog();
          });
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          showToast(error.message, 'Error');
        });
    } else {
      await apolloClient
        .mutate({
          //  textField 값들을 변수로 지정하여 수정
          variables: {
            input: {
              unitFeeCodeNo: proxyFeeCodeInfo.unitFeeCodeNo,
              deleteYn: proxyFeeCodeInfo.deleteYn === 'Y' ? 'N' : 'Y'
            }
          },
          mutation: proxyFeeCodeMutation.deleteUnitFeeCodeInfo
        })
        .then(res => {
          setProxyFeeCodeInfo({
            // 삽입에 성공하면 리스트에 저장
            ...proxyFeeCodeInfo,
            proxyFeeGroupCode: '', // 서비스 수수료 그룹
            proxyFeeCodeNo: '', // 서비스 수수료 코드
            proxyFeeNm: '', // 수수료명
            unitFeeCodeNo: '', // 단위항목코드
            unitFeeName: '', // 단위항목명
            feeClaimMethodDivisionCode: '', // 수수료청구방식
            applyUnitprc: 0, // 적용단가
            feeBaseRate: 0, // 수수료 기준율
            feeProofDivisionCode: '', // 수수료 증빙 구분
            feeAutoYn: '', // 자동대사 여부
            validateStartDate: '', // 유효시작일자
            validateEndDate: '', // 유효종료일자
            deleteYn: '', // 삭제여부
            domesticCode: '', // 국내외 구분
            feeInfoDivisionCode: '', // 대행 수수료 구분
            domesticBrandDivisionCode: '' // 국내외 상품 구분 - details만
          });
          hideDialog();
          showDialog('대행 수수료 코드 정보', '삭제되었습니다\n\n', () => {
            hideDialog();
          });
        })
        .catch(error => {
          console.log(error);
          hideDialog();
          showToast(error.message, 'Error');
        });
    }
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsValidForm(false); // 삽입 후 신규, 수정 버튼 비활성화
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsNew(false);
    setIsEmptyForm(true);
  };

  useEffect(() => {
    checkFormEmpty();
    checkFormValidation();
  });

  const initTextFields = () => {
    setProxyFeeCodeInfo({
        proxyFeeGroupCode: "1", // 서비스 수수료 그룹
        proxyFeeCodeNo: '', // 서비스 수수료 코드
        proxyFeeNm: '', // 수수료명
        unitFeeCodeNo: '', // 단위항목코드
        unitFeeName: '', // 단위항목명
        feeClaimMethodDivisionCode: "1", // 수수료청구방식
        applyUnitprc: 0, // 적용단가
        feeBaseRate: 0.0, // 수수료 기준율
        feeProofDivisionCode: '', // 수수료 증빙 구분
        feeAutoYn: "Y", // 자동대사 여부
        validateStartDate: format(new Date(), 'yyyyMMdd'), // 유효시작일자
    validateEndDate: format(addMonths(new Date(), 1),'yyyyMMdd'), // 유효종료일자
        deleteYn: "N", // 삭제여부
        domesticCode: "1", // 국내외 구분
        feeInfoDivisionCode: '', // 대행 수수료 구분
        domesticBrandDivisionCode: "1" // 국내외 상품 구분 - details만
      });

    setIsEmptyForm(true);
    setIsValidForm(false);
    setUpdateClick(!updateClick);
    setIsNew(false);
  };

  const [proxyFeeCodeList, setProxyFeeCodeList] = useState<Array<FeeCodeInfoReturn>>([]);

  const selectProxyFeeCodeList = async () => {
      await apolloClient
      .mutate({
          variables: {input: {
            feeCodeNo: searchProxyFeeCode.proxyFeeCodeNo,
            feeCodeName: searchProxyFeeCode.proxyFeeCodeNm,
          }},
          mutation: proxyFeeCodeMutation.selectProxyFeeCodeList
      })
      .then(res => {
          setProxyFeeCodeList(res.data.selectProxyFeeCodeList);
      })
      .catch(console.error);
  }

  const proxyFeeCodeSearchPop = () => {

    if (proxyFeeCodeInfo.feeInfoDivisionCode === '2') {
        return (
        <a href='#!'>
        <TextField
        size="small"
        style={{ width: '70%', float: 'left' }}
        label="위임 수수료 코드"
        name="proxyFeeCodeNo"
        required
        disabled
        onChange={event => {
          handleChange(event);
          checkFormValidation();
          checkFormEmpty();
        }}
        value={proxyFeeCodeInfo.proxyFeeCodeNo}
        variant="outlined"
        error={isEmpty(proxyFeeCodeInfo.proxyFeeCodeNo)}
        helperText={
          isEmpty(proxyFeeCodeInfo.proxyFeeCodeNo)
            ? 'Required'
            : ''
        }
        InputLabelProps={{
          shrink: true
        }}
      />
      <Button
      style={{marginLeft: '8px', float: 'right'}}
        variant="contained"
        color="primary"
        onClick={() => {setOpen(true); selectProxyFeeCodeList(); SetSearchProxyFeeCode({proxyFeeCodeNm: '', proxyFeeCodeNo: ''})}}
        >Search</Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>위임수수료 코드 조회</DialogTitle>
            <hr />
            <DialogContent>
            <p style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
            위임수수료코드
            <TextField
              style={{ width: '15%', marginRight: 20, marginLeft: 20 }}
              variant="outlined"
              onChange={(event) => SetSearchProxyFeeCode({...searchProxyFeeCode, proxyFeeCodeNo: event.target.value})}
              size="small"
            />
            위임수수료명
            <TextField
              style={{ width: '25%', marginRight: 20, marginLeft: 20 }}
              onChange={(event) => SetSearchProxyFeeCode({...searchProxyFeeCode, proxyFeeCodeNm: event.target.value})}
              variant="outlined"
              size="small"
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => selectProxyFeeCodeList()}
            >Search</Button>
            </p>
            <Box>
                <PerfectScrollbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell align="center">위임수수료코드</TableCell>
                            <TableCell align="center">위임수수료명</TableCell>
                            <TableCell align="center">사용</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {proxyFeeCodeList.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item) => (
                            <TableRow hover key={item.proxyFeeCodeNo}>
                        <TableCell align="center">{item.proxyFeeCodeNo}</TableCell>
                        <TableCell align="center">{item.proxyFeeNm}</TableCell>
                        <TableCell align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {setProxyFeeCodeInfo({...proxyFeeCodeInfo, proxyFeeCodeNo: item.proxyFeeCodeNo, proxyFeeNm: item.proxyFeeNm, proxyFeeGroupCode: item.proxyFeeGroupCode}); setOpen(false);}}
                            >사용</Button>
                            </TableCell>
                                </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </Box>
            </DialogContent>
            <DialogActions>
                <TablePagination
            style={{ marginLeft: 'auto' }}
            component="div"
            count={proxyFeeCodeList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            />
            <Button 
            variant="contained"
            color="primary"
            onClick={() => {setOpen(false); SetSearchProxyFeeCode({proxyFeeCodeNm: '', proxyFeeCodeNo: ''});}}
            >cancle</Button>
            </DialogActions>
        </Dialog>

        </a>
        );
    } else {
        return (
            <a href='#!'>
            <TextField
            size="small"
            style={{ width: '100%' }}
            label="위임 수수료 코드"
            name="proxyFeeCodeNo"
            required
            onChange={event => {
              handleChange(event);
              checkFormValidation();
              checkFormEmpty();
            }}
            value={proxyFeeCodeInfo.proxyFeeCodeNo}
            variant="outlined"
            error={(proxyFeeCodeInfo.feeInfoDivisionCode !== '') && (isEmpty(proxyFeeCodeInfo.proxyFeeCodeNo))}
            helperText={
              isEmpty(proxyFeeCodeInfo.proxyFeeCodeNo)
                ? 'Required'
                : ''
            }
            InputLabelProps={{
              shrink: true
            }}
          />
          <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>위임 수수료 목록</DialogTitle>
              <hr />
              <DialogContent>
              <p style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
                  위임수수료
                  </p>
              </DialogContent>
              </Dialog>
          </a>
          );
    }
  }

  const checkFeeBaseRate = () => {
    let feeBaseRateStr: string = proxyFeeCodeInfo.feeBaseRate.toString();
      if (proxyFeeCodeInfo.feeBaseRate >= 1) {
        alert('1 이하의 소수만 입력됩니다.');  
        feeBaseRateStr = '0.' + feeBaseRateStr.substr(2, 2);
        setProxyFeeCodeInfo({...proxyFeeCodeInfo, feeBaseRate: parseFloat(feeBaseRateStr)})
      }
    
    if(feeBaseRateStr.substring(feeBaseRateStr.indexOf('.') + 1).length > 3) {
        alert('소수점 3자리까지만 입력됩니다.');
        console.log('feeBaseRate : ', feeBaseRateStr);
        feeBaseRateStr = feeBaseRateStr.substr(0, 5)
        setProxyFeeCodeInfo({...proxyFeeCodeInfo, feeBaseRate: parseFloat(feeBaseRateStr)})
    }

  }

  const checkApplyUnitprc = () => {
    if(proxyFeeCodeInfo.applyUnitprc < 0) {
        alert('0 이상의 숫자만 입력됩니다.');
        setProxyFeeCodeInfo({...proxyFeeCodeInfo, applyUnitprc: 0})
    }
  }





  return (
    <div>
      <Card>
        <CardContent className={classes.content}>
          <div className={classes.content}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.headers}>
                    대행수수료구분
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      fullWidth
                      label="대행 수수료 구분"
                      margin="dense"
                      name="feeInfoDivisionCode"
                      onChange={handleChange}
                      required
                      variant="outlined"
                      select
                      error={isEmpty(proxyFeeCodeInfo.feeInfoDivisionCode)}
                      helperText={
                        isEmpty(proxyFeeCodeInfo.feeInfoDivisionCode)
                          ? 'Required'
                          : ''
                      }
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={proxyFeeCodeInfo.feeInfoDivisionCode}>
                      <option value=""></option>
                      <option value="1">위임</option>
                      <option value="2">단위</option>
                    </TextField>
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    위임 수수료 코드
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                      {proxyFeeCodeSearchPop()}
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    위임수수료명
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="위임수수료명"
                      required
                      name="proxyFeeNm"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === "2"}
                      value={proxyFeeCodeInfo.proxyFeeNm}
                      variant="outlined"
                      error={(proxyFeeCodeInfo.feeInfoDivisionCode !== '') && (isEmpty(proxyFeeCodeInfo.proxyFeeNm))}
                      helperText={
                        isEmpty(proxyFeeCodeInfo.proxyFeeNm) ? 'Required' : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.headers}>
                    삭제여부
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      size="small"
                      style={{ width: '100%' }}
                      label="삭제여부"
                      name="deleteYn"
                      required
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.deleteYn}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}>
                      <option value="N">미삭제</option>
                      <option value="Y">삭제</option>
                    </TextField>
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    단위항목코드
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="단위항목코드"
                      name="unitFeeCodeNo"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.unitFeeCodeNo}
                      variant="outlined"
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      error={
                        isEmpty(proxyFeeCodeInfo.unitFeeCodeNo) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                      }
                      helperText={
                        isEmpty(proxyFeeCodeInfo.unitFeeCodeNo) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                          ? 'Required'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    단위수수료명
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="단위수수료명"
                      name="unitFeeName"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.unitFeeName}
                      variant="outlined"
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      error={
                        isEmpty(proxyFeeCodeInfo.unitFeeName) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                      }
                      helperText={
                        isEmpty(proxyFeeCodeInfo.unitFeeName) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                          ? 'Required'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.headers}>
                    서비스 수수료 그룹
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      label="서비스 수수료 그룹"
                      name="proxyFeeGroupCode"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      variant="outlined"
                      fullWidth
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === "2"}
                      margin="dense"
                      required
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={proxyFeeCodeInfo.proxyFeeGroupCode}>
                      <option value="1">승인</option>
                      <option value="2">회원</option>
                      <option value="3">발급카드</option>
                    </TextField>
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    유효시작일자
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      type="date"
                      style={{ width: '100%' }}
                      label="유효시작일자"
                      name="validateStartDate"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={chaiStringUtils.dateFormat(
                        proxyFeeCodeInfo.validateStartDate,
                        ''
                      )}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    유효종료일자
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      type="date"
                      style={{ width: '100%' }}
                      label="유효종료일자"
                      name="validateEndDate"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={chaiStringUtils.dateFormat(
                        proxyFeeCodeInfo.validateEndDate,
                        ''
                      )}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.headers}>
                    국내외구분
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="국내외구분"
                      name="domesticCode"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.domesticCode}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      select
                      SelectProps={{ native: true }}>
                      <option value="1">국내</option>
                      <option value="2">해외</option>
                    </TextField>
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    국내외상품구분
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="국내외상품구분"
                      name="domesticBrandDivisionCode"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.domesticBrandDivisionCode}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      select
                      SelectProps={{ native: true }}>
                      <option value="1">국내</option>
                      <option value="9">공통</option>
                    </TextField>
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    수수료 청구 방식
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      label="수수료 청구 방식"
                      name="feeClaimMethodDivisionCode"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={proxyFeeCodeInfo.feeClaimMethodDivisionCode}>
                      <option value="1">정액</option>
                      <option value="2">단가</option>
                      <option value="3">구간</option>
                      <option value="4">정률</option>
                      <option value="5">복합</option>
                    </TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.headers}>
                    적용단가
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="적용단가"
                      type="number"
                      name="applyUnitprc"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                        checkApplyUnitprc();
                      }}
                      value={
                        Math.round(proxyFeeCodeInfo.applyUnitprc * 100) / 100
                      }
                      variant="outlined"
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      error={
                        (proxyFeeCodeInfo.applyUnitprc === '' ||
                          proxyFeeCodeInfo.applyUnitprc === 0) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                      }
                      helperText={
                        (proxyFeeCodeInfo.applyUnitprc === '' ||
                          proxyFeeCodeInfo.applyUnitprc === 0) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                          ? 'Required'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{min: '0', step: '100'}}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    기준율
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      type="number"
                      style={{ width: '100%' }}
                      label="기준율"
                      name="feeBaseRate"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                        checkFeeBaseRate();
                      }}
                      value={proxyFeeCodeInfo.feeBaseRate}
                      variant="outlined"
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      error={
                        (proxyFeeCodeInfo.feeBaseRate === '' ||
                          proxyFeeCodeInfo.feeBaseRate === 0) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                      }
                      helperText={
                        (proxyFeeCodeInfo.feeBaseRate === '' ||
                          proxyFeeCodeInfo.feeBaseRate === 0) &&
                        proxyFeeCodeInfo.feeInfoDivisionCode === '2'
                          ? 'Required'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{max: '1', step: '0.001', min: '0'}}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.headers}>
                    자동대사여부
                  </TableCell>
                  <TableCell align="center" className={classes.values}>
                    <TextField
                      size="small"
                      style={{ width: '100%' }}
                      label="자동대사여부"
                      name="feeAutoYn"
                      onChange={event => {
                        handleChange(event);
                        checkFormValidation();
                        checkFormEmpty();
                      }}
                      value={proxyFeeCodeInfo.feeAutoYn}
                      disabled={proxyFeeCodeInfo.feeInfoDivisionCode === '1'}
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true
                      }}>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </TextField>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </div>
          <Box
            style={{
              float: 'right',
              marginTop: 10,
              marginBottom: 10
            }}>
            <Button
              
              variant="contained"
              color="primary"
              onClick={event => {
                initTextFields();
              }}
              style={{ marginRight: 10 }}
              disabled={isEmptyForm}
            >Reset</Button>
            <Button
              
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              disabled={!isValidForm}
              onClick={() =>
                showDialog(
                  '대행 수수료 코드 정보',
                  '해당 정보로 저장하시겠습니까?',
                  handleAddProxyFeeCode
                )
              }
            >Add</Button>
            <Button
              children="Edit"
              variant="contained"
              color="primary"
              disabled={!isNew}
              onClick={() =>
                showDialog(
                  '대행 수수료 코드 정보',
                  '해당 정보로 저장하시겠습니까?',
                  handleUpdateProxyFeeCode
                )
              }
              style={{ marginRight: 10 }}
            />
            <Button
              
              variant="contained"
              color="primary"
              onClick={() =>
                showDialog(
                  '대행 수수료 코드 정보',
                  '해당 코드를 삭제하시겠습니까?',
                  handleDeleteProxyFeeCode
                )
              }
              disabled={!isNew}
              style={{ marginRight: 10 }}
            >
                {proxyFeeCodeInfo.deleteYn === 'N' ? 'Remove' : 'Restore'
              }</Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  values: {
    backgroundColor: 'white',
    width: '20%'
  },
  headers: {
    width: '10%'
  }
}));

export default ProxyFeeCodeDetails;

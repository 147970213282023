import { makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import apolloClient, { overseasPurchasedAggrHistoryMutation } from '../../lib/apolloClient';
import OverseasPurchasedAggrHistorySumTable from './OverseasPurchasedAggrHistorySumTable';
import OverseasPurchasedAggrHistoryTable from './OverseasPurchasedAggrHistoryTable';
import OverseasPurchasedAggrHistoryToolbar from './OverseasPurchasedAggrHistoryToolbar';

//기본 스타일를 가져옴(깡통인데 옵션조금있음)
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
}));

type Props = {
  listInfo: OverseasPurchasedAggrHistoryListReturnType;
};

const OverseasPurchasedAggrHistory: FC<Props> = ({ listInfo }) => {
  const classes = useStyles();
  const [data, setOPAIData] = useState(listInfo);

  const [searchForm, setSearchForm] = useState({
    inquiryDate: format(new Date(), 'yyyy-MM-dd'),
    totalCount: 0,
  });

  const opaiSum: OverseasPurchasedAggrHistorySum = {
    settlement_usd_withdrawal_count: 0,
    settlement_usd_deposit_count: 0,
    settlement_usd_withdrawal_total_amount: 0,
    settlement_usd_deposit_total_amount: 0,
    settlement_usd_trade_total_amount: 0,
    settlement_krw_withdrawal_total_amount: 0,
    settlement_krw_deposit_total_amount: 0,
    settlement_krw_trade_total_amount: 0,
    settlement_usd_fee_withdrawal_total_amount: 0,
    settlement_usd_fee_deposit_total_amount: 0,
    settlement_usd_fee_trade_total_amount: 0,
    settlement_krw_fee_withdrawal_total_amount: 0,
    settlement_krw_fee_deposit_total_amount: 0,
    settlement_krw_fee_trade_total_amount: 0,
    brand_usd_fee_withdrawal_total_amount: 0,
    brand_usd_fee_deposit_total_amount: 0,
    brand_krw_fee_withdrawal_total_amount: 0,
    brand_krw_fee_deposit_total_amount: 0,
    overseas_use_usd_fee_withdrawal_total_amount: 0,
    overseas_use_usd_fee_deposit_total_amount: 0,
    overseas_use_krw_fee_withdrawal_total_amount: 0,
    overseas_use_krw_fee_deposit_total_amount: 0,
    overseas_withdrawal_usd_fee_withdrawal_total_amount: 0,
    overseas_withdrawal_usd_fee_deposit_total_amount: 0,
    overseas_withdrawal_krw_fee_withdrawal_total_amount: 0,
    overseas_withdrawal_krw_fee_deposit_total_amount: 0,
    exchange_rate_diff_profit_amount: 0,
    exchange_rate_diff_loss_amount: 0,
    exchange_rate_diff_total_amount: 0,
    deal_base_exchange_rate: 0,
  };

  const [sumInfo, setSumInfo] = useState({
    totalCount: 0,
    opaiSum: opaiSum,
  });

  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await apolloClient
      .mutate({
        variables: {
          inquiryDate: searchForm.inquiryDate,
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
        },
        mutation: overseasPurchasedAggrHistoryMutation.selectOverseasPurchasedAggrHistoryList,
      })
      .then(res => {
        const opais = res.data.selectOverseasPurchasedAggrHistoryList;
        setSumInfo(opais);
        setOPAIData(opais);
      })
      .catch(console.error);
    setLoading(false);
  };

  // 페이징
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외매입내역집계조회" />
      <OverseasPurchasedAggrHistoryToolbar
        onSearch={fetch}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        paging={paging}
        setPaging={setPaging}
      />

      <div className={classes.content}>
        {loading ? (
          utils.Loading()
        ) : (
          <OverseasPurchasedAggrHistoryTable opais={data} paging={paging} setPaging={setPaging} />
        )}
      </div>
      <div className={classes.content}>
        <OverseasPurchasedAggrHistorySumTable title="해외매입집계내역" sumInfo={sumInfo.opaiSum} />
      </div>
    </div>
  );
};

export default OverseasPurchasedAggrHistory;

import gql from 'graphql-tag';

// 해외수수료기준관리
// 해외수수료기준목록 조회
export const cardOverseaFeeBase = gql`
    mutation selectOverseasFeeBaseList($input : overseasFeeBaseSearch) {
        selectOverseasFeeBaseList(input : $input){
            totalCount
            list{
                cardProductCodeName
                cardProductGroupCode
                cardProductCode
                brandDivisionCode
                overseasFeeDivisionCode
                feeApplyDate
                feeApplyDivisionCode
                basicFeeValue
                useYn
                waivedYn
                firstOperateAdminId
                firstOperateDetailDate
                firstOperateTradeCode
                lastOperateAdminId
                lastOperateDetailDate
                lastOperateTradeCode
            }
        }
    }
`;

// 해외수수료기준이력 조회
export const cardOverseaFeeBaseHistory = gql`
    mutation selectOverseasFeeBaseHistoryList($input : overseasFeeBaseSearch) {
      selectOverseasFeeBaseHistoryList(input : $input){
        totalCount
        list{
          changeDate
          changeTime
          cardProductGroupCode
          cardProductCode
          brandDivisionCode
          overseasFeeDivisionCode
          feeApplyDate
          feeApplyDivisionCode
          basicFeeValue
          useYn
          waivedYn
          lastOperateAdminId
        }
      }
    }
`;

// 해외수수료기준등록
export const insertCardFee = gql`
  mutation insertOverseasFeeBase($input: overseasFeeBaseCreate ) {
    insertOverseasFeeBase(input: $input) {
      success
      message
    }
  }
`;

// 해외수수료기준수정
export const updateCardFee = gql`
  mutation updateOverseasFeeBase($input: overseasFeeBaseCreate ) {
    updateOverseasFeeBase(input: $input) {
      success
      message
    }
  }
`;

// 해외수수료기준삭제
export const deleteCardFee = gql`
  mutation deleteOverseasFeeBase($input: overseasFeeBaseCreate ) {
    deleteOverseasFeeBase(input: $input) {
      success
      message
    }
  }
`;

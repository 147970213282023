import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  setInputSearchGubun: React.Dispatch<React.SetStateAction<string>>;
  setInputSearchVal: React.Dispatch<React.SetStateAction<string>>;
};
const CardDeliveryInfoToolbar: FC<Props> = ({ setInputSearchGubun, setInputSearchVal }) => {
  // const classes = useStyles();
  const [searchGubun, setSearchGubun] = useState(chaiStringUtils.getSchParamGubun());
  const [searchVal, setSearchVal] = useState(chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun())); // 조회 값

  // 셀렉트박스 변경이벤트
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchGubun(event.target.value as string);
  };

  // 조회값 변경 이베트
  const searchValHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  // 목록 조회
  const onSearchClick = async () => {
    setInputSearchGubun(searchGubun); // 조회 구분
    setInputSearchVal(searchVal); // 조회 값
    chaiStringUtils.setSchParamGubun(searchGubun);
    chaiStringUtils.setSchParamSearchVal(searchGubun, searchVal);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <TextField
              select
              fullWidth
              label="조회구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchGubun"
              value={searchGubun}
              onChange={handleChange}
            >
              <option value="1">휴대폰 번호</option>
              <option value="2">카드번호</option>
              <option value="3">고객번호</option>
            </TextField>
          </Grid>
          <Grid item md={4} xs={10}>
            <TextField
              fullWidth
              label="조회 값"
              placeholder="조회 값"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchVal"
              value={searchVal}
              onChange={searchValHandleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style={{
                marginTop: '8px',
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={1}></Grid>
      </CardContent>
    </Card>
  );
};

export default CardDeliveryInfoToolbar;

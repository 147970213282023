import React, { useState, useContext, createContext, useEffect } from 'react';
import store from 'store';

import apolloClient from './lib/apolloClient';
import { authMutation } from './lib/apolloClient/mutations';

const authContext = createContext<AuthType>({
  user: undefined,
  signIn: async () => {},
  signOut: async () => {},
  checkAuth: async () => {
    return false;
  },
});

interface IAuthUser {
  adminId: string;
  permission: {
    permissions: string[];
    isCustomerService: boolean;
    hasCardPermission: boolean;
  };
}

type AuthType = {
  user?: IAuthUser;
  signIn: (variables: IAuthSignIn) => Promise<void>;
  signOut: () => Promise<void>;
  checkAuth: () => Promise<boolean>;
};

export interface IAuthSignIn {
  token: string; // 로그인 토큰 / 로컬 로그인 시 'local' 사용
  localLoginType?: string; // 로컬 로그인 용 adminType, admin|cs
}

export function AuthProvider({ children }: { children: any }) {
  const storeAuth = store.get('admin');
  const [user, setUser] = useState(storeAuth?.data?.auth);
  const [firstAuthChecked, setFirstAuthChecked] = useState(false);

  const signIn = async (variables: IAuthSignIn) => {
    try {
      const res = await apolloClient.query({
        query: authMutation.authMutation,
        variables,
      });

      // 로그인 성공 시
      if (res?.data?.auth?.adminId) {
        store.set('admin', res);
        setUser(res.data.auth);
        return;
      }
    } catch (e) {
      console.error(e);
    }

    deleteAuth();
    throw new Error('로그인에 실패하였습니다');
  };

  const signOut = async () => {
    try {
      await apolloClient.query({
        query: authMutation.logoutMutation,
      });
      deleteAuth();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteAuth = () => {
    store.remove('admin');
    setUser(undefined);
  };

  const checkAuth = async () => {
    try {
      const res = await apolloClient.query({
        query: authMutation.authExtendMutation,
      });
      if (res?.data?.authExtend !== 'ok') {
        deleteAuth();
      }
    } catch (e) {
      deleteAuth();
    }
    return false;
  };

  const auth = {
    user,
    signIn,
    signOut,
    checkAuth,
  };

  useEffect(() => {
    auth.checkAuth().then(() => {
      setFirstAuthChecked(true);
    });
    // eslint-disable-next-line
  }, []);

  return <authContext.Provider value={auth}>{firstAuthChecked && children}</authContext.Provider>;
}

export function useAuth(): AuthType {
  return useContext<AuthType>(authContext);
}

import React , {FC,ChangeEvent} from 'react';
import {
  Theme,
  makeStyles,
  Card,
  CardContent,  
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,  
  TextField
} from '@material-ui/core';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';
import apolloClient from '../../lib/apolloClient';
import { cardOverseaFeeBaseMutation } from '../../lib/apolloClient/mutations';

  type Props = {
    cardOverseFeeBaseMgmt: selectOverseasFeeBaseList,
    setCardOverseFeeBaseMgmt: React.Dispatch<React.SetStateAction<selectOverseasFeeBaseList>>,
    updateClick: boolean,
    setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
    searchForm: CardOverseaFeeBaseSearchForm,
    onSearch: any
  };

  const CardOverseaFeeBaseDetails: FC<Props> = ({ cardOverseFeeBaseMgmt, setCardOverseFeeBaseMgmt, searchForm, onSearch, updateClick, setUpdateClick }) => {
    const classes = useStyles();
    const { showDialog, hideDialog } = useDialog();
    const { showToast } = useToast();

    // 변경이벤트 
    const handleChange = (e: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
      setCardOverseFeeBaseMgmt({
          ...cardOverseFeeBaseMgmt,
          [e.target.name] : e.target.value
        });
    };

    // disabled
    const isDisabled = () => {
      return updateClick;
    };

    // Reset
    const DetailResetClick = async() => {

      setUpdateClick(false);

      setCardOverseFeeBaseMgmt({
        ...cardOverseFeeBaseMgmt,
        cardProductGroupCode: '',                       // 카드상품그룹
        cardProductCode: '',                            // 카드상품코드
        brandDivisionCode: '',                          // 브랜드구분
        overseasFeeDivisionCode: '',                    // 해외수수료구분
        feeApplyDate: '',                               // 수수료적용일자
        feeApplyDivisionCode: '',                       // 수수료적용구분
        useYn: '',                                      // 사용여부
        waivedYn: '',                                   // 면제여부
        basicFeeValue: 0,                               // 기본수수료값     
        cardProductCodeName: '',                        // 상품코드명
        firstOperateAdminId: '',                        // 최초조작직원번호
        firstOperateDetailDate: '',                     // 최조조작상세일시
        firstOperateTradeCode: '',                      // 최초조작거래코드
        lastOperateAdminId: '',                         // 최종조작직원번호
        lastOperateDetailDate: '',                      // 최종조작상세일시
        lastOperateTradeCode: ''                        // 최종조작거래코드
      });
    }

    // 해외수수료기준등록
    const insertCardFeeHandle = async () => {
      console.log('<<<:해외수수료기준등록 cardOverseFeeBaseMgmt:',cardOverseFeeBaseMgmt);
      if(cardOverseFeeBaseMgmt.cardProductGroupCode === null || cardOverseFeeBaseMgmt.cardProductGroupCode === '') {
        showToast('카드상품그룹을 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.cardProductCode === null || cardOverseFeeBaseMgmt.cardProductCode === '') {
        showToast('카드상품코드를 입력해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.waivedYn === null || cardOverseFeeBaseMgmt.waivedYn === '') {
        showToast('면제여부를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.brandDivisionCode === null || cardOverseFeeBaseMgmt.brandDivisionCode === '') {
        showToast('브랜드구분코드를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.overseasFeeDivisionCode === null || cardOverseFeeBaseMgmt.overseasFeeDivisionCode === '') {
        showToast('해외수수료구분코드를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.feeApplyDate === null || cardOverseFeeBaseMgmt.feeApplyDate === '') {
        showToast('수수료적용일자를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.feeApplyDivisionCode === null || cardOverseFeeBaseMgmt.feeApplyDivisionCode === '') {
        showToast('수수료적용구분코드를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.basicFeeValue === null || cardOverseFeeBaseMgmt.basicFeeValue === 0) {
        showToast('기본수수료값을 입력해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.useYn === null || cardOverseFeeBaseMgmt.useYn === '') {
        showToast('사용여부를 선택해주세요.','error');
        hideDialog();
        return;
      }

      // 기본수수료 0 이상 조건
      if(cardOverseFeeBaseMgmt.basicFeeValue < 0){
        showToast('기본수수료값은 0이상 등록 가능합니다.','error');
        hideDialog();
        return;
      }

      await apolloClient.mutate({
        variables : {
          input:{
            cardProductGroupCode: cardOverseFeeBaseMgmt.cardProductGroupCode, // 카드상품그룹
            cardProductCode: cardOverseFeeBaseMgmt.cardProductCode, // 카드상품코드
            waivedYn: cardOverseFeeBaseMgmt.waivedYn, // 면제여부
            brandDivisionCode: cardOverseFeeBaseMgmt.brandDivisionCode, // 브랜드구분
            overseasFeeDivisionCode: cardOverseFeeBaseMgmt.overseasFeeDivisionCode,// 해외수수료구분
            feeApplyDate: cardOverseFeeBaseMgmt.feeApplyDate,// 수수료적용일자
            feeApplyDivisionCode: cardOverseFeeBaseMgmt.feeApplyDivisionCode,// 수수료적용구분
            basicFeeValue: Number(cardOverseFeeBaseMgmt.basicFeeValue), // 기본수수료값
            useYn: cardOverseFeeBaseMgmt.useYn // 사용여부
          }
        },
        mutation : cardOverseaFeeBaseMutation.insertCardFee
      }).then(res => {
        console.log('결과: ', res.data.insertOverseasFeeBase);
        hideDialog();

        if(res.data.insertOverseasFeeBase.success === 'Y') {
          showToast(res.data.insertOverseasFeeBase.message);
          // 목록 리로드
          onSearch(searchForm);
        } else {
          showToast(res.data.insertOverseasFeeBase.message,'error');
        }

        setCardOverseFeeBaseMgmt({
          ...cardOverseFeeBaseMgmt,
          cardProductGroupCode:'',
          cardProductCode:'',
          waivedYn:'',
          brandDivisionCode:'',
          overseasFeeDivisionCode:'',
          feeApplyDate:'',
          feeApplyDivisionCode:'',
          basicFeeValue: 0,
          useYn:''
        });
      }).catch(e =>{
        console.log(e);
        hideDialog();
        showToast(e.message, 'Error');
      });
    }

    // 해외수수료기준 수정
    const updateCardFeeHandle = async () => {
      console.log('<<< 해외수수료기준수정 cardOverseFeeBaseMgmt:',cardOverseFeeBaseMgmt);

      if(cardOverseFeeBaseMgmt.feeApplyDivisionCode === null || cardOverseFeeBaseMgmt.feeApplyDivisionCode === '') {
        showToast('수수료적용구분코드를 선택해주세요.','error');
        hideDialog();
        return;
      }
      if(cardOverseFeeBaseMgmt.basicFeeValue === null || cardOverseFeeBaseMgmt.basicFeeValue === 0) {
        showToast('기본수수료값을 입력해주세요.','error');
        hideDialog();
        return;
      }
      // 기본수수료 0 이상 조건
      if(cardOverseFeeBaseMgmt.basicFeeValue < 0){
        showToast('기본수수료값은 0이상 수정 가능합니다.','error');
        hideDialog();
        return;
      }

      await apolloClient.mutate({
        variables : {
          input:{
            cardProductGroupCode: cardOverseFeeBaseMgmt.cardProductGroupCode, // 카드상품그룹
            cardProductCode: cardOverseFeeBaseMgmt.cardProductCode, // 카드상품코드
            waivedYn: cardOverseFeeBaseMgmt.waivedYn, // 면제여부
            brandDivisionCode: cardOverseFeeBaseMgmt.brandDivisionCode, // 브랜드구분
            overseasFeeDivisionCode: cardOverseFeeBaseMgmt.overseasFeeDivisionCode,// 해외수수료구분
            feeApplyDate: cardOverseFeeBaseMgmt.feeApplyDate,// 수수료적용일자
            feeApplyDivisionCode: cardOverseFeeBaseMgmt.feeApplyDivisionCode,// 수수료적용구분
            basicFeeValue: Number(cardOverseFeeBaseMgmt.basicFeeValue), // 기본수수료값
            useYn: cardOverseFeeBaseMgmt.useYn // 사용여부
          }
        },
        mutation : cardOverseaFeeBaseMutation.updateCardFee
      }).then(res => {
        console.log('결과: ', res.data.updateOverseasFeeBase);
        hideDialog();

        if(res.data.updateOverseasFeeBase.success === 'Y') {
          showToast(res.data.updateOverseasFeeBase.message);
          // 목록 리로드
          onSearch(searchForm);
        } else {
          showToast(res.data.updateOverseasFeeBase.message,'error');
        }

        setCardOverseFeeBaseMgmt({
          ...cardOverseFeeBaseMgmt,
          cardProductGroupCode:'',
          cardProductCode:'',
          waivedYn:'',
          brandDivisionCode:'',
          overseasFeeDivisionCode:'',
          feeApplyDate:'',
          feeApplyDivisionCode:'',
          basicFeeValue: 0,
          useYn:''
        });
      }).catch(e =>{
        console.log(e);
        hideDialog();
        showToast(e.message, 'Error');
      });
    }

    // 해외수수료기준 삭제
    const deleteCardFeeHandle = async () => {
      console.log('<<< 해외수수료기준 삭제 cardOverseFeeBaseMgmt:',cardOverseFeeBaseMgmt);

      await apolloClient.mutate({
        variables : {
          input:{
            cardProductGroupCode: cardOverseFeeBaseMgmt.cardProductGroupCode, // 카드상품그룹
            cardProductCode: cardOverseFeeBaseMgmt.cardProductCode, // 카드상품코드
            waivedYn: cardOverseFeeBaseMgmt.waivedYn, // 면제여부
            brandDivisionCode: cardOverseFeeBaseMgmt.brandDivisionCode, // 브랜드구분
            overseasFeeDivisionCode: cardOverseFeeBaseMgmt.overseasFeeDivisionCode,// 해외수수료구분
            feeApplyDate: cardOverseFeeBaseMgmt.feeApplyDate,// 수수료적용일자
            feeApplyDivisionCode: cardOverseFeeBaseMgmt.feeApplyDivisionCode,// 수수료적용구분
            basicFeeValue: Number(cardOverseFeeBaseMgmt.basicFeeValue), // 기본수수료값
            useYn: cardOverseFeeBaseMgmt.useYn // 사용여부
          }
        },
        mutation : cardOverseaFeeBaseMutation.deleteCardFee
      }).then(res => {
        console.log('결과: ', res.data.deleteOverseasFeeBase);
        hideDialog();

        if(res.data.deleteOverseasFeeBase.success === 'Y') {
          showToast(res.data.deleteOverseasFeeBase.message);
          // 목록 리로드
          onSearch(searchForm);
        } else {
          showToast(res.data.deleteOverseasFeeBase.message,'error');
        }

        setCardOverseFeeBaseMgmt({
          ...cardOverseFeeBaseMgmt,
          cardProductGroupCode:'',
          cardProductCode:'',
          waivedYn:'',
          brandDivisionCode:'',
          overseasFeeDivisionCode:'',
          feeApplyDate:'',
          feeApplyDivisionCode:'',
          basicFeeValue: 0,
          useYn:''
        });
      }).catch(e =>{
        console.log(e);
        hideDialog();
        showToast(e.message, 'Error');
      });
    }

  return (
    <Card className={classes.content}>
      <CardContent>
        <div className={classes.inner}>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.header}>카드상품그룹</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="카드상품그룹"
                  placeholder="카드상품그룹"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  SelectProps={{
                    native: true
                  }}
                  select
                  name="cardProductGroupCode"
                  value={cardOverseFeeBaseMgmt.cardProductGroupCode}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="000002">국제체크카드</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.header}>카드상품코드</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  style={{width: '60%'}}
                  variant="outlined"
                  margin="dense"
                  label="카드상품코드"
                  placeholder="카드상품코드"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  name="cardProductCode"
                  value={cardOverseFeeBaseMgmt.cardProductCode}
                  onChange={(e) => {handleChange(e);}}
                  ></TextField>
                 
                </TableCell>
                <TableCell className={classes.header}>면제여부</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="면제여부"
                  placeholder="면제여부"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="waivedYn"
                  value={cardOverseFeeBaseMgmt.waivedYn}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.header}>브랜드구분</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="브랜드구분"
                  placeholder="브랜드구분"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }}
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="brandDivisionCode"
                  value={cardOverseFeeBaseMgmt.brandDivisionCode}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="3">MC</option>
                  </TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.header}>해외수수료구분</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="해외수수료구분"
                  placeholder="해외수수료구분"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }}
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="overseasFeeDivisionCode"
                  value={cardOverseFeeBaseMgmt.overseasFeeDivisionCode}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="001">ATM출금수수료</option>
                    <option value="002">ATM조회수수료</option>
                    <option value="003">해외이용수수료</option>
                    <option value="004">브랜드수수료</option>
                    <option value="005">해외이용수수료(ATM)</option>
                    <option value="006">ATM브랜드수수료</option>
                  </TextField>
                </TableCell>
                <TableCell className={classes.header}>수수료적용일자</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  required
                  fullWidth
                  type="date"
                  label="수수료적용일자"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  name="feeApplyDate"
                  value={chaiStringUtils.dateFormatDefault(cardOverseFeeBaseMgmt.feeApplyDate)}
                  onChange={(e) => {handleChange(e);}}
                  />
                </TableCell>
                <TableCell className={classes.header}>수수료적용구분</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="수수료적용구분"
                  placeholder="수수료적용구분"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="feeApplyDivisionCode"
                  value={cardOverseFeeBaseMgmt.feeApplyDivisionCode}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="1">정액($)</option>
                    <option value="2">정률(%)</option>                    
                  </TextField>
                </TableCell>
                <TableCell className={classes.header}>기본수수료값</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  style={{width: '60%'}}
                  variant="outlined"
                  margin="dense"
                  label="기본수수료값"
                  placeholder="기본수수료값"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  name="basicFeeValue"
                  value={cardOverseFeeBaseMgmt.basicFeeValue}
                  onChange={(e) => {handleChange(e);}}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.header}>사용여부</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                  disabled={isDisabled()}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="사용여부"
                  placeholder="사용여부"
                  size="small"
                  InputLabelProps={{
                    shrink: true
                  }} 
                  select
                  SelectProps={{
                    native: true
                  }}
                  name="useYn"
                  value={cardOverseFeeBaseMgmt.useYn}
                  onChange={(e) => {handleChange(e);}}
                  >
                    <option value="">선택</option>
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </TextField>
                </TableCell>
                <TableCell colSpan={4} style={{borderBottom: '0px solid white'}}></TableCell>
                <TableCell colSpan={2} style={{borderBottom: '0px solid white'}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttons}
                    onClick={() => 
                      showDialog(
                        '해외수수료기준삭제',
                        '해당 정보를 삭제하시겠습니까?',
                        deleteCardFeeHandle
                    )}
                    >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttons}
                    onClick={() => 
                      showDialog(
                        '해외수수료기준수정',
                        '해당 정보를 수정하시겠습니까?',
                        updateCardFeeHandle
                    )}
                    >
                    Edit
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttons}
                    onClick={() => 
                      showDialog(
                        '해외수수료기준등록',
                        '해당 정보를 등록하시겠습니까?',
                        insertCardFeeHandle
                    )}
                  >
                    Create
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.buttons}
                    onClick={DetailResetClick}
                    >
                    Reset
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  buttons: {
    marginTop: '8px',
    marginRight: '8px',
    float: 'right'
  },
  header: {
    backgroundColor: '#F4F6F8',
    width: '8%'
  },
  value: {
    width: '14%'
  }
}));

export default CardOverseaFeeBaseDetails;

import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Moment from 'moment';

type Props = {
    csvData:  CommonCodeInfo[],      // excel로 출력하기 위한 codeList.tsx의 데이터 props
    clicked: boolean,       // codeList.tsx의 excel 버튼이 클릭되었는지 확인을 위한 props
    setClicked: React.Dispatch<React.SetStateAction<boolean>>,      // excel 출력 이후 clicked를 초기화 하기 위한 props
}

export const ExportIntoExcel: FC<Props> = ({ 
    csvData, 
    clicked, 
    setClicked 
}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';     // 출력될 excel 파일의 타입 지정
    const fileExtension = '.xlsx';      // 출력될 excel 파일의 확장자 지정

    const excelData = () => {
        let rowData = []
        for (let i = 0; i < csvData.length; i++) {
            rowData.push({
                'No': `${i + 1}`, 
                '유형코드': `${csvData[i].typeCode}`,
                '코드유형명': `${csvData[i].typeCodeName}`,
                '그룹코드': `${csvData[i].groupCode}`,
                '코드그룹명': `${csvData[i].groupCodeName}`,
                '코드키': `${csvData[i].codeId}`,
                '코드명': `${csvData[i].codeName}`,
                '코드값': `${csvData[i].codeValue}`,
                '사용여부': `${csvData[i].useYn}` 
            });
        }
        return rowData;
      }


    // excel export 라이브러리를 통한 excel 출력 함수
    const exportToCSV = (csvData: ExcelData[]) => {        // codeList.tsx의 데이터를 파라미터로 받음
        const fileName = 'CommonCodeList_' + Moment().format('YYYYMMDDHHmmss');     // 생성될 excel 파일의 파일명 지정
        const ws = XLSX.utils.json_to_sheet(csvData);       // codeList.tsx의 데이터를 excel xlsx 데이터로 변경
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };        // excel 파일의 sheet 지정
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });        // array 형식으로 excel에 데이터 작성
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);       // 생성된 excel 파일을 파일로써 저장
    }

    return (
        <Button 
            variant="contained" 
            color="primary"
            style={{marginLeft: 'auto', marginBottom: 10}}
            onClick={
                (event) => {
                    setClicked(!clicked);       // clicked 초기화
                    exportToCSV(excelData());       // 파일 생성 함수 실행
                }}
            >
            Excel
        </Button>
    )
};

import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import apolloclient, { overseasApprListMutation } from '../../lib/apolloClient';
import { chaiPaymentId } from '../../lib/chaiAPI';

const useStyles = makeStyles({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px',
    minWidth: '300px',
  },
});

type Props = {
  refundCount: number;
  setRefundCount: React.Dispatch<React.SetStateAction<number>>;
  isOverseasApprPaymentIdPopOpen: boolean;
  setOverseasApprPaymentIdPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tradeDate: String;
  tradeApprovalSeq: number;
};

const OverseasApprPaymentIdPop: FC<Props> = ({
  refundCount,
  setRefundCount,
  isOverseasApprPaymentIdPopOpen,
  setOverseasApprPaymentIdPopOpen,
  tradeDate,
  tradeApprovalSeq,
}) => {
  const classes = useStyles();

  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();
  const [paymentId, setPaymentId] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const refundReq = {} as ChaiMoneyRefundReq;
      refundReq.idempotencyKey = 'OA' + tradeDate + tradeApprovalSeq.toString().padStart(16, '0');
      try {
        // 차이머니 결제ID조회
        if (tradeDate) {
          const chaiRes = await chaiPaymentId(refundReq);
          setPaymentId(chaiRes.paymentId);
        }
      } catch (e) {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeDate, tradeApprovalSeq]);

  const handleConfirm = async () => {
    if (!paymentId) {
      hideDialog();
      showToast('paymentId 값을 입력하세요. ', 'error');
      return;
    }
    await apolloclient
      .mutate({
        variables: {
          tradeDate: tradeDate,
          tradeApprovalSeq: tradeApprovalSeq,
          paymentId: paymentId,
        },
        mutation: overseasApprListMutation.updateOverseasApprPaymentId,
      })
      .then(res => {
        console.log('*** 머니번호 업데이트 시작 ***');
        console.log(res.data);
        setRefundCount(refundCount + 1);
        hideDialog();
        showDialog('paymentId 값 세팅', '처리되었습니다.\n\n', () => {
          hideDialog();
        });
      })
      .catch(e => {
        console.log('*** 머니번호 업데이트 에러 ***');
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    setOverseasApprPaymentIdPopOpen(false);
  };

  const handleChage = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentId(e.target.value);
  };

  const handlerPopClose = () => {
    setOverseasApprPaymentIdPopOpen(false);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'enter') {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={isOverseasApprPaymentIdPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title">
        <MenuTitleToolbar title="PaymentId 값 세팅" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <form autoComplete="off" noValidate>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>
                    머니승인번호
                  </TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    <TextField
                      fullWidth
                      onChange={handleChage}
                      onKeyPress={onKeyEnter}
                      margin="dense"
                      name="paymentId"
                      variant="outlined"
                      value={paymentId}
                    ></TextField>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </form>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={() => showDialog('PaymentId 값 세팅', '처리하시겠습니까?', handleConfirm)}>
          처리
        </Button>
        <Button color="primary" onClick={handlerPopClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverseasApprPaymentIdPop;

import {
  Card,
  CardActions,
  CardContent,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { convertPurchaseType } from './helper';
import PurchasedHistoryDetail from './PurchasedHistoryDetail';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  table: {
    //minWidth: 2000
  },
}));

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  dataList: HanaPurchasedHistoryList;
  page: number;
  rowsPerPage: number;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

const PurchasedHistoryTable: FC<Props> = ({ dataList, page, rowsPerPage, setPaging }) => {
  const classes = useStyles();

  let list = dataList === undefined ? [] : dataList['list'];
  let totalCount = dataList === undefined ? 0 : dataList['totalCount'];
  const [detailInfo, setDetailInfo] = useState<HanaPurchasedHistoryInfo>();
  const [isDetailPop, setDetailPop] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    const paging: Paging = {} as any;
    paging.page = inPage;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paging: Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = Number(event.target.value);
    setPaging(paging);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">매입일자</TableCell>
                <TableCell align="center">승인일자</TableCell>
                <TableCell align="center">거래형태</TableCell>
                <TableCell align="center">승인번호</TableCell>
                <TableCell align="center">가맹점명</TableCell>
                <TableCell align="center">매출합계액 (원)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.slice(0, list.length).map((data, index) => (
                <TableRow hover key={index}>
                  <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{data.isOverseasPurchase ? '해외' : '국내'}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.purchaseDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.approvalDate)}</TableCell>
                  <TableCell align="center">{convertPurchaseType(data.type)}</TableCell>
                  <TableCell align="center">
                    <Link
                      component="button"
                      variant="body1"
                      onClick={() => {
                        setDetailInfo(data);
                        setDetailPop(true);
                      }}
                    >
                      {data.approvalNumber ?? '-'}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{data._merchant?.name ?? '-'}</TableCell>
                  <TableCell align="right">{data.amount?.toLocaleString() ?? '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <PurchasedHistoryDetail isDetailPop={isDetailPop} setDetailPop={setDetailPop} detailInfo={detailInfo} />
    </Card>
  );
};

export default PurchasedHistoryTable;

import React, { useState, useEffect, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Checkbox
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { overseasCsListMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  table: {
    minWidth: 3000
  }  
}));

interface OverseasCsCheckInfo  {
  seq : number;
  date : string;
};

type Props = { 
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  statusCount: number,
  setStatusCount: React.Dispatch<React.SetStateAction<number>>,
  overseasCsList: OverseasCsInfo[]
};

const OverseasCsTable: FC<Props> = ({ paging, setPaging, statusCount, setStatusCount, overseasCsList}) => {
  const classes = useStyles();
  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  let totalCount = 0;
  const [allCheckYn, setAllCheckYn] = useState(false);
  const [checkValues, setCheckValues] = useState<string[]>([]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
  };

  const setTotalCount = (value: number) => {
    totalCount = value;
  }

  const checkFormValues = () => {

    if (checkValues.length === 0)
      setAllCheckYn(false);
  }

  useEffect(() => {
    checkFormValues();
  // eslint-disable-next-line
  }, [checkValues]);

  const isDisabled  = (item:OverseasCsInfo) => {

    console.log('csRewardTargetYn', item.csRewardTargetYn);
    console.log('rewardProgressStatusCode', item.rewardProgressStatusCode);
    if (item.csRewardTargetYn === 'Y' && item.rewardProgressStatusCode === '0') {
      return false;
    }
    else {
      return true;
    }
  };

  const onChangeAllCheck = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => { 
    console.log('allCheckYn=', allCheckYn);
    if (!allCheckYn) {
      // 초기화
      setCheckValues([]);
      const initialValues:string[] = [];
      const arrValues = overseasCsList.reduce((pre, item) => {
        if (item.csRewardTargetYn === 'Y' && item.rewardProgressStatusCode === '0') {
          pre.push(item.overseasCsSeq+';'+item.overseasCsReceveDate);
        }
        return pre;
      }, initialValues);
      setCheckValues(arrValues);
    }
    else {
      setCheckValues([]);
    }
    setAllCheckYn(!allCheckYn);
  };

  const onChangeCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const value = event.target.value;
    setCheckValues(checkValues.includes(value) ? checkValues.filter(c => c !== value) : [...checkValues, value]);
  };

  const handleInsertCsReword = async () => {
    console.log('checkValues=', checkValues);

    if (checkValues.length === 0) {
      showToast('보상대상이 선택되지 않았습니다.', 'error');
      return;
    }

    try {
      await apolloClient.mutate({
        variables: {
          checkValuesList: checkValues},
        mutation: overseasCsListMutation.insertOverseasCsReword
      }).then(res => {     
      console.log(res.data);
      setStatusCount(statusCount+1);
      hideDialog();
      showDialog(
        '보상대상',
        '선정 처리되었습니다.\n\n',
        () => {
          hideDialog();
          }
      )         
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setCheckValues([]);
    setAllCheckYn(false);
  };

  const handleCsHandEnd = async () => {
    console.log('checkValues=', checkValues);

    if (checkValues.length === 0) {
      showToast('수기종결처리대상이 선택되지 않았습니다.', 'error');
      return;
    }

    try {
      await apolloClient.mutate({
        variables: {
          checkValuesList: checkValues},
        mutation: overseasCsListMutation.updateOverseasCsHandEnd
      }).then(res => {     
      console.log(res.data);
      setStatusCount(statusCount+1);
      hideDialog();
      showDialog(
        '수기종결',
        '처리되었습니다.\n\n',
        () => {
          hideDialog();
          }
      )         
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setCheckValues([]);
    setAllCheckYn(false);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell align="center">No.</TableCell>
                  <TableCell align="center">
                    <Checkbox onChange={event => {onChangeAllCheck(event);}}checked={allCheckYn}/>
                  </TableCell>
                  <TableCell align="center">접수일자</TableCell>
                  <TableCell align="center">종결일자</TableCell>
                  <TableCell align="center">해외민원접수구분</TableCell>
                  <TableCell align="center">카드일련번호</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">매출일자</TableCell>
                  <TableCell align="center">매출종류구분</TableCell>
                  <TableCell align="center">매입접수번호</TableCell>
                  <TableCell align="center">회원한글명</TableCell>
                  <TableCell align="center">해외민원사유구분</TableCell>  
                  <TableCell align="center">영문가맹점명</TableCell>     
                  <TableCell align="center">MCC코드</TableCell>
                  <TableCell align="center">현지거래금액</TableCell>
                  <TableCell align="center">해외결제미화금액</TableCell>
                  <TableCell align="center">청구금액</TableCell>
                  <TableCell align="center">원화원금금액</TableCell>
                  <TableCell align="center">전금미화금액</TableCell>
                  <TableCell align="center">전금원화금액</TableCell>
                  <TableCell align="center">브랜드구분</TableCell>
                  <TableCell align="center">승인번호</TableCell>
                  <TableCell align="center">승인일자</TableCell>
                  <TableCell align="center">해외민원종료구분</TableCell>
                  <TableCell align="center">민원보상대상여부</TableCell>
                  <TableCell align="center">보상진행상태</TableCell>             
                </TableRow>
              </TableHead>
              <TableBody>
                {overseasCsList.slice(0, overseasCsList.length).map((item, index) => (
                    <TableRow
                    hover
                    key={item.overseasCsSeq+''+item.overseasCsReceveDate}
                    >
                    <TableCell align="center">{setTotalCount(item.totalCount)}{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align="center">
                      <Checkbox disabled={isDisabled(item)} onChange={event => {onChangeCheck(event);}} value={item.overseasCsSeq+';'+item.overseasCsReceveDate} checked={checkValues.includes(item.overseasCsSeq+';'+item.overseasCsReceveDate)}/>
                    </TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.overseasCsReceveDate)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.overseasCsEndDate)}</TableCell>
                    <TableCell align="center">{item.overseasCsReceiptCode}</TableCell>
                    <TableCell align="center">{item.cardSeq}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.salesDate)}</TableCell>
                    <TableCell align="center">{item.salesKindDivisionCode}</TableCell>
                    <TableCell align="center">{item.purchasedProcessNo}</TableCell>
                    <TableCell align="center">{item.userKorName}</TableCell>
                    <TableCell align="center">{item.overseasCsReasonDivisionCode}</TableCell>
                    <TableCell align="center">{item.merchantEngName}</TableCell>
                    <TableCell align="center">{item.mccMerchantCode}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.localTradeAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.overseasPaymentUsdAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.billAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.wonOriginAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.endUsdAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.endKrwAmount)}</TableCell>
                    <TableCell align="center">{item.brandDivisionCode}</TableCell>
                    <TableCell align="center">{item.overseasCardApprovalNo}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate)}</TableCell>
                    <TableCell align="center">{item.overseasCsEndDivisionCode}</TableCell>
                    <TableCell align="center">{item.csRewardTargetYn}</TableCell>
                    <TableCell align="center">{item.rewardProgressStatusCodeName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <Button className={classes.registerButton}
        color="primary"
        variant="contained"
        onClick={() => {showDialog(
          '수기종결',
          '처리 하시겠습니까?',
          () => handleCsHandEnd()
        )}}
      >            
        수기종결처리
      </Button>         
      <Button className={classes.registerButton}
        color="primary"
        variant="contained"
        onClick={() => {showDialog(
          '보상대상',
          '선정처리 하시겠습니까?',
          () => handleInsertCsReword()
        )}}
      >            
        보상대상지정
      </Button>      
    </Card>
  );
};

export default OverseasCsTable;

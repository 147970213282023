import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee'
  }
}));

type Props = { 
  isDetailPop: boolean,
  setDetailPop: React.Dispatch<React.SetStateAction<boolean>>,
  detailInfo: any
}

const PurchasedHistoryDetail: FC<Props> = ({ isDetailPop, setDetailPop, detailInfo }) => {
  const classes = useStyles();

  const handleClose = () => {
    setDetailPop(false);
  };

  return (
    <Dialog
      open={isDetailPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle><MenuTitleToolbar title="온라인사업자재정산내역 상세조회" /></DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableBody>
            <TableRow >
              <TableCell className={classes.thStyle}>Chai정산일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.receveBaseDate)}</TableCell>
              <TableCell className={classes.thStyle}>수신일련번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.receveSeq}</TableCell>
              <TableCell className={classes.thStyle}>BC재정산매입일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.reSettlementReceveDate)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>BC재정산일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.reSettlementDate)}</TableCell>
              <TableCell className={classes.thStyle}>원정산일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.originSettlementReceveDate)}</TableCell>
              <TableCell className={classes.thStyle}>원매출접수일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.originSalesReceveDate)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>매입접수번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.purchasedProcessNo}</TableCell>
              <TableCell className={classes.thStyle}>카드번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.cardNoMasked}</TableCell>
              <TableCell className={classes.thStyle}>카드순번</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.cardSeq}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>영업점번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.branchNo}</TableCell>
              <TableCell className={classes.thStyle}>매출종류</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('salesKindDivisionCode', detailInfo.salesKindDivisionCode)}({detailInfo.salesKindDivisionCode})</TableCell>
              <TableCell className={classes.thStyle}>카드승인번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.cardApprovalNo}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>거래일자</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.salesDate)}</TableCell>
              <TableCell className={classes.thStyle}>접수가맹점번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.receveMerchantNo}</TableCell>
              <TableCell className={classes.thStyle}>가맹점명</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.merchantName}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>계정구분</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('accountDivisionCode', detailInfo.accountDivisionCode)}({detailInfo.accountDivisionCode})</TableCell>
              <TableCell className={classes.thStyle}>계좌부서식별자</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.accountDepartmentIdentifier}</TableCell>
              <TableCell className={classes.thStyle}>가맹점업종코드</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.merchantBusinessCode}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>체크매출구분코드</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.checkSalesDivisionCode}</TableCell>
              <TableCell className={classes.thStyle}>중간하위사업자등록번호</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.bizNoFormat(detailInfo.midLowerBizNo)}</TableCell>
              <TableCell className={classes.thStyle}>하위사업자일련번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.lowerBuismSeq}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>하위사업자번호</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.bizNoFormat(detailInfo.lowerBizNo)}</TableCell>
              <TableCell className={classes.thStyle}>사업자영세중소구분코드</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.buismSmMidDivisionCode}</TableCell>
              <TableCell className={classes.thStyle}>원매출매출금액</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.originSalesSalesAmount)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>하위사업자매출금액</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.lowerBuismSalesAmount)}</TableCell>
              <TableCell className={classes.thStyle}>원매출가맹점수수료율</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.originSalesMerchantFeeRate}</TableCell>
              <TableCell className={classes.thStyle}>원매출가맹점수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.originSalesMerchantFee)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>원매출발행회사보전수수료율</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.originSalesIssuerFeeRate}</TableCell>
              <TableCell className={classes.thStyle}>원매출발행회사보전수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.originSalesIssuerFee)}</TableCell>
              <TableCell className={classes.thStyle}>개별가맹점수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.individualMerchantFee)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>개별가맹점발행회사수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.individualMerchantIssuerFee)}</TableCell>
              <TableCell className={classes.thStyle}>재계산가맹점수수료율</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.reCalcuMerchantFeeRate}</TableCell>
              <TableCell className={classes.thStyle}>재계산가맹점수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.reCalcuMerchantFee)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>재계산발행회사보전수수료율</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.reCalcuIssuerFeeRate}</TableCell>
              <TableCell className={classes.thStyle}>재계산발행회사보전수수료</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.reCalcuIssuerFee)}</TableCell>
              <TableCell className={classes.thStyle}>발행회사보전수수료차이금액</TableCell>
              <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.issuerFeeDiffAmount)}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell className={classes.thStyle}>자료처리구분</TableCell>
              <TableCell className={classes.tdStyle} colSpan={5}>{chaiStringUtils.codeName('dataProcessDivisionCode', detailInfo.dataProcessDivisionCode)}({detailInfo.dataProcessDivisionCode})</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">닫기</Button>
      </DialogActions>
    </Dialog>  
  );
};

export default PurchasedHistoryDetail;



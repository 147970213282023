import React, { FC, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import useStyles from './styles';

type Props = {
  className?: string,
  accidents: GetAccidentListReturn[],
  cardStatus: string,
  refreshAccidents: (cardNumber: string) => Promise<void>
}
const ApprovalList: FC<Props> = ({ className, accidents, cardStatus, refreshAccidents}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="사고 내역"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>사고명</TableCell>
                  <TableCell>등/해제 구분</TableCell>
                  <TableCell>등/해제 채널</TableCell>
                  <TableCell>등/해제 일자</TableCell>
                  <TableCell>등/해제 시간</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accidents.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((accident, index) => (
                  <TableRow
                    hover
                    key={accident.date + accident.time + index}
                  >
                    <TableCell>{accident.acdtName}</TableCell>
                    <TableCell>{accident.divison}</TableCell>
                    <TableCell>{accident.channel}</TableCell>
                    <TableCell>{accident.date}</TableCell>
                    <TableCell>{accident.time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={accidents.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default ApprovalList;

import gql from 'graphql-tag';



export const selectOverseasCsTrmList = gql`
  mutation selectOverseasCsTrmList($input : OverseasCsTrmSearch) {
    selectOverseasCsTrmList (input : $input) {
      totalCount
      overseasCsTrmSeq
      trmDate
      trmStatusCode
      trmUsdAmount
      trmKrwAmount
      trmApplyExchangeRate
      trmUsdProcessAmount
      exchangeRatePflsDivisionCode
      exchangeRateDiffAmount
      accountProcessSeq
      remarkDescription
    }
  }
`;


export const insertOverseasCsTrm = gql`
  mutation insertOverseasCsTrm($input : OverseasCsTrmInput) {
    insertOverseasCsTrm(input : $input) {
      result
    }
  }
`;


export const deleteOverseasCsTrm = gql`
  mutation deleteOverseasCsTrm($checkValuesList : [String]!) {
    deleteOverseasCsTrm(checkValuesList : $checkValuesList) {
      result
    }
  }
`;
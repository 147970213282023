import React, { useState, FC, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Theme, makeStyles, useTheme, useMediaQuery, Button, ButtonGroup } from '@material-ui/core';

import { Sidebar, Topbar } from './components';
import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';

import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}));

const CustomRouterLink = forwardRef((props: NavLinkProps, ref: any) => <RouterLink {...props} />);

type Props = { children: React.ReactNode };

function deleteTab(index: number, selectIndex: number, tabContent: any[]) {
  var url = '';
  if (selectIndex === index) {
    if (index === 0) {
      if (tabContent.length !== undefined && tabContent.length > 1) {
        url = tabContent[index + 1].key;
      }
    } else {
      url = tabContent[index - 1].key;
    }
  } else {
    url = tabContent[selectIndex].key;
  }

  return url;
}

const Main: FC<Props> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const tabContent = chaiStringUtils.setTab(children);
  let selectIndex = chaiStringUtils.getTabIndex(children);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
      <div style={{ marginLeft: 12 }}>
        {tabContent.map((section: any, index: any) => (
          <ButtonGroup style={{ width: '14%' }} key={`tab-group-${index}`}>
            <Button
              component={CustomRouterLink}
              to={section.key}
              color="primary"
              variant="contained"
              style={{ marginLeft: 12, marginTop: 10, marginBottom: 0, width: '95%' }}
            >
              <p
                style={{
                  height: 25,
                  textAlign: 'center',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {chaiStringUtils.getTitle(section.key)}
              </p>
            </Button>
            <Button
              component={CustomRouterLink}
              onClick={() => chaiStringUtils.deleteTab(index)}
              to={deleteTab(index, selectIndex, tabContent)}
              color="primary"
              variant="contained"
              style={{ padding: 0, width: '5%', marginTop: 10, marginBottom: 0 }}
            >
              {'x'}
            </Button>
          </ButtonGroup>
        ))}
      </div>
      <main>{tabContent[selectIndex].item}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;

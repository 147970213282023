import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { purchasedHistoryMutation } from '../../lib/apolloClient/mutations';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import PurchasedHistoryDetail from './PurchasedHistoryDetail';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Link
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    //minWidth: 2000
  }
}));

function convertOnlineType(code: string) {
  switch(code) {
    case '01':
      return '오프라인';
    case '02':
      return '온라인PG';
    case '03':
      return '인터넷MALL';
    default:
      return '분류없음';
  }
}

type Paging = {
  page	:number;
  rowsPerPage	:number;
}

type Props = {
  dataList: PurchasedHistoryList
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
}

const PurchasedHistoryTable: FC<Props> = ({ dataList, page, rowsPerPage, setPaging }) => {
  const classes = useStyles();

  let list = (dataList === undefined)? []:dataList["list"];
  let totalCount = (dataList === undefined)? 0:dataList["totalCount"];
  const [detailInfo, setDetailInfo] = useState('');
  const [isDetailPop, setDetailPop] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    const paging:Paging = {} as any;
    paging.page = inPage;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paging:Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = Number(event.target.value);
    setPaging(paging);
  };

  const handleDetailClick = (receveBaseDate: String, receveSeq: number) => {
    fetch(receveBaseDate, receveSeq);
    setDetailPop(true);
  }

  const fetch = async (receveBaseDate: String, receveSeq: number) => {
    await apolloClient.mutate({
      variables: { receveSeq: receveSeq, receveBaseDate: receveBaseDate },
      mutation: purchasedHistoryMutation.selectPurchasedHistoryInfo
    }).then(res => {
      setDetailInfo(res.data.selectPurchasedHistoryInfo);
    }).catch(console.error);
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Chai정산일자</TableCell>
                <TableCell align="center">BC매입일자</TableCell>
                <TableCell align="center">BC정산일자</TableCell>
                <TableCell align="center">거래일자</TableCell>
                <TableCell align="center">매출종류</TableCell>
                <TableCell align="center">계정구분</TableCell>
                <TableCell align="center">카드번호</TableCell>
                <TableCell align="center">매입접수번호</TableCell>
                <TableCell align="center">가맹점명</TableCell>
                <TableCell align="center">가맹점번호</TableCell>
                <TableCell align="center">매출합계액</TableCell>
                <TableCell align="center">승인번호</TableCell>
                <TableCell align="center">계정처리순번</TableCell>
                <TableCell align="center">온라인 상세코드</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.slice(0, list.length).map((data, index) => (
                <TableRow hover key={index} >
                  <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.receveBaseDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.receveDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.settlementDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.salesDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.codeName('salesKindDivisionCode', data.salesKindDivisionCode)}({data.salesKindDivisionCode})</TableCell>
                  <TableCell align="center">{chaiStringUtils.codeName('accountDivisionCode', data.accountDivisionCode)}({data.accountDivisionCode})</TableCell>
                  <TableCell align="center">{data.cardNoMasked}</TableCell>
                  <TableCell align="center">
                    <Link component="button" variant="body1" onClick={() => { handleDetailClick(data.receveBaseDate, data.receveSeq); }}>
                    {data.purchasedProcessNo}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{data.merchantName}</TableCell>
                  <TableCell align="center">{data.merchantNo}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.salesTotalAmount)}</TableCell>
                  <TableCell align="center">{data.cardApprovalNo}</TableCell>
                  <TableCell align="center">{data.accountProcessSeq}</TableCell>
                  <TableCell align="center">{convertOnlineType(data.onlineMerchantType)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <PurchasedHistoryDetail isDetailPop={isDetailPop} setDetailPop={setDetailPop} detailInfo={detailInfo}/>
    </Card>
  );
};

export default PurchasedHistoryTable;

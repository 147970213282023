import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type Props = { 
  approvalCompareAggrInfo: ApprovalCompareAggrInfo
}

const ApprovalHistoryLogInfoTable: FC<Props> = ({ approvalCompareAggrInfo }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} colSpan={2}>구분</TableCell>
                  <TableCell align="center" colSpan={3}>승인</TableCell>
                  <TableCell align="center" colSpan={3}>승인취소</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell> 
                  <TableCell align="center">차이머니</TableCell>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>                               
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle} rowSpan={2}>정상</TableCell>
                  <TableCell align="center" className={classes.thStyle}>건수</TableCell>
                  <TableCell align="right" >{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.normalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyNormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.normalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcNormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyNormalApprovalCancelCount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>금액</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.normalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyNormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.normalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcNormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyNormalApprovalCancelAmountSum)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle} rowSpan={2}>비정상</TableCell>
                  <TableCell align="center" className={classes.thStyle}>건수</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.abnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyAbnormalApprovalCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.abnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcAbnormalApprovalCancelCount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyAbnormalApprovalCancelCount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>금액</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.abnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyAbnormalApprovalAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.abnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.bcAbnormalApprovalCancelAmountSum)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(approvalCompareAggrInfo.chaimoneyAbnormalApprovalCancelAmountSum)}</TableCell>
                </TableRow>                
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default ApprovalHistoryLogInfoTable;

import React, { FC, ChangeEvent, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Input
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { batchInfoMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import PrecedenceBatchInfo from './PrecedenceBatchInfo';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  }
}));

type Props = {
  className?: string,
  statusCount: number,
  setStatusCount: React.Dispatch<React.SetStateAction<number>>,
  updateClick: boolean,
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
  batchInfo: BatchInfo,
  setBatchInfo: React.Dispatch<React.SetStateAction<BatchInfo>>,
}
const BatchInfoDetails: FC<Props> = ({ className, statusCount, setStatusCount, updateClick, setUpdateClick, batchInfo, setBatchInfo }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBatchInfo({
      ...batchInfo,
      [e.target.name]: e.target.value
    });
  };

  const [isValidForm, setIsValidForm] = React.useState(false);
  const [precedenceBatchInfoOpen, setPrecedenceBatchInfoOpen] = React.useState(false);

  const checkFormValidation = () => {
    let isValid = true;
    if (isEmpty(batchInfo.batchId)) isValid = false;
    if (isEmpty(batchInfo.batchName)) isValid = false;
    if (isEmpty(batchInfo.remarkDescription)) isValid = false;
    if (isEmpty(batchInfo.executionCycle)) isValid = false;
    setIsValidForm(isValid);
  }

  useEffect(() => {
    checkFormValidation();
    // console.log('updateClick', updateClick);
    // console.log('isValidForm', isValidForm);
    // console.log('&&&&&', !updateClick || !isValidForm);
  // eslint-disable-next-line
  }, [batchInfo])

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
      await apolloClient.mutate({
        variables: {input : {
          batchId : batchInfo.batchId, 
          batchName : batchInfo.batchName,
          precedenceBatchId: batchInfo.precedenceBatchId,
          executionCycle : batchInfo.executionCycle,
          remarkDescription: batchInfo.remarkDescription, 
          fileName : batchInfo.fileName} },
        mutation: batchInfoMutation.insertBatchInfo
      }).then(res => {     
        // console.log(res.data);
        setBatchInfo({
          ...batchInfo,
          totalCount: 0,
          batchSeqNo: 0,
          batchId: '',
          batchName: '',
          precedenceBatchId: '',
          precedenceBatchName: '',
          executionCycle: '1',
          remarkDescription: '',
          fileName: '',
          processDate: '',
          processStatus: ''
        });
        setStatusCount(statusCount+1);
        hideDialog();
        showDialog(
          '배치정보',
          '저장되었습니다.\n\n',
          () => {
            // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
            hideDialog();
          }
        )   

      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
  }

  const handleConfirmUpdConfirm = async () => {
    await apolloClient.mutate({
      variables: {input : {
        batchSeqNo: batchInfo.batchSeqNo, 
        batchId: batchInfo.batchId, 
        batchName: batchInfo.batchName, 
        precedenceBatchId: batchInfo.precedenceBatchId,
        executionCycle: batchInfo.executionCycle, 
        remarkDescription: batchInfo.remarkDescription, 
        fileName: batchInfo.fileName} },
      mutation: batchInfoMutation.updateBatchInfo
    }).then(res => {     
      // console.log(res.data);
      setBatchInfo({
        ...batchInfo,
        totalCount: 0,
        batchSeqNo: 0,
        batchId: '',
        batchName: '',
        precedenceBatchId: '',
        precedenceBatchName: '',
        executionCycle: '1',
        remarkDescription: '',
        fileName: '',
        processDate: '',
        processStatus: ''
      });
      setUpdateClick(false);
      setStatusCount(statusCount+1);
      hideDialog();
      showDialog(
        '배치정보',
        '수정되었습니다.\n\n',
        () => {
          // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
        }
      )   

    }).catch(e => {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    });
  }

  const handleResetForm = () => {
    setBatchInfo({
      ...batchInfo,
      totalCount: 0,
      batchSeqNo: 0,
      batchId: '',
      batchName: '',
      precedenceBatchId: '',
      precedenceBatchName: '',
      executionCycle: '1',
      remarkDescription: '',
      fileName: '',
      processDate: '',
      processStatus: ''
    });
    setUpdateClick(false);
  }

  const executionCycle = [
    {
      value: '1',
      label: '일'
    },
    {
      value : '2',
      label : '주'
    },
    {
      value : '3',
      label : '월'
    }
  ]

  const isEmpty = (input: string) => {
    return input === '';
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="배치 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="배치ID"
                margin="dense"
                name="batchId"
                onChange={handleChange}
                required
                value={batchInfo.batchId}
                variant="outlined"
                error={isEmpty(batchInfo.batchId)}
                helperText={isEmpty(batchInfo.batchId) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="배치명"
                margin="dense"
                name="batchName"
                onChange={handleChange}
                required
                value={batchInfo.batchName}
                variant="outlined"
                error={isEmpty(batchInfo.batchName)}
                helperText={isEmpty(batchInfo.batchName) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="실행주기"
                margin="dense"
                name="executionCycle"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={batchInfo.executionCycle}
              >
                {executionCycle.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="선행배치"
                margin="dense"
                name="precedenceBatchName"
                onChange={handleChange}
                value={batchInfo.precedenceBatchName? batchInfo.precedenceBatchName : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setPrecedenceBatchInfoOpen(true)}
                style = {{
                  marginTop: '8px'
                }}
              >
              Select  
              </Button>  
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setBatchInfo({...batchInfo, precedenceBatchId:'', precedenceBatchName:''})}
                style = {{
                  marginTop: '8px'
                }}
              >
               Reset  
              </Button>                        
            </Grid>         
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="내용"
                margin="dense"
                name="remarkDescription"
                onChange={handleChange}
                required
                value={batchInfo.remarkDescription ? batchInfo.remarkDescription : ''}
                variant="outlined"
                error={isEmpty(batchInfo.remarkDescription)}
                helperText={isEmpty(batchInfo.remarkDescription) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="배치파일명"
                margin="dense"
                name="fileName"
                onChange={handleChange}
                value={batchInfo.fileName ? batchInfo.fileName : ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Input type="hidden" name="batchSeqNo" value={batchInfo.batchSeqNo}></Input>
          <Input type="hidden" name="precedenceBatchId" value={batchInfo.precedenceBatchId ? batchInfo.precedenceBatchId : ''}></Input>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={updateClick || !isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '배치정보',
              '해당 정보로 저장하시겠습니까?',
              handleConfirmAddConfirm
            )}
          >
            New 
          </Button>
          <Button
            disabled={!updateClick || !isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '배치정보',
              '해당 정보로 수정하시겠습니까?',
              handleConfirmUpdConfirm
            )}
          >
            Edit 
          </Button>    
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleResetForm()}
          >
            Reset 
          </Button>                  
        </CardActions>
      </form>
      <PrecedenceBatchInfo batchInfo={batchInfo} setPrecedenceInfo={setBatchInfo} precedenceBatchInfoOpen={precedenceBatchInfoOpen} setPrecedenceBatchInfoOpen={setPrecedenceBatchInfoOpen}/>
    </Card>
  );
};

export default BatchInfoDetails;
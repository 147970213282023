import React, { FC, useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Checkbox
} from '@material-ui/core';

import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { overseasCsTrmMgmtMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  button: {
    margin: theme.spacing(1),
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },  
  table: {
    minWidth: 2000
  }   
}));

type Props = { 
  statusCount: number,
  setStatusCount: React.Dispatch<React.SetStateAction<number>>,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  overseasCsTrmList: OverseasCsTrmInfo[]
}

const OverseasCsTrmMgmtTable: FC<Props> = ({ statusCount, setStatusCount, paging, setPaging, overseasCsTrmList}) => {
  const classes = useStyles();
  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  let totalCount = 0;
  const [allCheckYn, setAllCheckYn] = useState(false);
  const [checkValues, setCheckValues] = useState<string[]>([]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
  };

  const setTotalCount = (value: number) => {
    totalCount = value;
  }

  const checkFormValues = () => {

    if (checkValues.length === 0)
      setAllCheckYn(false);
  }

  useEffect(() => {
    checkFormValues();
  // eslint-disable-next-line
  }, [checkValues]);

  const onChangeAllCheck = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => { 
    console.log('allCheckYn=', allCheckYn);
    if (!allCheckYn) {
      // 초기화
      setCheckValues([]);
      const arrTmpList = overseasCsTrmList.filter(function(item) {
        return item.trmStatusCode === '0';
      });
      const arrValues = arrTmpList.map(function (item) {
        return item.overseasCsTrmSeq+';'+item.trmDate;
      });
      setCheckValues(arrValues);
    }
    else {
      setCheckValues([]);
    }
    setAllCheckYn(!allCheckYn);
  };

  const onChangeCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const value = event.target.value;
    setCheckValues(checkValues.includes(value) ? checkValues.filter(c => c !== value) : [...checkValues, value]);
  };

  const handleDeleteCsTrm = async () => {
    console.log('checkValues=', checkValues);

    if (checkValues.length === 0) {
      showToast('삭제대상이 선택되지 않았습니다.', 'error');
      return;
    }

    try {
      await apolloClient.mutate({
        variables: {
          checkValuesList: checkValues},
        mutation: overseasCsTrmMgmtMutation.deleteOverseasCsTrm
      }).then(res => {     
      console.log(res.data);
      setStatusCount(statusCount+1);
      hideDialog();
      showDialog(
        '해외민원전금',
        '삭제 처리되었습니다.\n\n',
        () => {
          hideDialog();
          }
      )         
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setCheckValues([]);
    setAllCheckYn(false);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align='center'>NO.</TableCell>
                  <TableCell align="center">
                    <Checkbox onChange={event => {onChangeAllCheck(event);}}checked={allCheckYn}/>
                  </TableCell>
                  <TableCell align='center'>해외민원전금일련번호</TableCell>
                  <TableCell align='center'>전금일자</TableCell>
                  <TableCell align='center'>전금상태코드</TableCell>
                  <TableCell align='center'>전금미화금액</TableCell>
                  <TableCell align='center'>전금원화금액</TableCell>
                  <TableCell align='center'>전금적용환율</TableCell>
                  <TableCell align='center'>전금미화처리금액</TableCell>
                  <TableCell align='center'>환율차이손익구분</TableCell>
                  <TableCell align='center'>환율차이금액</TableCell>
                  <TableCell align='center'>계정처리일련번호</TableCell>
                  <TableCell align='center'>비고내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overseasCsTrmList.slice(0, overseasCsTrmList.length).map((item, index) => (
                  <TableRow
                    hover
                    key={item.overseasCsTrmSeq+''+item.trmDate}
                  >
                    <TableCell align='center'>{setTotalCount(item.totalCount)}{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align="center">
                      <Checkbox disabled={item.trmStatusCode !== '0'} onChange={event => {onChangeCheck(event);}} value={item.overseasCsTrmSeq+';'+item.trmDate} checked={checkValues.includes(item.overseasCsTrmSeq+';'+item.trmDate)}/>
                    </TableCell>
                    <TableCell align='center'>{item.overseasCsTrmSeq}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.dateFormatDefault(item.trmDate)}</TableCell>
                    <TableCell align='center'>{item.trmStatusCode}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.trmUsdAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.trmKrwAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.trmApplyExchangeRate)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.trmUsdProcessAmount)}</TableCell>
                    <TableCell align='center'>{item.exchangeRatePflsDivisionCode}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.exchangeRateDiffAmount)}</TableCell>
                    <TableCell align='center'>{item.accountProcessSeq}</TableCell>
                    <TableCell align='center'>{item.remarkDescription}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <Button className={classes.registerButton}
        color="primary"
        variant="contained"
        onClick={() => {showDialog(
          '해외민원전금',
          '삭제처리 하시겠습니까?',
          () => handleDeleteCsTrm()
        )}}
      >            
        삭제
      </Button>         
    </Card>
  );
};

export default OverseasCsTrmMgmtTable;

import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',                                                                     
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
}));

type Paging = {
  page: number;
  rowsPerPage : number;
}

type Props = {
  useFeeHistoryList: overseasUseFeeHistoryList
  paging: Paging
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
}

const OvrsUseFeeHistoryTable : FC<Props> = ({
  useFeeHistoryList,
  paging,
  setPaging
}) => {
    const classes = useStyles();

    // 페이징
    let list = (useFeeHistoryList === undefined)? []:useFeeHistoryList["list"];
    let totalCount = (useFeeHistoryList === undefined)? 0:useFeeHistoryList["totalCount"];

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
      setPaging({
        ...paging,
        page: inPage
      });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaging({
        ...paging,
        rowsPerPage: Number(event.target.value)
      });
    };
  

    return (
        <Card>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">NO.</TableCell>
                      <TableCell align="center">수신일자</TableCell>
                      <TableCell align="center">매출구분</TableCell> 
                      <TableCell align="center">매출일자</TableCell>
                      <TableCell align="center">청구일자</TableCell>  
                      <TableCell align="center">승인일자</TableCell> 
                      <TableCell align="center">카드번호</TableCell>                           
                      <TableCell align="center">가맹점</TableCell> 
                      <TableCell align="center">해외이용원화수수료</TableCell> 
                      <TableCell align="center">ATM출금원화수수료</TableCell> 
                      <TableCell align="center">최종인출원화금액</TableCell> 
                      <TableCell align="center">매입처리구분</TableCell> 
                      <TableCell align="center">거래원화금액</TableCell> 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {list.map((item, index)=>(
                        <TableRow 
                          hover 
                          key={index}
                        >
                          <TableCell align="center">{(paging.rowsPerPage * (paging.page + 1)) - (paging.rowsPerPage - (index + 1))}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.overseasPurchasedReceiveBaseDate)}</TableCell>
                          <TableCell align="center">{item.salesKindDivisionCode}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.salesDate)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.billDate)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate)}</TableCell>
                          <TableCell align="center">{item.cardNo}</TableCell>
                          <TableCell align="center">{item.merchantEngName}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(item.overseasUseKrwFee)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(item.overseasAtmTradeKrwFee)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(item.lastWithdrawalKrwAmount)}</TableCell>
                          <TableCell align="center">{item.purchasedProcessDivisionCode}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(item.tradeKrwAmount)}</TableCell>
                          
                       </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={totalCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={paging.page}
              rowsPerPage={paging.rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card>
      );

};

export default OvrsUseFeeHistoryTable;
import gql from 'graphql-tag';

//해외매입내역조회 sumInfo
export const selectOvrsPurchasedHistorySumInfo = gql`
  mutation selectOvrsPurchasedHistorySumInfo($input: ovrsPurchasedHistorySearch) {
    selectOvrsPurchasedHistorySumInfo(input: $input) {
      totalCount
      totalAmount
      cdslUsdTotalAmount
      cdslFeeUsdTotalAmount
      casUsdTotalAmount
      cashFeeUsdTotalAmount
      brandFeeCorrectUsdAmount
    }
  }
`;

//해외매입내역조회
export const selectOvrsPurchasedHistoryList = gql`
  mutation selectOvrsPurchasedHistoryList($input: ovrsPurchasedHistorySearch) {
    selectOvrsPurchasedHistoryList(input: $input) {
      receveBaseDate
      receveSeq
      overseasProcessDate
      accountDivisionCode
      salesKindDivisionCode
      purchasedProcessNo
      cardNo
      cardNoMasked
      userId
      cardMemberNo
      merchantEngName
      overseasCdslUsdAmount
      overseasCdslFeeUsdAmount
      overseasCdslFeeKrwAmount
      overseasCashUsdAmount
      overseasCashFeeUsdAmount
      overseasCashFeeKrwAmount
      overseasSettlementUsdAmount
      tradeProcessCode
    }
  }
`;

//해외매입내역 상세POP
export const selectOvrsPurchasedHistoryPop = gql`
  mutation selectOvrsPurchasedHistoryPop($input: selectOvrsPurchasedHistoryPopSearch) {
    selectOvrsPurchasedHistoryPop(input: $input) {
      receveBaseDate
      receveSeq
      overseasProcessDate
      sendDate
      accountDivisionCode
      salesKindDivisionCode
      bcSalesDivisionCode
      cardSeq
      purchasedProcessNo
      cardNo
      cardNoMasked
      firstCardSeq
      firstCardNo
      firstCardNoMasked
      userId
      cardMemberNo
      personalCorporationDivisionCode
      cardTypeCode
      cardProductCode
      brandDivisionCode
      salesDate
      merchantNationCode
      branchNo
      merchantEngName
      overseasCdslUsdAmount
      overseasCdslFeeUsdAmount
      overseasCdslFeeKrwAmount
      overseasCashUsdAmount
      overseasCashFeeUsdAmount
      overseasCashFeeKrwAmount
      overseasSettlementUsdAmount
      overseasSettlementKrwAmount
      approvalApplyExchangeRate
      purchasedApplyExchangeRate
      dealBaseExchangeRate
      billDate
      reversalYn
      overseasTradeKrwAmount
      localTradeCurrencyCode
      overseasBcPurchasedSeq
      onusDivisionCode
      revolvingDivisionCode
      revolvingNo
      localTradeAmount
      merchantCityName
      mccMerchantCode
      approvalDate
      overseasCardApprovalNo
      tradeApprovalSeq
      tradeDate
      overseasUseKrwFee
      overseasAtmTradeKrwFee
      checkSalesDivisionCode
      governmentPurchaseCardDivisionCode
      textBrandUsdFee
      purchasedBrandUsdFee
      purchasedBrandKrwFee
      overseasUseFeeImposeYn
      purchasedOverseasUseUsdFee
      purchasedOverseasUseKrwFee
      purchasedOverseasWithdrawalUsdFee
      purchasedOverseasWithdrawalKrwFee
      approvalBrandUsdFee
      approvalBrandKrwFee
      approvalOverseasUseUsdFee
      approvalOverseasUseKrwFee
      approvalOverseasWithdrawalUsdFee
      approvalOverseasWithdrawalKrwFee
      approvalRequestWithdrawalUsdAmount
      approvalRealWithdrawalKrwAmount
      originReceveNo
      noApprovalPurchasedDivisionCode
      purchasedProcessDivisionCode
      forcedPurchasedYn
      forcedPurchasedAdminId
      forcedPurchasedTradeCode
      originApprovalLedgerExistYn
      localDiffAmount
      purchasedUsdAmount
      purchasedKrwAmount
      withdrawalUsdDiffAmount
      withdrawalKrwDiffAmount
      accountDepartmentIdentifier
      inoutProcessCode
      paymentId
      inoutProcessSeq
      inoutUsdAmount
      inoutKrwAmount
      accountProcessSeq
      dataProcessDivisionCode
      overseasPurchasedErrorReason
      exchangeRatePflsDivisionCode
      purchasedExchangeRateDiffAmount
      brandFeeCorrectUsdAmount
      brandFeeCorrectKrwAmount
      tradeProcessCode
      adjustProcessStatusDivisionCode
      adjustProcessReason
      adjustProcessTradeCode
      purchasedUsdOriginAmount
      approvalRequestUsdOriginAmount
      overseasUseFeeBaseUsdAmount
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      lastOperateAdminId
      lastOperateDetailDate
      lastOperateTradeCode
    }
  }
`;

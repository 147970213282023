import client from './client';

export default async (input: generateVipCardInfo) => {  
  return client.post(`/root/card/vipCardNo`, getBody(input)).then(res => {
    console.log('chk generate VIP 카드번호 채번 정보');
    console.log(input);
    console.log('===============');
    console.log(res.data);
    
    const statusCode = res.status;
    if (!statusCode || statusCode !== 200) {
      throw new Error(`chk generate VIP 카드번호 채번 실패-!`)
    }    
    return res.data;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: generateVipCardInfo) => {
  return {
    binNo: input.binNo,
    selectNo: input.vipCardNo
  }
}
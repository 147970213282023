import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import PurchasedAggrDetailListPop from './PlccAggrDetailListPop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  table: {
    //minWidth: 2000
  },
}));

type Props = {
  dataList: PlccAggrHistoryList[];
};

const PurchasedAggrHistoryTable: FC<Props> = ({ dataList }) => {
  const classes = useStyles();

  const default_page = 0;
  const default_rowsPerPage = 100;
  const [rowsPerPage, setRowsPerPage] = useState(default_rowsPerPage);
  const [page, setPage] = useState(default_page);
  const [detailListPop, setDetailListPopOpen] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  useEffect(() => {
    setPage(default_page);
    setRowsPerPage(default_rowsPerPage);
  }, [dataList]);

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">구분</TableCell>
                <TableCell align="center">집계일</TableCell>
                <TableCell align="center">출금건수</TableCell>
                <TableCell align="center">출금금액</TableCell>
                <TableCell align="center">출금금액(달러)</TableCell>
                <TableCell align="center">입금금액</TableCell>
                <TableCell align="center">입금금액(달러)</TableCell>
                <TableCell align="center">총건수</TableCell>
                <TableCell align="center">총금액</TableCell>
                <TableCell align="center">수수료</TableCell>
                <TableCell align="center">수수료 VAT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((data, index) => (
                <TableRow hover key={index}>
                  <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{data.isOverseas ? '해외' : '국내'}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.purchaseDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.moneyFormat(data.paymentCount)} 건</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.plusAmount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.plusAmountUSD)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.minusAmount)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.minusAmountUSD)}</TableCell>
                  <TableCell align="center">
                    {chaiStringUtils.moneyFormat((data.paymentCount || 0) - (data.cancelPaymentCount || 0))}
                  </TableCell>
                  <TableCell align="right">
                    {chaiStringUtils.moneyFormat((data.plusAmount || 0) - (data.minusAmount || 0))}
                  </TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.fee)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.feeTax)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <PurchasedAggrDetailListPop
        detailListPop={detailListPop}
        setDetailListPopOpen={setDetailListPopOpen}
        loading={false}
        list={[]}
      />
    </Card>
  );
};

export default PurchasedAggrHistoryTable;

import gql from 'graphql-tag';

// 대행수수료 정산내역 목록 조회
export const selectSettlementProxyFeeList = gql`
    mutation selectSettlementProxyFeeList(
        $feeSettlementYm: String,
        $proxyFeeCodeNo: String,
        $domesticCode: String,
        $feeAutoYn: String
    ) {
       selectSettlementProxyFeeList(
            feeSettlementYm: $feeSettlementYm            
            proxyFeeCodeNo: $proxyFeeCodeNo          
            domesticCode: $domesticCode                
            feeAutoYn: $feeAutoYn  
       ){
        feeSettlementYm
        feeClaimYm
        domesticCode
        proxyFeeCodeNo
        proxyFeeNm
        unitFeeCodeNo
        unitFeeName
        applyUnitprc
        feeBaseRate
        feeAutoYn
        bcResultCount
        bcResultAmount
        chaiResultCount
        chaiResultAmount
       }  
    }
`;

// 위임수수료(조회조건) 목록 조회 
export const selectProxyFeeCodeNoNm = gql`
    mutation selectProxyFeeCodeNoNm{
        selectProxyFeeCodeNoNm{
            proxyFeeCodeNo
            proxyFeeNm
        }
    }
`;

// 대행수수료 정산내역 합계 조회
export const selectSettlementProxyFeeAggr = gql`
    mutation selectSettlementProxyFeeAggr(
        $feeSettlementYm: String
    ) {
        selectSettlementProxyFeeAggr(
            feeSettlementYm: $feeSettlementYm  
        ){
            totalAmountBC      
            totalAmountCHAI 
            totalCountBC 
            totalCountCHAI 
        }
    }
`;


import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button
}
from '@material-ui/core';
import { format, subDays } from 'date-fns'

type Paging = {
  page: number,
  rowsPerPage: number
}

type overseasApprHistoyLogSearchInfo = {
  salesKindDivisionCode: string,
  approvalDate: string,
  dataDivisionCode: string
}

type Props = {
  page: number,
  rowsPerPage: number,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<overseasApprHistoyLogSearchInfo>>
}

const OverseasApprHistoryLogListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {

  const [overseasApprHistoyLogSearch, setOverseasApprHistoyLogSearch] = useState({
    salesKindDivisionCode: '',
    approvalDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    dataDivisionCode: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverseasApprHistoyLogSearch({
      ...overseasApprHistoyLogSearch,
      [e.target.name]: e.target.value
    });
  }

  const onSearchClick = async () => {
    const paging:Paging = {} as any;
    paging.page =  page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onSearch(overseasApprHistoyLogSearch);
  }
  
  const onResetClick = async () => {
    setOverseasApprHistoyLogSearch({
      ...overseasApprHistoyLogSearch,
      salesKindDivisionCode: '',
      approvalDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      dataDivisionCode: ''
    });
    onSearchClick();
  }

  return (
    <Card>
      <form>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={2} xs={6}>
              <TextField
                select
                fullWidth
                label="승인구분"
                margin="dense"
                variant="outlined"
                name="salesKindDivisionCode"
                value={overseasApprHistoyLogSearch.salesKindDivisionCode}
                SelectProps={{
                  native: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="05">신판</option>
                <option value="15">신판취소</option>
                <option value="06">현금</option>
                <option value="16">현금취소</option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={6}>
              <TextField
                select
                fullWidth
                label="자료구분"
                margin="dense"
                variant="outlined"
                name="dataDivisionCode"
                value={overseasApprHistoyLogSearch.dataDivisionCode}
                SelectProps={{
                  native: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
              >
                  <option value="">전체</option>
                  <option value="1">BC 일치</option>
                  <option value="2">BC 불일치</option>
                  <option value="3">Money 불일치</option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={6}>
              <TextField
                required
                type="date"
                fullWidth
                label="승인일자"
                margin="dense"
                variant="outlined"
                name="approvalDate"
                value={overseasApprHistoyLogSearch.approvalDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style={{
                  marginTop: '8px'
                }}
              >
                Search
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={onResetClick}
                style={{
                  marginTop: '8px',
                  marginLeft: '8px'
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default OverseasApprHistoryLogListToolbar;
import React, { FC, useState, useEffect } from 'react';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { batchInfoMutation } from '../../lib/apolloClient/mutations';
import PrecedenceBatchInfoListToolbar from './PrecedenceBatchInfoListToolbar';
import PrecedenceBatchInfoTable from './PrecedenceBatchInfoTable';

type Props = {
  batchInfo: BatchInfo,
  setPrecedenceInfo: React.Dispatch<React.SetStateAction<BatchInfo>>,
  precedenceBatchInfoOpen: boolean,
  setPrecedenceBatchInfoOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PrecedenceBatchInfo: FC<Props> = ({ batchInfo, setPrecedenceInfo, precedenceBatchInfoOpen, setPrecedenceBatchInfoOpen }) => {
  const default_page = 0;
  const default_rowsPerPage = 100;
  // const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [inputBatchName, setInputBatchName] = useState('');
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          batchName : inputBatchName, 
          page: paging.page,
          rowsPerPage: paging.rowsPerPage } },
        mutation: batchInfoMutation.selectBatchInfoListByBatchName
      }).then(res => {     
        setData(res.data.selectBatchInfoListByBatchName.list);
        setTotalCount(res.data.selectBatchInfoListByBatchName.totalCount);
      }).catch(e => {
        console.log('error', e);
      });

      // setLoading(false)

    }
    fetch()
  }, [precedenceBatchInfoOpen, inputBatchName, paging]);

  const handlePrecedenceBatchInfoClose = () => {
    setPrecedenceBatchInfoOpen(false);
    setInputBatchName('');
    setPaging({
      ...paging,
      page: default_page,
      rowsPerPage : default_rowsPerPage
    })
  };

  return (
    <Dialog
      open={precedenceBatchInfoOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle id="alert-dialog-title">배치목록 선택</DialogTitle>
      <DialogContent>
        <div>
          <div>
            <PrecedenceBatchInfoListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setInputBatchName}/>  
          </div>
          <div>
            <PrecedenceBatchInfoTable paging={paging} setPaging={setPaging} totalCount={totalCount} batchInfo={batchInfo} setPrecedenceInfo={setPrecedenceInfo} batchlist={data}/>
          </div>
        </div>   
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handlePrecedenceBatchInfoClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrecedenceBatchInfo;

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import {format, subMonths} from 'date-fns';
import { overseasCsTrmMgmtMutation } from '../../lib/apolloClient/mutations';
import OverseasCsTrmMgmtToolbar from './OverseasCsTrmMgmtToolbar';
import OverseasCsTrmMgmtTable from './OverseasCsTrmMgmtTable';
import OverseasCsTrmMgmtDetails from './OverseasCsTrmMgmtDetails';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OverseasCsTrmMgmt = () => {
  const classes = useStyles();
  const default_page = 0;
  const default_rowsPerPage = 100;
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [updateClick, setUpdateClick] = useState(false);
  const [inputSearch, setInputSearch] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),   
    overseasCsTrmSeq: ''
  });
  const [statusCount, setStatusCount] = useState(0);
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          startDt : inputSearch.startDt.replace(/-/g,''),
          endDt : inputSearch.endDt.replace(/-/g,''),
          overseasCsTrmSeq : parseFloat(inputSearch.overseasCsTrmSeq) || 0, 
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: false } },
        mutation: overseasCsTrmMgmtMutation.selectOverseasCsTrmList
      }).then(res => {
        // console.log('>>>>>',res.data.selectBatchInfoList);  
        setData(res.data.selectOverseasCsTrmList);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch()
  }, [inputSearch, statusCount, paging])


  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외민원전금관리" />
      <OverseasCsTrmMgmtToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setInputSearch}/>
      <div className={classes.content}>
        <OverseasCsTrmMgmtTable statusCount={statusCount} setStatusCount={setStatusCount} paging={paging} setPaging={setPaging} overseasCsTrmList={data}/>
      </div>    
      <div className={classes.content}>
        <OverseasCsTrmMgmtDetails statusCount={statusCount} setStatusCount={setStatusCount}/>
      </div>
    </div>
  );

};

export default OverseasCsTrmMgmt;

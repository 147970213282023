import { makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import * as utils from '../../common/utils';
import { isNullOrBlank } from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import { useToast } from '../../contexts/Toast';
import apolloClient from '../../lib/apolloClient';
import { cardDeliveryInfoMutation } from '../../lib/apolloClient/mutations';
import { cardDelivery } from './cardApi';
import CardDeliveryDetail from './CardDeliveryDetail';
import CardDeliveryInfoToolbar from './CardDeliveryInfoToolbar';
import CardList from './CardList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  className?: string;
  cardUserInfoReturn: CardDeliveryInfoCardMemberInfoReturn; // 회원정보 타입
  cardInfoListReturn: CardDeliveryInfoCardInfoReturn[]; // 카드목록 타입
  cardInfoReturn: CardDeliveryInfoCardInfoReturn; // 카드단건 타입
  cardDeliveryInfoAPI: CardDeliveryInfoAPI; // 카드 발급/배송조회 상세
};

const CardDeliveryInfo: FC<Props> = ({
  className,
  cardUserInfoReturn,
  cardInfoListReturn,
  cardDeliveryInfoAPI,
  cardInfoReturn,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const { showToast } = useToast();

  // 회원정보
  const [inputSearchGubun, setInputSearchGubun] = useState(''); // set [조회구분]
  const [inputSearchVal, setInputSearchVal] = useState(''); // set [조회값]
  const [cardUserInfo, setCardUserInfo] = useState(cardUserInfoReturn); // 회원정보

  // 보유카드목록
  const [cards, setCards] = useState([]); // 보유카드목록

  // 카드 발금/배송 조회
  const [cardDeliveryDetail, setCardDeliveryDetail] = useState(cardDeliveryInfoAPI);

  // 카드 정보
  const [cardInfo, setCardInfo] = useState(cardInfoReturn);

  // 회원정보 조회
  useEffect(() => {
    console.log('useEffect 실행 - 회원정보 조회');

    setCardUserInfo(cardUserInfoReturn); // 회원정보 초기화
    setCards([]); // 보유카드 목록 초기화
    setCardInfo(cardInfoReturn); // 카드정보 초기화
    setCardDeliveryDetail(cardDeliveryInfoAPI); // 배송조회 초기화

    if (inputSearchVal !== '') {
      const fetch = async () => {
        await apolloClient
          .mutate({
            variables: { searchGubun: inputSearchGubun, searchVal: inputSearchVal },
            mutation: cardDeliveryInfoMutation.cardDeliveryInfoSelectCardMemberInfo,
          })
          .then(res => {
            console.log('회원 조회 결과 : ', res.data.cardDeliveryInfoSelectCardMemberInfo);
            setCardUserInfo(res.data.cardDeliveryInfoSelectCardMemberInfo); // 회원정보
          })
          .catch(console.error);
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [inputSearchGubun, inputSearchVal]);

  // 보유카드목록 조회
  useEffect(() => {
    console.log('useEffect 실행 - 카드목록 조회');

    setCards([]); // 보유카드 목록 초기화
    setCardInfo(cardInfoReturn); // 카드정보 초기화
    setCardDeliveryDetail(cardDeliveryInfoAPI); // 배송조회 초기화

    if (cardUserInfo !== undefined && cardUserInfo !== null) {
      // 회원정보가 존재할 때
      const fetch = async () => {
        setLoading(true);

        await apolloClient
          .mutate({
            variables: { cardMemberNo: cardUserInfo.cardMemberNo }, // set [카드회원번호]
            mutation: cardDeliveryInfoMutation.cardDeliveryInfoSelectCardList,
          })
          .then(res => {
            console.log('보유카드 목록 조회 결과 : ', res.data.cardDeliveryInfoSelectCardList);
            setCards(res.data.cardDeliveryInfoSelectCardList); // 보유카드목록
          })
          .catch(console.error);

        setLoading(false);
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [cardUserInfo]);

  // 카드 발급/배송 정보 DIPS API 호출
  useEffect(() => {
    console.log('useEffect 실행 - 카드발급/배송 정보 DIPS API 호출');

    if (cardInfo !== undefined && !isNullOrBlank(cardInfo.cardNo)) {
      // 카드 번호가 존재할 때
      cardDeliveryInfo();
    }
    // eslint-disable-next-line
  }, [cardInfo]);

  // 카드 발급/배송 정보 DIPS API 호출 Action
  const cardDeliveryInfo = async () => {
    try {
      if (cardInfo !== undefined) {
        setDeliveryLoading(true);
        const result = await cardDelivery({
          AuditClss: '2',
          AuditKey: cardInfo.cardNo,
          NextKey: '00',
        });

        console.log('결과: ', result);
        setDeliveryLoading(false);

        const status = result.STATUS;
        const resData = result.resData;

        // 결과 Toast 메세지
        if (status.success === 'Y') {
          // 발급 배송 조회 정보 세팅
          setCardDeliveryDetail(resData.view);
        } else {
          // 배송조회 초기화
          setCardDeliveryDetail(cardDeliveryInfoAPI);

          showToast(status.message + ` 에러코드: (${status.code})`);
        }
      }
    } catch (e) {
      let errorMessage = '';
      if (e.message.substring(e.message.length - 3) === '401') {
        errorMessage = '올바르지 않은 카드번호입니다. 에러코드: (79)';
      } else if (e.message.substring(e.message.length - 3) === '500') {
        errorMessage = '시스템 장애 에러코드 (89)';
      } else {
        errorMessage = '서버와의 통신이 원활하지 않습니다. \n관리자에게 문의하시기 바랍니다.';
      }
      showToast(errorMessage, 'error');

      // 배송조회 초기화
      setCardDeliveryDetail(cardDeliveryInfoAPI);
    }
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="카드 발급/배송 조회" />
      <CardDeliveryInfoToolbar setInputSearchGubun={setInputSearchGubun} setInputSearchVal={setInputSearchVal} />
      <div className={classes.content}>
        {loading ? utils.Loading() : <CardList title="보유카드목록" cards={cards} setCardInfo={setCardInfo} />}
        <br />
        {deliveryLoading ? (
          utils.Loading()
        ) : (
          <CardDeliveryDetail title="카드 발급/배송 정보" cardDeliveryDetail={cardDeliveryDetail} cardInfo={cardInfo} />
        )}
      </div>
    </div>
  );
};

export default CardDeliveryInfo;

import gql from 'graphql-tag';

// 승인집계내역 조회
export const selectApprovalAggr = gql`
  mutation selectApprovalAggr($aggregationDivisionCode: String!, $cardProductCode: String!, $startDt: String!, $endDt: String!) {
    selectApprovalAggr(aggregationDivisionCode: $aggregationDivisionCode, cardProductCode: $cardProductCode, startDt: $startDt, endDt: $endDt) {
      tradeDate
      aggregationDivisionCode
      cardProductCode
      cardProductName
      approvalCount
      approvalAmount
      cancelCount
      cancelAmount
      inabledCount
      inabledAmount
      bcRejectCount
      bcRejectAmount
    }
  }
`;

// 승입집계 조회조건(상품목록) 조회
export const selectProductList = gql`
  mutation selectProductList($useYn: String!) {
    selectProductList(useYn: $useYn) {
      cardProductCode
      cardProductCodeName
    }
  }
`;

// 승인집계 합계 조회
export const selectApprovalAggrSum = gql`
  mutation selectApprovalAggrSum($aggregationDivisionCode: String!, $cardProductCode: String!, $startDt: String!, $endDt: String!) {
    selectApprovalAggrSum(aggregationDivisionCode: $aggregationDivisionCode, cardProductCode: $cardProductCode, startDt: $startDt, endDt: $endDt) {
      approvalCount
      approvalAmount
      cancelCount
      cancelAmount
      inabledCount
      inabledAmount
    }
  }
`;

// 승입집계 상세목록 조회
export const selectApprovalDetailList = gql`
  mutation selectApprovalDetailList($tradeDate: String, $cardProductCode: String, $salesKindDivisionCodeGubun: String) {
    selectApprovalDetailList(tradeDate: $tradeDate, cardProductCode: $cardProductCode, salesKindDivisionCodeGubun: $salesKindDivisionCodeGubun) {
      tradeDate
      tradeApprovalSeq
      tradeUniqNo
      cardNo
      cardNoMasked
      salesKindDivisionCode
      approvalDate
      approvalTime
      cardApprovalNo
      cardMemberNo
      cardApprovalAmount
      merchantNo
      merchantName
      inoutProcessSeq
      paymentId
      paymentAcno
      approvalProcessStatusCode
      slipPurchaseYn
      _member {
        userKorName
        userId
      }
    }
  }
`;

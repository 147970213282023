import React, { FC,useState } from 'react';
import { Theme, makeStyles, CardContent } from '@material-ui/core';
import {
    Card,
    Grid,
    Button,
    TextField,
  } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  TextField : {
    marginRight: theme.spacing(1)
  },
}));

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<ProductMgmt>>
}



const SearchProduct: FC<Props> = ({onSearch}) => {
  const classes = useStyles();

  const [cardProductGroupName, setcardProductGroupName] = useState(''); //카드상품그룹명
  const [cardProductCode, setcardProductCode] = useState(''); //카드상품코드
  const [cardProductCodeName, setcardProductCodeName] = useState(''); //카드상품코드명
  const [useYn, setuseYn] = useState(''); //사용여부

  const handlecardProductGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcardProductGroupName(event.target.value);
  };
  const handlecardProductCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcardProductCode(event.target.value);
  };
  const handlecardProductCodeName= (event: React.ChangeEvent<HTMLInputElement>) => {
    setcardProductCodeName(event.target.value);
  };
  const handleuseYn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setuseYn(event.target.value);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
    if(event.key ==="Enter"){
      onSearchClick();
    }

  }

  const onSearchClick = async () => {
    
    const productMgmtSearch:ProductMgmt = {} as any;

    productMgmtSearch.cardProductGroupName = cardProductGroupName; 
    productMgmtSearch.cardProductCode = cardProductCode; 
    productMgmtSearch.cardProductCodeName = cardProductCodeName;
    productMgmtSearch.useYn = useYn;

    onSearch(productMgmtSearch);
  };


  return (
        <Card>
            <CardContent>
            <Grid
            container
            spacing={1}
          >
          <Grid item md={2} xs={6}> 
          <TextField className={classes.searchInput} placeholder="카드상품그룹명" variant="outlined" margin="dense" label="카드상품그룹명"
             InputLabelProps={{shrink: true,}} fullWidth
             onChange={handlecardProductGroupName} value={cardProductGroupName} onKeyPress={onKeyEnter} ></TextField>
          </Grid>
          <Grid item md={2} xs={6}>
          <TextField className={classes.searchInput} placeholder="카드상품코드" variant="outlined" margin="dense" label="카드상품코드"
          InputLabelProps={{shrink: true,}} fullWidth
          onChange={handlecardProductCode} value={cardProductCode} onKeyPress={onKeyEnter} ></TextField>
          </Grid>
          <Grid item md={2} xs={6}>
          <TextField className={classes.searchInput} placeholder="카드상품코드명" variant="outlined" margin="dense" label="카드상품코드명"
          InputLabelProps={{shrink: true,}} fullWidth
          onChange={handlecardProductCodeName} value={cardProductCodeName} onKeyPress={onKeyEnter} ></TextField>
          </Grid>
          <Grid item md={2} xs={6}>
          <TextField className={classes.searchInput} placeholder="사용여부" variant="outlined" margin="dense" label="사용여부"
          InputLabelProps={{shrink: true,}} fullWidth
          onChange={handleuseYn} value={useYn} onKeyPress={onKeyEnter} ></TextField>
          </Grid>
          <Grid item md={2} xs={6}>
          <Button variant="contained" color="primary" onClick={onSearchClick} style = {{marginTop: '8px' }}>SEARCH</Button>
          </Grid>
          </Grid>              
          </CardContent>
        </Card>

  );
};

export default SearchProduct;

import 'date-fns';
import React, { FC, useState } from 'react';
import { Grid, TextField, Card, CardContent, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<PurchasedAggrHistorySearch>>
}

const PurchasedAggrHistoryListToolbar: FC<Props> = ({onSearch}) => {  
  
  const [pStartAggregationDate, setStartAggregationDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [pEndAggregationDate, setEndAggregationDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  
  const handleStartAggregationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartAggregationDate(e.target.value);
  };
  const handleEndAggregationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndAggregationDate(e.target.value);
  };

  const { showToast } = useToast();
  const { hideDialog } = useDialog();

  const onSearchClick = async () => {
    const purchasedAggrHistorySearch:PurchasedAggrHistorySearch = {} as any;

    if(pStartAggregationDate==null){
      showToast('집계시작일자를 입력해주세요.', 'error')
      hideDialog(); 
      return;    
    }
    if(pEndAggregationDate==null){
      showToast('집계종료일자를 입력해주세요.', 'error')
      hideDialog(); 
      return;    
    }
    
    purchasedAggrHistorySearch.startAggregationDate = chaiStringUtils.dateFormat(pStartAggregationDate, 'yyyyMMdd');
    purchasedAggrHistorySearch.endAggregationDate = chaiStringUtils.dateFormat(pEndAggregationDate, 'yyyyMMdd');
    onSearch(purchasedAggrHistorySearch);
  };

  return (
    <Card>
      <CardContent>
      <Grid container spacing={1}>
        <Grid item md={2}>
          <TextField
              required
              fullWidth
              type="date"
              label="집계시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              name="pStartAggregationDate"
              value={pStartAggregationDate}
              onChange={handleStartAggregationDateChange}
            />    
        </Grid>
        <Grid item md={2}>
          <TextField
              required
              fullWidth
              type="date"
              label="집계종료일자"
              margin="dense"
              InputProps={{inputProps: { min: format(new Date(pStartAggregationDate), 'yyyy-MM-dd') } }}
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              name="pEndAggregationDate"
              value={pEndAggregationDate}
              onChange={handleEndAggregationDateChange}
            />    
        </Grid>
        <Grid item md={1}>
        <Button color="primary" variant="contained" onClick={onSearchClick} style = {{ marginTop: '9px' }}>Search</Button>
        </Grid>
      </Grid>
      </CardContent>
    </Card>
  );
};

export default PurchasedAggrHistoryListToolbar;

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import { batchInfoMutation } from '../../lib/apolloClient/mutations';
import BatchInfoListToolbar from './BatchInfoListToolbar';
import BatchInfoTable from './BatchInfoTable';
import BatchInfoDetails from './BatchInfoDetails';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const BatchInfoList = () => {
  const classes = useStyles();
  const default_page = 0;
  const default_rowsPerPage = 100;
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [batchInfoSearch, setBatchInfoSearch] = useState({
    batchName: '',
    fileName: ''
  });
  const [statusCount, setStatusCount] = useState(0);

  const [batchInfo, setBatchInfo] = useState({
    totalCount: 0,
    batchSeqNo: 0,
    batchId: '',
    batchName: '',
    precedenceBatchId: '',
    precedenceBatchName: '',
    executionCycle: '1',
    remarkDescription: '',
    fileName: '',
    processDate: '',
    processStatus: ''
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          batchName : batchInfoSearch.batchName, 
          fileName : batchInfoSearch.fileName, 
          page: paging.page,
          rowsPerPage: paging.rowsPerPage } },
        mutation: batchInfoMutation.selectBatchInfoList
      }).then(res => {
        // console.log('>>>>>',res.data.selectBatchInfoList);  
        setData(res.data.selectBatchInfoList);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch()
  }, [batchInfoSearch, statusCount, paging])


  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="배치내역" />
      <BatchInfoListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setBatchInfoSearch}/>
      <div className={classes.content}>
        <BatchInfoDetails statusCount={statusCount} setStatusCount={setStatusCount} updateClick={updateClick} setUpdateClick={setUpdateClick} batchInfo={batchInfo} setBatchInfo={setBatchInfo} />
      </div>    
      <div className={classes.content}>
        <BatchInfoTable statusCount={statusCount} setStatusCount={setStatusCount} setUpdateClick={setUpdateClick} paging={paging} setPaging={setPaging} batchlist={data} setBatchInfo={setBatchInfo}/>
      </div>
    </div>
  );

};

export default BatchInfoList;

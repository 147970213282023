import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type ApprovalAggrSumInfo = {
  approvalCount	:number;         // 입금건수
  approvalAmount	:number;       // 입금금액
  cancelCount	:number;       // 출금건수
  cancelAmount	:number;       // 출금금액
  inabledCount	:number;     // 합계건수
  inabledAmount	:number;     // 합계금액
}

type Props = { 
  sumInfo: ApprovalAggrSumInfo
}

const ApprovalAggrSumTable: FC<Props> = ({ sumInfo }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.content} style={{paddingBottom: '0'}}>
        <PerfectScrollbar>
        <div className={classes.inner}>
          <Table size="small" >
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>정상건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.approvalCount.toLocaleString()} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>취소건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.cancelCount.toLocaleString()} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>불능건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.inabledCount.toLocaleString()} 건</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>정상금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.approvalAmount.toLocaleString()} 원</TableCell>
                <TableCell align="center" className={classes.thStyle}>취소금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.cancelAmount.toLocaleString()} 원</TableCell>
                <TableCell align="center" className={classes.thStyle}>불능금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{sumInfo.inabledAmount.toLocaleString()} 원</TableCell>
              </TableRow>
            </TableBody>          
          </Table>
        </div>
      </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default ApprovalAggrSumTable;

import client from './client';
import { get } from 'lodash';
import chaiStringUtils from '../../common/chaiStringUtils';

//BC6406VO
export default async (
  input: GetUserInfoInput /*, cardNumber: string, cardEfctvYm: string, issueType: string, prevCardNo?: string*/
): Promise<GetUserInfoInput> => {
  return client
    .post(`/root/dips/card/issue`, getBody(input /*cardNumber, cardEfctvYm, issueType, prevCardNo*/))
    .then(res => {
      const statusCode = get(res, 'data.STATUS.code', undefined);
      if (!statusCode || statusCode !== '00') {
        throw new Error(`회원정보 변경 실패 - 알수없는 에러`);
      }

      return res.data.body;
    })
    .catch(e => {
      throw e;
    });
};

const getBody = (
  input: GetUserInfoInput /*, cardNumber: string, cardEfctvYm: string, issueType: string, prevCardNo?: string*/
) => {
  return {
    //header: getRequestHeader('6406', 'BCA'),
    //body: {
    ProcClss: '1',
    AplcClss: '41',
    AffiFirmNo: input.AffiFirmNo,
    CurCardNoEnc: Number.isNaN(Number(input.CurCardNo)) ? input.CurCardNo : chaiStringUtils.encrypt(input.CurCardNo),
    EngNm: input.EngNm,
    HgNm: input.HgNm,

    /*HomeDDDNo: '',
      HomeTelNo1: '',
      HomeTelNo2: '',*/

    HpNo1: input.HpNo1,
    HpNo2: input.HpNo2,
    HpNo3: input.HpNo3,

    //Email: input.Email,
    HomeNwOldAddrClss: input.HomeNwOldAddrClss,
    HomeZpNo: input.HomeZpNo,
    HomeAddr: input.HomeAddr,
    HomeNwAddr2: input.HomeNwAddr2,
    //}
  };
};

import gql from 'graphql-tag';

export const selectOverseasCsList = gql`
  mutation selectOverseasCsList($input : OverseasCsListSearch) {
    selectOverseasCsList (input: $input) {
      totalCount
      overseasCsSeq
      overseasCsReceveDate
      overseasCsEndDate
      overseasCsReceiptCode
      cardSeq
      purchasedProcessNo
      cardNo
      firstCardNo
      userKorName
      overseasCsReasonDivisionCode
      merchantEngName
      salesDate
      salesKindDivisionCode
      mccMerchantCode
      bcNationNo
      localTradeAmount
      overseasPaymentUsdAmount
      billAmount
      wonOriginAmount
      exchangeCommissionAmount
      cashFee
      endUsdAmount
      endKrwAmount
      brandDivisionCode
      overseasCardApprovalNo
      approvalDate
      onusDivisionCode
      overseasCsEndDivisionCode
      csRewardTargetYn
      rewardProgressStatusCode
      rewardProgressStatusCodeName
      bcCsReceiptSeq
    }
  }
`;

// 해외민원보상내역에 저장
export const insertOverseasCsReword = gql`
  mutation insertOverseasCsReword($checkValuesList : [String]!) {
    insertOverseasCsReword(checkValuesList : $checkValuesList) {
      result
    }
  }
`;

// 해외민원관리 수기종결처리
export const updateOverseasCsHandEnd = gql`
  mutation updateOverseasCsHandEnd ($checkValuesList : [String]!) {
    updateOverseasCsHandEnd (checkValuesList : $checkValuesList) {
      result
    }
  }
`;
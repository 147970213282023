import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

type Props = {
    className?: string,
    clicked: boolean,       // excel 버튼이 클릭되었는지 확인을 위한 props
    setClicked: React.Dispatch<React.SetStateAction<boolean>>,      // excel 출력 이후 clicked를 초기화 하기 위한 props
}

const ExportIntoExcel: FC<Props> = ({ 
    clicked, 
    setClicked,
    className
}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';     // 출력될 excel 파일의 타입 지정
    const fileExtension = '.xlsx';      // 출력될 excel 파일의 확장자 지정

    const excelData = () => {
        let rowData = []
        rowData.push({
            '그룹가맹점번호': '',
            '그룹가맹점명': '',
            '제휴대상번호': '',
            '제휴대상명': '',
            '해지일자': '',
        });

        return rowData;
      }


    // excel export 라이브러리를 통한 excel 출력 함수
    const exportToCSV = (csvData: any[]) => {
        const fileName = 'GroupMerchant Upload Format';     // 생성될 excel 파일의 파일명 지정
        const ws = XLSX.utils.json_to_sheet(csvData);       // 데이터를 excel xlsx 데이터로 변경
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };        // excel 파일의 sheet 지정
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });        // array 형식으로 excel에 데이터 작성
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);       // 생성된 excel 파일을 파일로써 저장
    }

    return (
        <Button 
            variant="contained" 
            color="primary"
            className={className}
            onClick={
                (event) => {
                    setClicked(!clicked);       // clicked 초기화
                    exportToCSV(excelData());       // 파일 생성 함수 실행
                }}
            >
            업로드양식
        </Button>
    )
};

export default ExportIntoExcel;

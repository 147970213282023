import React, { useState, FC } from 'react';
import {
  Theme,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  TablePagination,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

// 대행수수료코드리스트

type Props = {
  setProxyFeeCodeList: React.Dispatch<React.SetStateAction<FeeCodeInfoReturn>>;
  proxyFeeCodeList: FeeCodeInfoReturn[];
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmptyForm: React.Dispatch<React.SetStateAction<boolean>>;
  updateClick: boolean;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProxyFeeCodeList: FC<Props> = ({
  setProxyFeeCodeList,
  proxyFeeCodeList,
  setUpdateClick,
  setIsValidForm,
  setIsEmptyForm,
  updateClick,
  setIsNew
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const mapFeeInfoDivisionCode = new Map<string, string>();
  mapFeeInfoDivisionCode.set('', '');
  mapFeeInfoDivisionCode.set('1', '위임');
  mapFeeInfoDivisionCode.set('2', '단위');

  const mapDomesticCode = new Map<string, string>();
  mapDomesticCode.set('1', '국내');
  mapDomesticCode.set('2', '해외');

  const mapDomesticBrandDivisionCode = new Map<string, string>();
  mapDomesticBrandDivisionCode.set('1', '국내');
  mapDomesticBrandDivisionCode.set('9', '공통');

  const mapProxyFeeGroupCode = new Map<string, string>();
  mapProxyFeeGroupCode.set('1', '승인');
  mapProxyFeeGroupCode.set('2', '회원');
  mapProxyFeeGroupCode.set('3', '발급카드');

  const mapFeeClaimMethodDivisionCode = new Map<string, string>();
  mapFeeClaimMethodDivisionCode.set('1', '정액');
  mapFeeClaimMethodDivisionCode.set('2', '단가');
  mapFeeClaimMethodDivisionCode.set('3', '구간');
  mapFeeClaimMethodDivisionCode.set('4', '정률');
  mapFeeClaimMethodDivisionCode.set('5', '복합');

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const mapDeleteYn = new Map<String, String>();
  mapDeleteYn.set('Y', '삭제');
  mapDeleteYn.set('N', '미삭제');

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  const classes = useStyles();

  if (
    proxyFeeCodeList === null ||
    proxyFeeCodeList === undefined ||
    proxyFeeCodeList.length === 0
  ) {
    return (
      <Card style={{ backgroundColor: '#F4F6F8' }}>
        <CardContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '0px solid white' }}>
                  조회된 내용이 없습니다.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return (
    <div>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">NO.</TableCell>
                    <TableCell align="center">수수료 정보 구분</TableCell>
                    <TableCell align="center">수수료 코드</TableCell>
                    <TableCell align="center">수수료명</TableCell>
                    <TableCell align="center">유효시작일자</TableCell>
                    <TableCell align="center">유효종료일자</TableCell>
                    <TableCell align="center">삭제여부</TableCell>
                    <TableCell align="center">국내외 브랜드 구분</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proxyFeeCodeList
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((item, idx) => (
                      <TableRow
                        onClick={() => {
                          setProxyFeeCodeList(item);
                          setUpdateClick(!updateClick);
                          setIsEmptyForm(false);
                          setIsValidForm(false);
                          setIsNew(true);
                        }}
                        hover
                        key={item.proxyFeeCodeNo + '' + item.unitFeeCodeNo}>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {page * rowsPerPage + idx + 1}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {
                              mapFeeInfoDivisionCode.get(item.feeInfoDivisionCode)
                            }
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {item.feeInfoDivisionCode === '1'
                              ? item.proxyFeeCodeNo
                              : item.unitFeeCodeNo}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {item.feeInfoDivisionCode === '1'
                              ? item.proxyFeeNm
                              : item.unitFeeName}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{
                              color: 'navy'
                            }}>{`${item.validateStartDate.substr(
                            0,
                            4
                          )}년 ${item.validateStartDate.substr(
                            4,
                            2
                          )}월 ${item.validateStartDate.substr(
                            6,
                            2
                          )}일`}</Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{
                              color: 'navy'
                            }}>{`${item.validateEndDate.substr(
                            0,
                            4
                          )}년 ${item.validateEndDate.substr(
                            4,
                            2
                          )}월 ${item.validateEndDate.substr(6, 2)}일`}</Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {mapDeleteYn.get(item.deleteYn)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setProxyFeeCodeList(item);
                              setUpdateClick(!updateClick);
                              setUpdateClick(!updateClick);
                              setIsValidForm(false);
                              setIsNew(true);
                            }}
                            style={{ color: 'navy' }}>
                            {mapDomesticBrandDivisionCode.get(item.domesticBrandDivisionCode)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination // pagenation 조작을 위한 함수 지정
            style={{ marginLeft: 'auto' }}
            component="div"
            count={proxyFeeCodeList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

export default ProxyFeeCodeList;

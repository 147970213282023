import client from './client';
import { get, find, orderBy } from 'lodash';

/**list와 detail 분리.. */
export default async (input: GetCardListInput): Promise<CardInfo[]> => {
  console.log('보유카드목록 조회 START');
  console.log('type :' + input.type)
  console.log('csno : ' + input.csno)
 // console.log('cardNo : ' + input.cardNo)

  if (!input.csno && !input.cardNo) {
    throw new Error(`카드정보 조회 실패 - 입력값이 없습니다`)
  }

  return client.post(`/root/card/cardInfoListSelect`, input).then(res => {
    const cardList = get(res, 'data.CardInfo');
    console.log('cardList::'+JSON.stringify(cardList));

    if (!cardList) {
      throw new Error(`카드정보 조회 실패`)
    }

    //list
    if(input.type === 'list'){
      return orderBy(cardList, ['firstOperateDetailDate'], ['desc']);
    }else{ //detail
      return find(cardList, item => { return item.cardNo === input.cardNo })
    }
   
  });
}
import gql from 'graphql-tag';


export const selectCommonCodeInfoList = gql`
  mutation selectCommonCodeInfoList($typeCode: String, $groupCode: String, $codeName: String) {
    selectCommonCodeInfoList (typeCode : $typeCode, groupCode: $groupCode, codeName: $codeName) {
        codeName
        typeCode
        groupCode
        codeValue
        codeId
        useYn
        codeDescription
        mapCode
        groupCodeName
        typeCodeName
    }
  }
`;

export const selectTypeCodeInfoList = gql`
  mutation selectTypeCodeInfoList($typeCode: String) {
    selectTypeCodeInfoList (typeCode : $typeCode) {
        codeName
        typeCode
        groupCode
        codeValue
        codeId
        useYn
    }
  }
`;

export const selectGroupCodeInfoList = gql`
  mutation selectGroupCodeInfoList($groupCode: String) {
    selectGroupCodeInfoList (groupCode : $groupCode) {
        codeName
        typeCode
        groupCode
        codeValue
        codeId
        useYn
    }
  }
`;

// export const selectBatchInfoListByBatchName = gql`
//   mutation selectBatchInfoList($batchName : String) {
//     selectBatchInfoListByBatchName (batchName : $batchName) {
//       batchSeqNo
//       batchId
//       batchName
//       precedenceBatchId
//       executionCycle
//       fileName
//     }
//   }
// `;


export const insertCommonCodeInfo = gql`
  mutation insertCommonCodeInfo($input : CommonCodeInfoInput) {
    insertCommonCodeInfo(input : $input) {
      codeId
    }
  }
`;

export const insertTypeCodeInfo = gql`
  mutation insertTypeCodeInfo($input : TypeCodeInfoInput) {
    insertTypeCodeInfo(input : $input) {
      typeCode
    }
  }
`;

export const insertGroupCodeInfo = gql`
  mutation insertGroupCodeInfo($input : GroupCodeInfoInput) {
    insertGroupCodeInfo(input : $input) {
      groupCode
    }
  }
`;

export const updateCommonCodeInfo = gql`
  mutation updateCommonCodeInfo($input : CommonCodeInfoInput) {
    updateCommonCodeInfo(input : $input) {
      codeId
    }
  }
`;


export const changeTypeCodeUseYn =  gql`
mutation changeTypeCodeUseYn($input : TypeCodeInfoInput) {
  changeTypeCodeUseYn(input : $input) {
    typeCode
    useYn
    codeName
  }
}
`;

export const changeGroupCodeUseYn =  gql`
mutation changeGroupCodeUseYn($input : GroupCodeInfoInput) {
  changeGroupCodeUseYn(input : $input) {
    groupCode
    useYn
    codeName
  }
}
`;


// export const selectBatchProcessHistoryList = gql`
//   mutation selectBatchProcessHistoryList($input : BatchProcessHistorySearch) {
//     selectBatchProcessHistoryList(input : $input) {
//       batchSeqNo
//       processDate
//       processSeqNo
//       startDate
//       endDate
//       processStatus
//       processCount
//       targetDataCount
//       errorMessage
//     }
//   }
// `;


import gql from 'graphql-tag';

export const selectPurchasedAggrHistoryList = gql`
  mutation selectPurchasedAggrHistoryList($startAggregationDate: String, $endAggregationDate: String) {
    selectPurchasedAggrHistoryList(startAggregationDate: $startAggregationDate, endAggregationDate: $endAggregationDate) {
      aggregationDate
      aggregationDivisionCode
      aggregationUnitDivisionCode
      accountDivisionCode
      cardTypeCode
      domesticCode
      aggregationBaseDate
      withdrawalCount
      withdrawalTotalAmount
      depositCount
      depositTotalAmount
      tradeProcessCount
      tradeTotalAmount
      withdrawalFeeCount
      withdrawalFeeTotalAmount
      feeDepositCount
      feeDepositTotalAmount
      feeProcessCount
      feeTotalAmount
      settlementProcessYn
      settlementProcessTime
    }
  }
`;

export const selectPurchasedAggrHistoryInfo = gql`
  mutation selectPurchasedAggrHistoryInfo($startAggregationDate: String, $endAggregationDate: String) {
    selectPurchasedAggrHistoryInfo(startAggregationDate: $startAggregationDate, endAggregationDate: $endAggregationDate) {
      aggregationDate
      aggregationDivisionCode
      aggregationUnitDivisionCode
      accountDivisionCode
      cardTypeCode
      domesticCode
      aggregationBaseDate
      withdrawalCount
      withdrawalTotalAmount
      depositCount
      depositTotalAmount
      tradeProcessCount
      tradeTotalAmount
      withdrawalFeeCount
      withdrawalFeeTotalAmount
      feeDepositCount
      feeDepositTotalAmount
      feeProcessCount
      feeTotalAmount
      settlementProcessYn
      settlementProcessTime
    }
  }
`;

export const selectPurchasedAggrDetailList = gql`
  mutation selectPurchasedAggrDetailList($aggregationDate: String, $accountDivisionCode: String, $salesKindDivisionCode: String) {
    selectPurchasedAggrDetailList(aggregationDate: $aggregationDate, accountDivisionCode: $accountDivisionCode, salesKindDivisionCode: $salesKindDivisionCode) {
      receveSeq
      receveBaseDate
      accountDivisionCode
      receveDate
      settlementDate
      depositDate
      billDate
      salesKindDivisionCode
      purchasedProcessNo
      merchantNo
      cardSeq
      cardNo
      cardNoMasked
      cardMemberNo
      userId
      cardApprovalNo
      salesDate
      salesNetAmount
      serviceAmount
      taxAmount
      salesTotalAmount
      installmentMonth
      cardProductCode
      managementBranchCode
      merchantName
      merchantBizNo
      merchantFee
      merchantFeeRate
      issuerFee
      issuerFeeRate
      merchantInstallmentFee
      onlineSalesFee
      merchantUseFee
      merchantUseBcFee
      merchantBusinessCode
      settlementBusinessCode
      settlementFeeRate
      purchasedSettlementFee
      useFee
      checkSalesPrepayAmount
      saveSalesDivisionCode
      installmentRound
      merchantReductionExtFee
      installmentDivisionCode
      installmentNo
      revolvingDivisionCode
      revolvingNo
      approvalServiceDivisionCode
      userServiceDivisionCode
      commonMerchantUseDivisionCode
      checkSalesDivisionCode
      salesApplySalesDivisionCode
      originReceveNo
      originSalesDate
      pgBizNo
      merchantDivisionCode
      checkSalesPrepayDivisionCode
      checkSalesPrepayAccountNo
      extSettlementDivisionCode
      extApprovalDivisionCode
      taxReductionYn
      noInstallmentDivisionCode
      salesDiscountDivisionCode
      salesCancelDivisionCode
      salesDiscountAmount
      salesBcResponseAmount
      salesPartnerResponseAmount
      settlementProcessYn
      pointDeductionUseAmount
      pointDeductionUserUseAmount
      merchantDeductionAuthNo
      supportDivisionCode
      supportAmount
      purchasedDataProcessYn
      supportAmountSettlementYm
      preSettlementDate
      cancelPrepayProcessYn
      purchasedProcessDate
      fileName
      changeTerminalNo
      inoutProcessCode
      inoutProcessSeq
      paymentId
      cardTypeCode
      dataProcessDivisionCode
      accountProcessSeq
    }
  }
`;

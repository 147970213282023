import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer',
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  thSumStyle: {
    backgroundColor: '#eeeeee',
  },
}));

type Props = {
  title: string;
  overseasCsRefundWholeList: overseasCsRefundWholeList[];
  setOverseasCsRefundWholeList: React.Dispatch<React.SetStateAction<overseasCsRefundWholeList[]>>;
  allCheckYn: boolean;
  setAllCheckYn: React.Dispatch<React.SetStateAction<boolean>>;
};

const OverseasCsRefundWholeTable: FC<Props> = ({
  title,
  overseasCsRefundWholeList,
  setOverseasCsRefundWholeList,
  allCheckYn,
  setAllCheckYn,
}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const onChangeAllCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const selectCheck = overseasCsRefundWholeList;
      await selectCheck.map(item => {
        item.checked = event.target.checked;
        return item;
      });
      await setAllCheckYn(!allCheckYn);
      await setOverseasCsRefundWholeList([]);
      await setOverseasCsRefundWholeList(selectCheck);
    } catch {
      console.error();
    }
  };

  const onChangeCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const wholeCheck = overseasCsRefundWholeList;
      const check = event.target.checked;

      await wholeCheck
        .filter(overseasCsRefundWhole => {
          if (
            overseasCsRefundWhole.overseasCsRewardSeq + '/' + overseasCsRefundWhole.overseasCsRewardProcessDate ===
            event.target.value
          ) {
            return overseasCsRefundWhole;
          }
          return true;
        })
        .map(overseasCsRefundWhole => {
          overseasCsRefundWhole.checked = check;
          return overseasCsRefundWhole;
        });

      await setAllCheckYn(allCheckYn);
      await setOverseasCsRefundWholeList([]);
      await setOverseasCsRefundWholeList(wholeCheck);
    } catch {
      console.error();
    }
  };

  return (
    <Card>
      {!!title && <CardHeader title={title} />}
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      onChange={event => {
                        onChangeAllCheck(event);
                      }}
                      checked={allCheckYn}
                    />
                  </TableCell>
                  <TableCell align="center">NO</TableCell>
                  <TableCell align="center">보상진행상태</TableCell>
                  <TableCell align="center">전금일자</TableCell>
                  <TableCell align="center">전금미화금액</TableCell>
                  <TableCell align="center">접수일자</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">매출종류구분</TableCell>
                  <TableCell align="center">매입접수번호</TableCell>
                  <TableCell align="center">회원한글명</TableCell>
                  <TableCell align="center">가맹점명</TableCell>
                  <TableCell align="center">해외결제미화금액</TableCell>
                  <TableCell align="center">입금요청일자</TableCell>
                  <TableCell align="center">보상현금수수료원화</TableCell>
                  <TableCell align="center">보상브랜드수수료원화</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overseasCsRefundWholeList
                  .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                  .map((overseasCsRefundWhole, index) => (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <Checkbox
                          onChange={event => {
                            onChangeCheck(event);
                          }}
                          value={
                            overseasCsRefundWhole.overseasCsRewardSeq +
                              '/' +
                              overseasCsRefundWhole.overseasCsRewardProcessDate || ''
                          }
                          checked={overseasCsRefundWhole.checked ? overseasCsRefundWhole.checked : false}
                        />
                      </TableCell>
                      <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.rewardProgressStatusCode}</TableCell>
                      <TableCell align="center">
                        {chaiStringUtils.dateFormatDefault(overseasCsRefundWhole.trmDate)}
                      </TableCell>
                      <TableCell align="center">
                        {chaiStringUtils.moneyFormat(overseasCsRefundWhole.trmUsdAmount)} 원
                      </TableCell>
                      <TableCell align="center">
                        {chaiStringUtils.dateFormatDefault(overseasCsRefundWhole.overseasCsReceveDate)}
                      </TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.cardNo}</TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.salesKindDivisionCode}</TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.purchasedProcessNo}</TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.userKorName}</TableCell>
                      <TableCell align="center">{overseasCsRefundWhole.merchantEngName}</TableCell>
                      <TableCell align="center">
                        {chaiStringUtils.moneyFormat(overseasCsRefundWhole.overseasPaymentUsdAmount)} 원
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        {chaiStringUtils.moneyFormat(overseasCsRefundWhole.rewardCashFeeKrwAmount)} 원
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={overseasCsRefundWholeList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default OverseasCsRefundWholeTable;

import React, { FC, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  FormGroup,
  FormControlLabel, 
  Checkbox
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalListMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold'
  }
}));

type Props = {
  tradeDate: string,
  tradeApprovalSeq: number,
  approvalDetailPopOpen: boolean,
  setApprovalDetailPopOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const ApprovalDetailPop: FC<Props> = ({ tradeDate, tradeApprovalSeq, approvalDetailPopOpen, setApprovalDetailPopOpen }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  // const [loading, setLoading] = useState(false);
  // const [detailData, setDetailData] = useState<>();
  const [detailData, setDetailData] = useState({
    cardApprovalNo :'',
    approvalDate :'',
    approvalTime :'',
    cardNo :'',
    cardNoMasked: '',
    ispApprovalDivisionCode :'',
    merchantName :'',
    merchantNo :'',
    sendDate :'',
    sendTime :'',
    cardApprovalAmount :0,
    pointPaymentYn:'',
    taxAmount :0,
    serviceFee :0,
    approvalRequestMethodCode :'',
    treatmentAgencyCode :'',
    installmentMonth :'',
    approvalTerminalNo :'',
    tradeUniqNo :'',
    salesKindDivisionCode :'',
    salesAmount :0,
    approvalProcessStatusCode :'',
    slipPurchaseYn : '',
    inoutDate :'', 
    inoutProcessCode :'', 
    inoutProcessSeq :'', 
    paymentId :'', 
    adminErrorCode :'', 
    adminErrorMessage :'',
    refundDate :'', 
    refundProcessCode :'', 
    refundProcessSeq :'', 
    refundPaymentId :'',
    nonInoutSeq : 0,
    nonInoutRegisteredDate : '',
    nonInoutRequestYn : '',
    _member : {
      userKorName: '',
      userId: ''
    },
    _card : {
      validateTermYm: ''
    },
    _merchant : {
      telNo1: '',
      telNo2: '',
      telNo3: ''
    }
  });

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {tradeDate : tradeDate, tradeApprovalSeq: tradeApprovalSeq},
        mutation: approvalListMutation.selectApproval
      }).then(res => {  
        console.log(res.data.selectApproval);
        //setDetailData(res.data.selectApproval);
        const result = res.data.selectApproval;
        setDetailData({
          ...detailData,
          ...result
        });
        
      }).catch(e => {
        console.log('error', e);
        showToast(e.message, 'error');
      });

      // setLoading(false);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeDate, tradeApprovalSeq]);

  const handleApprovalDetailPopClose = () => {
    setApprovalDetailPopOpen(false);
  };


  const displayProcessStatus = (processStatus:string) => {
    
    let displayStatus = '';
    if (processStatus === '1') displayStatus = '정상';
    else if (processStatus === '2') displayStatus = '오류';
    else displayStatus = '처리중';

    return displayStatus;
  };

  const displayInoutProcessCode = (inoutProcessCode:string) => {
    
    let displayProcessCode = '';
    if (inoutProcessCode === '0') displayProcessCode = '입출금요청';
    else if (inoutProcessCode === '1') displayProcessCode = '입출금성공';
    else if (inoutProcessCode === '2') displayProcessCode = '입출금실패';
    else displayProcessCode = '';

    return displayProcessCode;
  };

  return (
    <Dialog
      open={approvalDetailPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title"><MenuTitleToolbar title="승인내역 상세조회" /></DialogTitle>
      <Divider />
      <DialogContent>
        <div>
            <Chip label="▶ 카드승인내역" color="primary"  size="small"  className={classes.chipStyle}/>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.cardApprovalNo}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인일시</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailData.approvalDate) + ' ' + chaiStringUtils.dateTimeFormat(detailData.approvalTime)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>결제방식</TableCell>
                  <TableCell align="center" className={classes.tdStyle} colSpan={3}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox size="small" checked={false} style = {{ padding: '0px' }}/>}
                        label="모바일카드여부"
                        color="primary"
                      />
                      <FormControlLabel /* accountTradeDivisionCode */
                        control={<Checkbox size="small" checked={true} style = {{ padding: '0px' }} />}
                        label="계좌거래"
                        color="primary"
                      />
                      <FormControlLabel
                        control={<Checkbox size="small" checked={false} style = {{ padding: '0px' }} />}
                        label="선결제"
                        color="primary"
                      />
                    </FormGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>카드번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.cardNoMasked}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>유효기한</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData._card && detailData._card.validateTermYm}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>한글성명</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData._member && detailData._member.userKorName}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>ISP승인방식</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.ispApprovalDivisionCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>가맹점명</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.merchantName}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>가맹점전화번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData._merchant && (detailData._merchant.telNo1 +' '+ detailData._merchant.telNo2 +' '+ detailData._merchant.telNo3)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>가맹점번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.merchantNo}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>은행전송일시</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailData.sendDate) + ' ' + chaiStringUtils.dateTimeFormat(detailData.sendTime)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인금액</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.cardApprovalAmount && detailData.cardApprovalAmount.toLocaleString()}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>세금</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.taxAmount && detailData.taxAmount.toLocaleString()}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>서비스수수료</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.serviceFee && detailData.serviceFee.toLocaleString()}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>매입여부</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.slipPurchaseYn === 'Y' ? '매입': ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인처리상태</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayProcessStatus(detailData.approvalProcessStatusCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>요청방식</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.approvalRequestMethodCode}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인기관</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.treatmentAgencyCode}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>할부기간</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.installmentMonth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>매출종류</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.codeName('salesKindDivisionCode', detailData.salesKindDivisionCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>매출금액</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.salesAmount && detailData.salesAmount.toLocaleString()}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>단말번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.approvalTerminalNo}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>고유번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.tradeUniqNo}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="▶ 입출금처리정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>입출금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.inoutDate && chaiStringUtils.dateFormatDefault(detailData.inoutDate)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>입출금처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(detailData.inoutProcessCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>입출금처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.inoutProcessSeq}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>입출금결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.paymentId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>계정계고객번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData._member && detailData._member.userId}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>계정계오류코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.adminErrorCode}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>계정계오류메시지</TableCell>
                  <TableCell align="left" className={classes.tdStyle} colSpan={3}>{detailData.adminErrorMessage}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>
        <br/>
        <div>
            <Chip label="▶ 미입출처리요청정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>미입출요청여부</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.nonInoutRequestYn}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>미입출요청일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.nonInoutRegisteredDate && chaiStringUtils.dateFormatDefault(detailData.nonInoutRegisteredDate)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>미입출일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.nonInoutSeq}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>        
        <br/>
        <div>
            <Chip label="▶ 환불처리정보" color="primary" size="small" className={classes.chipStyle} />
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>환불입금일자</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.refundDate && chaiStringUtils.dateFormatDefault(detailData.refundDate)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리코드</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(detailData.refundProcessCode)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불처리일련번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.refundProcessSeq}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>환불결제ID</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>{detailData.refundPaymentId}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleApprovalDetailPopClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalDetailPop;

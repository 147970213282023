import React, { FC, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { purchasedAggrHistoryMutation } from '../../lib/apolloClient/mutations';
import PurchasedAggrDetailListPop from './PurchasedAggrDetailListPop';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  table: {
    //minWidth: 2000
  },
}));

type Props = {
  dataList: PurchasedAggrHistoryList[];
};

const PurchasedAggrHistoryTable: FC<Props> = ({ dataList }) => {
  const classes = useStyles();

  const default_page = 0;
  const default_rowsPerPage = 100;
  const [rowsPerPage, setRowsPerPage] = useState(default_rowsPerPage);
  const [page, setPage] = useState(default_page);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [detailListPop, setDetailListPopOpen] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  useEffect(() => {
    setPage(default_page);
    setRowsPerPage(default_rowsPerPage);
  }, [dataList]);

  const handleDetailClick = (aggregationDate: String, accountDivisionCode: String, salesKindDivisionCode: String) => {
    fetch(aggregationDate, accountDivisionCode, salesKindDivisionCode);
    setDetailListPopOpen(true);
  };

  const fetch = async (aggregationDate: String, accountDivisionCode: String, salesKindDivisionCode: String) => {
    if (loading) {
      return;
    }

    setLoading(true);

    await apolloClient
      .mutate({
        variables: {
          aggregationDate: aggregationDate,
          accountDivisionCode: accountDivisionCode,
          salesKindDivisionCode: salesKindDivisionCode,
        },
        mutation: purchasedAggrHistoryMutation.selectPurchasedAggrDetailList,
      })
      .then(res => {
        setList(res.data.selectPurchasedAggrDetailList);
      })
      .catch(console.error);

    setLoading(false);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">집계일자</TableCell>
                <TableCell align="center">집계구분</TableCell>
                <TableCell align="center">카드계정</TableCell>
                <TableCell align="center">출금건수</TableCell>
                <TableCell align="center">출금총액</TableCell>
                <TableCell align="center">입금건수</TableCell>
                <TableCell align="center">입금총액</TableCell>
                <TableCell align="center">전체건수</TableCell>
                <TableCell align="center">전체총액</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((data, index) => (
                <TableRow hover key={index}>
                  <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.aggregationDate)}</TableCell>
                  <TableCell align="center">
                    {chaiStringUtils.codeName('aggregationDivisionCode', data.aggregationDivisionCode)}
                  </TableCell>
                  <TableCell align="center">
                    {chaiStringUtils.codeName('accountDivisionCode', data.accountDivisionCode)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="출금건수 상세목록">
                      <a
                        href="#!"
                        onClick={() => handleDetailClick(data.aggregationDate, data.accountDivisionCode, '05')}
                      >
                        {chaiStringUtils.moneyFormat(data.withdrawalCount)} 건
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.withdrawalTotalAmount)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="입금건수 상세목록">
                      <a
                        href="#!"
                        onClick={() => handleDetailClick(data.aggregationDate, data.accountDivisionCode, '15')}
                      >
                        {chaiStringUtils.moneyFormat(data.depositCount)} 건
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.depositTotalAmount)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="총건수 상세목록">
                      <a
                        href="#!"
                        onClick={() => handleDetailClick(data.aggregationDate, data.accountDivisionCode, '')}
                      >
                        {chaiStringUtils.moneyFormat(data.tradeProcessCount)} 건
                      </a>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.tradeTotalAmount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <PurchasedAggrDetailListPop
        detailListPop={detailListPop}
        setDetailListPopOpen={setDetailListPopOpen}
        loading={loading}
        list={list}
      />
    </Card>
  );
};

export default PurchasedAggrHistoryTable;

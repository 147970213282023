import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { MenuTitleToolbar } from '../../components';

const StyledTableCell = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

type Props = {
  detailPopOpen: boolean;
  setDetailPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailInfo: ForeignSndrvLog;
};

const ForeignSndrvLogDetailPop: FC<Props> = ({ detailPopOpen, setDetailPopOpen, detailInfo }) => {
  const handleClose = () => {
    setDetailPopOpen(false);
  };

  const sndrvDivisionCodeMap = new Map<String, String>();
  sndrvDivisionCodeMap.set('1', '수신');
  sndrvDivisionCodeMap.set('2', '송신');

  return (
    <Dialog
      open={detailPopOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        <MenuTitleToolbar title="대외송수신로그 상세" />
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>대외기관코드</StyledTableCell>
              <StyledTableCell>{detailInfo.foreignInstCode}</StyledTableCell>
              <StyledTableCell>전문관리번호</StyledTableCell>
              <StyledTableCell>{detailInfo.textManagementNo}</StyledTableCell>
              <StyledTableCell>거래일자</StyledTableCell>
              <StyledTableCell>{detailInfo.tradeDate}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>거래고유번호</StyledTableCell>
              <StyledTableCell>{detailInfo.tradeUniqNo}</StyledTableCell>
              <StyledTableCell>송수신구분코드</StyledTableCell>
              <StyledTableCell>{sndrvDivisionCodeMap.get(detailInfo.sndrvDivisionCode)}</StyledTableCell>
              <StyledTableCell>거래시분초</StyledTableCell>
              <StyledTableCell>{detailInfo.tradeTime}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>조작거래코드</StyledTableCell>
              <StyledTableCell>{detailInfo.operateTradeCode}</StyledTableCell>
              <StyledTableCell>응답코드</StyledTableCell>
              <StyledTableCell>{detailInfo.responseCode}</StyledTableCell>
              <StyledTableCell>카드번호</StyledTableCell>
              <StyledTableCell>{detailInfo.cardNoMasked}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>헤더정보</StyledTableCell>
              <StyledTableCell colSpan={5}>
                <TextareaAutosize
                  rowsMax={10}
                  aria-label="maximum height"
                  rows={5}
                  cols={130}
                  readOnly={true}
                  defaultValue={detailInfo.headerInfo}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>개별공통부정보</StyledTableCell>
              <StyledTableCell colSpan={5}>
                <TextareaAutosize
                  rowsMax={10}
                  aria-label="maximum height"
                  rows={5}
                  cols={130}
                  readOnly={true}
                  defaultValue={detailInfo.individualCommonInfo}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>메시지내용</StyledTableCell>
              <StyledTableCell colSpan={5}>
                <TextareaAutosize
                  rowsMax={10}
                  aria-label="maximum height"
                  rows={5}
                  cols={130}
                  readOnly={true}
                  defaultValue={detailInfo.messageDescription}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>최초조작직원번호</StyledTableCell>
              <StyledTableCell>{detailInfo.firstOperateAdminId}</StyledTableCell>
              <StyledTableCell>최초조작상세일시</StyledTableCell>
              <StyledTableCell>{detailInfo.firstOperateDetailDate}</StyledTableCell>
              <StyledTableCell>최초조작거래코드</StyledTableCell>
              <StyledTableCell>{detailInfo.firstOperateTradeCode}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForeignSndrvLogDetailPop;

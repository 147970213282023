import gql from 'graphql-tag';



export const selectBatchInfoList = gql`
  mutation selectBatchInfoList($input : BatchInfoSearch) {
    selectBatchInfoList (input : $input) {
      totalCount
      batchSeqNo
      batchId
      batchName
      precedenceBatchId
      precedenceBatchName
      executionCycle
      remarkDescription
      fileName
      processDate
      processStatus
    }
  }
`;

export const selectBatchInfoListByBatchName = gql`
  mutation selectBatchInfoListByBatchName($input : PrecedenceBatchInfoSearch) {
    selectBatchInfoListByBatchName (input : $input) {
      totalCount 
      list {
        batchSeqNo
        batchId
        batchName
        precedenceBatchId
        executionCycle
        fileName
      }
    }
  }
`;


export const insertBatchInfo = gql`
  mutation insertBatchInfo($input : BatchInfoInput) {
    insertBatchInfo(input : $input) {
      batchSeqNo
    }
  }
`;


export const updateBatchInfo = gql`
  mutation updateBatchInfo($input : BatchInfoInput) {
    updateBatchInfo(input : $input) {
      batchSeqNo
    }
  }
`;


export const selectBatchProcessHistoryList = gql`
  mutation selectBatchProcessHistoryList($input : BatchProcessHistorySearch) {
    selectBatchProcessHistoryList(input : $input) {
      totalCount 
      list {
        batchSeqNo
        processDate
        processSeqNo
        startDate
        endDate
        processStatus
        processCount
        targetDataCount
        errorMessage
      }
    }
  }
`;


export const updateProcessStatus = gql`
  mutation updateProcessStatus (
    $batchSeqNo : Float!,
  ) {
    updateProcessStatus (
      batchSeqNo : $batchSeqNo 
    ) {
      result
    }
  }
`;
import gql from 'graphql-tag';

export const getHanaMerchantMappingList = gql`
  query getHanaMerchantMappingList($mappingStatus: String, $filter: String, $page: Float, $rowsPerPage: Float) {
    getHanaMerchantMappingList(mappingStatus: $mappingStatus, filter: $filter, page: $page, rowsPerPage: $rowsPerPage) {
      list {
        id
        name
        mappingId
        groupIds
        businessCode
        businessNumber
        mappingStatus
        suggest {
          hanaMerchantId
          bcMerchantId
          bcMerchantName
          bcBusinessCode
          similarity
        }
      }
      totalCount
    }
  }
`;

export const getHanaMerchantMappingHistoryList = gql`
  query getHanaMerchantMappingHistoryList($page: Float, $rowsPerPage: Float) {
    getHanaMerchantMappingHistoryList(page: $page, rowsPerPage: $rowsPerPage) {
      list {
        id
        adminId
        updatedAt
        hanaMerchantId
        originalMappingId
        changedMappingId
        description
      }
      totalCount
    }
  }
`;

export const getHanaMerchantList = gql`
  query getHanaMerchantList(
    $merchantNo: String
    $merchantBizNo: String
    $page: Float
    $rowsPerPage: Float
    $isExcelDown: Boolean
  ) {
    getHanaMerchantList(
      merchantNo: $merchantNo
      merchantBizNo: $merchantBizNo
      page: $page
      rowsPerPage: $rowsPerPage
      isExcelDown: $isExcelDown
    ) {
      list {
        id
        name
        mappingId
        groupIds
        businessCode
        businessNumber
        mappingStatus
        suggest {
          hanaMerchantId
          bcMerchantId
          bcMerchantName
          bcBusinessCode
          similarity
        }
      }
      totalCount
    }
  }
`;

export const getHanaMerchantInfo = gql`
  query getHanaMerchantInfo($merchantNo: String) {
    getHanaMerchantInfo(merchantNo: $merchantNo) {
      id
      name
      mappingId
      groupIds
      businessCode
      businessNumber
      mappingStatus
    }
  }
`;

export const getHanaApprovalList = gql`
  query getHanaApprovalList($input: HanaApprovalSearch) {
    getHanaApprovalList(input: $input) {
      totalCount
      list {
        id
        paymentId
        cardSeq
        cardLastNumber
        cardUserId
        approvalNumber
        approvalDate
        merchantId
        currency
        installmentMonth
        amount
        amountKRW
        amountUSD
        brandFee
        exchangeRate
        isOverseasPurchase
        type
        createdAt
        updatedAt
        _merchant {
          id
          name
          businessNumber
          businessCode
        }
        _user {
          user_kor_name
        }
      }
    }
  }
`;

export const getHanaPurchasedList = gql`
  query getHanaPurchasedList($input: HanaPurchasedSearch) {
    getHanaPurchasedList(input: $input) {
      totalCount
      list {
        id
        paymentId
        approvalNumber
        purchaseDate
        approvalDate
        cardLastNumber
        merchantId
        amount
        feeKRW
        brandFee
        isOverseasPurchase
        type
        currency
        createdAt
        updatedAt
        _merchant {
          id
          name
          businessNumber
          businessCode
        }
        _user {
          user_kor_name
        }
      }
    }
  }
`;

export const getHanaAggrHistoryList = gql`
  query getHanaAggrHistoryList($startAggregationDate: String, $endAggregationDate: String, $isOverseas: String) {
    getHanaAggrHistoryList(
      startAggregationDate: $startAggregationDate
      endAggregationDate: $endAggregationDate
      isOverseas: $isOverseas
    ) {
      list {
        purchaseDate
        plusAmount
        minusAmount
        plusAmountUSD
        minusAmountUSD
        paymentCount
        cancelPaymentCount
        isOverseas
        fee
        feeTax
      }
      total {
        domestic {
          paymentCount
          plusAmount
          plusAmountUSD
          cancelPaymentCount
          minusAmount
          minusAmountUSD
          totalCount
          totalAmount
        }
        overseas {
          paymentCount
          plusAmount
          plusAmountUSD
          cancelPaymentCount
          minusAmount
          minusAmountUSD
          totalCount
          totalAmount
        }
      }
    }
  }
`;

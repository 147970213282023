import gql from 'graphql-tag';

export const selectOverseasPurchasedAggrHistoryList = gql`
  mutation selectOverseasPurchasedAggrHistoryList (
    $inquiryDate : String!,
    $page: Float, 
    $rowsPerPage: Float
  ) {
    selectOverseasPurchasedAggrHistoryList (
      inquiryDate :$inquiryDate,
      page : $page, 
      rowsPerPage : $rowsPerPage
    ) {
      totalCount
      list {        
        aggregation_date
        account_division_code
        card_type_code
        settlement_date
        settlement_usd_withdrawal_count
        settlement_usd_deposit_count
        settlement_usd_withdrawal_total_amount
        settlement_usd_deposit_total_amount
        settlement_usd_trade_total_amount
        settlement_krw_withdrawal_total_amount
        settlement_krw_deposit_total_amount
        settlement_krw_trade_total_amount
        settlement_usd_fee_withdrawal_total_amount
        settlement_usd_fee_deposit_total_amount
        settlement_usd_fee_trade_total_amount	
        settlement_krw_fee_withdrawal_total_amount
        settlement_krw_fee_deposit_total_amount
        settlement_krw_fee_trade_total_amount
      }
      opaiSum {
        settlement_usd_withdrawal_count
        settlement_usd_deposit_count
        settlement_usd_withdrawal_total_amount
        settlement_usd_deposit_total_amount
        settlement_usd_trade_total_amount
        settlement_krw_withdrawal_total_amount
        settlement_krw_deposit_total_amount
        settlement_krw_trade_total_amount
        settlement_usd_fee_withdrawal_total_amount
        settlement_usd_fee_deposit_total_amount
        settlement_usd_fee_trade_total_amount
        settlement_krw_fee_withdrawal_total_amount
        settlement_krw_fee_deposit_total_amount
        settlement_krw_fee_trade_total_amount
        brand_usd_fee_withdrawal_total_amount
        brand_usd_fee_deposit_total_amount
        brand_krw_fee_withdrawal_total_amount
        brand_krw_fee_deposit_total_amount
        overseas_use_usd_fee_withdrawal_total_amount
        overseas_use_usd_fee_deposit_total_amount
        overseas_use_krw_fee_withdrawal_total_amount
        overseas_use_krw_fee_deposit_total_amount
        overseas_withdrawal_usd_fee_withdrawal_total_amount
        overseas_withdrawal_usd_fee_deposit_total_amount
        overseas_withdrawal_krw_fee_withdrawal_total_amount
        overseas_withdrawal_krw_fee_deposit_total_amount
        exchange_rate_diff_profit_amount
        exchange_rate_diff_loss_amount
        exchange_rate_diff_total_amount
        deal_base_exchange_rate
      }
    }
  }
`;

export const selectOverseasPurchasedAggrDetailList = gql`
  mutation selectOverseasPurchasedAggrDetailList (
    $aggregation_date : String,
    $account_division_code : String,
    $substr_sales_kind_division_code : String
  ) {
    selectOverseasPurchasedAggrDetailList(
    aggregation_date : $aggregation_date,
    account_division_code : $account_division_code,
    substr_sales_kind_division_code : $substr_sales_kind_division_code
    ) {
      receve_base_date
      sales_date
      sales_kind_division_code
      card_no
      card_no_masked
      purchased_process_no
      merchant_eng_name
      mcc_merchant_code
      purchased_usd_amount
      purchased_krw_amount
      overseas_card_approval_no		
      account_process_seq
    }
  }
`;
import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
type SearchInfo = {
  searchGubun: string
  searchDate: string
  searchAccountNo: string
  searchAccountingProcessDivisionCode: string
}
type Props = {
  onSearch: React.Dispatch<React.SetStateAction<any>>,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}
const AccountProcAggrToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {
  
  // 변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  // 목록 조회
  const onSearchClick = async () => {
    onSearch(searchForm);
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="조회구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchGubun"
              value={searchForm.searchGubun}
              onChange={handleChange}
            >
              {/* 1:일자 2:계정  */}
              <option value="1">처리일자</option>
              <option value="2">계좌(계정)번호</option>
            </TextField>
          </Grid>
          {
            searchForm.searchGubun === '1'
            ? 
              <Grid
                item
                md={2}
                xs={6}
              >
                <TextField
                  required
                  fullWidth
                  type="date"
                  label="처리일자"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  name="searchDate"
                  value={searchForm.searchDate}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
            : 
              <Grid
                item
                md={2}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="계좌(계정) 번호"
                  placeholder="계좌(계정) 번호"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  name="searchAccountNo"
                  value={searchForm.searchAccountNo}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
          }
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="회계처리구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchAccountingProcessDivisionCode"
              value={searchForm.searchAccountingProcessDivisionCode}
              onChange={handleChange}
            >
              <option key="" value="">전체</option>
              <option key="1" value="1">계좌</option>
              <option key="2" value="2">계정</option>
            </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountProcAggrToolbar;
import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MenuTitleToolbar } from '../../components';
import apolloClient, { plccQuery } from '../../lib/apolloClient';
import { PlccMerchantMappingHistoryList } from './PlccMerchantMappingHistoryList';

const useStyles = makeStyles((theme: Theme) => ({
  root: { padding: theme.spacing(4) },
  content: { marginTop: theme.spacing(2) },
  detail: { marginBottom: theme.spacing(3) },
}));

export type PlccMerchantMappingSearchInfo = {
  searchType: string;
  searchValue: string;
};

export const PlccMerchantMappingHistory = () => {
  const classes = useStyles();
  const [data, setData] = useState<any>({ list: [], totalCount: 0 });
  const [loading, setLoading] = useState(false);

  const [paging, setPaging] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  // 리스트 쿼리
  const fetch = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    await apolloClient
      .query({
        variables: {
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
        },
        query: plccQuery.getHanaMerchantMappingHistoryList,
      })
      .then(res => {
        const result = res.data.getHanaMerchantMappingHistoryList;
        setData(result || { list: [], totalCount: 0 });
      })
      .catch(console.error);

    setLoading(false);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [paging]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="가맹점매핑 변경내역" />
      <div className={classes.content}>
        <PlccMerchantMappingHistoryList list={data} paging={paging} setPaging={setPaging} />
      </div>
    </div>
  );
};

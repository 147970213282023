import gql from 'graphql-tag';

export const selectApprovalList = gql`
  mutation selectApprovalList($input: ApprovalSearch) {
    selectApprovalList(input: $input) {
      totalCount
      list {
        tradeDate
        tradeApprovalSeq
        tradeUniqNo
        cardNo
        cardNoMasked
        salesKindDivisionCode
        approvalDate
        approvalTime
        cardApprovalNo
        cardMemberNo
        cardApprovalAmount
        merchantNo
        merchantName
        inoutProcessSeq
        paymentId
        paymentAcno
        approvalProcessStatusCode
        slipPurchaseYn
        accountProcessSeq
        adminErrorCode
        refundProcessCode
        refundPaymentId
        nonInoutRequestYn
        _member {
          userKorName
          userId
        }
        _merchant {
          merchantBizNo
        }
      }
    }
  }
`;

export const selectApproval = gql`
  mutation selectApproval($tradeDate: String!, $tradeApprovalSeq: Float!) {
    selectApproval(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      cardApprovalNo
      approvalDate
      approvalTime
      cardNo
      cardNoMasked
      ispApprovalDivisionCode
      merchantName
      merchantNo
      sendDate
      sendTime
      cardApprovalAmount
      pointPaymentYn
      taxAmount
      serviceFee
      approvalRequestMethodCode
      treatmentAgencyCode
      installmentMonth
      approvalTerminalNo
      tradeUniqNo
      salesKindDivisionCode
      salesAmount
      approvalProcessStatusCode
      slipPurchaseYn
      inoutDate
      inoutProcessCode
      inoutProcessSeq
      paymentId
      adminErrorCode
      adminErrorMessage
      refundDate
      refundProcessCode
      refundProcessSeq
      refundPaymentId
      nonInoutSeq
      nonInoutRegisteredDate
      nonInoutRequestYn
      _member {
        userKorName
        userId
      }
      _card {
        validateTermYm
      }
      _merchant {
        telNo1
        telNo2
        telNo3
      }
    }
  }
`;

export const selectApprovalGroupMerchant = gql`
  mutation selectApprovalGroupMerchant($merchantNo: String!) {
    selectApprovalGroupMerchant(merchantNo: $merchantNo) {
      groupMerchantNo
    }
  }
`;

export const selectApprovalOrigin = gql`
  mutation selectApprovalOrigin($cardNo: String!, $approvalDate: String!, $cardApprovalNo: String!) {
    selectApprovalOrigin(cardNo: $cardNo, approvalDate: $approvalDate, cardApprovalNo: $cardApprovalNo) {
      tradeDate
      tradeApprovalSeq
      inoutProcessSeq
      paymentId
      approvalProcessStatusCode
    }
  }
`;

export const selectApprovalCncl = gql`
  mutation selectApprovalCncl($cardNo: String!, $approvalDate: String!, $cardApprovalNo: String!) {
    selectApprovalCncl(cardNo: $cardNo, approvalDate: $approvalDate, cardApprovalNo: $cardApprovalNo) {
      tradeDate
      tradeApprovalSeq
    }
  }
`;

export const updateApprovalStatus = gql`
  mutation updateApprovalStatus($tradeDate: String!, $tradeApprovalSeq: Float!) {
    updateApprovalStatus(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      result
    }
  }
`;

export const updateApprovalRefundInfo = gql`
  mutation updateApprovalRefundInfo($tradeDate: String!, $tradeApprovalSeq: Float!) {
    updateApprovalRefundInfo(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq) {
      result
    }
  }
`;

export const updateApprovalPaymentId = gql`
  mutation updateApprovalPaymentId($tradeDate: String!, $tradeApprovalSeq: Float!, $paymentId: String!) {
    updateApprovalPaymentId(tradeDate: $tradeDate, tradeApprovalSeq: $tradeApprovalSeq, paymentId: $paymentId) {
      result
    }
  }
`;

import gql from 'graphql-tag';

// 환율정보 목록 조회
export const selectExchangeRate = gql`
    mutation selectExchangeRateList(
        $startDate: String,
        $endDate: String
    ) {
        selectExchangeRateList(
            startDate: $startDate,
            endDate: $endDate
        ){
            bcExchangeRateBaseDate
            bcExchangeRateCurrencyCode
            bcExchangeRateDivisionCode
            bcBaseExchangeRate
        }
    }
`;
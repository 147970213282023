import React, { useState, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { overseasCsRefundMutation } from '../../lib/apolloClient/mutations';
import { MenuTitleToolbar } from '../../components';

import OverseasCsRefundToolbar from './OverseasCsRefundToolbar';
import OverseasCsRefundPlenumTable from './OverseasCsRefundPlenumTable';
import OverseasCsRefundWholeTable from './OverseasCsRefundWholeTable';
import OverseasCsRefundSelectTable from './OverseasCsRefundSelectTable';
import OverseasCsRefundProcess from './OverseasCsRefundProcess';

import { format, subMonths } from 'date-fns';
import { useToast } from '../../contexts/Toast';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  registerButton: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    float: 'right',
  },
}));

type Props = {};

const OverseasCsRefund: FC<Props> = () => {
  const classes = useStyles();
  const { showToast } = useToast();
  const [data, setData] = useState([] as overseasCsRefundPlenumList[]);
  const [data2, setData2] = useState([] as overseasCsRefundWholeList[]);
  const [data3, setData3] = useState([] as overseasCsRefundSelectList[]);
  const [overseasCsRefundSelectPopList, setOverseasCsRefundSelectPopList] = useState(
    [] as overseasCsRefundSelectPopList[]
  );
  const [allCheckYn, setAllCheckYn] = useState(false);
  const [allCheckYn2, setAllCheckYn2] = useState(false);

  let [refundProcessResult, setRefundProcessResult] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    trmDate: format(new Date(), 'yyyy-MM-dd'), // 전금일자
    overseasCsReceveDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'), // 민원접수 시작일자
    overseasCsEndDate: format(new Date(), 'yyyy-MM-dd'), // 민원접수 종료일자
    cardNo: '', // 카드번호
    userKorName: '', // 회원명
    cardSeq: '', // 카드일렬번호
    rewardProgressStatusCode: '', // 보상진행상태코드
    purchasedProcessNo: '', // 매입접수번호
  });

  //  해외민원 전금내역 목록 조회 & 민원보상대상 전체내역 목록 조회
  const onSearch = async (searchForm: SearchInfo) => {
    // 해외민원 전금내역 목록 조회
    await apolloClient
      .mutate({
        variables: {
          trmDate: searchForm.trmDate,
          overseasCsReceveDate: searchForm.overseasCsReceveDate,
          overseasCsEndDate: searchForm.overseasCsEndDate,
          cardNo: searchForm.cardNo,
          userKorName: searchForm.userKorName,
          cardSeq: searchForm.cardSeq,
          rewardProgressStatusCode: searchForm.rewardProgressStatusCode,
          purchasedProcessNo: searchForm.purchasedProcessNo,
        },
        mutation: overseasCsRefundMutation.overseasCsRefundPlenum,
      })
      .then(res => {
        setData(res.data.overseasCsRefundPlenumList);
      })
      .catch(console.error);

    // 민원보상대상 전체내역 목록 조회
    await apolloClient
      .mutate({
        variables: {
          trmDate: searchForm.trmDate,
          overseasCsReceveDate: searchForm.overseasCsReceveDate,
          overseasCsEndDate: searchForm.overseasCsEndDate,
          cardNo: searchForm.cardNo,
          userKorName: searchForm.userKorName,
          cardSeq: searchForm.cardSeq,
          rewardProgressStatusCode: searchForm.rewardProgressStatusCode,
          purchasedProcessNo: searchForm.purchasedProcessNo,
        },
        mutation: overseasCsRefundMutation.overseasCsRefundWhole,
      })
      .then(res => {
        setData2(res.data.overseasCsRefundWholeList);
      })
      .catch(console.error);

    // 민원선정대상 목록 조회
    await apolloClient
      .mutate({
        variables: {
          trmDate: searchForm.trmDate,
          overseasCsReceveDate: searchForm.overseasCsReceveDate,
          overseasCsEndDate: searchForm.overseasCsEndDate,
          cardNo: searchForm.cardNo,
          userKorName: searchForm.userKorName,
          cardSeq: searchForm.cardSeq,
          rewardProgressStatusCode: searchForm.rewardProgressStatusCode,
          purchasedProcessNo: searchForm.purchasedProcessNo,
        },
        mutation: overseasCsRefundMutation.overseasCsRefundSelect,
      })
      .then(res => {
        setData3(res.data.overseasCsRefundSelectList);
      })
      .catch(console.error);
  };

  // 민원선정대상 선정 업데이트
  const onClickSelect = async () => {
    let checkedItem = [];

    for (let i = 0; i < data2.length; i++) {
      if (data2[i].checked === true) {
        if (data2[i].rewardProgressStatusCode === '3' || data2[i].rewardProgressStatusCode === '9') {
          showToast('최종확정/수기종결(완료)된 대상입니다.', 'error');
          return;
        }
      }

      if (data2[i].checked === true) {
        checkedItem.push({
          overseasCsRewardSeq: `${data2[i].overseasCsRewardSeq}`,
          overseasCsRewardProcessDate: `${data2[i].overseasCsRewardProcessDate}`,
          overseasCsTrmSeq: parseFloat(data[0].overseasCsTrmSeq),
          trmUsdAmount: `${data2[i].trmUsdAmount}`,
          trmApplyExchangeRate: `${data2[i].trmApplyExchangeRate}`,
          rewardSalesKrwAmount: `${data2[i].rewardSalesKrwAmount}`,
          trmDate: data[0].trmDate,
          trmStatusCode: `${data2[i].trmStatusCode}`,
          rewardProgressStatusCode: `${data2[i].rewardProgressStatusCode}`,
          trmUsdProcessAmount: parseFloat(data2[i].trmUsdProcessAmount),
        });
      }
    }

    if (checkedItem.length === 0) {
      showToast('선정할 대상이 선택되지 않았습니다.', 'error');
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          checkedItemSearch: checkedItem,
          overseasCsReceveDate: searchForm.overseasCsReceveDate, // 민원접수시작일자
          overseasCsEndDate: searchForm.overseasCsEndDate, // 민원접수종료일자
        },
        mutation: overseasCsRefundMutation.overseasCsRefundUpdate,
      })
      .then(res => {
        if (res.data.overseasCsRefundUpdateYN.success === 'Y') {
          showToast('선정대사가 변경되었습니다.');
          // 목록 리로드
          onSearch(searchForm);
        } else {
          showToast(res.data.overseasCsRefundUpdateYN.message, 'error');
        }
      })
      .catch(console.error);
  };

  // 민원선정대사 최종확정 처리
  const onClickDecide = async () => {
    let decideCheckedItem = [];
    for (let i = 0; i < data3.length; i++) {
      if (data3[i].checked2 === true) {
        decideCheckedItem.push({
          overseasCsRewardSeq: parseFloat(data3[i].overseasCsRewardSeq),
          overseasCsRewardProcessDate: `${data3[i].overseasCsRewardProcessDate}`,
          overseasCsTrmSeq: parseFloat(data3[i].overseasCsTrmSeq),
          trmDate: `${data3[i].trmDate}`,
        });
      }
    }

    if (decideCheckedItem.length === 0) {
      showToast('최종확정할 대상이 선택되지 않았습니다.', 'error');
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          decideCheckedItemV: decideCheckedItem,
        },
        mutation: overseasCsRefundMutation.overseasCsDecide,
      })
      .then(res => {
        if (res.data.overseasCsDecideYN.success === 'Y') {
          showToast('최종확정되었습니다.');
          // 목록 리로드
          onSearch(searchForm);
        } else {
          showToast(res.data.overseasCsDecideYN.message, 'error');
        }
      })
      .catch(console.error);
  };

  // 수기종결 처리
  let success = true;
  const onClickHand = async () => {
    // 환급처리 됐을경우 수기종결 할 수 없다
    if (refundProcessResult === true) {
      showToast('수기종결 불가(환급처리 완료)', 'error');
      return;
    }

    if (data3.length === 0) {
      showToast('수기종결 대상이 없습니다.', 'error');
      return;
    } else if (data3.length > 0) {
      let decideCheckedItem = [];
      for (let i = 0; i < data3.length; i++) {
        // 체크박스 선택시에는 수기종결 처리불가 (전체 일괄처리하기 때문)
        if (data3[i].checked2 === true) {
          showToast('체크박스 해제해주세요', 'error');
          return;
        }

        // 환급처리된
        if (data3[i].rewardProgressStatusCode === '9') {
          showToast('수기종결 불가(환급처리 완료).', 'error');
          return;
        }

        decideCheckedItem.push({
          overseasCsRewardSeq: parseFloat(data3[i].overseasCsRewardSeq),
          overseasCsRewardProcessDate: `${data3[i].overseasCsRewardProcessDate}`,
          overseasCsTrmSeq: parseFloat(data3[i].overseasCsTrmSeq),
          trmDate: `${data3[i].trmDate}`,
        });
      }

      await apolloClient
        .mutate({
          variables: {
            decideCheckedItemV: decideCheckedItem,
          },
          mutation: overseasCsRefundMutation.overseasCsWrite,
        })
        .then(res => {
          if (res.data.overseasCsWriteYN.success === 'Y') {
            showToast('수기종결 되었습니다.');
            // 목록 리로드
            onSearch(searchForm);
          } else {
            showToast(res.data.overseasCsWriteYN.message, 'error');
          }
        })
        .catch(e => {
          console.log(e);
          success = false;
          showToast(e.message, 'error');
        });

      // 수기종결 처리중 오류 발생 시 return
      if (success === false) {
        showToast('수기종결 처리중 오류', 'error');
        return;
      }

      // 수기종결 완료시 전금내역 update
      await apolloClient
        .mutate({
          variables: {
            input: {
              overseasCsTrmSeq: data3[0].overseasCsTrmSeq,
              trmDate: data3[0].trmDate,
            },
          },
          mutation: overseasCsRefundMutation.handleCloseTrmUpdate,
        })
        .then(res => {
          if (res.data.handleCloseTrmUpdateYN.success === 'Y') {
            showToast('수기종결 전금내역 업데이트 되었습니다.');
            // 목록 리로드
            onSearch(searchForm);
          } else {
            showToast(res.data.handleCloseTrmUpdateYN.message, 'error');
          }
        })
        .catch(e => {
          console.log(e);
          showToast(e.message, 'error');
        });
    }
  };

  // 환급처리 팝업오픈
  const [uploadPop, setUploadPop] = useState(false);
  const handleUploadPop = async () => {
    setUploadPop(true);

    if (data3.length !== 0) {
      // 최종확정된 list => 환급처리POP
      await apolloClient
        .mutate({
          variables: {
            trmDate: searchForm.trmDate,
            overseasCsReceveDate: searchForm.overseasCsReceveDate,
            overseasCsEndDate: searchForm.overseasCsEndDate,
            cardNo: searchForm.cardNo,
            userKorName: searchForm.userKorName,
            cardSeq: searchForm.cardSeq,
            rewardProgressStatusCode: searchForm.rewardProgressStatusCode,
            purchasedProcessNo: searchForm.purchasedProcessNo,
          },
          mutation: overseasCsRefundMutation.overseasCsRefundSelectPop,
        })
        .then(res => {
          setOverseasCsRefundSelectPopList(res.data.overseasCsRefundSelectPopList);
        })
        .catch(console.error);
    }
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외민원 환불내역" />
      <OverseasCsRefundToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />

      <div className={classes.content}>
        <OverseasCsRefundPlenumTable title="해외민원 전금내역" overseasCsRefundPlenumList={data} />
      </div>
      <div className={classes.content}>
        <OverseasCsRefundWholeTable
          title="민원보상대상 전체내역"
          overseasCsRefundWholeList={data2}
          setOverseasCsRefundWholeList={setData2}
          allCheckYn={allCheckYn}
          setAllCheckYn={setAllCheckYn}
        />
      </div>
      <div className={classes.content}>
        <OverseasCsRefundSelectTable
          title="민원선정대상"
          overseasCsRefundSelectList={data3}
          setOverseasCsRefundSelectList={setData3}
          allCheckYn2={allCheckYn2}
          setAllCheckYn2={setAllCheckYn2}
        />
      </div>

      <Button
        className={classes.registerButton}
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
        onClick={onClickHand}
        disabled={false}
      >
        수기종결
      </Button>

      <OverseasCsRefundProcess
        uploadPop={uploadPop}
        setUploadPop={setUploadPop}
        overseasCsRefundSelectPopList={overseasCsRefundSelectPopList}
        overseasCsRefundPlenumList={data}
        overseasCsRefundSelectList={data3}
        fetch={onSearch}
        searchForm={searchForm}
        setRefundProcessResult={setRefundProcessResult}
      />
      <Button className={classes.registerButton} color="primary" variant="contained" onClick={handleUploadPop}>
        환급처리
      </Button>

      <Button
        className={classes.registerButton}
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
        onClick={onClickDecide}
      >
        최종확정
      </Button>

      <Button
        className={classes.registerButton}
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
        onClick={onClickSelect}
        disabled={false}
      >
        선정
      </Button>
    </div>
  );
};

export default OverseasCsRefund;

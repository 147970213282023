import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Checkbox,
  TextField,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { nonInoutInfoMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';

type NonInoutTable = {
  tradeDate: string; // 거래일자
  aggregationDivisionCode: string; // 집계구분코드
  cardProductCode: string; // 카드상품코드
  cardProductName: string; // 카드상품명
  approvalCount: number; // 승인건수
  approvalAmount: number; // 승인금액
  cancelCount: number; // 취소건수
  cancelAmount: number; // 취소금액
  inabledCount: number; // 불능건수
  inabledAmount: number; // 불능금액
  bcRejectCount: number; // BC선거절건수
  bcRejectAmount: number; // BC선거절금액
};

type Props = {
  nonInoutInfo: NonInoutInfoReturn[];
  onClickRow: (nonInoutSeqInput: number) => Promise<void>;
  setNonInoutInfo: React.Dispatch<React.SetStateAction<NonInoutInfoReturn[]>>;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  updateClick: boolean;
  allCheckYn: boolean;
  setAllCheckYn: React.Dispatch<React.SetStateAction<boolean>>;
};

// type Props = { list: ApprovalAggrInfo[] }
const NonInoutInfoTable: FC<Props> = ({
  nonInoutInfo,
  onClickRow,
  setNonInoutInfo,
  setUpdateClick,
  updateClick,
  allCheckYn,
  setAllCheckYn,
}) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [updateDetails, setUpdateDetails] = useState({
    nonInoutStatusCode: '',
  });

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  if (nonInoutInfo === null || nonInoutInfo === undefined || nonInoutInfo.length === 0) {
    return (
      <Card style={{ backgroundColor: '#F4F6F8' }}>
        <CardContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '0px solid white' }}>조회된 내용이 없습니다.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  const onChangeAllCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    for (
      let i = 0;
      (page + 1) * rowsPerPage > nonInoutInfo.length ? i < nonInoutInfo.length : i < (page + 1) * rowsPerPage;
      i++
    ) {
      try {
        // console.log(`nonInoutInfo[${i-1}].checked11: ${nonInoutInfo[i-1].checked}`);
        // setNonInoutInfo([...nonInoutInfo.slice(0, i), {
        //   nonInoutSeq: nonInoutInfo[i].nonInoutSeq,
        //   nonInoutRegisteredDate: nonInoutInfo[i].nonInoutRegisteredDate,
        //   receveBaseDate: nonInoutInfo[i].receveBaseDate,
        //   inoutDivisionCode: nonInoutInfo[i].inoutDivisionCode,
        //   cardNo: nonInoutInfo[i].cardNo,
        //   userId: nonInoutInfo[i].userId,
        //   accountDivisionCode: nonInoutInfo[i].accountDivisionCode,
        //   debitTargetNo: nonInoutInfo[i].debitTargetNo,
        //   creditTargetNo: nonInoutInfo[i].creditTargetNo,
        //   summaryType: nonInoutInfo[i].summaryType,
        //   nonInoutStatusCode: nonInoutInfo[i].nonInoutStatusCode,
        //   nonInoutOriginAmount: nonInoutInfo[i].nonInoutOriginAmount,
        //   lastProcessDate: nonInoutInfo[i].lastProcessDate,
        //   inoutProcessSeq: nonInoutInfo[i].inoutProcessSeq,
        //   firstOperateDetailDate: nonInoutInfo[i].firstOperateDetailDate,
        //   firstOperateTradeCode: nonInoutInfo[i].firstOperateTradeCode,
        //   lastOperateAdminId: nonInoutInfo[i].lastOperateAdminId,
        //   lastOperateDetailDate: nonInoutInfo[i].lastOperateDetailDate,
        //   lastOperateTradeCode: nonInoutInfo[i].lastOperateTradeCode,
        //   receveDate: nonInoutInfo[i].receveDate,
        //   checked: true
        // }, ...nonInoutInfo.slice(i + 1, (nonInoutInfo.length - (i + 1)))]);

        const data2 = nonInoutInfo;

        await data2.forEach(item => {
          item.checked = event.target.checked;
        });
        await setAllCheckYn(!allCheckYn);
        await setNonInoutInfo([]);
        await setNonInoutInfo(data2);
      } catch {
        console.error();
      }
    }
  };

  const onChangeCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // let index = 0;
    // for(let i = 0; i < nonInoutInfo.length; i++) {
    //   if (nonInoutInfo[i].nonInoutSeq === parseInt(event.target.value)) {
    //     index = i;
    //     break;
    //   }
    // }
    // try{
    //   setNonInoutInfo([...nonInoutInfo.slice(0, index), {
    //     nonInoutSeq: nonInoutInfo[index].nonInoutSeq,
    //     nonInoutRegisteredDate: nonInoutInfo[index].nonInoutRegisteredDate,
    //     receveBaseDate: nonInoutInfo[index].receveBaseDate,
    //     inoutDivisionCode: nonInoutInfo[index].inoutDivisionCode,
    //     cardNo: nonInoutInfo[index].cardNo,
    //     userId: nonInoutInfo[index].userId,
    //     accountDivisionCode: nonInoutInfo[index].accountDivisionCode,
    //     debitTargetNo: nonInoutInfo[index].debitTargetNo,
    //     creditTargetNo: nonInoutInfo[index].creditTargetNo,
    //     summaryType: nonInoutInfo[index].summaryType,
    //     nonInoutStatusCode: nonInoutInfo[index].nonInoutStatusCode,
    //     nonInoutOriginAmount: nonInoutInfo[index].nonInoutOriginAmount,
    //     lastProcessDate: nonInoutInfo[index].lastProcessDate,
    //     inoutProcessSeq: nonInoutInfo[index].inoutProcessSeq,
    //     firstOperateDetailDate: nonInoutInfo[index].firstOperateDetailDate,
    //     firstOperateTradeCode: nonInoutInfo[index].firstOperateTradeCode,
    //     lastOperateAdminId: nonInoutInfo[index].lastOperateAdminId,
    //     lastOperateDetailDate: nonInoutInfo[index].lastOperateDetailDate,
    //     lastOperateTradeCode: nonInoutInfo[index].lastOperateTradeCode,
    //     receveDate: nonInoutInfo[index].receveDate,
    //     checked: event.target.checked
    //   }, ...nonInoutInfo.slice(index + 1, nonInoutInfo.length)]);
    // } catch(e) { console.log(e); }
    try {
      const data2 = nonInoutInfo;

      await data2
        .filter(item => {
          if (item.nonInoutSeq === parseInt(event.target.value)) {
            return item;
          }
          return true;
        })
        .forEach(item => {
          item.checked = event.target.checked;
        });

      await setAllCheckYn(allCheckYn);
      await setNonInoutInfo([]);
      await setNonInoutInfo(data2);
    } catch {
      console.error();
    }
  };

  const onClickUpdate = async () => {
    let checkedItem = [];
    for (let i = 0; i < nonInoutInfo.length; i++) {
      if (nonInoutInfo[i].checked === true) {
        checkedItem.push(nonInoutInfo[i].nonInoutSeq);
      }
    }
    if (checkedItem.length === 0) {
      showToast('미입출금내역이 선택되지 않았습니다.', 'error');
      return;
    }
    await apolloClient
      .mutate({
        variables: {
          input: {
            nonInoutStatusCode: updateDetails.nonInoutStatusCode,
            nonInoutSeqArr: checkedItem,
          },
        },
        mutation: nonInoutInfoMutation.updateNonInoutInfo,
      })
      .then(res => {
        console.log(res.data.updateNonInoutInfo);
      });
    setUpdateClick(!updateClick);
    showToast('미입출금상태가 변경되었습니다.');

    for (
      let i = 0;
      (page + 1) * rowsPerPage > nonInoutInfo.length ? i < nonInoutInfo.length : i < (page + 1) * rowsPerPage;
      i++
    ) {
      try {
        const data2 = nonInoutInfo;

        await data2.forEach(item => {
          item.checked = false;
        });
        await setAllCheckYn(allCheckYn);
        await setNonInoutInfo([]);
        await setNonInoutInfo(data2);
      } catch {
        console.error();
      }
    }
  };

  const map = new Map<String, String>();
  map.set('1', '전액미입출금');
  map.set('2', '부분미입출금');
  map.set('3', '전액입출금');
  map.set('4', '잡손실');
  map.set('5', '잡수익');
  map.set('6', '중단');

  const map2 = new Map<String, String>();
  map2.set('1', '입금');
  map2.set('2', '출금');

  return (
    <div>
      <Card className={classes.content}>
        <CardContent>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">NO.</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        onChange={event => {
                          onChangeAllCheck(event);
                        }}
                        checked={allCheckYn}
                      />
                    </TableCell>
                    <TableCell align="center">미입출금등록일자</TableCell>
                    <TableCell align="center">미입출금일련번호</TableCell>
                    <TableCell align="center">수신기준일자</TableCell>
                    <TableCell align="center">카드번호</TableCell>
                    <TableCell align="center">수신일자</TableCell>
                    <TableCell align="center">입출구분</TableCell>
                    <TableCell align="center">미입출원금금액</TableCell>
                    <TableCell align="center">최초조작상세일시</TableCell>
                    <TableCell align="center">미입출금상태코드</TableCell>
                    <TableCell align="center">대변대상번호</TableCell>
                    <TableCell align="center">차변대상번호</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nonInoutInfo.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item, idx) => (
                    <TableRow hover key={item.nonInoutSeq}>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {page * rowsPerPage + idx + 1}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          onChange={event => {
                            onChangeCheck(event);
                          }}
                          value={item.nonInoutSeq}
                          checked={item.checked}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {`${item.nonInoutRegisteredDate.substr(0, 4)}년 ${item.nonInoutRegisteredDate.substr(
                            4,
                            2
                          )}월 ${item.nonInoutRegisteredDate.substr(6, 2)}일`}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {item.nonInoutSeq}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {`${item.receveBaseDate.substr(0, 4)}년 ${item.receveBaseDate.substr(
                            4,
                            2
                          )}월 ${item.receveBaseDate.substr(6, 2)}일`}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {chaiStringUtils.cardNoMaskFormat(item.cardNo)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {item.receveDate}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {map2.get(item.inoutDivisionCode)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {item.nonInoutOriginAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {`${item.lastOperateDetailDate.substr(0, 4)}년 ${item.lastOperateDetailDate.substr(
                            4,
                            2
                          )}월 ${item.lastOperateDetailDate.substr(6, 2)}일 ${item.lastOperateDetailDate.substr(
                            8,
                            2
                          )}:${item.lastOperateDetailDate.substr(10, 2)}:${item.lastOperateDetailDate.substr(12, 2)}`}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {map.get(item.nonInoutStatusCode)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {item.creditTargetNo}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            onClickRow(item.nonInoutSeq);
                          }}
                          style={{ color: 'navy' }}
                        >
                          {item.debitTargetNo}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination // pagenation 조작을 위한 함수 지정
            style={{ marginLeft: 'auto' }}
            component="div"
            count={nonInoutInfo.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </CardActions>
      </Card>
      <br />

      <Card>
        <CardContent className={classes.content} style={{ paddingBottom: '0' }}>
          <PerfectScrollbar>
            <Table>
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F6F8', width: '15%' }}>중단여부</TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <TextField
                      select
                      fullWidth
                      label="중단구분"
                      margin="dense"
                      value={updateDetails.nonInoutStatusCode}
                      onChange={event => {
                        setUpdateDetails({
                          nonInoutStatusCode: event.target.value,
                        });
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="nonInoutStatusCode"
                    >
                      <option value="">구분</option>
                      <option value="1">전액미입출금</option>
                      <option value="2">부분미입출금</option>
                      <option value="3">전액입출금</option>
                      <option value="4">잡손실</option>
                      <option value="5">잡수익</option>
                      <option value="6">중단</option>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClickUpdate}
                      disabled={updateDetails.nonInoutStatusCode === ''}
                      style={{ marginRight: 10 }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default NonInoutInfoTable;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { MenuTitleToolbar } from '../../components';
import ExchangeRateToolbar from './ExchangeRateToolbar';
import ExchangeRateTable from './ExchangeRateTable';

import { exchangeRateMutation } from '../../lib/apolloClient/mutations';
import apolloClient from '../../lib/apolloClient';

import {format, subMonths} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    registerButton: {
      margin: theme.spacing(1), 
      marginTop: theme.spacing(3),
      float: "right"
    },
    hyperLinkButton: {
      textDecoration: 'none',
      color: 'white'
    }
}));

type SearchInfo = {
  startDate: string,     // 거래시작일자
  endDate: string        // 거래종료일자
}

type Props ={
  
};

const ExchangeRate: FC<Props> = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    // 검색조건
    const [searchForm, setSearchForm] = useState({
      startDate: format(subMonths(new Date(),1), 'yyyy-MM-dd'),   // 거래시작일자
      endDate: format(new Date(), 'yyyy-MM-dd')                   // 거래종료일자      
    });

    // 초기 목록 
    useEffect(() => {
      onSearch(searchForm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 환율정보 조회
    const onSearch = async (searchForm: SearchInfo) => {
      console.log('환율정보 목록 조회 시작: ', searchForm);

      // 초기화
      setData([]);

      await apolloClient.mutate({
        variables:{
          startDate: searchForm.startDate,      // 거래시작일자 
          endDate: searchForm.endDate           // 거래종료일자
        },
        mutation: exchangeRateMutation.selectExchangeRate

      }).then(res=> {
        console.log('환율정보 목록 조회 결과: ', res.data.selectExchangeRateList);
        setData(res.data.selectExchangeRateList);
      }).catch(console.error);

    }

    return(
        <div className={classes.root}>
            <MenuTitleToolbar title="환율정보"/>
            <ExchangeRateToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />
            
            <div className={classes.content}>
              <ExchangeRateTable  title="환율정보 목록" exchangeRateList={data} /> 
            </div>
       
        </div>
    )
}

export default ExchangeRate;
import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer',
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  thSumStyle: {
    backgroundColor: '#eeeeee',
  },
}));

type Props = {
  title: string;
  overseasCsRefundSelectList: overseasCsRefundSelectList[];
  setOverseasCsRefundSelectList: React.Dispatch<React.SetStateAction<overseasCsRefundSelectList[]>>;
  allCheckYn2: boolean;
  setAllCheckYn2: React.Dispatch<React.SetStateAction<boolean>>;
};

const OverseasCsRefundSelectTable: FC<Props> = ({
  title,
  overseasCsRefundSelectList,
  setOverseasCsRefundSelectList,
  allCheckYn2,
  setAllCheckYn2,
}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const onChangeAllCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const selectCheck = overseasCsRefundSelectList;
      await selectCheck.map(item => {
        item.checked2 = event.target.checked;
        return item;
      });
      await setAllCheckYn2(!allCheckYn2);
      await setOverseasCsRefundSelectList([]);
      await setOverseasCsRefundSelectList(selectCheck);
    } catch {
      console.error();
    }
  };

  const onChangeCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const selectCheck = overseasCsRefundSelectList;

      console.log('overseasCsRefundSelect: ', overseasCsRefundSelectList);
      await selectCheck
        .filter(overseasCsRefundSelect => {
          if (
            overseasCsRefundSelect.overseasCsRewardSeq + '/' + overseasCsRefundSelect.overseasCsRewardProcessDate ===
            event.target.value
          ) {
            return overseasCsRefundSelect;
          }
          return true;
        })
        .map(overseasCsRefundWhole => {
          overseasCsRefundWhole.checked2 = event.target.checked;
          return overseasCsRefundWhole;
        });

      await setAllCheckYn2(allCheckYn2);
      await setOverseasCsRefundSelectList([]);
      await setOverseasCsRefundSelectList(selectCheck);
    } catch {
      console.error();
    }
  };

  return (
    <Card>
      {!!title && <CardHeader title={title} />}
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2}>
                  <Checkbox
                    onChange={event => {
                      onChangeAllCheck(event);
                    }}
                    checked={allCheckYn2}
                  />
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  NO
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  보상기본정보
                </TableCell>
                <TableCell align="center" colSpan={7}>
                  실보상정보
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  민원정보
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">보상진행상태</TableCell>
                <TableCell align="center">처리일자</TableCell>
                <TableCell align="center">확정일자</TableCell>
                <TableCell align="center">적용환율</TableCell>
                <TableCell align="center">전금미화처리금액</TableCell>
                <TableCell align="center">매출원화금액</TableCell>
                <TableCell align="center">원화수익수수료</TableCell>
                <TableCell align="center">해외서비스원화수수료</TableCell>
                <TableCell align="center">보상현금수수료원화</TableCell>
                <TableCell align="center">환율차이손익구분</TableCell>
                <TableCell align="center">환율차이금액</TableCell>
                <TableCell align="center">민원보상원화총액</TableCell>
                <TableCell align="center">접수일자</TableCell>
                <TableCell align="center">...</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overseasCsRefundSelectList
                .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                .map((overseasCsRefundSelect, index) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Checkbox
                        onChange={event => {
                          onChangeCheck(event);
                        }}
                        value={
                          overseasCsRefundSelect.overseasCsRewardSeq +
                            '/' +
                            overseasCsRefundSelect.overseasCsRewardProcessDate || ''
                        }
                        checked={overseasCsRefundSelect.checked2 ? overseasCsRefundSelect.checked2 : false}
                      />
                    </TableCell>
                    <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{overseasCsRefundSelect.rewardProgressStatusCode}</TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelect.overseasCsRewardProcessDate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelect.csTargetConfirmDate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.applyExchangeRate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.trmUsdProcessAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.rewardSalesKrwAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.rewardKrwProfitFee)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.rewardOverseasUseKrwFee)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.rewardCashFeeKrwAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.exchangeRatePflsDivisionCode)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.exchangeRateDiffAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelect.csRewardKrwTotalAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelect.overseasCsReceveDate)}
                    </TableCell>
                    <TableCell align="center">{}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={overseasCsRefundSelectList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default OverseasCsRefundSelectTable;

import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
    Card,
    CardHeader,
    Divider,
    CardActions,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination
  } from '@material-ui/core';

  

  const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    },
    thStyle: {
      backgroundColor: '#fafafa',
    },
    thSumStyle: {
      backgroundColor: '#eeeeee',
    }, 
  }));

  type Props = {
    title: string ,
    overseasCsRefundPlenumList: overseasCsRefundPlenumList[] 
  }

  const OverseasCsRefundPlenumTable: FC<Props> = ({ 
    title,
    overseasCsRefundPlenumList
  }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };
  
    return (
        <Card>
          { !!title &&
            <CardHeader title={title}/>
          }
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">NO</TableCell>
                      <TableCell align="center">전금일자</TableCell>
                      <TableCell align="center">보상대사건수</TableCell>
                      <TableCell align="center">전금상태코드</TableCell>
                      <TableCell align="center">전금미화금액</TableCell>
                      <TableCell align="center">전금원화금액</TableCell>
                      <TableCell align="center">전금적용환율</TableCell>
                      <TableCell align="center">전금미화처리금액</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {overseasCsRefundPlenumList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((overseasCsRefundPlenum,index) => (
                      <TableRow 
                        hover 
                        key={index}
                      >
                        <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                        <TableCell align="center">{chaiStringUtils.dateFormatDefault(overseasCsRefundPlenum.trmDate)}</TableCell>
                        <TableCell align="center">건</TableCell>
                        <TableCell align="center">{overseasCsRefundPlenum.trmStatusCode}</TableCell>
                        <TableCell align="center">{chaiStringUtils.moneyFormat(overseasCsRefundPlenum.trmUsdAmount)} 원</TableCell>
                        <TableCell align="center">{chaiStringUtils.moneyFormat(overseasCsRefundPlenum.trmKrwAmount)} 원</TableCell>
                        <TableCell align="center">{chaiStringUtils.moneyFormat(overseasCsRefundPlenum.trmApplyExchangeRate)}</TableCell>
                        <TableCell align="center">{chaiStringUtils.moneyFormat(overseasCsRefundPlenum.trmUsdProcessAmount)} 원</TableCell>
                     </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>   
          <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={overseasCsRefundPlenumList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
          </CardActions>    
        </Card>
    );
  }
  
  export default OverseasCsRefundPlenumTable;
  

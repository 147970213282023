import React, { FC, ChangeEvent, useEffect } from 'react';
// import { useHistory } from 'react-router-dom' 
// import apolloClient from '../../lib/apolloClient';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { issueCard } from '../../lib/cardApi'
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  }
}));

type Props = {
  className?: string,
  userInfo: IssueCardInput,
  setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>>,
}
const AddCardDetails: FC<Props> = ({ className, userInfo, setUserInfo }) => {
  const classes = useStyles();
  const { showToast } = useToast();
 //  const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };
/*

  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(userInfo).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true)
    setIsValidForm(isValid);
  }

*/

  useEffect(() => {
   // checkFormValidation();
  // eslint-disable-next-line
  }, [userInfo])

  /*
  const [issueResult, setIssueResult] = React.useState({
    success: '',
    message: ''
  });
  */

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
    try {

      if(userInfo.AplcClss === undefined 
        || userInfo.AplcClss === null 
        || userInfo.AplcClss === ''
        || (userInfo.AplcClss !== '11' && userInfo.AplcClss !== '12')){
        userInfo.AplcClss = '11';
      }
      
   userInfo.ProcClss = '1';
   // userInfo.AplcClss = '11';
   userInfo.SndMthd = '2';
   userInfo.CardInvitCnstYnTel = '1';	
   userInfo.CardInvitCnstYnSms='1';
   userInfo.CardInvitCnstYnWrnt='1';
   userInfo.CardInvitCnstYnEmail='1';
   userInfo.InfoGdsGthrCnstYn='1';	 
   userInfo.InfoSvcGthrCnstYn='1';	 
   userInfo.UniqInfoGthrCnstYn2='1';
   userInfo.InfoIsurSppyCnstYn='1';
   userInfo.InfoFrauUseSppyCnstYn='1';
   userInfo.InfoAffiSppyCnstYn='1';
   userInfo.UniqInfoSppyCnstYn2='1';


      const issueCardResult = await issueCard(userInfo);
      console.log(issueCardResult);


      hideDialog();
      showDialog(
        '신청 결과',
        `\n성공여부: ${issueCardResult.STATUS.success}\n메세지:${issueCardResult.STATUS.message}`,
        () => {
         // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
        }
      )
    } catch (e) {
      hideDialog();
      showToast(e.message, 'error');
    };
  }

  const issueCode = [
    {
      value: '11',
      label: '최초신규 (모든 상품에 유효카드가 없을경우)'
    },
    {
      value: '12',
      label: '추가신규 (다른 상품에 유효카드가 존재할경우)'
    }
  ];

  const gender = [
    {
      value: '1',
      label: '남'
    },
    {
      value: '2',
      label: '여'
    }
  ];

  const product = [
    {
      value: '100128',
      label: '차이체크카드'
    },
    {
      value: '100915',
      label: '차이카드_Master'
    },
    {
      value: '100928',
      label: '차이카드_Master_교통'
    }
  ];

  const isForeigner = [
    {
      value: '2',
      label: '내국인'
    },
    {
      value: '1',
      label: '외국인'
    },
  ];

  const trafficCardIssueYn = [
    {
      value: '1',
      label: '후불교통신청'
    },
    {
      value: '2',
      label: '선불교통카드'
    },
    {
      value: '9',
      label: '미신청'
    }
  ];

  const BrndClss = [
    {
      value: '1',
      label: 'BC로컬'
    },
    {
      value: '2',
      label: 'Master'
    },
  ];

  const isEmpty = (input: string) => {
    return input === '';
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="발급 신청 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="상품명"
                margin="dense"
                name="AffiFirmNo"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.AffiFirmNo}
                
              >
                {product.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="브랜드 구분"
                margin="dense"
                name="BrndClss"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.BrndClss}
                
              >
                {BrndClss.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="후불교통카드 신청"
                margin="dense"
                name="trafficCardIssueYn"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.trafficCardIssueYn}
                
              >
                {trafficCardIssueYn.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="한글 이름"
                margin="dense"
                name="HgNm"
                onChange={handleChange}
                required
                value={userInfo.HgNm}
                variant="outlined"
                error={isEmpty(userInfo.HgNm)}
                helperText={isEmpty(userInfo.HgNm) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="영문 이름"
                margin="dense"
                name="EngNm"
                onChange={handleChange}
                required
                value={userInfo.EngNm}
                variant="outlined"
                error={isEmpty(userInfo.EngNm)}
                helperText={isEmpty(userInfo.EngNm) ? 'Required' : ''}
              />
            </Grid>           
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="성별 구분"
                margin="dense"
                name="Sex"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.Sex}
                
              >
                {gender.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="외국인 구분"
                margin="dense"
                name="FrnPsnClss"
                onChange={handleChange}
                required
                value={userInfo.FrnPsnClss}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                
              >
                {isForeigner.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호1"
                margin="dense"
                type='number'
                name="HpNo1"
                onChange={handleChange}
                required
                value={userInfo.HpNo1}
                variant="outlined"
                error={isEmpty(userInfo.HpNo1)}
                helperText={isEmpty(userInfo.HpNo1) ? 'Required' : ''}
                
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호2"
                margin="dense"
                type='number'
                name="HpNo2"
                onChange={handleChange}
                required
                value={userInfo.HpNo2}
                variant="outlined"
                error={isEmpty(userInfo.HpNo2)}
                helperText={isEmpty(userInfo.HpNo2) ? 'Required' : ''}
                
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호3"
                margin="dense"
                type='number'
                name="HpNo3"
                onChange={handleChange}
                required
                value={userInfo.HpNo3}
                variant="outlined"
                error={isEmpty(userInfo.HpNo3)}
                helperText={isEmpty(userInfo.HpNo3) ? 'Required' : ''}
                
              />
            </Grid>
                                   
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="우편번호"
                margin="dense"
                name="HomeZpNo"
                onChange={handleChange}
                required
                type='number'
                value={userInfo.HomeZpNo}
                variant="outlined"
                error={isEmpty(userInfo.HomeZpNo)}
                helperText={isEmpty(userInfo.HomeZpNo) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="도로명코드"
                margin="dense"
                name="HomeRoadCode"
                onChange={handleChange}
                required
                type='number'
                value={userInfo.HomeRoadCode}
                variant="outlined"
                error={isEmpty(userInfo.HomeRoadCode)}
                helperText={isEmpty(userInfo.HomeRoadCode) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="주소"
                margin="dense"
                name="HomeAddr"
                onChange={handleChange}
                required
                value={userInfo.HomeAddr}
                variant="outlined"
                error={isEmpty(userInfo.HomeAddr)}
                helperText={isEmpty(userInfo.HomeAddr) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                label="상세주소"
                margin="dense"
                name="HomeNwAddr2"
                onChange={handleChange}
                required
                value={userInfo.HomeNwAddr2}
                variant="outlined"
                error={isEmpty(userInfo.HomeNwAddr2)}
                helperText={isEmpty(userInfo.HomeNwAddr2) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="자택 전화번호1"
                margin="dense"
                type='number'
                name="HomeDDDNo"
                onChange={handleChange}
               // required
                value={userInfo.HomeDDDNo}
                variant="outlined"
              //  error={isEmpty(userInfo.HomeDDDNo)}
              //  helperText={isEmpty(userInfo.HomeDDDNo) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="자택 전화번호2"
                margin="dense"
                type='number'
                name="HomeTelNo1"
                onChange={handleChange}
              //  required
                value={userInfo.HomeTelNo1}
                variant="outlined"
              //  error={isEmpty(userInfo.HomeTelNo1)}
              //  helperText={isEmpty(userInfo.HomeTelNo1) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="자택 전화번호3"
                margin="dense"
                type='number'
                name="HomeTelNo2"
                onChange={handleChange}
              //  required
                value={userInfo.HomeTelNo2}
                variant="outlined"
              //  error={isEmpty(userInfo.HomeTelNo2)}
              //  helperText={isEmpty(userInfo.HomeTelNo2) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="고객번호"
                margin="dense"
                name="BkAcctNo"
                onChange={handleChange}
                required
                value={userInfo.BkAcctNo}
                variant="outlined"
                error={isEmpty(userInfo.BkAcctNo)}
                helperText={isEmpty(userInfo.BkAcctNo) ? 'Required' : ''}
                
              />
            </Grid> 
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="주민등록번호"
                margin="dense"
                name="JuminNo"
                onChange={handleChange}
                required
                value={userInfo.JuminNo}
                variant="outlined"
                error={isEmpty(userInfo.JuminNo)}
                helperText={isEmpty(userInfo.JuminNo) ? 'Required' : ''}
                
              />
            </Grid> 
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                inputProps={{
                  maxLength: 4,
                }}
                fullWidth
                type='number'
                label="비밀번호"
                margin="dense"
                name="PswdNo"
                onChange={handleChange}
                required
                value={userInfo.PswdNo}
                variant="outlined"
                error={isEmpty(userInfo.PswdNo)}
                helperText={isEmpty(userInfo.PswdNo) ? 'Required' : ''}
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="신규신청 구분"
                margin="dense"
                name="AplcClss"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={userInfo.AplcClss}                
              >
                {issueCode.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>           
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
          //  disabled={!isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '카드 발급 신청',
              '해당 정보로 신청하시겠습니까?',
              handleConfirmAddConfirm
            )}
          >
            발급 신청 
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddCardDetails;
import gql from 'graphql-tag';


export const selectCodeList = gql`
  mutation selectCodeList (
    $typeCode : String!,
    $groupCode : String!,
    $useYn : String!,
  ) {
    selectCodeList (
      typeCode : $typeCode,
      groupCode : $groupCode,
      useYn : $useYn,
    ) {
      typeCode
      groupCode
      codeId
      codeGroupDivisionCode
      codeName
      codeDescription
      codeValue
      mapCode
      useYn
    }
  }
`;

import client from './client';
import { get, find, orderBy } from 'lodash';

/**list와 detail 분리.. */
export default async (input: GetVipCardListInput): Promise<VipCardInfo[]> => {
  console.log('vip카드번호 목록 조회 START');
  console.log('================');
  console.log(input);
  console.log('================');

  /*if (!input.csno && !input.cardNo) {
    throw new Error(`vip 카드번호 정보 조회 실패 - 입력값이 없습니다`)
  }*/

  return client.post(`/root/card/vipCardList`, input).then(res => {
    const vipCardList = get(res, 'data.VipCardInfo');
    console.log('vipCardList::'+JSON.stringify(vipCardList));

    if (!vipCardList) {
      throw new Error(`조회된 vip카드정보가 없습니다.`)
    }

    //list
    if(input.type === 'list'){
      return orderBy(vipCardList, ['registeredDate'], ['desc']);
    }else{ //detail
      return find(vipCardList, item => { return item.vipCardNo === input.vipCardNo })
    }
    // throw new Error(`vip 카드번호 정보 조회 실패`)
  });
}
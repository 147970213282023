import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { CardContent,Grid,Card,TextField } from '@material-ui/core';

// import { SearchInput } from '../../components';
// import { getChaiUserInfo } from '../../lib/chaiAPI';


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  className?: string,
  userInfo: IssueCardInput,
  setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>>,
  setIsAddCardDetails: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAddCardCancelDetails: React.Dispatch<React.SetStateAction<boolean>>,
  setIsReAddCardDetails: React.Dispatch<React.SetStateAction<boolean>>,
  setIsUpdateCardDetails: React.Dispatch<React.SetStateAction<boolean>>
}
const UsersToolbar: FC<Props> = ({ 
  className,
  userInfo,
  setUserInfo, 
  setIsAddCardDetails,
  setIsAddCardCancelDetails,
  setIsReAddCardDetails,
  setIsUpdateCardDetails
 }) => {
  const classes = useStyles();

 // const [customerId, setCustomerId] = useState('');
  const [procClss, setProcClss] = useState('');

  /*
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerId(event.target.value);  
    userInfo.BkAcctNo = event.target.value;   
  };
  */

  const procHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    console.log('발급구분 선택 진입');
    console.log(event.target.value);
    setProcClss(event.target.value);
   
    switch (event.target.value) {
      case '1':
        setIsAddCardDetails(true);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(false);
        break;
      case '2':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(true);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(false);
        break;
      case '3':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(true);
        setIsAddCardCancelDetails(false);
        break;
      case '4':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(true);
        break; 
      
    }   
  };



  const procClssOption = [
    {
      value: '1',
      label: '신규발급'
    },
    {
      value: '2',
      label: '재발급'
    },
    {
      value: '3',
      label: '발급정보 정정 - 발급 당일만 가능'
    },
    {
      value: '4',
      label: '발급 취소 - 발급 당일만 가능'
    }
  ];

  /*
  const onSearchClick = async () => {
   const result = await getChaiUserInfo(customerId);
    console.log(result)
    setUserInfo({
      ...userInfo,
      ...result
    })
    

    switch (procClss) {
      case '1':
        setIsAddCardDetails(true);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(false);
        break;
      case '2':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(true);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(false);
        break;
      case '3':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(true);
        setIsAddCardCancelDetails(false);
        break;
      case '4':
        setIsAddCardDetails(false);
        setIsReAddCardDetails(false);
        setIsUpdateCardDetails(false);
        setIsAddCardCancelDetails(true);
        break; 
      
    }   
    
   
  };
  */

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
      <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
      <TextField
                
                label="업무처리 구분"
                margin="dense"                
                onChange={procHandleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={procClss}                
              >
                {procClssOption.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              </Grid>
              </Grid>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="User ID"
          handleChange={handleChange}
          value={customerId}
        />        
        <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Search 
        </Button> */}
        </CardContent>
  </Card>
      
  );
};


                            
export default UsersToolbar;

import client from '../../../lib/cardApi/client';

export default async (input: CardReturnManageInput): Promise<any> => {
  console.log('반송카드관리 카드목록 api 호출', getBody(input))

  return client.post(`/root/cardReturnManage/cardReturnList`, getBody(input)).then(res => {
    return res.data;
  }).catch(e => {
    throw Error(e);
  });
}

const getBody = (input: CardReturnManageInput): any => {
  let params = {
    issueStatusCode: input.issueStatusCode,    // 카드상태 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송수령
    searchDateGubun: input.searchDateGubun,    // 조회일자구분
    searchStartDate: input.searchStartDate,    // 시작일자
    searchEndDate: input.searchEndDate,        // 종료일자
    searchGubun: input.searchGubun,            // 조회구분(고객번호, 카드번호, 휴대폰번호)
    searchVal: input.searchVal
  }

  return params;
}
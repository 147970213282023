import gql from 'graphql-tag';



export const selectSettlementAggrList = gql`
  mutation selectSettlementAggrList ($input : SettlementAggrSearch){
    selectSettlementAggrList (input : $input) {
      aggregationDate
      accountDivisionCode
      withdrawalCount
      withdrawalTotalAmount
      depositCount
      depositTotalAmount
      tradeProcessCount
      tradeTotalAmount
      withdrawalFeeCount
      withdrawalFeeTotalAmount
      feeDepositCount
      feeDepositTotalAmount
      feeProcessCount
      feeTotalAmount
      withdrawalProxyFeeTotalAmount
      withdrawalMerchantFeeTotalAmount
      proxyFeeDepositTotalAmount
      merchantFeeDepositTotalAmount
      proxyFeeTotalAmount
      merchantFeeTotalAmount
    }
  }
`;


import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<BatchProcessHistorySearch>>
}

const BatchProcessHistoryListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {  

  const [batchProcessHistorySearch, setBatchProcessHistorySearch] = useState({
    fromDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    processStatus: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBatchProcessHistorySearch({
      ...batchProcessHistorySearch,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onSearch(batchProcessHistorySearch);
  };


  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="조회시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="fromDate"
                value={batchProcessHistorySearch.fromDate}
                onChange={handleChange}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="조회종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDate"
                value={batchProcessHistorySearch.endDate}
                onChange={handleChange}
              />
            </Grid>         
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="처리상태"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="processStatus"
                value={batchProcessHistorySearch.processStatus}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="1">정상완료</option>
                <option value="9">오류</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default BatchProcessHistoryListToolbar;

import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type Props = { 
  title :string;
  approvalHistoryInformations: ApprovalHistoryInformations;
}

const OverseasPurchasedAggrHistoryAdjustProcessApprovalHistoryInfomation: FC<Props> = ({ title, approvalHistoryInformations }) => {
  const classes = useStyles();
  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content} style={{paddingBottom: '0'}}>
        <div className={classes.inner}>
          <Table size="small">
          <TableBody>
            <TableRow>            
                  <TableCell align="center" className={classes.thStyle}>거래승인순번</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.trade_approval_seq == null) ? 0 : approvalHistoryInformations.trade_approval_seq}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.trade_date == null) ? 0 : chaiStringUtils.dateFormatDefault(approvalHistoryInformations.trade_date)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>카드순번</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.card_seq == null) ? 0 : approvalHistoryInformations.card_seq}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>매출종류구분코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.sales_kind_division_code == null) ? 0 : approvalHistoryInformations.sales_kind_division_code}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인일자</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.approval_date == null) ? 0 : chaiStringUtils.dateFormatDefault(approvalHistoryInformations.approval_date)}</TableCell>
            </TableRow>
            <TableRow>
                  <TableCell align="center" className={classes.thStyle}>해외카드승인번호</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.overseas_card_approval_no == null) ? 0 : approvalHistoryInformations.overseas_card_approval_no}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>카드번호</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.card_no_masked == null) ? 0 : approvalHistoryInformations.card_no_masked}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외승인이용번호</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.overseas_approval_use_no == null) ? 0 : approvalHistoryInformations.overseas_approval_use_no}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>MCC가맹점코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.mcc_merchant_code == null) ? 0 : approvalHistoryInformations.mcc_merchant_code}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>발행회사통화코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.issuer_currency_code == null) ? 0 : approvalHistoryInformations.issuer_currency_code}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center" className={classes.thStyle}>결제통화코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.payment_currency_code == null) ? 0 : approvalHistoryInformations.payment_currency_code}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>현지거래금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.local_trade_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.local_trade_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>BC국가번호</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.bc_nation_no == null) ? 0 : approvalHistoryInformations.bc_nation_no}</TableCell>
                  </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>승인도시명</TableCell>
                <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.approval_city_name == null) ? 0 : approvalHistoryInformations.approval_city_name}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center" className={classes.thStyle}>영문가맹점명</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.merchant_eng_name == null) ? 0 : approvalHistoryInformations.merchant_eng_name}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>브랜드구분코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.brand_division_code == null) ? 0 : approvalHistoryInformations.brand_division_code}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>전문승인요청미화금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.text_approval_request_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.text_approval_request_usd_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>브랜드수수료계산구분코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.brand_fee_calcu_division_code == null) ? 0 : approvalHistoryInformations.brand_fee_calcu_division_code}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>원승인취소구분코드</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.origin_approval_cancel_division_code == null) ? 0 : approvalHistoryInformations.origin_approval_cancel_division_code}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center" className={classes.thStyle}>승인취소일자</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.approval_cancel_date == null) ? 0 : chaiStringUtils.dateFormatDefault(approvalHistoryInformations.approval_cancel_date)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인요청미화금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.approval_request_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.approval_request_usd_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인요청미화원금금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.approval_request_usd_origin_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.approval_request_usd_origin_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외서비스수수료기준금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.overseas_use_fee_impose_base_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_use_fee_impose_base_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외브랜드미화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.overseas_brand_usd_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_brand_usd_fee)}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="center" className={classes.thStyle}>해외브랜드원화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>&#8361; {(approvalHistoryInformations.overseas_brand_krw_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_brand_krw_fee)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외이용미화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.overseas_use_usd_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_use_usd_fee)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외이용원화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>&#8361; {(approvalHistoryInformations.overseas_use_krw_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_use_krw_fee)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외출금미화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.overseas_withdrawal_usd_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_withdrawal_usd_fee)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>해외출금원화수수료</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>&#8361; {(approvalHistoryInformations.overseas_withdrawal_krw_fee == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.overseas_withdrawal_krw_fee)}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="center" className={classes.thStyle}>요청인출미화금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>$ {(approvalHistoryInformations.request_withdrawal_usd_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.request_withdrawal_usd_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>실인출원화금액</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>&#8361; {(approvalHistoryInformations.real_withdrawal_krw_amount == null) ? 0 : chaiStringUtils.moneyFormat(approvalHistoryInformations.real_withdrawal_krw_amount)}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>승인적용환율</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.approval_apply_exchange_rate == null) ? 0 : approvalHistoryInformations.approval_apply_exchange_rate}</TableCell>
                  <TableCell align="center" className={classes.thStyle}>계정처리순번</TableCell>
                  <TableCell align="left" className={classes.tdStyle}>{(approvalHistoryInformations.account_process_seq == null) ? 0 : approvalHistoryInformations.account_process_seq}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverseasPurchasedAggrHistoryAdjustProcessApprovalHistoryInfomation;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import GroupMerchantTable from './GroupMerchantTable';
import GrouptMerchantInfoListToolbar from './GroupMerchantInfoListToolbar';
import { groupMerchantInfoMutation } from '../../lib/apolloClient/mutations';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputUseYn: string                // 사용여부
}

type Props ={ className?:string, groupMerchantDetail: GetGroupMerchantDetail }
const GroupMerchantList: FC<Props> = ({className, groupMerchantDetail}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    inputGroupMerchantNo: '',     // 그룹가맹점번호
    inputGroupMerchantName: '',   // 그룹가맹점명
    inputMerchantNo: '',          // 가맹점번호
    inputMerchantName: '',        // 가맹점명
    inputGubun: '',               // 차이 / 비씨 구분
    inputUseYn: 'Y'               // 사용여부
  });

  useEffect(() => {
    fetch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 데이터 목록 조회 로직
  const fetch = async (searchFormInput: SearchInfo) => {
    console.log('목록 조회');

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        inputGroupMerchantNo: searchFormInput.inputGroupMerchantNo, 
        inputGroupMerchantName: searchFormInput.inputGroupMerchantName,
        inputMerchantNo: searchFormInput.inputMerchantNo, 
        inputMerchantName: searchFormInput.inputMerchantName,
        inputGubun: searchFormInput.inputGubun,
        inputUseYn: searchFormInput.inputUseYn
      },
      mutation: groupMerchantInfoMutation.selectGroupMerchantInfoList
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectGroupMerchantInfoList);
      setData(res.data.selectGroupMerchantInfoList);
    }).catch(console.error);

    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="그룹가맹점정보" />
      <GrouptMerchantInfoListToolbar  fetch={fetch} searchForm={searchForm} setSearchForm={setSearchForm}/>
      <div className={classes.content}>
        {loading
          ? 
            utils.Loading()
          :
            <GroupMerchantTable groupMerchantList={data} fetch={fetch} searchForm={searchForm}/>
        }
      </div>
    </div>
  );
};

export default GroupMerchantList;
import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import ForeignSndrvLogDetailPop from './ForeignSndrvLogDetailPop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  }
}));

type Props = { title:string, foreignSndrvLogs:ForeignSndrvLog[] }
const ForeignSndrvLogListTable: FC<Props> = ({ title, foreignSndrvLogs }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // 상세팝업 오픈여부
  const [detailPopOpen, setDetailPopOpen] = useState(false);

  // 상세데이터
  const foreignSndrvLog: ForeignSndrvLog = {
    foreignInstCode: '',
    textManagementNo: '',
    tradeDate: '',
    tradeUniqNo: '',
    sndrvDivisionCode: '',
    tradeTime: '',
    operateTradeCode: '',
    headerInfo: '',
    individualCommonInfo: '',
    responseCode: '',
    messageDescription: '',
    cardNo: '',
    cardNoMasked: '',
    firstOperateAdminId: '',
    firstOperateDetailDate: '',
    firstOperateTradeCode: ''
  };
  const [detailInfo, setDetailInfo] = useState(foreignSndrvLog);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 테이블 행 클릭 이벤트
  function handleRowClick(foreignSndrvLog: ForeignSndrvLog) {
    setDetailPopOpen(true);
    setDetailInfo(foreignSndrvLog);
  }

  if(foreignSndrvLogs.length === 0) {
    return (
      <Card>
        { !!title &&
          <CardHeader title={title}/>
        }
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>대외기관코드</TableCell>
                  <TableCell>조작거래코드</TableCell>
                  <TableCell>거래일자</TableCell>
                  <TableCell>전문관리번호</TableCell>
                  <TableCell>전문거래고유번호</TableCell>
                  <TableCell>거래시분초</TableCell>
                  <TableCell>응답코드</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foreignSndrvLogs.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((foreignSndrvLog,index) => (
                  <Tooltip title="더블클릭 상세보기" key={index}>
                    <TableRow
                      hover
                      key={foreignSndrvLog.foreignInstCode+''+foreignSndrvLog.textManagementNo+''+foreignSndrvLog.tradeDate}
                      onDoubleClick={() => handleRowClick(foreignSndrvLog)}
                      className={classes.cursor_pointer}
                    >
                      <TableCell>{foreignSndrvLog.foreignInstCode}</TableCell>
                      <TableCell>{foreignSndrvLog.operateTradeCode}</TableCell>
                      <TableCell>{chaiStringUtils.dateFormat(foreignSndrvLog.tradeDate, null)}</TableCell>
                      <TableCell>{foreignSndrvLog.textManagementNo}</TableCell>
                      <TableCell>{foreignSndrvLog.tradeUniqNo}</TableCell>
                      <TableCell>{chaiStringUtils.dateTimeFormat(foreignSndrvLog.tradeTime)}</TableCell>
                      <TableCell>{foreignSndrvLog.responseCode}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={foreignSndrvLogs.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <ForeignSndrvLogDetailPop detailPopOpen={detailPopOpen} setDetailPopOpen={setDetailPopOpen} detailInfo={detailInfo} />
    </Card>
  );
}

export default ForeignSndrvLogListTable;
import axios from "axios";

let CHAIMONEY_API_HOST = `https://api-dev.chai.finance`;

if (process.env.REACT_APP_ENV === 'prod') {
  CHAIMONEY_API_HOST = `http://card-payment-prod:3030`;
}
else if (process.env.REACT_APP_ENV === 'staging') {
  CHAIMONEY_API_HOST = `http://card-payment-dev:3030`;
}

let privateAPIKey = '100ef89b-c81a-4584-acd0-e52544d13ce8';
let publicAPIKey = '0db20776-8956-4284-93f6-a47e76227d9e';

if (process.env.REACT_APP_ENV === 'prod') {
  privateAPIKey = '2dba8622-f87d-42db-95c9-acdb9211aa90';
  publicAPIKey = '2e2195ca-4c6e-4818-92b4-41333ab22eb1';
}
else if (process.env.REACT_APP_ENV === 'staging') {
  privateAPIKey = '100ef89b-c81a-4584-acd0-e52544d13ce8';
  publicAPIKey = '0db20776-8956-4284-93f6-a47e76227d9e';
}

const client = axios.create({
  baseURL: CHAIMONEY_API_HOST,
  responseType: "json"
});


export const getChaiUserInfo = async (koreaSpecId: string) => {
  /*
  const result = await client.get(`/v1/card/user/${koreaSpecId}`, {
    headers: {
      'Private-API-Key': '97d1de70-189b-4c64-a4dd-8d7af44ba8a4'
    }
  });
  const gender = get(result, 'data.gender', undefined);
  const isForeigner = get(result,'data.isForeigner',undefined);
  console.log(gender)
*/
  return {   
    Sex: '1',
    HgNm: '',
    FrnPsnClss: '2',
  }
}


export const chaiPaymentCancel =  async (input: ChaiMoneyRefundReq) => {
  console.log('차이머니 환불:', input );

  const chaiPayUrl = `/v1/card/payment/${input.paymentId}/cancel`;

  const headers = {
    headers: {
      'Private-API-Key': privateAPIKey,
      'Idempotency-Key': input.idempotencyKey
    }
  };

  /***
  const params = {
    'checkoutAmount': input.checkoutAmount.toString(),
    'cancelAmount': input.cancelAmount.toString(),
    'cardMerchantId': input.merchantNo,
    'cardMerchantName': input.merchantName  
  };
  ***/
  // 가맹점정보 세팅부분 제거
  const params = {
    'checkoutAmount': input.checkoutAmount.toString(),
    'cancelAmount': input.cancelAmount.toString()
  };

  console.log('params=', params);

  return client.post(chaiPayUrl, params, headers).then(res => {    
    console.log('resdata=', res.data);
    return res.data;
  }).catch(e => {
    if (e.response) {
      console.log('e.response.status=', e.response.status);
      console.log('e.response.data.code=', e.response.data.code);
      const errCode = e.response.data.code;
      if (Math.floor(e.response.status / 100) === 4) {
        // 이미 취소된 거래의 경우에는 입출금성공으로 처리한다
        if (errCode) {
          if (errCode === 'C005') { // 이미 취소된 거래인 경우
            return {paymentId: input.paymentId};
          }
        }
      }
      throw e.response.data ? e.response.date : e;
    }
    else {
      console.log('chaiPaymentCancel',e);
      throw e;
    }
  });
}

export const chaiPaymentDeposit =  async (input: ChaiMoneyDepositReq) => {
  console.log('차이머니 입금:', input );

  const chaiPayUrl = `/v1/card/unprocessedTx/deposit`;

  const headers = {
    headers: {
      'Private-API-Key': privateAPIKey,
      'Idempotency-Key': input.idempotencyKey,
      'User-Id': input.userId
    }
  };

  const params = {
    'amount': input.amount.toString(),
    'description': input.description,
    'categoryId': input.categoryId,
  };
  
  console.log('params=', params);

  return client.post(chaiPayUrl, params, headers).then(res => {    
    console.log('resdata=', res.data);
    return res.data;
  }).catch(e => {
    if (e.response) {
      console.log('e.response.status=', e.response.status);
      console.log('e.response.data.code=', e.response.data.code);
      const errCode = e.response.data.code;
      if (Math.floor(e.response.status / 100) === 5) {
        if (errCode) {
          if (errCode === 'D025') { // 이미 입금처리된 거래인 경우
            return {id: '',status:'already deposit'};
          }
        }
      }
      throw e.response.data ? e.response.data : e;
    }
    else {
      console.log('chaiPaymentDeposit',e);
      throw e;
    }
  });
}


export const chaiPaymentStatus = async (input: ChaiMoneyRefundReq) => {
  console.log('차이머니 결제상태:', input );

  const chaiPayUrl = `/v1/payment/${input.paymentId}`;
  const headers = {
    headers: {
      'Private-API-Key': privateAPIKey,
      'Idempotency-Key': input.idempotencyKey
    }
  };

  return client.get(chaiPayUrl, headers).then(res => {    
    console.log('resdata=', res.data);
    return res.data;
  }).catch(e => {
    if (e.response) {
      console.log('chaiPaymentStatus e.response=',e.response);
      if (Math.floor(e.response.status / 100) === 4) {
        if (e.response.data.code) {
          if (e.response.data.code === 'C001') {
            return {paymentId:'',status:'notfound'};
          }
        }
      }
      throw e.response.data ? e.response.data : e;
    }
    else {
      console.log('chaiPaymentStatus', e);
      throw e;
    }
  });
}

export const chaiPaymentId = async (input: ChaiMoneyRefundReq) => {
  console.log('차이머니 결제ID:', input );

  const chaiPayUrl = `/v1/payment`;
  const headers = {
    headers: {
      'Private-API-Key': privateAPIKey,
      'Idempotency-Key': input.idempotencyKey
    }
  };

  return client.get(chaiPayUrl, headers).then(res => {    
    console.log('resdata=', res.data);
    return res.data;
  }).catch(e => {
    if (e.response) {
      console.log('chaiPaymentId e.response=',e.response);
      if (Math.floor(e.response.status / 100) === 4) {
        if (e.response.data.code) {
          if (e.response.data.code === 'C001') {
            return {paymentId:'',status:'notfound'};
          }
        }
      }
      throw e.response.data ? e.response.data : e;
    }
    else {
      console.log('chaiPaymentId', e);
      throw e;
    }
  });
}

export const chaiPaymentDepositStatus= async (input: ChaiMoneyDepositReq) => {
  console.log('차이머니 입금상태:', input );

  const chaiPayUrl = `/v1/card/unprocessedTx`;
  const headers = {
    headers: {
      'Public-API-Key': publicAPIKey,
      'Idempotency-Key': input.idempotencyKey
    }
  };

  return client.get(chaiPayUrl, headers).then(res => {    
    console.log('resdata=', res.data);
    return res.data;
  }).catch(e => {
    if (e.response) {
      console.log('chaiPaymentDepositStatus e.response=',e.response);
      if (Math.floor(e.response.status / 100) === 5) {
        if (e.response.data.code) {
          if (e.response.data.code === 'D024') { // 내역이 없을때
            return null;
          }
        }
      }      
      throw e.response.data ? e.response.data : e;
    }
    else {
      console.log('chaiDepositStatus', e);
      throw e;
    }
  });
}
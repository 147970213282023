import gql from 'graphql-tag';

// 계정처리내역 조회(페이징)
export const selectAccountProcListPaging = gql`
  mutation selectAccountProcListPaging(
    $startDt: String!
    $endDt: String!
    $cardNo: String!
    $accountProcessSeq: String!
    $accountingProcessDivisionCode: String!
    $page: Float
    $rowsPerPage: Float
    $isExcelDown: Boolean
  ) {
    selectAccountProcListPaging(
      startDt: $startDt
      endDt: $endDt
      cardNo: $cardNo
      accountProcessSeq: $accountProcessSeq
      accountingProcessDivisionCode: $accountingProcessDivisionCode
      page: $page
      rowsPerPage: $rowsPerPage
      isExcelDown: $isExcelDown
    ) {
      totalCount
      list {
        accountProcessSeq
        accountProcessDetailSeq
        accountDivisionCode
        inoutDivisionCode
        accountProcessDate
        accountProcessTime
        inoutAmount
        cardNo
        cardNoMasked
        accountingProcessDivisionCode
        merchantName
        accountNo
        paymentId
        summaryType
        slipNo
        processCancelYn
      }
    }
  }
`;

// 계정처리내역 조회
export const selectAccountProcList = gql`
  mutation selectAccountProcList($startDt: String!, $endDt: String!, $cardNo: String!, $accountProcessSeq: String!, $accountingProcessDivisionCode: String!) {
    selectAccountProcList(
      startDt: $startDt
      endDt: $endDt
      cardNo: $cardNo
      accountProcessSeq: $accountProcessSeq
      accountingProcessDivisionCode: $accountingProcessDivisionCode
    ) {
      accountProcessSeq
      accountProcessDetailSeq
      accountDivisionCode
      inoutDivisionCode
      accountProcessDate
      accountProcessTime
      inoutAmount
      cardNo
      cardNoMasked
      accountingProcessDivisionCode
      merchantName
      accountNo
      paymentId
      summaryType
      slipNo
      processCancelYn
    }
  }
`;

export const selectAccountProcSum = gql`
  mutation selectAccountProcSum($startDt: String!, $endDt: String!, $cardNo: String!, $accountProcessSeq: String!, $accountingProcessDivisionCode: String!) {
    selectAccountProcSum(
      startDt: $startDt
      endDt: $endDt
      cardNo: $cardNo
      accountProcessSeq: $accountProcessSeq
      accountingProcessDivisionCode: $accountingProcessDivisionCode
    ) {
      inCount
      inAmount
      outCount
      outAmount
      totalCount
      totalAmount
    }
  }
`;

import React, {useState, FC} from 'react';
import{
    Card,
    CardHeader,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
} from '@material-ui/core';

/**
const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
**/

  type Props ={
      data:TempCardBinHistory[],
    
}

const CardBinHistory : FC<Props> = ({ data }) => {

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
      };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
      };

    const brandDivisionCodeMap = new Map<String, String>();
    
    brandDivisionCodeMap.set('0', 'GLOBAL BC');
    brandDivisionCodeMap.set('1', 'LOCAL');
    brandDivisionCodeMap.set('2', 'MC');
    brandDivisionCodeMap.set('3', 'VISA');
    brandDivisionCodeMap.set('4', 'JCB');

    return(
            <Card>
        <CardHeader title="<BIN정보 변경이력>"/>        
        <Table>
            <TableHead>
            </TableHead>
            <TableRow>
                <TableCell>
                    No.
                </TableCell>
                <TableCell>
                    변경일시
                </TableCell>
                <TableCell>
                    BIN번호
                </TableCell>
                <TableCell>
                    카드상품코드
                </TableCell>
                <TableCell>
                    브랜드
                </TableCell>
                <TableCell>
                    시작일련번호
                </TableCell>
                <TableCell> 
                    종료일련번호
                </TableCell>
                <TableCell> 
                    현재일련번호
                </TableCell>
                <TableCell> 
                    종료일자
                </TableCell>
            </TableRow>
            <TableBody>
            {data.slice(page * rowsPerPage, (page + 1) * (rowsPerPage)).map((item, idx) => (
            <TableRow
            key={item.changeSeq+item.binNo+''+item.cardProductCode+''+item.endCardMediaSeq}>
                <TableCell>{(page * rowsPerPage) + idx+1}</TableCell>
                <TableCell>{item.changeDate}/{item.changeTime}</TableCell>
                <TableCell>{item.binNo}</TableCell>
                <TableCell>{item.cardProductCode}</TableCell>
                <TableCell>{brandDivisionCodeMap.get(item.brandDivisionCode)}</TableCell>
                <TableCell>{item.startCardMediaSeq}</TableCell>
                <TableCell>{item.endCardMediaSeq}</TableCell>
                <TableCell>{item.nowCardMediaSeq}</TableCell>
                <TableCell>{item.endDate}</TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            <TablePagination
            component="div"
            count={data.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
        />
            </Card>

    );
};

export default CardBinHistory;
import gql from 'graphql-tag';

export const selectNonInoutInfo = gql`
  mutation selectNonInoutInfo($input: NonInoutInfoInput) {
    selectNonInoutInfo(input: $input) {
      nonInoutRegisteredDate
      nonInoutSeq
      receveBaseDate
      cardNo
      receveDate
      inoutDivisionCode
      nonInoutOriginAmount
      lastOperateDetailDate
      nonInoutStatusCode
      creditTargetNo
      debitTargetNo
      checked
    }
  }
`;

export const updateNonInoutInfo = gql`
  mutation updateNonInoutInfo($input: updateNonInoutInfoInput) {
    updateNonInoutInfo(input: $input) {
      nonInoutStatusCode
      nonInoutSeqArr
    }
  }
`;

export const selectNonInoutHistory = gql`
  mutation selectNonInoutHistory($nonInoutSeq: Float!) {
    selectNonInoutHistory(nonInoutSeq: $nonInoutSeq) {
      nonInoutRegisteredDate
      nonInoutSeq
      receveBaseDate
      cardNo
      receveDate
      inputDivisionCode
      nonInoutOriginAmount
      lastOperateDetailDate
      nonInoutStatusCode
      creditTargetNo
      debitTargetNo
      summaryType
    }
  }
`;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';

import AccidentMgmtToolbar from './AccidentMgmtToolbar';
import UserDetailInfo from './UserDetailInfo';
import CardList from './CardList';
import CardAccidentHistoryList from './CardAccidentHistoryList';
import { accidentMgmtMutation } from '../../lib/apolloClient/mutations';
import { MenuTitleToolbar } from '../../components';

import CardAccidentRegister from './CardAccidentRegister';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type Props = { 
    className?:string, 
    cardUserInfoReturn: AccidentMgmtCardMemberInfoReturn,   // 회원정보 타입
    cardInfoListReturn: AccidentMgmtCardInfoReturn[],       // 카드목록 타입
    cardAccidentHistoryReturn: AccidentCardAccidentHistory[]  // 카드사고 이력
   }
const AccidentMgmt: FC<Props> = ({ className, cardUserInfoReturn, cardInfoListReturn, cardAccidentHistoryReturn }) => { 
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  
  // 회원정보
  const [cardUserInfo, setCardUserInfo] = useState(cardUserInfoReturn); // 회원정보

  // 보유카드목록
  const [cards, setCards] = useState([]); // 보유카드목록

  // 카드 번호
  const [cardNo, setCardNo] = useState(''); // set [카드번호]
  const [cardNoMasked, setCardNoMasked] = useState('');
  
  // 사고 이력
  const [cardAccidentHistorys, setCardAccidentHistorys] = useState([]);

  // 회원정보 조회
  const fetch = async (inputSearchGubun: string, inputSearchVal: string) => {
    console.log('회원정보 조회');

    setCardUserInfo(cardUserInfoReturn);  // 목록 초기화
    setCards([]); // 보유카드 목록 초기화
    setCardNo('');  // 카드번호 초기화
    setCardAccidentHistorys([]); // 사고이력 초기화

    // setLoading(true);

    await apolloClient.mutate({
        // inputSearchGubun: 조회구분
        // inputSearchVal: 조회 값
        variables: {searchGubun : inputSearchGubun, searchVal : inputSearchVal},
        mutation: accidentMgmtMutation.selectCardMemberInfo
      }).then(res => {
        console.log('회원 조회 결과 : ', res.data.selectCardMemberInfo);
        setCardUserInfo(res.data.selectCardMemberInfo); // 회원정보
      }).catch(console.error);

    // setLoading(false);
  };

  // 보유카드목록 조회
  const cardListFetch = async () => {
    setCards([]); // 보유카드 목록 초기화
    setCardNo('');  // 카드번호 초기화
    setCardAccidentHistorys([]); // 사고이력 초기화
    // setLoading(true);

    await apolloClient.mutate({
      variables: {cardMemberNo : cardUserInfo.cardMemberNo},  // set [카드회원번호]
      mutation: accidentMgmtMutation.selectCardList
    }).then(res => {
      console.log('보유카드 목록 조회 결과 : ', res.data.selectCardList);
      setCards(res.data.selectCardList); // 보유카드목록
    }).catch(console.error);

    // setLoading(false);
  }
  
  useEffect(() => {
    console.log('useEffect 실행 - 카드목록 조회');

    if(cardUserInfo !== undefined && cardUserInfo !== null) { // 회원정보가 존재할 때
      cardListFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardUserInfo]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="사고관리" />
      <AccidentMgmtToolbar fetch={fetch}/>
      <div className={classes.content}>
        <UserDetailInfo title='회원정보' cardUserInfo={cardUserInfo}/>
        <br/>
        <CardList title='보유카드목록' cards={cards} setCardNo={setCardNo} setCardNoMasked={setCardNoMasked} setCardAccidentHistorys={setCardAccidentHistorys}/>
        <br/>
        <CardAccidentRegister title='사고 등/해제' cardNo={cardNo} cardNoMasked={cardNoMasked} cardListFetch={cardListFetch}/>
        <br/>
        <CardAccidentHistoryList title="카드사고이력" cardAccidentHistorys={cardAccidentHistorys} />
      </div>
    </div>
  )
}

export default AccidentMgmt;
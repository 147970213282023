import React, { FC, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
// import {format} from 'date-fns';
import apolloClient from '../../lib/apolloClient';
// import { SearchInput } from '../../components';
import {settlementAutoMutation} from '../../lib/apolloClient/mutations';

  type SearchInfo = {
    feeSettlementYm:string,                     // 정산년월             
    proxyFeeCodeNo:string,                      // 위임수수료코드번호
    domesticCode:string,                        // 단위수수료코드번호     
  }
  
  type SettlementAutoNoNm = {
    proxyFeeCodeNo : string;
    proxyFeeNm : string;
  }

  type Props ={
      onSearch: React.Dispatch<React.SetStateAction<any>>,
      searchForm: SearchInfo,
      setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
  };

  const SettlementAutoToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {

    const [proxyFeeCodeNoList, setProxyFeeCodeNoList] = useState<SettlementAutoNoNm[]>([]);

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        });
    };

    // 위임수수료 코드번호 조회
    useEffect(() => {
      const fetch = async() => {
        await apolloClient.mutate({
          mutation: settlementAutoMutation.selectSettlementAutoNoNm
        }).then(res => {
          console.log('자동대사 test : ' , res);
          setProxyFeeCodeNoList(res.data.selectSettlementAutoNoNm);
        }).catch(console.error);
      }
      fetch()
    }, []);

    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
        if (event.key === "Enter"){
            onSearchClick();
        }
    }

    // 목록 조회
    const onSearchClick = async () => {
        onSearch(searchForm);
    }

    return(
     <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="month"
              label="정산기준년월"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="feeSettlementYm"
              value={searchForm.feeSettlementYm}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="위임수수료"
              placeholder="위임수수료"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              name="proxyFeeCodeNo"
              value={searchForm.proxyFeeCodeNo}
              onChange={handleChange}
              >
              <option key="" value="">전체</option>
              {proxyFeeCodeNoList.map(item => (
                <option
                  key={item.proxyFeeCodeNo}
                  value={item.proxyFeeCodeNo}
                >
                  {item.proxyFeeNm}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
                size="small"
                style={{ width: '100%' }}
                margin="dense"
                label="국내외구분"
                name="domesticCode"
                onChange={handleChange}
                value={searchForm.domesticCode}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                select
                SelectProps={{ native: true }}>
                <option value="">전체</option>   
                <option value="1">국내</option>
                <option value="2">해외</option>
              </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
      </CardContent>
     </Card>

    )
  }
  export default SettlementAutoToolbar;
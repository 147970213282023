import { Button, makeStyles } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import apolloClient, { plccQuery } from '../../lib/apolloClient';
import PurchasedAggrHistoryDetail from './PlccAggrHistoryDetail';
import PurchasedAggrHistoryListToolbar from './PlccAggrHistoryListToolbar';
import PurchasedAggrHistoryTable from './PlccAggrHistoryTable';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  detail: {
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  purchasedAggrHistoryDetail: PlccAggrHistoryDetailInfo;
};

export const HanaAggrHistoryList: FC<Props> = ({ purchasedAggrHistoryDetail }) => {
  const classes = useStyles();
  const [listData, setListData] = useState([]);
  const [listSearch, setListSearch] = useState({
    startAggregationDate: format(subMonths(new Date(), 1), 'yyyyMMdd'),
    endAggregationDate: format(new Date(), 'yyyyMMdd'),
    isOverseas: '',
  });

  const [detailData, setDetailData] = useState(purchasedAggrHistoryDetail);

  useEffect(() => {
    const getList = async () => {
      await apolloClient
        .query({
          query: plccQuery.getHanaAggrHistoryList,
          variables: {
            startAggregationDate: listSearch.startAggregationDate,
            endAggregationDate: listSearch.endAggregationDate,
            isOverseas: listSearch.isOverseas,
          },
        })
        .then(res => {
          setListData(res.data.getHanaAggrHistoryList.list);
          setDetailData(res.data.getHanaAggrHistoryList.total);
        })
        .catch(console.error);
    };
    getList(); // 매입집계내역
  }, [listSearch]);

  const excelClick = (csvData: any[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        집계일자: `${chaiStringUtils.dateFormatDefault(csvData[i].purchaseDate)}`,
        집계구분: `${csvData[i].isOverseas ? '해외' : '국내'}`,
        출금건수: `${chaiStringUtils.moneyFormat(csvData[i].paymentCount)}`,
        출금금액: `${chaiStringUtils.moneyFormat(csvData[i].plusAmount)}`,
        출금금액달러: `${chaiStringUtils.moneyFormat(csvData[i].plusAmountUSD)}`,
        입금건수: `${chaiStringUtils.moneyFormat(csvData[i].cancelPaymentCount)}`,
        입금금액: `${chaiStringUtils.moneyFormat(csvData[i].minusAmount)}`,
        입금금액달러: `${chaiStringUtils.moneyFormat(csvData[i].minusAmountUSD)}`,
        총건수: `${chaiStringUtils.moneyFormat((csvData[i].paymentCount || 0) - (csvData[i].cancelPaymentCount || 0))}`,
        총금액: `${chaiStringUtils.moneyFormat((csvData[i].plusAmount || 0) - (csvData[i].minusAmount || 0))}`,
        수수료: `${chaiStringUtils.moneyFormat(csvData[i].fee)}`,
        수수료VAT: `${chaiStringUtils.moneyFormat(csvData[i].feeTax)}`,
      });
    }
    utils.exportToCSV('신용카드 매입집계내역', rowData);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="신용카드 매입집계내역 조회" />
      <PurchasedAggrHistoryListToolbar /** 검색조건 */ onSearch={setListSearch} />
      <div className={classes.content}>
        <PurchasedAggrHistoryDetail /** 매입집계 */ className={classes.detail} detailInfo={detailData} />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto', marginBottom: 10 }}
          onClick={event => {
            excelClick(listData);
          }}
        >
          Excel
        </Button>
        <PurchasedAggrHistoryTable /** 매입집계내역LIST */ dataList={listData} />
      </div>
    </div>
  );
};

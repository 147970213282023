import React, { FC, useState, useEffect } from 'react';
import apolloClient from '../../lib/apolloClient';
import { format, subMonths } from 'date-fns';
import { purchasedAggrHistoryMutation } from '../../lib/apolloClient/mutations';
import PurchasedAggrHistoryListToolbar from './PurchasedAggrHistoryListToolbar';
import PurchasedAggrHistoryTable from './PurchasedAggrHistoryTable';
import PurchasedAggrHistoryDetail from './PurchasedAggrHistoryDetail';
import { makeStyles, Button } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  detail: {
    marginBottom: theme.spacing(3)
  }
}));

type Props = { 
  purchasedAggrHistoryDetail: PurchasedAggrHistoryDetailInfo
}

const PurchasedAggrHistory: FC<Props> = ({ purchasedAggrHistoryDetail }) => {
 
  const classes = useStyles();
  const [listData, setListData] = useState([])
  const [listSearch, setListSearch] = useState({
    startAggregationDate: format(subMonths(new Date(), 1), 'yyyyMMdd'), 
    endAggregationDate: format(new Date(), 'yyyyMMdd'), 
  });

  const [detailData, setDetailData] = useState(purchasedAggrHistoryDetail);

  useEffect(() => {
    const getList = async () => {
      await apolloClient.mutate({
        mutation: purchasedAggrHistoryMutation.selectPurchasedAggrHistoryList,
        variables: { startAggregationDate: listSearch.startAggregationDate, endAggregationDate: listSearch.endAggregationDate }
      }).then(res => {        
        setListData(res.data.selectPurchasedAggrHistoryList)
      }).catch(console.error);
    }
    getList()     // 매입집계내역

  }, [listSearch])
 
  useEffect(() => {
    const getDetail = async () => {
      await apolloClient.mutate({
        mutation: purchasedAggrHistoryMutation.selectPurchasedAggrHistoryInfo,
        variables: { startAggregationDate: listSearch.startAggregationDate, endAggregationDate: listSearch.endAggregationDate }
      }).then(res => {        
        setDetailData(res.data.selectPurchasedAggrHistoryInfo)
      }).catch(console.error);
    }
    getDetail()   // 매입집계상세

  }, [listSearch])

  const excelClick = (csvData: any[]) => {
    
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
            '집계일자': `${chaiStringUtils.dateFormatDefault(csvData[i].aggregationDate)}`,
            '집계구분': `${chaiStringUtils.codeName('aggregationDivisionCode', csvData[i].aggregationDivisionCode)}`,
            '카드계정': `${chaiStringUtils.codeName('accountDivisionCode', csvData[i].accountDivisionCode)}`,
            '출금건수': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalCount)}`,
            '출금총액': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalTotalAmount)}`,
            '입금건수': `${chaiStringUtils.moneyFormat(csvData[i].depositCount)}`,
            '입금총액': `${chaiStringUtils.moneyFormat(csvData[i].depositTotalAmount)}`,
            '전체건수': `${chaiStringUtils.moneyFormat(csvData[i].tradeProcessCount)}`,
            '전체총액': `${chaiStringUtils.moneyFormat(csvData[i].tradeTotalAmount)}`
        });
    }

    utils.exportToCSV("국내매입집계내역", rowData);   
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내매입집계내역 조회" />
      <PurchasedAggrHistoryListToolbar /** 검색조건 */ onSearch={setListSearch}/>
      <div className={classes.content}>
        <PurchasedAggrHistoryDetail /** 매입집계 */ className={classes.detail} detailInfo={detailData} />
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(listData); }} >Excel</Button>
        <PurchasedAggrHistoryTable /** 매입집계내역LIST */ dataList={listData} />
      </div>
      
    </div>
  );
};

export default PurchasedAggrHistory;

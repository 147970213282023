import React, { FC, ChangeEvent, useEffect } from 'react';
// import { useHistory } from 'react-router-dom' 
// import apolloClient from '../../lib/apolloClient';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { issueCard } from '../../lib/cardApi'
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  }
}));

type Props = {
  className?: string,
  userInfo: IssueCardInput,
  setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>>,
}
const AddCardCancelDetails: FC<Props> = ({ className, userInfo, setUserInfo }) => {
  const classes = useStyles();
  const { showToast } = useToast();
 // const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

/*
  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(userInfo).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true)
    setIsValidForm(isValid);
  }
*/


  useEffect(() => {
   // checkFormValidation();
  // eslint-disable-next-line
  }, [userInfo])

  /*
  const [issueResult, setIssueResult] = React.useState({
    success: '',
    message: ''
  });
  */

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
    try {
      userInfo.ProcClss = '3';
      userInfo.AplcClss = '';

      const issueCardResult = await issueCard(userInfo);
      console.log(issueCardResult);


      hideDialog();
      showDialog(
        '신청 결과',
        `\n성공여부: ${issueCardResult.STATUS.success}\n메세지:${issueCardResult.STATUS.message}`,
        () => {
         // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
        }
      )
    } catch (e) {
      hideDialog();
      showToast(e.message, 'error');
    };
  }


  
  

  const isEmpty = (input: string) => {
    return input === '';
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="발급 취소 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
         <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="신청 카드번호"
                margin="dense"
                name="AplcCardNo"
                onChange={handleChange}
                required
                value={userInfo.AplcCardNo}
                variant="outlined"
                error={isEmpty(userInfo.AplcCardNo)}
                helperText={isEmpty(userInfo.AplcCardNo) ? 'Required' : ''}
              />
            </Grid> 
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
          //  disabled={!isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '카드 발급 취소',
              '정말 취소하시겠습니까?',
              handleConfirmAddConfirm
            )}
          >
            발급 취소 
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddCardCancelDetails;
import client from './client';
import { get } from 'lodash';

export default async (input: GetCardUserInfoInput): Promise<GetCardUserInfoReturn> => {
  console.log('cardNo:'+input.cardNo);
  console.log('csno : '+input.csno);
  console.log('hpNo:'+input.hpNo);
  
  if (!input.csno && !input.cardNo && !input.hpNo) {
    throw new Error(`회원정보 조회 실패 - 입력값이 없습니다`)
  }

  return client.post(`/root/card/cardMemberSelect`, input).then(res => {
    console.log(input)

    const cardUserInfo = get(res, 'data.CardMember');
    console.log(res)

    if (!cardUserInfo) {
      throw new Error(`회원정보 조회 실패`)
    }

    return {
      csno: cardUserInfo.userId,
      cardMemberNo: cardUserInfo.cardMemberNo,
      custKorName: cardUserInfo.userKorName,
      custEngName: cardUserInfo.userEngName,
      birthDt: cardUserInfo.birthYm,
      sexDvsnCd: cardUserInfo.sexDivisionCode,
      foreignerDvsnCd: cardUserInfo.foreignerDivisionCode,
      homeNo1: cardUserInfo.homeNo1,
      homeNo2: cardUserInfo.homeNo2,
      homeNo3: cardUserInfo.homeNo3,
      phone: `${cardUserInfo.phoneNo1}-${cardUserInfo.phoneNo2}-${cardUserInfo.phoneNo3}`,
      phoneNo1:cardUserInfo.phoneNo1,
      phoneNo2:cardUserInfo.phoneNo2,
      phoneNo3:cardUserInfo.phoneNo3,
      emalAddr: cardUserInfo.emailAddress,
      homeNewAddress: cardUserInfo.homeNewAddress,
      homeNewDetailAddress: cardUserInfo.homeNewDetailAddress,
      addr: `${cardUserInfo.homeNewAddress} ${cardUserInfo.homeNewDetailAddress}`,
      /** 청구주소구분코드 1: 자택, 2: 직장, 3: 제3청구지 => 직장, 제3청구지 주소정보컬럼이 없음
       * 자택신규주소구분코드 1:구? 2:신? => 자택주소도 신주소컬럼밖에 없음
       */
      zipCode: cardUserInfo.homeZipCode,
      cardProductCode: cardUserInfo.cardProductCode,
      curCardNo: cardUserInfo.curCardNo
    }
  }).catch(e => {
    throw e 
  });
}
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { overseasPurchasedAggrHistoryAdjustProcessMutation } from '../../lib/apolloClient/mutations';
import OverseasPurchasedAggrHistoryAdjustProcessAdjustmentPurchaseDetails from './OverseasPurchasedAggrHistoryAdjustProcessAdjustmentPurchaseDetails';
import OverseasPurchasedAggrHistoryAdjustProcessApprovalHistoryInformation from './OverseasPurchasedAggrHistoryAdjustProcessApprovalHistoryInformation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 10000,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer',
  },
}));

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  data: OverseasPurchasedAggrHistoryAdjustProcessListReturnType;
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

const OverseasPurchasedAggrHistoryAdjustProcessTable: FC<Props> = ({ data, paging, setPaging }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const adjustmentPurchaseDetails: AdjustmentPurchaseDetails = {
    purchased_process_division_code: '',
    adjust_process_reason: '',
    data_process_division_code: '',
    adjust_process_status_division_code: '',
    trade_process_code: '',
    last_operate_admin_id: '',
    last_operate_trade_code: '',
    card_no: '',
    card_no_masked: '',
    local_trade_currency_code: '',
    local_trade_amount: 0,
    sales_date: '',
    merchant_nation_code: '',
    merchant_eng_name: '',
    merchant_city_name: '',
    account_division_code: '',
    sales_kind_division_code: '',
    purchased_process_no: '',
    bc_sales_division_code: '',
    approval_date: '',
    overseas_card_approval_no: '',
    trade_approval_seq: '',
    trade_date: '',
    overseas_cdsl_usd_amount: 0,
    overseas_cdsl_fee_usd_amount: 0,
    overseas_cdsl_fee_krw_amount: 0,
    overseas_settlement_usd_amount: 0,
    overseas_cash_usd_amount: 0,
    overseas_cash_fee_usd_amount: 0,
    overseas_cash_fee_krw_amount: 0,
    overseas_settlement_krw_amount: 0,
    purchased_apply_exchange_rate: '',
    deal_base_exchange_rate: '',
    purchased_usd_origin_amount: 0,
    approval_request_usd_origin_amount: 0,
    overseas_use_fee_base_usd_amount: 0,
  };

  const approvalHistoryInformations: ApprovalHistoryInformations = {
    trade_approval_seq: '',
    trade_date: '',
    card_seq: '',
    sales_kind_division_code: '',
    approval_date: '',
    overseas_card_approval_no: '',
    card_no: '',
    card_no_masked: '',
    overseas_approval_use_no: '',
    mcc_merchant_code: '',
    issuer_currency_code: '',
    payment_currency_code: '',
    local_trade_amount: 0,
    bc_nation_no: '',
    approval_city_name: '',
    merchant_eng_name: '',
    brand_division_code: '',
    text_approval_request_usd_amount: 0,
    brand_fee_calcu_division_code: '',
    origin_approval_cancel_division_code: '',
    approval_cancel_date: '',
    approval_request_usd_amount: 0,
    approval_request_usd_origin_amount: 0,
    overseas_use_fee_impose_base_amount: 0,
    overseas_brand_usd_fee: 0,
    overseas_brand_krw_fee: 0,
    overseas_use_usd_fee: 0,
    overseas_use_krw_fee: 0,
    overseas_withdrawal_usd_fee: 0,
    overseas_withdrawal_krw_fee: 0,
    request_withdrawal_usd_amount: 0,
    real_withdrawal_krw_amount: 0,
    approval_apply_exchange_rate: '',
    account_process_seq: '',
  };

  let list = data === undefined ? [] : data['list'];
  let totalCount = data === undefined ? 0 : data['totalCount'];

  const handleDetailClick = (receve_seq: String, merchant_eng_name: String, receve_base_date: String) => {
    fetch(receve_seq, merchant_eng_name, receve_base_date);
  };
  const [adjustmentPurchaseDetail, setAdjustmentPurchaseDetails] = useState(adjustmentPurchaseDetails);
  const [approvalHistoryInformation, setApprovalHistoryInformations] = useState(approvalHistoryInformations);

  const fetch = async (receve_seq: String, merchant_eng_name: String, receve_base_date: String) => {
    if (loading) {
      return;
    }
    setLoading(true);

    await apolloClient
      .mutate({
        variables: {
          receve_seq: receve_seq,
          merchant_eng_name: merchant_eng_name,
          receve_base_date: receve_base_date,
        },
        mutation:
          overseasPurchasedAggrHistoryAdjustProcessMutation.selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo,
      })
      .then(res => {
        const selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo =
          res.data.selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo;
        setAdjustmentPurchaseDetails(
          selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo.adjustmentPurchaseDetails
        );
        setApprovalHistoryInformations(
          selectOverseasPurchasedAggrHistoryAdjustmentPurchaseDetailInfo.approvalHistoryInformations
        );
      })
      .catch(console.error);
    setLoading(false);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    setPaging({
      ...paging,
      page: inPage,
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value),
    });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    <TableCell align="center">수신기준일자</TableCell>
                    <TableCell align="center">영문가맹점명</TableCell>
                    <TableCell align="center">해외신판미화금액</TableCell>
                    <TableCell align="center">해외신판수수료미화금액</TableCell>
                    <TableCell align="center">해외신판수수료원화금액</TableCell>
                    <TableCell align="center">해외현금미화금액</TableCell>
                    <TableCell align="center">해외현금수수료미화금액</TableCell>
                    <TableCell align="center">해외정산미화금액</TableCell>
                    <TableCell align="center">해외정산원화금액</TableCell>
                    <TableCell align="center">매입미화원금금액</TableCell>
                    <TableCell align="center">승인요청미화원금금액</TableCell>
                    <TableCell align="center">해외이용수수료기준미화금액</TableCell>
                    <TableCell align="center">승인적용환율</TableCell>
                    <TableCell align="center">매입적용환율</TableCell>
                    <TableCell align="center">매매기준환율</TableCell>
                    <TableCell align="center">청구일자</TableCell>
                    <TableCell align="center">리버설여부</TableCell>
                    <TableCell align="center">해외이용수수료기준미화금액</TableCell>
                    <TableCell align="center">현지거래통화코드</TableCell>
                    <TableCell align="center">거래통화구분명</TableCell>
                    <TableCell align="center">해외BC매입순번</TableCell>
                    <TableCell align="center">ONUS구분코드</TableCell>
                    <TableCell align="center">리볼빙구분코드</TableCell>
                    <TableCell align="center">리볼빙번호</TableCell>
                    <TableCell align="center">현지거래금액</TableCell>
                    <TableCell align="center">가맹점도시명</TableCell>
                    <TableCell align="center">MCC가맹점코드</TableCell>
                    <TableCell align="center">승인일자</TableCell>
                    <TableCell align="center">해외카드승인번호</TableCell>
                    <TableCell align="center">거래승인순번</TableCell>
                    <TableCell align="center">거래일자</TableCell>
                    <TableCell align="center">해외이용원화수수료</TableCell>
                    <TableCell align="center">해외ATM거래원화수수료</TableCell>
                    <TableCell align="center">체크매출구분코드</TableCell>
                    <TableCell align="center">정부구매카드구분코드</TableCell>
                    <TableCell align="center">전문브랜드미화수수료</TableCell>
                    <TableCell align="center">매입브랜드미화수수료</TableCell>
                    <TableCell align="center">매입브랜드원화수수료</TableCell>
                    <TableCell align="center">매입해외이용미화수수료</TableCell>
                    <TableCell align="center">매입해외이용원화수수료</TableCell>
                    <TableCell align="center">매입해외출금미화수수료</TableCell>
                    <TableCell align="center">매입해외출금원화수수료</TableCell>
                    <TableCell align="center">승인브랜드미화수수료</TableCell>
                    <TableCell align="center">승인브랜드원화수수료</TableCell>
                    <TableCell align="center">승인해외이용미화수수료</TableCell>
                    <TableCell align="center">승인해외이용원화수수료</TableCell>
                    <TableCell align="center">승인해외출금미화수수료</TableCell>
                    <TableCell align="center">승인해외출금원화수수료</TableCell>
                    <TableCell align="center">승인요청인출미화금액</TableCell>
                    <TableCell align="center">승인실인출원화금액</TableCell>
                    <TableCell align="center">원매입접수번호</TableCell>
                    <TableCell align="center">무승인매입구분코드</TableCell>
                    <TableCell align="center">매입처리구분코드</TableCell>
                    <TableCell align="center">강제매입여부</TableCell>
                    <TableCell align="center">원승인원장존재여부</TableCell>
                    <TableCell align="center">현지차이금액</TableCell>
                    <TableCell align="center">매입미화금액</TableCell>
                    <TableCell align="center">매입원화금액</TableCell>
                    <TableCell align="center">인출미화차이금액</TableCell>
                    <TableCell align="center">인출원화차이금액</TableCell>
                    <TableCell align="center">계좌부서식별자</TableCell>
                    <TableCell align="center">입출금처리코드</TableCell>
                    <TableCell align="center">입출미화금액</TableCell>
                    <TableCell align="center">입출원화금액</TableCell>
                    <TableCell align="center">입출금처리일련번호</TableCell>
                    <TableCell align="center">자료처리구분코드</TableCell>
                    <TableCell align="center">해외매입에러사유</TableCell>
                    <TableCell align="center">환율손익구분코드</TableCell>
                    <TableCell align="center">매입환율차이금액</TableCell>
                    <TableCell align="center">거래유형코드</TableCell>
                    <TableCell align="center">조정처리상태구분코드</TableCell>
                    <TableCell align="center">조정처리사유</TableCell>
                    <TableCell align="center">최종조작거래코드</TableCell>
                    <TableCell align="center">최종조작직원번호</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list
                    .slice(paging.rowsPerPage * paging.page, paging.rowsPerPage * (paging.page + 1))
                    .map((item, index) => (
                      <TableRow
                        hover
                        key={index}
                        className={classes.cursor_pointer}
                        onClick={() =>
                          handleDetailClick(item.receve_seq, item.merchant_eng_name, item.receve_base_date)
                        }
                      >
                        <TableCell align="center">
                          {paging.rowsPerPage * (paging.page + 1) - (paging.rowsPerPage - (index + 1))}
                        </TableCell>
                        <TableCell align="center">
                          {item.receve_base_date == null ? 0 : chaiStringUtils.dateFormatDefault(item.receve_base_date)}
                        </TableCell>
                        <TableCell align="center">
                          {item.merchant_eng_name == null ? 0 : item.merchant_eng_name}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_cdsl_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_cdsl_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_cdsl_fee_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_cdsl_fee_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.overseas_cdsl_fee_krw_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_cdsl_fee_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_cash_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_cash_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_cash_fee_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_cash_fee_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_settlement_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_settlement_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.overseas_settlement_krw_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_settlement_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.purchased_usd_origin_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_usd_origin_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.approval_request_usd_origin_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_request_usd_origin_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.overseas_use_fee_base_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_use_fee_base_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.approval_apply_exchange_rate == null ? 0 : item.approval_apply_exchange_rate}
                        </TableCell>
                        <TableCell align="center">
                          {item.purchased_apply_exchange_rate == null ? 0 : item.purchased_apply_exchange_rate}
                        </TableCell>
                        <TableCell align="center">
                          {item.deal_base_exchange_rate == null ? 0 : item.deal_base_exchange_rate}
                        </TableCell>
                        <TableCell align="center">
                          {item.bill_date == null ? 0 : chaiStringUtils.dateFormatDefault(item.bill_date)}
                        </TableCell>
                        <TableCell align="center">{item.reversal_yn == null ? 0 : item.reversal_yn}</TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.overseas_trade_krw_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_trade_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.local_trade_currency_code == null ? 0 : item.local_trade_currency_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.local_trade_currency_code == null ? 0 : item.local_trade_currency_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.overseas_bc_purchased_seq == null ? 0 : item.overseas_bc_purchased_seq}
                        </TableCell>
                        <TableCell align="center">
                          {item.onus_division_code == null ? 0 : item.onus_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.revolving_division_code == null ? 0 : item.revolving_division_code}
                        </TableCell>
                        <TableCell align="center">{item.revolving_no == null ? 0 : item.revolving_no}</TableCell>
                        <TableCell align="center">
                          {item.local_trade_amount == null ? 0 : chaiStringUtils.moneyFormat(item.local_trade_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.merchant_city_name == null ? 0 : item.merchant_city_name}
                        </TableCell>
                        <TableCell align="center">
                          {item.mcc_merchant_code == null ? 0 : item.mcc_merchant_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.approval_date == null ? 0 : chaiStringUtils.dateFormatDefault(item.approval_date)}
                        </TableCell>
                        <TableCell align="center">
                          {item.overseas_card_approval_no == null ? 0 : item.overseas_card_approval_no}
                        </TableCell>
                        <TableCell align="center">
                          {item.trade_approval_seq == null ? 0 : item.trade_approval_seq}
                        </TableCell>
                        <TableCell align="center">
                          {item.trade_date == null ? 0 : chaiStringUtils.dateFormatDefault(item.trade_date)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.overseas_use_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_use_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.overseas_atm_trade_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.overseas_atm_trade_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          {item.check_sales_division_code == null ? 0 : item.check_sales_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.government_purchase_card_division_code == null
                            ? 0
                            : item.government_purchase_card_division_code}
                        </TableCell>
                        <TableCell align="center">
                          $ {item.text_brand_usd_fee == null ? 0 : chaiStringUtils.moneyFormat(item.text_brand_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.purchased_brand_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_brand_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.purchased_brand_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_brand_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.purchased_overseas_use_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_overseas_use_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.purchased_overseas_use_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_overseas_use_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.purchased_overseas_withdrawal_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_overseas_withdrawal_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.purchased_overseas_withdrawal_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_overseas_withdrawal_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.approval_brand_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_brand_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.approval_brand_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_brand_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.approval_overseas_use_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_overseas_use_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.approval_overseas_use_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_overseas_use_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.approval_overseas_withdrawal_usd_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_overseas_withdrawal_usd_fee)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.approval_overseas_withdrawal_krw_fee == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_overseas_withdrawal_krw_fee)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.approval_request_withdrawal_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_request_withdrawal_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.approval_real_withdrawal_krw_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.approval_real_withdrawal_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.origin_receve_no == null ? 0 : item.origin_receve_no}
                        </TableCell>
                        <TableCell align="center">
                          {item.no_approval_purchased_division_code == null
                            ? 0
                            : item.no_approval_purchased_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.purchased_process_division_code == null ? 0 : item.purchased_process_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.forced_purchased_yn == null ? 0 : item.forced_purchased_yn}
                        </TableCell>
                        <TableCell align="center">
                          {item.origin_approval_ledger_exist_yn == null ? 0 : item.origin_approval_ledger_exist_yn}
                        </TableCell>
                        <TableCell align="center">
                          {item.origin_approval_ledger_exist_yn == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.local_diff_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.purchased_usd_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.purchased_krw_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          ${' '}
                          {item.withdrawal_usd_diff_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.withdrawal_usd_diff_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.withdrawal_krw_diff_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.withdrawal_krw_diff_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.account_department_identifier == null ? 0 : item.account_department_identifier}
                        </TableCell>
                        <TableCell align="center">
                          {item.inout_process_code == null ? 0 : item.inout_process_code}
                        </TableCell>
                        <TableCell align="center">
                          $ {item.inout_usd_amount == null ? 0 : chaiStringUtils.moneyFormat(item.inout_usd_amount)}
                        </TableCell>
                        <TableCell align="center">
                          &#8361;{' '}
                          {item.inout_krw_amount == null ? 0 : chaiStringUtils.moneyFormat(item.inout_krw_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.inout_process_seq == null ? 0 : item.inout_process_seq}
                        </TableCell>
                        <TableCell align="center">
                          {item.data_process_division_code == null ? 0 : item.data_process_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.overseas_purchased_error_reason == null ? 0 : item.overseas_purchased_error_reason}
                        </TableCell>
                        <TableCell align="center">
                          {item.exchange_rate_pfls_division_code == null ? 0 : item.exchange_rate_pfls_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.purchased_exchange_rate_diff_amount == null
                            ? 0
                            : chaiStringUtils.moneyFormat(item.purchased_exchange_rate_diff_amount)}
                        </TableCell>
                        <TableCell align="center">
                          {item.trade_process_code == null ? 0 : item.trade_process_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.adjust_process_status_division_code == null
                            ? 0
                            : item.adjust_process_status_division_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.adjust_process_reason == null ? 0 : item.adjust_process_reason}
                        </TableCell>
                        <TableCell align="center">
                          {item.last_operate_trade_code == null ? 0 : item.last_operate_trade_code}
                        </TableCell>
                        <TableCell align="center">
                          {item.last_operate_admin_id == null ? 0 : item.last_operate_admin_id}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <div className={classes.content}>
        <OverseasPurchasedAggrHistoryAdjustProcessAdjustmentPurchaseDetails
          title="조정매입내역정보"
          adjustmentPurchaseDetails={adjustmentPurchaseDetail}
        />
      </div>
      <div className={classes.content}>
        <OverseasPurchasedAggrHistoryAdjustProcessApprovalHistoryInformation
          title="승인내역정보"
          approvalHistoryInformations={approvalHistoryInformation}
        />
      </div>
    </Card>
  );
};

export default OverseasPurchasedAggrHistoryAdjustProcessTable;

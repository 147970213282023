import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';

import BatchProcessHistory from './BatchProcessHistory';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import apolloClient from '../../lib/apolloClient';
import { batchInfoMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { 
  statusCount: number,
  setStatusCount: React.Dispatch<React.SetStateAction<number>>,
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  batchlist: BatchInfo[],
  setBatchInfo: React.Dispatch<React.SetStateAction<BatchInfo>> 
}

const BatchInfoTable: FC<Props> = ({ statusCount, setStatusCount, setUpdateClick, paging, setPaging, batchlist, setBatchInfo }) => {
  const classes = useStyles();

  let totalCount = 0;
  const [batchSeqNo, setBatchSeqNo] = useState(0);
  const [batchProcessHistoryOpen, setBatchProcessHistoryOpen] = React.useState(false);
  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
    // setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
    // setRowsPerPage(Number(event.target.value));
  };

  const setTotalCount = (value: number) => {
    totalCount = value;
  }

  const handleConfirm = async (seqNo : number) => {
    try {
      console.log('>>>>>>>', seqNo);
      await apolloClient.mutate({
        variables: {
          batchSeqNo: seqNo},
          mutation: batchInfoMutation.updateProcessStatus
      }).then(res => {     
      console.log(res.data);
      setStatusCount(statusCount+1);
      hideDialog();
      showDialog(
        '상태변경',
        '처리되었습니다.\n\n',
        () => {
        // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
          }
      )         
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
  }

  const displayProcessStatus = (processStatus:string, seqNo:number) => {
    
    if (processStatus && processStatus !== '오류') {
      console.log('#####', batchSeqNo);
      return (<Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={(e) => {e.stopPropagation(); showDialog(
          '상태변경',
          '변경처리 하시겠습니까?',
          () => handleConfirm(seqNo)
        )}}
        >
         상태값 변경 
      </Button>);
    }
    return;
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>NO.</TableCell>
                  <TableCell align='center'>배치ID</TableCell>
                  <TableCell align='center'>배치명</TableCell>
                  <TableCell align='center'>최종처리일자</TableCell>
                  <TableCell align='center'>처리상태</TableCell>
                  <TableCell align='center'>상태변경</TableCell>
                  <TableCell align='center'>처리내역</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batchlist.slice(0, batchlist.length).map((item, index) => (
                  <TableRow onClick={() => {setBatchInfo(item); setUpdateClick(true);}}
                    hover
                    key={item.batchSeqNo}
                  >
                    <TableCell align='center'>{setTotalCount(item.totalCount)}{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align='left'>{item.batchId}</TableCell>
                    <TableCell align='left'>{item.batchName}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.dateFormatDefault(item.processDate)}</TableCell>
                    <TableCell align='center'>{item.processStatus}</TableCell>
                    <TableCell align='center'>{displayProcessStatus(item.processStatus, item.batchSeqNo)}</TableCell>
                    <TableCell align='center'>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {e.stopPropagation(); setBatchProcessHistoryOpen(true); setBatchSeqNo(item.batchSeqNo);}}
                      >
                         처리내역  
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <BatchProcessHistory batchSeqNo={batchSeqNo} batchProcessHistoryOpen={batchProcessHistoryOpen} setBatchProcessHistoryOpen={setBatchProcessHistoryOpen}/>
    </Card>
  );
};

export default BatchInfoTable;

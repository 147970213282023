import React, { useState } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { RouteComponentProps } from "react-router";

import { CardInfo, UserInfo, ApprovalList, AccidentList } from '../../components';
import { default as merchants } from '../MerchantList/merchants'
import { getCardList, getCardUserInfo, getApprovalList, getAccidentList } from '../../lib/cardApi'

import CardDetailToolbar from './CardDetailToolbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type MatchParams = { cardNumber: string }
const CardDetail: React.SFC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { cardNumber } = match.params;
  const classes = useStyles();

  const [cardUserInfo, setCardUserInfo] = useState({
    csno: '',
    cardMemberNo: '',
    custKorName: '',
    custEngName: '',
    birthDt: '',
    sexDvsnCd: '',
    foreignerDvsnCd: '',
    homeNo1: '',
    homeNo2: '',
    homeNo3: '',
    phone: '',
    phoneNo1: '',
    phoneNo2: '',
    phoneNo3: '',
    //emalAddr: '',
    addr: '',
    homeNewAddress: '',
    homeNewDetailAddress: '',
    zipCode: '',
    cardProductCode: '',
    curCardNo: ''
  });

  const [cardInfo, setCardInfo] = useState({
    cardNo: '',
    prdtName: '',
    valdTermYm: '',
    isucDt: '',
    cardStat: '',
    lastCardYn: '',
    acdtStatDvsnCd: '',

    /**getCardList.d.ts 인터페이스에 선언한대로 초기화해주어야함-! */
    userId: '',
    cardOwnrName: '',
    terminateDate: '',
  });

  const [approvals, setApprovals] = useState<GetApprovalListReturn[]>([]);
  const [accidents, setAccidents] = useState<GetAccidentListReturn[]>([]);

  const [isCardDetailsVisible, setIsCardDetailsVisible] = useState(false);

  const refreshCardInfo = async (cardNumber: string) => {
    const cardInfoResult = await getCardList({ type: 'detail', cardNo: cardNumber });
    setCardInfo({
      ...cardInfo,
      ...cardInfoResult
    })
  }

  const refreshCardUserInfo = async (cardNumber: string) => {
    const cardUserInfoResult = await getCardUserInfo({ cardNo: cardNumber });
    setCardUserInfo({
      ...cardUserInfo,
      ...cardUserInfoResult
    })
  }

  const refreshApprovals = async (cardNumber: string) => {
    const approvalListResult = await getApprovalList({
      cardNo: cardNumber,
      fromApvlDt: '19000101',
      toApvlDt: '99991231'
    });
    setApprovals(approvalListResult);
  }

  const refreshAccidents = async (cardNumber: string) => {
    const accidentListResult = await getAccidentList({
      cardNo: cardNumber,
    });
    setAccidents(accidentListResult);
  }

  return (
    <div className={classes.root}>
      <CardDetailToolbar
        cardNumber={cardNumber}
        isCardDetailsVisible={isCardDetailsVisible}
        setIsCardDetailsVisible={setIsCardDetailsVisible}
        refreshCardInfo={refreshCardInfo}
        refreshCardUserInfo={refreshCardUserInfo}
        refreshApprovals={refreshApprovals}
        refreshAccidents={refreshAccidents}
      />
      { isCardDetailsVisible &&
      <div className={classes.content}>
        <Grid
          container
          spacing={4}
        >
         <Grid
            item
            md={5}
            xs={12}
          >
            <CardInfo cardInfo={cardInfo} setCardInfo={setCardInfo} merchants={merchants} cardUserInfo={cardUserInfo} refreshCardInfo={refreshCardInfo} refreshApprovals={refreshApprovals} refreshAccidents={refreshAccidents}/>
          </Grid> 
          
          <Grid
            item
            md={4}
            xs={12}
          >
            <UserInfo cardUserInfo={cardUserInfo} setCardUserInfo={setCardUserInfo}/>
          </Grid>
                    
          <Grid 
            item
            md={12}
            xs={12}
          >
           <ApprovalList approvals={approvals} cardInfo={cardInfo} refreshApprovals={refreshApprovals}/>
          </Grid>
          
          <Grid
            item
            md={12}
            xs={12}
          >
            <AccidentList accidents={accidents} cardStatus={cardInfo.cardStat} refreshAccidents={refreshAccidents}/>
          </Grid>
        </Grid>
      </div>
      }
    </div>
  );
};

export default CardDetail;

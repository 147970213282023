import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogActions,
  TextField
} from '@material-ui/core'
import { useDialog } from '../../contexts/Dialog';
import apolloClient, { overseasApprListMutation, overseasApprHistoryLogMutation } from '../../lib/apolloClient';
import chaiStringUtils from '../../common/chaiStringUtils';
import { useToast } from '../../contexts/Toast';
import { chaiPaymentCancel, chaiPaymentId, chaiPaymentStatus } from '../../lib/chaiAPI';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee'
  }, 
  tdStyle: {
    border: '1px solid #eeeeee',
    minWidth: '370px'
  }
}));

type overseasApprRefundInfo = {
  tradeDate: string,
  tradeApprovalSeq: number,
  salesKindDivisionCode: string,
  cardNo: string,
  approvalDate: string,
  approvalTime: string,
  overseasCardApprovalNo: string,
  requestWithdrawalUsdAmount : number,
  realWithdrawalKrwAmount: number,
  overseasMerchantNo: string,
  merchantEngName: string,
  approvalProcessStatusCode: string,
  userId: string,
  amdinErrorCode: string,
  originTradeDate: string,
  originTradeApprovalSeq: number,
  originApprovalProcessStatusCode: string,
  inoutProcessSeq: string,
  paymentId: string,
  accountProcessSeq: number,
  cancelAfterUsdBalance : number,
  approvalCancelStatusDivisionCode : string,
  originCancelApplyUsdBalance : number,
  originCancelApplyKrwBalance : number
}

type Props = {
  refundReqInfo: overseasApprRefundInfo,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  isOverseasApprRefundPopOpen: boolean,
  setOverseasApprRefundPopOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OverseasApprRefundPop: FC<Props> = ({refundReqInfo, refundCount, setRefundCount, isOverseasApprRefundPopOpen, setOverseasApprRefundPopOpen}) => {
  const classes = useStyles();

  const [overseasApprRefundInfo, setOverseasApprRefundInfo] = useState(refundReqInfo);
  const [paymentStatus, setPaymentStatus] = useState('notfound');
  const [isApprovalCnclExist, setIsApprovalCnclExist] = useState(false);
  const [isError, setIsError] = useState(false);

  const {showDialog, hideDialog} = useDialog();
  const { showToast } = useToast();
  
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundReqInfo]);

  const fetch = async () => {//승인취소 => 원승인 정보 가져오기
    setOverseasApprRefundInfo({
      ...refundReqInfo
    });

    let idempotencyKey = '';
    let paymentId = '';
    let originApproval:any;

    // 승인의 경우
    if (refundReqInfo.salesKindDivisionCode === '05' || refundReqInfo.salesKindDivisionCode === '06') {
      if (refundReqInfo.inoutProcessSeq) {
        idempotencyKey = refundReqInfo.inoutProcessSeq;
      }
      else {
        idempotencyKey = 'OA'+ refundReqInfo.originTradeDate + refundReqInfo.originTradeApprovalSeq.toString().padStart(16, '0');
      }
      paymentId = refundReqInfo.paymentId;    
    }    
    else if(refundReqInfo.salesKindDivisionCode === '15' || refundReqInfo.salesKindDivisionCode === '16'){
      await apolloClient.mutate({
        variables: {
          cardNo: refundReqInfo.cardNo,
          approvalDate: refundReqInfo.approvalDate,
          overseasCardApprovalNo: refundReqInfo.overseasCardApprovalNo
        },
        mutation: overseasApprListMutation.selectOverseasApprOrigin
      }).then(res => {
        console.log("*** 원승인정보조회 결과 ***");
        console.log(res.data.selectOverseasApprOrigin);
        originApproval = res.data.selectOverseasApprOrigin
        if (originApproval.inoutProcessSeq) {
          idempotencyKey = originApproval.inoutProcessSeq;
        }
        else {
          idempotencyKey = 'OA'+ originApproval.tradeDate + originApproval.tradeApprovalSeq.toString().padStart(16, '0');
        }
        paymentId = originApproval.paymentId; 
      }).catch(e => {
        console.log("*** 원승인정보조회 에러 ***");
        console.log(e);
        setIsError(true);
        showToast(e.message, 'error');
      });

      // 승인취소 정상내역이 존재하는지 확인
      await apolloClient.mutate({
        variables: {
          cardNo:refundReqInfo.cardNo,
          approvalDate : refundReqInfo.approvalDate,
          cardApprovalNo: refundReqInfo.overseasCardApprovalNo,
          cancelAfterUsdBalance : refundReqInfo.cancelAfterUsdBalance},
        mutation: overseasApprListMutation.selectOverseasApprCncl
      }).then(res => {  
        console.log('selectApprovalCncl=',res.data.selectOverseasApprCncl);
        const approvalCncl = res.data.selectOverseasApprCncl;
        if (approvalCncl) {
          setIsApprovalCnclExist(true);
        }
      }).catch(e => {
        setIsError(true);
        console.log('error', e);
        showToast(e.message, 'error');
      });  
    }

    const refundReq = {} as ChaiMoneyRefundReq;
    refundReq.idempotencyKey = idempotencyKey;

    // paymentId가 세팅되지 않은 경우 idempotencyKey를 이용하여 결제상태를 조회
    try {
      // 차이머니 결제ID조회
      if (refundReqInfo.originTradeDate && !paymentId) {
        const chaiRes = await chaiPaymentId(refundReq);
        console.log('chaiRes>>>>', chaiRes);
        paymentId = chaiRes.paymentId;
        setPaymentStatus(chaiRes.status);
      }
    }
    catch (e) {
      console.log(e);
      setIsError(true);
      hideDialog();
      showToast(e.message, 'error');
    }
    // paymentId가 세팅되어온 경우의 결제상태를 구한다.
    refundReq.paymentId = paymentId;
    if (refundReqInfo.paymentId) {
      try {
        if (refundReqInfo.originTradeDate) {
          const chaiRes = await chaiPaymentStatus(refundReq);
          console.log('chaiRes', chaiRes);
          setPaymentStatus(chaiRes.status);
        }          
      }
      catch (e) {
        console.log(e);
        setIsError(true);
        hideDialog();
        showToast(e.message, 'error');
      }
    }
    if (refundReqInfo.salesKindDivisionCode === '05' || refundReqInfo.salesKindDivisionCode === '06') {    
      setOverseasApprRefundInfo({
        ...refundReqInfo,
        paymentId : paymentId
      }); 
    }
    else if ((refundReqInfo.salesKindDivisionCode === '15' || refundReqInfo.salesKindDivisionCode === '16') && originApproval) {  
      setOverseasApprRefundInfo({
        ...refundReqInfo,
        originTradeDate: originApproval.tradeDate,
        originTradeApprovalSeq: originApproval.tradeApprovalSeq,
        originApprovalProcessStatusCode: originApproval.approvalProcessStatusCode,
        inoutProcessSeq: originApproval.inoutProcessSeq,
        paymentId: originApproval.paymentId
      });      
    }      

  }

  //[입금처리]
  const handleConfirmRefund = async () => {
    try {
      if(!overseasApprRefundInfo.paymentId){
        hideDialog();
        showToast('paymentId를 입력하세요!', 'error');
        return;
      }
      if(overseasApprRefundInfo.paymentId){
        const refundReq = {} as ChaiMoneyRefundReq;
        refundReq.paymentId = overseasApprRefundInfo.paymentId;
        if(overseasApprRefundInfo.inoutProcessSeq){
          refundReq.idempotencyKey = overseasApprRefundInfo.inoutProcessSeq;
        }else{
          refundReq.idempotencyKey = 'OA' + overseasApprRefundInfo.originTradeDate + overseasApprRefundInfo.originTradeApprovalSeq.toString().padStart(16,'0');
        }
        refundReq.checkoutAmount = overseasApprRefundInfo.realWithdrawalKrwAmount;
        refundReq.cancelAmount = overseasApprRefundInfo.realWithdrawalKrwAmount;// 승인은 부분취소가 없으므로 => 원승인금액 이용
        refundReq.merchantNo = overseasApprRefundInfo.overseasMerchantNo;
        refundReq.merchantName = overseasApprRefundInfo.merchantEngName;
      
        let paymentId = refundReq.paymentId;
        if(paymentStatus !== 'canceled'){// 차이머니 결제상태가 canceled가 아닐때 
          const result = await chaiPaymentCancel(refundReq);// 취소 API 호출(차이머니 환불 호출)
          paymentId = result.paymentId;
        }

        await apolloClient.mutate({
          variables: {
            tradeDate: overseasApprRefundInfo.tradeDate,
            tradeApprovalSeq: overseasApprRefundInfo.tradeApprovalSeq,
            salesKindDivisionCode: overseasApprRefundInfo.salesKindDivisionCode,
            approvalCancelStatusDivisionCode:overseasApprRefundInfo.approvalCancelStatusDivisionCode,
            approvalDate:overseasApprRefundInfo.approvalDate,
            approvalTime:overseasApprRefundInfo.approvalTime,
            originTradeDate: overseasApprRefundInfo.originTradeDate,
            originTradeApprovalSeq: overseasApprRefundInfo.originTradeApprovalSeq,
            cardNo: overseasApprRefundInfo.cardNo,
            userId: overseasApprRefundInfo.userId,
            realWithdrawalKrwAmount: overseasApprRefundInfo.realWithdrawalKrwAmount,
            merchantEngName: overseasApprRefundInfo.merchantEngName,
            refundProcessSeq: refundReq.idempotencyKey,
            refundPaymentId: paymentId,
            accountProcessSeq: overseasApprRefundInfo.accountProcessSeq
          },
          mutation: overseasApprHistoryLogMutation.updateOverseasApprHistoryLogRefund
        }).then(res => {
          console.log("*** 입금처리업데이트 결과 ***");
          console.log(res.data.updateOverseasApprHistoryLogRefund);
          setRefundCount(refundCount+1);
          hideDialog();
          showDialog(
            '승인상태확인',
            '입금환불 처리되었습니다.\n\n',
            () => {hideDialog();}
          );
        }).catch(e => {
          console.log("*** 입금처리업데이트 에러 ***");
          console.log(e);
          hideDialog();
          showToast(e.message, 'error');
        });
      }
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setOverseasApprRefundPopOpen(false);
  }

  //[부분취소 상태변경처리]
  const handleConfirmPartialCancel = async () => {
    
    await apolloClient.mutate({
      variables: {
        tradeDate: overseasApprRefundInfo.tradeDate,
        tradeApprovalSeq: overseasApprRefundInfo.tradeApprovalSeq,
        salesKindDivisionCode: overseasApprRefundInfo.salesKindDivisionCode,
        approvalCancelStatusDivisionCode:overseasApprRefundInfo.approvalCancelStatusDivisionCode,
        approvalDate:overseasApprRefundInfo.approvalDate,
        approvalTime:overseasApprRefundInfo.approvalTime,
        originTradeDate: overseasApprRefundInfo.originTradeDate,
        originTradeApprovalSeq: overseasApprRefundInfo.originTradeApprovalSeq,
        originCancelApplyUsdBalance: overseasApprRefundInfo.originCancelApplyUsdBalance,
        originCancelApplyKrwBalance: overseasApprRefundInfo.originCancelApplyKrwBalance,
        requestWithdrawalUsdAmount : overseasApprRefundInfo.requestWithdrawalUsdAmount,
        realWithdrawalKrwAmount: overseasApprRefundInfo.realWithdrawalKrwAmount,
        cancelAfterUsdBalance: overseasApprRefundInfo.cancelAfterUsdBalance
      },
      mutation: overseasApprHistoryLogMutation.updateOverseasApprHistoryLogPartialCancel
    }).then(res => {
      console.log("*** 상태변경 결과 ***");
      console.log(res.data.updateOverseasApprHistoryLogPartialCancel);
      setRefundCount(refundCount+1);
      hideDialog();
      showDialog(
        '승인상태확인',
        '처리되었습니다.\n\n',
        () => {hideDialog();}
      );
    }).catch(e => {
      console.log("*** 상태변경 에러 ***");
      console.log(e);
      hideDialog();
      showToast(e.messasge, 'error');
    });
    setOverseasApprRefundPopOpen(false);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOverseasApprRefundInfo({
      ...overseasApprRefundInfo,
      [e.target.name]: e.target.value
    });
  }

  const handleOverseasApprRefundPopClose = () => {
    setOverseasApprRefundPopOpen(false);
  }

  const isUpdStatusDisabled = () => {

    let isUpdStatus = true;
  
    if (isError) {
      isUpdStatus = false;
    }
    else {
      if (overseasApprRefundInfo.salesKindDivisionCode === '05' || overseasApprRefundInfo.salesKindDivisionCode === '06') {
        if (paymentStatus === 'confirmed') {
          isUpdStatus = false;
        }
      }
      else if (overseasApprRefundInfo.salesKindDivisionCode === '15' || overseasApprRefundInfo.salesKindDivisionCode === '16') {
        // 원승인이 정상이고 승인취소 정상내역이 존재하지 않으면 상태버튼 disabled
        if (overseasApprRefundInfo.originApprovalProcessStatusCode === '1' && !isApprovalCnclExist) {
            isUpdStatus = false;
        }
      }
    }
    return isUpdStatus;
  };


  const isDisabled = () => {// 승인취소 && (오류,실패), 승인취소 && 머니번호x => 입금처리 불가, 상태변경 불가
    let isRefund = true;

    if (isError) {
      isRefund = false;
    }
    else {
      if (overseasApprRefundInfo.salesKindDivisionCode === '05' || overseasApprRefundInfo.salesKindDivisionCode === '06') {
        if (paymentStatus !== 'confirmed') {
          isRefund = false;
        }
      }
      else if(overseasApprRefundInfo.salesKindDivisionCode === '15' || overseasApprRefundInfo.salesKindDivisionCode === '16'){
        if(overseasApprRefundInfo.originApprovalProcessStatusCode !== '1'){
          isRefund = false;
        }
        if(!overseasApprRefundInfo.paymentId){
          isRefund = false;
        }
        // 승인취소 정상내역이 존재하면 환불처리하지 않고 상태만 변경
        if (isApprovalCnclExist) {
          isRefund = false;
        }
        // 당일/익일 전액취소(1/2), 장기미매입부분취소(5)가 아닌 경우는 환불하지 않는다.
        if (overseasApprRefundInfo.approvalCancelStatusDivisionCode !== '1' && 
            overseasApprRefundInfo.approvalCancelStatusDivisionCode !== '2' &&
            overseasApprRefundInfo.approvalCancelStatusDivisionCode !== '5') {
          isRefund = false;
        }        
      }
    }
    return isRefund;
  }

  const isPartialCancelStatusDisabled = () => {// 부분취소 상태변경
    let isRefund = true;

    if (isError) {
      isRefund = false;
    }
    else {
      // 원승인이 정상이 아니면 부분취소상태변경처리하지 않는다.
      if(overseasApprRefundInfo.originApprovalProcessStatusCode !== '1'){
        isRefund = false;
      }
      // 승인취소 정상내역이 존재하면 부분취소상태변경처리하지 않는다.
      if (isApprovalCnclExist) {
        isRefund = false;
      }   
    }
    return isRefund;
  }

  const displayRefundButton = () => {

    // 현재는 승인에 대해서만 망취소를 해야하는데 못한 경우만 있어서 조건을 승인이면서 오류로만 조회
    if ((overseasApprRefundInfo.salesKindDivisionCode === '05' || overseasApprRefundInfo.salesKindDivisionCode === '06') && 
          overseasApprRefundInfo.approvalProcessStatusCode === '2') {
      return (<Button
        disabled={!isUpdStatusDisabled()}
        color="primary"
        variant="contained"
        onClick={() => showDialog(
          '승인상태확인',
          '환불처리코드변경처리 하시겠습니까?',
          handleRefundConfirm
        )}
        >
        환불처리코드변경 
      </Button>);
    }
  }

  const displayPartialCancelButton = () => {

    if ((overseasApprRefundInfo.salesKindDivisionCode === '15' || overseasApprRefundInfo.salesKindDivisionCode === '16' ) &&
  (overseasApprRefundInfo.approvalCancelStatusDivisionCode === '3' || overseasApprRefundInfo.approvalCancelStatusDivisionCode === '4') &&
  !overseasApprRefundInfo.approvalProcessStatusCode) {
      return (<Button
        disabled={!isPartialCancelStatusDisabled()}
        color="primary"
        variant="contained"
        onClick={() => showDialog(
          '승인상태확인',
          '부분취소 상태변경처리 하시겠습니까?',
          handleConfirmPartialCancel
        )}
        >
        부분취소 상태변경
      </Button>);
    }
  }

  const displayButton = () => {// 승인 && 오류 => 상태변경 버튼 추가
    if(!overseasApprRefundInfo.approvalProcessStatusCode){
      return (
        <Button
          disabled={!isUpdStatusDisabled()}
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '승인상태확인',
            '상태변경처리 하시겠습니까?',
            handleConfirm
          )}
        >
          상태변경
        </Button>
      );
    }
  }

  const handleRefundConfirm = async () => {
      await apolloClient.mutate({
        variables: {
          tradeDate: overseasApprRefundInfo.tradeDate, 
          tradeApprovalSeq: overseasApprRefundInfo.tradeApprovalSeq},
          mutation: overseasApprListMutation.updateOverseasApprRefundInfo
      }).then(res => {     
      console.log(res.data);
      setRefundCount(refundCount+1); 
      hideDialog();
      showDialog(
        '승인상태확인',
        '처리되었습니다.\n\n',
        () => {
        // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
          }
      )         
      }).catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
    setOverseasApprRefundPopOpen(false);
  }

  //[상태변경] 승인내역의 처리상태를 ('') => 실패('2')로 처리
  const handleConfirm = async () => {
    await apolloClient.mutate({
      variables: {
        tradeDate: overseasApprRefundInfo.tradeDate,
        tradeApprovalSeq: overseasApprRefundInfo.tradeApprovalSeq
      }
      ,mutation:overseasApprListMutation.updateOverseasApprStatus
    }).then(res => {
      console.log("*** 상태변경 결과 ***");
      console.log(res.data.updateOverseasApprStatus);
      setRefundCount(refundCount+1);
      hideDialog();
      showDialog(
        '승인상태확인',
        '처리되었습니다.\n\n',
        () => {hideDialog();}
      );
    }).catch(e => {
      console.log("*** 상태변경 에러 ***");
      console.log(e);
      hideDialog();
      showToast(e.messasge, 'error');
    });
    setOverseasApprRefundPopOpen(false);
  }

  const onkeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key==="Enter"){
      event.preventDefault();
    }
  }

  return(
    <Dialog
      open={isOverseasApprRefundPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
        <DialogTitle id="scroll-dialog-title"><MenuTitleToolbar title="상태확인" /></DialogTitle>
        <Divider />
        <DialogContent>
          <div>
            <form
              autoComplete="off"
              noValidate
            >
              <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" className={classes.thStyle}>실인출원화금액</TableCell>
                      <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(overseasApprRefundInfo.realWithdrawalKrwAmount)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.thStyle}>입출금처리일련번호</TableCell>
                      <TableCell align="right" className={classes.tdStyle}>
                        {overseasApprRefundInfo.inoutProcessSeq? overseasApprRefundInfo.inoutProcessSeq : 
                          'OA'+overseasApprRefundInfo.originTradeDate+overseasApprRefundInfo.originTradeApprovalSeq.toString().padStart(16,'0')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.thStyle}>머니승인번호</TableCell>
                      <TableCell align="center" className={classes.tdStyle}>
                        <TextField
                          fullWidth
                          margin="dense"
                          name="paymentId"
                          variant="outlined"
                          onChange={handleChange}
                          onKeyPress={onkeyEnter}
                          value={overseasApprRefundInfo.paymentId? overseasApprRefundInfo.paymentId : ''}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
              </Table>
            </form>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          {displayRefundButton()}
          {displayButton()}
          {displayPartialCancelButton()}
          <Button
            disabled={!isDisabled()}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '승인상태확인',
              '입금처리 하시겠습니까?',
              handleConfirmRefund
            )}
          >
            입금처리
          </Button>
          <Button 
            onClick={handleOverseasApprRefundPopClose} 
            color="default"
            variant="contained"
          > 
            닫기 
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default OverseasApprRefundPop;
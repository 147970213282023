import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

import AccountProcAggrDetailListPop from './AccountProcAggrDetailListPop';
import apolloClient from '../../lib/apolloClient';
import { accountProcAggrMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type AccountProcAggrInfo = {
  accountProcessDate	              :String;     // 처리일자
  accountNo                         :String;     // 계좌(계정)번호
  accountingProcessDivisionCode     :String      // 회계처리구분
  inCount	                          :number;     // 입금건수
  inAmount	                        :number;     // 입금금액
  outCount	                        :number;     // 출금건수
  outAmount	                        :number;     // 출금금액
  totalCount	                      :number;     // 합계건수
  totalAmount	                      :number;     // 합계금액
}
type Props = { list: AccountProcAggrInfo[] }
const AccountProcAggrTable: FC<Props> = ({ list }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  // 상세팝업 오픈여부
  const [detailListPop, setDetailListPopOpen] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 입/출금/전체건수 클릭 시
  const handleInoutClick = (accountProcessDate: String, accountNo: String, inoutDivisionCode: String) => {
    // 조회
    fetch(accountProcessDate, accountNo, inoutDivisionCode);

    // 팝업 오픈
    setDetailListPopOpen(true);
  }

  // 계정처리내역 조회 
  const fetch = async (accountProcessDate: String, accountNo: String, inoutDivisionCode: String) => {
    console.log('계정처리집계 상세 목록 조회 - INPUT: ', accountProcessDate, accountNo, inoutDivisionCode);

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        accountProcessDate: accountProcessDate,
        accountNo: accountNo,
        inoutDivisionCode: inoutDivisionCode
      },
      mutation: accountProcAggrMutation.selectAccountProcAggrDetailList
    }).then(res => {
      console.log('계정처리집계 상세 목록 조회 결과: ', res.data.selectAccountProcAggrDetailList);
      setData(res.data.selectAccountProcAggrDetailList);
    }).catch(console.error);

    setLoading(false);
  }

  // 회계계정코드명
  const accountNoCodeName = new Map<String, String>();
  accountNoCodeName.set('27401', '원화체크카드기타예수금');
  accountNoCodeName.set('41300', '체크카드원화수입수수료');
  accountNoCodeName.set('13401', '체크카드관련가지급금');
  accountNoCodeName.set('83301', '체크카드캐시백(원화)');
  accountNoCodeName.set('12001', '체크카드캐시백취소미수금');
  accountNoCodeName.set('96001', '잡손실');
  accountNoCodeName.set('93001', '잡이익');
  accountNoCodeName.set('85300', '카드사고보상금');
  accountNoCodeName.set('43001067838', '가맹점대금BC계좌');

  // 회계처리구분코드
  const accountingProcessDivisionCodeName = new Map<String, String>();
  accountingProcessDivisionCodeName.set('1', '계좌');
  accountingProcessDivisionCodeName.set('2', '계정');

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">처리일자</TableCell>
                  <TableCell align="center">계좌(계정)번호</TableCell>
                  <TableCell align="center">회계처리구분</TableCell>
                  <TableCell align="center">입금건수</TableCell>
                  <TableCell align="center">입금금액</TableCell>
                  <TableCell align="center">출금건수</TableCell>
                  <TableCell align="center">출금금액</TableCell>
                  <TableCell align="center">합계건수</TableCell>
                  <TableCell align="center">합계금액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                      <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.accountProcessDate)}</TableCell>
                      <TableCell align="center">{item.accountNo} {chaiStringUtils.codeName('accountNoCodeName',item.accountNo) === undefined ? '' : '('+chaiStringUtils.codeName('accountNoCodeName',item.accountNo)+')'}</TableCell>                     
                      <TableCell align="center">{chaiStringUtils.codeName('accountingProcessDivisionCodeName',item.accountingProcessDivisionCode)}</TableCell>

                      <TableCell align="right">
                        <Tooltip title="입금건수 상세목록">
                        <a href="#!" onClick={() => handleInoutClick(item.accountProcessDate, item.accountNo, '1')}>
                          {item.inCount.toLocaleString()} 건
                        </a>
                        </Tooltip>
                      </TableCell>
                      
                      <TableCell align="right">{item.inAmount.toLocaleString()} 원</TableCell>

                      <TableCell align="right">
                        <Tooltip title="출금건수 상세목록">
                        <a href="#!" onClick={() => handleInoutClick(item.accountProcessDate, item.accountNo, '2')}>
                          {item.outCount.toLocaleString()} 건
                        </a>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">{item.outAmount.toLocaleString()} 원</TableCell>

                      <TableCell align="right">
                        <Tooltip title="총건수 상세목록">
                            <a href="#!" onClick={() => handleInoutClick(item.accountProcessDate, item.accountNo, '')}>
                              {item.totalCount.toLocaleString()} 건
                            </a>
                        </Tooltip>
                      </TableCell>
                      
                      <TableCell align="right">{item.totalAmount.toLocaleString()} 원</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <AccountProcAggrDetailListPop detailListPop={detailListPop} setDetailListPopOpen={setDetailListPopOpen} loading={loading} data={data}/>
    </Card>
  );
};

export default AccountProcAggrTable;
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightBorder: {
    borderRightWidth: 1,
    borderRightColor: '#eee',
    borderRightStyle: 'solid',
  },
}));

type Props = {
  list: HanaMerchantMappingHistoryType;
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

export const PlccMerchantMappingHistoryList: FC<Props> = ({ list: listData, paging, setPaging }) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);

  let list = listData === undefined ? [] : listData['list'] || [];
  let totalCount = listData === undefined ? 0 : listData['totalCount'] || 0;

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    setPaging({
      ...paging,
      page: inPage,
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value),
    });
  };

  useEffect(() => {
    if (scrollRef?.current) scrollRef.current.scrollTop = 0;
  }, [list]);

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {/* 상단 테이블 위쪽 + 하단 페이지부분 + 패딩부분 */}
            <TableContainer style={{ maxHeight: 'calc(100vh - 308px - 68px - 32px)' }} ref={scrollRef}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>변경 시간</TableCell>
                    <TableCell style={{ width: '360px' }}>어드민ID</TableCell>
                    <TableCell style={{ width: '140px' }}>하나 아이디</TableCell>
                    <TableCell style={{ width: '140px' }}>변경 전 BC아이디</TableCell>
                    <TableCell style={{ width: '140px' }}>변경 후 BC아이디</TableCell>
                    <TableCell>메모</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((history, idx) => (
                    <TableRow hover key={history.id} style={{ cursor: 'pointer', verticalAlign: 'top' }}>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>
                        {new Date(history.updatedAt).toLocaleString()}
                      </TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12, fontFamily: 'monospace' }}>
                        {history.adminId}
                      </TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{history.hanaMerchantId}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{history.originalMappingId}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{history.changedMappingId}</TableCell>
                      <TableCell style={{ paddingTop: 12, paddingBottom: 12 }}>{history.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

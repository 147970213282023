import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import { reIssueRequest } from './cardApi';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  title: string,
  issueReturnHistorys: any[],
  issueReturnHistoryFetch: any
}

const IssueReturnHistoryList: FC<Props> = ({
  title,
  issueReturnHistorys,
  issueReturnHistoryFetch
}) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  if(issueReturnHistorys.length === 0) {
    return (
      <Card>
        { !!title &&
          <CardHeader title={title}/>
        }
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  // 재배송 요청 처리 card-server api 호출
  const cardNo = issueReturnHistorys[0].cardNo;
  const cardNoMasked = issueReturnHistorys[0].cardNoMasked;
  const handleReIssueRequest = async () => {
    console.log('재 배송 요청 처리 CARD_NO: ', cardNo);

    try {

      const result =  await reIssueRequest (cardNo);

      console.log('재 배송 요청 처리 결과: ', result);

      // 결과 Toast 메세지
      if(result.success === 'Y') {
        showToast(result.message);

        // 발송반송내역 리로드
        issueReturnHistoryFetch();  
      }else {
        showToast(result.message, 'error');
      }

    } catch (e) {
      let errorMessage = '';
      errorMessage = '서버와의 통신이 원활하지 않습니다. \n관리자에게 문의하시기 바랍니다.';
      showToast(errorMessage, 'error');
    }

    hideDialog();
  }

  // 카드발송유형코드Map
  const sndTpCodeMap = new Map<String, String>();
  sndTpCodeMap.set('1', '본인수령');
  sndTpCodeMap.set('2', '관리영업점송부');
  sndTpCodeMap.set('3', '제3영업점송부');
  sndTpCodeMap.set('4', '본인특송');

  // 발송상태구분코드Map
  const issueStatusCode = new Map<String, String>();
  issueStatusCode.set('1', '발송');
  issueStatusCode.set('2', '수령');
  issueStatusCode.set('3', '반송');
  issueStatusCode.set('4', '재발송');
  issueStatusCode.set('5', '폐기');
  issueStatusCode.set('6', '고객등록');
  issueStatusCode.set('7', '반송입고');

  // 배송업체코드Map
  const sndClssCodeMap = new Map<String, String>();
  sndClssCodeMap.set('1', 'LMC본인');
  sndClssCodeMap.set('3', '중국현지법인');
  sndClssCodeMap.set('4', '국제본인');
  sndClssCodeMap.set('5', '제니엘본인');
  sndClssCodeMap.set('6', '국제영업점');
  sndClssCodeMap.set('7', '제니엘영업점');
  sndClssCodeMap.set('8', '우리영업점');
  sndClssCodeMap.set('9', 'LMC영업점');
  sndClssCodeMap.set('A', '국제본인');
  sndClssCodeMap.set('B', '국제영업점');
  sndClssCodeMap.set('C', '제니엘본인');
  sndClssCodeMap.set('D', '제니엘영업점');
  sndClssCodeMap.set('E', '중국현지');
  sndClssCodeMap.set('F', '우리영업점');
  sndClssCodeMap.set('G', '한승본인');
  sndClssCodeMap.set('H', '한승영업점');
  sndClssCodeMap.set('I', '성우본인');
  sndClssCodeMap.set('J', '성우영업점');
  sndClssCodeMap.set('K', 'LMC본인');
  sndClssCodeMap.set('L', 'LMC영업점');

  // 본인과의 관계
  const rcpRelCodeMap = new Map<String, String>();
  rcpRelCodeMap.set('01', '본인');
  rcpRelCodeMap.set('02', '조부');
  rcpRelCodeMap.set('03', '조모');
  rcpRelCodeMap.set('04', '아버지');
  rcpRelCodeMap.set('05', '어머니');
  rcpRelCodeMap.set('06', '처');
  rcpRelCodeMap.set('07', '남편');
  rcpRelCodeMap.set('08', '형');
  rcpRelCodeMap.set('09', '동생');
  rcpRelCodeMap.set('10', '언니');
  rcpRelCodeMap.set('11', '누나');
  rcpRelCodeMap.set('12', '아들');
  rcpRelCodeMap.set('13', '딸');
  rcpRelCodeMap.set('14', '손자');
  rcpRelCodeMap.set('15', '손녀');
  rcpRelCodeMap.set('16', '며느리');
  rcpRelCodeMap.set('17', '삼촌');
  rcpRelCodeMap.set('18', '사촌');
  rcpRelCodeMap.set('19', '친척');
  rcpRelCodeMap.set('20', '직장동료');
  rcpRelCodeMap.set('21', '상사');
  rcpRelCodeMap.set('22', '친구');
  rcpRelCodeMap.set('23', '선배');
  rcpRelCodeMap.set('24', '후배');
  rcpRelCodeMap.set('25', '은행원');
  rcpRelCodeMap.set('26', '배우자');
  rcpRelCodeMap.set('27', '자녀');
  rcpRelCodeMap.set('28', '부모');
  rcpRelCodeMap.set('29', '형제자매');
  rcpRelCodeMap.set('98', '우체국등기');
  rcpRelCodeMap.set('99', '기타');

  // 반송사유코드Map
  const backRsonNoCodeMap = new Map<String, String>();
  backRsonNoCodeMap.set('01', '수취인불명');
  backRsonNoCodeMap.set('02', '이사불명');
  backRsonNoCodeMap.set('03', '주소불명');
  backRsonNoCodeMap.set('04', '장기부재');
  backRsonNoCodeMap.set('05', '수취인거부');
  backRsonNoCodeMap.set('06', '기타');
  backRsonNoCodeMap.set('07', '기타');
  backRsonNoCodeMap.set('08', '기타');
  backRsonNoCodeMap.set('09', '기타');

  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>카드번호</TableCell>
                  <TableCell>순번</TableCell>
                  <TableCell>회원번호</TableCell>
                  <TableCell>고객번호</TableCell>
                  <TableCell>발송상태</TableCell>
                  <TableCell>기준일자</TableCell>
                  <TableCell>발송유형</TableCell>
                  <TableCell>발송업체</TableCell>
                  <TableCell>발송매수</TableCell>
                  <TableCell>발송영업점</TableCell>
                  <TableCell>발송번호</TableCell>
                  <TableCell>발급일자</TableCell>
                  <TableCell>재발송일자</TableCell>
                  <TableCell>반송사유</TableCell>
                  <TableCell>등기번호</TableCell>
                  <TableCell>카드수령인</TableCell>
                  <TableCell>관계</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issueReturnHistorys.slice((rowsPerPage*page), rowsPerPage*(page+1)).map(issueReturnHistory => (
                  <TableRow
                    hover
                    key={issueReturnHistory.changeSeq}
                  >
                    <TableCell>{issueReturnHistory.cardNoMasked}</TableCell>
                    <TableCell>{issueReturnHistory.changeSeq}</TableCell>
                    <TableCell>{issueReturnHistory.cardMemberNo}</TableCell>
                    <TableCell>{issueReturnHistory.userId}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('issueStatusCode',issueReturnHistory.issueStatusCode)}</TableCell>
                    <TableCell>{issueReturnHistory.issueBaseDate}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('sndTpCode',issueReturnHistory.sendTypeDivisionCode)}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('sndClssCode',issueReturnHistory.issueCorporationDivisionCode)}</TableCell>
                    <TableCell>{issueReturnHistory.issueCount}</TableCell>
                    <TableCell>{issueReturnHistory.issueBranchNo}</TableCell>
                    <TableCell>{issueReturnHistory.issueNo}</TableCell>
                    <TableCell>{issueReturnHistory.issueDate}</TableCell>
                    <TableCell>{issueReturnHistory.reissueDate}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('backRsonNoCode',issueReturnHistory.issueReturnCode)}</TableCell>
                    <TableCell>{issueReturnHistory.registeredNo}</TableCell>
                    <TableCell>{issueReturnHistory.receveName}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('rcpRelCode',issueReturnHistory.receveRelationCode)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {
              /* 카드 상태가 반송수령(7)인 경우만 버튼 보임 */
              issueReturnHistorys[0].issueStatusCode === '7'
              ? 
              <Button className={classes.registerButton} variant="outlined" color="primary" size="medium" 
              onClick={
              () => showDialog(
                '재배송요청',
                `카드번호: ${cardNoMasked} \n선택한 카드의 재배송을 요청 하시겠습니까?`,
                () => {handleReIssueRequest()}
              )
              }
            >            
              재배송요청
            </Button>
              : ''
            }
            
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={issueReturnHistorys.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
};

export default IssueReturnHistoryList;
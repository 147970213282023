import React, { FC, useState } from 'react';
import { Card, CardContent, Grid, Button, TextField } from '@material-ui/core';
import { PlccMerchantMappingSearchInfo } from './PlccMerchantMapping';
import { PlccMerchantMappingExcel } from './PlccMerchantMappingExcel';

type Props = {
  selects: { [k: string]: string | number };
  originalSelects: { [k: string]: string | number };
  onSearch: React.Dispatch<React.SetStateAction<any>>;
  searchForm: PlccMerchantMappingSearchInfo;
  setSearchForm: React.Dispatch<React.SetStateAction<PlccMerchantMappingSearchInfo>>;
  onConfirmClick: React.Dispatch<React.SetStateAction<any>>;
};

const PlccMerchantMappingListToolbar: FC<Props> = ({
  selects,
  originalSelects,
  onSearch,
  searchForm,
  setSearchForm,
  onConfirmClick,
}) => {
  const [excelPopup, setExcelPopup] = useState(false);
  // 변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value,
    });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick();
      event.preventDefault();
    }
  };

  // 목록 조회
  const onSearchClick = async () => {
    onSearch(searchForm);
  };

  const changes = Object.keys(originalSelects || []).filter(
    original => selects[original] !== originalSelects[original]
  ).length;

  return (
    <Card>
      <form autoComplete="off" noValidate>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={2} xs={6}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="매핑 상태"
                margin="dense"
                name="searchType"
                onChange={handleChange}
                select
                SelectProps={{
                  native: true,
                }}
                value={searchForm.searchType}
                variant="outlined"
              >
                <option value="1">전체</option>
                <option value="2">매핑완료</option>
                <option value="3">매핑대기</option>
                <option value="4">매핑없음</option>
              </TextField>
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="가맹점 정보"
                margin="dense"
                name="searchValue"
                onChange={handleChange}
                onKeyPress={onKeyEnter}
                placeholder="가맹점 번호 또는 가맹점명을 입력하세요"
                value={searchForm.searchValue}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style={{
                  marginTop: '8px',
                }}
              >
                Search
              </Button>
              &nbsp;
              <Button
                color="primary"
                variant="contained"
                onClick={() => setExcelPopup(true)}
                style={{
                  marginTop: '8px',
                }}
              >
                CSV Upload
              </Button>
            </Grid>
            {changes > 0 && (
              <Grid item style={{ textAlign: 'right' }} xs>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onConfirmClick}
                  style={{
                    marginTop: '8px',
                  }}
                >
                  변경 사항 저장 ({changes})
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </form>
      <PlccMerchantMappingExcel uploadPop={excelPopup} setUploadPop={setExcelPopup} />
    </Card>
  );
};

export default PlccMerchantMappingListToolbar;

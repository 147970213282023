import React, { FC, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns'
import apolloClient from '../../lib/apolloClient';
import { approvalAggrMutation } from '../../lib/apolloClient/mutations';

interface ProductInfo  {
  cardProductCode :string;
  cardProductCodeName :string;
}

type SearchInfo = {
  cardProductCode           :string;  // 카드상품
  startDate                 :string;  // 시작일자
  endDate                   :string;  // 종료일자  
}

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<SearchInfo>>
}

const AprvAgrgToolbar: FC<Props> = ({onSearch}) => {  
  
  const [productList, setProductList] = useState<ProductInfo[]>([]);
  const [searchForm, setSearchForm] = useState({
    cardProductCode: 'all',
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 카드상품 목록 조회
  useEffect(() => {
    const fetch = async () => {
      console.log('카드상품 목록조회 start')
      await apolloClient.mutate({
        variables: { useYn: 'Y' },
        mutation: approvalAggrMutation.selectProductList
      }).then(res => {
        setProductList(res.data.selectProductList);
      }).catch(console.error);
    }
    fetch()
    console.log('카드상품 목록조회 end')
  }, []);

  const onSearchClick = async () => {
    console.log(searchForm);
    onSearch(searchForm);
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >            
                        
            <Grid
              item
              md={2}
              xs={6}
            >              
              <TextField
                select
                fullWidth
                label="카드상품"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardProductCode"
                value={searchForm.cardProductCode}
                onChange={handleChange}
              >
                <option key="" value="all">전체</option>
                {productList.map(item => (
                  <option
                    key={item.cardProductCode}
                    value={item.cardProductCode}
                  >
                    {item.cardProductCodeName}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                type="date"
                label="시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDate"
                value={searchForm.startDate}
                onChange={handleChange}
              />                            
            </Grid>
            {/* <span>~</span> */}
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                type="date"
                label="종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDate"
                value={searchForm.endDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>  
          <Grid
            container
            spacing={1}
          >
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default AprvAgrgToolbar;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import {format, subDays} from 'date-fns';
import { MenuTitleToolbar } from '../../components';

import CardOverseaFeeBaseToolbar from './CardOverseaFeeBaseToolbar';
import CardOverseaFeeBaseDetails from './CardOverseaFeeBaseDetails';
import CardOverseaFeeBaseTable from './CardOverseaFeeBaseTable';
import CardOverseaFeeBaseHistory from './CardOverseaFeeBaseHistory';
import { cardOverseaFeeBaseMutation } from '../../lib/apolloClient/mutations';


type Props = {
  overseasFeeBaseList : overseasFeeBaseList
  overseasFeeBaseHistoryList : overseasFeeBaseHistoryList
}
const CardOverseaFeebaseMgmt: FC<Props> = ({overseasFeeBaseList, overseasFeeBaseHistoryList}) => {

    const useStyles = makeStyles((theme: Theme) => ({       
      root: {
        padding: theme.spacing(3)
      },
      content: {
        marginTop: theme.spacing(2)
      }
    }));
  
    const classes = useStyles();
    const [data, setData] = useState(overseasFeeBaseList);
    const [updateClick, setUpdateClick] = useState(false);
    const [history, setHistory] = useState(overseasFeeBaseHistoryList);
    let startDate = new Date();  

    const [searchForm, setSearchForm] = useState({
      cardProductGroupCode: '',
      cardProductCode: '',
      brandDivisionCode: '',
      overseasFeeDivisionCode: '',
      useYn: '',
      waivedYn:'',
      startDt: format(subDays(startDate,1), 'yyyy-MM-dd'),
      endDt: format(startDate, 'yyyy-MM-dd')
    });

    const [cardOverseFeeBaseMgmt, setCardOverseFeeBaseMgmt] = useState({
      cardProductGroupCode: '',                       // 카드상품그룹
      cardProductCode: '',                            // 카드상품코드
      brandDivisionCode: '',                          // 브랜드구분
      overseasFeeDivisionCode: '',                    // 해외수수료구분
      feeApplyDate: '',                               // 수수료적용일자
      feeApplyDivisionCode: '',                       // 수수료적용구분
      useYn: '',                                      // 사용여부
      waivedYn: '',                                   // 면제여부
      basicFeeValue: 0,                               // 기본수수료값     
      cardProductCodeName: '',                        // 상품코드명
      firstOperateAdminId: '',                        // 최초조작직원번호
      firstOperateDetailDate: '',                     // 최조조작상세일시
      firstOperateTradeCode: '',                       // 최초조작거래코드
      lastOperateAdminId: '',                         // 최종조작직원번호
      lastOperateDetailDate: '',                      // 최종조작상세일시
      lastOperateTradeCode: ''                        // 최종조작거래코드
    });

    // 페이징
    const default_page = 0;
    const default_rowsPerPage = 10;
    const [paging, setPaging] = useState({
        page: default_page,
        rowsPerPage: default_rowsPerPage
    });

    const [pagingHistory, setPagingHistory] = useState({
      page: default_page,
      rowsPerPage: default_rowsPerPage
  });

    useEffect(() => {
      onSearch(searchForm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging, pagingHistory]);

    // 해외수수료기준목록 조회
    const onSearch = async (searchForm: CardOverseaFeeBaseSearchForm) => {
      console.log('<<< 해외수수료기준목록 실행 searchForm: ', searchForm);

      // 초기화
      setData(overseasFeeBaseList);
      
      await apolloClient.mutate({
        variables : {
          input : {
            cardProductGroupCode : searchForm.cardProductGroupCode,
            cardProductCode : searchForm.cardProductCode,
            brandDivisionCode : searchForm.brandDivisionCode,
            waivedYn : searchForm.waivedYn,
            overseasFeeDivisionCode : searchForm.overseasFeeDivisionCode,
            startDt : searchForm.startDt,
            endDt : searchForm.endDt,
            useYn : searchForm.useYn,
            page : paging.page,
            rowsPerPage : paging.rowsPerPage
          }
        },
        mutation : cardOverseaFeeBaseMutation.cardOverseaFeeBase
      }).then( res => {
        console.log('결과 : ', res.data.selectOverseasFeeBaseList);
        setData(res.data.selectOverseasFeeBaseList);
      }).catch(console.error);

      historySearch(searchForm);
    }

    // 해외수수료기준변경이력 조회
    const historySearch = async(searchForm: CardOverseaFeeBaseSearchForm) => {
      console.log('<<< 해외수수료기준변경이력 실행 searchForm: ', searchForm);
      await apolloClient.mutate({
        variables : {
          input : {
            cardProductGroupCode : searchForm.cardProductGroupCode,
            cardProductCode : searchForm.cardProductCode,
            brandDivisionCode : searchForm.brandDivisionCode,
            waivedYn : searchForm.waivedYn,
            overseasFeeDivisionCode : searchForm.overseasFeeDivisionCode,
            startDt : searchForm.startDt,
            endDt : searchForm.endDt,
            useYn : searchForm.useYn,
            page : pagingHistory.page,
            rowsPerPage : pagingHistory.rowsPerPage
          }
        },
        mutation : cardOverseaFeeBaseMutation.cardOverseaFeeBaseHistory
      }).then( res => {
        console.log('결과 : ', res.data.selectOverseasFeeBaseHistoryList);
        setHistory(res.data.selectOverseasFeeBaseHistoryList);
      }).catch(console.error);
    }

    return (
        <div className={classes.root}>
          <MenuTitleToolbar title="해외수수료기준관리" />
          <CardOverseaFeeBaseToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />
          <div className={classes.content}>
            <CardOverseaFeeBaseDetails cardOverseFeeBaseMgmt={cardOverseFeeBaseMgmt} setCardOverseFeeBaseMgmt={setCardOverseFeeBaseMgmt} 
                                       searchForm={searchForm} onSearch={onSearch} updateClick={updateClick} setUpdateClick={setUpdateClick}/>
          </div>
          <div className={classes.content}>
            <CardOverseaFeeBaseTable title="해외수수료기준목록" paging={paging} setPaging={setPaging} selectOverseasFeeBaseList={data} setUpdateClick={setUpdateClick} setCardOverseFeeBaseMgmt={setCardOverseFeeBaseMgmt}/>
          </div>
          <div className={classes.content}>
            <CardOverseaFeeBaseHistory title="해외수수료기준변경이력" pagingHistory={pagingHistory} setPagingHistory={setPagingHistory} historyList={history}/>
          </div>
        </div>
    )
}



export default CardOverseaFeebaseMgmt;

import React, { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box
} from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { commonCodeMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

type Props = {
  className?: string; // index.tsx에서 지정한 Style을 사용하기 위한 props
  typeCodeList?: CommonCodeInfo[]; // codeUpdate.tsx에서 유형코드 리스트를 받기 위한 props
  groupCodeList?: CommonCodeInfo[]; // codeUpdate.tsx에서 그룹코드 리스트를 받기 위한 props
  setCommonCodeInfo: React.Dispatch<React.SetStateAction<CommonCodeInfo>>; // 유형코드와 그룹코드의 신규 추가, 사용여부 변경 시 리스트에 추가하기 위한 props
  updateClick: boolean; // 2번째 argument가 updateClick인 useEffect를 실행하기 위한 props
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>; // // 2번째 argument가 updateClick인 useEffect를 실행하기 위한 props
  commonCodeInfo: CommonCodeInfo; // // 유형코드와 그룹코드의 신규 추가, 사용여부 변경 시 리스트에 추가하기 위한 props
  checkFormValidation: VoidFunction; // 리스트 클릭 시 신규, 수정 버튼 활성화 조작을 위한 props
};

export const TypeCodeList: FC<Props> = ({
  typeCodeList,
  commonCodeInfo,
  setCommonCodeInfo,
  setUpdateClick,
  updateClick,
  checkFormValidation
}) => {
  const [open, setOpen] = useState(false); // codeUpdate.tsx에서 유형코드의 목록 버튼 클릭 시 Dialog 창을 활성화 시키기 위한 state

  const { showToast } = useToast(); // Toast 메세징 기능을 위한 함수 선언
  const { showDialog, hideDialog } = useDialog(); // Dialog 팝업창을 띄우기 위한 함수 선언

  const map = new Map<String, String>();
  map.set('Y', '사용');
  map.set('N', '미사용');

  const changeUseYn = async (itemTypeCode: string, useYn: string) => {
    // 유형코드 사용 여부 변경 수정을 위한 함수

    const changeYn = useYn === 'Y' ? 'N' : 'Y'; // 변경할 string을 상수로 지정

    await apolloClient
      .mutate({
        variables: {
          input: {
            // typeCode를 검색하여 useYn을 changeYn 값으로 변경
            typeCode: itemTypeCode,
            useYn: changeYn,
            codeName: typeCodeName
          }
        },
        mutation: commonCodeMutation.changeTypeCodeUseYn
      })
      .then(res => {
        setCommonCodeInfo({
          ...commonCodeInfo,
          codeName: '',
          codeId: '',
          typeCode: '',
          groupCode: '',
          codeValue: '',
          mapCode: '',
          codeDescription: '',
          useYn: ''
        });
        hideDialog();
        showDialog('유형코드정보', '수정되었습니다.\n\n', () => {
          hideDialog();
        });
      })
      .catch(error => {
        console.log(error);
        showToast(error.message, 'Error');
      });
    setUpdateClick(!updateClick); // 2번째 argument가 updateClick인 useEffect를 실행
  };

  const handleClickOpen = () => {
    // Dialog 팝업창 실행을 위한 실행 여부 조작 함수
    open ? setOpen(false) : setOpen(true);
  };

  const [rowsPerPage, setRowsPerPage] = useState(5); // pagenation의 페이지당 리스트 출력 수를 조정하기 위한 state
  const [page, setPage] = useState(0); // pagenation의 페이지 위치를 조정하기 위한 state

  // pagenation의 페이지를 변경하는 함수
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page); // pagenation의 pageChange 함수를 통해 페이지 번호를 받아서 page의 값 변경
  };

  // pagenation의 페이지당 리스트 출력 수를 변경하는 함수
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value)); // pagenation의 onChangeRowsPerPage 함수를 통해 페이지 당 리스트 출력 숫자를 받아서 rowsPerPage의 값 변경
  };

  const [typeCode, setTypeCode] = useState(''); // 유형코드 신규 삽입 시 유형코드를 받기 위한 state
  const [typeCodeName, setTypeCodeName] = useState(''); // 유형코드 신규 삽입 시 유형코드명을 받기 위한 state

  // 유형코드 리스트의 row 클릭 시 codeUpdate.tsx의 textField에 값을 보내주기 위한 함수
  const updateTypeCodeFromItemHandle = (item: CommonCodeInfo) => {
    setCommonCodeInfo({
      groupCode: commonCodeInfo.groupCode,
      codeId: commonCodeInfo.codeId,
      codeName: commonCodeInfo.codeName,
      typeCode: item.typeCode,
      codeValue: commonCodeInfo.codeValue,
      useYn: commonCodeInfo.useYn,
      codeDescription: commonCodeInfo.codeDescription,
      mapCode: commonCodeInfo.mapCode,
      codeGroupDivisionCode: '3'
    });
    checkFormValidation(); // 신규, 수정 버튼 활성화 여부를 확인하기 위한 함수
  };

  if (typeCodeList === undefined) return <div></div>; // typeCodeList를 받아오지 못하면 리스트에 빈 값 출력

  let isDup = false; // PK인 typeCode가 중복이 되면 card-graph 서버에서 에러코드로써 typeCode의 값 'xxx'를 송출, 에러 감지시 isDup 변수 활성화

  const handleAddTypeCode = async () => {
    // 유형코드 신규 삽입 함수

    console.log('#############    Add Type Code');

    await apolloClient
      .mutate({
        variables: {
          input: {
            // 유형코드와 유형코드명을 변수로 받아서 insert 실행
            typeCode: typeCode,
            codeName: typeCodeName
          }
        },
        mutation: commonCodeMutation.insertTypeCodeInfo
      })
      .then(res => {
        console.log(res.data);
        setCommonCodeInfo({
          ...typeCodeList,
          codeName: '',
          codeId: '',
          typeCode: '',
          groupCode: '',
          codeValue: '',
          mapCode: '',
          codeDescription: '',
          useYn: '',
          codeGroupDivisionCode: ''
        });

        if (res.data.insertTypeCodeInfo.typeCode === 'xxx') {
          // 유형코드 삽입 시의 에러 발생 여부 확인
          isDup = true;
        }

        if (isDup) {
          // 에러 발생 시의 Dialog 메세징
          hideDialog();
          showDialog('유형코드정보', '존재하는 유형코드 입니다.\n\n', () => {
            hideDialog();
          });
        } else {
          // 에러 미발생 시의 Dialog 메세징
          hideDialog();
          showDialog('유형코드정보', '저장되었습니다.\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(error => {
        console.log(error);
        showToast(error.message, 'Error');
      });
    setUpdateClick(!updateClick); // 2번째 argument가 updateClick인 useEffect 실행
  };

  return (
    <a href='#!' style={{ marginLeft: 10, textAlign: 'center' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen} // 목록 버튼 클릭 시 clickOpen을 true로 활성화 하여 Dialog 오픈
      >
        Search
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>코드유형 등록</DialogTitle>
        <hr />
        <DialogContent>
          <p style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
            유형코드
            <TextField
              style={{ width: '20%', marginRight: 20, marginLeft: 20 }}
              variant="outlined"
              onChange={event => setTypeCode(event.target.value)} // 유형코드 textField 입력 시 state에 입력
              value={typeCode}
              size="small"
            />
            유형명
            <TextField
              style={{ width: '30%', marginRight: 20, marginLeft: 20 }}
              onChange={event => setTypeCodeName(event.target.value)} // 유형코드명 textField 입력 시 state에 입력
              value={typeCodeName}
              variant="outlined"
              size="small"
            />
            <Button
              color="primary"
              variant="contained"
              children="Add"
              onClick={() =>
                showDialog(
                  // 추가 버튼 클릭 시 Dialog 창으로 한번 더 확인한 후 유형코드 삽입 실행
                  '유형코드정보',
                  '해당 정보로 저장하시겠습니까?',
                  handleAddTypeCode
                )
              }
            />
          </p>
          <Box>
            <PerfectScrollbar>
              <Table style={{ marginTop: 10 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">유형코드</TableCell>
                    <TableCell align="center">코드유형명</TableCell>
                    <TableCell align="center">사용여부</TableCell>
                    <TableCell align="center">관리</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeCodeList
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((
                      item,
                      idx // pagenation 설정하여 갯수 만큼을 typeCodeList에서 item으로 받아옴
                    ) => (
                      <TableRow
                        onClick={() => {
                          updateTypeCodeFromItemHandle(item);
                          setUpdateClick(true);
                        }} // 리스트의 row 클릭 시 codeUpdate.tsx의 유형코드 textField에 입력 및 useEffect 실행
                        hover
                        key={item.typeCode} // typeCode를 Key로 받아서 목록 호출
                      >
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateTypeCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {item.typeCode}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateTypeCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {item.codeName}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateTypeCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {map.get(item.useYn)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button // 버튼 클릭 시 사용여부 변경 함수 호출
                            color="primary"
                            variant="contained"
                            onClick={event => {
                              event.preventDefault(); // 리스트에 적용되어 있는 hover 및 리스트 클릭 동작 중복 배제
                              changeUseYn(item.typeCode, item.useYn);
                            }}>
                            {map.get(item.useYn)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        </DialogContent>
        <Button
          color="primary"
          variant="contained"
          style={{
            marginLeft: 'auto',
            marginTop: 10,
            float: 'right',
            marginRight: '42px'
          }}
          onClick={handleClickOpen}
          children="Cancel"
        />
        <DialogActions>
          <TablePagination // pagenation 조작을 위한 함수 지정
            component="div"
            count={typeCodeList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </DialogActions>
      </Dialog>
    </a>
  );
};

export const GroupCodeList: FC<Props> = ({
  groupCodeList,
  commonCodeInfo,
  setCommonCodeInfo,
  setUpdateClick,
  updateClick,
  checkFormValidation
}) => {
  const [open, setOpen] = useState(false); // codeUpdate.tsx에서 그룹코드의 목록 버튼 클릭 시 Dialog 창을 활성화 시키기 위한 state

  const map = new Map<String, String>();
  map.set('Y', '사용');
  map.set('N', '미사용');

  const { showToast } = useToast(); // Toast 메세징 기능을 위한 함수 선언
  const { showDialog, hideDialog } = useDialog(); // Dialog 팝업창을 띄우기 위한 함수 선언

  const changeUseYn = async (itemGroupCode: string, useYn: string) => {
    // 그룹코드 사용 여부 변경 수정을 위한 함수

    const changeYn = useYn === 'Y' ? 'N' : 'Y'; // 변경할 string을 상수로 지정

    await apolloClient
      .mutate({
        variables: {
          input: {
            // typeCode를 검색하여 useYn을 changeYn 값으로 변경
            groupCode: itemGroupCode,
            useYn: changeYn,
            codeName: groupCodeName
          }
        },
        mutation: commonCodeMutation.changeGroupCodeUseYn
      })
      .then(res => {
        setCommonCodeInfo({
          ...commonCodeInfo,
          codeName: '',
          codeId: '',
          typeCode: '',
          groupCode: '',
          codeValue: '',
          mapCode: '',
          codeDescription: '',
          useYn: ''
        });
        hideDialog();
        showDialog('그룹코드 정보', '수정되었습니다.\n\n', () => {
          hideDialog();
        });
      })
      .catch(error => {
        console.log(error);
        showToast(error.message, 'Error');
      });
    setUpdateClick(!updateClick); // 2번째 argument가 updateClick인 useEffect를 실행
  };

  const handleClickOpen = () => {
    // Dialog 팝업창 실행을 위한 실행 여부 조작 함수
    open ? setOpen(false) : setOpen(true);
  };

  const [rowsPerPage, setRowsPerPage] = useState(5); // pagenation의 페이지당 리스트 출력 수를 조정하기 위한 state
  const [page, setPage] = useState(0); // pagenation의 페이지 위치를 조정하기 위한 state

  // pagenation의 페이지를 변경하는 함수
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page); // pagenation의 pageChange 함수를 통해 페이지 번호를 받아서 page의 값 변경
  };

  // pagenation의 페이지당 리스트 출력 수를 변경하는 함수
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value)); // pagenation의 onChangeRowsPerPage 함수를 통해 페이지 당 리스트 출력 숫자를 받아서 rowsPerPage의 값 변경
  };
  const [groupCode, setGroupCode] = useState(''); // 그룹코드 신규 삽입 시 그룹코드를 받기 위한 state
  const [groupCodeName, setGroupCodeName] = useState(''); // 그룹코드 신규 삽입 시 그룹코드명을 받기 위한 state

  // 그룹코드 리스트의 row 클릭 시 codeUpdate.tsx의 textField에 값을 보내주기 위한 함수
  const updateGroupCodeFromItemHandle = (item: CommonCodeInfo) => {
    setCommonCodeInfo({
      groupCode: item.groupCode,
      codeId: commonCodeInfo.codeId,
      codeName: commonCodeInfo.codeName,
      typeCode: commonCodeInfo.typeCode,
      codeValue: commonCodeInfo.codeValue,
      useYn: commonCodeInfo.useYn,
      codeDescription: commonCodeInfo.codeDescription,
      mapCode: commonCodeInfo.mapCode,
      codeGroupDivisionCode: '3'
    });
    checkFormValidation(); // 신규, 수정 버튼 활성화 여부를 확인하기 위한 함수
  };

  if (groupCodeList === undefined) return <div></div>; // groupCodeList를 받아오지 못하면 리스트에 빈 값 출력

  let isDup = false; // PK인 goupCode가 중복이 되면 card-graph 서버에서 에러코드로써 groupCode의 값 'xxx'를 송출, 에러 감지시 isDup 변수 활성화

  const handleAddGroupCode = async () => {
    // 그룹코드 신규 추가 함수

    console.log('#############    Add Group Code');

    await apolloClient
      .mutate({
        variables: {
          input: {
            // 그룹코드와 그룹코드명을 변수로 받아서 insert 실행
            groupCode: groupCode,
            codeName: groupCodeName
          }
        },
        mutation: commonCodeMutation.insertGroupCodeInfo
      })
      .then(res => {
        console.log(res.data);
        setCommonCodeInfo({
          ...groupCodeList,
          codeName: '',
          codeId: '',
          typeCode: '',
          groupCode: '',
          codeValue: '',
          mapCode: '',
          codeDescription: '',
          useYn: '',
          codeGroupDivisionCode: ''
        });

        if (res.data.insertGroupCodeInfo.groupCode === 'xxx') {
          // 그룹코드 삽입 시의 에러 발생 여부 확인
          isDup = true;
        }

        if (isDup) {
          // 에러 발생 시의 Dialog 메세징
          hideDialog();
          showDialog('그룹코드정보', '존재하는 그룹코드 입니다.\n\n', () => {
            hideDialog();
          });
        } else {
          // 에러 미발생 시의 Dialog 메세징
          hideDialog();
          showDialog('그룹코드정보', '저장되었습니다.\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(error => {
        console.log(error);
        showToast(error.message, 'Error');
      });
    setUpdateClick(!updateClick); // 2번째 argument가 updateClick인 useEffect 실행
  };

  return (
    <a href='#!' style={{ marginLeft: 10, textAlign: 'center' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen} // 목록 버튼 클릭 시 clickOpen을 true로 활성화 하여 Dialog 오픈
      >
        Search
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>그룹코드 등록</DialogTitle>
        <hr />
        <DialogContent>
          <p style={{ fontFamily: 'sans-serif', fontSize: 14 }}>
            그룹코드
            <TextField
              style={{ width: '20%', marginRight: 20, marginLeft: 20 }}
              size="small"
              variant="outlined"
              value={groupCode}
              onChange={event => setGroupCode(event.target.value)} // 그룹코드 textField 입력 시 state에 입력
            />
            그룹명
            <TextField
              style={{ width: '30%', marginRight: 20, marginLeft: 20 }}
              variant="outlined"
              size="small"
              value={groupCodeName}
              onChange={event => setGroupCodeName(event.target.value)} // 그룹코드명 textField 입력 시 state에 입력
            />
            <Button
              color="primary"
              variant="contained"
              children="Add"
              onClick={() =>
                showDialog(
                  // 추가 버튼 클릭 시 Dialog 창으로 한번 더 확인한 후 그룹코드 삽입 실행
                  '그룹코드정보',
                  '해당 정보로 저장하시겠습니까?',
                  handleAddGroupCode
                )
              }
            />
          </p>
          <Box>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">그룹코드</TableCell>
                    <TableCell align="center">그룹명</TableCell>
                    <TableCell align="center">사용여부</TableCell>
                    <TableCell align="center">관리</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupCodeList
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((
                      item,
                      idx // pagenation 설정하여 갯수 만큼을 groupCodeList에서 item으로 받아옴
                    ) => (
                      <TableRow
                        onClick={() => {
                          updateGroupCodeFromItemHandle(item);
                          setUpdateClick(true);
                        }} // 리스트의 row 클릭 시 codeUpdate.tsx의 그룹코드 textField에 입력 및 useEffect 실행
                        hover
                        key={item.groupCode} // groupCode를 Key로 받아서 목록 호출
                      >
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateGroupCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {item.groupCode}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateGroupCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {item.codeName}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              updateGroupCodeFromItemHandle(item);
                              setUpdateClick(!updateClick);
                            }}
                            style={{ color: 'navy' }}>
                            {map.get(item.useYn)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={event => {
                              event.preventDefault(); // 리스트에 적용되어 있는 hover 및 리스트 클릭 동작 중복 배제
                              changeUseYn(item.groupCode, item.useYn);
                            }}>
                            {map.get(item.useYn)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        </DialogContent>
        <Button
          color="primary"
          variant="contained"
          style={{
            marginLeft: 'auto',
            marginTop: 10,
            float: 'right',
            marginRight: '42px'
          }}
          onClick={handleClickOpen}
          children="Cancel"
        />
        <DialogActions>
          <TablePagination // pagenation 조작을 위한 함수 지정
            component="div"
            count={groupCodeList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </DialogActions>
      </Dialog>
    </a>
  );
};

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useToast } from '../../contexts/Toast';

import { getVipCardList } from '../../lib/cardApi';

import { MenuTitleToolbar } from '../../components';
import VipCardToolbar from './VipCardToolbar';
import VipCardList from './VipCardList';
import VipCardInfoTable from './VipCardInfoTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const VipCardInfo = () => {

  //카드상세정보
  const [vipCardInfo, setVipCardInfo] = useState({
    cardSeq: '',
    userId:  '',
    cardProductCode:  '',
    cardNo:  '',
    cardNoStatusDivisionCode:  '',
    firstOperateDetailDate:  ''
    //issueDate:  ''
  });

  const { showToast } = useToast();
  const classes = useStyles();
  const [list, setList] = useState<VipCardInfo[]>([]);

  //const [data, setData] = useState(<VipCardInfo>[]);
  const [searchForm, setSearchForm] = useState({
    type: '',
    userId: '',
    cardProductCode: '',
    vipCardNo: '',
    cardNoStatusDivisionCode: ''
  });
  // const [loading, setLoading] = useState(false);

  const [updateClick, setUpdateClick] = useState(false);

  useEffect(() => {
    refreshVipCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm]); 

  const refreshVipCardList = async () => {
    console.log('=refreshVipCardList=');
    const fetch = async () => {
      // setLoading(true);

      try{
        //VIP 카드번호정보 목록 조회
        const vipCardList = await getVipCardList({
          type: 'list',
          userId: searchForm.userId,
          cardProductCode: searchForm.cardProductCode,
          vipCardNo: searchForm.vipCardNo,
          cardNoStatusDivisionCode: searchForm.cardNoStatusDivisionCode
        });
        setList(vipCardList);
      }catch(e){
        showToast(e.message, 'error')
      }      

      // setLoading(false);
    }
    fetch()
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="VIP 카드번호 관리" />

      <VipCardToolbar onSearch={setSearchForm}/>
      <div className={classes.content}>
        <VipCardList list={list} refreshVipCardList={refreshVipCardList}/>        
      </div>
      <div className={classes.content}>
        <VipCardInfoTable vipCardInfo={vipCardInfo} setVipCardInfo={setVipCardInfo} updateClick={updateClick} setUpdateClick={setUpdateClick} refreshVipCardList={refreshVipCardList}/>
      </div>
    </div>
  );

};

export default VipCardInfo;

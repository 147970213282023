import gql from 'graphql-tag';

// 대외송수신로그목록 조회
export const selectForeignSndrvLogList = gql`
  mutation selectForeignSndrvLogList(
    $tradeDate: String,
    $operateTradeCode: String,
    $startTradeTime: String,
    $endTradeTime: String,
    $tradeUniqNo: String
  ) {
    selectForeignSndrvLogList(
      tradeDate: $tradeDate,
      operateTradeCode: $operateTradeCode,
      startTradeTime: $startTradeTime,
      endTradeTime: $endTradeTime,
      tradeUniqNo: $tradeUniqNo
    ) {
        foreignInstCode
        textManagementNo
        tradeDate
        tradeUniqNo
        sndrvDivisionCode
        tradeTime
        operateTradeCode
        headerInfo
        individualCommonInfo
        responseCode
        messageDescription
        cardNo
        cardNoMasked
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
    }
  }
` ;
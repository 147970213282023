import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  title: string,
  cardAccidentHistorys: AccidentCardAccidentHistory[]
}
const CardAccidentHistoryList: FC<Props> = ({ title, cardAccidentHistorys }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 사고 등재 로우만 Filter (ex. 0111: 분실/도난 등재, 0061: 카드본인미수령등재, 0071: 사용자일시정지등재, 0811: 카드해지 등등)
  const filterdCardAccidentHistorys = cardAccidentHistorys.filter( (cardAccidentHistory)=> cardAccidentHistory.cardAccidentCode.substring(cardAccidentHistory.cardAccidentCode.length-1) === '1');
  console.log('filterdCardAccidentHistorys: ', filterdCardAccidentHistorys);

  /* 변경순번 내림차순 정렬 */
  const sortingField = 'changeSeq';
  filterdCardAccidentHistorys.sort(function(a, b) { // 내림차순
    return b[sortingField] - a[sortingField];
  });

  // 사고등해제구분코드Map
  const accidentStatusDivisionCodeMap = new Map<String, String>();
  accidentStatusDivisionCodeMap.set('0', '등재');
  accidentStatusDivisionCodeMap.set('1', '해제');

  // 사고코드 Map
  const accidentCodeMap = new Map<String, String>();
  accidentCodeMap.set('011', '분실 / 도난');
  accidentCodeMap.set('007', '사용자 일시정지');
  accidentCodeMap.set('005', '반송');
  accidentCodeMap.set('006', '배송정지');
  accidentCodeMap.set('081', '카드해지');

  // 접수채널코드 Map
  const accidentReceiptDivisionCodeMap = new Map<String, String>();
  accidentReceiptDivisionCodeMap.set('01', '본사전화');
  accidentReceiptDivisionCodeMap.set('02', '방문');
  accidentReceiptDivisionCodeMap.set('03', '영업점전화');
  accidentReceiptDivisionCodeMap.set('04', 'FAX');
  accidentReceiptDivisionCodeMap.set('05', 'ARS');
  accidentReceiptDivisionCodeMap.set('06', '단말입력');
  accidentReceiptDivisionCodeMap.set('07', '고객사');
  accidentReceiptDivisionCodeMap.set('08', '정정');

  if(filterdCardAccidentHistorys.length === 0) {
    return (
      <Card>
        { !!title &&
          <CardHeader title={title}/>
        }
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} align='center'>사고유형</TableCell>
                  <TableCell rowSpan={2} align='center'>사고명</TableCell>
                  <TableCell rowSpan={2} align='center'>구분</TableCell>
                  <TableCell colSpan={3} align='center'>등재</TableCell>
                  <TableCell colSpan={3} align='center'>해제</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center'>등재 채널</TableCell>
                  <TableCell align='center'>등재 일자</TableCell>
                  <TableCell align='center'>등재 시간</TableCell>
                  <TableCell align='center'>해제 채널</TableCell>
                  <TableCell align='center'>해제 일자</TableCell>
                  <TableCell align='center'>해제 시간</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterdCardAccidentHistorys.slice((rowsPerPage*page), rowsPerPage*(page+1)).map(history => (
                    <TableRow
                      hover
                      key={Number(`${history.changeDate}${history.changeSeq}`)}
                    >
                    <TableCell>{history.cardAccidentCode.substring(0, history.cardAccidentCode.length-1)}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('accidentCode',history.cardAccidentCode.substring(0,history.cardAccidentCode.length-1))}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('accidentStatusDivisionCode',history.accidentStatusDivisionCode)}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('accidentReceiptDivisionCode',history.accidentReceiptDivisionCode)}</TableCell>
                    <TableCell>{chaiStringUtils.dateFormat(history.accidentEnrollmentDate, null)}</TableCell>
                    <TableCell>{chaiStringUtils.dateTimeFormat(history.accidentEnrollmentTime)}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('accidentReceiptDivisionCode',history.accidentTerminateDivisionCode)}</TableCell>
                    <TableCell>{chaiStringUtils.dateFormat(history.accidentTerminateDate, null)}</TableCell>
                    <TableCell>{chaiStringUtils.dateTimeFormat(history.accidentTerminateTime)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={filterdCardAccidentHistorys.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default CardAccidentHistoryList;
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError, ErrorResponse } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import store from 'store';

// const GRAPHQL_ENDPOINT = `${config.apiScheme}://${config.graphUrl}`;
// const GRAPHQL_SUBSCRIPTIONS_ENDPOINT = `${config.graphqlWSScheme}://${config.graphUrl}`;
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPH_URL
  ? `https://${process.env.REACT_APP_GRAPH_URL}`
  : 'https://local.chai.finance:4100';

// Create a http link:
const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const errorLink = onError(({ networkError, graphQLErrors }: ErrorResponse) => {
  // auth 획득 실패
  if (
    (networkError && 'statusCode' in networkError && networkError.statusCode === 401) ||
    (graphQLErrors && graphQLErrors.find((gqlError: any) => gqlError.extensions.code === 'UNAUTHENTICATED'))
  ) {
    const admin = store.get('admin');
    // 세션이 있었을 경우
    if (admin?.data?.auth?.adminId) {
      store.remove('admin');
      window.location.replace('/');
    } else if (window.location.pathname.length > 1 && window.location.pathname.indexOf('/login') !== 0) {
      console.log(window.location.pathname);
      window.location.replace('/');
    }
  }
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;

import gql from 'graphql-tag';

export const selectOverseasApprHistoryLogAggr = gql`
  mutation selectOverseasApprHistoryLogAggr(
    $salesKindDivisionCode: String,
    $approvalDate: String,
    $dataDivisionCode: String
  ){
      selectOverseasApprHistoryLogAggr(
        salesKindDivisionCode: $salesKindDivisionCode,
        approvalDate: $approvalDate,
        dataDivisionCode: $dataDivisionCode
    ){
        cardNormalApprovalAmountSum
        cardNormalApprovalAmountCount
        cardAbnormalApprovalAmountSum
        cardAbnormalApprovalCount
        cardNormalApprovalCancelAmountSum
        cardNormalApprovalCancelCount
        cardAbnormalApprovalCancelAmountSum
        cardAbnormalApprovalCancelCount
        cardChaimoneyNormalApprovalAmountSum
        cardChaimoneyNormalApprovalCount
        cardChaimoneyAbnormalApprovalAmountSum
        cardChaimoneyAbnormalApprovalCount
        cardChaimoneyNormalApprovalCancelAmountSum
        cardChaimoneyNormalApprovalCancelCount
        cardChaimoneyAbnormalApprovalCancelAmountSum
        cardChaimoneyAbnormalApprovalCancelCount
        cardBcNormalApprovalAmountSum
        cardBcNormalApprovalCount
        cardBcAbnormalApprovalAmountSum
        cardBcAbnormalApprovalCount
        cardBcNormalApprovalCancelAmountSum
        cardBcNormalApprovalCancelCount
        cardBcAbnormalApprovalCancelAmountSum
        cardBcAbnormalApprovalCancelCount
        atmNormalApprovalAmountSum
        atmNormalApprovalCount
        atmAbnormalApprovalAmountSum
        atmAbnormalApprovalCount
        atmNormalApprovalCancelAmountSum
        atmNormalApprovalCancelCount
        atmAbnormalApprovalCancelAmountSum
        atmAbnormalApprovalCancelCount
        atmChaimoneyNormalApprovalAmountSum
        atmChaimoneyNormalApprovalCount
        atmChaimoneyAbnormalApprovalAmountSum
        atmChaimoneyAbnormalApprovalCount
        atmChaimoneyNormalApprovalCancelAmountSum
        atmChaimoneyNormalApprovalCancelCount
        atmChaimoneyAbnormalApprovalCancelAmountSum
        atmChaimoneyAbnormalApprovalCancelCount
        atmBcNormalApprovalAmountSum
        atmBcNormalApprovalCount
        atmBcAbnormalApprovalAmountSum
        atmBcAbnormalApprovalCount
        atmBcNormalApprovalCancelAmountSum
        atmBcNormalApprovalCancelCount
        atmBcAbnormalApprovalCancelAmountSum
        atmBcAbnormalApprovalCancelCount
    }
  }
`;

export const selectOverseasApprHistoryLogList = gql`
  mutation selectOverseasApprHistoryLogList (
    $salesKindDivisionCode: String,
    $approvalDate: String,
    $dataDivisionCode: String,
    $page: Float,
    $rowsPerPage: Float
  ) {
      selectOverseasApprHistoryLogList (
        salesKindDivisionCode: $salesKindDivisionCode,
        approvalDate: $approvalDate,
        dataDivisionCode: $dataDivisionCode,
        page: $page,
        rowsPerPage: $rowsPerPage
    ) {
        totalCount
        dataDivisionCode
        approvalDate
        salesKindDivisionCode
        cardNo
        cardNoMasked
        overseasCardApprovalNo
        cancelAfterUsdBalance
        slipPurchaseYn
        chaiTextApprovalRequestUsdAmount
        bcTextApprovalRequestUsdAmount
        chaiCardApprovalResponseCode
        bcCardApprovalResponseCode
        chaiMoneyPaymentAmount
        chaiMoneyResultCode
    }
  }
`;

export const selectOverseasApprHistoryLogRefundInfo = gql`
  mutation selectOverseasApprHistoryLogRefundInfo (
    $cardNo: String,
    $approvalDate: String,
    $overseasCardApprovalNo: String,
    $cancelAfterUsdBalance: Float
  ){
    selectOverseasApprHistoryLogRefundInfo (
      cardNo: $cardNo,
      approvalDate: $approvalDate,
      overseasCardApprovalNo: $overseasCardApprovalNo,
      cancelAfterUsdBalance: $cancelAfterUsdBalance
    ) {
      tradeDate
      tradeApprovalSeq
      cardNo
      cardNoMasked
      cardSeq
      salesKindDivisionCode
      approvalDate
      approvalTime
      overseasCardApprovalNo
      originApprovalCancelDivisionCode
      merchantNo
      merchantEngName
      approvalProcessStatusCode
      cardApprovalResponseCode
      accountProcessSeq
      inoutDate
      inoutProcessCode
      inoutProcessSeq
      paymentId
      refundDate
      refundProcessCode
      refundProcessSeq
      refundPaymentId
      approvalCancelDate
      approvalCancelTime
      textApprovalRequestUsdAmount
      approvalRequestUsdAmount
      approvalRequestUsdOriginAmount
      requestWithdrawalUsdAmount
      realWithdrawalKrwAmount 
      overseasBrandUsdFee 
      overseasBrandKrwFee
      overseasUseUsdFee
      overseasUseKrwFee 
      overseasWithdrawalUsdFee 
      overseasWithdrawalKrwFee
      memberinfo {
        cardMemberNo
        userKorName
        phoneNo1
        phoneNo2
        phoneNo3
      }
      cardinfo {
        cardSeq
        cardNo
        cardProductCode
        userId
        cardProductCodeName
      }
      cancelinfo {
        tradeDate
        tradeApprovalSeq
        cardNo
        salesKindDivisionCode       
        approvalDate
        approvalTime
        overseasCardApprovalNo
        textApprovalRequestUsdAmount
        requestWithdrawalUsdAmount
        realWithdrawalKrwAmount
        cancelAfterUsdBalance
        approvalCancelStatusDivisionCode
        originCancelApplyUsdBalance
        originCancelApplyKrwBalance
        approvalProcessStatusCode
        cardApprovalResponseCode
        accountProcessSeq
        nonInoutRequestYn
        nonInoutRegisteredDate
        nonInoutSeq
        inoutDate
        inoutProcessCode
        inoutProcessSeq
        paymentId        
        refundDate
        refundProcessCode
        refundProcessSeq
        refundPaymentId
      }
      chaimoneyinfo {
        tradeDate
        idempotencyKey
        paymentId
        paymentAmount
      }
      chaimoneycancelinfo {
        tradeDate
        idempotencyKey
        paymentId
        paymentAmount
      }
    }
  }
`;

export const updateOverseasApprHistoryLogRefund = gql`
  mutation updateOverseasApprHistoryLogRefund(
    $tradeDate: String,
    $tradeApprovalSeq: Float,
    $salesKindDivisionCode: String,
    $approvalCancelStatusDivisionCode: String,
    $approvalDate: String,
    $approvalTime: String,
    $originTradeDate: String,
    $originTradeApprovalSeq: Float,
    $cardNo: String,
    $userId: String,
    $realWithdrawalKrwAmount: Float,
    $merchantEngName: String,
    $refundProcessSeq: String,
    $refundPaymentId: String,
    $accountProcessSeq: Float
  ){
    updateOverseasApprHistoryLogRefund(
      tradeDate: $tradeDate,
      tradeApprovalSeq: $tradeApprovalSeq,
      salesKindDivisionCode: $salesKindDivisionCode,
      approvalCancelStatusDivisionCode: $approvalCancelStatusDivisionCode,
      approvalDate: $approvalDate,
      approvalTime: $approvalTime,
      originTradeDate: $originTradeDate,
      originTradeApprovalSeq: $originTradeApprovalSeq,
      cardNo: $cardNo,
      userId: $userId,
      realWithdrawalKrwAmount: $realWithdrawalKrwAmount,
      merchantEngName: $merchantEngName,
      refundProcessSeq: $refundProcessSeq,
      refundPaymentId: $refundPaymentId,
      accountProcessSeq: $accountProcessSeq
    ){
      result
    }
  }
`;

export const updateOverseasApprHistoryLogPartialCancel = gql`
  mutation updateOverseasApprHistoryLogPartialCancel(
    $tradeDate: String,
    $tradeApprovalSeq: Float,
    $salesKindDivisionCode: String,
    $approvalCancelStatusDivisionCode: String,
    $approvalDate: String,
    $approvalTime: String,
    $originTradeDate: String,
    $originTradeApprovalSeq: Float,
    $originCancelApplyUsdBalance: Float,
    $originCancelApplyKrwBalance: Float,
    $requestWithdrawalUsdAmount: Float,
    $realWithdrawalKrwAmount: Float,
    $cancelAfterUsdBalance: Float
  ){
    updateOverseasApprHistoryLogPartialCancel(
      tradeDate: $tradeDate,
      tradeApprovalSeq: $tradeApprovalSeq,
      salesKindDivisionCode: $salesKindDivisionCode,
      approvalCancelStatusDivisionCode: $approvalCancelStatusDivisionCode,
      approvalDate: $approvalDate,
      approvalTime: $approvalTime,
      originTradeDate: $originTradeDate,
      originTradeApprovalSeq: $originTradeApprovalSeq,
      originCancelApplyUsdBalance: $originCancelApplyUsdBalance,
      originCancelApplyKrwBalance: $originCancelApplyKrwBalance,
      requestWithdrawalUsdAmount: $requestWithdrawalUsdAmount,      
      realWithdrawalKrwAmount: $realWithdrawalKrwAmount,
      cancelAfterUsdBalance: $cancelAfterUsdBalance
    ){
      result
    }
  }
`;


import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import CardReturnManageToolbar from './CardReturnManageToolbar';
import CardList from './CardList';
import IssueReturnHistoryList from './IssueReturnHistoryList';

import { useToast } from '../../contexts/Toast';
import { cardlist, issueReturnHistoryList } from './cardApi';
import * as utils from '../../common/utils';
import {format, subMonths} from 'date-fns';
import { MenuTitleToolbar } from '../../components';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { ExportCardListIntoExcel } from './ExportCardListIntoExcel';
import { ExportIssueReturnHistoryListIntoExcel } from './ExportIssueReturnHistoryListIntoExcel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
}));

type SearchInfo = {
  issueStatusCode: string;    // 카드상태 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송수령
  searchDateGubun: string;    // 조회일자구분
  searchStartDate: string;    // 시작일자
  searchEndDate: string;      // 종료일자
  searchGubun: string;        // 조회구분(고객번호, 카드번호, 휴대폰번호)
  searchVal: string;
}
type Props = {
  className?: string,
}
const CardReturnManage: FC<Props> = ({ className }) => {
  
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { showToast } = useToast();

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    issueStatusCode: '1',    // 카드상태 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송수령
    searchDateGubun: 'issueDate',    // 조회일자구분
    searchStartDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),    // 시작일자
    searchEndDate: format(new Date(), 'yyyy-MM-dd'),      // 종료일자
    searchGubun: chaiStringUtils.getSchParamGubun(),        // 조회구분(고객번호, 카드번호, 휴대폰번호)
    searchVal: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun())
  });

  // 카드목록
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 카드목록 조회 card-server
  const fetch = async (searchInputForm: SearchInfo) => {
    console.log('useEffect 실행 - 카드목록 조회 card-server INPUT: ', searchInputForm);

    // 발송반송내역 초기화
    setCardNo('');

    try {
      setLoading(true);
      const result = await cardlist ({
        issueStatusCode: searchInputForm.issueStatusCode,    // 카드상태 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송수령
        searchDateGubun: searchInputForm.searchDateGubun,    // 조회일자구분
        searchStartDate: searchInputForm.searchStartDate,    // 시작일자
        searchEndDate: searchInputForm.searchEndDate,        // 종료일자
        searchGubun: searchInputForm.searchGubun,            // 조회구분(고객번호, 카드번호, 휴대폰번호)
        searchVal: searchInputForm.searchVal
      });

      console.log('카드목록 조회 : ', result);
      setLoading(false);

      // 카드목록 세팅
      setCards(result);
    } catch(e) {
      let errorMessage = '';
      errorMessage = '서버와의 통신이 원활하지 않습니다. \n관리자에게 문의하시기 바랍니다.';
      showToast(errorMessage, 'error');
    }
  }


  // 발송반송 내역 조회
  const [cardNo, setCardNo] = useState(''); // set [카드번호]
  const [issueReturnHistorys, setIssueReturnHistorys] = useState([]);

  // 발송반송 내역 조회
  useEffect(() => {
    console.log('useEffect 실행 - 발송반송내역 목록 조회 card-server');
      issueReturnHistoryFetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNo]);

  // 발송반송 내역 조회 card-server
  const issueReturnHistoryFetch = async () => {
    console.log('CARD_NO: ', cardNo);

    try {
      setLoading2(true);
      const result = await issueReturnHistoryList (cardNo);

      console.log('발송반송내역 목록 조회 : ', result);
      setLoading2(false);

      // 발송반송내역 목록 세팅
      setIssueReturnHistorys(result);

    } catch(e) {
      let errorMessage = '';
      errorMessage = '서버와의 통신이 원활하지 않습니다. \n관리자에게 문의하시기 바랍니다.';
      showToast(errorMessage, 'error');
    }
  }
  
  // 카드목록 엑셀다운로드
  const [ cardListExcelClicked, setCardListExcelClicked ] = useState(false);

  // 발송반송내역 엑셀다운로드
  const [issueReturnHistoryListExcelClicked, setIssueReturnHistoryListExcelClicked] = useState(false);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="반송카드관리" />
      <CardReturnManageToolbar onSearch={fetch} searchForm={searchForm} setSearchForm={setSearchForm}/>
      <div className={classes.content}>
        <ExportCardListIntoExcel
          className={classes.registerButton}
          csvData={cards}
          clicked={cardListExcelClicked}
          setClicked={setCardListExcelClicked}
        />

        <br/>
        <br/>
        <br/>

        {loading
          ? 
            utils.Loading()
          :
          <CardList title='카드목록' cards={cards} setCardNo={setCardNo}/>
        }

        <ExportIssueReturnHistoryListIntoExcel
          className={classes.registerButton}
          csvData={issueReturnHistorys}
          clicked={issueReturnHistoryListExcelClicked}
          setClicked={setIssueReturnHistoryListExcelClicked}
        />

        <br/>
        <br/>
        <br/>
        
        {loading2
          ? 
            utils.Loading()
          :
            <IssueReturnHistoryList title="발송반송내역" issueReturnHistorys={issueReturnHistorys} issueReturnHistoryFetch={issueReturnHistoryFetch}/>
        }
        
      </div>
    </div>
 )
}

export default CardReturnManage;
import React, { FC, useState, useEffect } from 'react';
import apolloClient from '../../lib/apolloClient';
import { cancelEdiPayHistoryMutation } from '../../lib/apolloClient/mutations';
import CancelEdiPayHistoryListToolbar from './CancelEdiPayHistoryListToolbar';
import CancelEdiPayHistoryTable from './CancelEdiPayHistoryTable';
import CancelEdiPayHistoryDetail from './CancelEdiPayHistoryDetail';
import { makeStyles, Theme } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import { format, subMonths } from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  detail: {
    marginTop: theme.spacing(3)
  }
}));

type Props = { 
  cancelEdiPayHistoryDetail: CancelEdiPayHistoryDetailInfo
}

const CancelEdiPayHistory: FC<Props> = ({ cancelEdiPayHistoryDetail }) => {
 
  const classes = useStyles();
  const [listData, setListData] = useState([])
  const [listSearch, setListSearch] = useState({
    startReceveBaseDate: format(subMonths(new Date(), 1), 'yyyyMMdd'), 
    endReceveBaseDate: format(new Date(), 'yyyyMMdd'), 
    slipPurchaseYn: '', 
    cardNo: chaiStringUtils.getSchParamCardNo()
  });

  const [detailData, setDetailData] = useState(cancelEdiPayHistoryDetail);

  useEffect(() => {
    const getList = async () => {
      await apolloClient.mutate({
        mutation: cancelEdiPayHistoryMutation.selectCancelEdiPayHistoryList,
        variables: { startReceveBaseDate: listSearch.startReceveBaseDate, endReceveBaseDate: listSearch.endReceveBaseDate, slipPurchaseYn: listSearch.slipPurchaseYn, cardNo: chaiStringUtils.encrypt(listSearch.cardNo) }
      }).then(res => {        
        setListData(res.data.selectCancelEdiPayHistoryList)
      }).catch(console.error);
    }
    getList()

  }, [listSearch])

  useEffect(() => {
    const getDetail = async () => {
      await apolloClient.mutate({
        mutation: cancelEdiPayHistoryMutation.selectCancelEdiPayHistoryInfo,
        variables: { startReceveBaseDate: listSearch.startReceveBaseDate, endReceveBaseDate: listSearch.endReceveBaseDate, slipPurchaseYn: listSearch.slipPurchaseYn, cardNo: chaiStringUtils.encrypt(listSearch.cardNo) }
      }).then(res => {        
        setDetailData(res.data.selectCancelEdiPayHistoryInfo)
      }).catch(console.error);
    }
    getDetail()
    
  }, [listSearch])
 
  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="EDI취소선지급내역 조회" />
      <CancelEdiPayHistoryListToolbar /** 검색조건 */ onSearch={setListSearch}/>
      <div className={classes.content}>
        <CancelEdiPayHistoryTable /** 취소선지급내역LIST */ dataList={listData} />
      </div>
      <CancelEdiPayHistoryDetail /** 취소선지급집계 */ className={classes.detail} detailInfo={detailData} />
    </div>
  );
};

export default CancelEdiPayHistory;

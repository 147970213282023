import gql from 'graphql-tag';

// 대표가맹점 마스터정보 조회
export const selectMasterMerchantMgmtList = gql`
  mutation selectMasterMerchantMgmtList(
    $inputMasterMerchantNo: String, 
    $inputMasterMerchantName: String   
    $inputMerchantNo: String,
    $inputCancelYn: String
    ) {
      selectMasterMerchantMgmtList (
        masterMerchantNo: $inputMasterMerchantNo, 
        masterMerchantName: $inputMasterMerchantName,        
        merchantNo: $inputMerchantNo,
        cancelYn: $inputCancelYn
      ) {
        masterMerchantNo
        masterMerchantName        
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
    }
  }
`;

// 대표가맹점+가맹점정보 상세목록조회
export const selectMasterMerchantMgmtDetailList = gql`
  mutation selectMasterMerchantMgmtDetailList(
    $inputMasterMerchantNo: String,
    $inputCancelYn: String
    ) {
      selectMasterMerchantMgmtDetailList (
      masterMerchantNo: $inputMasterMerchantNo,
      cancelYn: $inputCancelYn
      ) {
      receveSeq
      receveBaseDate
      masterMerchantNo
      masterMerchantName
      merchantNo
      merchantName
      registeredDate
      canceledDate
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      useYn      
      merchantInfo {
        receveSeq
        receveBaseDate
        registeredDate
        merchantNo
        merchantBizNo
        merchantName
        merchantEngName
        officerName
        businessCode
        businessSubCode
        telNo1
        telNo2
        telNo3
        terminateDate
        salesDivision
        edcMerchantYn
        settlementProcessCycle
        feeRate
        cardFeeRate
        branchGiroNo
        tradeBlockYn
        accountBankCode
        tradeBlockStartDate
        tradeBlockEndDate
        installmentMonth
        paymentAcno
        merchantAddressDivisionCode
        merchantRoadAddress01
        merchantRoadAddress02
        merchantRoadAddressManagementNo
        merchantPostNo
        merchantEtcAddress
        remarkDescription
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
      }
    }
  } 
`;

// 대표가맹점 마스터 삽입
export const insertMasterMerchantMaster = gql`
  mutation insertMasterMerchantMaster($input: masterMerchantMgmtInputType) {
    insertMasterMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 마스터 수정
export const updateMasterMerchantMaster = gql`
  mutation updateMasterMerchantMaster($input: masterMerchantMgmtInputType) {
    updateMasterMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 마스터 삭제
export const deleteMasterMerchantMaster = gql`
  mutation deleteMasterMerchantMaster($input: masterMerchantMgmtInputType) {
    deleteMasterMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 상세 신규
export const insertMasterMerchantMgmtDetail = gql`
  mutation insertMasterMerchantMgmtDetail($input: masterMerchantMgmtDetailInputType) {
    insertMasterMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 상세 수정
export const updateMasterMerchantMgmtDetail = gql`
  mutation updateMasterMerchantMgmtDetail($input: masterMerchantMgmtDetailInputType) {
    updateMasterMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 상세 삭제
export const deleteMasterMerchantMgmtDetail = gql`
  mutation deleteMasterMerchantMgmtDetail($input: masterMerchantMgmtDetailInputType) {
    deleteMasterMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 대표가맹점 엑셀업로드
export const masterMerchantMgmtExcelUpload = gql`
  mutation masterMerchantMgmtExcelUpload($inputList: [masterMerchangetMgmtExcelUploadInputType]) {
    masterMerchantMgmtExcelUpload(inputList: $inputList) {
      success
      message
    }
  }
`;


// 대표가맹점번호 순번 Max+1 가져오기
export const selectMasterMerchantMgmtMaxNoGet = gql`
  mutation selectMasterMerchantMgmtMaxNoGet {
    selectMasterMerchantMgmtMaxNoGet {
      masterMerchantNoMax
    }
  }
`;
import React, { FC, ChangeEvent, useState } from 'react';
import clsx from 'clsx';

import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  CardActions,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { productMgmtMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  position: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    float: 'right',
  },
}));

type Props = {
  className?: string;
  productMgmt: ProductMgmt;
  setProductMgmt: React.Dispatch<React.SetStateAction<ProductMgmt>>;
  updateClick: boolean;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProductMgmtDetails: FC<Props> = ({ className, updateClick, setUpdateClick, productMgmt, setProductMgmt }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  // const [groupData, setGroupData] = useState([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProductMgmt({
      ...productMgmt,
      [e.target.name]: e.target.value,
    });
  };

  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    if ((isEmpty(productMgmt.cardProductGroupCode) || isEmpty(productMgmt.cardProductCode)) === false) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
    setUpdateClick(updateClick);
  };

  // eslint-disable-next-line
  const [isEmptyForm, setIsEmptyForm] = useState(true);

  const checkFormEmpty = () => {
    if (
      (isEmpty(productMgmt.cardProductGroupCode) &&
        isEmpty(productMgmt.cardProductCode) &&
        isEmpty(productMgmt.cardProductGroupName) &&
        isEmpty(productMgmt.cardProductCodeName) &&
        isEmpty(productMgmt.brandDivisionCode) &&
        isEmpty(productMgmt.useYn)) === true
    ) {
      setIsEmptyForm(true);
    } else {
      setIsEmptyForm(false);
    }
  };

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
    // 신규
    if (brandDivisionCode === null || productMgmt.brandDivisionCode === '') {
      showToast('브랜드가 선택되지 않았습니다.');
      hideDialog();
      return;
    } else if (productMgmt.cardProductCode === null || productMgmt.cardProductCode === '') {
      showToast('카드상품코드를 입력하세요.');
      hideDialog();
      return;
    } else if (productMgmt.cardProductGroupCode === null || productMgmt.cardProductGroupCode === '') {
      showToast('카드상품코드를 입력하세요.');
      hideDialog();
      return;
    } else if (productMgmt.useYn === null || productMgmt.useYn === '') {
      showToast('사용여부를 입력하세요.');
      hideDialog();
      return;
    }

    console.log('#######');
    let check = false;
    await apolloClient
      .mutate({
        variables: {
          input: {
            cardProductGroupCode: productMgmt.cardProductGroupCode,
            cardProductCode: productMgmt.cardProductCode,
            cardProductGroupName: productMgmt.cardProductGroupName,
            cardProductCodeName: productMgmt.cardProductCodeName,
            brandDivisionCode: productMgmt.brandDivisionCode,
            useYn: productMgmt.useYn,
          },
        },
        mutation: productMgmtMutation.insertProductMgmt,
      })
      .then(res => {
        if (res.data.insertProductMgmt.useYn === '동일코드') {
          hideDialog();
          showDialog('카드상품코드', '카드상품코드가 기존코드와 같을 수 없습니다.\n\n', () => {
            hideDialog();
          });
          return;
        }

        if (res.data.insertProductMgmt.cardProductGroupCode === 'xxx') {
          check = true;
        }
        console.log(check);
        if (check) {
          hideDialog();
          showDialog('카드정보', '동일한 값은 저장할 수 없습니다.\n\n', () => {
            hideDialog();
          });
        } else {
          hideDialog();
          showDialog('', '저장되었습니다.\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'Error');
      });
    setProductMgmt({
      ...productMgmt,
      cardProductGroupCode: '',
      cardProductCode: '',
      cardProductGroupName: '',
      cardProductCodeName: '',
      brandDivisionCode: '',
      useYn: '',
    });
    setUpdateClick(!updateClick);
    setIsValidForm(false);
  };

  const handleConfirmUpdConfirm = async () => {
    //수정
    console.log('###### Update Product Code');
    if (brandDivisionCode === null || productMgmt.brandDivisionCode === '') {
      showToast('브랜드가 선택되지 않았습니다.');
      hideDialog();
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          input: {
            cardProductGroupCode: productMgmt.cardProductGroupCode,
            cardProductCode: productMgmt.cardProductCode,
            cardProductGroupName: productMgmt.cardProductGroupName,
            cardProductCodeName: productMgmt.cardProductCodeName,
            brandDivisionCode: productMgmt.brandDivisionCode,
            useYn: productMgmt.useYn,
          },
        },
        mutation: productMgmtMutation.updateProductMgmt,
      })
      .then(res => {
        if (res.data.updateProductMgmt.useYn === '동일코드') {
          hideDialog();
          showDialog('', '잘못된 요청입니다.\n\n', () => {
            hideDialog();
          });
          return;
        }
        console.log(res.data);
        setProductMgmt({
          ...productMgmt,
          cardProductGroupCode: '',
          cardProductCode: '',
          cardProductGroupName: '',
          cardProductCodeName: '',
          brandDivisionCode: '',
          useYn: '',
        });
        hideDialog();
        showDialog('카드정보', '수정되었습니다\n\n', () => {
          hideDialog();
        });
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.messgae, 'Error');
      });
    setUpdateClick(!updateClick);
    setIsValidForm(false);
  };

  const initTextFields = () => {
    setProductMgmt({
      ...productMgmt,
      cardProductGroupCode: '',
      cardProductCode: '',
      cardProductGroupName: '',
      cardProductCodeName: '',
      brandDivisionCode: '',
      useYn: '',
    });
    setIsEmptyForm(false);
    setUpdateClick(!updateClick);
  };

  const isEmpty = (input: string) => {
    return input === '';
  };

  const brandDivisionCode = [
    { value: '', label: '선택' },
    { value: '0', label: 'GLOBAL BC' },
    { value: '1', label: 'LOCAL' },
    { value: '2', label: 'MC' },
    { value: '3', label: 'VISA' },
    { value: '4', label: 'JCB' },
  ];

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="<상품정보>" />
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell>카드상품그룹코드</TableCell>
            <TableCell>
              <TextField
                label="카드상품그룹코드"
                margin="dense"
                name="cardProductGroupCode"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={productMgmt.cardProductGroupCode}
                helperText={isEmpty(productMgmt.cardProductGroupCode) ? 'Required' : ''}
                variant="outlined"
              />
            </TableCell>
            <TableCell>카드상품그룹명</TableCell>
            <TableCell>
              <TextField
                label="카드상품그룹명"
                margin="dense"
                name="cardProductGroupName"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={productMgmt.cardProductGroupName}
                variant="outlined"
              />
            </TableCell>
            <TableCell>카드상품코드</TableCell>
            <TableCell>
              <TextField
                label="카드상품코드"
                margin="dense"
                name="cardProductCode"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                required
                value={productMgmt.cardProductCode}
                variant="outlined"
                helperText={isEmpty(productMgmt.cardProductCode) ? 'Required' : ''}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>카드상품코드명</TableCell>
            <TableCell>
              <TextField
                label="카드상품코드명"
                margin="dense"
                name="cardProductCodeName"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={productMgmt.cardProductCodeName}
                variant="outlined"
              />
            </TableCell>
            <TableCell>브랜드구분</TableCell>
            <TableCell>
              <TextField
                label="브랜드구분"
                margin="dense"
                name="brandDivisionCode"
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
                value={productMgmt.brandDivisionCode}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {brandDivisionCode.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </TableCell>
            <TableCell>사용여부</TableCell>
            <TableCell>
              <RadioGroup
                name="useYn"
                value={productMgmt.useYn}
                onChange={e => {
                  handleChange(e);
                  checkFormValidation();
                  checkFormEmpty();
                }}
              >
                <FormControlLabel value="Y" control={<Radio />} label="Y" defaultChecked />
                <FormControlLabel value="N" control={<Radio />} label="N" />
              </RadioGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button //신규
          color="primary"
          variant="contained"
          onClick={() => showDialog('카드정보', '해당 정보로 저장하시겠습니까?', handleConfirmAddConfirm)}
        >
          ADD
        </Button>

        <Button //수정
          color="primary"
          variant="contained"
          onClick={() => showDialog('카드정보', '해당 정보로 수정하시겠습니까?', handleConfirmUpdConfirm)}
          disabled={!isValidForm}
        >
          EDIT
        </Button>
        <Button //초기화
          color="primary"
          variant="contained"
          onClick={initTextFields}
        >
          RESET
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductMgmtDetails;

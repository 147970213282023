import { Box, Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const StyledTableCell = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = { className?: string; merchantInfo?: GetHanaMerchantDetail; bcMerchantInfo?: GetMerchantDetail };
const CustomerInfo: FC<Props> = ({ className, merchantInfo, bcMerchantInfo }) => {
  const classes = useStyles();

  if (merchantInfo === undefined) {
    return (
      <Card className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="가맹점 상세 정보" />
          <Divider />
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>선택한 가맹점이 없습니다.</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Divider />
        </form>
      </Card>
    );
  }

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="가맹점 상세 정보 - 신용(하나)" />
          <Divider />
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>사업자등록번호</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.bizNoFormat(merchantInfo.businessNumber)}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>가맹점명</StyledTableCell>
                <StyledTableCell>{merchantInfo.name}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>업종코드</StyledTableCell>
                <StyledTableCell>{merchantInfo.businessCode}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>그룹가맹점 ID</StyledTableCell>
                <StyledTableCell>{merchantInfo.groupIds && merchantInfo.groupIds.join(',')}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Divider />
        </form>
      </Card>
      <Box p={1} />
      {bcMerchantInfo && ['matched', 'on_ready'].includes(merchantInfo.mappingStatus) && (
        <Card className={clsx(classes.root, className)}>
          <form>
            <CardHeader title="가맹점 상세 정보 - 체크(비씨)" />
            <Divider />
            <Table>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>등록일자</StyledTableCell>
                  <StyledTableCell>{chaiStringUtils.dateFormat(bcMerchantInfo.registeredDate, null)}</StyledTableCell>
                  <StyledTableCell>가맹점번호</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantNo}</StyledTableCell>
                  <StyledTableCell>사업자등록번호</StyledTableCell>
                  <StyledTableCell>{chaiStringUtils.bizNoFormat(bcMerchantInfo.merchantBizNo)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>가맹점명</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantName}</StyledTableCell>
                  <StyledTableCell>영문가맹점명</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantEngName}</StyledTableCell>
                  <StyledTableCell>대표자명</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.officerName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>업종코드</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.businessCode}</StyledTableCell>
                  <StyledTableCell>업종부코드</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.businessSubCode}</StyledTableCell>
                  <StyledTableCell>가맹점전화번호</StyledTableCell>
                  <StyledTableCell>
                    {bcMerchantInfo.telNo1} {bcMerchantInfo.telNo2} {bcMerchantInfo.telNo3}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>해지일자</StyledTableCell>
                  <StyledTableCell>{chaiStringUtils.dateFormat(bcMerchantInfo.terminateDate, null)}</StyledTableCell>
                  <StyledTableCell>판매구분</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.salesDivision}</StyledTableCell>
                  <StyledTableCell>EDC가맹점여부</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.edcMerchantYn}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>대금지급주기</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.settlementProcessCycle} 일</StyledTableCell>
                  <StyledTableCell>수수료율</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.feeRate} %</StyledTableCell>
                  <StyledTableCell>체크카드수수료율</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.cardFeeRate} %</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>지점지로번호</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.branchGiroNo}</StyledTableCell>
                  <StyledTableCell>거래제한여부</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.tradeBlockYn}</StyledTableCell>
                  <StyledTableCell>계좌은행코드</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.accountBankCode}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>거래제한등재일자</StyledTableCell>
                  <StyledTableCell>
                    {chaiStringUtils.dateFormat(bcMerchantInfo.tradeBlockStartDate, null)}
                  </StyledTableCell>
                  <StyledTableCell>거래제한해제일자</StyledTableCell>
                  <StyledTableCell>
                    {chaiStringUtils.dateFormat(bcMerchantInfo.tradeBlockEndDate, null)}
                  </StyledTableCell>
                  <StyledTableCell>할부월수</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.installmentMonth}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>결제계좌번호</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.paymentAcno}</StyledTableCell>
                  <StyledTableCell>사업장신구주소구분코드</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantAddressDivisionCode}</StyledTableCell>
                  <StyledTableCell>사업장동이상신규주소</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantRoadAddress01}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>사업장동이하신규주소</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantRoadAddress02}</StyledTableCell>
                  <StyledTableCell>사업장도로명관리번호</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantRoadAddressManagementNo}</StyledTableCell>
                  <StyledTableCell>가맹점우편번호</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantPostNo}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>가맹점기타주소</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.merchantEtcAddress}</StyledTableCell>
                  <StyledTableCell>비고내용</StyledTableCell>
                  <StyledTableCell>{bcMerchantInfo.remarkDescription}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
            <Divider />
          </form>
        </Card>
      )}
    </>
  );
};

export default CustomerInfo;

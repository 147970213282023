import 'date-fns';
import React, { FC, useState } from 'react';
import { Grid, TextField, Card, CardContent, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<CancelEdiPayHistorySearch>>;
};

const CancelEdiPayHistoryListToolbar: FC<Props> = ({ onSearch }) => {
  const [pStartReceveBaseDate, setStartReceveBaseDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [pEndReceveBaseDate, setEndReceveBaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [pSlipPurchaseYn, setSlipPurchaseYn] = useState('');
  const [pCardNo, setCardNo] = useState(chaiStringUtils.getSchParamCardNo());

  const { showToast } = useToast();
  const { hideDialog } = useDialog();

  const handleStartReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartReceveBaseDate(e.target.value);
  };

  const handleEndReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndReceveBaseDate(e.target.value);
  };

  const handleSlipPurchaseYnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlipPurchaseYn(event.target.value);
  };

  const handleCardNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardNo(event.target.value);
  };

  const onSearchClick = async () => {
    const cancelEdiPayHistorySearch: CancelEdiPayHistorySearch = {} as any;

    if (pStartReceveBaseDate == null) {
      showToast('Chai정산시작일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    if (pEndReceveBaseDate == null) {
      showToast('Chai정산종료일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    cancelEdiPayHistorySearch.startReceveBaseDate = chaiStringUtils.dateFormat(pStartReceveBaseDate, 'yyyyMMdd');
    cancelEdiPayHistorySearch.endReceveBaseDate = chaiStringUtils.dateFormat(pEndReceveBaseDate, 'yyyyMMdd');
    cancelEdiPayHistorySearch.slipPurchaseYn = pSlipPurchaseYn;
    cancelEdiPayHistorySearch.cardNo = pCardNo;

    // 공통 검색 파라미터 저장
    chaiStringUtils.setSchParamCardNo(pCardNo);

    onSearch(cancelEdiPayHistorySearch);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pStartReceveBaseDate"
              value={pStartReceveBaseDate}
              onChange={handleStartReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산종료일자"
              margin="dense"
              InputProps={{
                inputProps: {
                  min: pStartReceveBaseDate ? format(new Date(pStartReceveBaseDate), 'yyyy-MM-dd') : undefined,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pEndReceveBaseDate"
              value={pEndReceveBaseDate}
              onChange={handleEndReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              fullWidth
              label="전표매입여부"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pSalesKindDivisionCode"
              value={pSlipPurchaseYn}
              onChange={handleSlipPurchaseYnChange}
            >
              <option value="">전체</option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="카드번호"
              placeholder="카드번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pCardNo"
              value={pCardNo}
              onChange={handleCardNoChange}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button color="primary" variant="contained" onClick={onSearchClick} style={{ marginTop: '9px' }}>
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CancelEdiPayHistoryListToolbar;

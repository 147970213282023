import gql from 'graphql-tag';

// 회원정보 단건 조회
export const cardDeliveryInfoSelectCardMemberInfo = gql`
  mutation cardDeliveryInfoSelectCardMemberInfo($searchGubun: String, $searchVal: String) {
    cardDeliveryInfoSelectCardMemberInfo(searchGubun: $searchGubun, searchVal: $searchVal) {
      cardMemberNo
      userId
      personalCorporationDivisionCode
      userKorName
      userEngName
      firstJoinDate
      joinDate
      validateYn
      terminateDate
      sexDivisionCode
      emailAddress
      billSendCode
      claimAddressDivisionCode
      homeNo1
      homeNo2
      homeNo3
      phoneNo1
      phoneNo2
      phoneNo3
      paymentDate
      homeNewAddressDivisionCode
      homeZipCode
      homeNewAddress
      homeNewDetailAddress
      foreignerDivisionCode
      birthYm
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      lastOperateAdminId
      lastOperateDetailDate
      lastOperateTradeCode
    }
  }
`;

// 보유카드목록 다건 조회
export const cardDeliveryInfoSelectCardList = gql`
  mutation cardDeliveryInfoSelectCardList($cardMemberNo: String) {
    cardDeliveryInfoSelectCardList(cardMemberNo: $cardMemberNo) {
      cardSeq
      tradeUniqNo
      cardNo
      cardNoMasked
      cardMemberNo
      userId
      cardOwnrDivisionCode
      cardOwnrName
      validateTermYm
      lastCardYn
      beforeCardNo
      beforeCardSeq
      cardProductCode
      paymentDate
      requestIssueDivisionCode
      trafficFunctionDivisionCode
      cashFunctionYn
      icNo
      cardStatusCode
      requestDate
      issueDate
      issueTime
      sendbackDate
      disposalDate
      receveDate
      openDate
      terminateDate
      sendDate
      cardSendAddressDivisionCode
      sendTypeDivisionCode
      terminalMediaDivisionCode
      cancelYn
      firstTradeDate
      cardProductGatradeYn
      ancillaryServiceGatradeYn
      uniqDiscriminationNoCollectYn
      financeGroupOfferYn
      iniquityUsePreventionOfferYn
      allianceAgencyOfferYn
      uniqDiscriminationNoOfferYn
      telCreditCardAdviceYn
      smsCreditCardAdviceYn
      writtenCreditCardAdviceYn
      emailCreditCardAdviceYn
      smsSendYn
      brandDivisionCode
      firstCardSeq
      firstCardNo
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      lastOperateAdminId
      lastOperateDetailDate
      lastOperateTradeCode
      cardProduct {
        cardProductCode
        cardProductCodeName
      }
      cardAccidentReceiptHistory {
        changeDate
        changeSeq
        tradeUniqNo
        accidentDscmNo
        accidentDscmDivisionCode
        cardAccidentCode
        cardSeq
        cardNo
        accidentStatusDivisionCode
        accidentEnrollmentDate
        accidentEnrollmentTime
        accidentTerminateDate
        accidentTerminateTime
        beforeAccidentSeq
        beforeAccidentDate
        beforeCardStatusCode
        validateTermYm
        reporterName
        telNo
        userLostDate
        lostAreaName
        accidentReceiptNo
        accidentReceiptEntityCode
        accidentReceiptDivisionCode
        accidentTerminateDivisionCode
        foreignLostYn
        cardMemberNo
        requestAdminId
        userId
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
      }
    }
  }
`;

// 보유카드목록 단건 조회
export const cardDeliveryInfoSelectCardInfo = gql`
  mutation cardDeliveryInfoSelectCardInfo($cardNo: String) {
    cardDeliveryInfoSelectCardInfo(cardNo: $cardNo) {
      cardSeq
      tradeUniqNo
      cardNo
      cardMemberNo
      userId
      cardOwnrDivisionCode
      cardOwnrName
      validateTermYm
      lastCardYn
      beforeCardNo
      beforeCardSeq
      cardProductCode
      paymentDate
      requestIssueDivisionCode
      trafficFunctionDivisionCode
      cashFunctionYn
      icNo
      cardStatusCode
      requestDate
      issueDate
      issueTime
      sendbackDate
      disposalDate
      receveDate
      openDate
      terminateDate
      sendDate
      cardSendAddressDivisionCode
      sendTypeDivisionCode
      terminalMediaDivisionCode
      cancelYn
      firstTradeDate
      cardProductGatradeYn
      ancillaryServiceGatradeYn
      uniqDiscriminationNoCollectYn
      financeGroupOfferYn
      iniquityUsePreventionOfferYn
      allianceAgencyOfferYn
      uniqDiscriminationNoOfferYn
      telCreditCardAdviceYn
      smsCreditCardAdviceYn
      writtenCreditCardAdviceYn
      emailCreditCardAdviceYn
      smsSendYn
      brandDivisionCode
      firstCardSeq
      firstCardNo
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      lastOperateAdminId
      lastOperateDetailDate
      lastOperateTradeCode
      cardProduct {
        cardProductCode
        cardProductCodeName
      }
      cardAccidentReceiptHistory {
        changeDate
        changeSeq
        tradeUniqNo
        accidentDscmNo
        accidentDscmDivisionCode
        cardAccidentCode
        cardSeq
        cardNo
        accidentStatusDivisionCode
        accidentEnrollmentDate
        accidentEnrollmentTime
        accidentTerminateDate
        accidentTerminateTime
        beforeAccidentSeq
        beforeAccidentDate
        beforeCardStatusCode
        validateTermYm
        reporterName
        telNo
        userLostDate
        lostAreaName
        accidentReceiptNo
        accidentReceiptEntityCode
        accidentReceiptDivisionCode
        accidentTerminateDivisionCode
        foreignLostYn
        cardMemberNo
        requestAdminId
        userId
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
      }
    }
  }
`;

import { makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import apolloClient from '../../lib/apolloClient';
import { settlementAutoMutation } from '../../lib/apolloClient/mutations';
import SettlementAutoTable from './SettlementAutoTable';
import SettlementAutoToolbar from './SettlementAutoToolbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type SearchInfo = {
  feeSettlementYm: string; // 정산년월
  proxyFeeCodeNo: string; // 위임수수료코드번호
  domesticCode: string; // 국내외구분코드
};

type Props = {};

const SettlementAuto: FC<Props> = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  // const [settlementAutoAggrData, setSettlementAutoAggrData] = useState({} as SettlementAutoAggr);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    // 정산년월
    feeSettlementYm:
      chaiStringUtils.getFeeSettlementYm() === ''
        ? format(new Date(), 'yyyy-MM')
        : chaiStringUtils.getFeeSettlementYm(),
    proxyFeeCodeNo: chaiStringUtils.getProxyFeeCodeNo(), // 위임수수료코드번호
    domesticCode: chaiStringUtils.getDomesticCode(), // 국내외구분코드
  });

  useEffect(() => {
    onSearch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 대행수수료 정산내역 목록 조회
  const onSearch = async (searchForm: SearchInfo) => {
    console.log('대행수수료 자동대사 목록 조회 시작 : ', searchForm);

    // 공통 파라미터 Set
    chaiStringUtils.setFeeSettlementYm(searchForm.feeSettlementYm);
    chaiStringUtils.setProxyFeeCodeNo(searchForm.proxyFeeCodeNo);
    chaiStringUtils.setDomesticCode(searchForm.domesticCode);

    //초기화
    setData([]);

    await apolloClient
      .mutate({
        variables: {
          feeSettlementYm: searchForm.feeSettlementYm, // 정산년월
          proxyFeeCodeNo: searchForm.proxyFeeCodeNo, // 위임수수료코드번호
          domesticCode: searchForm.domesticCode, // 국내외구분코드
        },
        mutation: settlementAutoMutation.selectSettlementAuto,
      })
      .then(res => {
        console.log('대행수수료 자동대사 조회 결과 : ', res.data.selectSettlementAutoList);
        setData(res.data.selectSettlementAutoList);
        fetch(searchForm);
      })
      .catch(console.error);
  };

  // 대행수수료 정산내역 합계 조회
  const fetch = async (searchForm: SearchInfo) => {
    console.log('대행수수료 합계 조회 시작 : ', searchForm);
    //초기화
    // setSettlementAutoAggrData({} as SettlementAutoAggr);

    await apolloClient
      .mutate({
        variables: {
          feeSettlementYm: searchForm.feeSettlementYm, // 정산년월
        },
        mutation: settlementAutoMutation.selectSettlementAutoAggr,
      })
      .then(res => {
        console.log('대행수수료 정산내역 합계 조회 : ', res.data.selectSettlementAutoAggr);
        // setSettlementAutoAggrData(res.data.selectSettlementAutoAggr);
      })
      .catch(console.error);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="대행수수료 정산내역" />
      <SettlementAutoToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />
      {/* <div className={classes.content}>
                <SettlementAutoAggrTable settlementAutoAggrData={settlementAutoAggrData} />
            </div> */}
      <div className={classes.content}>
        <SettlementAutoTable title="대행수수료 정산내역 목록" settlementAutoList={data} />
      </div>
    </div>
  );
};

export default SettlementAuto;

import gql from 'graphql-tag';

// 매출내역 목록 조회
export const selectSalesDetails = gql`
    mutation selectSalesDetailsList(
        $criteriaYn: String,
        $page: Float, 
        $rowsPerPage: Float,
        $isExcelDown: Boolean
    ) {
        selectSalesDetailsList(
            criteriaYn: $criteriaYn,
            page: $page,
            rowsPerPage: $rowsPerPage,
            isExcelDown: $isExcelDown
        ){
            totalCount
            list{
                depositDate
                salesCount
                salesTotalAmount
                merchantFee
                issuerFee
            }
        }
      }
`;

// 매출내역 합계 조회
export const selectSalesDetailsSum = gql`
      mutation selectSalesDetailsSumList (
        $criteriaYn: String
      ) {
        selectSalesDetailsSumList(
            criteriaYn : $criteriaYn
        ){
            sumsalescount	
            sumsalestotalamount
            summerchantfee 
            sumissuerfee	
        }
      }
`;

import React, { useState, useEffect} from 'react';
import {Theme, makeStyles, Button} from '@material-ui/core';
import {MenuTitleToolbar} from '../../components';
import OverseasApprListToolbar from './OverseasApprListToolbar';
import OverseasApprListTable from './OverseasApprListTable';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {format, subMonths} from 'date-fns';
import apolloClient from '../../lib/apolloClient';
import { overseasApprListMutation } from '../../lib/apolloClient/mutations';
import * as utils from '../../common/utils';


const useStyles = makeStyles((theme:Theme)=>({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type memberInfo = {
  userKorName: string,
  userId: string
}

type overseasApprInfo = {
  overseasCardApprovalNo: string,
  overseasApprovalUseNo: string,
  paymentId: string,
  approvalDate: string,
  approvalTime: string,
  tradeDate: string,
  cardNo: string,
  cardNoMasked: string,
  merchantEngName: string,
  overseasMerchantNo: string,
  salesKindDivisionCode: string,
  requestWithdrawalUsdAmount: number,
  realWithdrawalKrwAmount: number,
  approvalProcessStatusCode: string,
  slipPurchaseYn: string,
  tradeApprovalSeq: number,
  adminErrorCode: string,
  refundPaymentId: string,
  textApprovalRequestUsdAmount: number,
  inoutProcessSeq: string,
  accountProcessSeq: number,
  refundProcessCode: string,
  nonInoutRequestYn: string,
  cancelAfterUsdBalance: number,
  approvalCancelStatusDivisionCode: string,
  originCancelApplyUsdBalance: number,
  originCancelApplyKrwBalance: number
  _member: memberInfo
}

const OverseasApprList = () => {
  const classes = useStyles();

  const [searchForm, setSearchForm] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    salesDivisionCode: '',
    apprProcessStatusCode: '',
    cardApprNo: '',
    paymentId: '',
    adminErrorCode: ''
  });

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  const [refundCount, setRefundCount] = useState(0);

  const displayProcessCode = (item: overseasApprInfo) => {
    let displayProcess = '';
    if(item.approvalProcessStatusCode === '1') displayProcess = '정상';
    else if (item.approvalProcessStatusCode === '2') displayProcess = '오류';
    else displayProcess = '처리중';
    return displayProcess;
  }

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: overseasApprInfo[]) => {
    let rowData = [];
    console.log("엑셀 다운 => csvData.length: "+csvData.length);
    for(let i=0; i<csvData.length; i++){
      rowData.push({
        '카드승인번호': `${csvData[i].overseasCardApprovalNo}`,
        '승인이용번호': `${csvData[i].overseasApprovalUseNo}`,
        '머니승인번호': `${csvData[i].paymentId}`,
        '승인일시': `${chaiStringUtils.dateFormatDefault(csvData[i].approvalDate)+ ' '+ chaiStringUtils.dateTimeFormat(csvData[i].approvalTime)}`, 
        '거래일자': `${chaiStringUtils.dateFormatDefault(csvData[i].tradeDate)}`,
        '고객번호': `${csvData[i]._member.userId}`,
        '성명': `${csvData[i]._member.userKorName}`,
        '카드번호': `${csvData[i].cardNoMasked}`,
        '영문가맹점명': `${csvData[i].merchantEngName}`,
        '매출종류': `${chaiStringUtils.codeName('overseasSalesKindDivisionCode',csvData[i].salesKindDivisionCode)}`,
        '요청인출미화금액': `${csvData[i].requestWithdrawalUsdAmount}`,
        '실인출원화금액': `${csvData[i].realWithdrawalKrwAmount}`,
        '처리구분': `${displayProcessCode(csvData[i])}`,
        '매입여부': `${csvData[i].slipPurchaseYn === 'Y' ? '매입' : ''}`
      });
    }
    utils.exportToCSV("해외승인내역", rowData);
  }

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchForm, isFlag, paging, refundCount]);

  const onSearch = async() => {
    await apolloClient.mutate({
      variables: {
        searchType: searchForm.searchType,
        searchValue: searchForm.searchValue,
        startDate: searchForm.startDate,
        endDate: searchForm.endDate,
        salesDivisionCode: searchForm.salesDivisionCode,
        apprProcessStatusCode: searchForm.apprProcessStatusCode,
        cardApprNo: searchForm.cardApprNo,
        paymentId: searchForm.paymentId,
        adminErrorCode: searchForm.adminErrorCode,
        page: paging.page,
        rowsPerPage: paging.rowsPerPage,
        isExcelDown: isExcelDown
      }
      , mutation: overseasApprListMutation.selectOverseasApprList
    }).then(res => {
      console.log("*** 리스트조회 결과 ***");
      console.log(res.data.selectOverseasApprList);
      if(isExcelDown){
        setExcelDown(false);
        excelDown(res.data.selectOverseasApprList.list);
      }else{
        setData(res.data.selectOverseasApprList.list);
        setTotalCount(res.data.selectOverseasApprList.totalCount);
      }
    }).catch(e => {
      console.log("*** 리스트조회 에러 ***");
      console.log(e);
    });
  };

  return(
    <div className={classes.root}>
      <MenuTitleToolbar title="해외승인내역조회" />
      <OverseasApprListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setSearchForm}/>
      <div className={classes.content}>
        <Button 
          color="primary" 
          variant="contained" 
          style={{marginLeft:'auto', marginBottom: 10}} 
          onClick={() => {excelClick();}}
        >
          Excel
        </Button>
        <OverseasApprListTable paging={paging} setPaging={setPaging} totalCount={totalCount} list={data} refundCount={refundCount} setRefundCount={setRefundCount}/>
      </div>
    </div>
  );
};



export default OverseasApprList;
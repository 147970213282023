import gql from 'graphql-tag';


export const selectProductMgmtList = gql`
    mutation selectProductMgmtList(
        $cardProductGroupName : , String,
        $cardProductCode : String,
        $cardProductCodeName : String,
        $useYn : String,

        ){
        selectProductMgmtList(
            cardProductGroupName : $cardProductGroupName
            cardProductCode : $cardProductCode
            cardProductCodeName : $cardProductCodeName
            useYn : $useYn
        ){
            cardProductGroupCode    
            cardProductCode 
            cardProductGroupName
            cardProductCodeName
            brandDivisionCode
            useYn
        }
    }
`;

export const selectProductMgmtHistoryList = gql`
    mutation selectProductMgmtHistoryList($cardProductGroupName: String, $cardProductGroupCode: String, $cardProductCode: String, $cardProductCodeName: String){
        selectProductMgmtHistoryList(cardProductGroupName: $cardProductGroupName, cardProductGroupCode: $cardProductGroupCode, cardProductCode: $cardProductCode, cardProductCodeName: $cardProductCodeName){
            changeSeq
            changeDate
            changeTime
            cardProductGroupCode
            cardProductCode
            cardProductGroupName
            cardProductCodeName
            useYn
            remarkDescription
            cardIssueFeeYn
            cardIssueFee
            trafficFunctionDivisionCode
            brandDivisionCode
            annualFeeClaimYn
            localProductYearlyDues
            visaProductYearlyDues
            masterProductYearlyDues
            unionpayProductYearlyDues
            etcProductYearlyDues
            productCashbackDivisionCode
            productCashbackPaymentDate
            productTypeDivisionCode
        }
    }
`;

export const selectProductMgmtListByProductName = gql`
    mutation selectProductMgmtListByProductName($cardProductCode : String){
        selectProductMgmtListByProductName(cardProductCode : $cardProductCode){
            cardProductGroupCode	
            cardProductCode	
            cardProductGroupName
            cardProductCodeName
            brandDivisionCode
            useYn
        }
    }
`;

export const insertProductMgmt = gql`
    mutation insertProductMgmt($input : productMgmtInput){
        insertProductMgmt(input : $input){
            cardProductGroupCode	
            cardProductCode	
            cardProductGroupName
            cardProductCodeName
            brandDivisionCode
            useYn
        }
    }
`;

export const updateProductMgmt = gql`
    mutation updateProductMgmt($input : productMgmtInput){
        updateProductMgmt(input : $input){
            cardProductGroupCode	
            cardProductCode	
            cardProductGroupName
            cardProductCodeName
            brandDivisionCode
            useYn
        }
    }
`;

export const deleteProductMgmt = gql`
    mutation deleteProductMgmt($input : productMgmtInput){
        deleteProductMgmt(input : $input){
            cardProductCode
        }
    }
`;






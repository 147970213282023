import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { settlementProxyFeeMutation } from '../../lib/apolloClient/mutations';

import SettlementProxyFeeToolbar from './SettlementProxyFeeToolbar';
import SettlementProxyFeeTable from './SettlementProxyFeeTable';
import { MenuTitleToolbar } from '../../components';

import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type SearchInfo = {
  feeSettlementYm: string; // 정산년월
  proxyFeeCodeNo: string; // 위임수수료코드번호
  domesticCode: String; // 국내외구분코드
  feeAutoYn: String; // 자동대사여부
};

type Props = {};

const SettlementProxyFee: FC<Props> = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  // const [settlementProxyFeeAggrData, setSettlementProxyFeeAggrData] = useState({} as SettlementProxyFeeAggr);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    // 정산년월
    feeSettlementYm:
      chaiStringUtils.getFeeSettlementYm() === ''
        ? format(new Date(), 'yyyy-MM')
        : chaiStringUtils.getFeeSettlementYm(),
    proxyFeeCodeNo: chaiStringUtils.getProxyFeeCodeNo(), // 위임수수료코드번호
    domesticCode: chaiStringUtils.getDomesticCode(), // 국내외구분코드
    feeAutoYn: '', // 자동대사여부
  });

  useEffect(() => {
    onSearch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 대행수수료 정산내역 목록 조회
  const onSearch = async (searchForm: SearchInfo) => {
    console.log('대행수수료 목록 조회 시작 : ', searchForm);

    // 공통 파라미터 Set
    chaiStringUtils.setFeeSettlementYm(searchForm.feeSettlementYm);
    chaiStringUtils.setProxyFeeCodeNo(searchForm.proxyFeeCodeNo);
    chaiStringUtils.setDomesticCode(searchForm.domesticCode);

    //초기화
    setData([]);

    await apolloClient
      .mutate({
        variables: {
          feeSettlementYm: searchForm.feeSettlementYm, // 정산년월
          proxyFeeCodeNo: searchForm.proxyFeeCodeNo, // 위임수수료코드번호
          domesticCode: searchForm.domesticCode, // 국내외구분코드
          feeAutoYn: searchForm.feeAutoYn, // 자동대사여부
        },
        mutation: settlementProxyFeeMutation.selectSettlementProxyFeeList,
      })
      .then(res => {
        console.log('대행수수료 목록 조회 결과 : ', res.data.selectSettlementProxyFeeList);
        setData(res.data.selectSettlementProxyFeeList);
        fetch(searchForm);
      })
      .catch(console.error);
  };

  // 대행수수료 정산내역 합계 조회
  const fetch = async (searchForm: SearchInfo) => {
    console.log('대행수수료 합계 조회 시작 : ', searchForm);
    //초기화
    // setSettlementProxyFeeAggrData({} as SettlementProxyFeeAggr);

    await apolloClient
      .mutate({
        variables: {
          feeSettlementYm: searchForm.feeSettlementYm, // 정산년월
        },
        mutation: settlementProxyFeeMutation.selectSettlementProxyFeeAggr,
      })
      .then(res => {
        console.log('대행수수료 정산내역 합계 조회 : ', res);
        // setSettlementProxyFeeAggrData(res.data.selectSettlementProxyFeeAggr);
      })
      .catch(console.error);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="대행수수료 자동대사" />
      <SettlementProxyFeeToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />
      {/* <div className={classes.content}>
                <SettlementProxyFeeAggrTable settlementProxyFeeAggrData={settlementProxyFeeAggrData} />
            </div>  */}
      <div className={classes.content}>
        <SettlementProxyFeeTable title="대행수수료 자동대사 목록" settlementProxyFeeList={data} />
      </div>
    </div>
  );
};

export default SettlementProxyFee;

import React, { FC, ChangeEvent } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Button,
  DialogContent,
  Card,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import * as utils from '../../common/utils';
import { masterMerchantMgmtMutation } from '../../lib/apolloClient/mutations';
import apolloClient from '../../lib/apolloClient';

const StyledTableCell = withStyles((theme: Theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 1
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));
**/

type SearchInfo = {
  inputMasterMerchantNo: string,     // 대표가맹점번호
  inputMasterMerchantName: string,   // 대표가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명  
  inputCancelYn: string             // 해지여부
}

type Props = {
  updatePop: boolean,
  setUpdatePop: React.Dispatch<React.SetStateAction<boolean>>,
  masterMerchantDetail: {
    masterMerchantNo: string;
    masterMerchantName: string;
  },
  setMasterMerchantDetailInfo: React.Dispatch<React.SetStateAction<{
    masterMerchantNo: string;
    masterMerchantName: string;
  }>>,
  fetch: any,
  searchForm: SearchInfo
}

const MasterMerchantInfoMgmtUpdatePop: FC<Props> = ({updatePop, setUpdatePop, masterMerchantDetail, setMasterMerchantDetailInfo, fetch, searchForm}) => {
  // const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMasterMerchantDetailInfo({
      ...masterMerchantDetail,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setUpdatePop(false);
  };

  // 대표가맹점 저장 처리
  const handleUpdateAction = async () => {
    console.log(masterMerchantDetail);
    if(utils.isNullOrBlank(masterMerchantDetail.masterMerchantNo) || masterMerchantDetail.masterMerchantNo.length !== 8) {
      showToast('대표가맹점번호가 올바르지 않습니다.', 'error');
      hideDialog();
      return;
    }

    if(utils.isNullOrBlank(masterMerchantDetail.masterMerchantName)) {
      showToast('대표가맹점명이 입력되지 않았습니다.', 'error');
      hideDialog();
      return;
    }

    await apolloClient.mutate({
      variables: {
        input: {
          masterMerchantNo: masterMerchantDetail.masterMerchantNo,         // 대표가맹점번호
          masterMerchantName: masterMerchantDetail.masterMerchantName,     // 대표가맹점명
        }
      },
      mutation: masterMerchantMgmtMutation.updateMasterMerchantMaster
    }).then(res => {
      hideDialog();
      console.log('결과: ', res.data.updateMasterMerchantMaster);

      if(res.data.updateMasterMerchantMaster.success === 'Y') {
        showToast(res.data.updateMasterMerchantMaster.message);
        handleClose();

        // 목록 리로드
        fetch(searchForm);
      }else {
        showToast(res.data.updateMasterMerchantMaster.message, 'error');
      }
      
    }).catch(e => {
      console.log(e);
        hideDialog();
        showToast(e.message, 'error');
    });
  }

  // 대표가맹점 삭제
  const handleDeleteAction = async () => {
    console.log(masterMerchantDetail);

    await apolloClient.mutate({
      variables: {
        input: {
          masterMerchantNo: masterMerchantDetail.masterMerchantNo,         // 대표가맹점번호
          masterMerchantName: masterMerchantDetail.masterMerchantName,     // 대표가맹점명
        }
      },
      mutation: masterMerchantMgmtMutation.deleteMasterMerchantMaster
    }).then(res => {
      hideDialog();
      console.log('결과: ', res.data.deleteMasterMerchantMaster);

      if(res.data.deleteMasterMerchantMaster.success === 'Y') {
        showToast(res.data.deleteMasterMerchantMaster.message);
        handleClose();

        // 목록 리로드
        fetch(searchForm);
      }else {
        showToast(res.data.deleteMasterMerchantMaster.message, 'error');
      }
      
    }).catch(e => {
      console.log(e);
        hideDialog();
        showToast(e.message, 'error');
    });
  }

  return (
    <Dialog
      open={updatePop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title"><MenuTitleToolbar title="대표가맹점 수정" /></DialogTitle>

      <DialogContent>
        <Card>
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>대표가맹점번호</StyledTableCell>
                <StyledTableCell colSpan={5}>
                  <TextField
                    fullWidth
                    placeholder="대표가맹점번호 10자리"
                    margin="dense"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="masterMerchantNo"
                    value={masterMerchantDetail.masterMerchantNo}
                    onChange={handleChange}
                  />
                </StyledTableCell>
                <StyledTableCell>대표가맹점명</StyledTableCell>
                <StyledTableCell colSpan={5}>
                  <TextField
                    fullWidth
                    placeholder="대표가맹점명"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="masterMerchantName"
                    value={masterMerchantDetail.masterMerchantName}
                    onChange={handleChange}
                  />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Card>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button 
          style={{color: '#fff', background: '#dc3545'}}
          variant="contained"
          onClick={() => showDialog(
            '대표가맹점정보',
            '해당 정보를 정말 삭제하시겠습니까? 삭제 시 복구할 수 없습니다.',
            handleDeleteAction
          )}
        >
          Remove
        </Button>
        <Button 
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '대표가맹점정보',
            '해당 정보로 저장하시겠습니까?',
            handleUpdateAction
          )}
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MasterMerchantInfoMgmtUpdatePop;
import React, { FC, useState }from 'react';
import{
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CardActions,
  TablePagination
}
from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import OverseasApprHistoryLogRefund from './OverseasApprHistoryLogRefund';

const useStyles = makeStyles((theme: Theme)=> ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

type Paging = {
  page: number,
  rowsPerPage: number
}

type overseasApprHistoryLogListInfo = {
  totalCount: number,
  dataDivisionCode: string,
  approvalDate: string,
  salesKindDivisionCode: string,
  cardNo: string,
  cardNoMasked: string,
  overseasCardApprovalNo: string,
  cancelAfterUsdBalance:number,
  slipPurchaseYn: string,
  chaiTextApprovalRequestUsdAmount: number,
  bcTextApprovalRequestUsdAmount: number,
  chaiCardApprovalResponseCode: string,
  bcCardApprovalResponseCode: string,
  chaiMoneyPaymentAmount: number,
  chaiMoneyResultCode: string
}

type Props = {
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  overseasApprHistoryLogList: overseasApprHistoryLogListInfo[]
}

const OverseasApprHistoryLogListTable: FC<Props> = ({paging, setPaging, refundCount, setRefundCount, overseasApprHistoryLogList}) => {
  const classes = useStyles();

  const [refundReqInfo, setRefundReqInfo] = useState({
    cardNo: '',
    cardNoMasked: '',
    overseasCardApprovalNo: '',
    approvalDate: '',
    salesKindDivisionCode:'',
    cancelAfterUsdBalance: 0
  });
  const [isOverseasApprHistoryLogRefundOpen, setOverseasApprHistoryLogRefundOpen] = useState(false);
  let totalCount = 0;
  const setTotalCount = (val: number) => {
    totalCount = val;
  }

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(e.target.value)
    });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>NO</TableCell>
                  <TableCell align="center" rowSpan={2}>승인일시</TableCell>
                  <TableCell align="center" rowSpan={2}>승인구분</TableCell>
                  <TableCell align="center" rowSpan={2}>매출구분</TableCell>
                  <TableCell align="center" rowSpan={2}>카드번호</TableCell>
                  <TableCell align="center" rowSpan={2}>승인번호</TableCell>
                  <TableCell align="center" rowSpan={2}>취소후잔액</TableCell>
                  <TableCell align="center" colSpan={3}>승인금액</TableCell>
                  <TableCell align="center" colSpan={3}>응답결과</TableCell>
                  <TableCell align="center" rowSpan={2}>상세조회 / 환불요청</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>
                  <TableCell align="center">차이머니</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overseasApprHistoryLogList.slice(0,overseasApprHistoryLogList.length).map((item, index) => (
                  <TableRow hover key ={item.approvalDate+''+item.salesKindDivisionCode+''+item.cardNo+''+item.overseasCardApprovalNo+item.cancelAfterUsdBalance.toString()}>
                    <TableCell align="center">{setTotalCount(Number(item.totalCount))}{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate.substring(0,8))+' '+chaiStringUtils.dateTimeFormat(item.approvalDate.substring(8,14))}</TableCell>
                    <TableCell align="center">{(item.dataDivisionCode === '05'|| item.dataDivisionCode === '06')? '승인' : '승인취소'}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('overseasSalesKindDivisionCode',item.salesKindDivisionCode)}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                    <TableCell align="center">{item.overseasCardApprovalNo}</TableCell>
                    <TableCell align="center">{item.cancelAfterUsdBalance}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.chaiTextApprovalRequestUsdAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.bcTextApprovalRequestUsdAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.chaiMoneyPaymentAmount)}</TableCell>
                    <TableCell align="center">{item.chaiCardApprovalResponseCode}</TableCell>
                    <TableCell align="center">{item.bcCardApprovalResponseCode}</TableCell>
                    <TableCell align="center">{item.chaiMoneyResultCode}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setOverseasApprHistoryLogRefundOpen(true); 
                          setRefundReqInfo({
                            cardNo: item.cardNo,
                            cardNoMasked: item.cardNoMasked,
                            overseasCardApprovalNo: item.overseasCardApprovalNo,
                            approvalDate: item.approvalDate,
                            salesKindDivisionCode: item.salesKindDivisionCode,
                            cancelAfterUsdBalance: item.cancelAfterUsdBalance
                          })
                        }}
                      >
                        상세조회 / 환불요청
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
      <OverseasApprHistoryLogRefund refundReqInfo={refundReqInfo} refundCount={refundCount} setRefundCount={setRefundCount} isOverseasApprHistoryLogRefundOpen={isOverseasApprHistoryLogRefundOpen} setOverseasApprHistoryLogRefundOpen={setOverseasApprHistoryLogRefundOpen}/>
    </Card>
  );
};

export default OverseasApprHistoryLogListTable;
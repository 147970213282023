import React, { FC, useState, useEffect } from 'react';
import apolloClient from '../../lib/apolloClient';
import { purchasedHistoryMutation } from '../../lib/apolloClient/mutations';
import PurchasedHistoryListToolbar from './PurchasedHistoryListToolbar';
import PurchasedHistoryTable from './PurchasedHistoryTable';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { MenuTitleToolbar } from '../../components';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  detail: {
    marginTop: theme.spacing(3)
  }
}));

function convertOnlineType(code: string) {
  switch(code) {
    case '01':
      return '오프라인';
    case '02':
      return '온라인PG';
    case '03':
      return '인터넷MALL';
    default:
      return '분류없음';
  }
}

type Props = {
  listInfo: PurchasedHistoryList
}

const PurchasedHistory: FC<Props> = ({ listInfo }) => {

  const default_page = 0;
  const default_rowsPerPage = 100;
  const classes = useStyles();
  const [listData, setListData] = useState(listInfo);
  const [listSearch, setListSearch] = useState({
    searchGubun: chaiStringUtils.getSchParamGubun(),
    searchText: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startReceveBaseDate: format(subMonths(new Date(), 1), 'yyyyMMdd'),
    endReceveBaseDate: format(new Date(), 'yyyyMMdd'),
    purchasedProcessNo: '',
    salesKindDivisionCode: '',
    merchantNo: '',
    accountProcessSeq: 0,
    accountDivisionCode: '',
    isExcelDown: false
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const getList = async () => {
      await apolloClient.mutate({
        mutation: purchasedHistoryMutation.selectPurchasedHistoryList,
        variables: { searchGubun: listSearch.searchGubun
                   , searchText: listSearch.searchText
                   , startReceveBaseDate: listSearch.startReceveBaseDate
                   , endReceveBaseDate: listSearch.endReceveBaseDate
                   , salesKindDivisionCode: listSearch.salesKindDivisionCode
                   , purchasedProcessNo: listSearch.purchasedProcessNo
                   , merchantNo: listSearch.merchantNo
                   , accountProcessSeq: listSearch.accountProcessSeq
                   , accountDivisionCode: listSearch.accountDivisionCode
                   , page: paging.page
                   , rowsPerPage: paging.rowsPerPage
                   , isExcelDown: isExcelDown
                   }
      }).then(res => {
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectPurchasedHistoryList.list);
        }else{
          setListData(res.data.selectPurchasedHistoryList)
        }
      }).catch(console.error);
    }

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSearch, paging, isFlag])

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: PurchasedHistoryInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
      console.log(csvData[i])
      rowData.push({
            'Chai정산일자': `${chaiStringUtils.dateFormatDefault(csvData[i].receveBaseDate)}`,
            'BC매입일자': `${chaiStringUtils.dateFormatDefault(csvData[i].receveDate)}`,
            'BC정산일자': `${chaiStringUtils.dateFormatDefault(csvData[i].settlementDate)}`,
            '거래일자': `${chaiStringUtils.dateFormatDefault(csvData[i].salesDate)}`,
            '매출종류': `${chaiStringUtils.codeName('salesKindDivisionCode', csvData[i].salesKindDivisionCode)}(${csvData[i].salesKindDivisionCode})`,
            '계정구분': `${chaiStringUtils.codeName('accountDivisionCode', csvData[i].accountDivisionCode)}(${csvData[i].accountDivisionCode})`,
            '카드번호': `${chaiStringUtils.cardNoMaskFormat(csvData[i].cardNoMasked)}`,
            '매입접수번호': `${csvData[i].purchasedProcessNo}`,
            '가맹점명': `${csvData[i].merchantName}`,
            '가맹점번호': `${csvData[i].merchantNo}`,
            '매출합계액': `${chaiStringUtils.moneyFormat(csvData[i].salesTotalAmount)}`,
            '승인번호': `${csvData[i].cardApprovalNo}`,
            '온라인 상세코드': `${convertOnlineType(csvData[i].onlineMerchantType)}`,
        });
    }

    utils.exportToCSV("국내매입내역", rowData);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내매입내역 조회" />
      <PurchasedHistoryListToolbar /** 검색조건 */ onSearch={setListSearch} page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} />
      <div className={classes.content}>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <PurchasedHistoryTable /** 매입내역LIST */ dataList={listData} page={paging.page} rowsPerPage={paging.rowsPerPage} setPaging={setPaging} />
      </div>
    </div>
  );
};

export default PurchasedHistory;

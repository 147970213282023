import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  }
  
}));
**/

type SearchInfo = {
  issueStatusCode: string;    // 카드상태 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송수령
  searchDateGubun: string;    // 조회일자구분
  searchStartDate: string;    // 시작일자
  searchEndDate: string;      // 종료일자
  searchGubun: string;        // 조회구분(고객번호, 카드번호, 휴대폰번호)
  searchVal: string;
}

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<any>>,
  searchForm: SearchInfo
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}
const CardReturnManageToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {
  // const classes = useStyles();

  // 변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  // 목록 조회
  const onSearchClick = async () => {
    console.log('searchGubun :', searchForm.searchGubun);
    console.log('searchVal :', searchForm.searchVal);
    chaiStringUtils.setSchParamGubun(searchForm.searchGubun);
    chaiStringUtils.setSchParamSearchVal(searchForm.searchGubun, searchForm.searchVal);

    onSearch(searchForm);
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="발송상태구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="issueStatusCode"
              value={searchForm.issueStatusCode}
              onChange={handleChange}
            >
              {/* 1:발송 2:수령 3:반송 4:재발송 5:폐기 6:고객등록 7:반송입고 */}
              <option value="1">발송</option>
              <option value="2">수령</option>
              <option value="3">반송</option>
              <option value="7">반송입고</option>
              <option value="4">재발송</option>
              <option value="5">폐기</option>
            </TextField>
          </Grid>

          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="조회구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchGubun"
              value={searchForm.searchGubun}
              onChange={handleChange}
            >
              <option value="1">휴대폰 번호</option>
              <option value="2">카드번호</option>
              <option value="3">고객번호</option>
            </TextField>
          </Grid>

          <Grid
            item
            md={4}
            xs={10}
          >
            <TextField
              fullWidth
              label="조회 값"
              placeholder="조회 값"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="searchVal"
              value={searchForm.searchVal}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
        </Grid>
        <Grid
            container
            spacing={1}
          >              
            <Grid
            item
            md={2}
            xs={6}
            >
              <TextField
                select
                fullWidth
                label="조회일자구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchDateGubun"
                value={searchForm.searchDateGubun}
                onChange={handleChange}
              >
                <option value="issueDate">발급일자</option>
                <option value="receveDate">수령일자</option>
                <option value="sendbackDate">반송일자</option>
                <option value="terminateDate">해지일자</option>
              </TextField>
            </Grid>

            <Grid
              item
              md={3}
              xs={8}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchStartDate"
                value={searchForm.searchStartDate}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={3}
              xs={8}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchEndDate"
                value={searchForm.searchEndDate}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>

            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        
        <Grid
          container
          spacing={1}
        >
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CardReturnManageToolbar;

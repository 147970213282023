import gql from 'graphql-tag';

export const authMutation = gql`
  query auth($token: String!, $localLoginType: String) {
    auth(token: $token, localLoginType: $localLoginType) {
      adminId
      permission {
        permissions
        isCustomerService
        hasCardPermission
      }
    }
  }
`;

export const authExtendMutation = gql`
  query authExtend {
    authExtend
  }
`;

export const authPermissionCheckQuery = gql`
  query authPermissionCheck {
    authPermissionCheck
  }
`;

export const logoutMutation = gql`
  query logout {
    logout
  }
`;

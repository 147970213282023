import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import * as util from '../../common/utils';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Button,
  DialogContent
} from '@material-ui/core';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import UploadList from './UploadList';
import ExcelReader from '../../components/CommonExcel/ExcelReader';
import { holidayInformationMutation } from '../../lib/apolloClient/mutations';
import apolloClient from '../../lib/apolloClient';

/**
const StyledTableCell = withStyles((theme: Theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);
**/

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 1
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  uploadButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
}));

type SearchInfo = {
    baseDate: string,           // 기준일자
    baseDateEnd:string,         // 기준종료일자
    holidayCode: string         // 휴일구분
}

type UploadInfo = {
    baseDate: string,
    dayOfWeekCode: string,
    holidayCode: string
}
type Props = {
  uploadPop: boolean,
  setUploadPop: React.Dispatch<React.SetStateAction<boolean>>,
  searchForm: SearchInfo,
  fetch: any
}

const GroupMerchantInfoInsertPop: FC<Props> = ({uploadPop, setUploadPop, searchForm, fetch }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const handleClose = () => {
    setUploadList([] as any[]); // 목록 초기화
    setUploadPop(false);
  };

  // 업로드
  const [uploadList, setUploadList] = useState([] as any[]);
  const handleUploadAction = async () => {

  const uploadArr = [] as UploadInfo[];

  for (const obj of uploadList) {
    if(util.isNullOrBlank(String(obj.기준일자)) || chaiStringUtils.dateFormatDefault(new Date(obj.기준일자)).replace(/[^0-9]/g,'').length !== 8) {
      showToast('기준일자 입력이 올바르지 않습니다.(8자리 필수)', 'error');
      hideDialog();
      return;
    }

    if(util.isNullOrBlank(String(obj.요일구분))) {
      showToast('요일구분은 필수 입력값입니다.', 'error');
      hideDialog();
      return;
    }

    if(util.isNullOrBlank(String(obj.휴일구분))) {
      showToast('휴일구분은 필수 입력값입니다.', 'error');
      hideDialog();
      return;
    }

    const today = new Date(obj.기준일자);
    today.setHours(today.getHours() + 9);

    uploadArr.push({
      'baseDate': String(chaiStringUtils.dateFormatDefault(today).replace(/[^0-9]/g,'')).trim(),
      'dayOfWeekCode': String(obj.요일구분).trim(),
      'holidayCode': String(obj.휴일구분).trim()
    });
  };
  
  // 엑셀 업로드
  await apolloClient.mutate({
    variables: {
      inputList: uploadArr
    },
    mutation: holidayInformationMutation.holidayInformationExcelUpload
  })
  .then(res => {
    hideDialog();
    console.log('결과: ', res.data.holidayInformationExcelUpload);

    if(res.data.holidayInformationExcelUpload.success === 'Y') {
      showToast(res.data.holidayInformationExcelUpload.message);
      handleClose();

      // 목록 리로트
      fetch(searchForm);
    }else {
      showToast(res.data.holidayInformationExcelUpload.message, 'error');
    }
  }).catch(e => {
    console.log(e);
    hideDialog();
    showToast(e.message, 'error');
  });

  }

  return (
    <Dialog
      open={uploadPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle id="alert-dialog-title"><MenuTitleToolbar title="엑셀업로드 팝업" /></DialogTitle>

      <DialogContent>
        <div className={classes.uploadButton}>
          <ExcelReader setData={setUploadList}/>
        </div>
        <br/>
        <br/>
        <br/>
        <UploadList uploadList={uploadList}/>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button 
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '업로드목록',
            '해당 정보로 DB업로드하시겠습니까?',
            handleUploadAction
          )}
        >
           저장
        </Button>
        <Button onClick={handleClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupMerchantInfoInsertPop;
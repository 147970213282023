import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { MenuTitleToolbar } from '../../components';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import apolloClient, { plccMutation } from '../../lib/apolloClient';
import UploadList from './PlccMerchantMappingExcelUploadList';
// import { make_cols } from './MakeColumns';
export const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  'fods',
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

type Props = {
  uploadPop: boolean;
  setUploadPop: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PlccMerchantMappingExcel: FC<Props> = ({ uploadPop, setUploadPop }) => {
  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  // 업로드
  const [file, setFile] = useState<any>();
  const [uploadList, setUploadList] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const handleUploadAction = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const changes: any = {};
    uploadList.forEach(row => {
      changes[row.hanaId] = row.bcId;
    });

    await apolloClient
      .mutate({
        variables: {
          variables: changes || {},
          original: undefined,
        },
        mutation: plccMutation.updateHanaMerchantMapping,
      })
      .then((res: any) => {
        if (res?.data?.updateHanaMerchantMappingList?.errors?.length > 0) {
          const errors = res?.data?.updateHanaMerchantMappingList?.errors;
          showToast(`${errors.length}개의 항목의 업데이트에 문제가 생겼습니다. ${JSON.stringify(errors)}`, 'error');
          console.log(errors);
        } else {
          showToast(`${res?.data?.updateHanaMerchantMappingList?.changedTotal}개의 항목을 업데이트했습니다.`);
        }
      })
      .catch(console.error);

    setLoading(false);
    hideDialog();
    handleClose();
  };

  const handleClose = () => {
    setUploadList([] as any[]); // 목록 초기화
    setUploadPop(false);
  };

  // 파일 업로드 버튼
  // const handleFileInputButton = () => {
  //   document.getElementById('file')!.click();
  // };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      const raw = e?.target?.result;
      if (raw) {
        const lines = raw.toString().replace(/\r/g, '').split('\n');
        const arr = lines.reduce((acc: any, line: string, idx) => {
          // 헤더 제거
          if (idx === 0) {
            return acc;
          }
          const cols = line.split(',');
          return acc.concat({
            name: cols[0],
            hanaId: cols[1],
            bcId: cols[2],
          });
        }, []);
        setUploadList(arr);
      }
    };
    fileReader.readAsText(file);
  }, [file]);

  return (
    <Dialog
      open={uploadPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <MenuTitleToolbar title="엑셀업로드" />
      </DialogTitle>
      <DialogContent>
        <Box style={{ textAlign: 'right' }}>
          <Button color="primary" variant="contained">
            <input id="user-tag-userlist-file" type="file" hidden onChange={handleChange} accept=".csv" />
            <label htmlFor="user-tag-userlist-file">&nbsp;CSV 업로드</label>
          </Button>
        </Box>
        <Box p={2} />
        <UploadList uploadList={uploadList} />
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => showDialog('업로드목록', '해당 정보로 DB업로드하시겠습니까?', handleUploadAction)}
        >
          저장
        </Button>
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

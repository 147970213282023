import React, { FC, useState, useEffect }from 'react';
import{
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  DialogActions,
  Button
}
from '@material-ui/core';
import { makeStyles, Theme }from '@material-ui/core';
import {useDialog} from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import { MenuTitleToolbar } from '../../components';
import apolloClient from '../../lib/apolloClient';
import { overseasApprHistoryLogMutation } from '../../lib/apolloClient';
import chaiStringUtils from '../../common/chaiStringUtils';
import { chaiPaymentCancel, chaiPaymentStatus } from '../../lib/chaiAPI'

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee'
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px'
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold'
  }
}));

type overseasApprHistoryLogRefundSearch = {
  cardNo: string,
  overseasCardApprovalNo: string,
  approvalDate: string,
  salesKindDivisionCode: string,
  cancelAfterUsdBalance:number
}

type Props = {
  refundReqInfo: overseasApprHistoryLogRefundSearch,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  isOverseasApprHistoryLogRefundOpen: boolean,
  setOverseasApprHistoryLogRefundOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OverseasApprHistoryLogRefund: FC<Props> = ({refundReqInfo, refundCount, setRefundCount, isOverseasApprHistoryLogRefundOpen, setOverseasApprHistoryLogRefundOpen}) => {
  const classes = useStyles();
  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  const initRefundData = {
    tradeDate: '',
    tradeApprovalSeq: 0,
    cardNo: '',
    cardNoMasked: '',
    cardSeq: 0,
    salesKindDivisionCode: '',
    approvalDate: '',
    approvalTime: '',
    overseasCardApprovalNo: '',
    originApprovalCancelDivisionCode: '',
    merchantNo: '',
    merchantEngName: '',
    approvalProcessStatusCode: '',
    cardApprovalResponseCode: '',
    accountProcessSeq: 0,
    inoutDate: '',
    inoutProcessCode: '',
    inoutProcessSeq: '',
    paymentId: '',
    refundDate: '',
    refundProcessCode: '',
    refundProcessSeq: '',
    refundPaymentId: '',
    approvalCancelDate: '',
    approvalCancelTime: '',
    textApprovalRequestUsdAmount: 0,
    approvalRequestUsdAmount: 0,
    approvalRequestUsdOriginAmount: 0,
    requestWithdrawalUsdAmount: 0,
    realWithdrawalKrwAmount : 0,
    overseasBrandUsdFee : 0,
    overseasBrandKrwFee: 0,
    overseasUseUsdFee: 0,
    overseasUseKrwFee : 0,
    overseasWithdrawalUsdFee : 0,
    overseasWithdrawalKrwFee: 0,
    memberinfo: {
      cardMemberNo: '',
      userKorName: '',
      phoneNo1: '',
      phoneNo2: '',
      phoneNo3: ''
    },
    cardinfo: {
      cardSeq: 0,
      cardNo: '',
      cardProductCode: '',
      userId: '',
      cardProductCodeName: ''
    },
    cancelinfo: {
      tradeDate: '',
      tradeApprovalSeq: 0,
      cardNo: '',
      salesKindDivisionCode       : '',
      approvalDate: '',
      approvalTime: '',
      overseasCardApprovalNo: '',
      textApprovalRequestUsdAmount: 0,
      requestWithdrawalUsdAmount : 0,
      realWithdrawalKrwAmount : 0,
      cancelAfterUsdBalance : 0,
      approvalCancelStatusDivisionCode : '',
      originCancelApplyUsdBalance: 0,
      originCancelApplyKrwBalance: 0,
      approvalProcessStatusCode: '',
      cardApprovalResponseCode: '',
      accountProcessSeq: 0,
      nonInoutRequestYn: '',
      nonInoutRegisteredDate: '',
      nonInoutSeq: 0,
      inoutDate: '',
      inoutProcessCode: '',
      inoutProcessSeq: '',
      paymentId        : '',
      refundDate: '',
      refundProcessCode: '',
      refundProcessSeq: '',
      refundPaymentId: ''
    },
    chaimoneyinfo: {
      tradeDate: '',
      idempotencyKey: '',
      paymentId: '',
      paymentAmount: 0
    },
    chaimoneycancelinfo: {
      tradeDate: '',
      idempotencyKey: '',
      paymentId: '',
      paymentAmount: 0
    }
  };

  const [refundData, setRefundData] = useState(initRefundData);
  const [isApprovalExist, setIsApprovalExist] = useState(false);
  
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refundReqInfo]);

  const fetch = async () => {//승인기준의 데이터 가져오기
    await apolloClient.mutate({
      variables: {
        cardNo: refundReqInfo.cardNo,
        approvalDate: refundReqInfo.approvalDate.substring(0,8),
        overseasCardApprovalNo: refundReqInfo.overseasCardApprovalNo,
        cancelAfterUsdBalance: refundReqInfo.cancelAfterUsdBalance
      },
      mutation: overseasApprHistoryLogMutation.selectOverseasApprHistoryLogRefundInfo
    }).then(res => {
      console.log("**** 승인대사환불정보 결과 ****");
      console.log(res.data.selectOverseasApprHistoryLogRefundInfo);
      const result = res.data.selectOverseasApprHistoryLogRefundInfo;
      if (result) {
        setIsApprovalExist(true);
        setRefundData({
          ...refundData,
          ...result
        });
      }
      else {
        setIsApprovalExist(false);
        setRefundData({
          ...refundData,
          ...initRefundData
        });        
      }
    }).catch(e => {
      console.log("**** 승인대사환불정보 에러 ****");
      console.log(e);     
    });
  }

  // [입금처리]
  const handleConfirmRefund = async () => {
    const refundReq = {} as ChaiMoneyRefundReq;
    refundReq.paymentId = refundData.paymentId ? refundData.paymentId : refundData.chaimoneyinfo.paymentId;
    refundReq.idempotencyKey = refundData.inoutProcessSeq ? refundData.inoutProcessSeq : 'OA' + refundData.tradeDate + refundData.tradeApprovalSeq.toString().padStart(16, '0')
    refundReq.checkoutAmount = refundData.realWithdrawalKrwAmount;
    refundReq.cancelAmount = refundData.cancelinfo ? refundData.cancelinfo.realWithdrawalKrwAmount : refundData.realWithdrawalKrwAmount
    refundReq.merchantNo = refundData.merchantNo;
    refundReq.merchantName = refundData.merchantEngName;

    // 차이머니 결제상태 조회
    const paymentStatus = await chaiPaymentStatus(refundReq);
    console.log('status=', paymentStatus);
    let paymentId = refundReq.paymentId;

    // 차이머니 결제상태가 canceled가 아닐때 취소API 호출
    if (paymentStatus.status !== 'canceled') {
      // 차이머니 환불 호출
      const rslt = await chaiPaymentCancel(refundReq);    
      paymentId = rslt.paymentId;
    }

    // 승인오류 발생가 발생하면 승인에 대해서 환불처리한다. 승인이 오류가 발생하면 승인취소도 없으므로
    // 승인오류시 차이머니거래로그가 존재할 때만 환불처리된다.
    let reqSalesKindDivisionCode;
    if(refundData.approvalProcessStatusCode !=='1' && refundData.chaimoneyinfo){
      reqSalesKindDivisionCode = '05';
    }else{
      reqSalesKindDivisionCode = '15';
    }

    await apolloClient.mutate({
      variables: {
        tradeDate: reqSalesKindDivisionCode === '05' ? refundData.tradeDate : refundData.cancelinfo.tradeDate,
        tradeApprovalSeq: reqSalesKindDivisionCode === '05' ? refundData.tradeApprovalSeq : refundData.cancelinfo.tradeApprovalSeq,
        salesKindDivisionCode: reqSalesKindDivisionCode === '05' ? refundData.salesKindDivisionCode : refundData.cancelinfo.salesKindDivisionCode,
        approvalCancelStatusDivisionCode: reqSalesKindDivisionCode === '05' ? '' : refundData.cancelinfo.approvalCancelStatusDivisionCode,
        approvalDate: reqSalesKindDivisionCode === '05' ? refundData.approvalDate : refundData.cancelinfo.approvalDate,
        approvalTime: reqSalesKindDivisionCode === '05' ? refundData.approvalTime : refundData.cancelinfo.approvalTime,
        originTradeDate: refundData.tradeDate,
        originTradeApprovalSeq: refundData.tradeApprovalSeq,
        carNo: refundData.cardNo,
        userId: refundData.cardinfo.userId,
        realWithdrawalKrwAmount: reqSalesKindDivisionCode === '05' ? refundData.realWithdrawalKrwAmount : refundData.cancelinfo.realWithdrawalKrwAmount,
        merchantEngName: refundData.merchantEngName,
        refundProcessSeq: refundReq.idempotencyKey,
        refundPaymentId: paymentId,
        accountProcessSeq: reqSalesKindDivisionCode === '05' ? refundData.accountProcessSeq : refundData.cancelinfo.accountProcessSeq
      },
      mutation: overseasApprHistoryLogMutation.updateOverseasApprHistoryLogRefund
    }).then(res => {
      console.log("*** 해외승인대사_입금처리 결과 ***");
      console.log(res.data.updateOverseasApprHistoryLogRefund);
      setRefundCount(refundCount+1);
      hideDialog();
      showDialog(
        '승인대사',
        '입금환불 처리되었습니다.\n\n',
        () => {hideDialog();}
      );
    }).catch(e => {
      console.log("*** 해외승인대사_입금처리 에러 ***");
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    });
    setOverseasApprHistoryLogRefundOpen(false);
  }

    // [부분취소 상태처리]
    const handleConfirmPartialCancel = async () => {
  
      // 승인정상 && 부분취소(3) or 매입후 전액취소(4) && 승인취소오류
      await apolloClient.mutate({
        variables: {
          tradeDate: refundData.cancelinfo.tradeDate,
          tradeApprovalSeq: refundData.cancelinfo.tradeApprovalSeq,
          salesKindDivisionCode: refundData.cancelinfo.salesKindDivisionCode,
          approvalCancelStatusDivisionCode: refundData.cancelinfo.approvalCancelStatusDivisionCode,
          approvalDate: refundData.cancelinfo.approvalDate,
          approvalTime: refundData.cancelinfo.approvalTime,
          originTradeDate: refundData.tradeDate,
          originTradeApprovalSeq: refundData.tradeApprovalSeq,
          originCancelApplyUsdBalance: refundData.cancelinfo.originCancelApplyUsdBalance,
          originCancelApplyKrwBalance: refundData.cancelinfo.originCancelApplyKrwBalance,
          requestWithdrawalUsdAmount: refundData.cancelinfo.requestWithdrawalUsdAmount,
          realWithdrawalKrwAmount: refundData.cancelinfo.realWithdrawalKrwAmount,
          cancelAfterUsdBalance: refundData.cancelinfo.cancelAfterUsdBalance
        },
        mutation: overseasApprHistoryLogMutation.updateOverseasApprHistoryLogPartialCancel
      }).then(res => {
        console.log("*** 해외승인대사_부분취소상태변경처리 결과 ***");
        console.log(res.data.updateOverseasApprHistoryLogPartialCancel);
        setRefundCount(refundCount+1);
        hideDialog();
        showDialog(
          '승인대사',
          '부분취소 상태변경 처리되었습니다.\n\n',
          () => {hideDialog();}
        );
      }).catch(e => {
        console.log("*** 해외승인대사_부분취소상태변경처리 에러 ***");
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
      setOverseasApprHistoryLogRefundOpen(false);
    }

  const handleOverseasApprHistoryLogRefundClose = () => {
    setOverseasApprHistoryLogRefundOpen(false);
  }

  const isDisabled = () => {
    let isRefund = true;
    if (!isApprovalExist) {
      isRefund = false;
    }
    else {
      if(!refundData.cardinfo){// 카드정보 없을 때, 회원정보 없을 때, 취소정보 없을 때 => 환불불가
        isRefund = false;
      }
      else if(!refundData.memberinfo){
        isRefund = false;
      }
      else if(!refundData.cancelinfo){// 취소정보 없을 때 && (승인오류 && 차이머니거래 존재) => 환불가능
        if(refundData.approvalProcessStatusCode !=='1' && refundData.chaimoneyinfo){
          isRefund = true;
        }else{
          isRefund = false;
        }
      }
      else{
        // (승인오류 && 차이머니거래 존재) && 승인취소오류 => 환불가능
        if (refundData.approvalProcessStatusCode !== '1' && refundData.chaimoneyinfo && refundData.cancelinfo.approvalProcessStatusCode !== '1') {
          isRefund = true;
        }
        // 승인정상 && 전액취소 && 승인취소오류 => 환불가능
        // 전액취소 : 당일 전액취소(1), 익일 전액취소(2), 장기미매입부분취소(5)
        else if (refundData.approvalProcessStatusCode === '1' && 
          ( refundData.cancelinfo.approvalCancelStatusDivisionCode === '1' || 
            refundData.cancelinfo.approvalCancelStatusDivisionCode === '2' ||
            refundData.cancelinfo.approvalCancelStatusDivisionCode === '5') &&
          refundData.cancelinfo.approvalProcessStatusCode !== '1') {
          isRefund = true;
        }
        else {
          isRefund = false;
        }
      }
      // 이미 환불처리되었다면 => 환불불가
      if (refundData.refundProcessCode === '1' || (refundData.cancelinfo && refundData.cancelinfo.refundProcessCode === '1')) {
        isRefund = false;
      }
    }
    return isRefund;
  }

  const isPartialCancelDisabled = () => {
    let isRefund = false;
    if (!isApprovalExist) {
      isRefund = false;
    }
    else {
      if(!refundData.cardinfo){// 카드정보 없을 때, 회원정보 없을 때, 취소정보 없을 때 
        isRefund = false;
      }
      else if(!refundData.memberinfo){
        isRefund = false;
      }
      else if(!refundData.cancelinfo){ // 취소정보 없을 때 
        isRefund = false;
      }
      else { // 취소정보가 있을때
        // 승인정상 && 부분취소(3) or 매입후 전액취소(4) && 승인취소오류 => 승인취소상태 성공으로 변경처리
        if (refundData.approvalProcessStatusCode === '1' && 
          ( refundData.cancelinfo.approvalCancelStatusDivisionCode === '3' || 
            refundData.cancelinfo.approvalCancelStatusDivisionCode === '4') &&
          refundData.cancelinfo.approvalProcessStatusCode !== '1') {
          isRefund = true;
        }
        else {
          isRefund = false;
        }
      } 
    }
    return isRefund;
  }

  const displayProcessStatus = (processStatus:string) => {
    let displayStatus = '';
    if (processStatus === '1') displayStatus = '정상';
    else if (processStatus === '2') displayStatus = '오류';
    else displayStatus = '처리중';
    return displayStatus;
  };

  const displayInoutProcessCode = (inoutProcessCode:string) => {
    let displayProcessCode = '';
    if (inoutProcessCode === '1') displayProcessCode = '입출금성공';
    else if (inoutProcessCode === '2') displayProcessCode = '입출금실패';
    else displayProcessCode = '';
    return displayProcessCode;
  }
  
  return (
    <Dialog
      open={isOverseasApprHistoryLogRefundOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scoll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title"><MenuTitleToolbar title="해외승인대사 상세조회 및 고객환불 입금처리" /></DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Chip label="▶ 카드승인내역" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>승인일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundData.approvalDate) + ' ' + chaiStringUtils.dateTimeFormat(refundData.approvalTime)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>원거래승인번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundReqInfo.overseasCardApprovalNo}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br/>
        <div>
          <Chip label="▶ 카드기본정보" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>고객번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.memberinfo && refundData.memberinfo.cardMemberNo}</TableCell>
                <TableCell align="center" className={classes.thStyle}>고객명</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.memberinfo && refundData.memberinfo.userKorName}</TableCell>
                <TableCell align="center" className={classes.thStyle}>휴대폰번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.memberinfo && refundData.memberinfo.phoneNo1+'-'+refundData.memberinfo.phoneNo2+'-'+refundData.memberinfo.phoneNo3}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>카드번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cardinfo && refundData.cardNoMasked}</TableCell>
                <TableCell align="center" className={classes.thStyle}>상품명</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cardinfo && refundData.cardinfo.cardProductCodeName}</TableCell>
                <TableCell align="center" className={classes.thStyle}></TableCell>
                <TableCell align="center" className={classes.tdStyle}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br/>
        <div>
          <Chip label="▶ 거래정보" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>승인시각</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateTimeFormat(refundData.approvalTime)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>전문승인요청미화금액</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.textApprovalRequestUsdAmount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>요청인출미화금액</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.requestWithdrawalUsdAmount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>실인출원화금액</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.realWithdrawalKrwAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>해외브랜드미화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasBrandUsdFee)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외브랜드원화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasBrandKrwFee)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외이용미화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasUseUsdFee)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외이용원화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasUseKrwFee)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>해외출금미화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasWithdrawalUsdFee)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외출금원화수수료</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(refundData.overseasWithdrawalKrwFee)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>처리상태</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{displayProcessStatus(refundData.approvalProcessStatusCode)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>응답코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cardApprovalResponseCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>입출금일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundData.inoutDate)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>입출금처리코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(refundData.inoutProcessCode)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>입출금처리일련번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.inoutProcessSeq}</TableCell>
                <TableCell align="center" className={classes.thStyle}>결제ID</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.paymentId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>환불입금일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(refundData.refundDate)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불처리코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{displayInoutProcessCode(refundData.refundProcessCode)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불처리일련번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.refundProcessSeq}</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불결제ID</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.refundPaymentId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br/>
        <div>
          <Chip label="▶ 취소정보" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>취소일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.tradeDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>원거래승인일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.approvalDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>원거래승인번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.overseasCardApprovalNo : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>처리상태</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? displayProcessStatus(refundData.cancelinfo.approvalProcessStatusCode) : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>승인응답코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.cardApprovalResponseCode : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>미입출처리요청여부</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? (refundData.cancelinfo.nonInoutRequestYn === 'Y' ? '미입출처리요청' : '' ) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>미입출등록일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.nonInoutRegisteredDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>미입출처리일련번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.nonInoutSeq : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>입출금일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.inoutDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>입출금처리코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? displayInoutProcessCode(refundData.cancelinfo.inoutProcessCode) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>입출금처리일련번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.inoutProcessSeq : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>결제ID</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.paymentId : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>환불입금일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? chaiStringUtils.dateFormatDefault(refundData.cancelinfo.refundDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불처리코드</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? displayInoutProcessCode(refundData.cancelinfo.refundProcessCode) : '' }</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불처리일련번호</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.refundProcessSeq : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>환불결제ID</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.cancelinfo ? refundData.cancelinfo.refundPaymentId : ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br/>
        <div>
          <Chip label="▶ 차이머니거래내역" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? chaiStringUtils.dateFormatDefault(refundData.chaimoneyinfo.tradeDate) : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>idempotencyKey</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? refundData.chaimoneyinfo.idempotencyKey : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>결제ID</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? refundData.chaimoneyinfo.paymentId : ''}</TableCell>
                <TableCell align="center" className={classes.thStyle}>처리금액</TableCell>
                <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneyinfo ? chaiStringUtils.moneyFormat(refundData.chaimoneyinfo.paymentAmount) : ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br/>
        <div>
          <Chip label="▶ 차이머니취소거래내역" color="primary" size="small" className={classes.chipStyle}/>
          <Table size="small">
            <TableBody>
              <TableRow>
              <TableCell align="center" className={classes.thStyle}>거래일자</TableCell>
              <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycancelinfo ? chaiStringUtils.dateFormatDefault(refundData.chaimoneycancelinfo.tradeDate) : ''}</TableCell>
              <TableCell align="center" className={classes.thStyle}>idempotencyKey</TableCell>
              <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycancelinfo ? refundData.chaimoneycancelinfo.idempotencyKey : ''}</TableCell>
              <TableCell align="center" className={classes.thStyle}>결제ID</TableCell>
              <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycancelinfo ? refundData.chaimoneycancelinfo.paymentId : ''}</TableCell>
              <TableCell align="center" className={classes.thStyle}>처리금액</TableCell>
              <TableCell align="center" className={classes.tdStyle}>{refundData.chaimoneycancelinfo ? chaiStringUtils.moneyFormat(refundData.chaimoneycancelinfo.paymentAmount) : ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={!isDisabled()}
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '승인대사',
            '입금처리 하시겠습니까?',
            handleConfirmRefund
          )}
        >
          입금처리
        </Button>
        <Button
          disabled={!isPartialCancelDisabled()}
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '승인대사',
            '부분취소 상태변경 하시겠습니까?',
            handleConfirmPartialCancel
          )}
        >
          부분취소 상태변경
        </Button>
        <Button
          color="default"
          onClick={handleOverseasApprHistoryLogRefundClose}
          variant="contained"
        >
          닫기
        </Button>        
      </DialogActions>
    </Dialog>
  )
}

export default OverseasApprHistoryLogRefund;
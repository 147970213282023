import React, { FC, useState, useEffect } from 'react';
import apolloClient from '../../lib/apolloClient';
import { onlineBuismHistoryMutation } from '../../lib/apolloClient/mutations';
import OnlineBuismHistoryListToolbar from './OnlineBuismHistoryListToolbar';
import OnlineBuismHistoryTable from './OnlineBuismHistoryTable';
import { makeStyles, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import { MenuTitleToolbar } from '../../components';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  detail: {
    marginTop: theme.spacing(3)
  }
}));

type Props = { 
  listInfo: OnlineBuismHistoryList
}

const OnlineBuismHistory: FC<Props> = ({ listInfo }) => {
 
  const default_page = 0;
  const default_rowsPerPage = 100;
  const classes = useStyles();
  const [listData, setListData] = useState(listInfo);
  const [listSearch, setListSearch] = useState({
    startReceveBaseDate: format(subMonths(new Date(), 1), 'yyyyMMdd'), 
    endReceveBaseDate: format(new Date(), 'yyyyMMdd'), 
    purchasedProcessNo: '',
    isExcelDown: false
  });

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const getList = async () => {
      await apolloClient.mutate({
        mutation: onlineBuismHistoryMutation.selectOnlineBuismHistoryList,
        variables: { startReceveBaseDate: listSearch.startReceveBaseDate
                   , endReceveBaseDate: listSearch.endReceveBaseDate
                   , purchasedProcessNo: listSearch.purchasedProcessNo 
                   , page: paging.page
                   , rowsPerPage: paging.rowsPerPage
                   , isExcelDown: isExcelDown
                  }
      }).then(res => {        
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectOnlineBuismHistoryList.list);
        }else{
          setListData(res.data.selectOnlineBuismHistoryList)
        }
        
        setListData(res.data.selectOnlineBuismHistoryList)
      }).catch(console.error);
    }
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSearch, paging, isFlag])

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: OnlineBuismHistoryInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
            'Chai정산일자': `${chaiStringUtils.dateFormatDefault(csvData[i].receveBaseDate)}`,
            'BC재정산매입일자': `${chaiStringUtils.dateFormatDefault(csvData[i].reSettlementReceveDate)}`,
            'BC재정산일자': `${chaiStringUtils.dateFormatDefault(csvData[i].reSettlementDate)}`,
            '거래일자': `${chaiStringUtils.dateFormatDefault(csvData[i].salesDate)}`,
            '매출종류': `${chaiStringUtils.codeName('salesKindDivisionCode', csvData[i].salesKindDivisionCode)}(${csvData[i].salesKindDivisionCode})`,
            '카드번호': `${csvData[i].cardNoMasked}`,
            '매입접수번호': `${csvData[i].purchasedProcessNo}`,
            '원매출금액': `${chaiStringUtils.moneyFormat(csvData[i].originSalesSalesAmount)}`,
            '원매출가맹점수수료율': `${csvData[i].originSalesMerchantFeeRate}`,
            '원매출가맹점수수료': `${chaiStringUtils.moneyFormat(csvData[i].originSalesMerchantFee)}`,
            '재계산가맹점수수료율': `${csvData[i].reCalcuMerchantFeeRate}`,
            '재계산가맹점수수료': `${chaiStringUtils.moneyFormat(csvData[i].reCalcuMerchantFee)}`
        });
    }

    utils.exportToCSV("온라인사업자재정산내역", rowData);    
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="온라인사업자재정산내역 조회" />
      <OnlineBuismHistoryListToolbar /** 검색조건 */ onSearch={setListSearch} page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging}/>
      <div className={classes.content}>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <OnlineBuismHistoryTable /** 온라인사업자매출내역LIST */ dataList={listData} page={paging.page} rowsPerPage={paging.rowsPerPage} setPaging={setPaging} />
      </div>
    </div>
  );
};

export default OnlineBuismHistory;

import React, { FC, ChangeEvent, useEffect } from 'react';
// import { useHistory } from 'react-router-dom' 
// import apolloClient from '../../lib/apolloClient';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { issueCard } from '../../lib/cardApi'
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

const useStyles = makeStyles(() => ({
  root: {},
  dialogContent: {
    minWidth: '400px',
  }
}));

type Props = {
  className?: string,
  userInfo: IssueCardInput,
  setUserInfo: React.Dispatch<React.SetStateAction<IssueCardInput>>,
}
const UpdateCardDetails: FC<Props> = ({ className, userInfo, setUserInfo }) => {
  const classes = useStyles();
  const { showToast } = useToast();
 // const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

/*
  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(userInfo).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true)
    setIsValidForm(isValid);
  }

*/

  useEffect(() => {
  //  checkFormValidation();
  // eslint-disable-next-line
  }, [userInfo])

  /*
  const [issueResult, setIssueResult] = React.useState({
    success: '',
    message: ''
  });
  */

  const { showDialog, hideDialog } = useDialog();

  const handleConfirmAddConfirm = async () => {
    try {
      
   userInfo.ProcClss = '2';
   userInfo.AplcClss = '';
   userInfo.SndMthd = '2';
   userInfo.CardInvitCnstYnTel = '1';	
   userInfo.CardInvitCnstYnSms='1';
   userInfo.CardInvitCnstYnWrnt='1';
   userInfo.CardInvitCnstYnEmail='1';
   userInfo.InfoGdsGthrCnstYn='1';	 
   userInfo.InfoSvcGthrCnstYn='1';	 
   userInfo.UniqInfoGthrCnstYn2='1';
   userInfo.InfoIsurSppyCnstYn='1';
   userInfo.InfoFrauUseSppyCnstYn='1';
   userInfo.InfoAffiSppyCnstYn='1';
   userInfo.UniqInfoSppyCnstYn2='1';


      const issueCardResult = await issueCard(userInfo);
      console.log(issueCardResult);


      hideDialog();
      showDialog(
        '신청 결과',
        `\n성공여부: ${issueCardResult.STATUS.success}\n메세지:${issueCardResult.STATUS.message}`,
        () => {
         // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
          hideDialog();
        }
      )
    } catch (e) {
      hideDialog();
      showToast(e.message, 'error');
    };
  }

/*
  const gender = [
    {
      value: '1',
      label: '남'
    },
    {
      value: '2',
      label: '여'
    },
  ];

  const isForeigner = [
    {
      value: '2',
      label: '내국인'
    },
    {
      value: '1',
      label: '외국인'
    },
  ];

  const trafficCardIssueYn = [
    {
      value: '1',
      label: '신청'
    },
    {
      value: '9',
      label: '미신청'
    },
  ];

  const BrndClss = [
    {
      value: '1',
      label: 'BC로컬'
    },
    {
      value: '3',
      label: 'VISA'
    },
  ];
 
  const SndAddrClss = [
    {
      value: '1',
      label: '자택'
    },
    {
      value: '2',
      label: '근무처'
    },
  ];
   */

  const isEmpty = (input: string) => {
    return input === '';
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="발급 정정 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="신청 카드번호"
                margin="dense"
                name="AplcCardNo"
                onChange={handleChange}
                required
                value={userInfo.AplcCardNo}
                variant="outlined"
                error={isEmpty(userInfo.AplcCardNo)}
                helperText={isEmpty(userInfo.AplcCardNo) ? 'Required' : ''}
              />
            </Grid> 
           <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="영문 이름"
                margin="dense"
                name="EngNm"
                onChange={handleChange}
                required
                value={userInfo.EngNm}
                variant="outlined"
                error={isEmpty(userInfo.EngNm)}
                helperText={isEmpty(userInfo.EngNm) ? 'Required' : ''}
              />
            </Grid>
           
                   
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
         //   disabled={!isValidForm}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '발급 정정',
              '해당 정보로 신청하시겠습니까?',
              handleConfirmAddConfirm
            )}
          >
            발급 정정 
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default UpdateCardDetails;
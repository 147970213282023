import 'date-fns';
import React, { FC, useState } from 'react';
import { Grid, TextField, Card, CardContent, Button } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import chaiCodeList from '../../common/chaiCodeList.js';
import { format, subMonths } from 'date-fns';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<PurchasedHistorySearch>>;
  page: number;
  rowsPerPage: number;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

const PurchasedHistoryListToolbar: FC<Props> = ({ onSearch, page, rowsPerPage, setPaging }) => {
  const [pSearchGubun, setSearchGubun] = useState(chaiStringUtils.getSchParamGubun());
  const [pSearchText, setSearchText] = useState(
    chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun())
  );
  const [pStartReceveBaseDate, setStartReceveBaseDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [pEndReceveBaseDate, setEndReceveBaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [pPurchasedProcessNo, setPurchasedProcessNo] = useState('');
  const [pSalesKindDivisionCode, setSalesKindDivisionCode] = useState('');
  const [pMerchantNo, setMerchantNo] = useState('');
  const [pAccountProcessSeq, setAccountProcessSeq] = useState('');
  const [pAccountDivisionCode, setAccountDivisionCode] = useState('');

  const { showToast } = useToast();
  const { hideDialog } = useDialog();

  // 검색조건
  const handleSearchGubunChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchGubun(event.target.value as string);
  };
  // 검색값
  const handleSearchTextChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  // Chai정산시작일자
  const handleStartReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartReceveBaseDate(e.target.value);
  };
  // Chai정산종료일자
  const handleEndReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndReceveBaseDate(e.target.value);
  };
  // 매입접수번호
  const handlePurchasedProcessNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurchasedProcessNo(event.target.value);
  };
  // 매출종류구분코드
  const handleSalesKindDivisionCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSalesKindDivisionCode(event.target.value as string);
  };
  // 가맹점번호
  const handleMerchantNoChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMerchantNo(event.target.value);
  };
  // 계정처리순번
  const handleAccountProcessSeqChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountProcessSeq(event.target.value);
  };
  // 계정구분코드
  const handleAccountDivisionCodeChnage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAccountDivisionCode(event.target.value as string);
  };

  const onSearchClick = async () => {
    const purchasedHistorySearch: PurchasedHistorySearch = {} as any;

    if (pStartReceveBaseDate == null) {
      showToast('Chai정산시작일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    if (pEndReceveBaseDate == null) {
      showToast('Chai정산종료일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    const paging: Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    purchasedHistorySearch.searchGubun = pSearchGubun;
    purchasedHistorySearch.searchText = pSearchText;
    purchasedHistorySearch.startReceveBaseDate = chaiStringUtils.dateFormat(pStartReceveBaseDate, 'yyyyMMdd');
    purchasedHistorySearch.endReceveBaseDate = chaiStringUtils.dateFormat(pEndReceveBaseDate, 'yyyyMMdd');
    purchasedHistorySearch.purchasedProcessNo = pPurchasedProcessNo;
    purchasedHistorySearch.salesKindDivisionCode = pSalesKindDivisionCode;
    purchasedHistorySearch.merchantNo = pMerchantNo;
    purchasedHistorySearch.accountProcessSeq = Number(pAccountProcessSeq);
    purchasedHistorySearch.accountDivisionCode = pAccountDivisionCode;
    purchasedHistorySearch.isExcelDown = false;

    // 공통파라미터 저장
    chaiStringUtils.setSchParamGubun(pSearchGubun);
    chaiStringUtils.setSchParamSearchVal(pSearchGubun, pSearchText);

    onSearch(purchasedHistorySearch);
  };

  // 계정구분코드 목록
  const accountDivisionCodeList = chaiCodeList.codeData.accountDivisionCode;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              select
              fullWidth
              label="검색조건"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pSearchGubun"
              value={pSearchGubun}
              onChange={handleSearchGubunChange}
            >
              <option value="1">휴대폰번호</option>
              <option value="2">카드번호</option>
              <option value="3">고객번호</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="검색값"
              placeholder="검색값"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pSearchText"
              value={pSearchText}
              onChange={handleSearchTextChnage}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pStartReceveBaseDate"
              value={pStartReceveBaseDate}
              onChange={handleStartReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산종료일자"
              margin="dense"
              InputProps={{
                inputProps: {
                  min: pStartReceveBaseDate ? format(new Date(pStartReceveBaseDate), 'yyyy-MM-dd') : undefined,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pEndReceveBaseDate"
              value={pEndReceveBaseDate}
              onChange={handleEndReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              fullWidth
              label="매출종류"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pSalesKindDivisionCode"
              value={pSalesKindDivisionCode}
              onChange={handleSalesKindDivisionCodeChange}
            >
              <option value="">전체</option>
              <option value="05">일반매출</option>
              <option value="15">취소매출</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="가맹점번호"
              placeholder="가맹점번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pMerchantNo"
              value={pMerchantNo}
              onChange={handleMerchantNoChnage}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="매입접수번호"
              placeholder="매입접수번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pPurchasedProcessNo"
              value={pPurchasedProcessNo}
              onChange={handlePurchasedProcessNoChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="계정처리순번"
              placeholder="계정처리순번"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pAccountProcessSeq"
              value={pAccountProcessSeq}
              onChange={handleAccountProcessSeqChnage}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              fullWidth
              label="계정구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pAccountDivisionCode"
              value={pAccountDivisionCode}
              onChange={handleAccountDivisionCodeChnage}
            >
              <option value="">전체</option>
              {accountDivisionCodeList.map(option => (
                <option key={option.codeValue} value={option.codeValue}>
                  {option.codeNm}({option.codeValue})
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button color="primary" variant="contained" onClick={onSearchClick} style={{ marginTop: '9px' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PurchasedHistoryListToolbar;

import {
  // Table,
  // TableBody,
  Button,
  Card,
  CardContent,
  CardHeader,
  // CardActions,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../Auth';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { Approval } from '../../components';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import apolloClient from '../../lib/apolloClient';
import { decryptCardNoMutation } from '../../lib/apolloClient/mutations';
import { passwordChange, passwordErrorReset } from '../../lib/cardApi';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: '#F4F6F8'
    }
  }
}))(TableRow);
**/

const isEmpty = (input: string) => {
  return input === '';
};

//비밀번호
type PasswordInfo = {
  password1: string;
  password2: string;
};

type Props = {
  cardInfo: CardInfo;
  merchants: Merchant[];
  cardUserInfo: GetCardUserInfoReturn;
  setCardInfo: React.Dispatch<React.SetStateAction<CardInfo>>;
  refreshCardInfo: (cardNumber: string) => Promise<void>;
  refreshApprovals: (cardNumber: string) => Promise<void>;
  refreshAccidents: (cardNumber: string) => Promise<void>;
};
const CardInfo: FC<Props> = ({
  merchants,
  cardInfo,
  cardUserInfo,
  refreshCardInfo,
  refreshApprovals,
  refreshAccidents,
}) => {
  // const classes = useStyles();
  const auth = useAuth();
  const hasCardPermission = auth.user?.permission?.hasCardPermission;
  const [approvalOpen, setApprovalOpen] = useState(false);
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const [passwordForm, setPasswordForm] = useState<PasswordInfo>({
    password1: '',
    password2: '',
  });

  const [decryptedCardNo, setDecryptedCardNo] = useState(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordForm({
      ...passwordForm,
      [e.target.name]: e.target.value,
    });
  };

  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(passwordForm).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true);
    setIsValidForm(isValid);
  };

  useEffect(() => {
    checkFormValidation();
    // eslint-disable-next-line
  }, [passwordForm]);

  // 비밀번호 변경
  const onPasswordUpdate = async () => {
    try {
      console.log('선택한 카드번호:  ' + cardInfo.cardNo);
      console.log('변경할 비밀번호 : ' + passwordForm.password1);
      console.log('변경할 비밀번호확인 : ' + passwordForm.password2);

      // 입력한 비밀번호 확인
      if (passwordForm.password1 !== passwordForm.password2) {
        showToast('입력하신 비밀번호가 일치하지 않습니다.', 'error');

        hideDialog();
        return;
      }

      // 비밀번호 유효성 검증
      //await passwordVerify(passwordForm.password1, cardUserInfo.homeNo2, cardUserInfo.homeNo3);

      // 비밀번호 변경처리
      const chgResult = await passwordChange(cardInfo.cardNo, passwordForm.password1);
      if (chgResult.STATUS.success !== 'Y') {
        showToast(chgResult.STATUS.message, 'error');
        setPasswordForm({
          ...passwordForm,
          password1: '',
          password2: '',
        });
        return;
      } else {
        showToast('비밀번호 변경이 정상적으로 처리되었습니다.', 'error');
        setPasswordForm({
          ...passwordForm,
          password1: '',
          password2: '',
        });
        return;
      }
    } catch (e) {
      showToast(e.message, 'error');
      console.log(e);

      hideDialog();
    }
  };
  // 비밀번호 오류횟수 초기화
  const onPasswordErrorReset = async () => {
    try {
      console.log('선택한 카드번호:  ' + cardInfo.cardNo);
      const resetResult = await passwordErrorReset(cardInfo.cardNo);

      if (resetResult.STATUS.success !== 'Y') {
        showToast(resetResult.STATUS.message, 'error');
        return;
      } else {
        showToast('비밀번호 오류횟수 초기화가 완료되었습니다.', 'error');
        hideDialog();
      }
    } catch (e) {
      showToast(e.message, 'error');
      console.log(e);

      hideDialog();
    }
  };

  /**
  const onAccidentConfirm = async (accidentCode: string) => {
    try {
      await accident({
        mnbdNbr: cardInfo.cardNo,
        acdntCd: accidentCode,
        reptPrsnNm: cardUserInfo.custKorName,
        cntctTelNbr: cardUserInfo.phone
      });
      await refreshCardInfo(cardInfo.cardNo);
    } catch (e) {
      showToast(e.message, 'error');
      console.log(e);
    }
    hideDialog();
  };
  **/

  /**
  const onReissueConfirm = async () => {
    try {
      **
      const getUserInfoResult = await getUserInfo(cardUserInfo.csno, true, cardInfo.cardNo)
      await issueCard({
        id: cardUserInfo.csno,
        productName: 'CHAI카드',
        korName: cardUserInfo.custKorName,
        engName: cardUserInfo.custEngName,
        birthday: cardUserInfo.birthDt,
        gender: cardUserInfo.sexDvsnCd,
        isForeigner: cardUserInfo.foreignerDvsnCd,
        phoneNumber: cardUserInfo.phone,
        ci: '',
        address: cardUserInfo.addr,
        zipcode: cardUserInfo.zipCode,
        password: '1234',
        passwordConfirm: '1234',
      }, getUserInfoResult.aplnCardNo, cardInfo.valdTermYm, '21', cardInfo.cardNo);
      **
      await refreshCardInfo(cardInfo.cardNo);
    } catch (e) {
      console.log(e);
      showToast(e.message, 'error');
    }
    hideDialog();
  };
  **/

  const handleClickCardUnmask = async () => {
    if (!hasCardPermission) {
      return;
    }

    if (decryptedCardNo) {
      setDecryptedCardNo(undefined);
      return;
    }

    const { cardNo } = cardInfo;

    await apolloClient
      .mutate({
        variables: { cardNo },
        mutation: decryptCardNoMutation.decryptCardNo,
      })
      .then(res => {
        const cardNo = res.data?.decryptCardNo?.cardNo;
        if (cardNo) {
          setDecryptedCardNo(res.data?.decryptCardNo?.cardNo);
        }
      })
      .catch(console.error);
  };

  return (
    <Card>
      <form>
        <CardHeader title="카드 상세정보" />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="카드번호"
                margin="dense"
                onChange={handleChange}
                disabled
                value={decryptedCardNo || cardInfo.cardNoMasked}
                variant="outlined"
                InputProps={{
                  endAdornment: hasCardPermission ? (
                    <InputAdornment position="end">
                      <IconButton aria-label="카드번호 Unmask" onClick={handleClickCardUnmask} edge="end">
                        {decryptedCardNo ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
                }}
              />{' '}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="상품명"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.prdtName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {/*<TextField
                fullWidth
                label="유효기간"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.valdTermYm}
                variant="outlined"
              />*/}
              <NumberFormat
                customInput={TextField}
                fullWidth
                label="유효기간"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.valdTermYm}
                variant="outlined"
                format="####/##"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="등록일자"
                margin="dense"
                onChange={handleChange}
                disabled
                value={chaiStringUtils.dateFormat(cardInfo.isucDt, 'yyyy.MM.dd')}
                variant="outlined"
              />
              {/*<NumberFormat
                customInput={TextField}
                fullWidth
                label="등록일자"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.isucDt}
                variant="outlined"
                format = "####.##.##"
              />*/}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="해지일자"
                margin="dense"
                onChange={handleChange}
                disabled
                value={chaiStringUtils.dateFormat(cardInfo.terminateDate, 'yyyy.MM.dd')}
                variant="outlined"
              />
              {/*<NumberFormat
                customInput={TextField}
                fullWidth
                label="해지일자"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.terminateDate}
                variant="outlined"
                format = "####.##.##"
              />*/}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="최종카드여부"
                margin="dense"
                onChange={handleChange}
                disabled
                value={cardInfo.lastCardYn}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                type="password"
                label="비밀번호"
                margin="dense"
                name="password1"
                onChange={handleChange}
                required
                value={passwordForm.password1}
                variant="outlined"
                error={isEmpty(passwordForm.password1)}
                helperText={isEmpty(passwordForm.password1) ? 'Required' : ''}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                type="password"
                label="비밀번호 확인"
                margin="dense"
                name="password2"
                onChange={handleChange}
                required
                value={passwordForm.password2}
                variant="outlined"
                error={isEmpty(passwordForm.password2)}
                helperText={isEmpty(passwordForm.password2) ? 'Required' : ''}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Button disabled={!isValidForm} variant="outlined" color="primary" onClick={() => onPasswordUpdate()}>
                비밀번호 변경
              </Button>{' '}
              &nbsp;&nbsp;
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  showDialog('비밀번호 오류횟수 초기화', '비밀번호 오류횟수를 초기화 하시겠습니까?', () =>
                    onPasswordErrorReset()
                  )
                }
              >
                비밀번호 오류횟수 초기화
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>

      <Approval
        cardInfo={cardInfo}
        merchants={merchants}
        refreshApprovals={refreshApprovals}
        approvalOpen={approvalOpen}
        setApprovalOpen={setApprovalOpen}
      />
    </Card>
  );
};

export default CardInfo;

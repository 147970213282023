import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    textAlign: 'center',
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    textAlign: 'center',
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
}));

type Props = {
  detailData?: any;
  approvalDetailPopOpen: boolean;
  setApprovalDetailPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApprovalDetailPop: FC<Props> = ({ detailData, approvalDetailPopOpen, setApprovalDetailPopOpen }) => {
  const classes = useStyles();
  const handleApprovalDetailPopClose = () => {
    setApprovalDetailPopOpen(false);
  };

  // eslint-disable-next-line
  const displayProcessStatus = (processStatus: string) => {
    let displayStatus = '';
    if (processStatus === 'confirm') displayStatus = '정상';
    else if (processStatus === 'canceled') displayStatus = '취소';
    else displayStatus = '처리중';

    return displayStatus;
  };

  return (
    <Dialog
      open={approvalDetailPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <MenuTitleToolbar title="승인내역 상세조회" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Chip label="▶ 카드승인내역" color="primary" size="small" className={classes.chipStyle} />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={classes.thStyle}>승인번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.approvalNumber}</TableCell>
                <TableCell className={classes.thStyle}>승인일</TableCell>
                <TableCell className={classes.tdStyle}>
                  {chaiStringUtils.dateFormatDefault(detailData.approvalDate)}
                </TableCell>
                <TableCell className={classes.thStyle}>승인구분</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>카드번호</TableCell>
                <TableCell className={classes.tdStyle}>****-****-****-{detailData.cardLastNumber}</TableCell>
                <TableCell className={classes.thStyle}>할부개월수</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.installmentMonth}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>해외결제 여부</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.isOverseasPurchase ? 'Y' : 'N'}</TableCell>
                <TableCell className={classes.thStyle}>승인통화</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.currency}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>현지승인금액</TableCell>
                <TableCell className={classes.tdStyle}>{detailData?.amount?.toLocaleString()}</TableCell>
                <TableCell className={classes.thStyle}>승인금액 (원화)</TableCell>
                <TableCell className={classes.tdStyle}>{detailData?.amountKRW?.toLocaleString()}</TableCell>
                <TableCell className={classes.thStyle}>승인금액 (달러)</TableCell>
                <TableCell className={classes.tdStyle}>{detailData?.amountUSD?.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>수수료 원화</TableCell>
                <TableCell className={classes.tdStyle}>{detailData?.feeKRW?.toLocaleString()}</TableCell>
                <TableCell className={classes.thStyle}>환율</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.exchangeRate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>가맹점명</TableCell>
                <TableCell className={classes.tdStyle}>{detailData._merchant?.name}</TableCell>
                <TableCell className={classes.thStyle}>가맹점번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailData._merchant?.id}</TableCell>
                <TableCell className={classes.thStyle}>가맹점업종코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailData._merchant?.businessCode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.thStyle}>머니 승인번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailData.paymentId}</TableCell>
                <TableCell className={classes.thStyle}>생성시간</TableCell>
                <TableCell className={classes.tdStyle}>{new Date(detailData?.createdAt).toLocaleString()}</TableCell>
                <TableCell className={classes.thStyle}>변경시간</TableCell>
                <TableCell className={classes.tdStyle}>{new Date(detailData?.updatedAt).toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleApprovalDetailPopClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalDetailPop;

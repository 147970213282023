import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import ProxyFeeCodeToolbar from './ProxyFeeCodeToolbar';
import ProxyFeeCodeList from './ProxyFeeCodeList';
import ProxyFeeCodeDetails from './ProxyFeeCodeDetails';
import { proxyFeeCodeMutation } from '../../lib/apolloClient/mutations';
import apolloClient from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import { format, addMonths } from 'date-fns';

// 대행수수료코드관리

type Props = {
  className?: string;
  feeCodeInfoReturn: FeeCodeInfoReturn;
  feeCodeInfoSearchReturn: FeeCodeInfoReturn;
};

const ProxyFeeCodeManagement: FC<Props> = ({
  feeCodeInfoReturn,
  feeCodeInfoSearchReturn
}) => {
  const [feeCodeInfo, setfeeCodeInfo] = useState({
    proxyFeeGroupCode: "1", // 서비스 수수료 그룹
    proxyFeeCodeNo: '', // 서비스 수수료 코드
    proxyFeeNm: '', // 수수료명
    unitFeeCodeNo: '', // 단위항목코드
    unitFeeName: '', // 단위항목명
    feeClaimMethodDivisionCode: "1", // 수수료청구방식
    applyUnitprc: 0, // 적용단가
    feeBaseRate: 0.0, // 수수료 기준율
    feeProofDivisionCode: '', // 수수료 증빙 구분
    feeAutoYn: "N", // 자동대사 여부
    validateStartDate: format(new Date(), 'yyyyMMdd'), // 유효시작일자
    validateEndDate: format(addMonths(new Date(), 1),'yyyyMMdd'), // 유효종료일자
    deleteYn: "N", // 삭제여부
    domesticCode: "1", // 국내외 구분
    feeInfoDivisionCode: '', // 대행 수수료 구분
    domesticBrandDivisionCode: "1" // 국내외 상품 구분 - details만
  });

  const [feeCodeInfoSearch, setFeeCodeInfoSearch] = useState({
    feeInfoDivisionCode: '',
    deleteYn: '',
    proxyFeeCodeNo: '',
    proxyFeeNm: '',
    proxyFeeGroupCode: '',
    unitFeeCodeNo: '',
    unitFeeName: ''
  });

  const [listData, setListData] = useState<Array<FeeCodeInfoReturn>>([]);

  const classes = useStyles();
  const [updateClick, setUpdateClick] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [isEmptyForm, setIsEmptyForm] = useState(true);

  const checkFormValidation = () => {
    if (feeCodeInfo.feeInfoDivisionCode === '1') {
      if (
        (isEmpty(feeCodeInfo.proxyFeeGroupCode) ||
          isEmpty(feeCodeInfo.proxyFeeCodeNo) ||
          isEmpty(feeCodeInfo.deleteYn) ||
          isEmpty(feeCodeInfo.proxyFeeNm)) === false &&
        (feeCodeInfo.deleteYn === 'Y' || feeCodeInfo.deleteYn === 'N')
      ) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
      setUpdateClick(updateClick);
    } else {
      if (
        (isEmpty(feeCodeInfo.proxyFeeGroupCode) ||
          isEmpty(feeCodeInfo.proxyFeeCodeNo) ||
          isEmpty(feeCodeInfo.unitFeeCodeNo) ||
          isEmpty(feeCodeInfo.unitFeeName) ||
          isEmpty(feeCodeInfo.deleteYn) ||
          isEmpty(feeCodeInfo.proxyFeeNm) ||
          isEmpty(feeCodeInfo.feeAutoYn) ||
          isEmpty(feeCodeInfo.feeClaimMethodDivisionCode) ||
          isEmptyNumb(feeCodeInfo.feeBaseRate) ||
          isEmptyNumb(feeCodeInfo.applyUnitprc) ||
          isEmpty(feeCodeInfo.domesticBrandDivisionCode)) === false &&
        (feeCodeInfo.deleteYn === 'Y' || feeCodeInfo.deleteYn === 'N') &&
        (feeCodeInfo.feeAutoYn === 'Y' || feeCodeInfo.feeAutoYn === 'N')
      ) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
      setUpdateClick(updateClick);
    }
  };

  const [isNew, setIsNew] = useState(false);

  const isEmpty = (input: string) => {
    // updateCode.tsx의 textField 값의 유무 확인
    return input === '';
  };

  const isEmptyNumb = (input: number) => {
    return input === 0;
  };

  const checkFormEmpty = () => {
    if (feeCodeInfo.feeInfoDivisionCode === '1') {
      if (
        (isEmpty(feeCodeInfo.proxyFeeGroupCode) &&
          isEmpty(feeCodeInfo.proxyFeeCodeNo) &&
          isEmpty(feeCodeInfo.proxyFeeNm) &&
          isEmpty(feeCodeInfo.validateStartDate) &&
          isEmpty(feeCodeInfo.validateEndDate) &&
          isEmpty(feeCodeInfo.deleteYn) &&
          isEmpty(feeCodeInfo.domesticCode) &&
          isEmpty(feeCodeInfo.domesticBrandDivisionCode)) === true
      ) {
        setIsEmptyForm(true);
      } else {
        setIsEmptyForm(false);
      }
    } else {
      if (
        (isEmpty(feeCodeInfo.proxyFeeGroupCode) &&
          isEmpty(feeCodeInfo.proxyFeeCodeNo) &&
          isEmpty(feeCodeInfo.proxyFeeNm) &&
          isEmpty(feeCodeInfo.unitFeeCodeNo) &&
          isEmpty(feeCodeInfo.unitFeeName) &&
          isEmpty(feeCodeInfo.feeClaimMethodDivisionCode) &&
          isEmptyNumb(feeCodeInfo.applyUnitprc) &&
          isEmptyNumb(feeCodeInfo.feeBaseRate) &&
          isEmpty(feeCodeInfo.feeProofDivisionCode) &&
          isEmpty(feeCodeInfo.feeAutoYn) &&
          isEmpty(feeCodeInfo.validateStartDate) &&
          isEmpty(feeCodeInfo.validateEndDate) &&
          isEmpty(feeCodeInfo.deleteYn) &&
          isEmpty(feeCodeInfo.domesticCode) &&
          isEmpty(feeCodeInfo.domesticBrandDivisionCode)) === true
      ) {
        setIsEmptyForm(true);
      } else {
        setIsEmptyForm(false);
      }
    }
  };

  useEffect(() => {
    let feeCodeList = () => {};
    if (
      feeCodeInfoSearch.feeInfoDivisionCode === null ||
      feeCodeInfoSearch.feeInfoDivisionCode === undefined ||
      feeCodeInfoSearch.feeInfoDivisionCode === ''
    ) {
      feeCodeList = async () => {
        apolloClient
          .mutate({
            variables: {
              input: {
                feeCodeNo: feeCodeInfoSearch.proxyFeeCodeNo,
                feeCodeName: feeCodeInfoSearch.proxyFeeNm,
                deleteYn: feeCodeInfoSearch.deleteYn,
                proxyFeeGroupCode: feeCodeInfoSearch.proxyFeeGroupCode
              }
            },
            mutation: proxyFeeCodeMutation.selectAllOfFeeCodeList
          })
          .then(res => {
            setListData(res.data.selectAllOfFeeCodeList);
          });
      };
    } else if (feeCodeInfoSearch.feeInfoDivisionCode === '1') {
      feeCodeList = async () => {
        await apolloClient
          .mutate({
            variables: {
              input: {
                feeCodeNo: feeCodeInfoSearch.proxyFeeCodeNo,
                feeCodeName: feeCodeInfoSearch.proxyFeeNm,
                deleteYn: feeCodeInfoSearch.deleteYn,
                proxyFeeGroupCode: feeCodeInfoSearch.proxyFeeGroupCode
              }
            },
            mutation: proxyFeeCodeMutation.selectProxyFeeCodeList
          })
          .then(res => {
            console.log('페칭전 : ', listData);
            setListData(res.data.selectProxyFeeCodeList);
            console.log('펫칭후: ', listData);
            console.log('위임수수료코드 fetching: ');
            console.log(res.data.selectProxyFeeCodeList);
          })
          .catch(console.error);
      };
    } else if (feeCodeInfoSearch.feeInfoDivisionCode === '2') {
      feeCodeList = async () => {
        await apolloClient
          .mutate({
            variables: {
              input: {
                deleteYn: feeCodeInfoSearch.deleteYn,
                feeCodeNo: feeCodeInfoSearch.unitFeeCodeNo,
                feeCodeName: feeCodeInfoSearch.unitFeeName,
                proxyFeeGroupCode: feeCodeInfoSearch.proxyFeeGroupCode
              }
            },
            mutation: proxyFeeCodeMutation.selectUnitFeeCodeList
          })
          .then(res => {
            setListData(res.data.selectUnitFeeCodeList);
          })
          .catch(console.error);
      };
    }
    feeCodeList();
    checkFormEmpty();
    checkFormValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateClick, feeCodeInfoSearch]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="대행수수료코드관리" />
      <ProxyFeeCodeToolbar
        setFeeCodeInfoSearch={setFeeCodeInfoSearch}
        feeCodeInfoSearch={feeCodeInfoSearch}
      />
      <div className={classes.content}>
        <ProxyFeeCodeList
          updateClick={updateClick}
          proxyFeeCodeList={listData}
          setProxyFeeCodeList={setfeeCodeInfo}
          setIsValidForm={setIsValidForm}
          setIsEmptyForm={setIsEmptyForm}
          setUpdateClick={setUpdateClick}
          setIsNew={setIsNew}
        />
      </div>
      <div className={classes.content}>
        <ProxyFeeCodeDetails
          updateClick={updateClick}
          setUpdateClick={setUpdateClick}
          proxyFeeCodeInfo={feeCodeInfo}
          setProxyFeeCodeInfo={setfeeCodeInfo}
          setIsValidForm={setIsValidForm}
          isValidForm={isValidForm}
          checkFormValidation={checkFormValidation}
          setIsEmptyForm={setIsEmptyForm}
          isEmptyForm={isEmptyForm}
          checkFormEmpty={checkFormEmpty}
          isNew={isNew}
          setIsNew={setIsNew}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

export default ProxyFeeCodeManagement;

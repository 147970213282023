import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
    Card,
    CardActions,
    CardContent,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TablePagination
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
    padding: 0
    },
    dialogContent: {
    minWidth: '400px',
    },
    icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
    },
    inner: {
    minWidth: 1050
    },
    nameContainer: {
    display: 'flex',
    alignItems: 'center'
    },
    avatar: {
    marginRight: theme.spacing(2)
    },
    actions: {
    justifyContent: 'flex-end'
    },
    isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
    },
    statusContainer: {
    display: 'flex',
    alignItems: 'center'
    },
    status: {
    marginRight: theme.spacing(1)
    },
    button: {
    margin: theme.spacing(1),
    },
    thStyle: {
    backgroundColor: '#eeeeee',
    }, 
    tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '300px',
    },
}));

type Props = {
    overseasExchRateDiffList : OvrsExchRateDiffHistoryInfo[]
}

const OvrsExchRateDiffTable: FC<Props> = ({ overseasExchRateDiffList }) => {
    
    const classes = useStyles();

    const getThStyle = (item: OvrsExchRateDiffHistoryInfo) => {
        if (item.aggregationDate === '99999999') {
            return classes.thStyle;
        } else {
            return '';
        }
    }; 

    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);

    const handlePageChange = (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => {
        setPage(page);
    };

    const handleRowsPerPageChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
    };

    return (
        <Card>
            <CardContent className={classes.content} style={{paddingBottom: '0'}}>
            <PerfectScrollbar>
                <div className={classes.inner}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell align='center' rowSpan={2}>No</TableCell> 
                    <TableCell align='center' rowSpan={2}>정산일자</TableCell> 
                    <TableCell align='center' colSpan={3}>정산미화금액(달러)</TableCell>
                    <TableCell align='center' colSpan={3}>정산원화금액(원)</TableCell>
                    <TableCell align='center' colSpan={2}>환율차이총액(원)</TableCell>
                    <TableCell align='center' colSpan={2}>환율정보</TableCell>
                    <TableCell align='center' rowSpan={2}>정산환율적용금액 </TableCell>
                    <TableCell align='center' colSpan={2}>최종환율손익 </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell align='center'>출금금액 </TableCell>
                    <TableCell align='center'>입금금액 </TableCell>
                    <TableCell align='center'>거래총액 </TableCell>
                    <TableCell align='center'>출금금액 </TableCell>
                    <TableCell align='center'>입금금액 </TableCell>
                    <TableCell align='center'>거래총액 <span style={{color:'blue'}}>(D)</span></TableCell>
                    <TableCell align='center'>매입시점 <span style={{color:'blue'}}>(A)</span></TableCell>
                    <TableCell align='center'>정산시점 <span style={{color:'blue'}}>(B)</span></TableCell>
                    <TableCell align='center'>매입시점 </TableCell>
                    <TableCell align='center'>정산시점 </TableCell>
                    <TableCell align='center'>손익금액 <p><span style={{color:'blue'}}>(C = A + B)</span></p> </TableCell>
                    <TableCell align='center'>손익율(%) <p><span style={{color:'blue'}}>(E = ( C / D ) * 100)</span> </p> </TableCell>
                    </TableRow>                                   
                </TableHead>
                <TableBody>
                    {overseasExchRateDiffList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                    <TableRow  hover key={index} className={getThStyle(item)}>   
                        <TableCell align='center'>{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>            
                        <TableCell align='center'>{item.aggregationDate === '99999999' ? '합계' : chaiStringUtils.dateFormatDefault(item.aggregationDate)}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setUsdWdTotalAmount).concat(' $')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setUsdDpTotalAmount).concat(' $')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setUsdTdTotalAmount).concat(' $')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setKrwWdTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setKrwDpTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setKrwTdTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setExRtDifTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.purExRtDifTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{item.aggregationDate === '99999999' ? '' : chaiStringUtils.moneyFormat(item.dealBaseExchangeRate).concat(' 원')}</TableCell>
                        <TableCell align='right'>{item.aggregationDate === '99999999' ? '' : chaiStringUtils.moneyFormat(item.settlementBaseExchangeRate).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setExRtApplyAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat(item.setExRtDifTotalAmount + item.purExRtDifTotalAmount).concat(' 원')}</TableCell>
                        <TableCell align='right'>{chaiStringUtils.moneyFormat((((item.setExRtDifTotalAmount + item.purExRtDifTotalAmount)/ item.setKrwTdTotalAmount) * 100 ).toFixed(2)).concat(' %')}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>            
                </Table>
                </div>  
            </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
            <TablePagination
                component="div"
                count={overseasExchRateDiffList.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 100]}
            />
            </CardActions>    
        </Card>  
    );
};

export default OvrsExchRateDiffTable;

import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import apolloClient from '../../lib/apolloClient';
import { onlineBuismHistoryMutation } from '../../lib/apolloClient/mutations';
import OnlineBuismHistoryDetail from './OnlineBuismHistoryDetail';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Link
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 2000
  }
}));

type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = { 
  dataList: OnlineBuismHistoryList
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>> 
}

const OnlineBuismHistoryTable: FC<Props> = ({ dataList, page, rowsPerPage, setPaging }) => {
  const classes = useStyles();

  let list = (dataList === undefined)? []:dataList["list"];
  let totalCount = (dataList === undefined)? 0:dataList["totalCount"];
  const [detailInfo, setDetailInfo] = useState('');
  const [isDetailPop, setDetailPop] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    const paging:Paging = {} as any;
    paging.page = inPage;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paging:Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = Number(event.target.value);
    setPaging(paging);
  };

  const handleDetailClick = (receveBaseDate: String, receveSeq: number) => {
    fetch(receveBaseDate, receveSeq);
    setDetailPop(true);
  }

  const fetch = async (receveBaseDate: String, receveSeq: number) => {
    await apolloClient.mutate({
      variables: { receveSeq: receveSeq, receveBaseDate: receveBaseDate },
      mutation: onlineBuismHistoryMutation.selectOnlineBuismHistoryInfo
    }).then(res => {
      setDetailInfo(res.data.selectOnlineBuismHistoryInfo);
    }).catch(console.error);
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Chai정산일자</TableCell>
                <TableCell align="center">BC재정산매입일자</TableCell>
                <TableCell align="center">BC재정산일자</TableCell>
                <TableCell align="center">거래일자</TableCell>
                <TableCell align="center">매출종류구분</TableCell>
                <TableCell align="center">카드번호</TableCell>
                <TableCell align="center">매입접수번호</TableCell>
                <TableCell align="center">원매출금액</TableCell>
                <TableCell align="center">원매출가맹점수수료율</TableCell>
                <TableCell align="center">원매출가맹점수수료</TableCell>
                <TableCell align="center">재계산가맹점수수료율</TableCell>
                <TableCell align="center">재계산가맹점수수료</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.slice(0, list.length).map((data, index) => (
                <TableRow hover key={index} >
                  <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.receveBaseDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.reSettlementReceveDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.reSettlementDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.salesDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.codeName('salesKindDivisionCode', data.salesKindDivisionCode)}({data.salesKindDivisionCode})</TableCell>
                  <TableCell align="center">{data.cardNoMasked}</TableCell>
                  <TableCell align="center">
                    <Link component="button" variant="body1" onClick={() => { handleDetailClick(data.receveBaseDate, data.receveSeq); }}>
                    {data.purchasedProcessNo}
                    </Link>
                  </TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.originSalesSalesAmount)}</TableCell>
                  <TableCell align="center">{data.originSalesMerchantFeeRate}</TableCell>
                  <TableCell align="center">{chaiStringUtils.moneyFormat(data.originSalesMerchantFee)}</TableCell>
                  <TableCell align="center">{data.reCalcuMerchantFeeRate}</TableCell>
                  <TableCell align="center">{chaiStringUtils.moneyFormat(data.reCalcuMerchantFee)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <OnlineBuismHistoryDetail isDetailPop={isDetailPop} setDetailPop={setDetailPop} detailInfo={detailInfo}/>
    </Card>
  );
};

export default OnlineBuismHistoryTable;

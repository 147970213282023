import React, { FC } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/styles';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = { 
  className?: string,
  detailInfo: CancelEdiPayHistoryDetailInfo 
}

const ViewInfo: FC<Props> = ({ className, detailInfo }) => {
  const classes = useStyles();

  if(detailInfo === undefined) {
    return (
      <Card className={clsx(classes.root, className)} ></Card>
    );
  }

  return (
    <Card className={clsx(classes.root, className)} >
        <Table>
          <TableBody>
            <StyledTableRow >
              <StyledTableCell>정상입금금액</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.moneyFormat(detailInfo.depositAmount)}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Divider />
    </Card>
  );
};

export default ViewInfo;



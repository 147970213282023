import gql from 'graphql-tag';

export const selectCardBinNo = gql`
    mutation selectCardBinNo($binNo : String,$brandDivisionCode : String){
        selectCardBinNo(binNo : $binNo, brandDivisionCode : $brandDivisionCode){
            binNo
            cardProductCode
            enrollmentSeq
            brandDivisionCode
            startCardMediaSeq
            endCardMediaSeq
            nowCardMediaSeq
            useYn
            endDate
            firstOperateAdminId
            firstOperateDetailDate
            firstOperateTradeCode
            lastOperateAdminId
            lastOperateDetailDate
            lastOperateTradeCode
            CardBinNoHistory{
                changeSeq
                changeDate
                changeTime
                binNo
                cardProductCode
                enrollmentSeq
                brandDivisionCode
                startCardMediaSeq
                endCardMediaSeq
                nowCardMediaSeq
                useYn
                endDate
                firstOperateAdminId
                firstOperateDetailDate
                firstOperateTradeCode
            }
        }
    }
`;

export const selectCardBinNoHistory = gql`
    mutation selectCardBinNoHistory($binNo: String, $startCardMediaSeq: String, $endCardMediaSeq: String, $nowCardMediaSeq: String){
        selectCardBinNoHistory(binNo: $binNo, startCardMediaSeq: $startCardMediaSeq, endCardMediaSeq: $endCardMediaSeq, nowCardMediaSeq: $nowCardMediaSeq){
            changeSeq
            changeDate
            changeTime
            binNo
            cardProductCode
            enrollmentSeq
            brandDivisionCode
            startCardMediaSeq
            endCardMediaSeq
            nowCardMediaSeq
            useYn
            endDate
            firstOperateAdminId
            firstOperateDetailDate
            firstOperateTradeCode
        }
    }
`;

export const insertCardBinNo = gql`
    mutation insertCardBinNo($input : CardBinCardBinNoInput){
        insertCardBinNo(input : $input){
            binNo
            cardProductCode
            brandDivisionCode
            startCardMediaSeq
            endCardMediaSeq
            nowCardMediaSeq
            useYn
            endDate
            enrollmentSeq
            
        }
    }
`;

export const deleteCardBinNo = gql`
mutation deleteCardBinNo($input : CardBinCardBinNoInput){
    deleteCardBinNo(input : $input){
        binNo
        cardProductCode
        brandDivisionCode
        startCardMediaSeq
        endCardMediaSeq
        nowCardMediaSeq
        useYn
        enrollmentSeq
        endDate
    }
}    

`;

export const updateCardBinNo = gql`
mutation updateCardBinNo($input : CardBinCardBinNoInput){
    updateCardBinNo(input : $input){
        binNo
        cardProductCode
        brandDivisionCode
        startCardMediaSeq
        endCardMediaSeq
        nowCardMediaSeq
        useYn
        enrollmentSeq
        endDate
    }
}    
`;

import gql from 'graphql-tag';

export const selectOnlineBuismHistoryList = gql`
  mutation selectOnlineBuismHistoryList(
    $startReceveBaseDate: String
    $endReceveBaseDate: String
    $purchasedProcessNo: String
    $page: Float
    $rowsPerPage: Float
  ) {
    selectOnlineBuismHistoryList(
      startReceveBaseDate: $startReceveBaseDate
      endReceveBaseDate: $endReceveBaseDate
      purchasedProcessNo: $purchasedProcessNo
      page: $page
      rowsPerPage: $rowsPerPage
    ) {
      totalCount
      list {
        receveSeq
        receveBaseDate
        reSettlementReceveDate
        reSettlementDate
        originSalesReceveDate
        purchasedProcessNo
        cardNo
        cardNoMasked
        cardSeq
        branchNo
        salesKindDivisionCode
        cardApprovalNo
        salesDate
        receveMerchantNo
        merchantName
        accountDivisionCode
        accountDepartmentIdentifier
        merchantBusinessCode
        checkSalesDivisionCode
        midLowerBizNo
        lowerBuismSeq
        lowerBizNo
        buismSmMidDivisionCode
        originSalesSalesAmount
        lowerBuismSalesAmount
        originSalesMerchantFeeRate
        originSalesMerchantFee
        originSalesIssuerFeeRate
        originSalesIssuerFee
        individualMerchantFee
        individualMerchantIssuerFee
        reCalcuMerchantFeeRate
        reCalcuMerchantFee
        reCalcuIssuerFeeRate
        reCalcuIssuerFee
        issuerFeeDiffAmount
        dataProcessDivisionCode
      }
    }
  }
`;

export const selectOnlineBuismHistoryInfo = gql`
  mutation selectOnlineBuismHistoryInfo($receveSeq: Float, $receveBaseDate: String) {
    selectOnlineBuismHistoryInfo(receveSeq: $receveSeq, receveBaseDate: $receveBaseDate) {
      receveSeq
      receveBaseDate
      reSettlementReceveDate
      reSettlementDate
      originSettlementReceveDate
      originSalesReceveDate
      purchasedProcessNo
      cardNo
      cardNoMasked
      cardSeq
      branchNo
      salesKindDivisionCode
      cardApprovalNo
      salesDate
      receveMerchantNo
      merchantName
      accountDivisionCode
      accountDepartmentIdentifier
      merchantBusinessCode
      checkSalesDivisionCode
      midLowerBizNo
      lowerBuismSeq
      lowerBizNo
      buismSmMidDivisionCode
      originSalesSalesAmount
      lowerBuismSalesAmount
      originSalesMerchantFeeRate
      originSalesMerchantFee
      originSalesIssuerFeeRate
      originSalesIssuerFee
      individualMerchantFee
      individualMerchantIssuerFee
      reCalcuMerchantFeeRate
      reCalcuMerchantFee
      reCalcuIssuerFeeRate
      reCalcuIssuerFee
      issuerFeeDiffAmount
      dataProcessDivisionCode
    }
  }
`;

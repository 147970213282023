export { default as AddCard } from './AddCard';
export { default as NotFound } from './NotFound';
export { default as CardDetail } from './CardDetail';
export { default as CardList } from './CardList';
export { default as Customer } from './Customer';
export { default as CardMember } from './CardMember';
export { default as VipCardInfo } from './VipCardInfo';
export { default as AprvAgrg } from './AprvAgrg';
export { default as MerchantList } from './MerchantList';
export { default as GroupMerchantList } from './GroupMerchantList';
export { default as Login } from './Login';
export { default as LoginResult } from './LoginResult';
export { default as Logout } from './Logout';
export { default as CardProduct } from './CardProduct';
export { default as BatchInfoList } from './BatchInfoList';
export { default as CodeMgmt } from './CodeMgmt';
export { default as AccidentMgmt } from './AccidentMgmt';
export { default as PurchasedHistory } from './PurchasedHistory';
export { default as PurchasedAggrHistory } from './PurchasedAggrHistory';
export { default as OnlineBuismHistory } from './OnlineBuismHistory';
export { default as CancelEdiPayHistory } from './CancelEdiPayHistory';
export { default as ProductManagement } from './ProductManagement';
export { default as ApprovalList } from './ApprovalList';
export { default as ApprovalAggr } from './ApprovalAggr';
export { default as AccountProcList } from './AccountProcList';
export { default as ApprovalHistoryLogList } from './ApprovalHistoryLogList';
export { default as PurchasedTypeHistory } from './PurchasedTypeHistory';
export { default as CardDeliveryInfo } from './CardDeliveryInfo';
export { default as CardBin } from './CardBin';

export { default as ProxyFeeCodeManagement } from './ProxyFeeCodeManagement';
export { default as CardReturnManage } from './CardReturnManage';
export { default as SettlementAggrList } from './SettlementAggrList';
export { default as ApprovalHistoryLogAggr } from './ApprovalHistoryLogAggr';
export { default as ForeignSndrvLogList } from './ForeignSndrvLogList';

export { default as NonInoutInfo } from './NonInoutInfo';
export { default as SettlementList } from './SettlementList';
export { default as AccountProcAggr } from './AccountProcAggr';

export { default as CardOverseaFeeBaseMgmt } from './CardOverseaFeeBaseMgmt';

export { default as MerchantGroupMgmt } from './GroupMerchantMgmt';
export { default as MerchantMasterMgmt } from './MasterMerchantMgmt';

export { default as SettlementAuto } from './SettlementAuto';
export { default as SettlementProxyFee } from './SettlementProxyFee';

export { default as HolidayInformation } from './HolidayInformation';
export { default as SalesDetails } from './SalesDetails';
export { default as OverseasCsList } from './OverseasCsList';
export { default as ExchangeRate } from './ExchangeRate';
export { default as OverseasCsTrmMgmt } from './OverseasCsTrmMgmt';

export { default as OverseasPurchasedAggrHistory } from './OverseasPurchasedAggrHistory';
export { default as OverseasPurchasedAggrHistoryAdjustProcess } from './OverseasPurchasedAggrHistoryAdjustProcess';

export { default as OverseasApprList } from './OverseasApprList';
export { default as OverseasApprHistoryLogList } from './OverseasApprHistoryLogList';
export { default as OverseasCsRefund } from './OverseasCsRefund';

export { default as OverseasExchRateDiffHistory } from './OverseasExchRateDiffHistory';
export { default as OverseasPurchasedHistory } from './OverseasPurchasedHistory';
export { default as OverseasSettlementAggrList } from './OverseasSettlementAggrList';
export { default as OverseasCalculate } from './OverseasCalculate';
export { default as OverseasUseFeeHistory } from './OverseasUseFeeHistory';

export { PlccMerchantMapping } from './PlccMerchantMapping/PlccMerchantMapping';
export { HanaMerchantList } from './HanaMerchant';
export { HanaApprovalList } from './HanaApprovalList';
export { HanaPurchasedList } from './HanaPurchasedList';
export { HanaAggrHistoryList } from './HanaPurchasedAggrHistory';

/////// 검색단 공통 파라미터 정의 ////////
window.$searchParamCardNo = ''; // 카드번호
window.$searchParamUserId = ''; // 고객번호
window.$searchParamHpNo = ''; // 휴대폰번호
window.$searchParamGubun = ''; // 조회구분(1.휴대폰번호,2.카드번호,3.고객번호)

// 대행수수료 정산내역 조회 / 대행수수료 자동대사 조회 공통 파라미터
window.$feeSettlementYm = ''; // 정산년월
window.$proxyFeeCodeNo = ''; // 위임수수료코드
window.$domesticCode = ''; // 국내외구분코드

import React, { FC,useState } from 'react'
import { useToast } from '../../contexts/Toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { cardMemberMutation } from '../../lib/apolloClient/mutations';

type Props = { 
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCardNo: string;
  setCards: React.Dispatch<React.SetStateAction<CardInfo[]>>,
}

const CardMemberDialog: FC<Props> = ({  open, setOpen, updateCardNo, setCards }) => {
 
  const { showToast } = useToast();
  const [updateCardStatus, setUpdateCardStatus] = useState('0');

  
  const onCancel = () => {
    setOpen(false);
  };

    //input name setting
    const selectChange = (event: React.ChangeEvent<HTMLInputElement>) => {    

      setUpdateCardStatus(event.target.value);
    };

    const handleConfirm = async () => {
      console.log(updateCardNo);
      try {
        
        await apolloClient.mutate({
          variables: { input:{
            cardNo: updateCardNo,
            cardStatusCode: updateCardStatus
          }
          },
            mutation: cardMemberMutation.updateCardStatusForAdmin
        }).then(res => {     
        console.log(res.data);     
        onCancel();
        setCards([]);   
        }).catch(e => {
          console.log(e);
          onCancel();
          showToast(e.message, 'error');
        });
      } catch (e) {
        console.log(e);
        onCancel();
        showToast(e.message, 'error');
      }
    }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"강제 상태값 변경"}</DialogTitle>
      <DialogContent>
      <TextField
                select
                fullWidth
                label="변경할 상태값"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="statusCode"
                id={"updateStatusCode"}         
                value={updateCardStatus}       
                onChange={selectChange}
              >
                <option value="0">배송중</option>
                <option value="1">정상</option>
                <option value="2">해지</option>
                <option value="3">폐기</option>
              </TextField>
        <DialogContentText id="alert-dialog-description">
          {"카드 상태값이 잘못 변경될시 카드프로세스에 큰 영향을 주게됩니다."}<br></br>
          {"정말 변경처리 하시겠습니까?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          취소
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CardMemberDialog; 
import React, { FC, useState, ChangeEvent } from 'react';
import {
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Button
} from '@material-ui/core';

import { approve } from '../../lib/cardApi'
import { useToast } from '../../contexts/Toast';

type Props = {
  merchants: Merchant[],
  cardInfo: CardInfo,
  refreshApprovals: (cardNumber: string) => Promise<void>,
  approvalOpen: boolean,
  setApprovalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const Approval: FC<Props> = ({ merchants, cardInfo, refreshApprovals, approvalOpen, setApprovalOpen }) => {
  const { showToast } = useToast();

  const handleApprovalClose = () => {
    setApprovalOpen(false);
  };

  const handleApprovalConfirm = async () => {
    try {
      await approve(values, cardInfo.cardNo);
      await refreshApprovals(cardInfo.cardNo);
      showToast('승인 성공', 'success')
    } catch (e) {
      showToast(e.message, 'error')
      console.log(e)
    } finally {
      setApprovalOpen(false);
    };
  };

  const [values, setValues] = useState({
    merchantId: merchants.length > 0 ? merchants[0].id : '',
    taxAmount: '',
    netAmount: '',
    serviceAmount: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('name:'+e.target.name);
    console.log('value:'+e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Dialog
      open={approvalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='sm'
    >
      <DialogTitle id="alert-dialog-title">승인 신청</DialogTitle>
      <DialogContent>
        <form autoComplete="off" noValidate>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="카드번호"
                  margin="dense"
                  variant="outlined"
                  disabled
                  value={cardInfo.cardNo}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="유효기간"
                  margin="dense"
                  variant="outlined"
                  disabled
                  value={cardInfo.valdTermYm}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="가맹점"
                  margin="dense"
                  name="merchantId"
                  onChange={handleChange}
                  required
                  variant="outlined"
                  select
                  SelectProps={{ native: true }}
                >
                  {merchants.map(merchant => (
                    <option
                      key={merchant.id}
                      value={merchant.id}
                    >
                      {merchant.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="승인금액"
                  margin="dense"
                  name="netAmount"
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="부가세"
                  margin="dense"
                  name="taxAmount"
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="봉사료"
                  margin="dense"
                  name="serviceAmount"
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </Grid>
            </Grid>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleApprovalClose} color="primary">
          취소
        </Button>
        <Button onClick={handleApprovalConfirm} color="primary" autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Approval;

import gql from 'graphql-tag';

export const selectPurchasedTypeHistoryList = gql`
  mutation selectPurchasedTypeHistoryList($input: PurchasedTypeHistorySearch) {
    selectPurchasedTypeHistoryList(input: $input) {
      totalCount
      salesKindDivisionCode
      cardNo
      cardNoMasked
      cardApprovalNo
      approvalDate
      cardApprovalAmount
      merchantNo
      merchantName
    }
  }
`;

export const selectPurchasedTypeHistoryAggregation = gql`
  mutation selectPurchasedTypeHistoryAggregation($input: PurchasedTypeHistorySearch) {
    selectPurchasedTypeHistoryAggregation(input: $input) {
      normalApprovalAmountSum
      normalApprovalCount
      normalApprovalCancelAmountSum
      normalApprovalCancelCount
    }
  }
`;

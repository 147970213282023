import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  thStyle: {
    backgroundColor: '#fafafa'
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px'
  }
}));


type Props = {
    className : string,
    detailInfo : ovrsPurchasedHistorySumInfo
}

const OverseasPurchasedHistoryDetail : FC<Props> = ({className, detailInfo}) => {

    console.log('@ detailInfo : ' , detailInfo);

    const classes = useStyles();

    if(detailInfo === undefined) {
        return (
          <Card className={clsx(classes.root, className)} ></Card>
        );
      }

    return (
        <Card className={clsx(classes.root, className)}  >
        <Divider />
        <Table size="small" >
            <TableBody>
                <TableRow>
                    <TableCell align="center" className={classes.thStyle}>총 정산미화합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.totalAmount) } 달러</TableCell>
                    <TableCell align="center" className={classes.thStyle}>총 건수</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {detailInfo.totalCount} 건</TableCell>
                    <TableCell align="center" className={classes.thStyle}>신판미화 총 합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.cdslUsdTotalAmount)} 달러</TableCell>
                    <TableCell align="center" className={classes.thStyle}>신판미화수수료 총 합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.cdslFeeUsdTotalAmount)} 달러</TableCell>  
                </TableRow>
                <TableRow>
                    <TableCell align="center" className={classes.thStyle}>현금미화 총 합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.casUsdTotalAmount)} 달러</TableCell>
                    <TableCell align="center" className={classes.thStyle}>현금미화수수료 총 합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.cashFeeUsdTotalAmount)} 달러</TableCell>
                    <TableCell align="center" className={classes.thStyle}>ISA보정미화 총 합계금액</TableCell>
                    <TableCell align="right" className={classes.tdStyle}> {chaiStringUtils.moneyFormat(detailInfo.brandFeeCorrectUsdAmount)} 달러</TableCell>
              </TableRow>
            </TableBody>          
          </Table>
    </Card>

    );

};

export default OverseasPurchasedHistoryDetail;

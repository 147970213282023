import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  }
  
}));
**/

type SearchInfo = {
  tradeDate:string,           // 거래일자
  operateTradeCode:string,    // 전문번호
  startTradeTime:string,      // 거래시작시각
  endTradeTime:string,        // 거래종료시각
  tradeUniqNo:string          // 전문거래고유번호
}

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<any>>,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}
const CardReturnManageToolbar: FC<Props> = ({ onSearch , searchForm, setSearchForm}) => {
  // const classes = useStyles();

  // 변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  // 목록 조회
  const onSearchClick = async () => {
    onSearch(searchForm);
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="거래일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="tradeDate"
              value={searchForm.tradeDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="조작거래코드"
              placeholder="조작거래코드"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="operateTradeCode"
              value={searchForm.operateTradeCode}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="거래시작시각"
              placeholder="거래시작시각"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="startTradeTime"
              value={searchForm.startTradeTime}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="거래종료시각"
              placeholder="거래종료시각"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="endTradeTime"
              value={searchForm.endTradeTime}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="전문거래고유번호"
              placeholder="전문거래고유번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="tradeUniqNo"
              value={searchForm.tradeUniqNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>

          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CardReturnManageToolbar;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { holidayInformationMutation } from '../../lib/apolloClient/mutations';

import HolidayInformationToolbar from './HolidayInformationToolbar';
import HolidayInformationToolbarCud from './HolidayInformationToolbarCud';
import HolidayInformationTable from './HolidayInformationTable';
import { MenuTitleToolbar } from '../../components';
import ExcelUploadPop from './ExcelUploadPop';
import ExportIntoExcel from './ExportIntoExcel';
import { ExportHolidayInformationListTableInfoExcel } from './ExportHolidayInformationListTableInfoExcel';
// import excelUploadPath from '../../assets/Holiday_ExcelUpload.xlsx';

import {
    Button,
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    registerButton: {
      margin: theme.spacing(1), 
      marginTop: theme.spacing(3),
      float: "right"
    },
    hyperLinkButton: {
      textDecoration: 'none',
      color: 'white'
    }
}));

type SearchInfo = {
    baseDate:string,        // 기준일자
    baseDateEnd:string,     // 기준종료일자
    holidayCode:string      // 휴일구분
}

type Props ={};

const HolidayInformation: FC<Props> = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [updateClick, setUpdateClick] = useState(false);

    const [holidayInformation, setHolidayInformation] = useState({
        baseDate:	'',
        baseDateEnd:	'',
        dayOfWeekCode:	'',
        holidayCode: '',
        businessDay: '0',
        nextBusinessDay: '',
        dayOfWeekCodeName: '',
        holidayCodeName: ''
    });

    // 검색조건
    const [searchForm, setSearchForm] = useState({
        baseDate: format(subMonths(new Date(),1), 'yyyy-MM-dd'),     // 기준일자
        baseDateEnd:format(new Date(), 'yyyy-MM-dd'),                // 기준종료일자
        holidayCode: ''                                              // 휴일구분
    });

    useEffect(() => {
        onSearch(searchForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 휴일관리 목록 조회
    const onSearch = async (searchForm: SearchInfo) => {
        console.log('휴일관리 목록 조회 시작 : ', searchForm);

    // 초기화
    setData([]);

    setHolidayInformation({
        baseDate:	'',
        baseDateEnd:	'',
        dayOfWeekCode:	'',
        holidayCode: '0',
        businessDay: '',
        nextBusinessDay: '',
        dayOfWeekCodeName: '',
        holidayCodeName: ''
    });

        await apolloClient.mutate({
            variables:{
                baseDate: searchForm.baseDate,          // 기준일자
                baseDateEnd: searchForm.baseDateEnd,     // 기준종료일자
                holidayCode: searchForm.holidayCode     // 휴일구분
            },
            mutation: holidayInformationMutation.selectHolidayInformation

        }).then(res=> {
            console.log('휴일관리 목록 조회 결과 : ', res.data.selectHolidayInformationList);
            setData(res.data.selectHolidayInformationList);
        }).catch(console.error);

    }

    // 엑셀업로드 팝업
    const [uploadPop, setUploadPop] =useState(false);
    const handleUploadPop = () => {
        // 팝업 오픈
        setUploadPop(true);
    }

    // 엑셀양식다운로드
    const [clicked, setClicked] = useState(false); // 데이터 excel 출력을 위한 버튼 클릭 확인 state

    // 휴일정보관리목록 엑셀 다운로드
    const [listExcelClicked, setListExcelClicked] = useState(false);

    return (
        <div className={classes.root}>
            <MenuTitleToolbar title="휴일정보관리"/>
            <HolidayInformationToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />

            <div className={classes.content}>
                <HolidayInformationToolbarCud HolidayInformationList={data} holidayInformation={holidayInformation} 
                                              setHolidayInformation={setHolidayInformation} updateClick={updateClick} 
                                              setUpdateClick={setUpdateClick} searchForm={searchForm} fetch={onSearch}
                                              />
            </div>

            <ExcelUploadPop uploadPop={uploadPop} setUploadPop={setUploadPop} searchForm={searchForm} fetch={onSearch}/>

            <ExportHolidayInformationListTableInfoExcel 
                className={classes.registerButton}
                csvData={data}
                clicked={listExcelClicked}
                setClicked={setListExcelClicked}
            />

            <Button className={classes.registerButton} 
                color="primary" 
                variant="contained"
                onClick={handleUploadPop}
            >            
                엑셀업로드POP
            </Button>

            {/* <Button className={classes.registerButton} 
                color="primary" 
                variant="contained"
            >            
            <Link to={excelUploadPath} target="_blank" download className={classes.hyperLinkButton}>Excel 양식</Link>
            </Button> */}

            <ExportIntoExcel
                className={classes.registerButton}
                clicked={clicked}
                setClicked={setClicked}
            />
            
            <br/>
            <br/>
            <br/>

            <div className={classes.content}>
                <HolidayInformationTable title="휴일정보관리 목록" HolidayInformationList={data} 
                                         setHolidayInformation={setHolidayInformation} 
                                         setUpdateClick={setUpdateClick} />
            </div>

        </div>
    )
}

export default HolidayInformation;
  
import 'date-fns';
import React, { FC, useState } from 'react';
import { Grid, TextField, Card, CardContent, Button } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

type Paging = {
  page: number;
  rowsPerPage: number;
};

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<OnlineBuismHistorySearch>>;
  page: number;
  rowsPerPage: number;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
};

const OnlineBuismHistoryListToolbar: FC<Props> = ({ onSearch, page, rowsPerPage, setPaging }) => {
  const [pStartReceveBaseDate, setStartReceveBaseDate] = useState(format(subMonths(new Date(), 1), 'yyyy-MM-dd'));
  const [pEndReceveBaseDate, setEndReceveBaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [pPurchasedProcessNo, setPurchasedProcessNo] = useState('');
  const { showToast } = useToast();
  const { hideDialog } = useDialog();

  const handleStartReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartReceveBaseDate(e.target.value);
  };

  const handleEndReceveBaseDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndReceveBaseDate(e.target.value);
  };

  const handlePurchasedProcessNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurchasedProcessNo(event.target.value);
  };

  const onSearchClick = async () => {
    const onlineBuismHistorySearch: OnlineBuismHistorySearch = {} as any;

    if (pStartReceveBaseDate == null) {
      showToast('Chai정산시작일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    if (pEndReceveBaseDate == null) {
      showToast('Chai정산종료일자를 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    const paging: Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onlineBuismHistorySearch.startReceveBaseDate = chaiStringUtils.dateFormat(pStartReceveBaseDate, 'yyyyMMdd');
    onlineBuismHistorySearch.endReceveBaseDate = chaiStringUtils.dateFormat(pEndReceveBaseDate, 'yyyyMMdd');
    onlineBuismHistorySearch.purchasedProcessNo = pPurchasedProcessNo;
    onlineBuismHistorySearch.isExcelDown = false;
    onSearch(onlineBuismHistorySearch);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pStartReceveBaseDate"
              value={pStartReceveBaseDate}
              onChange={handleStartReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              required
              fullWidth
              type="date"
              label="Chai정산종료일자"
              margin="dense"
              InputProps={{
                inputProps: {
                  min: pStartReceveBaseDate ? format(new Date(pStartReceveBaseDate), 'yyyy-MM-dd') : undefined,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pEndReceveBaseDate"
              value={pEndReceveBaseDate}
              onChange={handleEndReceveBaseDateChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="매입접수번호"
              placeholder="매입접수번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="pPurchasedProcessNo"
              value={pPurchasedProcessNo}
              onChange={handlePurchasedProcessNoChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button color="primary" variant="contained" onClick={onSearchClick} style={{ marginTop: '9px' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OnlineBuismHistoryListToolbar;

import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  TableHead,
  CardActions,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as util from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(2)
  }
}));

type Props = {
  detailListPop: boolean,
  setDetailListPopOpen: React.Dispatch<React.SetStateAction<boolean>>,
  loading: boolean
  data: any[]
}
const AccountProcAggrDetailListPop: FC<Props> = ({ detailListPop, setDetailListPopOpen, loading, data }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleClose = () => {
    setDetailListPopOpen(false);
    setPage(0);
    setRowsPerPage(10);
  };

  const map = new Map<String, String>();
  map.set('05', '승인');
  map.set('15', '승인취소');
  map.set('06', '현금서비스정상');
  map.set('16', '현금서비스취소');
  map.set('08', '할부');
  map.set('18', '할부취소');
  map.set('95', '선거절');

  const displayProcessStatus = (item:ApprovalInfo) => {
    
    if (item.approvalProcessStatusCode === '1')  return '정상';
    else if (item.approvalProcessStatusCode === '2') return '오류';
  };

  return (
    <Dialog
      open={detailListPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle><MenuTitleToolbar title="승인집계 상세목록" /></DialogTitle>
        <DialogContent>
          {
            loading
            ? util.Loading()
            : 
              <Card>
                <CardContent className={classes.content}>
                  <PerfectScrollbar>
                    <div className={classes.inner}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">No.</TableCell>
                            <TableCell align="center">카드승인번호</TableCell>
                            <TableCell align="center">머니승인번호</TableCell>
                            <TableCell align="center">승인일시(원)</TableCell>
                            <TableCell align="center">거래일자</TableCell>
                            <TableCell align="center">고객번호</TableCell>
                            <TableCell align="center">성명</TableCell>
                            <TableCell align="center">카드번호</TableCell>
                            <TableCell align="center">가맹점명</TableCell>
                            <TableCell align="center">매출종류</TableCell>
                            <TableCell align="center">승인금액</TableCell>
                            <TableCell align="center">처리구분</TableCell>
                            <TableCell align="center">매입여부</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                            <TableRow
                              hover
                              key={index}
                            >
                              <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                              <TableCell align="center">{item.cardApprovalNo}</TableCell>
                              <TableCell align="center">{item.paymentId}</TableCell>
                              <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate) + ' ' + chaiStringUtils.dateTimeFormat(item.approvalTime)}</TableCell>
                              <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.tradeDate)}</TableCell>
                              <TableCell align="center">{item._member.userId}</TableCell>
                              <TableCell align="center">{item._member.userKorName}</TableCell>
                              <TableCell align="center">{item.cardNoMasked}</TableCell>
                              <TableCell align="center">{item.merchantName}</TableCell>
                              <TableCell align="center">{map.get(item.salesKindDivisionCode)}</TableCell>
                              <TableCell align="right">{item.cardApprovalAmount && item.cardApprovalAmount.toLocaleString()}</TableCell>
                    <TableCell align="center">{displayProcessStatus(item)}</TableCell>
                              <TableCell align="center">{item.slipPurchaseYn === 'Y' ? '매입' : ''}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={data.length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </CardActions>
              </Card>
          }
        </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountProcAggrDetailListPop;
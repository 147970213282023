import React, { FC, useState, useEffect, ChangeEvent} from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalListMutation } from '../../lib/apolloClient/mutations';
import { approvalHistoryLogMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { chaiPaymentCancel, chaiPaymentId, chaiPaymentStatus } from '../../lib/chaiAPI'

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee',
    minWidth: '370px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold'
  }
}));

type Props = {
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  refundReqInfo: ApprovalRefundInfo,
  approvalRefundPopOpen: boolean,
  setApprovalRefundPopOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ApprovalRefundPop: FC<Props> = ({ refundCount, setRefundCount, refundReqInfo, approvalRefundPopOpen, setApprovalRefundPopOpen }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  // const [loading, setLoading] = useState(false);
  const [approvalRefundInfo, setApprovalRefundInfo] = useState(refundReqInfo);
  const [paymentStatus, setPaymentStatus] = useState('notfound');
  const [isApprovalCnclExist, setIsApprovalCnclExist] = useState(false);
  const [isError, setIsError] = useState(false);
  const { showDialog, hideDialog } = useDialog();

  useEffect(() => {
    const fetch = async () => {

      setApprovalRefundInfo({
        ...refundReqInfo
      });

      let idempotencyKey = '';
      let paymentId = '';
      let originApproval:any;
      // 승인의 경우
      if (refundReqInfo.salesKindDivisionCode === '05') {
        if (refundReqInfo.inoutProcessSeq) {
          idempotencyKey = refundReqInfo.inoutProcessSeq;
        }
        else {
          idempotencyKey = 'AP'+ refundReqInfo.originTradeDate + refundReqInfo.originTradeApprovalSeq.toString().padStart(16, '0');
        }
        paymentId = refundReqInfo.paymentId; 
        const refundReq = {} as ChaiMoneyRefundReq;
        refundReq.idempotencyKey = idempotencyKey;       
      }
      else if (refundReqInfo.salesKindDivisionCode === '15') {
        // setLoading(true);

        // 승인취소의 경우는 원승인 정보를 가져온다.
        await apolloClient.mutate({
          variables: {
            cardNo:refundReqInfo.cardNo,
            approvalDate : refundReqInfo.approvalDate,
            cardApprovalNo: refundReqInfo.cardApprovalNo},
          mutation: approvalListMutation.selectApprovalOrigin
        }).then(res => {  
          console.log('selectApprovalOrigin=',res.data.selectApprovalOrigin);
          originApproval = res.data.selectApprovalOrigin;
          if (originApproval.inoutProcessSeq) {
            idempotencyKey = originApproval.inoutProcessSeq;
          }
          else {
            idempotencyKey = 'AP'+ originApproval.tradeDate + originApproval.tradeApprovalSeq.toString().padStart(16, '0');
          }
          paymentId = originApproval.paymentId;
        }).catch(e => {
          setIsError(true);
          console.log('error', e);
          showToast(e.message, 'error');
        });

        // 승인취소 정상내역이 존재하는지 확인
        await apolloClient.mutate({
          variables: {
            cardNo:refundReqInfo.cardNo,
            approvalDate : refundReqInfo.approvalDate,
            cardApprovalNo: refundReqInfo.cardApprovalNo},
          mutation: approvalListMutation.selectApprovalCncl
        }).then(res => {  
          console.log('selectApprovalCncl=',res.data.selectApprovalCncl);
          const approvalCncl = res.data.selectApprovalCncl;
          if (approvalCncl) {
            setIsApprovalCnclExist(true);
          }
        }).catch(e => {
          setIsError(true);
          console.log('error', e);
          showToast(e.message, 'error');
        });      
        // setLoading(false);
      }
      const refundReq = {} as ChaiMoneyRefundReq;
      refundReq.idempotencyKey = idempotencyKey;

      // paymentId가 세팅되지 않은 경우 idempotencyKey를 이용하여 결제상태를 조회
      try {
        // 차이머니 결제ID조회
        if (refundReqInfo.originTradeDate && !paymentId) {
          const chaiRes = await chaiPaymentId(refundReq);
          console.log('chaiRes>>>>', chaiRes);
          paymentId = chaiRes.paymentId;
          setPaymentStatus(chaiRes.status);
        }
      }
      catch (e) {
        console.log(e);
        setIsError(true);
        hideDialog();
        showToast(e.message, 'error');
      }
      // paymentId가 세팅되어온 경우의 결제상태를 구한다.
      refundReq.paymentId = paymentId;
      if (refundReqInfo.paymentId) {
        try {
          if (refundReqInfo.originTradeDate) {
            const chaiRes = await chaiPaymentStatus(refundReq);
            console.log('chaiRes', chaiRes);
            setPaymentStatus(chaiRes.status);
          }          
        }
        catch (e) {
          console.log(e);
          setIsError(true);
          hideDialog();
          showToast(e.message, 'error');
        }
      }
      if (refundReqInfo.salesKindDivisionCode === '05') {    
        setApprovalRefundInfo({
          ...refundReqInfo,
          paymentId : paymentId
        }); 
      }
      else if (refundReqInfo.salesKindDivisionCode === '15' && originApproval) {  
        setApprovalRefundInfo({
          ...refundReqInfo,
          originTradeDate: originApproval.tradeDate,
          originTradeApprovalSeq : originApproval.tradeApprovalSeq,
          originApprovalProcessStatusCode : originApproval.approvalProcessStatusCode,
          inoutProcessSeq : originApproval.inoutProcessSeq,
          paymentId : paymentId
        });        
      }              
    }  
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundReqInfo]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setApprovalRefundInfo({
      ...approvalRefundInfo,
      [e.target.name]: e.target.value
    });
  };

  const handleApprovalRefundPopClose = () => {
    setApprovalRefundPopOpen(false);
  };

  const handleConfirmRefund = async () => {

    try {
      // paymentId가 세팅되어있지않으면 환불처리하지 못한다
      if (!approvalRefundInfo.paymentId) {
        hideDialog();
        showToast('paymentId를 입력하세요!!!!', 'error');
        return;
      }
      if (approvalRefundInfo.paymentId) {
        const refundReq = {} as ChaiMoneyRefundReq;
        refundReq.paymentId = approvalRefundInfo.paymentId;
        if (approvalRefundInfo.inoutProcessSeq) {
          refundReq.idempotencyKey = approvalRefundInfo.inoutProcessSeq;
        }
        else {
          refundReq.idempotencyKey = 'AP'+ approvalRefundInfo.originTradeDate + approvalRefundInfo.originTradeApprovalSeq.toString().padStart(16, '0');
        }
        // 승인은 부분취소가 없으므로 동일한 값으로 원승인금액 이용
        refundReq.checkoutAmount =  approvalRefundInfo.cardApprovalAmount;
        refundReq.cancelAmount = approvalRefundInfo.cardApprovalAmount;
        refundReq.merchantNo = approvalRefundInfo.merchantNo; // 가맹점정보 원승인정보 이용
        refundReq.merchantName = approvalRefundInfo.merchantName;

        let paymentId = refundReq.paymentId;
        // 차이머니 결제상태가 canceled가 아닐때 취소API 호출
        if (paymentStatus !== 'canceled') {        
          // 차이머니 환불 호출
          const rslt = await chaiPaymentCancel(refundReq);
          paymentId = rslt.paymentId;
        }

        await apolloClient.mutate({
          variables: {input : {
            tradeDate: approvalRefundInfo.tradeDate, 
            tradeApprovalSeq: approvalRefundInfo.tradeApprovalSeq, 
            salesKindDivisionCode : approvalRefundInfo.salesKindDivisionCode,
            orgnlTradeDate : approvalRefundInfo.originTradeDate,
            orgnlTradeApprovalSeq : approvalRefundInfo.originTradeApprovalSeq,
            cardNo: approvalRefundInfo.cardNo,
            paymentAcno: approvalRefundInfo.paymentAcno,
            cardApprovalAmount: approvalRefundInfo.cardApprovalAmount,
            merchantName:approvalRefundInfo.merchantName,
            refundProcessSeq: refundReq.idempotencyKey, 
            refundPaymentId: paymentId,
            accountProcessSeq: approvalRefundInfo.accountProcessSeq} },
          mutation: approvalHistoryLogMutation.updateApprovalCompare
        }).then(res => {     
          console.log(res.data);
          setRefundCount(refundCount+1); 
          hideDialog();
          showDialog(
            '승인상태확인',
            '입금환불처리되었습니다.\n\n',
            () => {
              // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
              hideDialog();
            }
          )   
      
        }).catch(e => {
          console.log(e);
          hideDialog();
          showToast(e.message, 'error');
        });
      }
    } catch (e) {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    }
    setApprovalRefundPopOpen(false);
}


const handleConfirm = async () => {
  try {
    await apolloClient.mutate({
      variables: {
        tradeDate: approvalRefundInfo.tradeDate, 
        tradeApprovalSeq: approvalRefundInfo.tradeApprovalSeq},
        mutation: approvalListMutation.updateApprovalStatus
    }).then(res => {     
    console.log(res.data);
    setRefundCount(refundCount+1); 
    hideDialog();
    showDialog(
      '승인상태확인',
      '처리되었습니다.\n\n',
      () => {
      // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
        hideDialog();
        }
    )         
    }).catch(e => {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    });
  } catch (e) {
    console.log(e);
    hideDialog();
    showToast(e.message, 'error');
  }
  setApprovalRefundPopOpen(false);
}

const handleRefundConfirm = async () => {
  try {
    await apolloClient.mutate({
      variables: {
        tradeDate: approvalRefundInfo.tradeDate, 
        tradeApprovalSeq: approvalRefundInfo.tradeApprovalSeq},
        mutation: approvalListMutation.updateApprovalRefundInfo
    }).then(res => {     
    console.log(res.data);
    setRefundCount(refundCount+1); 
    hideDialog();
    showDialog(
      '승인상태확인',
      '처리되었습니다.\n\n',
      () => {
      // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
        hideDialog();
        }
    )         
    }).catch(e => {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    });
  } catch (e) {
    console.log(e);
    hideDialog();
    showToast(e.message, 'error');
  }
  setApprovalRefundPopOpen(false);
}

const displayRefundButton = () => {

  // 현재는 승인에 대해서만 망취소를 해야하는데 못한 경우만 있어서 조건을 승인이면서 오류로만 조회
  if (approvalRefundInfo.salesKindDivisionCode === '05' && approvalRefundInfo.approvalProcessStatusCode === '2') {
    return (<Button
      disabled={!isUpdStatusDisabled()}
      color="primary"
      variant="contained"
      onClick={() => showDialog(
        '승인상태확인',
        '환불처리코드변경처리 하시겠습니까?',
        handleRefundConfirm
      )}
      >
      환불처리코드변경 
    </Button>);
  }
}

const displayButton = () => {

  if (approvalRefundInfo.approvalProcessStatusCode === '') {
    return (<Button
      disabled={!isUpdStatusDisabled()}
      color="primary"
      variant="contained"
      onClick={() => showDialog(
        '승인상태확인',
        '상태변경처리 하시겠습니까?',
        handleConfirm
      )}
      >
      상태변경 
    </Button>);
  }
}

const isUpdStatusDisabled = () => {

  let isUpdStatus = true;

  if (isError) {
    isUpdStatus = false;
  }
  else {
    if (approvalRefundInfo.salesKindDivisionCode === '05') {
      if (paymentStatus === 'confirmed') {
        isUpdStatus = false;
      }
    }
    else if (approvalRefundInfo.salesKindDivisionCode === '15') {
      // 원승인이 정상이고 승인취소 정상내역이 존재하지 않으면 환불해야하니까 상태버튼 disabled
      if (approvalRefundInfo.originApprovalProcessStatusCode === '1' && !isApprovalCnclExist) {
          isUpdStatus = false;
      }
    }
  }
  return isUpdStatus;
};

const isDisabled = () => {

  let isRefund = true;

  if (isError) {
    isRefund = false;
  }
  else {
    if (approvalRefundInfo.salesKindDivisionCode === '05') {
      if (paymentStatus !== 'confirmed') {
        isRefund = false;
      }
    }
    else if (approvalRefundInfo.salesKindDivisionCode === '15') {
      // 원승인이 정상이 아니면 환불하지 않는다.
      if (approvalRefundInfo.originApprovalProcessStatusCode !== '1') {
        isRefund = false;
      }
      if (!approvalRefundInfo.paymentId) {
        isRefund = false;
      }
      // 승인취소 정상내역이 존재하면 환불처리하지 않고 상태만 변경
      if (isApprovalCnclExist) {
        isRefund = false;
      }
    }
  }
  return isRefund;
};

const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
      event.preventDefault();
  }
};


  return (
    <Dialog
      open={approvalRefundPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title"><MenuTitleToolbar title="상태확인" /></DialogTitle>
      <Divider />
      <DialogContent>
        <div>
        <form
        autoComplete="off"
        noValidate
        > 
            <Table size="small">
              <TableBody>
              <TableRow>
                  <TableCell align="center" className={classes.thStyle}>승인금액</TableCell>
                  <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(approvalRefundInfo.cardApprovalAmount)}</TableCell>
                </TableRow>                
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>입출금처리일련번호</TableCell>
                  <TableCell align="right" className={classes.tdStyle}>
                    <TextField
                      fullWidth
                      label="입출금처리일련번호"
                      margin="dense"
                      name="inoutProcessSeq"
                      onChange={handleChange}
                      value={approvalRefundInfo.inoutProcessSeq? approvalRefundInfo.inoutProcessSeq : 'AP'+ approvalRefundInfo.originTradeDate + approvalRefundInfo.originTradeApprovalSeq.toString().padStart(16, '0')}
                      variant="outlined"
                      disabled 
                    />  
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>머니승인번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    <TextField
                      fullWidth
                      label="머니승인번호"
                      margin="dense"
                      name="paymentId"
                      onChange={handleChange}
                      value={approvalRefundInfo.paymentId? approvalRefundInfo.paymentId : ''}
                      variant="outlined"
                      onKeyPress={onKeyEnter}
                    />                    
                  </TableCell>
                </TableRow>                
              </TableBody>
            </Table>
        </form>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        {displayRefundButton()}
        {displayButton()}
        <Button
            disabled={!isDisabled()}
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              '승인상태확인',
              '입금처리 하시겠습니까?',
              handleConfirmRefund
            )}
          >
            입금처리 
        </Button>
        <Button onClick={handleApprovalRefundPopClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalRefundPop;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { MenuTitleToolbar } from '../../components';
import SalesDetailsToolbar from './SalesDetailsToolbar';
import SalesDetailsTable from './SalesDetailsTable';
import SumSalesDetailsTable from './SumSalesDetailsTable';
import { salesDetailsMutation } from '../../lib/apolloClient/mutations';

import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import apolloClient from '../../lib/apolloClient';
import * as utils from '../../common/utils';
import { useToast } from '../../contexts/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  registerButton: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    float: 'right',
  },
  hyperLinkButton: {
    textDecoration: 'none',
    color: 'white',
  },
}));

type SearchInfo = {
  criteriaYn: string; // 기준년월
};

type Props = {
  listInfo: SalesDetailsListReturnType;
};

const SalesDetails: FC<Props> = ({ listInfo }) => {
  const classes = useStyles();
  const [data, setData] = useState(listInfo);
  const [loading, setLoading] = useState(false);

  // 엑셀다운로드
  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);
  const { showToast } = useToast();

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    criteriaYn: format(new Date(), 'yyyy-MM'), // 기준년월
  });

  // 합계
  const [sumInfo, setSumInfo] = useState({
    sumsalescount: 0,
    sumsalestotalamount: 0,
    summerchantfee: 0,
    sumissuerfee: 0,
  });

  // const [aggrLoading, setAggrLoading] = useState(false);

  // 매출내역 조회
  const onSearch = async (searchForm: SearchInfo) => {
    console.log('매출내역 목록 조회 시작: ', searchForm);

    if (!isExcelDown) {
      setLoading(true);
    }

    await apolloClient
      .mutate({
        variables: {
          criteriaYn: searchForm.criteriaYn, // 기준년월
          page: paging.page,
          rowsPerPage: paging.rowsPerPage,
          isExcelDown: isExcelDown,
        },
        mutation: salesDetailsMutation.selectSalesDetails,
      })
      .then(res => {
        console.log('매출내역 목록 조회 결과 : ', res.data.selectSalesDetailsList);
        if (isExcelDown) {
          setExcelDown(false);
          if (Number(res.data.selectSalesDetailsList.totalCount) > 100000) {
            showToast('10만건이상 엑셀다운로드 할 수 없습니다.', 'error');
          } else {
            excelDown(res.data.selectSalesDetailsList.list);
          }
        } else {
          setData(res.data.selectSalesDetailsList);
          fetch();
        }
      })
      .catch(console.error);

    setLoading(false);
  };

  // 합계내역 조회
  const fetch = async () => {
    // 이미 로딩 중이면 리턴
    // if(aggrLoading) {
    //   return;
    // }

    // setAggrLoading(true);

    await apolloClient
      .mutate({
        variables: {
          criteriaYn: searchForm.criteriaYn,
        },
        mutation: salesDetailsMutation.selectSalesDetailsSum,
      })
      .then(res => {
        console.log('합계 조회 결과: ', res.data.selectSalesDetailsSumList);
        setSumInfo(res.data.selectSalesDetailsSumList);
      })
      .catch(console.error);

    // setAggrLoading(false);
  };

  // 페이징
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  });

  useEffect(() => {
    onSearch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, isFlag]);

  // 엑셀다운로드 버튼 클릭
  const excelClick = () => {
    if (Number(data.totalCount) > 100000) {
      showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
      return;
    }

    setFlag(!isFlag);
    setExcelDown(true);
  };

  const excelDown = (csvData: SalesDetails[]) => {
    let rowData = [];
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
        No: `${i + 1}`,
        입금일자: `${csvData[i].depositDate}`,
        매출건수: `${csvData[i].salesCount}`,
        매출금액: `${csvData[i].salesTotalAmount}`,
        가맹점수수료: `${csvData[i].merchantFee}`,
        발급사보전수수료: `${csvData[i].issuerFee}`,
      });
    }

    utils.exportToCSV('매출내역', rowData);
  };

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="매출내역" />
      <SalesDetailsToolbar onSearch={onSearch} searchForm={searchForm} setSearchForm={setSearchForm} />

      <div className={classes.content}>
        <SumSalesDetailsTable sumInfo={sumInfo} />
      </div>

      <div className={classes.registerButton}>
        {isExcelDown ? <span style={{ marginRight: 20 }}>Loading...</span> : ''}
        <Button
          variant="contained"
          color="primary"
          onClick={event => {
            excelClick();
          }}
        >
          Excel
        </Button>
      </div>

      <br />
      <br />
      <br />

      <div className={classes.content}>
        {loading ? (
          utils.Loading()
        ) : (
          <SalesDetailsTable title="매출내역 목록" salesDetailsList={data} paging={paging} setPaging={setPaging} />
        )}
      </div>
    </div>
  );
};

export default SalesDetails;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import store from 'store';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL
  ? `https://${process.env.REACT_APP_ADMIN_URL}`
  : `https://local.chai.finance:5000`;

const CARD_ADMIN_URL = `https://${document.location.host}`;

// TODO: 환경 변수로 변경?
const PUBKEY = '6dddd584361d413aada22bb1e8ce8913';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  // const [data, setData] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [error] = useState<Error>();

  const isAuthed = !!store.get('admin');

  if (isAuthed) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const login = () => {
    const loginUri = `${ADMIN_URL}/login?publicKey=${PUBKEY}&redirect=${CARD_ADMIN_URL}/login/done`;
    window.location.replace(loginUri);
  };

  const logindev = (adminType: string) => {
    const loginUri = `/login/done?local_login=true&admin_type=${adminType}`;
    window.location.replace(loginUri);
  };

  const isdev = process.env.NODE_ENV === 'development';

  return (
    <div className={classes.root}>
      <div>세션이 없습니다.</div>
      <br />
      <Button color="primary" variant="outlined" size="medium" onClick={login}>
        로그인
      </Button>
      {isdev && (
        <>
          &nbsp;
          <Button color="secondary" variant="outlined" size="medium" onClick={() => logindev('admin')}>
            로컬 로그인 (Admin)
          </Button>
          &nbsp;
          <Button color="secondary" variant="outlined" size="medium" onClick={() => logindev('cs')}>
            로컬 로그인 (CS)
          </Button>
          &nbsp;
          <Button color="secondary" variant="outlined" size="medium" onClick={() => logindev('card-admin')}>
            로컬 로그인 (Admin+Card)
          </Button>
        </>
      )}
    </div>
  );
};

export default Login;

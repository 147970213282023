import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Moment from 'moment';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type IssueReturnHistoryListExcel = {
  카드번호: string,
  순번: string,
  회원번호: string,
  고객번호: string,
  발송상태: string,
  기준일자: string,
  발송유형: string,
  발송업체: string,
  발소애수: string,
  발송영업점: string,
  발송번호: string,
  발급일자: string,
  재발송일자: string,
  반송사유: string,
  등기번호: string,
  카드수령인: string,
  관계: string
}

type Props = {
  className?:string,
  csvData:  any[],                                            // excel로 출력하기 위한 data
  clicked: boolean,                                           // excel 버튼이 클릭되었는지 확인을 위한 props
  setClicked: React.Dispatch<React.SetStateAction<boolean>>,  // excel 출력 이후 clicked를 초기화 하기 위한 props
}

export const ExportIssueReturnHistoryListIntoExcel: FC<Props> = ({
  className,
  csvData,
  clicked,
  setClicked
}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';     // 출력될 excel 파일의 타입 지정
  const fileExtension = '.xlsx';   
  
  const excelData = () => {
    let rowData = [] as IssueReturnHistoryListExcel[]
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
          '카드번호': `${chaiStringUtils.nvlDefault(csvData[i].cardNoMasked)}`,
          '순번': `${chaiStringUtils.nvlDefault(csvData[i].changeSeq)}`,
          '회원번호': `${chaiStringUtils.nvlDefault(csvData[i].cardMemberNo)}`,
          '고객번호': `${chaiStringUtils.nvlDefault(csvData[i].userId)}`,
          '발송상태': `${chaiStringUtils.nvlDefault(chaiStringUtils.codeName('issueStatusCode',csvData[i].issueStatusCode))}`,
          '기준일자': `${chaiStringUtils.nvlDefault(csvData[i].issueBaseDate)}`,
          '발송유형': `${chaiStringUtils.nvlDefault(chaiStringUtils.codeName('sndTpCode',csvData[i].sendTypeDivisionCode))}`,
          '발송업체': `${chaiStringUtils.nvlDefault(chaiStringUtils.codeName('sndClssCode',csvData[i].issueCorporationDivisionCode))}`,
          '발소애수': `${chaiStringUtils.nvlDefault(csvData[i].issueCount)}`,
          '발송영업점': `${chaiStringUtils.nvlDefault(csvData[i].issueBranchNo)}`,
          '발송번호': `${chaiStringUtils.nvlDefault(csvData[i].issueNo)}`,
          '발급일자': `${chaiStringUtils.nvlDefault(csvData[i].issueDate)}`,
          '재발송일자': `${chaiStringUtils.nvlDefault(csvData[i].reissueDate)}`,
          '반송사유': `${chaiStringUtils.nvlDefault(chaiStringUtils.codeName('backRsonNoCode',csvData[i].issueReturnCode))}`,
          '등기번호': `${chaiStringUtils.nvlDefault(csvData[i].registeredNo)}`,
          '카드수령인': `${chaiStringUtils.nvlDefault(csvData[i].receveName)}`,
          '관계': `${chaiStringUtils.nvlDefault(chaiStringUtils.codeName('rcpRelCode',csvData[i].receveRelationCode))}`
        });
    }
    return rowData;
  }

  // excel export 라이브러리를 통한 excel 출력 함수
  const exportToCSV = (csvData: IssueReturnHistoryListExcel[]) => {           // 데이터를 파라미터로 받음
    const fileName = '반송카드관리_발송반송내역_' + Moment().format('YYYYMMDDHHmmss');  // 생성될 excel 파일의 파일명 지정
    const ws = XLSX.utils.json_to_sheet(csvData);                             // 데이터를 excel xlsx 데이터로 변경
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };              // excel 파일의 sheet  지정
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });  // array 형식으로 excel에 데이터 작성
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);                         // 생성된 excel 파일을 파일로써 저장
  } 

  return (
    <Button 
        variant="contained" 
        color="primary"
        className={className}
        onClick={
            (event) => {
                setClicked(!clicked);                                         // clicked 초기화
                exportToCSV(excelData());                                     // 파일 생성 함수 실행
            }}
        >
        목록 Excel
    </Button>
  )
}
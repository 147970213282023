import React, { useState, useEffect }from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import OverseasApprHistoryLogListTable from './OverseasApprHistoryLogListTable';
import OveresasApprHistoryLogListToolbar from './OverseasApprHistoryLogListToolbar';
import OverseasApprHistoryLogAggrTable from './OverseasApprHistoryLogAggrTable';
import { MenuTitleToolbar } from '../../components';
import { subDays, format } from 'date-fns';
import apolloClient from '../../lib/apolloClient';
import { overseasApprHistoryLogMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OverseasApprHistoryLogList = () => {
  const classes = useStyles();

  const [overseasApprHistoyLogSearch, setOverseasApprHistoyLogSearch] = useState({
    salesKindDivisionCode: '',
    approvalDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    dataDivisionCode: ''
  });

  const default_page = 0;
  const default_rowsPerPage= 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });

  const [refundCount, setRefundCount] = useState(0);

  const [data, setData] = useState([]);
  const [aggrData, setAggrData] = useState({
    cardNormalApprovalAmountSum: 0,
    cardNormalApprovalAmountCount: 0,
    cardAbnormalApprovalAmountSum: 0,
    cardAbnormalApprovalCount: 0,
    cardNormalApprovalCancelAmountSum: 0,
    cardNormalApprovalCancelCount: 0,
    cardAbnormalApprovalCancelAmountSum: 0,
    cardAbnormalApprovalCancelCount: 0,
    cardChaimoneyNormalApprovalAmountSum: 0,
    cardChaimoneyNormalApprovalCount: 0,
    cardChaimoneyAbnormalApprovalAmountSum: 0,
    cardChaimoneyAbnormalApprovalCount: 0,
    cardChaimoneyNormalApprovalCancelAmountSum: 0,
    cardChaimoneyNormalApprovalCancelCount: 0,
    cardChaimoneyAbnormalApprovalCancelAmountSum: 0,
    cardChaimoneyAbnormalApprovalCancelCount: 0,
    cardBcNormalApprovalAmountSum: 0,
    cardBcNormalApprovalCount: 0,
    cardBcAbnormalApprovalAmountSum: 0,
    cardBcAbnormalApprovalCount: 0,
    cardBcNormalApprovalCancelAmountSum: 0,
    cardBcNormalApprovalCancelCount: 0,
    cardBcAbnormalApprovalCancelAmountSum: 0,
    cardBcAbnormalApprovalCancelCount: 0,
    atmNormalApprovalAmountSum: 0,
    atmNormalApprovalCount: 0,
    atmAbnormalApprovalAmountSum: 0,
    atmAbnormalApprovalCount: 0,
    atmNormalApprovalCancelAmountSum: 0,
    atmNormalApprovalCancelCount: 0,
    atmAbnormalApprovalCancelAmountSum: 0,
    atmAbnormalApprovalCancelCount: 0,
    atmChaimoneyNormalApprovalAmountSum: 0,
    atmChaimoneyNormalApprovalCount: 0,
    atmChaimoneyAbnormalApprovalAmountSum: 0,
    atmChaimoneyAbnormalApprovalCount: 0,
    atmChaimoneyNormalApprovalCancelAmountSum: 0,
    atmChaimoneyNormalApprovalCancelCount: 0,
    atmChaimoneyAbnormalApprovalCancelAmountSum: 0,
    atmChaimoneyAbnormalApprovalCancelCount: 0,
    atmBcNormalApprovalAmountSum: 0,
    atmBcNormalApprovalCount: 0,
    atmBcAbnormalApprovalAmountSum: 0,
    atmBcAbnormalApprovalCount: 0,
    atmBcNormalApprovalCancelAmountSum: 0,
    atmBcNormalApprovalCancelCount: 0,
    atmBcAbnormalApprovalCancelAmountSum: 0,
    atmBcAbnormalApprovalCancelCount: 0
  });

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paging, refundCount, overseasApprHistoyLogSearch]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refundCount, overseasApprHistoyLogSearch]);

  const onSearch = async () => {
    //리스트 조회
    await apolloClient.mutate({
      variables: {
        salesKindDivisionCode: overseasApprHistoyLogSearch.salesKindDivisionCode,
        approvalDate: overseasApprHistoyLogSearch.approvalDate,
        dataDivisionCode: overseasApprHistoyLogSearch.dataDivisionCode,
        page: paging.page,
        rowsPerPage: paging.rowsPerPage
      },
      mutation: overseasApprHistoryLogMutation.selectOverseasApprHistoryLogList
    }).then(res => {
      console.log("*** 승인대사조회 결과 ***")
      console.log(res.data.selectOverseasApprHistoryLogList);
      setData(res.data.selectOverseasApprHistoryLogList);
    }).catch(e => {
      console.log("*** 승인대사조회 에러 ***")
      console.log(e);
    });
  }

  const fetch = async () => {
    //집계 조회
    await apolloClient.mutate({
      variables: {
        salesKindDivisionCode: overseasApprHistoyLogSearch.salesKindDivisionCode,
        approvalDate: overseasApprHistoyLogSearch.approvalDate,
        dataDivisionCode: overseasApprHistoyLogSearch.dataDivisionCode
      },
      mutation: overseasApprHistoryLogMutation.selectOverseasApprHistoryLogAggr
    }).then(res => {
      console.log("*** 승인대사집계조회 결과 ***")
      console.log(res.data.selectOverseasApprHistoryLogAggr);
      setAggrData(res.data.selectOverseasApprHistoryLogAggr);
    }).catch(e => {
      console.log("*** 승인대사집계조회 에러 ***")
      console.log(e);
    });
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="해외승인대사내역"/>
      <OveresasApprHistoryLogListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setOverseasApprHistoyLogSearch}/>
      <div className={classes.content}> 
        <OverseasApprHistoryLogAggrTable overseasApprHistoryLogAggrInfo={aggrData}/>
      </div>
      <div className={classes.content}>
        <OverseasApprHistoryLogListTable paging={paging} setPaging={setPaging} refundCount={refundCount} setRefundCount={setRefundCount} overseasApprHistoryLogList={data}/>
      </div>
    </div>
  );
};

export default OverseasApprHistoryLogList;
import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Moment from 'moment';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type CardListExcel = {
  No: string,
  카드번호: string,
  회원명: string,
  상품명: string,
  유효기한: string,
  등록일자: string,
  최종카드여부: string,
  상태: string,
  사고등재여부: string
}

type Props = {
  className?:string,
  csvData:  any[],                                            // excel로 출력하기 위한 data
  clicked: boolean,                                           // excel 버튼이 클릭되었는지 확인을 위한 props
  setClicked: React.Dispatch<React.SetStateAction<boolean>>,  // excel 출력 이후 clicked를 초기화 하기 위한 props
}

export const ExportCardListIntoExcel: FC<Props> = ({
  className,
  csvData,
  clicked,
  setClicked
}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';     // 출력될 excel 파일의 타입 지정
  const fileExtension = '.xlsx';   
  
  const excelData = () => {
    let rowData = [] as CardListExcel[]
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
          'No': `${i + 1}`, 
          '카드번호': `${csvData[i].cardnomasked}`,
          '회원명': `${csvData[i].cardownrname}`,
          '상품명': `${csvData[i].cardproductcodename}`,
          '유효기한': `${csvData[i].validatetermym}`,
          '등록일자': `${chaiStringUtils.dateFormat(csvData[i].issuedate, null)}`,
          '최종카드여부': `${csvData[i].lastcardyn}`,
          '상태': `${chaiStringUtils.codeName('cardStatusCode',csvData[i].cardstatuscode)}`,
          '사고등재여부': `${csvData[i].accidentyn}`  
        });
    }
    return rowData;
  }

  // excel export 라이브러리를 통한 excel 출력 함수
  const exportToCSV = (csvData: CardListExcel[]) => {                         // 데이터를 파라미터로 받음
    const fileName = '반송카드관리_카드목록_' + Moment().format('YYYYMMDDHHmmss');  // 생성될 excel 파일의 파일명 지정
    const ws = XLSX.utils.json_to_sheet(csvData);                             // 데이터를 excel xlsx 데이터로 변경
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };              // excel 파일의 sheet  지정
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });  // array 형식으로 excel에 데이터 작성
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);                         // 생성된 excel 파일을 파일로써 저장
  } 

  return (
    <Button 
        variant="contained" 
        color="primary"
        className={className}
        onClick={
            (event) => {
                setClicked(!clicked);                                         // clicked 초기화
                exportToCSV(excelData());                                     // 파일 생성 함수 실행
            }}
        >
        목록 Excel
    </Button>
  )
}
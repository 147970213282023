import React from 'react';
import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { useAuth } from './Auth';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  AccidentMgmt as AccidentMgmtView,
  AccountProcAggr as AccountProcAggrView,
  AccountProcList as AccountProcListView,
  AddCard as AddCardView,
  ApprovalAggr as ApprovalAggrView,
  ApprovalHistoryLogAggr as ApprovalHistoryLogAggrView,
  ApprovalHistoryLogList as ApprovalHistoryLogListView,
  ApprovalList as ApprovalListView,
  AprvAgrg as AprvAgrgListView,
  BatchInfoList as BatchInfoListView,
  CancelEdiPayHistory as CancelEdiPayHistoryView,
  CardBin as CardBinView,
  CardDeliveryInfo as CardDeliveryInfoView,
  CardDetail as CardDetailView,
  CardList as CardListView,
  CardMember as CardMemberView,
  CardOverseaFeeBaseMgmt as CardOverseaFeeBaseMgmtView,
  CardProduct as CardProductView,
  CardReturnManage as CardReturnManageView,
  CodeMgmt as CodeMgmtView,
  Customer as CustomerView,
  ExchangeRate as ExchangeRateView,
  ForeignSndrvLogList as ForeignSndrvLogListView,
  GroupMerchantList as GroupMerchantListView,
  HanaApprovalList,
  HanaAggrHistoryList,
  HanaPurchasedList,
  HanaMerchantList as HanaMerchantListView,
  HolidayInformation as HolidayInformationView,
  Login as LoginView,
  LoginResult as LoginResultView,
  Logout as LogoutView,
  MerchantGroupMgmt as MerchantGroupMgmtView,
  MerchantList as MerchantListView,
  MerchantMasterMgmt as MerchantMasterMgmtView,
  NonInoutInfo as NonInoutInfoView,
  NotFound as NotFoundView,
  OnlineBuismHistory as OnlineBuismHistoryView,
  OverseasApprHistoryLogList as OverseasApprHistoryLogListView,
  OverseasApprList as OverseasApprListView,
  OverseasCalculate as OverseasCalculateView,
  OverseasCsList as OverseasCsListView,
  OverseasCsRefund as OverseasCsRefundView,
  OverseasCsTrmMgmt as OverseasCsTrmMgmtView,
  OverseasExchRateDiffHistory as OverseasExchRateDiffHistoryView,
  OverseasPurchasedAggrHistory as OverseasPurchasedAggrHistoryView,
  OverseasPurchasedAggrHistoryAdjustProcess as OverseasPurchasedAggrHistoryAdjustProcessView,
  OverseasPurchasedHistory as OverseasPurchasedHistoryView,
  OverseasSettlementAggrList as OverseasSettlementAggrListView,
  OverseasUseFeeHistory as OverseasUseFeeHistoryView,
  PlccMerchantMapping as PlccMerchantMappingView,
  ProductManagement as ProductManagementView,
  ProxyFeeCodeManagement as ProxyFeeCodeManagementView,
  PurchasedAggrHistory as PurchasedAggrHistoryView,
  PurchasedHistory as PurchasedHistoryView,
  PurchasedTypeHistory as PurchasedTypeHistoryView,
  SalesDetails as SalesDetailsView,
  SettlementAggrList as SettlementAggrListView,
  SettlementAuto as SettlementAutoView,
  SettlementList as SettlementListView,
  SettlementProxyFee as SettlementProxyFeeView,
  VipCardInfo as VipCardInfoView,
} from './views';
import { PlccMerchantMappingHistory } from './views/PlccMerchantMapping/PlccMerchantMappingHistory';

const Routes: React.FC<RouteComponentProps> = props => {
  const auth = useAuth();

  // 비 로그인 상태 접근 시 라우트
  if (!auth?.user) {
    return (
      <Switch>
        <Redirect exact from="/" to="/login" />
        <RouteWithLayout component={LoginResultView} layout={MinimalLayout} path="/login/done" />
        <RouteWithLayout component={LoginView} layout={MinimalLayout} path="/login" />
        <RouteWithLayout component={LogoutView} layout={MinimalLayout} path="/logout" />
        <Redirect to="/login" />
      </Switch>
    );
  }

  const rootPath = auth.user?.permission?.permissions.includes('admin') ? '/settlement' : '/cardMember';

  return (
    <Switch>
      <Redirect exact from="/" to={rootPath} />
      <RouteWithLayout component={CodeMgmtView} layout={MainLayout} needAdminPermission path="/CodeMgmt" />
      <RouteWithLayout component={CardProductView} layout={MinimalLayout} path="/cardProduct" />
      <RouteWithLayout component={CardListView} layout={MainLayout} needAdminPermission path="/cardlist" />
      <RouteWithLayout component={CustomerView} layout={MainLayout} path="/customer/:customerId" />
      <RouteWithLayout component={CustomerView} layout={MainLayout} path="/customer" />
      <RouteWithLayout component={CardMemberView} layout={MainLayout} path="/cardMember/:customerId" />
      <RouteWithLayout component={CardMemberView} layout={MainLayout} path="/cardMember" />

      {/** 발급집계내역조회 */}
      {/*<RouteWithLayout
        component={CardMemberView}
        layout={MainLayout}
        path="/cardMember/:customerId"
      />*/}
      <RouteWithLayout component={AprvAgrgListView} layout={MainLayout} needAdminPermission path="/aprvAgrg" />
      <RouteWithLayout component={VipCardInfoView} layout={MainLayout} needAdminPermission path="/vipCardInfo" />

      <RouteWithLayout component={AddCardView} layout={MainLayout} path="/addcard" />
      <RouteWithLayout component={CardDetailView} layout={MainLayout} path="/carddetail/:cardNumber" />
      <RouteWithLayout component={CardDetailView} layout={MainLayout} path="/carddetail" />
      <RouteWithLayout component={MerchantListView} layout={MainLayout} path="/merchantlist" />
      <RouteWithLayout component={GroupMerchantListView} layout={MainLayout} path="/groupMerchantList" />
      <RouteWithLayout component={ApprovalListView} layout={MainLayout} path="/approvalList/:searchDt/:paymentId" />
      <RouteWithLayout component={ApprovalListView} layout={MainLayout} path="/approvalList" />
      <RouteWithLayout component={ApprovalAggrView} layout={MainLayout} needAdminPermission path="/approvalAggr" />
      <RouteWithLayout
        component={AccountProcListView}
        layout={MainLayout}
        needAdminPermission
        path="/accountProcList"
      />
      <RouteWithLayout component={LoginResultView} layout={MinimalLayout} path="/login/done" />
      <RouteWithLayout component={LoginView} layout={MinimalLayout} path="/login" />
      <RouteWithLayout component={LogoutView} layout={MinimalLayout} path="/logout" />
      <RouteWithLayout component={BatchInfoListView} layout={MainLayout} needAdminPermission path="/batchInfo" />
      <RouteWithLayout component={NotFoundView} layout={MinimalLayout} path="/not-found" />
      <RouteWithLayout component={PurchasedHistoryView} layout={MainLayout} path="/purchasedHistory" />
      <RouteWithLayout
        component={PurchasedAggrHistoryView}
        layout={MainLayout}
        needAdminPermission
        path="/purchasedAggrHistory"
      />
      <RouteWithLayout
        component={OnlineBuismHistoryView}
        layout={MainLayout}
        needAdminPermission
        path="/onlineBuismHistory"
      />
      <RouteWithLayout component={CancelEdiPayHistoryView} layout={MainLayout} path="/cancelEdiPayHistory" />
      <RouteWithLayout component={AccidentMgmtView} layout={MainLayout} path="/accidentMgmt" />
      <RouteWithLayout
        component={ProductManagementView}
        layout={MainLayout}
        needAdminPermission
        path="/ProductManagement"
      />
      <RouteWithLayout
        component={ApprovalHistoryLogListView}
        layout={MainLayout}
        needAdminPermission
        path="/approvalHistoryLog"
      />
      <RouteWithLayout component={PurchasedTypeHistoryView} layout={MainLayout} path="/purchasedTypeHistory" />
      <RouteWithLayout component={CardDeliveryInfoView} layout={MainLayout} path="/cardDeliveryInfo" />
      <RouteWithLayout component={CardBinView} layout={MainLayout} needAdminPermission path="/CardBin" />
      <RouteWithLayout
        component={ProxyFeeCodeManagementView}
        layout={MainLayout}
        needAdminPermission
        path="/proxyFeeCodeManagement"
      />
      <RouteWithLayout component={CardReturnManageView} layout={MainLayout} path="/cardReturnManage" />
      <RouteWithLayout
        component={SettlementAggrListView}
        layout={MainLayout}
        needAdminPermission
        path="/settlementAggrList"
      />
      <RouteWithLayout
        component={ApprovalHistoryLogAggrView}
        layout={MainLayout}
        needAdminPermission
        path="/approvalHistoryLogAggr"
      />
      <RouteWithLayout
        component={ForeignSndrvLogListView}
        layout={MainLayout}
        needAdminPermission
        path="/foreignSndrvLogList"
      />
      <RouteWithLayout component={NonInoutInfoView} layout={MainLayout} needAdminPermission path="/nonInoutInfo" />
      <RouteWithLayout component={SettlementListView} layout={MainLayout} needAdminPermission path="/settlement" />
      <RouteWithLayout
        component={AccountProcAggrView}
        layout={MainLayout}
        needAdminPermission
        path="/accountProcAggr"
      />
      <RouteWithLayout
        component={CardOverseaFeeBaseMgmtView}
        layout={MainLayout}
        needAdminPermission
        path="/cardOverseaFeeBaseMgmt"
      />
      <RouteWithLayout
        component={MerchantGroupMgmtView}
        layout={MainLayout}
        needAdminPermission
        path="/MerchantGroupMgmt"
      />
      <RouteWithLayout
        component={MerchantMasterMgmtView}
        layout={MainLayout}
        needAdminPermission
        path="/MerchantMasterMgmt"
      />
      <RouteWithLayout component={SettlementProxyFeeView} layout={MainLayout} path="/SettlementProxyFee" />
      <RouteWithLayout component={SettlementAutoView} layout={MainLayout} needAdminPermission path="/SettlementAuto" />
      <RouteWithLayout
        component={HolidayInformationView}
        layout={MainLayout}
        needAdminPermission
        path="/HolidayInformation"
      />
      <RouteWithLayout component={SalesDetailsView} layout={MainLayout} needAdminPermission path="/SalesDetails" />
      <RouteWithLayout component={OverseasCsListView} layout={MainLayout} path="/overseasCsList" />
      <RouteWithLayout component={ExchangeRateView} layout={MainLayout} needAdminPermission path="/ExchangeRate" />
      <RouteWithLayout component={OverseasCsTrmMgmtView} layout={MainLayout} path="/overseasCsTrmMgmt" />
      <RouteWithLayout
        component={OverseasApprHistoryLogListView}
        layout={MainLayout}
        path="/OverseasApprHistoryLogList"
      />
      <RouteWithLayout component={OverseasApprListView} layout={MainLayout} path="/OverseasApprList" />
      <RouteWithLayout
        component={OverseasCsRefundView}
        layout={MainLayout}
        needAdminPermission
        path="/OverseasCsRefund"
      />
      <RouteWithLayout
        component={OverseasExchRateDiffHistoryView}
        layout={MainLayout}
        needAdminPermission
        path="/OverseasExchRateDiffHistory"
      />
      <RouteWithLayout component={OverseasPurchasedHistoryView} layout={MainLayout} path="/OverseasPurchasedHistory" />
      <RouteWithLayout
        component={OverseasPurchasedAggrHistoryView}
        layout={MainLayout}
        path="/OverseasPurchasedAggrHistory"
      />
      <RouteWithLayout
        component={OverseasPurchasedAggrHistoryAdjustProcessView}
        layout={MainLayout}
        path="/OverseasPurchasedHistoryAdjust"
      />
      <RouteWithLayout
        component={OverseasSettlementAggrListView}
        layout={MainLayout}
        needAdminPermission
        path="/OverseasSettlementAggrList"
      />
      <RouteWithLayout component={OverseasUseFeeHistoryView} layout={MainLayout} path="/OverseasUseFeeHistory" />
      <RouteWithLayout component={OverseasCalculateView} layout={MainLayout} path="/OverseasCalculate" />

      <RouteWithLayout
        component={PlccMerchantMappingHistory}
        needAdminPermission
        layout={MainLayout}
        path="/PLCC/MerchantMappingHistory"
      />
      <RouteWithLayout
        component={PlccMerchantMappingView}
        needAdminPermission
        layout={MainLayout}
        path="/PLCC/MerchantMapping"
      />
      <RouteWithLayout
        component={HanaMerchantListView}
        needAdminPermission
        layout={MainLayout}
        path="/PLCC/MerchantList"
      />
      <RouteWithLayout component={HanaApprovalList} layout={MainLayout} path="/PLCC/ApprovalList" />
      <RouteWithLayout component={HanaPurchasedList} layout={MainLayout} path="/PLCC/PurchasedList" />
      <RouteWithLayout component={HanaAggrHistoryList} layout={MainLayout} path="/PLCC/AggrHistory" />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withRouter(Routes);

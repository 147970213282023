import React, { useState, FC } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  TablePagination,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  makeStyles,
  Theme,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ExportIntoExcel } from './ExportIntoExcel';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  setCommonCodeInfo: React.Dispatch<React.SetStateAction<CommonCodeInfo>>; // 리스트의 row 클릭 시 codeUpdate.tsx의 textField로 값을 전달하기 위한 props
  commonCodeList: CommonCodeInfo[]; // 리스트에 index.tsx에서 받은 공통코드를 출력하기 위한 공통코드 정보 props
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>; // updateClick이 2번째 argument인 useEffect 실행을 위한 props
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>; // 리스트 row 클릭 시 codeUpdate.tsx의 신규, 수정 버튼 활성화 여부 조작을 위한 props
  setIsEmptyForm: React.Dispatch<React.SetStateAction<boolean>>; // 리스트 row 클릭 시 codeUpdate.tsx의 입력 초기화 버튼 활성화 여부 조작을 위한 props
  updateClick: boolean;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
};

const CodeTable: FC<Props> = ({
  commonCodeList,
  setCommonCodeInfo,
  setUpdateClick,
  setIsEmptyForm,
  setIsValidForm,
  updateClick,
  setIsNew
}) => {
  const classes = useStyles(); // className을 통한 style 설정을 위한 상수 선언

  const map = new Map<String, String>();
  map.set('Y', '사용');
  map.set('N', '미사용');

  const [clicked, setClicked] = useState(false); // 데이터 excel 출력을 위한 버튼 클릭 확인 state
  const [rowsPerPage, setRowsPerPage] = useState(5); // pagenation의 페이지당 리스트 출력 수를 조정하기 위한 state
  const [page, setPage] = useState(0); // pagenation의 페이지 위치를 조정하기 위한 state

  // pagenation의 페이지를 변경하는 함수
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page); // pagenation의 pageChange 함수를 통해 페이지 번호를 받아서 page의 값 변경
  };

  // pagenation의 페이지당 리스트 출력 수를 변경하는 함수
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value)); // pagenation의 onChangeRowsPerPage 함수를 통해 페이지 당 리스트 출력 숫자를 받아서 rowsPerPage의 값 변경
  };

  if (
    commonCodeList === null ||
    commonCodeList === undefined ||
    commonCodeList.length === 0
  ) {
    return (
      <Card style={{ backgroundColor: '#F4F6F8' }}>
        <CardContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '0px solid white' }}>
                  조회된 내용이 없습니다.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.content}>
      <CardContent>
        <p
          style={{
            display: 'flex',
            fontFamily: 'sans-serif',
            marginTop: 20,
            marginLeft: 20,
            marginRight: 20
          }}>
          *&nbsp;{commonCodeList.length}&nbsp;건
          <ExportIntoExcel // codeGroupDivisionCode가 3인 코드의 전체 코드의 숫자를 받음
            clicked={clicked} // excel 버튼 클릭 시 clicked를 활성화 하여 exportIntoExcel.tsx로 전송
            setClicked={setClicked}
            csvData={commonCodeList} // excel로 출력할 데이터를 codeList의 데이터로 설정
          />
        </p>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">NO.</TableCell>
                  <TableCell align="center">유형코드</TableCell>
                  <TableCell align="center">코드유형명</TableCell>
                  <TableCell align="center">그룹코드</TableCell>
                  <TableCell align="center">코드그룹명</TableCell>
                  <TableCell align="center">코드키</TableCell>
                  <TableCell align="center">코드명</TableCell>
                  <TableCell align="center">코드값</TableCell>
                  <TableCell align="center">사용여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commonCodeList
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((
                    item,
                    idx // pagenation 설정하여 갯수 만큼을 comonCodeList에서 item으로 받아옴
                  ) => (
                    <TableRow
                      onClick={() => {
                        setCommonCodeInfo(item); // 리스트의 row 클릭 시 codeUpdate.tsx의 textField로 데이터를 전송
                        setUpdateClick(!updateClick); // 리스트 클릭 시 2번째 argument가 updateClick인 useEffect를 실행
                        setIsEmptyForm(false); // 리스트 클릭 시 codeUpdate.tsx의 입력 초기화 버튼 활성화
                        setIsValidForm(false); // 리스트 클릭 시 codeUpdate.tsx의 신규, 수정 버튼 비활성화
                        setIsNew(true);
                      }}
                      hover // 리스트 테이블의 hover 효과
                      key={idx} // 리스트의 key를 codeId로 지정
                    >
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                        {(idx+1)+(page* rowsPerPage)}
                        </Button>
                      </TableCell>
                      {/* 가져온 코드 리스트의 indexNumber를 페이지네이션 번호와 동기화 */}
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.typeCode)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.typeCodeName)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.groupCode)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.groupCodeName)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.codeId)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.codeName)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(item.codeValue)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setCommonCodeInfo(item);
                            setUpdateClick(!updateClick);
                            setIsEmptyForm(false);
                            setIsValidForm(false);
                            setIsNew(true);
                          }}
                          style={{ color: 'navy' }}>
                          {chaiStringUtils.nvlDefault(map.get(item.useYn))}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination // pagenation 조작을 위한 함수 지정
          style={{ marginLeft: 'auto' }}
          component="div"
          count={commonCodeList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </CardActions>
    </Card>
  );
};

export default CodeTable;

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

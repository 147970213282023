import gql from 'graphql-tag';

export const selectPurchasedHistoryList = gql`
  mutation selectPurchasedHistoryList(
    $searchGubun: String
    $searchText: String
    $startReceveBaseDate: String
    $endReceveBaseDate: String
    $salesKindDivisionCode: String
    $purchasedProcessNo: String
    $merchantNo: String
    $accountProcessSeq: Float
    $accountDivisionCode: String
    $page: Float
    $rowsPerPage: Float
    $isExcelDown: Boolean
  ) {
    selectPurchasedHistoryList(
      searchGubun: $searchGubun
      searchText: $searchText
      startReceveBaseDate: $startReceveBaseDate
      endReceveBaseDate: $endReceveBaseDate
      salesKindDivisionCode: $salesKindDivisionCode
      purchasedProcessNo: $purchasedProcessNo
      merchantNo: $merchantNo
      accountProcessSeq: $accountProcessSeq
      accountDivisionCode: $accountDivisionCode
      page: $page
      rowsPerPage: $rowsPerPage
      isExcelDown: $isExcelDown
    ) {
      totalCount
      list {
        receveSeq
        receveBaseDate
        accountDivisionCode
        receveDate
        settlementDate
        depositDate
        billDate
        salesKindDivisionCode
        purchasedProcessNo
        merchantNo
        cardSeq
        cardNo
        cardNoMasked
        cardMemberNo
        userId
        cardApprovalNo
        salesDate
        salesNetAmount
        serviceAmount
        taxAmount
        salesTotalAmount
        installmentMonth
        cardProductCode
        managementBranchCode
        merchantName
        merchantBizNo
        merchantFee
        merchantFeeRate
        issuerFee
        issuerFeeRate
        merchantInstallmentFee
        onlineSalesFee
        merchantUseFee
        merchantUseBcFee
        merchantBusinessCode
        settlementBusinessCode
        settlementFeeRate
        purchasedSettlementFee
        useFee
        checkSalesPrepayAmount
        saveSalesDivisionCode
        installmentRound
        merchantReductionExtFee
        installmentDivisionCode
        installmentNo
        revolvingDivisionCode
        revolvingNo
        approvalServiceDivisionCode
        userServiceDivisionCode
        commonMerchantUseDivisionCode
        checkSalesDivisionCode
        salesApplySalesDivisionCode
        originReceveNo
        originSalesDate
        pgBizNo
        merchantDivisionCode
        checkSalesPrepayDivisionCode
        checkSalesPrepayAccountNo
        extSettlementDivisionCode
        extApprovalDivisionCode
        taxReductionYn
        noInstallmentDivisionCode
        salesDiscountDivisionCode
        salesCancelDivisionCode
        salesDiscountAmount
        salesBcResponseAmount
        salesPartnerResponseAmount
        settlementProcessYn
        pointDeductionUseAmount
        pointDeductionUserUseAmount
        merchantDeductionAuthNo
        supportDivisionCode
        supportAmount
        purchasedDataProcessYn
        supportAmountSettlementYm
        preSettlementDate
        cancelPrepayProcessYn
        purchasedProcessDate
        fileName
        changeTerminalNo
        inoutProcessCode
        inoutProcessSeq
        paymentId
        cardTypeCode
        dataProcessDivisionCode
        accountProcessSeq
        onlineMerchantType
      }
    }
  }
`;

export const selectPurchasedHistoryInfo = gql`
  mutation selectPurchasedHistoryInfo($receveSeq: Float, $receveBaseDate: String) {
    selectPurchasedHistoryInfo(receveSeq: $receveSeq, receveBaseDate: $receveBaseDate) {
      receveSeq
      receveBaseDate
      accountDivisionCode
      receveDate
      settlementDate
      depositDate
      billDate
      salesKindDivisionCode
      purchasedProcessNo
      merchantNo
      cardSeq
      cardNo
      cardNoMasked
      cardMemberNo
      userId
      cardApprovalNo
      salesDate
      salesNetAmount
      serviceAmount
      taxAmount
      salesTotalAmount
      installmentMonth
      cardProductCode
      managementBranchCode
      merchantName
      merchantBizNo
      merchantFee
      merchantFeeRate
      issuerFee
      issuerFeeRate
      merchantInstallmentFee
      onlineSalesFee
      merchantUseFee
      merchantUseBcFee
      merchantBusinessCode
      settlementBusinessCode
      settlementFeeRate
      purchasedSettlementFee
      useFee
      checkSalesPrepayAmount
      saveSalesDivisionCode
      installmentRound
      merchantReductionExtFee
      installmentDivisionCode
      installmentNo
      revolvingDivisionCode
      revolvingNo
      approvalServiceDivisionCode
      userServiceDivisionCode
      commonMerchantUseDivisionCode
      checkSalesDivisionCode
      salesApplySalesDivisionCode
      originReceveNo
      originSalesDate
      pgBizNo
      merchantDivisionCode
      checkSalesPrepayDivisionCode
      checkSalesPrepayAccountNo
      extSettlementDivisionCode
      extApprovalDivisionCode
      taxReductionYn
      noInstallmentDivisionCode
      salesDiscountDivisionCode
      salesCancelDivisionCode
      salesDiscountAmount
      salesBcResponseAmount
      salesPartnerResponseAmount
      settlementProcessYn
      pointDeductionUseAmount
      pointDeductionUserUseAmount
      merchantDeductionAuthNo
      supportDivisionCode
      supportAmount
      purchasedDataProcessYn
      supportAmountSettlementYm
      preSettlementDate
      cancelPrepayProcessYn
      purchasedProcessDate
      fileName
      changeTerminalNo
      inoutProcessCode
      inoutProcessSeq
      paymentId
      cardTypeCode
      dataProcessDivisionCode
      accountProcessSeq
    }
  }
`;

import React, { FC } from 'react';
import {
    Theme,
    makeStyles,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    TablePagination,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { getOverseasFeeCodeName } from '../../helpers/index.js';

type Props = {
    title: string,
    selectOverseasFeeBaseList: overseasFeeBaseList,
    paging: Paging,
    setPaging: React.Dispatch<React.SetStateAction<Paging>>,
    setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
    setCardOverseFeeBaseMgmt: React.Dispatch<React.SetStateAction<selectOverseasFeeBaseList>>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

const CardOverseaBaseTable : FC<Props> = ({
  title,
  selectOverseasFeeBaseList,
  paging,
  setPaging,
  setUpdateClick,
  setCardOverseFeeBaseMgmt
}) => {
    const classes = useStyles();

    // 페이징
    let list = (selectOverseasFeeBaseList === undefined)? []:selectOverseasFeeBaseList["list"];
    let totalCount = (selectOverseasFeeBaseList === undefined)? 0:selectOverseasFeeBaseList["totalCount"];

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
      setPaging({
        ...paging,
        page: inPage
      });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaging({
        ...paging,
        rowsPerPage: Number(event.target.value)
      });
    };

    return (
        <Card className={classes.content}>
            { !!title &&
              <CardHeader title={title}/>
            }
            <CardContent>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">No.</TableCell>
                                    <TableCell align="center">카드상품그룹</TableCell>
                                    <TableCell align="center">카드상품코드</TableCell>
                                    <TableCell align="center">상품코드명</TableCell>
                                    <TableCell align="center">브랜드구분</TableCell>
                                    <TableCell align="center">해외수수료구분</TableCell>
                                    <TableCell align="center">수수료적용일자</TableCell>
                                    <TableCell align="center">수수료적용구분</TableCell>
                                    <TableCell align="center">기본수수료값</TableCell>
                                    <TableCell align="center">사용여부</TableCell>
                                    <TableCell align="center">면제여부</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((item, index)=>(
                                <TableRow
                                    hover
                                    key={index}
                                    onClick={() => {setCardOverseFeeBaseMgmt(item); setUpdateClick(true); }}
                                >
                                    <TableCell align="center">{(paging.rowsPerPage * (paging.page + 1)) - (paging.rowsPerPage - (index + 1))}</TableCell>
                                    <TableCell align="center">{item.cardProductGroupCode }</TableCell>
                                    <TableCell align="center">{item.cardProductCode }</TableCell>
                                    <TableCell align="center">{item.cardProductCodeName}</TableCell>
                                    <TableCell align="center">{item.brandDivisionCode}</TableCell>
                                    <TableCell align="center">{getOverseasFeeCodeName(item.overseasFeeDivisionCode)}</TableCell>
                                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.feeApplyDate)}</TableCell>
                                    <TableCell align="center">{item.feeApplyDivisionCode === '1' ? '정액($)' : '정률(%)'}</TableCell>
                                    <TableCell align="center">{item.feeApplyDivisionCode === '1' ? String(item.basicFeeValue).concat(' $') : String(item.basicFeeValue).concat(' %') }</TableCell>
                                    <TableCell align="center">{item.useYn}</TableCell>
                                    <TableCell align="center">{item.waivedYn}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={totalCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={paging.page}
              rowsPerPage={paging.rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
            </CardActions>
        </Card>
    )
}

export default CardOverseaBaseTable;

import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, Typography } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { Card, Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type Props = {
  className?: string;
  detailInfo: PlccAggrHistoryDetailInfo;
};

const ViewInfo: FC<Props> = ({ className, detailInfo }) => {
  const classes = useStyles();

  if (detailInfo === undefined) {
    return <Card className={clsx(classes.root, className)}></Card>;
  }

  return (
    <>
      <Typography variant="subtitle2">- 국내</Typography>
      <Card className={clsx(classes.root, className)}>
        <Divider />
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center" className={classes.thStyle}>
                출금건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.paymentCount)} 건
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                입금건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.cancelPaymentCount)} 건
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                총건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.totalCount)} 건
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" className={classes.thStyle}>
                출금금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.plusAmount)} 원
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                입금금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.minusAmount)} 원
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                총금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.domestic.totalAmount)} 원
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Typography variant="subtitle2">- 해외</Typography>
      <Card className={clsx(classes.root, className)}>
        <Divider />
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center" className={classes.thStyle}>
                출금건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.paymentCount)} 건
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                입금건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.cancelPaymentCount)} 건
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                총건수
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.totalCount)} 건
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" className={classes.thStyle}>
                출금금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.plusAmount)} 원
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                입금금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.minusAmount)} 원
              </TableCell>
              <TableCell align="center" className={classes.thStyle}>
                총금액
              </TableCell>
              <TableCell align="right" className={classes.tdStyle}>
                {chaiStringUtils.moneyFormat(detailInfo.overseas.totalAmount)} 원
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default ViewInfo;

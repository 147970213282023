import React, { FC, useState } from 'react';
import { useToast } from '../contexts/Toast';
import { Theme, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import NumberFormat from 'react-number-format';
import chaiStringUtils from '../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

type Props = {
  title: string;
  cards: CardInfo[]; //보유카드목록

  isCardDetailsVisible: boolean;
  setIsCardDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>;

  refreshCardInfo: (cardNumber: string) => Promise<void>;
};
const CardList: FC<Props> = ({
  title,
  cards,
  isCardDetailsVisible,
  setIsCardDetailsVisible,

  refreshCardInfo
}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const { showToast } = useToast();

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    console.log('page::' + page);
    setPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  const setCardDetail = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const cardNo = event.currentTarget.value;

    try {
      await refreshCardInfo(cardNo); //카드상세정보조회
      //await refreshApprovals(cardNo); //승인내역목록조회
      //await refreshAccidents(cardNo); //사고내역목록조회

      setIsCardDetailsVisible(true); //카드상세정보 조회완료 되었으니 true로 변경처리-!
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  return (
    <Card>
      {!!title && <CardHeader title={title} />}
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>카드번호</TableCell>
                  <TableCell>상품명</TableCell>
                  <TableCell>유효기한</TableCell>
                  <TableCell>등록일자</TableCell>
                  <TableCell>최종카드여부</TableCell>
                  <TableCell>상태</TableCell>
                  <TableCell>사고등재여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards
                  .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                  .map((item, index) => (
                    <TableRow hover key={item.cardNo}>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={setCardDetail}
                          value={item.cardNo}>
                          {item.cardNoMasked}
                          {/*<NumberFormat format = "####-####-####-####" value={item.cardNo} displayType={'text'}/>*/}
                        </Button>
                      </TableCell>
                      <TableCell>{item.prdtName}</TableCell>
                      <TableCell>
                        <NumberFormat
                          format="####/##"
                          value={item.valdTermYm}
                          displayType={'text'}
                        />
                      </TableCell>
                      <TableCell>
                        {/*<NumberFormat format = "####.##.##" value={item.isucDt} displayType={'text'}/>*/}
                        {chaiStringUtils.dateFormat(item.isucDt, 'yyyy.MM.dd')}
                      </TableCell>
                      <TableCell>
                        <div className={classes.isFinalContainer}>
                          {item.lastCardYn}
                        </div>
                      </TableCell>
                      <TableCell>{item.cardStat}</TableCell>
                      <TableCell>{item.acdtStatDvsnCd}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={cards.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default CardList;

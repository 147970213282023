import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import chaiCodeList from '../../common/chaiCodeList.js';


type SearchInfo = {
    searchType : string;
    searchValue : string;
    dateDivisionCode : string;
    startDt : string;
    endDt : string;
    salesKindDivCode : string;
    accountDivCode : string; 
    purchasedProcNo : string;
    accountProcSeq : string; 
}


type Props = {
    onSearch: React.Dispatch<React.SetStateAction<SearchInfo>>
}

const OverseasPurcahsedHistoryToolBar : FC<Props> = ({onSearch}) => {

    const [searchForm, setSearchForm] = useState({
        searchType: chaiStringUtils.getSchParamGubun(),
        searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
        dateDivisionCode: '1',
        startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
        endDt: format(new Date(), 'yyyy-MM-dd'),
        salesKindDivCode : '',
        accountDivCode : '', 
        purchasedProcNo : '',
        accountProcSeq : ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name] : e.target.value
        });
    };

    // 계정구분코드 목록
    const accountDivisionCodeList = chaiCodeList.codeData.accountDivisionCode;

    const onSearchClick = async () => {
        onSearch(searchForm);
    };

    const onKeyEnter = async(event : React.KeyboardEvent<HTMLInputElement>)=>{    
        if(event.key === "Enter"){
            onSearchClick();
        }
    };

    return (
        <Card>
            <CardContent>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <TextField
                        select
                        fullWidth
                        label="검색조건"
                        margin="dense"
                        SelectProps={{
                        native: true,
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined"
                        name="searchType"
                        value={searchForm.searchType}
                        onChange={handleChange}
                        onKeyPress={onKeyEnter}
                    >
                        <option value="1">휴대폰번호</option>
                        <option value="2">카드번호</option>
                        <option value="3">고객번호</option>
                    </TextField>  
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        fullWidth
                        label="검색값"
                        placeholder="검색값"
                        margin="dense"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined"
                        name="searchValue"
                        value={searchForm.searchValue}
                        onChange={handleChange}
                        onKeyPress={onKeyEnter}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        select
                        fullWidth
                        label="검색일자구분"
                        margin="dense"
                        SelectProps={{
                        native: true,
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined"
                        name="dateDivisionCode"
                        value={searchForm.dateDivisionCode}
                        onChange={handleChange}
                        onKeyPress={onKeyEnter}
                    >
                        <option value="1">수신기준일자</option>
                        <option value="2">해외처리일자</option>
                    </TextField>  
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        label="시작일자"
                        margin="dense"
                        InputLabelProps={{
                        shrink: true
                        }}
                        variant="outlined"
                        name="startDt"
                        value={searchForm.startDt}
                        onChange={handleChange}
                        onKeyPress={onKeyEnter}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        label="종료일자"
                        margin="dense"
                        InputLabelProps={{
                        shrink: true
                        }}
                        variant="outlined"
                        name="endDt"
                        value={searchForm.endDt}
                        onChange={handleChange}
                        onKeyPress={onKeyEnter}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <TextField select fullWidth label="매출종류" margin="dense" 
                        SelectProps={{
                        native: true,
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined"
                        name="salesKindDivCode"
                        value={searchForm.salesKindDivCode}
                        onChange={handleChange} 
                        onKeyPress={onKeyEnter}
                        >
                        <option value="">전체</option>
                        <option value="05">일반매출</option>
                        <option value="15">취소매출</option>
                    </TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField select fullWidth label="계정구분" margin="dense" 
                        SelectProps={{
                        native: true,
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        variant="outlined"
                        name="accountDivCode"
                        value={searchForm.accountDivCode}
                        onChange={handleChange} 
                        onKeyPress={onKeyEnter}
                        >
                        <option value="">전체</option>
                        {accountDivisionCodeList.map(option => (
                        <option key={option.codeValue} 
                            value={option.codeValue}>{option.codeNm}({option.codeValue})</option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField 
                    fullWidth label="매입접수번호" 
                    placeholder="매입접수번호" 
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }} 
                    variant="outlined" 
                    name="purchasedProcNo" 
                    value={searchForm.purchasedProcNo} 
                    onChange={handleChange} 
                    onKeyPress={onKeyEnter}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField 
                    fullWidth label="계정처리순번" 
                    placeholder="계정처리순번" 
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }} 
                    variant="outlined" 
                    name="accountProcSeq" 
                    value={searchForm.accountProcSeq} 
                    onChange={handleChange} 
                    onKeyPress={onKeyEnter}
                    /> 
                </Grid>

                <Grid item xs={12} md={1}>
                    <Button color="primary" 
                    variant="contained" 
                    onClick={onSearchClick} 
                    style = {{ marginTop: '9px' }}>
                        Search
                    </Button>
                </Grid>
            </Grid>
            </CardContent>
        </Card>
       
    );

};

export default OverseasPurcahsedHistoryToolBar;

import React, { FC, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';
import * as utils from '../../common/utils';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<OverseasCsListSearch>>
}

const OverseasCsListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {

  const [overseasCsListSearch, setOverseasCsListSearch] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'), 
    csRewardYn: '',   
    overseasCsReceiptCode: '',
    overseasCsReasonDivisionCode: '',
    overseasCsEndDivisionCode: '',
    salesKindDivisionCode: '',
    cardNo: '',
    purchasedProcessNo: '',
    userKorName: ''
  });
  const [overseasCsReceiptCodeList, setOverseasCsReceiptCodeList] = useState<CommonCodeInfo[]>([]);
  const [overseasCsReasonCodeList, setOverseasCsReasonCodeList] = useState<CommonCodeInfo[]>([]);
  const [overseasCsEndCodeList, setOverseasCsEndCodeList] = useState<CommonCodeInfo[]>([]);
  const [salesKindDivisionCodeList, setSalesKindDivisionCodeList] = useState<CommonCodeInfo[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverseasCsListSearch({
      ...overseasCsListSearch,
      [e.target.name]: e.target.value
    });
  };

  // 민원접수구분코드 조회
  useEffect(() => {
    const fetch = async() => {
      // 민원접수구분코드 조회
      setOverseasCsReceiptCodeList(await utils.getCodeList('CS','CSRECEIPT','Y'));
      // 민원사유구분코드 조회
      setOverseasCsReasonCodeList(await utils.getCodeList('CS','CSREASON','Y'));
      // 민원종료구분코드 조회
      setOverseasCsEndCodeList(await utils.getCodeList('CS','CSEND','Y'));
      // 매출구분코드 조회
      setSalesKindDivisionCodeList(await utils.getCodeList('BASIC','SALESKIND','Y'));
    }
    fetch()
  }, []);

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
    
    onSearch(overseasCsListSearch);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="접수시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={overseasCsListSearch.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="접수종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={overseasCsListSearch.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="보상대상"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="csRewardYn"
                value={overseasCsListSearch.csRewardYn}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                <option key="Y" value="Y">Y</option>
              </TextField>
            </Grid>            
          </Grid>
          <Grid
            container
            spacing={1}
          >              
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="민원접수구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="overseasCsReceiptCode"
                value={overseasCsListSearch.overseasCsReceiptCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {overseasCsReceiptCodeList.map(item => (
                  <option
                    key={item.codeId}
                    value={item.codeId}
                  >
                    {item.codeValue}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="민원사유구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="overseasCsReasonDivisionCode"
                value={overseasCsListSearch.overseasCsReasonDivisionCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {overseasCsReasonCodeList.map(item => (
                  <option
                    key={item.codeId}
                    value={item.codeId}
                  >
                    {item.codeValue}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="민원종결구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="overseasCsEndDivisionCode"
                value={overseasCsListSearch.overseasCsEndDivisionCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {overseasCsEndCodeList.map(item => (
                  <option
                    key={item.codeId}
                    value={item.codeId}
                  >
                    {item.codeValue}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="매출종류구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="salesKindDivisionCode"
                value={overseasCsListSearch.salesKindDivisionCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {salesKindDivisionCodeList.map(item => (
                  <option
                    key={item.codeId}
                    value={item.codeId}
                  >
                    {item.codeValue}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
          >              
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="카드번호"
                placeholder="카드번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardNo"
                value={overseasCsListSearch.cardNo}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="매입접수번호"
                placeholder="매입접수번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="purchasedProcessNo"
                value={overseasCsListSearch.purchasedProcessNo}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="회원명"
                placeholder="회원명"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="userKorName"
                value={overseasCsListSearch.userKorName}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default OverseasCsListToolbar;

import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { productMgmtMutation } from '../../lib/apolloClient/mutations';
import SearchProduct from './SearchProduct';
import ProductList from './ProductList';
import ProductMgmtHistory from './ProductMgmtHistory';
import ProductMgmtDetails from './ProductMgmtDetails';
import { MenuTitleToolbar } from '../../components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ProductMgmt = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [updateClick, setUpdateClick] = useState(false);
  const [productMgmtSearch, setProductMgmtSearch ] = useState({
    cardProductGroupCode: '',
    cardProductCode: '',
    cardProductGroupName: '',
    cardProductCodeName: '',
    brandDivisionCode:'',
    useYn: ''
  });
  const [productMgmt, setProductMgmt] = useState({
    cardProductGroupCode:	'',
    cardProductCode:	'',
    cardProductGroupName:	'',
    cardProductCodeName: '',
    brandDivisionCode: '',
    useYn: ''
  });

  // 카드상품 이력
  const [historyInput, setHistoryInput] = useState({
    cardProductGroupCode: '',
    cardProductCode: '',
    cardProductGroupName: '',
    cardProductCodeName: ''
  });
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const historyfetch = async () => {
      console.log('카드상품이력 조회 실행');
      
      await apolloClient.mutate({
        variables: {
          cardProductGroupName : historyInput.cardProductGroupName,
          cardProductGroupCode:	historyInput.cardProductGroupCode,
          cardProductCode: historyInput.cardProductCode,
          cardProductCodeName: historyInput.cardProductCodeName
        },
        mutation: productMgmtMutation.selectProductMgmtHistoryList
      }).then(res => {
        console.log('이력조회 결과: ', res.data.selectProductMgmtHistoryList);
        setHistory(res.data.selectProductMgmtHistoryList);
      }).catch(console.error);
    }
    historyfetch()
  }, [historyInput]) 


  useEffect(() => {
    const fetch = async () => {
      console.log('실행!');
      await apolloClient.mutate({
        variables: {
          cardProductGroupName : productMgmtSearch.cardProductGroupName,
          cardProductGroupCode:	productMgmtSearch.cardProductGroupCode,
          cardProductCode: productMgmtSearch.cardProductCode,
          cardProductCodeName: productMgmtSearch.cardProductCodeName,
          brandDivisionCode:productMgmtSearch.brandDivisionCode,
          useYn: productMgmtSearch.useYn,
        },
        mutation: productMgmtMutation.selectProductMgmtList
      }).then(res => {
        setData(res.data.selectProductMgmtList);
      }).catch(console.error);
    }
    setProductMgmt({
      cardProductGroupCode:	'',
      cardProductCode:	'',
      cardProductGroupName:	'',
      cardProductCodeName: '',
      brandDivisionCode: '',
      useYn: ''
    });
    setHistoryInput({
      cardProductGroupCode: '',
      cardProductCode: '',
      cardProductGroupName: '',
      cardProductCodeName: ''
    });
    fetch()
  }, [updateClick, productMgmtSearch])


  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="상품정보관리" />
        <SearchProduct onSearch={setProductMgmtSearch}/>
        <div className={classes.content}></div>  
        <ProductMgmtDetails updateClick={updateClick} setUpdateClick={setUpdateClick} productMgmt={productMgmt} setProductMgmt={setProductMgmt} />
        <div className={classes.content}></div>
        <ProductList setUpdateClick={setUpdateClick} productMgmt={data} setProductMgmt={setProductMgmt} setHistoryInput={setHistoryInput}/>
        <div className={classes.content}></div>
        <ProductMgmtHistory data={history} />
      </div>
  );
}

export default ProductMgmt;
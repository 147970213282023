import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Card,
    CardHeader,
    Divider,
    CardActions,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination
  } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

  

  const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    },
    thStyle: {
      backgroundColor: '#fafafa',
    },
    thSumStyle: {
      backgroundColor: '#eeeeee',
    }, 
  }));


  type Paging = {
    page	:number;         
    rowsPerPage	:number;       
  }

  type Props = {
      title: string
      salesDetailsList: SalesDetailsListReturnType
      paging: Paging,
      setPaging: React.Dispatch<React.SetStateAction<Paging>>
  }

  const SalesDetailsTable: FC<Props> = ({ title, salesDetailsList, paging, setPaging }) => {
    const classes = useStyles();

    // 페이징
    let list = (salesDetailsList === undefined)? []:salesDetailsList["list"];
    let totalCount = (salesDetailsList === undefined)? 0:salesDetailsList["totalCount"];

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
      setPaging({
        ...paging,
        page: inPage
      });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaging({
        ...paging,
        rowsPerPage: Number(event.target.value)
      });
    };

    return(
        <Card>
          { !!title &&
            <CardHeader title={title}/>
          }
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">NO</TableCell>
                      <TableCell align="center">입금일자</TableCell>
                      <TableCell align="center">매출건수</TableCell>
                      <TableCell align="center">매출금액</TableCell>
                      <TableCell align="center">가맹점수수료</TableCell>
                      <TableCell align="center">발급사보전수수료</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {list.map((item,index) => (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell align="center">{(paging.rowsPerPage * (paging.page + 1)) - (paging.rowsPerPage - (index + 1))}</TableCell>
                      <TableCell align="center">{chaiStringUtils.dateFormatDefault((item.depositDate))}</TableCell>
                      <TableCell align="center">{chaiStringUtils.moneyFormat(item.salesCount) + ' 건'}</TableCell>
                      <TableCell align="center">{chaiStringUtils.moneyFormat(item.salesTotalAmount) + ' 원'}</TableCell>
                      <TableCell align="center">{chaiStringUtils.moneyFormat(item.merchantFee) + ' 원'}</TableCell>
                      <TableCell align="center">{chaiStringUtils.moneyFormat(item.issuerFee) + ' 원'}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={paging.page}
            rowsPerPage={paging.rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
          </CardActions>
        </Card>
    );
  }

  export default SalesDetailsTable;
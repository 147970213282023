import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#fafafa',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
}));

type Props = { 
  title :string;  
  sumInfo: OverseasPurchasedAggrHistorySum;
}

const OverseasPurchasedAggrSumTable: FC<Props> = ({ title, sumInfo }) => {
  const classes = useStyles();
  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content} style={{paddingBottom: '0'}}>
        <div className={classes.inner}>        
          <Table size="small">
          <TableBody>
            <TableRow>
                <TableCell align="center" className={classes.thStyle}>정산미화금액출금건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(sumInfo.settlement_usd_withdrawal_count == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_withdrawal_count)} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산미화입금건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{(sumInfo.settlement_usd_deposit_count == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_deposit_count)} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산미화출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산미화입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_deposit_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>정산미화거래총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_trade_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_trade_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산원화출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산원화입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산원화거래총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_trade_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_trade_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>정산미화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산미화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_fee_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산미화수수료거래총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.settlement_usd_fee_trade_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_usd_fee_trade_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산원화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_fee_withdrawal_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>정산원화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_fee_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>정산원화수수료거래총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.settlement_krw_fee_trade_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.settlement_krw_fee_trade_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>브랜드미화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.brand_usd_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.brand_usd_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>브랜드미화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.brand_usd_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.brand_usd_fee_deposit_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>브랜드원화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.brand_krw_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.brand_krw_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>브랜드원화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.brand_krw_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.brand_krw_fee_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외이용미화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.overseas_use_usd_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_use_usd_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외출금미화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.overseas_use_usd_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_use_usd_fee_deposit_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>해외이용원화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.overseas_use_krw_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_use_krw_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외이용원화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.overseas_use_krw_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_use_krw_fee_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외출금미화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.overseas_withdrawal_usd_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_withdrawal_usd_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외출금미화수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>$ {(sumInfo.overseas_withdrawal_usd_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_withdrawal_usd_fee_deposit_total_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>해외출금원화수수료출금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.overseas_withdrawal_krw_fee_withdrawal_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_withdrawal_krw_fee_withdrawal_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>해외출금수수료입금총액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.overseas_withdrawal_krw_fee_deposit_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.overseas_withdrawal_krw_fee_deposit_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매입환율차이수익금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.exchange_rate_diff_profit_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.exchange_rate_diff_profit_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매입환율차이비용금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.exchange_rate_diff_loss_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.exchange_rate_diff_loss_amount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>매입환율차이총금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>&#8361; {(sumInfo.exchange_rate_diff_total_amount == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.exchange_rate_diff_total_amount)}</TableCell>
                <TableCell align="center" className={classes.thStyle}>매매기준환율</TableCell>
                <TableCell align="right" className={classes.tdStyle} colSpan={5}>{(sumInfo.deal_base_exchange_rate == null) ? 0 : chaiStringUtils.moneyFormat(sumInfo.deal_base_exchange_rate)}</TableCell>
              </TableRow>
            </TableBody>       
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverseasPurchasedAggrSumTable;

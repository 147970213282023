import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient, { overseasPurchasedAggrHistoryAdjustProcessMutation } from '../../lib/apolloClient';
import { MenuTitleToolbar } from '../../components';
import OverseasPurchasedAggrHistoryAdjustProcessToolbar from './OverseasPurchasedAggrHistoryAdjustProcessToolbar';
import OverseasPurchasedAggrHistoryAdjustProcessTable from './OverseasPurchasedAggrHistoryAdjustProcessTable';
import * as utils from '../../common/utils';
import {format} from 'date-fns'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    registerButton: {
      margin: theme.spacing(1),
      float: 'right'
    },
  }));

type Props = {
    listInfo: OverseasPurchasedAggrHistoryAdjustProcessListReturnType
  }

const OverseasPurchasedAggrHistoryAdjustProcess: FC<Props> = ({listInfo}) => {
    const classes = useStyles();
    const [data, setOverseasPurchasedAggrHistoryData] = useState(listInfo);
    
    const[searchForm, setSearchForm] = useState({
        startReceve_base_date: format(new Date(), 'yyyy-MM-dd'),
        endReceve_base_date: format(new Date(), 'yyyy-MM-dd'),
        totalCount: 0
        });

    const [loading, setLoading] = useState(false);

    const fetch = async () => {
        if(loading) {
            return;
            }
        setLoading(true);
        await apolloClient.mutate({
            variables: {
                startReceve_base_date: searchForm.startReceve_base_date,
                endReceve_base_date: searchForm.endReceve_base_date,
                page: paging.page,
                rowsPerPage: paging.rowsPerPage
            },
            mutation: overseasPurchasedAggrHistoryAdjustProcessMutation.selectOverseasPurchasedAggrHistoryAdjustProcessList
        }).then(res => {
            const data = res.data.selectOverseasPurchasedAggrHistoryAdjustProcessList;
            setOverseasPurchasedAggrHistoryData(data);
        }).catch(console.error);
        setLoading(false);
    };
        
    // 페이징
    const default_page = 0;
    const default_rowsPerPage = 10;
    const [paging, setPaging] = useState({
        page: default_page,
        rowsPerPage: default_rowsPerPage
    });

    useEffect(()=> {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    return (
        <div className={classes.root}>
            <MenuTitleToolbar title="해외매입조정처리내역조회" />
            <OverseasPurchasedAggrHistoryAdjustProcessToolbar
            onSearch={fetch}
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            paging={paging}
            setPaging={setPaging} />
      <div className={classes.content}>
            {loading
          ? 
            utils.Loading()
          :
          <OverseasPurchasedAggrHistoryAdjustProcessTable data={data} paging={paging} setPaging={setPaging} />
        }
            </div>
        <br/>
        <br/>
        </div>
    );
};

export default OverseasPurchasedAggrHistoryAdjustProcess;

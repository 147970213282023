import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  nonInoutHistory: NonInoutHistoryReturn[];
};

const NonInoutHistory: FC<Props> = ({ nonInoutHistory }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  
  const map = new Map<String, String>();
  map.set('1', '전액미입출금');
  map.set('2', '부분미입출금');
  map.set('3', '전액입출금');
  map.set('4', '잡손실');
  map.set('5', '잡수익');
  map.set('6', '중단');

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  if (
    nonInoutHistory === null ||
    nonInoutHistory === undefined ||
    nonInoutHistory.length === 0
  ) {
    return (
      <Card style={{ backgroundColor: '#F4F6F8' }} className={classes.content}>
        <CardContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '0px solid white' }}>
                  조회된 내용이 없습니다.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.content}>
      <CardContent>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">NO.</TableCell>
                  <TableCell align="center">등록일자</TableCell>
                  <TableCell align="center">미입출금일련번호</TableCell>
                  <TableCell align="center">수신기준일자</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">수신일자</TableCell>
                  <TableCell align="center">입출구분</TableCell>
                  <TableCell align="center">미입출원금금액</TableCell>
                  <TableCell align="center">최종조작상세일시</TableCell>
                  <TableCell align="center">미입출금상태코드</TableCell>
                  <TableCell align="center">대변대상번호</TableCell>
                  <TableCell align="center">차변대상번호</TableCell>
                  <TableCell align="center">변경사유</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonInoutHistory
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item, idx) => (
                    <TableRow
                      hover
                      key={item.nonInoutSeq + '' + item.nonInoutRegisteredDate}>
                      <TableCell style={{ width: '9px' }} align="center">
                        {page * rowsPerPage + idx + 1}
                      </TableCell>
                      <TableCell align="center">
                        {`${item.nonInoutRegisteredDate.substr(
                        0,
                        4
                      )}년 ${item.nonInoutRegisteredDate.substr(
                        4,
                        2
                      )}월 ${item.nonInoutRegisteredDate.substr(6, 2)}일`}
                      </TableCell>
                      <TableCell align="center">{item.nonInoutSeq}</TableCell>
                      <TableCell align="center">{`${item.receveBaseDate.substr(
                        0,
                        4
                      )}년 ${item.receveBaseDate.substr(
                        4,
                        2
                      )}월 ${item.receveBaseDate.substr(6, 2)}일`}</TableCell>

                      <TableCell align="center">
                        {chaiStringUtils.cardNoMaskFormat(item.cardNo)}
                      </TableCell>
                      <TableCell align="center">{item.receveDate}</TableCell>
                      <TableCell align="center">
                        {item.inputDivisionCode}
                      </TableCell>
                      <TableCell align="center">
                        {item.nonInoutOriginAmount.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                      <TableCell align="center">
                        {`${item.lastOperateDetailDate.substr(
                              0,
                              4
                            )}년 ${item.lastOperateDetailDate.substr(
                              4,
                              2
                            )}월 ${item.lastOperateDetailDate.substr(
                              6,
                              2
                            )}일 ${item.lastOperateDetailDate.substr(
                              8,
                              2
                            )}:${item.lastOperateDetailDate.substr(
                              10,
                              2
                            )}:${item.lastOperateDetailDate.substr(12, 2)}`}
                      </TableCell>
                      <TableCell align="center">
                      {map.get(item.nonInoutStatusCode)}
                      </TableCell>
                      <TableCell align="center">
                        {item.creditTargetNo}
                      </TableCell>
                      <TableCell align="center">{item.debitTargetNo}</TableCell>
                      <TableCell align="center">{item.summaryType}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination // pagenation 조작을 위한 함수 지정
          style={{ marginLeft: 'auto' }}
          component="div"
          count={nonInoutHistory.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

export default NonInoutHistory;

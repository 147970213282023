import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { 
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  totalCount : number,
  batchlist: BatchProcessHistoryInfo[] 
}

const BatchProcessHistoryTable: FC<Props> = ({ paging, setPaging, totalCount, batchlist }) => {
  const classes = useStyles();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
    // setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
    // setRowsPerPage(Number(event.target.value));
  };

  const displayProcessStatus = (processStatus:string) => {
    
    let displayStatus = '';
    if (processStatus === '1') displayStatus = '정상';
    else if (processStatus === '2') displayStatus = '처리중';
    else if (processStatus === '9') displayStatus = '오류';
    else displayStatus = '';

    return displayStatus;
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>NO.</TableCell>
                  <TableCell align='center'>처리일자</TableCell>
                  <TableCell align='center'>처리순번</TableCell>
                  <TableCell align='center'>시작일시</TableCell>
                  <TableCell align='center'>종료일시</TableCell>
                  <TableCell align='center'>처리상태</TableCell>
                  <TableCell align='center'>대상건수</TableCell>
                  <TableCell align='center'>처리건수</TableCell>
                  <TableCell align='center'>오류메시지</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batchlist.slice(0, batchlist.length).map((item, index) => (
                  <TableRow
                    hover
                    key={item.batchSeqNo+''+item.processDate+''+item.processSeqNo}
                  >
                    <TableCell align='center'>{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.dateFormatDefault(item.processDate)}</TableCell>
                    <TableCell align='center'>{item.processSeqNo}</TableCell>
                    <TableCell align='center'>{item.startDate && chaiStringUtils.dateFormatDefault(item.startDate.substring(0,8))+' '+chaiStringUtils.dateTimeFormat(item.startDate.substring(8,14))}</TableCell>
                    <TableCell align='center'>{item.endDate && chaiStringUtils.dateFormatDefault(item.endDate.substring(0,8))+' '+chaiStringUtils.dateTimeFormat(item.endDate.substring(8,14))}</TableCell>
                    <TableCell align='center'>{displayProcessStatus(item.processStatus)}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.moneyFormat(item.targetDataCount)}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.moneyFormat(item.processCount)}</TableCell>
                    <TableCell align='center'>{item.errorMessage}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
    </Card>
  );
};

export default BatchProcessHistoryTable;

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee',
  },
}));

type Props = {
  isDetailPop: boolean;
  setDetailPop: React.Dispatch<React.SetStateAction<boolean>>;
  detailInfo?: HanaPurchasedHistoryInfo;
};

const PurchasedHistoryDetail: FC<Props> = ({ isDetailPop, setDetailPop, detailInfo }) => {
  const classes = useStyles();

  const handleClose = () => {
    setDetailPop(false);
  };

  if (!detailInfo) {
    return (
      <Dialog
        open={isDetailPop}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle>
          <MenuTitleToolbar title="신용카드 매입내역 상세조회" />
        </DialogTitle>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={isDetailPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle>
        <MenuTitleToolbar title="신용카드 매입내역 상세조회" />
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.thStyle}>승인번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.approvalNumber}</TableCell>
              <TableCell className={classes.thStyle}>승인일</TableCell>
              <TableCell className={classes.tdStyle}>
                {chaiStringUtils.dateFormatDefault(detailInfo.approvalDate)}
              </TableCell>
              <TableCell className={classes.thStyle}>매입일</TableCell>
              <TableCell className={classes.tdStyle}>
                {chaiStringUtils.dateFormatDefault(detailInfo.purchaseDate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>카드번호</TableCell>
              <TableCell className={classes.tdStyle}>****-****-****-{detailInfo.cardLastNumber}</TableCell>
              <TableCell className={classes.thStyle}>가맹점 번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo._merchant?.id}</TableCell>
              <TableCell className={classes.thStyle}>가맹점명</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo._merchant?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>가맹점 업종코드</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo._merchant?.businessCode}</TableCell>
              <TableCell className={classes.thStyle}>가맹점 사업자번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo._merchant?.businessNumber}</TableCell>
              <TableCell className={classes.thStyle}>거래형태</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tdStyle}>해외결제</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>해외거래여부</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.isOverseasPurchase ? 'Y' : 'N'}</TableCell>
              <TableCell className={classes.thStyle}>통화</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.currency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>현지 금액</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo?.amount?.toLocaleString()}</TableCell>
              <TableCell className={classes.thStyle}>매입 원화</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo?.amountKRW?.toLocaleString()}</TableCell>
              <TableCell className={classes.thStyle}>매입 달러</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo?.amountUSD?.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>수수료 원화</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo?.feeKRW?.toLocaleString()}</TableCell>
              <TableCell className={classes.thStyle}>환율</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.exchangeRate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thStyle}>매입 ID</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.id}</TableCell>
              <TableCell className={classes.thStyle}>머니 승인번호</TableCell>
              <TableCell className={classes.tdStyle}>{detailInfo.paymentId}</TableCell>
              <TableCell className={classes.thStyle}>생성시간</TableCell>
              <TableCell className={classes.tdStyle}>{new Date(detailInfo?.createdAt).toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchasedHistoryDetail;

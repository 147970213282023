import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { SearchInput } from '../../components';
import { useToast } from '../../contexts/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

type Props = {
  className?: string,
  cardNumber: string,
  isCardDetailsVisible: boolean,
  setIsCardDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  refreshCardInfo: (cardNumber: string) => Promise<void>
  refreshCardUserInfo: (cardNumber: string) => Promise<void>
  refreshApprovals: (cardNumber: string) => Promise<void>
  refreshAccidents: (cardNumber: string) => Promise<void>,
}
const UsersToolbar: FC<Props> = ({
  className,
  cardNumber='',
  isCardDetailsVisible,
  setIsCardDetailsVisible,
  refreshCardInfo, 
  refreshCardUserInfo,
  refreshApprovals,
  refreshAccidents,
}) => {
  const classes = useStyles();

  const [cNo, setcNo] = useState(cardNumber);
  const { showToast } = useToast();

  const setCardDetail = async (cardNumber: string) => {
    try {
      await refreshCardInfo(cardNumber);
      await refreshCardUserInfo(cardNumber);
      await refreshApprovals(cardNumber);
      await refreshAccidents(cardNumber);

      setIsCardDetailsVisible(true);
    } catch (e) {
      showToast(e.message, 'error');
    }
  }

  useEffect(() => {
    if (cardNumber && !isCardDetailsVisible) {
      console.log(cardNumber, isCardDetailsVisible)
      setCardDetail(cardNumber);
    }
  // eslint-disable-next-line
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcNo(event.target.value);
  };

  const onSearchClick = () => {
    setCardDetail(cNo);
  };

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Card Number"
          handleChange={handleChange}
          value={cNo}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Search 
        </Button>
      </div>
    </div>
  );
};

export default UsersToolbar;

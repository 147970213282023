import React, {useState, FC} from 'react';
import{
    Card,
    CardHeader,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
} from '@material-ui/core';

type Props ={
    data:TempCardProductHistory[],
}

/**
const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
**/

const ProductMgmtHistory : FC<Props> = ({ data }) => {
    // const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
      };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
      };

    const brandDivisionCodeMap = new Map<String, String>();
    brandDivisionCodeMap.set('0', 'GLOBAL BC');
    brandDivisionCodeMap.set('1', 'LOCAL');
    brandDivisionCodeMap.set('2', 'MC');
    brandDivisionCodeMap.set('3', 'VISA');
    brandDivisionCodeMap.set('4', 'JCB');

return (
        <Card>
        <CardHeader title="<상품정보 변경이력>"/>        
        <Table>
            <TableHead>
            </TableHead>
            <TableRow>
                <TableCell>
                    No.
                </TableCell>
                <TableCell>
                    변경일시
                </TableCell>
                <TableCell>
                    카드상품그룹코드
                </TableCell>
                <TableCell>
                    카드상품코드
                </TableCell>
                <TableCell>
                    카드상품코드명
                </TableCell>
                <TableCell>
                    사용여부
                </TableCell>
                <TableCell>
                    브랜드
                </TableCell>
                <TableCell> 
                    연회비청구여부
                </TableCell>
                <TableCell> 
                    마스터상품연간회비
                </TableCell>
                <TableCell> 
                    비고
                </TableCell>
            </TableRow>
            <TableBody>
            {data.slice(page * rowsPerPage, (page + 1) * (rowsPerPage)).map((item, idx) => (
            
            <TableRow
                key={Number(item.changeSeq)}>
                <TableCell>{(page * rowsPerPage) + idx+1}</TableCell>
                <TableCell>{item.changeDate}/{item.changeTime}</TableCell>
                <TableCell>{item.cardProductGroupCode}</TableCell>
                <TableCell>{item.cardProductCode}</TableCell>
                <TableCell>{item.cardProductCodeName}</TableCell>
                <TableCell>{item.useYn}</TableCell>
                <TableCell>{brandDivisionCodeMap.get(item.brandDivisionCode)}</TableCell>
                <TableCell>{item.annualFeeClaimYn}</TableCell>
                <TableCell>{item.masterProductYearlyDues}</TableCell>
                <TableCell>{item.remarkDescription}</TableCell>
            </TableRow>
            ))}
            </TableBody>
        </Table>
        <TablePagination
            component="div"
            count={data.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
        />
        </Card>

);
};
export default ProductMgmtHistory;

import gql from 'graphql-tag';

//발급집계내역 목록 조회
export const selectAprvAgrgList = gql`
  mutation selectAprvAgrgList($cardProductCode: String!, $startDate: String!, $endDate: String!) {
    selectAprvAgrgList(cardProductCode: $cardProductCode, startDate: $startDate, endDate: $endDate) {
      tradeDate
      cardProductCode
      cardProductCodeName
      approvalCount
      approvalAmount
      approvalReCount
      approvalLostReCount
    }
  }
`;

//발급집계내역 합계 조회
export const selectAprvAgrgTotData = gql`
  mutation selectAprvAgrgTotData($cardProductCode: String!, $startDate: String!, $endDate: String!) {
    selectAprvAgrgTotData(cardProductCode: $cardProductCode, startDate: $startDate, endDate: $endDate) {
      approvalTotCount
      approvalTotAmount
      approvalReTotCount
      approvalLostReTotCount
    }
  }
`;

//발급집계내역 상세 목록 조회
export const selectAprvAgrgDetail = gql`
  mutation selectAprvAgrgDetail($tradeDate: String!, $cardProductCode: String!) {
    selectAprvAgrgDetail(tradeDate: $tradeDate, cardProductCode: $cardProductCode) {
      tradeDate
      gb
      cardNo
      cardNoMasked
      prdtName
      valdTermYm
      cardStat
      terminateDate
    }
  }
`;

import React, { FC, ChangeEvent } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Button,
  DialogContent,
  Card,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import * as utils from '../../common/utils';
import { groupMerchantMgmtMutation } from '../../lib/apolloClient/mutations';
import apolloClient from '../../lib/apolloClient';

const StyledTableCell = withStyles((theme: Theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 1
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));
**/

type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputCancelYn: string             // 해지여부
}

type Props = {
  updatePop: boolean,
  setUpdatePop: React.Dispatch<React.SetStateAction<boolean>>,
  groupMerchantDetail: {
    groupMerchantNo: string;
    groupMerchantName: string;
  },
  setGroupMerchantDetailInfo: React.Dispatch<React.SetStateAction<{
    groupMerchantNo: string;
    groupMerchantName: string;
  }>>,
  fetch: any,
  searchForm: SearchInfo
}

const GroupMerchantInfoMgmtUpdatePop: FC<Props> = ({updatePop, setUpdatePop, groupMerchantDetail, setGroupMerchantDetailInfo, fetch, searchForm}) => {
  // const classes = useStyles();
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupMerchantDetailInfo({
      ...groupMerchantDetail,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setUpdatePop(false);
  };

  // 그룹가맹점 저장 처리
  const handleUpdateAction = async () => {
    console.log(groupMerchantDetail);
    if(utils.isNullOrBlank(groupMerchantDetail.groupMerchantNo) || groupMerchantDetail.groupMerchantNo.length !== 8) {
      showToast('그룹가맹점번호가 올바르지 않습니다.', 'error');
      hideDialog();
      return;
    }

    if(utils.isNullOrBlank(groupMerchantDetail.groupMerchantName)) {
      showToast('그룹가맹점명이 입력되지 않았습니다.', 'error');
      hideDialog();
      return;
    }

    await apolloClient.mutate({
      variables: {
        input: {
          groupMerchantNo: groupMerchantDetail.groupMerchantNo,         // 그룹가맹점번호
          groupMerchantName: groupMerchantDetail.groupMerchantName,     // 그룹가맹점명
        }
      },
      mutation: groupMerchantMgmtMutation.updateGroupMerchantMaster
    }).then(res => {
      hideDialog();
      console.log('결과: ', res.data.updateGroupMerchantMaster);

      if(res.data.updateGroupMerchantMaster.success === 'Y') {
        showToast(res.data.updateGroupMerchantMaster.message);
        handleClose();

        // 목록 리로드
        fetch(searchForm);
      }else {
        showToast(res.data.updateGroupMerchantMaster.message, 'error');
      }
      
    }).catch(e => {
      console.log(e);
        hideDialog();
        showToast(e.message, 'error');
    });
  }

  // 그룹가맹점 삭제
  const handleDeleteAction = async () => {
    console.log(groupMerchantDetail);

    await apolloClient.mutate({
      variables: {
        input: {
          groupMerchantNo: groupMerchantDetail.groupMerchantNo,         // 그룹가맹점번호
          groupMerchantName: groupMerchantDetail.groupMerchantName,     // 그룹가맹점명
        }
      },
      mutation: groupMerchantMgmtMutation.deleteGroupMerchantMaster
    }).then(res => {
      hideDialog();
      console.log('결과: ', res.data.deleteGroupMerchantMaster);

      if(res.data.deleteGroupMerchantMaster.success === 'Y') {
        showToast(res.data.deleteGroupMerchantMaster.message);
        handleClose();

        // 목록 리로드
        fetch(searchForm);
      }else {
        showToast(res.data.deleteGroupMerchantMaster.message, 'error');
      }
      
    }).catch(e => {
      console.log(e);
        hideDialog();
        showToast(e.message, 'error');
    });
  }

  return (
    <Dialog
      open={updatePop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title"><MenuTitleToolbar title="그룹가맹점 수정" /></DialogTitle>

      <DialogContent>
        <Card>
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>그룹가맹점번호</StyledTableCell>
                <StyledTableCell colSpan={5}>
                  <TextField
                    fullWidth
                    placeholder="그룹가맹점번호 10자리"
                    margin="dense"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="groupMerchantNo"
                    value={groupMerchantDetail.groupMerchantNo}
                    onChange={handleChange}
                  />
                </StyledTableCell>
                <StyledTableCell>그룹가맹점명</StyledTableCell>
                <StyledTableCell colSpan={5}>
                  <TextField
                    fullWidth
                    placeholder="그룹가맹점명"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="groupMerchantName"
                    value={groupMerchantDetail.groupMerchantName}
                    onChange={handleChange}
                  />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Card>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button 
          style={{color: '#fff', background: '#dc3545'}}
          variant="contained"
          onClick={() => showDialog(
            '그룹가맹점정보',
            '해당 정보를 정말 삭제하시겠습니까? 삭제 시 복구할 수 없습니다.',
            handleDeleteAction
          )}
        >
          Remove
        </Button>
        <Button 
          color="primary"
          variant="contained"
          onClick={() => showDialog(
            '그룹가맹점정보',
            '해당 정보로 저장하시겠습니까?',
            handleUpdateAction
          )}
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupMerchantInfoMgmtUpdatePop;
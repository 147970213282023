import React, {useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import {MenuTitleToolbar} from '../../components';
import OverseasSettlementAggrListToolBar from './OverseasSettlementAggrListToolBar';
import OverseasSettlementAggrListDetail from './OverseasSettlementAggrListDetail';
import OverseasSettlementAggrListEntire from './OverseasSettlementAggrListEntire';
import OverseasSettlementAggrListFinalAmount from './OverseasSettlementAggrListFinalAmount';
import apolloClient from '../../lib/apolloClient';
import {overseasSettlementAggrListMutation} from '../../lib/apolloClient/mutations'; 

const useStyles = makeStyles((theme: Theme) => ({
    root : { padding : theme.spacing(4) },
    content : { marginTop : theme.spacing(2) },
    detail : { marginBottom : theme.spacing(3) }
}));

const OverseasSettlementAggrList = () => {

    const classes = useStyles();

    const [searchForm, setSearchForm] = useState({
        searchDate : format(new Date(), 'yyyy-MM-dd')
    });

    const [detailData, setDetailData] = useState([]);
    const [entireData, setEntireData] = useState([]);


    useEffect(() => {
        console.log('# selectOvrsSettlementAggrListDetail mut START ');
        const getDetail = async() => {
            await apolloClient.mutate({
                mutation : overseasSettlementAggrListMutation.selectOvrsSettlementAggrListDetail,
                variables : { searchDate : searchForm.searchDate.replace(/-/g, '') }
            }).then( res => {
                setDetailData(res.data.selectOvrsSettlementAggrListDetail);
                console.log('# selectOvrsSettlementAggrListDetail mut END', res.data.selectOvrsSettlementAggrListDetail)
            }).catch(console.error);
        }
        getDetail();
    },[searchForm]);


    useEffect(() => {
        console.log('# selectOvrsSettlementAggrListEntire mut START ')
        const getEntire = async() => {
            await apolloClient.mutate({
                mutation : overseasSettlementAggrListMutation.selectOvrsSettlementAggrListEntire,
                variables : { searchDate : searchForm.searchDate.replace(/-/g, '') }
            }).then( res => {
                setEntireData(res.data.selectOvrsSettlementAggrListEntire);
                console.log('# selectOvrsSettlementAggrListEntire mut END' , res.data.selectOvrsSettlementAggrListEntire);
            })
            .catch(console.error);
        }
        getEntire();
    },[searchForm]);

    return(
        <div className={classes.root}>
            <MenuTitleToolbar  title="해외정산집계내역조회" />
            <OverseasSettlementAggrListToolBar onSearch={setSearchForm} />
            <div className={classes.content}>
                <OverseasSettlementAggrListDetail detailInfo={detailData} />
                <br/>
                <OverseasSettlementAggrListFinalAmount detailInfo={detailData} />
                <br/>
                <OverseasSettlementAggrListEntire entireInfo={entireData}/>
            </div>
        </div>
    );


}

export default OverseasSettlementAggrList;
import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalHistoryLogMutation } from '../../lib/apolloClient/mutations';
import ApprovalHistoryLogListToolbar from './ApprovalHistoryLogListToolbar';
import ApprovalHistoryLogTable from './ApprovalHistoryLogTable';
import ApprovalHistoryLogAggrTable from './ApprovalHistoryLogAggrTable';
import { MenuTitleToolbar } from '../../components';
import {format, subDays} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ApprovalHistoryLogList = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const default_page = 0;
  const default_rowsPerPage = 100;
  const [approvalHistoryLogSearch, setApprovalHistoryLogSearch] = useState({
    salesKindDivisionCode: '',
    dataDivisionCode:'',
    approvalDate: format(subDays(new Date(), 1), 'yyyy-MM-dd')
  });

  const [approvalAggrData, setApprovalAggrData] = useState({
    normalApprovalAmountSum: 0,
    normalApprovalCount:	0,
    abnormalApprovalAmountSum:	0,
    abnormalApprovalCount: 0,
    normalApprovalCancelAmountSum: 0,
    normalApprovalCancelCount: 0,
    abnormalApprovalCancelAmountSum: 0,
    abnormalApprovalCancelCount: 0,
    chaimoneyNormalApprovalAmountSum: 0,
    chaimoneyNormalApprovalCount:	0,
    chaimoneyAbnormalApprovalAmountSum:	0,
    chaimoneyAbnormalApprovalCount: 0,
    chaimoneyNormalApprovalCancelAmountSum: 0,
    chaimoneyNormalApprovalCancelCount: 0,
    chaimoneyAbnormalApprovalCancelAmountSum: 0,
    chaimoneyAbnormalApprovalCancelCount: 0,     
    bcNormalApprovalAmountSum: 0,
    bcNormalApprovalCount:	0,
    bcAbnormalApprovalAmountSum:	0,
    bcAbnormalApprovalCount: 0,
    bcNormalApprovalCancelAmountSum: 0,
    bcNormalApprovalCancelCount: 0,
    bcAbnormalApprovalCancelAmountSum: 0,
    bcAbnormalApprovalCancelCount: 0  
  });

  const [refundCount,setRefundCount] = useState(1);

  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });


  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {          
          salesKindDivisionCode : approvalHistoryLogSearch.salesKindDivisionCode, 
          dataDivisionCode : approvalHistoryLogSearch.dataDivisionCode,
          approvalDate : approvalHistoryLogSearch.approvalDate.replace(/-/g,''),
          page: paging.page,
          rowsPerPage: paging.rowsPerPage}},
        mutation: approvalHistoryLogMutation.selectApprovalCompareList
      }).then(res => {
        // console.log(res.data);  
        setData(res.data.selectApprovalCompareList);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
  }, [approvalHistoryLogSearch, refundCount, paging])


  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {  
          salesKindDivisionCode : approvalHistoryLogSearch.salesKindDivisionCode, 
          dataDivisionCode : approvalHistoryLogSearch.dataDivisionCode,
          approvalDate : approvalHistoryLogSearch.approvalDate.replace(/-/g,'')}
        },
        mutation: approvalHistoryLogMutation.selectApprovalCompareAggregation
      }).then(res => {
        // console.log('>>>>>>',res.data);  
        setApprovalAggrData(res.data.selectApprovalCompareAggregation);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
  }, [approvalHistoryLogSearch, refundCount])


  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내승인대사내역" />
      <ApprovalHistoryLogListToolbar page={default_page} rowsPerPage={default_rowsPerPage} setPaging={setPaging} onSearch={setApprovalHistoryLogSearch}/> 
      <div className={classes.content}>
        <ApprovalHistoryLogAggrTable approvalCompareAggrInfo={approvalAggrData}/>
      </div>      
      <div className={classes.content}>
        <ApprovalHistoryLogTable paging={paging} setPaging={setPaging} refundCount={refundCount} setRefundCount={setRefundCount} approvalCompareList={data}/>
      </div>
    </div>
  );

};

export default ApprovalHistoryLogList;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useAuth } from '../../Auth';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Logout = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    auth.signOut().then(() => {
      setMessage('로그아웃 하였습니다.');
      setLoading(false);
    });
  }, [auth]);

  if (loading) {
    return (
      <div className={classes.root}>
        <div>Loading...</div>
        <Link to={{ pathname: '/logout' }}>
          <Button color="primary" variant="outlined" size="medium">
            재시도
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>{message ? JSON.stringify(message) : '로그아웃 되었습니다.'}</div>
      <br />
      <div>
        <Link to={{ pathname: '/' }}>
          <Button color="primary" variant="outlined" size="medium">
            확인
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Logout;

import React, { FC, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import * as utils from '../../common/utils';

type Props = {
    onSearch: React.Dispatch<React.SetStateAction<any>>,
    searchForm: SearchInfo,
    setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
};

const OverseasCsRefundToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {


const [csRewardCodeList, setCsRewardCodeList] = useState<CommonCodeInfo[]>([]);

    // 변경이벤트
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        });  
    };

    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
        if (event.key === "Enter"){
            onSearchClick();
        }
    }

    // 목록 조회
    const onSearchClick = async () => {
        onSearch(searchForm);
    }

    // 보상진행상태코드 공통코드 조회
    useEffect(() => {
      const fetch = async() => {
        setCsRewardCodeList(await utils.getCodeList('CS','CSREWARD','Y'));
      }
      fetch()
    }, []);

    return(
        <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="전금일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="trmDate"
              value={searchForm.trmDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid> 
        </Grid>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="민원접수시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="overseasCsReceveDate"
              value={searchForm.overseasCsReceveDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="민원접수종료일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="overseasCsEndDate"
              value={searchForm.overseasCsEndDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              type="text"
              label="카드번호"
              placeholder="카드번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="cardNo"
              value={searchForm.cardNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              type="text"
              label="회원명"
              placeholder="회원명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="userKorName"
              value={searchForm.userKorName}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              type="text"
              label="카드일렬번호"
              placeholder="카드일렬번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="cardSeq"
              value={searchForm.cardSeq}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="보상진행상태코드"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              name="rewardProgressStatusCode"
              value={searchForm.rewardProgressStatusCode}
              onChange={handleChange}
            >
            <option key="" value="">전체</option>
            { csRewardCodeList.map(item => (
              <option
                key={item.codeValue}
                value={item.codeId}
              >
                {item.codeValue}
              </option>
            ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              type="text"
              label="매입접수번호"
              placeholder="매입접수번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="purchasedProcessNo"
              value={searchForm.purchasedProcessNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              style = {{
                marginTop: '8px'
              }}
              onClick={onSearchClick}
            >
              Search 
            </Button>
          </Grid>
        </Grid>    
      </CardContent>
     </Card>

    )
}

export default OverseasCsRefundToolbar;
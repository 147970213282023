import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee'
  }
}));

type Props = { 
  isDetailPop: boolean,
  setDetailPop: React.Dispatch<React.SetStateAction<boolean>>,
  detailInfo: any
}

const PurchasedHistoryDetail: FC<Props> = ({ isDetailPop, setDetailPop, detailInfo }) => {

  const classes = useStyles();

  const handleClose = () => {
    setDetailPop(false);
  };

  return (
    <Dialog
      open={isDetailPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle><MenuTitleToolbar title="매입내역 상세조회" /></DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableBody>
              <TableRow >
                <TableCell className={classes.thStyle}>Chai정산일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.receveBaseDate)}</TableCell>
                <TableCell className={classes.thStyle}>수신일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.receveSeq}</TableCell>
                <TableCell className={classes.thStyle}>계정구분</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('accountDivisionCode', detailInfo.accountDivisionCode)}({detailInfo.accountDivisionCode})</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>BC매입일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.receveDate)}</TableCell>
                <TableCell className={classes.thStyle}>BC정산일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.settlementDate)}</TableCell>
                <TableCell className={classes.thStyle}>입금일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.depositDate)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>청구일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.billDate)}</TableCell>
                <TableCell className={classes.thStyle}>매출종류</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('salesKindDivisionCode', detailInfo.salesKindDivisionCode)}({detailInfo.salesKindDivisionCode})</TableCell>
                <TableCell className={classes.thStyle}>매입접수번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.purchasedProcessNo}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>가맹점번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantNo}</TableCell>
                <TableCell className={classes.thStyle}>카드순번</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cardSeq}</TableCell>
                <TableCell className={classes.thStyle}>카드번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cardNoMasked}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>체크카드회원번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cardMemberNo}</TableCell>
                <TableCell className={classes.thStyle}>고객번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.userId}</TableCell>
                <TableCell className={classes.thStyle}>카드승인번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cardApprovalNo}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>거래일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.salesDate)}</TableCell>
                <TableCell className={classes.thStyle}>순매출금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.salesNetAmount)}</TableCell>
                <TableCell className={classes.thStyle}>봉사료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.serviceAmount)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>세금금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.taxAmount)}</TableCell>
                <TableCell className={classes.thStyle}>매출총액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.salesTotalAmount)}</TableCell>
                <TableCell className={classes.thStyle}>할부월수</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.installmentMonth}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>카드상품코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cardProductCode}</TableCell>
                <TableCell className={classes.thStyle}>관리은행지점코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.managementBranchCode}</TableCell>
                <TableCell className={classes.thStyle}>가맹점명</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantName}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>가맹점사업자등록번호</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.bizNoFormat(detailInfo.merchantBizNo)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.merchantFee)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점수수료율</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantFeeRate}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>발행회사보전수수료율</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.issuerFeeRate}</TableCell>
                <TableCell className={classes.thStyle}>가맹점부담할부수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.merchantInstallmentFee)}</TableCell>
                <TableCell className={classes.thStyle}>통신판매수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.onlineSalesFee)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>가맹점이용수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.merchantUseFee)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점이용BC대행수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.merchantUseBcFee)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점업종코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantBusinessCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>정산용도업종코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.settlementBusinessCode}</TableCell>
                <TableCell className={classes.thStyle}>정산용도수수료율</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.settlementFeeRate}</TableCell>
                <TableCell className={classes.thStyle}>매입정산수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.purchasedSettlementFee)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>이용수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.useFee)}</TableCell>
                <TableCell className={classes.thStyle}>체크매출선지급실제금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.checkSalesPrepayAmount)}</TableCell>
                <TableCell className={classes.thStyle}>세이브매출구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.saveSalesDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>가맹점공제별도수수료</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.merchantReductionExtFee)}</TableCell>
                <TableCell className={classes.thStyle}>할부구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.installmentDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>할부번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.installmentNo}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>리볼빙구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.revolvingDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>리볼빙번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.revolvingNo}</TableCell>
                <TableCell className={classes.thStyle}>승인서비스구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.approvalServiceDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>회원서비스구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.userServiceDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>공동이용매출구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('commonMerchantUseDivisionCode', detailInfo.commonMerchantUseDivisionCode)}({detailInfo.commonMerchantUseDivisionCode})</TableCell>
                <TableCell className={classes.thStyle}>체크매출구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('checkSalesDivisionCode', detailInfo.checkSalesDivisionCode)}({detailInfo.checkSalesDivisionCode})</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>판매적용매출구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.salesApplySalesDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>원매입접수번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.originReceveNo}</TableCell>
                <TableCell className={classes.thStyle}>원매출일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.originSalesDate)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>PG사업자등록번호</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.bizNoFormat(detailInfo.pgBizNo)}</TableCell>
                <TableCell className={classes.thStyle}>가맹점구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>체크매출선지급구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.checkSalesPrepayDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>체크매출선지급계좌번호</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.accountMaskFormat(detailInfo.checkSalesPrepayAccountNo)}</TableCell>
                <TableCell className={classes.thStyle}>별도정산구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.extSettlementDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>별도승인구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.extApprovalDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>소득공제유무코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.taxReductionYn}</TableCell>
                <TableCell className={classes.thStyle}>무이자할부구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.noInstallmentDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>매출할인구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.salesDiscountDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>매출취소구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.salesCancelDivisionCode}</TableCell>
                <TableCell className={classes.thStyle}>매출할인총액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.salesDiscountAmount)}</TableCell>
                <TableCell className={classes.thStyle}>BC부담할인금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.salesBcResponseAmount)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>제휴사부담할인금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.salesPartnerResponseAmount)}</TableCell>
                <TableCell className={classes.thStyle}>정산처리여부</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.settlementProcessYn}</TableCell>
                <TableCell className={classes.thStyle}>실차감포인트사용금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.pointDeductionUseAmount)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>실차감회원포인트사용금액</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.pointDeductionUserUseAmount)}</TableCell>
                <TableCell className={classes.thStyle}>현장할인인증번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.merchantDeductionAuthNo}</TableCell>
                <TableCell className={classes.thStyle}>보조금구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.supportDivisionCode}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>데이터보정처리여부</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.purchasedDataProcessYn}</TableCell>
                <TableCell className={classes.thStyle}>보조금액정산입금년월</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormat(detailInfo.supportAmountSettlementYm, 'yyyy-MM')}</TableCell>
                <TableCell className={classes.thStyle}>선정산일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.preSettlementDate)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>취소선지급중복처리여부</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.cancelPrepayProcessYn}</TableCell>
                <TableCell className={classes.thStyle}>매입처리일자</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.dateFormatDefault(detailInfo.purchasedProcessDate)}</TableCell>
                <TableCell className={classes.thStyle}>서버파일명</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.fileName}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>수정단말번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.changeTerminalNo}</TableCell>
                <TableCell className={classes.thStyle}>입출금처리코드</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('inoutProcessCode', detailInfo.inoutProcessCode)}({detailInfo.inoutProcessCode})</TableCell>
                <TableCell className={classes.thStyle}>입출금처리일련번호</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.inoutProcessSeq}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>계정처리순번</TableCell>
                <TableCell className={classes.tdStyle}>{detailInfo.accountProcessSeq}</TableCell>
                <TableCell className={classes.thStyle}>카드구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('cardTypeCode', detailInfo.cardTypeCode)}({detailInfo.cardTypeCode})</TableCell>
                <TableCell className={classes.thStyle}>자료처리구분코드</TableCell>
                <TableCell className={classes.tdStyle}>{chaiStringUtils.codeName('dataProcessDivisionCode', detailInfo.dataProcessDivisionCode)}({detailInfo.dataProcessDivisionCode})</TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={classes.thStyle}>결제ID</TableCell>
                <TableCell className={classes.tdStyle} colSpan={5} >{detailInfo.paymentId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
        </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">닫기</Button>
      </DialogActions>
    </Dialog>  
  );
};

export default PurchasedHistoryDetail;



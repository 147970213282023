import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  CardHeader,
  Divider,
  Tooltip,
  Link
} from '@material-ui/core';
import MasterMerchantInfoMgmtInsertPop from './MasterMerchantInfoMgmtInsertPop';
import MasterMerchantInfoMgmtUpdatePop from './MasterMerchantInfoMgmtUpdatePop';
import apolloClient from '../../lib/apolloClient';
import { masterMerchantMgmtMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
}));

type SearchInfo = {
  inputMasterMerchantNo: string,     // 대표가맹점번호
  inputMasterMerchantName: string,   // 대표가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string,         // 가맹점명  
  inputCancelYn: string             // 해지여부
}

type Props = {
  masterMerchantList: MasterMerchantMgmtDetail[],
  setInputDetailMasterMerchant: React.Dispatch<React.SetStateAction<MasterMerchantMgmtDetail>>,
  searchForm: SearchInfo,
  fetch: any
}

const MasterMerchantListTable: FC<Props> = ({ masterMerchantList, setInputDetailMasterMerchant, searchForm, fetch }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 가맹점 번호 클릭 시 상세화면
  const handleDetailClick = (obj: MasterMerchantMgmtDetail) => {
    setInputDetailMasterMerchant(obj);
  }

  // 신규등록 팝업
  const [insertPop, setInsertPop] = useState(false);
  // 대표가맹점 Entity
  const [masterMerchantInfo, setMasterMerchantInfo] = useState({
    masterMerchantNo: '',                              // 대표가맹점번호
    masterMerchantName: '',                            // 대표가맹점명
  });
  const handleInsertPop = async () => {
    // 맥스순번 채번
    await apolloClient.mutate({
      variables: {
      },
      mutation: masterMerchantMgmtMutation.selectMasterMerchantMgmtMaxNoGet
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectMasterMerchantMgmtMaxNoGet.masterMerchantNoMax);
      setMasterMerchantInfo({
        masterMerchantNo: res.data.selectMasterMerchantMgmtMaxNoGet.masterMerchantNoMax,  // 대표가맹점번호
        masterMerchantName: '',                                      // 대표가맹점명
      })
    }).catch(console.error);

    // 팝업 오픈
    await setInsertPop(true);
  };

  // 더블클릭 시 수정 팝업
  const [updatePop, setUpdatePop] = useState(false);
  const [masterMerchantDetailInfo, setMasterMerchantDetailInfo] = useState({
    masterMerchantNo: '',
    masterMerchantName: ''
  });
  const handleRowClick = async (obj: MasterMerchantMgmtDetail) => {
    console.log('수정팝업: ', obj);
   
    await setMasterMerchantDetailInfo({
      masterMerchantNo: obj.masterMerchantNo,
      masterMerchantName: obj.masterMerchantName
    });
    setUpdatePop(true);
  };

  if(masterMerchantList.length === 0) {
    return (
      <div>
      <Card>
        <CardHeader title="대표가맹점"/>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button className={classes.registerButton}
          color="primary"
          variant="contained"
          onClick={() => handleInsertPop()}
        >            
          NEW
        </Button>
      </Card>
    <MasterMerchantInfoMgmtInsertPop 
      insertPop={insertPop} 
      setInsertPop={setInsertPop} 
      searchForm={searchForm} 
      fetch={fetch} 
      masterMerchantInfo={masterMerchantInfo} 
      setMasterMerchantInfo={setMasterMerchantInfo}/>
      </div>
    );
  }

  return (
    <div>
    <Card>
      <CardHeader title="대표가맹점 목록"/>
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  
                  <TableCell align="center">대표가맹점번호</TableCell>
                  <TableCell align="center">대표가맹점명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {masterMerchantList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((masterMerchant, index) => (
                  <Tooltip title="더블클릭 상세수정" key={index}>
                    <TableRow
                      hover
                      key={index}
                      className={classes.cursor_pointer}
                      onDoubleClick={() => handleRowClick(masterMerchant)}
                    >
                     
                      <TableCell align="center">
                        <Link component="button" variant="body1" onClick={() => handleDetailClick(masterMerchant as MasterMerchantMgmtDetail)}>
                          {masterMerchant.masterMerchantNo}
                        </Link>
                      </TableCell>
                      <TableCell align="center">{masterMerchant.masterMerchantName}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={masterMerchantList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>

      <Button className={classes.registerButton}
        color="primary"
        variant="contained"
        onClick={() => handleInsertPop()}
      >            
        NEW
      </Button>
    </Card>
    <MasterMerchantInfoMgmtInsertPop 
      insertPop={insertPop} 
      setInsertPop={setInsertPop} 
      searchForm={searchForm} 
      fetch={fetch} 
      masterMerchantInfo={masterMerchantInfo} 
      setMasterMerchantInfo={setMasterMerchantInfo}/>
    <MasterMerchantInfoMgmtUpdatePop updatePop={updatePop} setUpdatePop={setUpdatePop} masterMerchantDetail={masterMerchantDetailInfo} setMasterMerchantDetailInfo={setMasterMerchantDetailInfo} fetch={fetch} searchForm={searchForm}/>
    </div>
  )
}

export default MasterMerchantListTable;
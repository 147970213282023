import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));
**/

type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputCancelYn: string             // 해지여부
}

type Props = {
  fetch: any,
  searchForm: SearchInfo
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}
const GroupMerchantMgmtToolbar: FC<Props> = ({ fetch, searchForm, setSearchForm }) => {
  // const classes = useStyles();

  // 변경이벤트
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 목록 조회
  const onSearchClick = async () => {
    console.log('searchForm: ', searchForm);
    fetch(searchForm);
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={1}
            xs={3}
          >
            <TextField
              select
              fullWidth
              label="구분"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputGubun"
              value={searchForm.inputGubun}
              onChange={handleChange}
            >
              <option value="">전체</option>
              <option value="BC">BC</option>
              <option value="CHAI">CHAI</option>
            </TextField>
          </Grid>
          <Grid
            item
            md={1}
            xs={3}
          >
            <TextField
              select
              fullWidth
              label="해지여부"
              margin="dense"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputCancelYn"
              value={searchForm.inputCancelYn}
              onChange={handleChange}
            >
              <option value="">전체</option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="그룹가맹점번호 8자리"
              placeholder="그룹가맹점번호 8자리"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputGroupMerchantNo"
              value={searchForm.inputGroupMerchantNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="그룹가맹점명"
              placeholder="그룹가맹점명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputGroupMerchantName"
              value={searchForm.inputGroupMerchantName}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="가맹점번호 9자리"
              placeholder="가맹점번호 9자리"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputMerchantNo"
              value={searchForm.inputMerchantNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          {/* <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              fullWidth
              label="가맹점명"
              placeholder="가맹점명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inputMerchantName"
              value={searchForm.inputMerchantName}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid> */}
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
        
        <Grid
          container
          spacing={1}
        >
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GroupMerchantMgmtToolbar;
import gql from 'graphql-tag';

export const selectApprovalHistoryLogAggr = gql`
  mutation selectApprovalHistoryLogAggr ($input : ApprovalHistoryLogAggrSearch){
    selectApprovalHistoryLogAggr (input : $input) {
      tradeDate
      aggregationDivisionCode
      cardProductCode
      cardProductName
      approvalCount
      approvalAmount
      cancelCount
      cancelAmount
      inabledCount
      inabledAmount
      bcRejectCount
      bcRejectAmount
    }
  }
`;



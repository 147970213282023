import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<SettlementListSearch>>
}

const SettlementListToolbar: FC<Props> = ({onSearch}) => {  

  const [inputSearch, setInputSearch] = useState({
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch({
      ...inputSearch,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    onSearch(inputSearch);
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="정산시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDate"
                value={inputSearch.startDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="정산종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDate"
                value={inputSearch.endDate}
                onChange={handleChange}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default SettlementListToolbar;

import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Drawer, DrawerProps } from '@material-ui/core';
import SidebarNav from './SidebarNav';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import { AttachMoney } from '@material-ui/icons';
import store from 'store';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 47px - 64px)',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  className?: string;
  onClose?: () => void;
  open: boolean;
  variant: DrawerProps['variant'];
};

const Sidebar: FC<Props> = ({ open, variant, onClose, className }) => {
  const classes = useStyles();

  let pages: {
    key: string;
    title: string;
    nodes: { key: string; title: string; href: string }[];
    icon: any;
  }[] = [];

  const pagesAdmin = [
    {
      key: 'product',
      title: '상품',
      icon: <AccountTreeIcon />,
      nodes: [
        {
          key: 'product1',
          title: '상품정보관리',
          href: '/ProductManagement',
        },
        {
          key: 'product2',
          title: 'BIN정보관리',
          href: '/CardBin',
        },
        {
          key: 'product3',
          title: '대행수수료 코드관리',
          href: '/proxyFeeCodeManagement',
        },
        {
          key: 'product4',
          title: '환율정보 조회',
          href: '/ExchangeRate',
        },
        {
          key: 'product5',
          title: '해외민원환불',
          href: '/OverseasCsRefund',
        },
        {
          key: 'product6',
          title: '해외수수료기준관리',
          href: '/CardOverseaFeeBaseMgmt',
        },
      ],
    },
    {
      key: 'merchant',
      title: '가맹점',
      icon: <StoreIcon />,
      nodes: [
        {
          key: 'merchant1',
          title: 'BC 가맹점정보',
          href: '/merchantList',
        },
        {
          key: 'merchant5',
          title: '하나 가맹점정보',
          href: '/PLCC/merchantList',
        },
        // {
        //   key: 'merchant2',
        //   title: '그룹 가맹점정보',
        //   href: '/groupMerchantList'
        // },
        {
          key: 'merchant3',
          title: '그룹 가맹점관리',
          href: '/MerchantGroupMgmt',
        },
        {
          key: 'merchant4',
          title: '대표 가맹점관리',
          href: '/MerchantMasterMgmt',
        },
      ],
    },
    {
      key: 'mbr',
      title: '회원카드',
      icon: <CreditCardIcon />,
      nodes: [
        {
          key: 'mbr1',
          title: '카드 발급신청',
          href: '/addcard',
        },
        // {
        //   key: 'mbr2',
        //   title: 'Customer',
        //   href: '/customer'
        // },
        {
          key: 'mbr2',
          title: '회원정보 관리',
          href: '/cardMember',
        },
        {
          key: 'mbr3',
          title: '발급집계내역 조회',
          href: '/aprvAgrg',
        },
        {
          key: 'mbr4',
          title: 'VIP 카드번호 관리',
          href: '/vipCardInfo',
        },
        // {
        //   key: 'mbr6',
        //   title: 'Card Detail',
        //   href: '/carddetail'
        // },
        {
          key: 'mbr5',
          title: '카드 발급/배송 조회',
          href: '/cardDeliveryInfo',
        },
        {
          key: 'mbr6',
          title: '반송카드관리',
          href: '/cardReturnManage',
        },
      ],
    },
    {
      key: 'plcc',
      title: '신용카드',
      icon: <CreditCardIcon />,
      nodes: [
        {
          key: 'plcc1',
          title: '승인 내역 조회',
          href: '/PLCC/ApprovalList',
        },
        {
          key: 'plcc2',
          title: '매입 내역 조회',
          href: '/PLCC/PurchasedList',
        },
        {
          key: 'plcc6',
          title: '매입 집계내역 조회',
          href: '/PLCC/AggrHistory',
        },
        {
          key: 'plcc4',
          title: '가맹점 매핑',
          href: '/PLCC/MerchantMapping',
        },
        {
          key: 'plcc5',
          title: '가맹점 매핑 변경내역',
          href: '/PLCC/MerchantMappingHistory',
        },
      ],
    },
    {
      key: 'approval',
      title: '승인사고',
      icon: <ShoppingCartIcon />,
      nodes: [
        {
          key: 'approval1',
          title: '사고관리',
          href: '/accidentMgmt',
        },
        {
          key: 'approval2',
          title: '국내승인내역 조회',
          href: '/approvalList',
        },
        {
          key: 'approval3',
          title: '국내승인집계내역 조회',
          href: '/approvalAggr',
        },
        {
          key: 'approval4',
          title: '국내승인대사내역 조회',
          href: '/approvalHistoryLog',
        },
        {
          key: 'approval5',
          title: '이용내역 조회',
          href: '/purchasedTypeHistory',
        },
        // ,
        // {
        //   key: 'approval6',
        //   title: '국내승인대사로그집계 조회',
        //   href: '/approvalHistoryLogAggr'
        // }
        {
          key: 'overseasApproval1',
          title: '해외승인내역 조회',
          href: '/OverseasApprList',
        },
        {
          key: 'overseasApproval2',
          title: '해외승인대사내역 조회',
          href: '/OverseasApprHistoryLogList',
        },
      ],
    },
    {
      key: 'purchase',
      title: '매입',
      icon: <LibraryBooksIcon />,
      nodes: [
        {
          key: 'purchase1',
          title: '국내매입내역 조회',
          href: '/purchasedHistory',
        },
        {
          key: 'purchase2',
          title: '국내매입집계 조회',
          href: '/purchasedAggrHistory',
        },
        {
          key: 'purchase3',
          title: '온라인사업자재정산내역 조회',
          href: '/onlineBuismHistory',
        },
        {
          key: 'purchase4',
          title: 'EDI취소선지급내역 조회',
          href: '/cancelEdiPayHistory',
        },
        {
          key: 'settlement4',
          title: '미입출금내역 조회',
          href: '/nonInoutInfo',
        },
        {
          key: 'purchase5',
          title: '매출내역 조회',
          href: '/salesDetails',
        },
        {
          key: 'overseasPurchase',
          title: '해외 매입 내역',
          href: '/OverseasPurchasedHistory',
        },
        {
          key: 'overseasPurchaseAggr',
          title: '해외매입집계 조회',
          href: '/OverseasPurchasedAggrHistory',
        },
        {
          key: 'overseasPurchaseAdjust',
          title: '해외 매입 조정',
          href: '/OverseasPurchasedHistoryAdjust',
        },
      ],
    },
    {
      key: 'settlement',
      title: '정산',
      icon: <LocalAtmIcon />,
      nodes: [
        {
          key: 'settlement5',
          title: '국내정산대금내역 조회',
          href: '/settlement',
        },
        {
          key: 'settlement3',
          title: '국내정산집계 조회',
          href: '/settlementAggrList',
        },
        {
          key: 'settlement2',
          title: '대행수수료 정산내역 조회',
          href: '/settlementAuto',
        },
        {
          key: 'settlement4',
          title: '대행수수료 자동대사 조회',
          href: '/SettlementProxyFee',
        },
        //, {
        // key: 'settlement6',
        //  title: '해외미입출금내역조회',
        //  href: '/OvrsNonInoutHistory'
        //},
        {
          key: 'settlement7',
          title: '해외이용수수료내역조회',
          href: '/OverseasUseFeeHistory',
        },
        {
          key: 'settlement8',
          title: '해외정산조회',
          href: '/OverseasCalculate',
        },
      ],
    },
    {
      key: 'account',
      title: '회계',
      icon: <AttachMoney />,
      nodes: [
        {
          key: 'account1',
          title: '계정처리집계 조회',
          href: '/accountProcAggr',
        },
        {
          key: 'account2',
          title: '계정처리내역 조회',
          href: '/accountProcList',
        },
      ],
    },
    {
      key: 'common',
      title: '시스템',
      icon: <PermDataSettingIcon />,
      nodes: [
        {
          key: 'common1',
          title: '공통코드관리',
          href: '/codeMgmt',
        },
        {
          key: 'common2',
          title: '배치관리',
          href: '/batchInfo',
        },
        {
          key: 'common3',
          title: '대외송수신로그 조회',
          href: '/foreignSndrvLogList',
        },
        {
          key: 'common4',
          title: '휴일정보관리',
          href: '/holidayInformation',
        },
      ],
    },
  ];

  const admin = store.get('admin');
  if (admin) {
    if (admin?.data?.auth?.permission?.permissions.includes('admin')) {
      pages = pagesAdmin;
    } else if (admin?.data?.auth?.permission?.isCustomerService) {
      // CS 어드민 메뉴 필터링
      pages = pagesAdmin
        .filter(page => {
          return ['merchant', 'mbr', 'approval', 'purchase', 'plcc'].includes(page.key);
        })
        .map(page => {
          return {
            ...page,
            nodes: page.nodes.filter(item => {
              return [
                'merchant1',
                'merchant2',
                'mbr1',
                'mbr2',
                'mbr5',
                'mbr6',
                'approval1',
                'approval2',
                'approval5',
                'purchase1',
                'purchase4,',
                'settlement4',
                'overseasApproval1',
                'overseasApproval2',
                'overseasPurchase',
                'plcc1',
                'plcc2',
              ].includes(item.key);
            }),
          };
        });
    }
  }

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

export default Sidebar;

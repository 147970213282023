import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format
  // , subMonths
} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';

type Props = {
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  searchInfo: ApprovalSearchInfo,
  onSearch: React.Dispatch<React.SetStateAction<ApprovalSearchInfo>>
}

const ApprovalListToolbar: FC<Props> = ({paging, setPaging, searchInfo, onSearch}) => {  

  const default_page = 0;
  const default_rowsPerPage = 10;

  let startDate = new Date();
  let endDate = new Date();
  if (searchInfo.startDt) {
    const startDt = searchInfo.startDt.replace(/[^0-9]/g,'');
    startDate = new Date (Number(startDt.substring(0,4)), Number(startDt.substring(4,6))-1, Number(startDt.substring(6,8)));
  }
  if (searchInfo.endDt) {
    const endDt = searchInfo.endDt.replace(/[^0-9]/g,'');
    endDate = new Date (Number(endDt.substring(0,4)), Number(endDt.substring(4,6)) -1, Number(endDt.substring(6,8)));
  }

  const [searchForm, setSearchForm] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDt: format(startDate, 'yyyy-MM-dd'),
    endDt: format(endDate, 'yyyy-MM-dd'),
    salesKindDivisionCode: '',
    approvalProcessStatusCode: '',
    cardApprovalNo: '',
    paymentId: searchInfo.paymentId ? searchInfo.paymentId : '',
    adminErrorCode: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    setPaging({
      ...paging,
      page: default_page,
      rowsPerPage : default_rowsPerPage
    })
    // 공통파라미터 저장
    chaiStringUtils.setSchParamGubun(searchForm.searchType);
    chaiStringUtils.setSchParamSearchVal(searchForm.searchType, searchForm.searchValue);
    onSearch(searchForm);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };
  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="검색조건"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchType"
                value={searchForm.searchType}
                onChange={handleChange}
              >
                <option value="1">휴대폰번호</option>
                <option value="2">카드번호</option>
                <option value="3">고객번호</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="검색값"
                placeholder="검색값"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="searchValue"
                value={searchForm.searchValue}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="승인시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={searchForm.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="승인종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={searchForm.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
          >              
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="매출종류"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="salesKindDivisionCode"
                value={searchForm.salesKindDivisionCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="05">정상매출</option>
                <option value="15">취소매출</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="처리구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="approvalProcessStatusCode"
                value={searchForm.approvalProcessStatusCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="1">정상</option>
                <option value="2">오류</option>
                <option value="9">처리중</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="카드승인번호"
                placeholder="카드승인번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardApprovalNo"
                value={searchForm.cardApprovalNo}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="머니승인번호"
                placeholder="머니승인번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="paymentId"
                value={searchForm.paymentId}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="오류코드"
                placeholder="오류코드"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="adminErrorCode"
                value={searchForm.adminErrorCode}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>            
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default ApprovalListToolbar;

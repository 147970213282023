import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subMonths} from 'date-fns';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<OverseasCsTrmSearch>>
}

const OverseasCsTrmMgmtToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {  

  const [inputSearch, setInputSearch] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),   
    overseasCsTrmSeq: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch({
      ...inputSearch,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onSearch(inputSearch);
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="전금시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={inputSearch.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>          
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="전금종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={inputSearch.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="해외민원전금일련번호"
                placeholder="해외민원전금일련번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="overseasCsTrmSeq"
                value={inputSearch.overseasCsTrmSeq}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>  
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>                      
          </Grid>
        </CardContent>
      </form>
    </Card>    
  );
};

export default OverseasCsTrmMgmtToolbar;

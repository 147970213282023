import React , {useState,FC} from 'react';

import{
    Card,
    CardHeader,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

type Props ={
    setProductMgmt: React.Dispatch<React.SetStateAction<ProductMgmt>>,
    productMgmt: ProductMgmt[],
    setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>,
    setHistoryInput: React.Dispatch<React.SetStateAction<any>>
}
  
const ProductList : FC<Props> = ({ productMgmt, setProductMgmt, setUpdateClick, setHistoryInput }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    
    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
      };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
      };  

    function fnGetHistory(item: ProductMgmt) {
      setHistoryInput(item);
    }
    
    const brandDivisionCodeMap = new Map<String, String>();
    brandDivisionCodeMap.set('0', 'GLOBAL BC');
    brandDivisionCodeMap.set('1', 'LOCAL');
    brandDivisionCodeMap.set('2', 'MC');
    brandDivisionCodeMap.set('3', 'VISA');
    brandDivisionCodeMap.set('4', 'JCB');

return (
        <Card>
            <CardHeader title="<상품목록>"/>      
            <PerfectScrollbar>     
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>카드상품그룹코드</TableCell>
                <TableCell>카드상품코드</TableCell>
                <TableCell>카드상품코드명</TableCell>
                <TableCell>브랜드</TableCell>
                <TableCell>사용여부</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {productMgmt.slice(page * rowsPerPage, (page + 1) * (rowsPerPage)).map((item, idx) => (

            <TableRow
                onClick={()=> {setProductMgmt(item); setUpdateClick(true); fnGetHistory(item);}}
                hover
                key={item.cardProductCode}>
                <TableCell>{(page * rowsPerPage) + idx+1}</TableCell>
                <TableCell>{item.cardProductGroupCode}</TableCell>
                <TableCell>{item.cardProductCode}</TableCell>
                <TableCell>{item.cardProductCodeName}</TableCell>
                <TableCell>{brandDivisionCodeMap.get(item.brandDivisionCode)}</TableCell>
                <TableCell>{item.useYn}</TableCell>
            </TableRow>                
            ))}
            </TableBody>
        </Table>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={productMgmt.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        </Card>
);
};
export default ProductList; 

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { groupMerchantMgmtMutation } from '../../lib/apolloClient/mutations';
import * as utils from '../../common/utils';
import GroupMerchantMgmtToolbar from './GroupMerchantMgmtToolbar';
import GroupMerchantMgmtListTable from './GroupMerchantMgmtListTable';
import GroupMerchantMgmtDetailListTable from './GroupMerchantMgmtDetailListTable';
import { MenuTitleToolbar } from '../../components';
import ExcelUploadPop from './ExcelUploadPop';
import ExportIntoExcel from './ExportIntoExcel';

import {
  Button,
} from '@material-ui/core';
import { ExportGroupMerchantMgmtListTableInfoExcel } from './ExportGroupMerchantMgmtListTableInfoExcel';
import { ExportGroupMerchantMgmtDetailListTableInfoExcel } from './ExportGroupMerchantMgmtDetailListTableInfoExcel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
}));

type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputCancelYn: string             // 해지여부
}

type Props ={ className?:string, groupMerchantDetail: GroupMerchantMgmtDetail }
const GroupMerchantMgmt: FC<Props> = ({className, groupMerchantDetail}) => {
  const classes = useStyles();
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    inputGroupMerchantNo: '',     // 그룹가맹점번호
    inputGroupMerchantName: '',   // 그룹가맹점명
    inputMerchantNo: '',          // 가맹점번호
    inputMerchantName: '',        // 가맹점명
    inputGubun: '',               // 차이 / 비씨 구분
    inputCancelYn: ''             // 해지여부
  });

  useEffect(() => {
    fetch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 그룹가맹점 목록 조회 로직
  const fetch = async (searchFormInput: SearchInfo) => {
    console.log('그룹가맹점목록 조회');

    // 상세목록 초기화 
    setInputDetailGroupMerchant({} as GroupMerchantMgmtDetail);

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        inputGroupMerchantNo: searchFormInput.inputGroupMerchantNo, 
        inputGroupMerchantName: searchFormInput.inputGroupMerchantName,
        inputMerchantNo: searchFormInput.inputMerchantNo, 
        inputGubun: searchFormInput.inputGubun,
        inputCancelYn: searchFormInput.inputCancelYn
      },
      mutation: groupMerchantMgmtMutation.selectGroupMerchantMgmtList
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectGroupMerchantMgmtList);
      setGroupList(res.data.selectGroupMerchantMgmtList);
    }).catch(console.error);

    setLoading(false);
  };

  // 가맹점 목록 조회
  const [inputDetailGroupMerchant, setInputDetailGroupMerchant] = useState({} as GroupMerchantMgmtDetail);
  const [loading2, setLoading2] = useState(false);
  const [detailMerchantList, setDetailMerchantList] = useState([]);
  useEffect(() => {
    console.log('가맹점 목록 조회 INPUT: ', inputDetailGroupMerchant.groupMerchantNo);
    detailFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDetailGroupMerchant]);

  // 가맹점 목록 조회 fetch
  const detailFetch = async () => {
    // 이미 로딩 중이면 리턴
    if(loading2) {
      return;
    }
    
    setLoading2(true);

    await apolloClient.mutate({
      variables: {
        inputGroupMerchantNo: inputDetailGroupMerchant.groupMerchantNo,
        inputCancelYn: searchForm.inputCancelYn
      },
      mutation: groupMerchantMgmtMutation.selectGroupMerchantMgmtDetailList
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectGroupMerchantMgmtDetailList);
      setDetailMerchantList(res.data.selectGroupMerchantMgmtDetailList);
    }).catch(console.error);

    setLoading2(false);
  }

  // 엑셀업로드 팝업
  const [uploadPop, setUploadPop] = useState(false);
  const handleUploadPop = () => {
    // 팝업 오픈
    setUploadPop(true);
  };

  // 엑셀양식다운로드
  const [clicked, setClicked] = useState(false); // 데이터 excel 출력을 위한 버튼 클릭 확인 state

  // 그룹가맹점마스터목록 엑셀 다운로드
  const [listExcelClicked, setListExcelClicked] = useState(false);

  // 그룹가맹점목록 엑셀 다운로드
  const [detailListExcelClicked, setDetailListExcelClicked] = useState(false);
  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="그룹가맹점관리" />
      <GroupMerchantMgmtToolbar  fetch={fetch} searchForm={searchForm} setSearchForm={setSearchForm}/>
      
      <ExcelUploadPop uploadPop={uploadPop} setUploadPop={setUploadPop} searchForm={searchForm} fetch={fetch}/>
      
      <ExportGroupMerchantMgmtListTableInfoExcel 
        className={classes.registerButton}
        csvData={groupList}
        clicked={listExcelClicked}
        setClicked={setListExcelClicked}
      />

      <Button className={classes.registerButton} 
        color="primary" 
        variant="contained"
        onClick={handleUploadPop}
      >            
        엑셀업로드POP
      </Button>

      <ExportIntoExcel
        className={classes.registerButton}
        clicked={clicked}
        setClicked={setClicked}
      />

      <br/>
      <br/>

      <div className={classes.content}>
        {loading
          ? 
            utils.Loading()
          :
            <GroupMerchantMgmtListTable groupMerchantList={groupList} setInputDetailGroupMerchant={setInputDetailGroupMerchant} searchForm={searchForm} fetch={fetch} />
        }
        
        <ExportGroupMerchantMgmtDetailListTableInfoExcel 
          className={classes.registerButton}
          csvData={detailMerchantList}
          clicked={detailListExcelClicked}
          setClicked={setDetailListExcelClicked}
        />
        <br/>
        <br/>
        <br/>
        {loading2
          ? utils.Loading()
          : <GroupMerchantMgmtDetailListTable detailMerchantList={detailMerchantList} searchForm={searchForm} fetch={fetch} inputDetailGroupMerchant={inputDetailGroupMerchant}/>
        }
      </div>
    </div>
  );
}

export default GroupMerchantMgmt;
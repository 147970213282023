import client from './client';
import { get, orderBy } from 'lodash';

export default async (input: GetApprovalListInput): Promise<GetApprovalListReturn[]> => {
  return client.post(`/root/cardApvl/list`, input).then(res => {
    console.log(input)
    console.log(res)
    const approvals = get(res, 'data.apvl_his');
    if (!approvals) {
      return [];
      // throw new Error(`승인정보 조회 실패`)
    }
    return orderBy(approvals, ['oprtDtlDtime'], ['desc']);
  }).catch(e => {
    console.log(e)
    throw e 
  });
}
import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  thSumStyle: {
    backgroundColor: '#fafafa',
  },  
}));

type Props = { 
  settlementAggrList: SettlementAggrInfo[], 
}

const SettlementAggrTable: FC<Props> = ({ settlementAggrList }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const getThStyle = (item: SettlementAggrInfo) => {

    if (item.aggregationDate === '99999999') {
      if (item.accountDivisionCode === '000') {
        return classes.thStyle;
      }
      else {
        return '';
      }
    }
    else {
      if (item.accountDivisionCode === '000') {
        return classes.thSumStyle;
      }
      else {
        return '';
      }
    }
  }; 

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' rowSpan={3}>집계일자</TableCell> 
                  <TableCell align='center' rowSpan={3}>항목</TableCell>
                  <TableCell align='center' colSpan={4}>가맹점대금</TableCell>
                  <TableCell align='center' colSpan={4}>가맹점수수료</TableCell> 
                  <TableCell align='center' colSpan={4}>비용(BC대행수수료)</TableCell>
                  <TableCell align='center' colSpan={4}>차이매출</TableCell> 
                  <TableCell align='center' rowSpan={3}>차액</TableCell>                           
                </TableRow>
                <TableRow>
                  <TableCell align='center' colSpan={2}>BC지급</TableCell>
                  <TableCell align='center' colSpan={2}>당사입금</TableCell>   
                  <TableCell align='center' colSpan={2}>수익발생</TableCell>  
                  <TableCell align='center' colSpan={2}>수익취소</TableCell>
                  <TableCell align='center' colSpan={2}>비용발생</TableCell>  
                  <TableCell align='center' colSpan={2}>비용취소</TableCell>
                  <TableCell align='center' colSpan={2}>매출발생</TableCell>  
                  <TableCell align='center' colSpan={2}>매출취소</TableCell>                                                     
                </TableRow>
                <TableRow>
                  <TableCell align='center'>건수</TableCell>
                  <TableCell align='center'>금액</TableCell>   
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell> 
                  <TableCell align='center'>건수</TableCell>
                  <TableCell align='center'>금액</TableCell>   
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell>
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell> 
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell>  
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell> 
                  <TableCell align='center'>건수</TableCell>  
                  <TableCell align='center'>금액</TableCell>                                                                                     
                </TableRow>                                   
              </TableHead>
              <TableBody>
                {settlementAggrList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                    <TableRow
                      hover
                      key={item.aggregationDate+''+item.accountDivisionCode}
                      className={getThStyle(item)}
                    >
                    {item.aggregationDate === '99999999' ?
                      <TableCell align='center' colSpan={2}>{'합계'}</TableCell>
                    : 
                      <TableCell align='center'>{chaiStringUtils.dateFormatDefault(item.aggregationDate)}</TableCell>
                    }
                    {item.aggregationDate === '99999999' ?
                      null
                      : 
                      <TableCell align='left'>{item.accountDivisionCode === '000' ? '소계' : chaiStringUtils.codeName('accountDivisionCode', item.accountDivisionCode)}</TableCell>
                    }
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.depositCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.depositTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalFeeCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalMerchantFeeTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.feeDepositCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.merchantFeeDepositTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalFeeCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalProxyFeeTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.feeDepositCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.proxyFeeDepositTotalAmount)}</TableCell>                                        
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalFeeCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.withdrawalFeeTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.feeDepositCount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.feeDepositTotalAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.tradeTotalAmount)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={settlementAggrList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
    </Card>
  );
};

export default SettlementAggrTable;

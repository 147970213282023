import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { cardBinMutation } from '../../lib/apolloClient/mutations';
import SearchCardBin from './SearchCardBin';
import CardBinList from './CardBinList';
import CardBinDetails from './CardBinDetails';
import CardBinHistory from './CardBinHistory';
import { MenuTitleToolbar } from '../../components';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));

const CardBin = () => {
    const classes = useStyles();
    const [data,setData] = useState([]);
    const [updateClick, setUpdateClick] = useState(false);
    const [cardBinNoSearch, setCardBinNoSearch] = useState({
        binNo : '',
        cardProductCode : '',
        brandDivisionCode : '',
        startCardMediaSeq : '',
        endCardMediaSeq : '',
        nowCardMediaSeq : '',
        useYn : '',
        endDate : '',
        enrollmentSeq : 0,
    });
    const [cardBinNo, setCardBinNo] = useState({
      binNo : '',
      cardProductCode : '',
      brandDivisionCode : '',
      startCardMediaSeq : '',
      endCardMediaSeq : '',
      nowCardMediaSeq : '',
      useYn : '',
      endDate : '',
      enrollmentSeq :0,
    });

    const [historyInput, setHistoryInput] = useState({
      binNo : '',
      startCardMediaSeq : '',
      endCardMediaSeq : '',
      nowCardMediaSeq : '',
      enrollmentSeq: 0,
    });

    const [history, setHistory] = useState([]);

    useEffect(() => {
      const fetch = async () => {
        console.log('BIN이력 조회 실행');
        await apolloClient.mutate({
          variables: {
            binNo : historyInput.binNo,
            startCardMediaSeq : historyInput.startCardMediaSeq,
            endCardMediaSeq : historyInput.endCardMediaSeq,
            nowCardMediaSeq : historyInput.nowCardMediaSeq,
          },
          mutation : cardBinMutation.selectCardBinNoHistory
        }).then(res => {
          console.log('나와라');
          console.log('이력조회 결과: ', res.data.selectCardBinNoHistory);
          setHistory(res.data.selectCardBinNoHistory);
        }).catch(console.error);
      }
      fetch()
    },[historyInput])
    

    useEffect(() => {
      const fetch = async () => {
        console.log('실행!');
        await apolloClient.mutate({
          variables: {
            binNo : cardBinNoSearch.binNo,
            cardProductCode : cardBinNoSearch.cardProductCode,
            brandDivisionCode : cardBinNoSearch.brandDivisionCode,
            startCardMediaSeq : cardBinNoSearch.startCardMediaSeq,
            endCardMediaSeq : cardBinNoSearch.endCardMediaSeq,
            nowCardMediaSeq : cardBinNoSearch.nowCardMediaSeq,
            useYn : cardBinNoSearch.useYn,
            endDate : cardBinNoSearch.endDate,
            enrollmentSeq : cardBinNoSearch.enrollmentSeq,
          },
          mutation: cardBinMutation.selectCardBinNo
        }).then(res => {
          console.log(res.data.selectCardBinNo)
          setData(res.data.selectCardBinNo);
        }).catch(console.error);
      }
      setHistoryInput({
        binNo : '',
        startCardMediaSeq : '',
        endCardMediaSeq : '',
        nowCardMediaSeq : '',
        enrollmentSeq: 0,
      });
      setCardBinNo({
        binNo : '',
        cardProductCode : '',
        brandDivisionCode : '',
        startCardMediaSeq : '',
        endCardMediaSeq : '',
        nowCardMediaSeq : '',
        useYn : '',
        endDate : '',
        enrollmentSeq :0,
      });
      fetch()
    }, [updateClick, cardBinNoSearch])

    return(
        <div className={classes.root}>
          <MenuTitleToolbar title="BIN정보관리" />
          <SearchCardBin onSearch={setCardBinNoSearch}></SearchCardBin>
          <div className={classes.content}>
            <CardBinDetails updateClick={updateClick} setUpdateClick={setUpdateClick} cardBinNo={cardBinNo} setCardBinNo={setCardBinNo} />
            <div className={classes.content}></div>
            <CardBinList setUpdateClick={setUpdateClick} cardBinNo={data} setCardBinNo={setCardBinNo} setHistoryInput={setHistoryInput} />
            <div className={classes.content}></div>
            <CardBinHistory data={history}/>
        
        </div>
        </div>
    );
}

export default CardBin;
import React, { FC } from 'react';
import { Card, CardContent, Grid, Button, TextField } from '@material-ui/core';

type SearchInfo = {
    settlementDate:string;    // 정산일자
}

type Props = {
    onSearch: React.Dispatch<React.SetStateAction<any>>,
    searchForm: SearchInfo,
    setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
};

const OverseasCalculateToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm}) => {

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        });
    };
    
    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
        if (event.key === "Enter"){
            onSearchClick();
        }
    }

    // 목록 조회
    const onSearchClick = async() => {
        onSearch(searchForm);
    }
    
    return (
        <Card>
         <CardContent>
           <Grid
             container
             spacing={1}
           >
             <Grid
               item
               md={2}
               xs={6}
             >
               <TextField
                 required
                 fullWidth
                 type="date"
                 label="정산일자"
                 margin="dense"
                 InputLabelProps={{
                   shrink: true,
                 }}
                 variant="outlined"
                 name="settlementDate"
                 value={searchForm.settlementDate}
                 onChange={handleChange}
                 onKeyPress={onKeyEnter}
               />
             </Grid>
             <Grid
               item
               md={2}
               xs={6}
             >
               <Button
                 color="primary"
                 variant="contained"
                 style = {{
                   marginTop: '8px'
                 }}
                 onClick={onSearchClick}
               >
                 Search 
               </Button>
             </Grid>
           </Grid>
         </CardContent>
        </Card>
    )
}

export default OverseasCalculateToolbar;
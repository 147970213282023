import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MenuTitleToolbar } from '../../components';
import AddCardToolbar from './AddCardToolbar';
import AddCardDetails from './AddCardDetails';
import AddCardCancelDetails from './AddCardCancelDetails';
import ReAddCardDetails from './ReAddCardDetails';
import UpdateCardDetails from './UpdateCardDetails';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState({
  /** 처리구분( 1:회원등록요청 2:등록자료정정요청 3:등록취소 4:등록확인) */
  ProcClss:'',
  /** 거래고유번호 (처리구분이 2, 3일 때) */
  OrgNo:'',
	/** 신청발급구분 - 11:신규 12:추가신규 21:훼손재발급24:분실재발급41:제신고 */
  AplcClss:'',
	/** 현카드번호 (재발급,제신고시 셋팅) */
  CurCardNo:'',
	/** 영문성명*/
  EngNm:'',
	/** 주민등록번호 */
  JuminNo:'',
	/** 비밀번호(SEED)  */
  PswdNo:'',
	/** 계좌번호 -> 핸드폰번호 : CHAI는 핸드폰번호로 사용 */
  BkAcctNo:'',
	/** 카드발송지 주소구분 1:자택,2:근무처 */
  SndAddrClss:'1',
	/** 청구서 발송방법 2:발송중지(이메일) 6:이메일 */
  SndMthd:'',
	/** 자택 전화번호 DDD */
  HomeDDDNo:'',
	/** 자택(국) 전화번호 */
  HomeTelNo1:'',
	/** 자택(번) 전화번호 */
  HomeTelNo2:'',
	/** 자택 우편번호 */
  HomeZpNo:'',
	/** 자택 주소
 - 우편번호6자리 && 구주소 일 경우 : 상세주소(동이하주소) 입력
 - 나머지 : 동이상 주소 입력 */
  HomeAddr:'',
	/** 제휴업체번호(상품코드) */
  AffiFirmNo:'100128',
	/** 인터넷ID번호(이메일) */
  Email:'',
	/** 신용카드권유동의여부[전화] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  CardInvitCnstYnTel:'',
	/** 신용카드권유동의여부[SMS] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  CardInvitCnstYnSms:'',
	/** 신용카드권유동의여부[서면] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  CardInvitCnstYnWrnt:'',
	/** 신용카드권유동의여부[이메일] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  CardInvitCnstYnEmail:'',
	/** 정보제공동의여부[수집_카드상품] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  InfoGdsGthrCnstYn:'',
	/** 정보제공동의여부[수집_부수서비스] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  InfoSvcGthrCnstYn:'',
	/** 정보제공동의여부[수집_고유식별번호] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  UniqInfoGthrCnstYn2:'',
	/** 정보제공동의여부[제공_금융그룹계열사] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  InfoIsurSppyCnstYn:'',
	/** 정보제공동의여부[제공_부정사용방지] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  InfoFrauUseSppyCnstYn:'',
	/** 정보제공동의여부[제공_제휴기관] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  InfoAffiSppyCnstYn:'',
	/** 정보제공동의여부[제공_고유식별번호] 1:동의, 9:미동의, ""(증권사에서 쓰지 않는 항목은 빈 값) */
  UniqInfoSppyCnstYn2:'',
	/** 자택신구주소구분코드 1:구주소, 2:새주소 */
  HomeNwOldAddrClss:'2',
	/** 자택신주소2
 - 우편번호6자리 && 구주소 일 경우 : 빈값
 - 나머지 : 동이하 주소 입력 */
  HomeNwAddr2:'',
	/** 자택도로명참조코드 */
  HomeRoadCode:'',
	/** 후불교통카드 신청 1.신청 , 9.미신청 */
  trafficCardIssueYn:'',
	/** 모바일우선발급 A1.신청 , 00.미신청 */
  mobileCardIssueYn:'',
	/** 브랜드구분(1:BC로컬,3:VISA)
- 본 항목은 필수 */
  BrndClss:'1',	

  /** 핸드폰번호 - 단순 화면 표시용도 */
  HpNo1:'',
  /** 핸드폰번호 - 단순 화면 표시용도 */
  HpNo2:'',
  /** 핸드폰번호 - 단순 화면 표시용도 */
  HpNo3:'',


  /** 성별 - 단순 화면 표시용도 */
  Sex:'1',

  /** 한글성명 - 단순 화면 표시용도 */
  HgNm:'',

  /** 외국인구분 - 단순 화면 표시용도 */
  FrnPsnClss:'2',

  /**신청카드번호 */
  AplcCardNo:''

  });
  const [isAddCardDetails, setIsAddCardDetails] = useState(true);
  const [isAddCardCancelDetails, setIsAddCardCancelDetails] = useState(false);
  const [isReAddCardDetails, setIsReAddCardDetails] = useState(false);
  const [isUpdateCardDetails, setIsUpdateCardDetails] = useState(false);


  return (
    <div className={classes.root}>
       <MenuTitleToolbar title="카드발급 " />
      <AddCardToolbar userInfo={userInfo} setUserInfo={setUserInfo} 
      setIsAddCardDetails={setIsAddCardDetails}
      setIsAddCardCancelDetails={setIsAddCardCancelDetails}
      setIsReAddCardDetails={setIsReAddCardDetails}
      setIsUpdateCardDetails={setIsUpdateCardDetails} />
      {isAddCardDetails &&
        <div className={classes.content}>
          <AddCardDetails userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      }
      {isAddCardCancelDetails &&
        <div className={classes.content}>
          <AddCardCancelDetails userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      }
      {isReAddCardDetails &&
        <div className={classes.content}>
          <ReAddCardDetails userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      }
      {isUpdateCardDetails &&
        <div className={classes.content}>
          <UpdateCardDetails userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      }
    </div>
  );
};

export default Account;

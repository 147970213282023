import gql from 'graphql-tag';

export const selectCardBinList = gql`
  mutation selectCardBinList (
    $useYn : String!,
  ) {
    selectCardBinList (
      useYn : $useYn,
    ) {
      binNo
      cardProductCode
    }
  }
`;



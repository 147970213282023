import { Theme, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MenuTitleToolbar } from '../../components';
import AccountProcAggrToolbar from './AccountProcAggrToolbar';
import AccountProcAggrTable from './AccountProcAggrTable';
import apolloClient from '../../lib/apolloClient';
import { accountProcAggrMutation } from '../../lib/apolloClient/mutations';
import * as util from '../../common/utils';
import {format} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

type SearchInfo = {
  searchGubun: string
  searchDate: string
  searchAccountNo: string
  searchAccountingProcessDivisionCode: string
}
const AccountProcAggr = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    searchGubun: '1',                               // 조회구분 1:일자, 2:계정
    searchDate: format(new Date(), 'yyyy-MM-dd'),   // 조회 일자
    searchAccountNo: '',                            // 조회 계좌(계정)번호
    searchAccountingProcessDivisionCode: ''         // 회계처리구분코드(1:계좌, 2:계정)
  });

  useEffect(() => {
    fetch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 계정처리집계내역 조회
  const fetch = async (searchForm: SearchInfo) => {
    console.log('계정처리집계내역 조회 - INPUT: ', searchForm);

    if(loading) {
      return;
    }
    
    setLoading(true);
    
    await apolloClient.mutate({
      variables: {
        searchGubun: searchForm.searchGubun,
        searchDate: searchForm.searchDate,
        searchAccountNo: searchForm.searchAccountNo,
        searchAccountingProcessDivisionCode: searchForm.searchAccountingProcessDivisionCode
      },
      mutation: accountProcAggrMutation.selectAccountProcAggr
    }).then(res => {
      console.log('조회결과 - ', res.data.selectAccountProcAggr);
      setData(res.data.selectAccountProcAggr);
    }).catch(console.error);
    
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="계정처리집계" />
      <AccountProcAggrToolbar onSearch={fetch} searchForm={searchForm} setSearchForm={setSearchForm} />
      <div className={classes.content}>
        {
          loading
          ? util.Loading()
          : <AccountProcAggrTable list={data} />
        }
      </div>
    </div>
  );
};

export default AccountProcAggr;
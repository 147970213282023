import React, { FC } from 'react';
import {Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import nanumBoldFont from '../../fonts/NanumGothicExtraBold.ttf';
import nanumFont from '../../fonts/NanumGothic.ttf';

Font.register({
    family: 'NanumBold',
    src : nanumBoldFont
});
Font.register({
    family: 'Nanum',
    src : nanumFont
});

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 25
    },
    title : {
        fontSize : 24,
        textAlign : 'center',
        fontFamily : 'NanumBold'
    },
    section : {
        marginLeft : '69%'     
    },
    subtitle : {
        fontSize : 10,
        textAlign : 'left',
        fontFamily : 'NanumBold'
    },
    table: { 
        display: 'table', 
        width: 'auto', 
        borderStyle: 'solid', 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    },
    tableRow: { 
        margin: 'auto', 
        flexDirection: 'row' 
    }, 
    tableHeaderCol: { 
        width: '16.67%', 
        borderStyle: 'solid', 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: 'grey'
    },
    tableCol: { 
        width: '16.67%', 
        borderStyle: 'solid', 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableHeaderCell: { 
        margin: 'auto', 
        marginTop: 5, 
        fontSize: 10, 
        fontFamily: 'NanumBold' 
    },
    tableCell: { 
        margin: 'auto', 
        marginTop: 5, 
        fontSize: 10, 
        fontFamily: 'Nanum' 
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
});

type Props = { 
    pdfList: ApprovalInfo[],
    searchInfo: ApprovalSearchInfo
}

const ApprovalPdf: FC<Props> = ({pdfList, searchInfo}) => {

    return (
    <Document>
    <Page size='A4' orientation='landscape' style={styles.page}>
    <View><Text style={styles.title}>차이카드 매출증빙내역서</Text></View>
    <View style={styles.section}><Text style={styles.subtitle}> </Text></View>
    <View style={styles.section}><Text style={styles.subtitle}>카 드 사  : 비씨카드</Text></View>
    <View style={styles.section}><Text style={styles.subtitle}>회 원 명  : {pdfList.length > 0 ? pdfList[0]._member.userKorName : ''}</Text></View>
    <View style={styles.section}><Text style={styles.subtitle}>카드번호 : {pdfList.length > 0 ? pdfList[0].cardNoMasked : ''}</Text></View>
    <View style={styles.section}><Text style={styles.subtitle}>기       간   : {searchInfo.startDt+' ~ '+searchInfo.endDt}</Text></View>
    <View style={styles.section}><Text style={styles.subtitle}> </Text></View>
    <View style={styles.table}> 
        <View style={styles.tableRow}> 
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'승인번호'}</Text></View> 
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'거래일시'}</Text></View>
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'가맹점명'}</Text></View>
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'거래금액'}</Text></View>
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'사업자번호'}</Text></View>
            <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderCell}>{'매출구분'}</Text></View>
        </View>
        {pdfList.slice(0, pdfList.length).map((item, index) => ( 
        <View key={item.tradeDate+''+item.tradeApprovalSeq} style={styles.tableRow} wrap={false}> 
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.cardApprovalNo}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{chaiStringUtils.dateFormatDefault(item.approvalDate) + ' ' + chaiStringUtils.dateTimeFormat(item.approvalTime)}</Text></View>  
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.merchantName}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.cardApprovalAmount && item.cardApprovalAmount.toLocaleString()}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{chaiStringUtils.bizNoFormat(item._merchant?.merchantBizNo)}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{chaiStringUtils.codeName('salesKindDivisionCode',item.salesKindDivisionCode)}</Text></View>
        </View> 
        ))}
    </View>
    <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed />
    </Page>
  </Document>
  );
};

export default ApprovalPdf;
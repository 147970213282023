import React, { FC } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import chaiStringUtils from '../../common/chaiStringUtils.js';

const StyledTableCell = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = { 
    title: string,
    className?: string, 
    cardDeliveryDetail: CardDeliveryInfoAPI,
    cardInfo: CardDeliveryInfoCardInfoReturn
  }
const CardDeliveryDetail: FC<Props> = ({ className, cardDeliveryDetail, title, cardInfo }) => {
  const classes = useStyles();

  // 카드발송유형코드Map
  const sndTpCodeMap = new Map<String, String>();
  sndTpCodeMap.set('1', '본인수령');
  sndTpCodeMap.set('2', '관리영업점송부');
  sndTpCodeMap.set('3', '제3영업점송부');
  sndTpCodeMap.set('4', '본인특송');

  // 배송업체코드Map
  const sndClssCodeMap = new Map<String, String>();
  sndClssCodeMap.set('1', 'LMC본인');
  sndClssCodeMap.set('3', '중국현지법인');
  sndClssCodeMap.set('4', '국제본인');
  sndClssCodeMap.set('5', '제니엘본인');
  sndClssCodeMap.set('6', '국제영업점');
  sndClssCodeMap.set('7', '제니엘영업점');
  sndClssCodeMap.set('8', '우리영업점');
  sndClssCodeMap.set('9', 'LMC영업점');
  sndClssCodeMap.set('A', '국제본인');
  sndClssCodeMap.set('B', '국제영업점');
  sndClssCodeMap.set('C', '제니엘본인');
  sndClssCodeMap.set('D', '제니엘영업점');
  sndClssCodeMap.set('E', '중국현지');
  sndClssCodeMap.set('F', '우리영업점');
  sndClssCodeMap.set('G', '한승본인');
  sndClssCodeMap.set('H', '한승영업점');
  sndClssCodeMap.set('I', '성우본인');
  sndClssCodeMap.set('J', '성우영업점');
  sndClssCodeMap.set('K', 'LMC본인');
  sndClssCodeMap.set('L', 'LMC영업점');

  // 발급진행정보코드Map
  const pgrsClssCodeMap = new Map<String, String>();
  pgrsClssCodeMap.set('00', '보통 미발급시');
  pgrsClssCodeMap.set('01', '원장생성 ');
  pgrsClssCodeMap.set('02', '발급자료생성');
  pgrsClssCodeMap.set('03', '발급완료');
  pgrsClssCodeMap.set('04', '봉입완료');
  pgrsClssCodeMap.set('05', '발송확정');
  pgrsClssCodeMap.set('06', 'SMS전송');
  pgrsClssCodeMap.set('07', '발송완료');
  pgrsClssCodeMap.set('13', '반송');
  pgrsClssCodeMap.set('14', '배송대기');
  pgrsClssCodeMap.set('20', '등기발송');
  pgrsClssCodeMap.set('41', '영업점배송완료');
  pgrsClssCodeMap.set('51', '본인배송완료');
  pgrsClssCodeMap.set('52', '회원직접수령');
  pgrsClssCodeMap.set('53', '직원수령');
  pgrsClssCodeMap.set('54', '영업점발송');
  pgrsClssCodeMap.set('55', '등기수령');
  pgrsClssCodeMap.set('56', '영업점배송완료');
  pgrsClssCodeMap.set('57', '회원사발송');
  pgrsClssCodeMap.set('91', '발급보류');
  pgrsClssCodeMap.set('92', '발급보류해제');
  pgrsClssCodeMap.set('93', '폐기');

  // 본인과의 관계
  const rcpRelCodeMap = new Map<String, String>();
  rcpRelCodeMap.set('01', '본인');
  rcpRelCodeMap.set('02', '조부');
  rcpRelCodeMap.set('03', '조모');
  rcpRelCodeMap.set('04', '아버지');
  rcpRelCodeMap.set('05', '어머니');
  rcpRelCodeMap.set('06', '처');
  rcpRelCodeMap.set('07', '남편');
  rcpRelCodeMap.set('08', '형');
  rcpRelCodeMap.set('09', '동생');
  rcpRelCodeMap.set('10', '언니');
  rcpRelCodeMap.set('11', '누나');
  rcpRelCodeMap.set('12', '아들');
  rcpRelCodeMap.set('13', '딸');
  rcpRelCodeMap.set('14', '손자');
  rcpRelCodeMap.set('15', '손녀');
  rcpRelCodeMap.set('16', '며느리');
  rcpRelCodeMap.set('17', '삼촌');
  rcpRelCodeMap.set('18', '사촌');
  rcpRelCodeMap.set('19', '친척');
  rcpRelCodeMap.set('20', '직장동료');
  rcpRelCodeMap.set('21', '상사');
  rcpRelCodeMap.set('22', '친구');
  rcpRelCodeMap.set('23', '선배');
  rcpRelCodeMap.set('24', '후배');
  rcpRelCodeMap.set('25', '은행원');
  rcpRelCodeMap.set('26', '배우자');
  rcpRelCodeMap.set('27', '자녀');
  rcpRelCodeMap.set('28', '부모');
  rcpRelCodeMap.set('29', '형제자매');
  rcpRelCodeMap.set('98', '우체국등기');
  rcpRelCodeMap.set('99', '기타');

  // 반송사유코드Map
  const backRsonNoCodeMap = new Map<String, String>();
  backRsonNoCodeMap.set('1', '수취인불명');
  backRsonNoCodeMap.set('2', '이사불명');
  backRsonNoCodeMap.set('3', '주소불명');
  backRsonNoCodeMap.set('4', '장기부재');
  backRsonNoCodeMap.set('5', '수취인거부');
  backRsonNoCodeMap.set('6', '기타');
  backRsonNoCodeMap.set('7', '기타');
  backRsonNoCodeMap.set('8', '기타');
  backRsonNoCodeMap.set('9', '기타');

  // 처리결과코드Map
  const rsltClssCodeMap = new Map<String, String>();
  rsltClssCodeMap.set('1', '정상');
  rsltClssCodeMap.set('2', '불능');


  if(cardDeliveryDetail === undefined) {
    return (
      <Card
      className={clsx(classes.root, className)}
    >
      <form>
      { !!title &&
        <CardHeader title={title}/>
      }
        <Divider />
        <Table>
          <TableBody>
            <StyledTableRow >
              <StyledTableCell>선택한 카드정보가 없습니다.</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Divider />
      </form>
    </Card>
    );
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form>
      { !!title &&
        <CardHeader title={title}/>
      }
        <Divider />
        <Table>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>카드번호</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.cardNoMaskFormat(cardDeliveryDetail.AplcCardNo)}</StyledTableCell>
              <StyledTableCell>상품명</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.GdsNm}</StyledTableCell>
              <StyledTableCell>유효기한</StyledTableCell>
              <StyledTableCell>{cardInfo.validateTermYm}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>발급일자</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.dateFormat(cardDeliveryDetail.IssuDate, null)} {chaiStringUtils.getDayOfTheWeek(cardDeliveryDetail.IssuDate)}</StyledTableCell>
              <StyledTableCell>고객번호</StyledTableCell>
              <StyledTableCell>{cardInfo.userId}</StyledTableCell>
              <StyledTableCell>고객명</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.HgNm}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>발송번호</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.SndNo}</StyledTableCell>
              <StyledTableCell>발송유형</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('sndTpCode',cardDeliveryDetail.SndTp)}</StyledTableCell>
              <StyledTableCell>배송업체</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('sndClssCode',cardDeliveryDetail.SndClss)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>발송일자</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.dateFormat(cardDeliveryDetail.SndDate, null)} {chaiStringUtils.getDayOfTheWeek(cardDeliveryDetail.SndDate)}</StyledTableCell>
              <StyledTableCell>발송매수</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.SndNum}</StyledTableCell>
              <StyledTableCell>등기번호</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.ZpRedgNo}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>발송지주소</StyledTableCell>
              <StyledTableCell colSpan={3}>{cardDeliveryDetail.SndZpNo} {cardDeliveryDetail.SndAddr1} {cardDeliveryDetail.SndAddr2}</StyledTableCell>
              <StyledTableCell>전화번호</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.TelNo1} {cardDeliveryDetail.TelNo2}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>발급진행상태</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('pgrsClssCode',cardDeliveryDetail.PgrsClss)}</StyledTableCell>
              <StyledTableCell>수령일자</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.dateFormat(cardDeliveryDetail.HandToDate, null)} {chaiStringUtils.getDayOfTheWeek(cardDeliveryDetail.HandToDate)}</StyledTableCell>
              <StyledTableCell>수령인</StyledTableCell>
              <StyledTableCell>{cardDeliveryDetail.RcpPsn}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>본인과의관계</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('rcpRelCode',cardDeliveryDetail.RcpRel)}</StyledTableCell>
              <StyledTableCell>반송일자</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.dateFormat(cardDeliveryDetail.SndBkDate, null)} {chaiStringUtils.getDayOfTheWeek(cardDeliveryDetail.SndBkDate)}</StyledTableCell>
              <StyledTableCell>반송사유</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('backRsonNoCode',cardDeliveryDetail.BackRsonNo)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>처리결과</StyledTableCell>
              <StyledTableCell>{chaiStringUtils.codeName('rsltClssCode',cardDeliveryDetail.RsltClss)}</StyledTableCell>
              <StyledTableCell>처리일자</StyledTableCell>
              <StyledTableCell colSpan={3}>{chaiStringUtils.dateFormat(cardDeliveryDetail.ProcDate, null)} {chaiStringUtils.getDayOfTheWeek(cardDeliveryDetail.ProcDate)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>처리사유1</StyledTableCell>
              <StyledTableCell colSpan={5}>{cardDeliveryDetail.RsonNo1} {cardDeliveryDetail.RsonNm1}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>처리사유2</StyledTableCell>
              <StyledTableCell colSpan={5}>{cardDeliveryDetail.RsonNo2} {cardDeliveryDetail.RsonNm2}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>처리사유3</StyledTableCell>
              <StyledTableCell colSpan={5}>{cardDeliveryDetail.RsonNo3} {cardDeliveryDetail.RsonNm3}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Divider />
      </form>
    </Card>
  )

}

export default CardDeliveryDetail;
import React, { useState, useEffect } from 'react';
import apolloClient from '../../lib/apolloClient';
import { commonCodeMutation } from '../../lib/apolloClient/mutations';
import CodeDetails from './CodeDetails';
import CodeTable from './CodeTable';
import CodeToolbar from './CodeToolbar';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';

// 공통코드관리

const CodeMgmt = () => {
  const classes = useStyles(); // className을 통한 style 설정을 위한 상수 선언
  const [updateClick, setUpdateClick] = useState(false); // re-rendering을 위한 state 선언
  const [commonCodeInfoSearch, setCommonCodeInfoSearch] = useState({
    // 검색을 위한 state 선언
    typeCode: '',
    groupCode: '',
    codeId: '',
    codeName: '',
    codeValue: '',
    useYn: '',
    codeDescription: '',
    mapCode: '',
    codeGroupDivisionCode: '',
  });
  const [isNew, setIsNew] = useState(false);
  const [commonCodeInfo, setCommonCodeInfo] = useState({
    // CodeList에 출력될 코드 정보 표기를 위한 state 선언
    typeCode: '',
    groupCode: '',
    codeId: '',
    codeName: '',
    codeValue: '',
    useYn: 'Y',
    codeDescription: '',
    mapCode: '',
    codeGroupDivisionCode: '',
  });

  const [data, setData] = useState([]); // CodeList에 props로 코드 리스트 정보를 전달해 줄 data를 저장하기 위한 state 선언

  const [isValidForm, setIsValidForm] = useState(false);

  const isEmpty = (input: string) => {
    // updateCode.tsx의 textField 값의 유무 확인
    return input === '';
  };

  const checkFormValidation = () => {
    // updateCode.tsx의 신규, 수정 버튼 활성화 확인
    if (
      (isEmpty(commonCodeInfo.typeCode) || // 유형코드, 그룹코드, 코드키, 코드이름이 모두 있을때 활성화
        isEmpty(commonCodeInfo.groupCode) ||
        isEmpty(commonCodeInfo.codeId) ||
        isEmpty(commonCodeInfo.codeName)) === false
    ) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
    setUpdateClick(updateClick);
  };

  const [isEmptyForm, setIsEmptyForm] = useState(true); // updateCode.tsx의 입력 초기화 버튼 활성화 확인을 위한 state

  const checkFormEmpty = () => {
    // updateCode.tsx의 입력 초기화 버튼 활성화 확인
    if (
      (isEmpty(commonCodeInfo.typeCode) && // 모든 입력값들이 하나라도 공백이 아닐 때 활성화
        isEmpty(commonCodeInfo.groupCode) &&
        isEmpty(commonCodeInfo.codeId) &&
        isEmpty(commonCodeInfo.codeName) &&
        isEmpty(commonCodeInfo.codeValue) &&
        isEmpty(commonCodeInfo.useYn) &&
        isEmpty(commonCodeInfo.mapCode) &&
        isEmpty(commonCodeInfo.codeDescription)) === true
    ) {
      setIsEmptyForm(true);
    } else {
      setIsEmptyForm(false);
    }
  };

  useEffect(() => {
    // 2번째 argument가 변경될 때마다 실행
    // CodeList로 전달할 공통코드 리스트를 card-graph에서 호출
    const fetch = async () => {
      await apolloClient
        .mutate({
          variables: {
            // 유형코드, 그룹코드, 코드명 검색을 위해 변수 지정
            typeCode: commonCodeInfoSearch.typeCode,
            groupCode: commonCodeInfoSearch.groupCode,
            codeName: commonCodeInfoSearch.codeName,
          },
          mutation: commonCodeMutation.selectCommonCodeInfoList, // selectCommonCodeInfoList mutation 실행
        })
        .then(res => {
          setData(res.data.selectCommonCodeInfoList); // 결과값이 나오면 data state에 mutation 결과 저장
        })
        .catch(console.error);
    };
    fetch(); // rendering 될 때마다 fetch 실행
    checkFormValidation(); // list가 rendering 될 때마다 updateCode.tsx의 신규, 수정 버튼 활성화 확인
    checkFormEmpty(); // list가 rendering 될 떄마다 updateCode.tsx의 입력 초기화 버튼 활성화 확인
    // eslint-disable-next-line
  }, [updateClick, commonCodeInfoSearch]); // updateClick: 추가, 수정 등 리스트에 변화가 생길때 변경, commonCodeInfoSearch: 검색 조회 시 변경
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MenuTitleToolbar title="공통코드관리" />
        <CodeToolbar onSearch={setCommonCodeInfoSearch} onInitialize={setCommonCodeInfoSearch} />
      </div>
      <div className={classes.content}>
        <CodeDetails
          updateClick={updateClick}
          setUpdateClick={setUpdateClick}
          commonCodeInfo={commonCodeInfo}
          setCommonCodeInfo={setCommonCodeInfo}
          setIsValidForm={setIsValidForm}
          isValidForm={isValidForm}
          checkFormValidation={checkFormValidation}
          setIsEmptyForm={setIsEmptyForm}
          isEmptyForm={isEmptyForm}
          checkFormEmpty={checkFormEmpty}
          isNew={isNew}
          setIsNew={setIsNew}
        />
      </div>
      <div className={classes.content}>
        <CodeTable
          updateClick={updateClick}
          setUpdateClick={setUpdateClick}
          commonCodeList={data}
          setCommonCodeInfo={setCommonCodeInfo}
          setIsValidForm={setIsValidForm}
          setIsEmptyForm={setIsEmptyForm}
          setIsNew={setIsNew}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export default CodeMgmt;

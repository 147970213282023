import React, { FC } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';

type SearchInfo = {
    startDate: string,      // 거래시작일자
    endDate: string         // 거래종료일자
}

type Props ={
    onSearch: React.Dispatch<React.SetStateAction<any>>,
    searchForm: SearchInfo,
    setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}

const ExchangeRateToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {

    // 변경이벤트
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        });
    };

    // 목록 조회 버튼 클릭 
    const onSearchClick = async () => {
        onSearch(searchForm);
    }

    return(
        <Card>
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="거래시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDate"
                value={searchForm.startDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="거래종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDate"
                value={searchForm.endDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                style = {{
                  marginTop: '8px'
                }}
                onClick={onSearchClick}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
       </Card>
    )
}

export default ExchangeRateToolbar;
import gql from 'graphql-tag';

export const selectCardMemberList = gql`
    mutation cardMember {
        selectCardMemberList{
            cardMemberNo
            userKorName
        }        
    }
`;

export const updateCardStatusForAdmin = gql`
  mutation updateCardStatusForAdmin($input : updateCardStatusInput) {
    updateCardStatusForAdmin(input : $input) {
        success
        message
    }
  }
`;
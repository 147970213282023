import React, { FC, useState, useEffect, ChangeEvent} from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalListMutation } from '../../lib/apolloClient/mutations';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import { chaiPaymentId } from '../../lib/chaiAPI'

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee',
    minWidth: '380px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold'
  }
}));

type Props = {
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  paymentIdInfo: ApprovalPaymentIdInfo,
  approvalPaymentIdPopOpen: boolean,
  setApprovalPaymentIdPopOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ApprovalPaymentIdPop: FC<Props> = ({ refundCount, setRefundCount, paymentIdInfo, approvalPaymentIdPopOpen, setApprovalPaymentIdPopOpen }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const [paymentId, setPaymentId] = useState('');
  const { showDialog, hideDialog } = useDialog();

  useEffect(() => {
    const fetch = async () => {
      const refundReq = {} as ChaiMoneyRefundReq;
      refundReq.idempotencyKey = 'AP'+ paymentIdInfo.tradeDate + paymentIdInfo.tradeApprovalSeq.toString().padStart(16, '0');
      try {
        // 차이머니 결제ID조회
        if (paymentIdInfo.tradeDate) {
          const chaiRes = await chaiPaymentId(refundReq);
          setPaymentId(chaiRes.paymentId);
        }
      }
      catch (e) {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIdInfo]);  


 const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentId(e.target.value);
};

  const handleApprovalPaymentIdPopClose = () => {
    setApprovalPaymentIdPopOpen(false);
  };


const handleConfirm = async () => {

  if (!paymentId) {
    hideDialog();
    showToast('paymentId를 입력하세요!!!!', 'error');
    return;
  }

  try {
    await apolloClient.mutate({
      variables: {
        tradeDate: paymentIdInfo.tradeDate, 
        tradeApprovalSeq: paymentIdInfo.tradeApprovalSeq,
        paymentId :paymentId},
        mutation: approvalListMutation.updateApprovalPaymentId
    }).then(res => {     
    console.log(res.data);
    setRefundCount(refundCount+1); 
    hideDialog();
    showDialog(
      'paymentId값 세팅',
      '처리되었습니다.\n\n',
      () => {
      // history.push(`/carddetail/${issueCardResult.applyCardNo}`);
        hideDialog();
        }
    )         
    }).catch(e => {
      console.log(e);
      hideDialog();
      showToast(e.message, 'error');
    });
  } catch (e) {
    console.log(e);
    hideDialog();
    showToast(e.message, 'error');
  }
  setApprovalPaymentIdPopOpen(false);
}

const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
      event.preventDefault();
  }
};

  return (
    <Dialog
      open={approvalPaymentIdPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title"><MenuTitleToolbar title="PaymentId값 세팅" /></DialogTitle>
      <Divider />
      <DialogContent>
        <div>
        <form
        autoComplete="off"
        noValidate
        > 
            <Table size="small">
              <TableBody>            
                <TableRow>
                  <TableCell align="center" className={classes.thStyle}>머니승인번호</TableCell>
                  <TableCell align="center" className={classes.tdStyle}>
                    <TextField
                      fullWidth
                      label="머니승인번호"
                      margin="dense"
                      name="paymentId"
                      onChange={handleChange}
                      value={paymentId}
                      variant="outlined"
                      onKeyPress={onKeyEnter}
                    />                    
                  </TableCell>
                </TableRow>                 
              </TableBody>
            </Table>
        </form>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
            color="primary"
            variant="contained"
            onClick={() => showDialog(
              'PaymentId값 세팅',
              '처리 하시겠습니까?',
              handleConfirm
            )}
          >
            처리
        </Button>
        <Button onClick={handleApprovalPaymentIdPopClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalPaymentIdPop;

import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Link
} from '@material-ui/core';
import ApprovalDetailPop from './ApprovalDetailPop';
import ApprovalRefundPop from './ApprovalRefundPop';
import ApprovalPaymentIdPop from './ApprovalPaymentIdPop';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { 
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  totalCount : number,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  list: ApprovalInfo[] 
}


const ApprovalTable: FC<Props> = ({ paging, setPaging, totalCount, refundCount, setRefundCount, list }) => {
  const classes = useStyles();

  const [tradeDate, setTradeDate] = useState('');
  const [tradeApprovalSeq, setTradeApprovalSeq] = useState(0);
  const [approvalDetailPopOpen, setApprovalDetailPopOpen] = React.useState(false);
  const [approvalRefundPopOpen, setApprovalRefundPopOpen] = React.useState(false);
  const [approvalPaymentIdPopOpen, setApprovalPaymentIdPopOpen] = React.useState(false);
  const [approvalRefundInfo, setApprovalRefundInfo] = useState({
    tradeDate: '',
    tradeApprovalSeq: 0,
    salesKindDivisionCode: '',
    cardNo: '',
    cardNoMasked: '',
    approvalDate: '',
    cardApprovalNo: '',
    cardApprovalAmount: 0,
    merchantNo:'',
    merchantName: '',
    paymentAcno: '',
    approvalProcessStatusCode: '',
    adminErrorCode : '',
    originTradeDate: '',
    originTradeApprovalSeq: 0,
    originApprovalProcessStatusCode: '',
    inoutProcessSeq : '',
    paymentId: '',
    accountProcessSeq : 0
  });
  const [approvalPaymentIdInfo, setApprovalPaymentIdInfo] = useState({
    tradeDate : '',
    tradeApprovalSeq : 0
  });

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
    // setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
    // setRowsPerPage(Number(event.target.value));
  };

  const displayProcessStatus = (item:ApprovalInfo) => {
  
    // 승인의 경우는 처리상태코드값이 비어있거나 처리상태코드가 오류(2)이고 어드민오류코드값이 ESOCKETTIMEDOUT, ETIMEDOUT, ECONNRESET일 경우는 환불처리가능하도록 한다.
    // 타임아웃이 발생했을때 환불처리코드값이 0이거나 1일때는 환불처리됐다고 처리함 ( 0의 경우는 차이결제쪽으로 환불요청을 했기때문에 이후에 처리됐을것으로 간주)
    // 승인취소의 경우는 처리상태코드값이 비어있을때 환불처리가능하도록 한다.

    // 에러코드가 10자리라서 잘림
    const sockErrors = ['ESOCKETTIM', 'ETIMEDOUT', 'ECONNRESET'];
    if (item.approvalProcessStatusCode === '1')  return '정상';
    else if ((item.salesKindDivisionCode === '05' && item.approvalProcessStatusCode === '2' && (sockErrors.indexOf(item.adminErrorCode) < 0 ||
              item.refundProcessCode === '0' || item.refundProcessCode === '1' || item.nonInoutRequestYn === 'Y'))) return '오류';
    else if (item.salesKindDivisionCode !== '05' && item.approvalProcessStatusCode === '2') return '오류';
    else {
      return (<Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => {setApprovalRefundPopOpen(true); 
                      setApprovalRefundInfo({...approvalRefundInfo, 
                        tradeDate:item.tradeDate, 
                        tradeApprovalSeq:item.tradeApprovalSeq,
                        salesKindDivisionCode:item.salesKindDivisionCode,
                        cardNo:item.cardNo,
                        approvalDate:item.approvalDate,
                        cardApprovalNo:item.cardApprovalNo,
                        cardApprovalAmount:item.cardApprovalAmount,
                        merchantNo:item.merchantNo,
                        merchantName:item.merchantName,
                        paymentAcno:item.paymentAcno,
                        approvalProcessStatusCode:item.approvalProcessStatusCode,
                        adminErrorCode:item.adminErrorCode,
                        originTradeDate : item.tradeDate,
                        originTradeApprovalSeq : item.tradeApprovalSeq,
                        originApprovalProcessStatusCode: item.approvalProcessStatusCode,
                        inoutProcessSeq:item.inoutProcessSeq, 
                        paymentId:item.paymentId,
                        accountProcessSeq:item.accountProcessSeq})}}
    >
       상태확인 
    </Button>);
    }
  };

  const displayPaymentId = (item:ApprovalInfo) => {
    
    if (item.paymentId) {
      return item.paymentId;
    }
    else {
      if (item.salesKindDivisionCode === '05' && item.approvalProcessStatusCode === '1') {
        return (<Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {setApprovalPaymentIdInfo({...approvalPaymentIdInfo, tradeDate:item.tradeDate, tradeApprovalSeq:item.tradeApprovalSeq}); setApprovalPaymentIdPopOpen(true);}}
      >
         paymentId값 세팅 
      </Button>);
      }
      else return item.paymentId;
    }
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">카드승인번호</TableCell>
                  <TableCell align="center">머니승인번호</TableCell>
                  <TableCell align="center">승인일시(원)</TableCell>
                  <TableCell align="center">거래일자</TableCell>
                  <TableCell align="center">고객번호</TableCell>
                  <TableCell align="center">성명</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">가맹점명</TableCell>
                  <TableCell align="center">매출종류</TableCell>
                  <TableCell align="center">승인금액</TableCell>
                  <TableCell align="center">처리구분</TableCell>
                  <TableCell align="center">매입여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(0, list.length).map((item, index) => (
                  <TableRow
                    hover
                    key={item.tradeDate+''+item.tradeApprovalSeq}
                  >
                    <TableCell align="center">{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align="center">
                      <Link component="button" variant="body1" onClick={() => { setTradeDate(item.tradeDate); setTradeApprovalSeq(item.tradeApprovalSeq); setApprovalDetailPopOpen(true); }}>
                        {item.cardApprovalNo}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{displayPaymentId(item)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate) + ' ' + chaiStringUtils.dateTimeFormat(item.approvalTime)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.tradeDate)}</TableCell>
                    <TableCell align="center">{item._member && item._member.userId}</TableCell>
                    <TableCell align="center">{item._member && item._member.userKorName}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                    <TableCell align="center">{item.merchantName}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('salesKindDivisionCode',item.salesKindDivisionCode)}</TableCell>
                    <TableCell align="right">{item.cardApprovalAmount && item.cardApprovalAmount.toLocaleString()}</TableCell>
                    <TableCell align="center">{displayProcessStatus(item)}</TableCell>
                    <TableCell align="center">{item.slipPurchaseYn === 'Y' ? '매입' : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <ApprovalDetailPop tradeDate={tradeDate} tradeApprovalSeq={tradeApprovalSeq} approvalDetailPopOpen={approvalDetailPopOpen} setApprovalDetailPopOpen={setApprovalDetailPopOpen}/>
      <ApprovalRefundPop refundCount={refundCount} setRefundCount={setRefundCount} refundReqInfo={approvalRefundInfo} approvalRefundPopOpen={approvalRefundPopOpen} setApprovalRefundPopOpen={setApprovalRefundPopOpen}/>
      <ApprovalPaymentIdPop refundCount={refundCount} setRefundCount={setRefundCount}  paymentIdInfo={approvalPaymentIdInfo} approvalPaymentIdPopOpen={approvalPaymentIdPopOpen} setApprovalPaymentIdPopOpen={setApprovalPaymentIdPopOpen}/>
    </Card>
  );
};

export default ApprovalTable;

import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Tooltip
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import GroupMerchantInfoInsertPop from './GroupMerchantInfoInsertPop';
import GroupMerchantInfoUpdatePop from './GroupMerchantInfoUpdatePop';
import { useToast } from '../../contexts/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
}));
type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputUseYn: string                // 사용여부
}

type Props = {
  groupMerchantList: GetGroupMerchantDetail[],
  fetch: any,
  searchForm: SearchInfo
}
const GroupMerchantTable: FC<Props> = ({ groupMerchantList, fetch, searchForm }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 신규등록 팝업
  const [insertPop, setInsertPop] = useState(false);
  const handleInsertPop = () => {
    // 팝업 오픈
    setInsertPop(true);
  };

  // 더블클릭 시 수정 팝업
  const [updatePop, setUpdatePop] = useState(false);
  const [groupMerchantDetailInfo, setGroupMerchantDetailInfo] = useState({
    groupMerchantNo: '',
    groupMerchantName: '',
    merchantNo: '',
    merchantName: '',
    canceledDate: '',
    useYn: ''
  });
  const handleRowClick = async (obj: GetGroupMerchantDetail) => {
    console.log('수정팝업: ', obj);
    if(obj.receveBaseDate !== 'CHAI') {
      showToast('수정할 수 없는 항목입니다.', 'error');
      return;
    }
    await setGroupMerchantDetailInfo({
      groupMerchantNo: obj.groupMerchantNo,
      groupMerchantName: obj.groupMerchantName,
      merchantNo: obj.merchantNo,
      merchantName: obj.merchantName,
      canceledDate: obj.canceledDate,
      useYn: obj.useYn
    });
    setUpdatePop(true);
  };

  if(groupMerchantList.length === 0) {
    return (
      <div>
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Button className={classes.registerButton} variant="outlined" color="primary" size="medium" 
        onClick={() => handleInsertPop()}
      >            
        신규
      </Button>
      <GroupMerchantInfoInsertPop insertPop={insertPop} setInsertPop={setInsertPop} fetch={fetch} searchForm={searchForm}/>
      </div>
    );
  }
  return (
    <div>
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>구분</TableCell>
                  <TableCell>그룹가맹점번호</TableCell>
                  <TableCell>그룹가맹점명</TableCell>
                  <TableCell>가맹점번호</TableCell>
                  <TableCell>가맹점명</TableCell>
                  <TableCell>사용여부</TableCell>
                  <TableCell>사업자번호</TableCell>
                  <TableCell>대표자명</TableCell>
                  <TableCell>등록일자</TableCell>
                  <TableCell>해지일자</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupMerchantList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((groupMerchant, index) => (
                  <Tooltip title="더블클릭 상세수정" key={index}>
                    <TableRow
                      hover
                      key={groupMerchant.receveSeq.toString()}
                      className={classes.cursor_pointer}
                      onDoubleClick={() => handleRowClick(groupMerchant)}
                    >
                      <TableCell>{groupMerchant.gubun}</TableCell>
                      <TableCell>{groupMerchant.groupMerchantNo}</TableCell>
                      <TableCell>{groupMerchant.groupMerchantName}</TableCell>
                      <TableCell>{groupMerchant.merchantNo}</TableCell>
                      <TableCell>{groupMerchant.merchantName}</TableCell>
                      <TableCell>{groupMerchant.useYn}</TableCell>
                      <TableCell>
                        {
                          groupMerchant.merchantInfo === null
                          ? ''
                          : chaiStringUtils.bizNoFormat(groupMerchant.merchantInfo.merchantBizNo)
                        }
                      </TableCell>
                      <TableCell>
                        {
                          groupMerchant.merchantInfo === null
                          ? ''
                          : groupMerchant.merchantInfo.officerName
                        }
                      </TableCell>
                      <TableCell>
                        {
                          groupMerchant.merchantInfo === null
                          ? ''
                          : chaiStringUtils.dateFormat(groupMerchant.merchantInfo.registeredDate, null)
                        }
                      </TableCell>
                      <TableCell>{chaiStringUtils.dateFormat(groupMerchant.canceledDate, null)}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={groupMerchantList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
    <Button className={classes.registerButton} variant="outlined" color="primary" size="medium" 
      onClick={() => handleInsertPop()}
    >            
      신규
    </Button>
    <GroupMerchantInfoInsertPop insertPop={insertPop} setInsertPop={setInsertPop} fetch={fetch} searchForm={searchForm}/>
    <GroupMerchantInfoUpdatePop updatePop={updatePop} setUpdatePop={setUpdatePop} groupMerchantDetail={groupMerchantDetailInfo} setGroupMerchantDetailInfo={setGroupMerchantDetailInfo} fetch={fetch} searchForm={searchForm}/>
    </div>
  );
};

export default GroupMerchantTable;
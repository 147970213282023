import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { MenuTitleToolbar } from '../../components';
import apolloClient from '../../lib/apolloClient';
import { overseasApprListMutation } from '../../lib/apolloClient/mutations';
import chaiStringUtils from '../../common/chaiStringUtils';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
}));

type Props = {
  isOverseasApprDetailPopOpen: boolean;
  setOverseasApprDetailPopOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tradeDate: string;
  tradeApprovalSeq: number;
};

const OverseasApprDetailPop: FC<Props> = ({
  isOverseasApprDetailPopOpen,
  setOverseasApprDetailPopOpen,
  tradeDate,
  tradeApprovalSeq,
}) => {
  const classes = useStyles();

  const [detail, setDetail] = useState({
    overseasCardApprovalNo: '',
    approvalDate: '',
    approvalTime: '',
    cardTypeCode: '',
    cardNo: '',
    cardNoMasked: '',
    merchantEngName: '',
    overseasMerchantNo: '',
    textSendDate: '',
    textSendTime: '',
    textApprovalRequestUsdAmount: 0,
    approvalRequestUsdAmount: 0,
    approvalRequestUsdOriginAmount: 0,
    overseasBrandUsdFee: 0,
    originOverseasBrandUsdFee: 0,
    overseasBrandKrwFee: 0,
    originOverseasBrandKrwFee: 0,
    overseasUseUsdFee: 0,
    originOverseasUseUsdFee: 0,
    overseasUseKrwFee: 0,
    originOverseasUseKrwFee: 0,
    overseasWithdrawalUsdFee: 0,
    originOverseasWithdrawalUsdFee: 0,
    overseasWithdrawalKrwFee: 0,
    originOverseasWithdrawalKrwFee: 0,
    textTradeUniqNo: '',
    slipPurchaseYn: '',
    approvalProcessStatusCode: '',
    approvalRequestMethodCode: '',
    approvalAgencyIdentifier: '',
    salesKindDivisionCode: '',
    requestWithdrawalUsdAmount: 0,
    beforeRequestWithdrawalUsdAmount: 0,
    realWithdrawalKrwAmount: 0,
    beforeRealWithdrawalKrwAmount: 0,
    registeredTerminalNo: '',
    inoutDate: '',
    inoutProcessCode: '',
    inoutProcessSeq: '',
    paymentId: '',
    adminErrorCode: '',
    adminErrorMessage: '',
    refundDate: '',
    refundProcessCode: '',
    refundProcessSeq: '',
    refundPaymentId: '',
    accountInoutYn: '',
    nonInoutSeq: 0,
    nonInoutRegisteredDate: '',
    nonInoutRequestYn: '',
    approvalProcessCode: '',
    overseasApprovalUseNo: '',
    _card: {
      validateTermYm: '',
    },
    _member: {
      userKorName: '',
      userId: '',
    },
  });

  const displayProcessCode = (process_code: string) => {
    let displayProcess = '';
    if (process_code === '1') displayProcess = '정상';
    else if (process_code === '2') displayProcess = '오류';
    else displayProcess = '처리중';
    return displayProcess;
  };

  const displayInoutProcessCode = (inout_process_code: string) => {
    let displayInoutProcess = '';
    if (inout_process_code === '1') displayInoutProcess = '입출금성공';
    else if (inout_process_code === '2') displayInoutProcess = '입출금실패';
    else displayInoutProcess = '';
    return displayInoutProcess;
  };

  const handlerClosePop = () => {
    setOverseasApprDetailPopOpen(false);
  };

  const onSearchDetail = async () => {
    console.log('*** 상세조회 실시 ***');
    console.log('tradeDate: ' + tradeDate);
    console.log('tradeApprovalSeq: ' + tradeApprovalSeq);
    await apolloClient
      .mutate({
        variables: {
          tradeDate: tradeDate,
          tradeApprovalSeq: tradeApprovalSeq,
        },
        mutation: overseasApprListMutation.selectOverseasApprDetail,
      })
      .then(res => {
        console.log('*** 상세조회 결과 ***');
        console.log(res.data.selectOverseasApprDetail);
        const result = res.data.selectOverseasApprDetail;
        setDetail({
          ...detail,
          ...result,
        });
      })
      .catch(e => {
        console.log('*** 상세조회 오류 ***');
        console.log(e);
      });
  };

  useEffect(() => {
    if (isOverseasApprDetailPopOpen) {
      onSearchDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeDate, tradeApprovalSeq]);

  return (
    <Dialog
      open={isOverseasApprDetailPopOpen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <MenuTitleToolbar title="해외승인내역 상세조회" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Chip label="▶ 카드승인내역" color="primary" size="small" className={classes.chipStyle} />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  해외카드승인번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasCardApprovalNo}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  승인일자
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {chaiStringUtils.dateFormatDefault(detail.approvalDate) +
                    ' ' +
                    chaiStringUtils.dateTimeFormat(detail.approvalTime)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  결제방식
                </TableCell>
                <TableCell align="center" className={classes.tdStyle} colSpan={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox size="small" checked={false} style={{ padding: '0px' }} />}
                      label="모바일카드여부"
                      color="primary"
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" checked={true} style={{ padding: '0px' }} />}
                      label="계좌거래"
                      color="primary"
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" checked={false} style={{ padding: '0px' }} />}
                      label="선결제"
                      color="primary"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  가맹점명
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.merchantEngName}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  카드번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.cardNoMasked}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  유효기한
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail._card && detail._card.validateTermYm}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  한글성명
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail._member && detail._member.userKorName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  가맹점번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasMerchantNo}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  전문전송일시
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {chaiStringUtils.dateFormatDefault(detail.textSendDate) +
                    ' ' +
                    chaiStringUtils.dateTimeFormat(detail.textSendTime)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  매입여부
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.slipPurchaseYn}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  전문승인요청미화금액
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.textApprovalRequestUsdAmount && detail.textApprovalRequestUsdAmount.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  승인요청미화금액
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.approvalRequestUsdAmount && detail.approvalRequestUsdAmount.toLocaleString()}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  승인요청미화원금금액
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.approvalRequestUsdOriginAmount && detail.approvalRequestUsdOriginAmount.toLocaleString()}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  해외브랜드미화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasBrandUsdFee && detail.overseasBrandUsdFee.toLocaleString()}(
                  {detail.originOverseasBrandUsdFee && detail.originOverseasBrandUsdFee.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  해외브랜드원화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasBrandKrwFee && detail.overseasBrandKrwFee.toLocaleString()}(
                  {detail.originOverseasBrandKrwFee && detail.originOverseasBrandKrwFee.toLocaleString()})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  해외이용미화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasUseUsdFee && detail.overseasUseUsdFee.toLocaleString()}(
                  {detail.originOverseasUseUsdFee && detail.originOverseasUseUsdFee.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  해외이용원화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasUseKrwFee && detail.overseasUseKrwFee.toLocaleString()}(
                  {detail.originOverseasUseKrwFee && detail.originOverseasUseKrwFee.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  해외출금미화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasWithdrawalUsdFee && detail.overseasWithdrawalUsdFee.toLocaleString()}(
                  {detail.originOverseasWithdrawalUsdFee && detail.originOverseasWithdrawalUsdFee.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  해외출금원화수수료(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasWithdrawalKrwFee && detail.overseasWithdrawalKrwFee.toLocaleString()}(
                  {detail.originOverseasWithdrawalKrwFee && detail.originOverseasWithdrawalKrwFee.toLocaleString()})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  승인처리상태
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {displayProcessCode(detail.approvalProcessStatusCode)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  승인요청방식
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.approvalRequestMethodCode}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  승인기관식별자
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.approvalAgencyIdentifier}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  매출종류
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {chaiStringUtils.codeName('overseasSalesKindDivisionCode', detail.salesKindDivisionCode)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  요청인출미화금액(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.requestWithdrawalUsdAmount && detail.requestWithdrawalUsdAmount.toLocaleString()}(
                  {detail.beforeRequestWithdrawalUsdAmount && detail.beforeRequestWithdrawalUsdAmount.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  실인출원화금액(면제전)
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.realWithdrawalKrwAmount && detail.realWithdrawalKrwAmount.toLocaleString()}(
                  {detail.beforeRealWithdrawalKrwAmount && detail.beforeRealWithdrawalKrwAmount.toLocaleString()})
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  단말번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.registeredTerminalNo}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  전문거래고유번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.textTradeUniqNo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  이용번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.overseasApprovalUseNo}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  승인처리코드
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.approvalProcessCode}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  계좌입출여부
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.accountInoutYn}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}></TableCell>
                <TableCell align="center" className={classes.tdStyle}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br />
        <div>
          <Chip label="▶ 입출금처리정보" color="primary" size="small" className={classes.chipStyle} />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  입출금일자
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {chaiStringUtils.dateFormatDefault(detail.inoutDate)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  입출금처리코드
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {displayInoutProcessCode(detail.inoutProcessCode)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  입출금처리일련번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.inoutProcessSeq}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  입출금결제ID
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.paymentId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  계정계고객번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail._member ? detail._member.userId : ''}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  계정계오류코드
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.adminErrorCode}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  계정계오류메세지
                </TableCell>
                <TableCell align="left" className={classes.tdStyle} colSpan={3}>
                  {detail.adminErrorMessage}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br />
        <div>
          <Chip label="▶ 미입출처리요청정보" color="primary" size="small" className={classes.chipStyle} />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  미입출요청여부
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.nonInoutRequestYn}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  미입출요청일자
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.nonInoutRegisteredDate && chaiStringUtils.dateFormatDefault(detail.nonInoutRegisteredDate)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  미입출일련번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.nonInoutSeq}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <br />
        <div>
          <Chip label="▶ 환불처리정보" color="primary" size="small" className={classes.chipStyle} />
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>
                  홤불입금일자
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {chaiStringUtils.dateFormatDefault(detail.refundDate)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  환불처리코드
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {displayInoutProcessCode(detail.refundProcessCode)}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  환불처리일련번호
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.refundProcessSeq}
                </TableCell>
                <TableCell align="center" className={classes.thStyle}>
                  환불결제ID
                </TableCell>
                <TableCell align="center" className={classes.tdStyle}>
                  {detail.refundPaymentId}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handlerClosePop} color="default" variant="contained">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverseasApprDetailPop;

import React, { FC } from 'react';
import { Card, CardContent, Grid, Button, TextField } from '@material-ui/core';

type Props = {
    calculateBreakdownList: overseasCalculateBreakdownList[], 
    calculateExchangeRateSearch: React.Dispatch<React.SetStateAction<any>>,
    exchangeRateForm: CalculateExchangeRateSearchInfo,
    setExchangeRateForm: React.Dispatch<React.SetStateAction<CalculateExchangeRateSearchInfo>>
};

const OverseasCalculateExchangeRateToolbar: FC<Props> = ({ 
    calculateBreakdownList,
    calculateExchangeRateSearch, 
    exchangeRateForm, 
    setExchangeRateForm}) => {

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExchangeRateForm({
            ...exchangeRateForm,
            [e.target.name]: e.target.value
        });
    };
    
    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
        if (event.key === "Enter"){
            onSearchClick();
        }
    }

    // 목록 조회
    const onSearchClick = async() => {
        calculateExchangeRateSearch(exchangeRateForm);
    }

    // 정산환율 검색 버튼 숨기기
    const isDisabled = () => {
        let isCalculate = true;
        if(calculateBreakdownList.length === 0) {
            isCalculate = false;
        }
        return isCalculate;
    }

    
    return (
        <Card>
         <CardContent>
           <Grid
             container
             spacing={1}
           >
             <Grid
               item
               md={2}
               xs={6}
             >
               <TextField
                 fullWidth
                 label="정산환율"
                 margin="dense"
                 InputLabelProps={{
                   shrink: true,
                 }}
                 variant="outlined"
                 name="calculateExchangeRate"
                 value={exchangeRateForm.calculateExchangeRate}
                 onChange={handleChange}
                 onKeyPress={onKeyEnter}
               />
             </Grid>
             <Grid
               item
               md={2}
               xs={6}
             >
               <Button
                 disabled={!isDisabled()}
                 color="primary"
                 variant="contained"
                 style = {{
                   marginTop: '8px'
                 }}
                 onClick={onSearchClick}
               >
                 Search 
               </Button>
             </Grid>
           </Grid>
         </CardContent>
        </Card>
    )
}

export default OverseasCalculateExchangeRateToolbar;
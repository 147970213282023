import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  CardHeader,
  Divider,
  Tooltip,
  Link
} from '@material-ui/core';
import { useToast } from '../../contexts/Toast';
import GroupMerchantInfoMgmtInsertPop from './GroupMerchantInfoMgmtInsertPop';
import GroupMerchantInfoMgmtUpdatePop from './GroupMerchantInfoMgmtUpdatePop';
import apolloClient from '../../lib/apolloClient';
import { groupMerchantMgmtMutation } from '../../lib/apolloClient/mutations';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
}));

type SearchInfo = {
  inputGroupMerchantNo: string,     // 그룹가맹점번호
  inputGroupMerchantName: string,   // 그룹가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명
  inputGubun: string,               // 차이 / 비씨 구분
  inputCancelYn: string             // 해지여부
}

type Props = {
  groupMerchantList: GroupMerchantMgmtDetail[],
  setInputDetailGroupMerchant: React.Dispatch<React.SetStateAction<GroupMerchantMgmtDetail>>,
  searchForm: SearchInfo,
  fetch: any
}

const GroupMerchantListTable: FC<Props> = ({ groupMerchantList, setInputDetailGroupMerchant, searchForm, fetch }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 가맹점 번호 클릭 시 상세화면
  const handleDetailClick = (obj: GroupMerchantMgmtDetail) => {
    setInputDetailGroupMerchant(obj);
  }

  // 신규등록 팝업
  const [insertPop, setInsertPop] = useState(false);
  // 그룹가맹점 Entity
  const [groupMerchantInfo, setGroupMerchantInfo] = useState({
    groupMerchantNo: '',                              // 그룹가맹점번호
    groupMerchantName: '',                            // 그룹가맹점명
  });
  const handleInsertPop = async () => {
    // 맥스순번 채번
    await apolloClient.mutate({
      variables: {
      },
      mutation: groupMerchantMgmtMutation.selectGroupMerchantMgmtMaxNoGet
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectGroupMerchantMgmtMaxNoGet.groupMerchantNoMax);
      setGroupMerchantInfo({
        groupMerchantNo: res.data.selectGroupMerchantMgmtMaxNoGet.groupMerchantNoMax,  // 그룹가맹점번호
        groupMerchantName: '',                                      // 그룹가맹점명
      })
    }).catch(console.error);

    // 팝업 오픈
    await setInsertPop(true);
  };

  // 더블클릭 시 수정 팝업
  const [updatePop, setUpdatePop] = useState(false);
  const [groupMerchantDetailInfo, setGroupMerchantDetailInfo] = useState({
    groupMerchantNo: '',
    groupMerchantName: ''
  });
  const handleRowClick = async (obj: GroupMerchantMgmtDetail) => {
    console.log('수정팝업: ', obj);
    if(obj.gubun !== 'CHAI') {
      showToast('수정할 수 없는 항목입니다.', 'error');
      return;
    }
    await setGroupMerchantDetailInfo({
      groupMerchantNo: obj.groupMerchantNo,
      groupMerchantName: obj.groupMerchantName
    });
    setUpdatePop(true);
  };

  if(groupMerchantList.length === 0) {
    return (
      <div>
      <Card>
        <CardHeader title="그룹가맹점"/>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button className={classes.registerButton}
          color="primary"
          variant="contained"
          onClick={() => handleInsertPop()}
        >            
          NEW
        </Button>
      </Card>
    <GroupMerchantInfoMgmtInsertPop 
      insertPop={insertPop} 
      setInsertPop={setInsertPop} 
      searchForm={searchForm} 
      fetch={fetch} 
      groupMerchantInfo={groupMerchantInfo} 
      setGroupMerchantInfo={setGroupMerchantInfo}/>
      </div>
    );
  }

  return (
    <div>
    <Card>
      <CardHeader title="그룹가맹점 목록"/>
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">구분</TableCell>
                  <TableCell align="center">그룹가맹점번호</TableCell>
                  <TableCell align="center">그룹가맹점명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupMerchantList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((groupMerchant, index) => (
                  <Tooltip title="더블클릭 상세수정" key={index}>
                    <TableRow
                      hover
                      key={index}
                      className={classes.cursor_pointer}
                      onDoubleClick={() => handleRowClick(groupMerchant)}
                    >
                      <TableCell align="center">{groupMerchant.gubun}</TableCell>
                      <TableCell align="center">
                        <Link component="button" variant="body1" onClick={() => handleDetailClick(groupMerchant as GroupMerchantMgmtDetail)}>
                          {groupMerchant.groupMerchantNo}
                        </Link>
                      </TableCell>
                      <TableCell align="center">{groupMerchant.groupMerchantName}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={groupMerchantList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>

      <Button className={classes.registerButton}
        color="primary"
        variant="contained"
        onClick={() => handleInsertPop()}
      >            
        NEW
      </Button>
    </Card>
    <GroupMerchantInfoMgmtInsertPop 
      insertPop={insertPop} 
      setInsertPop={setInsertPop} 
      searchForm={searchForm} 
      fetch={fetch} 
      groupMerchantInfo={groupMerchantInfo} 
      setGroupMerchantInfo={setGroupMerchantInfo}/>
    <GroupMerchantInfoMgmtUpdatePop updatePop={updatePop} setUpdatePop={setUpdatePop} groupMerchantDetail={groupMerchantDetailInfo} setGroupMerchantDetailInfo={setGroupMerchantDetailInfo} fetch={fetch} searchForm={searchForm}/>
    </div>
  )
}

export default GroupMerchantListTable;
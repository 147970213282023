import gql from 'graphql-tag';

// 가맹점 목록 조회 (페이징)
export const selectMerchantInfoListPaging = gql`
  mutation selectMerchantInfoListPaging(
      $merchantNo: String, 
      $merchantBizNo: String,
      $page: Float, 
      $rowsPerPage: Float,
      $isExcelDown: Boolean
    ) {
      selectMerchantInfoListPaging (
        merchantNo: $merchantNo, 
        merchantBizNo: $merchantBizNo,
        page: $page,
        rowsPerPage: $rowsPerPage,
        isExcelDown: $isExcelDown
      ) {
          totalCount
          list {
            receveSeq
            receveBaseDate
            registeredDate
            merchantNo
            merchantBizNo
            merchantName
            merchantEngName
            officerName
            businessCode
            businessSubCode
            telNo1
            telNo2
            telNo3
            terminateDate
            salesDivision
            edcMerchantYn
            settlementProcessCycle
            feeRate
            cardFeeRate
            branchGiroNo
            tradeBlockYn
            accountBankCode
            tradeBlockStartDate
            tradeBlockEndDate
            installmentMonth
            paymentAcno
            merchantAddressDivisionCode
            merchantRoadAddress01
            merchantRoadAddress02
            merchantRoadAddressManagementNo
            merchantPostNo
            merchantEtcAddress
            remarkDescription
            firstOperateAdminId
            firstOperateDetailDate
            firstOperateTradeCode
            lastOperateAdminId
            lastOperateDetailDate
            lastOperateTradeCode
          }
    }
  }
`;

// 가맹점 정보 조회
export const selectMerchantInfoList = gql`
  mutation selectMerchantInfoList($type1: String, $type2: String) {
    selectMerchantInfoList (merchantNo: $type1, merchantBizNo: $type2) {
      receveSeq
      receveBaseDate
      registeredDate
      merchantNo
      merchantBizNo
      merchantName
      merchantEngName
      officerName
      businessCode
      businessSubCode
      telNo1
      telNo2
      telNo3
      terminateDate
      salesDivision
      edcMerchantYn
      settlementProcessCycle
      feeRate
      cardFeeRate
      branchGiroNo
      tradeBlockYn
      accountBankCode
      tradeBlockStartDate
      tradeBlockEndDate
      installmentMonth
      paymentAcno
      merchantAddressDivisionCode
      merchantRoadAddress01
      merchantRoadAddress02
      merchantRoadAddressManagementNo
      merchantPostNo
      merchantEtcAddress
      remarkDescription
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      lastOperateAdminId
      lastOperateDetailDate
      lastOperateTradeCode
    }
  }
`;

export const selectMerchantInfo = gql`
mutation selectMerchantInfo($type: String) {
  selectMerchantInfo (merchantNo: $type) {
    receveSeq
    receveBaseDate
    registeredDate
    merchantNo
    merchantBizNo
    merchantName
    merchantEngName
    officerName
    businessCode
    businessSubCode
    telNo1
    telNo2
    telNo3
    terminateDate
    salesDivision
    edcMerchantYn
    settlementProcessCycle
    feeRate
    cardFeeRate
    branchGiroNo
    tradeBlockYn
    accountBankCode
    tradeBlockStartDate
    tradeBlockEndDate
    installmentMonth
    paymentAcno
    merchantAddressDivisionCode
    merchantRoadAddress01
    merchantRoadAddress02
    merchantRoadAddressManagementNo
    merchantPostNo
    merchantEtcAddress
    remarkDescription
    firstOperateAdminId
    firstOperateDetailDate
    firstOperateTradeCode
    lastOperateAdminId
    lastOperateDetailDate
    lastOperateTradeCode
  }
}
`;
import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    },
    thStyle: {
      backgroundColor: '#fafafa',
    },
    thSumStyle: {
      backgroundColor: '#eeeeee',
    }, 
  }));

type Props = {title: string, settlementAutoList: SettlementAuto[] }
const SettlementAutoTable: FC<Props> = ({ title, settlementAutoList}) => {
const classes = useStyles();

const [rowsPerPage, setRowsPerPage] = useState(10);
const [page, setPage] = useState(0);

const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
  setPage(page);
};

const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setRowsPerPage(Number(event.target.value));
};

// 대행수수료 자동대사 정산내역 합계 (정산건수)
let resultCountSum = 0;
settlementAutoList.map(settlementAuto => {
    if(settlementAuto.unitFeeCodeNo !== '000000'){
     resultCountSum += settlementAuto.resultCount 
    }
    return resultCountSum;
});

// 대행수수료 자동대사 정산내역 합계 (정산금액)
let resultAmountSum = 0;
settlementAutoList.map(settlementAuto => {
    if(settlementAuto.unitFeeCodeNo === '000000'){
     resultAmountSum += settlementAuto.BCResultAmount
    }
    return resultAmountSum;
});

//
const getThStyle = (item: SettlementAuto) => {
  if(item.unitFeeCodeNo === '000000'){
    return classes.thStyle;
  }
}

if(settlementAutoList.length === 0) {
  return (
    <Card>
    { !!title &&
      <CardHeader title={title}/>
    }
    <Divider />
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>조회된 내용이 없습니다.</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Card>
  );
}

  return (
      <Card>
        { !!title &&
          <CardHeader title={title}/>
        }
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>NO</TableCell>
                    <TableCell align="center" rowSpan={2}>정산년월</TableCell>
                    <TableCell align="center" rowSpan={2}>청구년월</TableCell>
                    <TableCell align="center" rowSpan={2}>국내외구분코드</TableCell>
                    <TableCell align="center" rowSpan={2}>서비스수수료코드</TableCell>
                    <TableCell align="center" rowSpan={2}>서비스수수료명</TableCell>
                    <TableCell align="center" rowSpan={2}>단위항목코드</TableCell>
                    <TableCell align="center" rowSpan={2}>단위항목명</TableCell>
                    <TableCell align="center" rowSpan={2}>단가</TableCell>
                    <TableCell align="center" rowSpan={2}>요율(%)</TableCell>
                    <TableCell align="center" rowSpan={2}>정산건수</TableCell>
                    <TableCell align="center" rowSpan={2}>정산금액</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                      hover
                      className={classes.thSumStyle}
                    >
                      <TableCell align="center" colSpan={10} >합계</TableCell>
                      <TableCell align="right">{resultCountSum} 건</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(resultAmountSum)} 원</TableCell>
                    </TableRow>
                  {settlementAutoList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((settlementAuto,index) => (
                    <TableRow
                      hover
                      key={index}
                      className={getThStyle(settlementAuto)}
                    >
                      <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                      <TableCell align="center">{chaiStringUtils.yyyyMMFormatDefault(settlementAuto.feeSettlementYm)}</TableCell>
                      <TableCell align="center">{chaiStringUtils.yyyyMMFormatDefault(settlementAuto.feeClaimYm)}</TableCell>
                      <TableCell align="center">{chaiStringUtils.codeName('domesticCode',settlementAuto.domesticCode)}</TableCell>
                      <TableCell align="center">{settlementAuto.proxyFeeCodeNo}</TableCell>
                      <TableCell align="center">{settlementAuto.proxyFeeNm}</TableCell>
                      <TableCell align="center">{settlementAuto.unitFeeCodeNo === '000000' ? '소계' : settlementAuto.unitFeeCodeNo}</TableCell>
                      <TableCell align="center">{settlementAuto.unitFeeName}</TableCell>
                      <TableCell align="right">{settlementAuto.unitFeeCodeNo === '000000' ? '' : chaiStringUtils.moneyFormat(settlementAuto.applyUnitprc).concat(' 원')}</TableCell>
                      <TableCell align="right">{settlementAuto.unitFeeCodeNo === '000000' ? '' : String(settlementAuto.feeBaseRate).concat(' %')}</TableCell>
                      <TableCell align="right">{settlementAuto.unitFeeCodeNo === '000000' ? chaiStringUtils.moneyFormat(settlementAuto.BCTotalResultCount) : chaiStringUtils.moneyFormat(settlementAuto.resultCount)} 건</TableCell>
                      <TableCell align="right">{chaiStringUtils.moneyFormat(settlementAuto.BCResultAmount)} 원</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>     
        <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={settlementAutoList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        </CardActions>  
      </Card>
  );
}

export default SettlementAutoTable;
import client from './client';

export default async (input: VipCardInput) => {  
  return client.post(`/root/card/vipCardInsert`, getBody(input)).then(res => {
    console.log('등록할 VIP 카드번호 정보');
    console.log(input);
    console.log('===============');
    console.log(res.data);
    
    const statusCode = res.status;
    if (!statusCode || statusCode !== 200) {
      throw new Error(`VIP 카드번호 등록 실패-!`)
    }    
    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: VipCardInput) => {
  return {
    userId: input.userId,
    cardProductCode: input.cardProductCode,
    cardNo: input.cardNo
  }
}
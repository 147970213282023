import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
} from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { overseasCsRefundMutation } from '../../lib/apolloClient/mutations';

import * as chaiAPI from '../../lib/chaiAPI';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  thStyle: {
    backgroundColor: '#eeeeee',
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px',
  },
  chipStyle: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
}));

type Props = {
  uploadPop: boolean;
  setUploadPop: React.Dispatch<React.SetStateAction<boolean>>;
  overseasCsRefundSelectPopList: overseasCsRefundSelectPopList[];
  overseasCsRefundPlenumList: overseasCsRefundPlenumList[];
  overseasCsRefundSelectList: overseasCsRefundSelectList[];
  fetch: any;
  searchForm: SearchInfo;
  setRefundProcessResult: React.Dispatch<React.SetStateAction<boolean>>;
};

const OverseasCsRefundProcess: FC<Props> = ({
  uploadPop,
  setUploadPop,
  overseasCsRefundSelectPopList,
  overseasCsRefundPlenumList,
  overseasCsRefundSelectList,
  fetch,
  searchForm,
  setRefundProcessResult,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { showDialog, hideDialog } = useDialog();
  const { showToast } = useToast();

  const handleClose = () => {
    setUploadPop(false);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 환불처리
  const handleConfirmRefund = async () => {
    let success = true;

    if (overseasCsRefundSelectPopList.length === 0) {
      showToast('환불목록이 없습니다.', 'error');
      hideDialog();
      handleClose();
      return;
    }

    for (let i = 0; i < overseasCsRefundSelectPopList.length; i++) {
      const refundReq = {} as ChaiMoneyDepositReq;
      refundReq.idempotencyKey =
        'CS' +
        overseasCsRefundSelectPopList[i].overseasCsRewardProcessDate +
        overseasCsRefundSelectPopList[i].overseasCsRewardSeq.toString().padStart(16, '0');
      refundReq.userId = overseasCsRefundSelectPopList[i].userId;
      refundReq.amount = overseasCsRefundSelectPopList[i].csRewardKrwTotalAmount;
      refundReq.description = overseasCsRefundSelectPopList[i].merchantEngName;
      refundReq.categoryId = overseasCsRefundSelectPopList[i].salesKindDivisionCode;

      try {
        // 환급처리
        let paymentId = '';

        const status = await chaiAPI.chaiPaymentDepositStatus(refundReq);
        if (status) {
          paymentId = status.id;
        } else {
          // 입금API 호출
          const rslt = await chaiAPI.chaiPaymentDeposit(refundReq);
          paymentId = rslt.id;
        }

        await apolloClient
          .mutate({
            variables: {
              input: {
                cardNo: overseasCsRefundSelectPopList[i].cardNo,
                paymentAcno: overseasCsRefundSelectPopList[i].userId,
                cardApprovalAmount: overseasCsRefundSelectPopList[i].csRewardKrwTotalAmount,
                merchantEngName: overseasCsRefundSelectPopList[i].merchantEngName,
                refundProcessSeq: refundReq.idempotencyKey,
                refundPaymentId: paymentId,
                salesKindDivisionCode: overseasCsRefundSelectPopList[i].salesKindDivisionCode,
                overseasCsRewardSeq: overseasCsRefundSelectPopList[i].overseasCsRewardSeq,
                overseasCsRewardProcessDate: overseasCsRefundSelectPopList[i].overseasCsRewardProcessDate,
                trmUsdProcessAmount: overseasCsRefundSelectPopList[i].trmUsdProcessAmount,
                overseasCsTrmSeq: overseasCsRefundSelectPopList[i].overseasCsTrmSeq,
                trmDate: overseasCsRefundSelectPopList[i].trmDate,
              },
            },
            mutation: overseasCsRefundMutation.refundProcess,
          })
          .then(res => {
            setRefundProcessResult(true);
            showToast('환급처리완료.');
          })
          // eslint-disable-next-line
          .catch(e => {
            console.log(e);
            success = false;
            hideDialog();
            showToast(e.message, 'error');
          });
      } catch (e) {
        // 미입출금내역 등록
        await apolloClient
          .mutate({
            variables: {
              input: {
                cardNo: overseasCsRefundSelectPopList[i].cardNo,
                cardSeq: overseasCsRefundSelectPopList[i].cardSeq,
                paymentAcno: overseasCsRefundSelectPopList[i].userId,
                cardApprovalAmount: overseasCsRefundSelectPopList[i].csRewardKrwTotalAmount,
                merchantEngName: overseasCsRefundSelectPopList[i].merchantEngName,
                refundProcessSeq: refundReq.idempotencyKey,
                salesKindDivisionCode: overseasCsRefundSelectPopList[i].salesKindDivisionCode,
                inoutProcessSeq: overseasCsRefundSelectPopList[i].inoutProcessSeq,
                overseasCsRewardSeq: overseasCsRefundSelectPopList[i].overseasCsRewardSeq,
                overseasCsRewardProcessDate: overseasCsRefundSelectPopList[i].overseasCsRewardProcessDate,
                trmUsdProcessAmount: overseasCsRefundSelectPopList[i].trmUsdProcessAmount,
                overseasCsTrmSeq: overseasCsRefundSelectPopList[i].overseasCsTrmSeq,
                trmDate: overseasCsRefundSelectPopList[i].trmDate,
              },
            },
            mutation: overseasCsRefundMutation.insertNonInOutInfo,
          })
          .then(res => {
            setRefundProcessResult(true);
            showToast('미입출 완료.');
          })
          // eslint-disable-next-line
          .catch(e => {
            console.log(e);
            success = false;
            hideDialog();
            showToast(e.message, 'error');
          });
      }
    }

    // 환급처리중 오류 발생 시 update문 타지않는다.
    if (success !== true) {
      hideDialog();
      handleClose();
      showToast('환급처리중 오류', 'error');
      return;
    }

    // 미입출금내역 오류 발생 시 update문 타지않는다.
    if (success !== true) {
      hideDialog();
      handleClose();
      showToast('미입출금 처리중 오류', 'error');
      return;
    }

    // 다 완료하면 update
    await apolloClient
      .mutate({
        variables: {
          input: {
            overseasCsTrmSeq: overseasCsRefundSelectPopList[0].overseasCsTrmSeq,
            trmDate: overseasCsRefundSelectPopList[0].trmDate,
          },
        },
        mutation: overseasCsRefundMutation.refundProcessLastInsert,
      })
      .then(res => {
        showToast('환차처리 완료');
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });

    setUploadPop(false);
    hideDialog();
    // 목록리로드
    fetch(searchForm);
  };

  // 환차처리 버튼클릭
  const exchangeRageDiff = async () => {
    if (overseasCsRefundPlenumList.length === 0) {
      showToast('환차목록이 없습니다.', 'error');
      hideDialog();
      handleClose();
      return;
    }

    let count = 0;
    if (overseasCsRefundPlenumList[0].trmStatusCode !== '9') {
      for (let i = 0; i < overseasCsRefundSelectList.length; i++) {
        if (overseasCsRefundSelectList[i].rewardProgressStatusCode === '9') {
          count++;
        }
      }
      if (overseasCsRefundSelectList.length === count) {
        await apolloClient
          .mutate({
            variables: {
              input: {
                overseasCsTrmSeq: overseasCsRefundPlenumList[0].overseasCsTrmSeq,
                trmDate: overseasCsRefundPlenumList[0].trmDate,
              },
            },
            mutation: overseasCsRefundMutation.refundProcessLastInsert,
          })
          .then(res => {
            showToast('환차처리 완료');
          })
          .catch(e => {
            console.log(e);
            hideDialog();
            showToast(e.message, 'error');
          });
      }
    } else if (overseasCsRefundPlenumList[0].trmStatusCode === '9') {
      showToast('이미 완료된 환차처리', 'error');
      hideDialog();
      handleClose();
      return;
    }

    setUploadPop(false);
    hideDialog();
    // 목록리로드
    fetch(searchForm);
  };

  // 환차처리 버튼 숨기기
  const isDisabled = () => {
    let isRefund = true;
    if (overseasCsRefundPlenumList.length === 0) {
      isRefund = false;
    } else if (overseasCsRefundPlenumList[0].trmStatusCode !== '9') {
      if (overseasCsRefundSelectPopList.length !== 0) {
        isRefund = false;
      } else {
        isRefund = true;
      }
    } else {
      isRefund = false;
    }
    return isRefund;
  };

  // 환불처리 버튼 숨기기
  const isDisabledRefund = () => {
    let isRefund = true;
    if (overseasCsRefundSelectPopList.length === 0) {
      isRefund = false;
    }
    return isRefund;
  };

  return (
    <Dialog
      open={uploadPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title">
        <b>최종확정된 민원선정대상 환급처리</b>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Chip label="최종확정된 민원선정대상" color="primary" size="small" className={classes.chipStyle} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2}>
                  NO
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  보상기본정보
                </TableCell>
                <TableCell align="center" colSpan={7}>
                  실보상정보
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  민원정보
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">보상진행상태</TableCell>
                <TableCell align="center">처리일자</TableCell>
                <TableCell align="center">확정일자</TableCell>
                <TableCell align="center">적용환율</TableCell>
                <TableCell align="center">전금미화처리금액</TableCell>
                <TableCell align="center">매출원화금액</TableCell>
                <TableCell align="center">원화수익수수료</TableCell>
                <TableCell align="center">해외서비스원화수수료</TableCell>
                <TableCell align="center">보상현금수수료원화</TableCell>
                <TableCell align="center">환율차이손익구분</TableCell>
                <TableCell align="center">환율차이금액</TableCell>
                <TableCell align="center">민원보상원화총액</TableCell>
                <TableCell align="center">접수일자</TableCell>
                <TableCell align="center">...</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overseasCsRefundSelectPopList
                .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                .map((overseasCsRefundSelectPop, index) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{overseasCsRefundSelectPop.rewardProgressStatusCode}</TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelectPop.overseasCsRewardProcessDate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelectPop.csTargetConfirmDate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.applyExchangeRate)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.trmUsdProcessAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.rewardSalesKrwAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.rewardKrwProfitFee)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.rewardOverseasUseKrwFee)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.rewardCashFeeKrwAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.exchangeRatePflsDivisionCode)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.exchangeRateDiffAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.moneyFormat(overseasCsRefundSelectPop.csRewardKrwTotalAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(overseasCsRefundSelectPop.overseasCsReceveDate)}
                    </TableCell>
                    <TableCell align="center">{}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={overseasCsRefundSelectList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={!isDisabledRefund()}
          color="primary"
          variant="contained"
          onClick={() => showDialog('민원환급', '환불처리 하시겠습니까?', handleConfirmRefund)}
        >
          환불처리
        </Button>
        <Button
          disabled={!isDisabled()}
          color="primary"
          variant="contained"
          onClick={() => showDialog('환차처리', '환차처리 하시겠습니까?', exchangeRageDiff)}
        >
          환차처리
        </Button>
        <Button onClick={handleClose} color="primary">
           닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverseasCsRefundProcess;

import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { accountProcMutation } from '../../lib/apolloClient/mutations';
import AccountProcToolbar from './AccountProcToolbar';
import AccountProcTable from './AccountProcTable';
import AccountProcSum from './AccountProcSum';
import { MenuTitleToolbar } from '../../components';
import * as utils from '../../common/utils';
import {format, subMonths} from 'date-fns'
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
}));

type Props = {
  listInfo: AccountProcListReturnType
}

const AccountProcList: FC<Props> = ({listInfo}) => {
  const classes = useStyles();
  const [data, setData] = useState(listInfo);
  const [searchForm, setSearchForm] = useState({
    startDt: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDt: format(new Date(), 'yyyy-MM-dd'),
    cardNo: chaiStringUtils.getSchParamCardNo(),
    accountProcessSeq: '',
    accountingProcessDivisionCode: ''
  });
  const [sumInfo, setSumInfo] = useState({
    inCount	: 0, 
    inAmount	: 0, 
    outCount	: 0, 
    outAmount	: 0, 
    totalCount	: 0, 
    totalAmount	: 0
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  // 엑셀다운로드
  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  // 계정처리내역 조회
  const fetch = async () => {
    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    if(!isExcelDown){
      setLoading(true);
    }

    await apolloClient.mutate({
      variables: {
        startDt: searchForm.startDt,
        endDt: searchForm.endDt,
        cardNo: searchForm.cardNo,
        accountProcessSeq: searchForm.accountProcessSeq,
        accountingProcessDivisionCode: searchForm.accountingProcessDivisionCode,
        page: paging.page,
        rowsPerPage: paging.rowsPerPage,
        isExcelDown: isExcelDown
      },
      mutation: accountProcMutation.selectAccountProcListPaging
    }).then(res => {
      console.log('목록조회 결과: ', res.data.selectAccountProcListPaging);
      if(isExcelDown){
        setExcelDown(false);
        if(Number(res.data.selectAccountProcListPaging.totalCount) > 100000) {
          showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
        }else {
          excelDown(res.data.selectAccountProcListPaging.list);
        }
      }else{
        setData(res.data.selectAccountProcListPaging)
      }
      fetchSum(); // 합계조회
    }).catch(console.error);

    setLoading(false);
  };

  // 계정처리내역 합계
  const fetchSum = async () => {
    await apolloClient.mutate({
      variables: {
        startDt: searchForm.startDt,
        endDt: searchForm.endDt,
        cardNo: searchForm.cardNo,
        accountProcessSeq: searchForm.accountProcessSeq,
        accountingProcessDivisionCode: searchForm.accountingProcessDivisionCode
      },
      mutation: accountProcMutation.selectAccountProcSum
    }).then(res => {
      setSumInfo(res.data.selectAccountProcSum);
    }).catch(console.error);
  };

  // 페이징
  const default_page = 0;
  const default_rowsPerPage = 10;
  const [paging, setPaging] = useState({
    page: default_page,
    rowsPerPage: default_rowsPerPage
  });
  
  useEffect(()=> {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, isFlag]);

  // 엑셀다운로드 버튼 클릭
  const excelClick = () => {
    if(Number(data.totalCount) > 100000) {
      showToast('10만건이상 엑셀다운로드 할수 없습니다.', 'error');
      return;
    }

    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: AccountProcInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
        rowData.push({
          'No':`${i+1}`,
          '계정처리일련번호': `${csvData[i].accountProcessSeq}`,
          '상세일련번호': `${csvData[i].accountProcessDetailSeq}`,
          '카드계정구분': `${chaiStringUtils.codeName('accountDivisionCode',csvData[i].accountDivisionCode)}`,
          '입출구분': `${chaiStringUtils.codeName('inoutDivisionCode',csvData[i].inoutDivisionCode)}`,
          '계정처리일자': `${chaiStringUtils.dateFormatDefault(csvData[i].accountProcessDate)}`,
          '계정처리시간': `${chaiStringUtils.dateTimeFormat(csvData[i].accountProcessTime)}`,
          '입출금액': `${csvData[i].inoutAmount && csvData[i].inoutAmount.toLocaleString()}`,
          '회계처리구분': `${chaiStringUtils.codeName('accountingProcessDivisionCodeName',csvData[i].accountingProcessDivisionCode)}`,
          '가맹점명': `${csvData[i].merchantName}`,
          '계좌(계정)번호': `${csvData[i].accountNo} ${chaiStringUtils.codeName('accountNoCodeName',csvData[i].accountNo)=== undefined ? '' : '('+chaiStringUtils.codeName('accountNoCodeName',csvData[i].accountNo)+')'}`,
          '처리취소여부': `${csvData[i].processCancelYn}`,
          '카드번호': `${csvData[i].cardNoMasked}`
        });
    }

    utils.exportToCSV("계정처리내역", rowData);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="계정처리내역" />
      <AccountProcToolbar 
        onSearch={fetch}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        paging={paging}
        setPaging={setPaging}
        />
      <div className={classes.content}>
        <AccountProcSum sumInfo={sumInfo}/>
      </div>
      <div className={classes.content}>
        <div className={classes.registerButton}>
          {isExcelDown ? <span style={{marginRight: 20}}>Loading...</span> : ''}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={(event) => {excelClick(); }} >
              Excel
          </Button>
        </div>

        <br/>
        <br/>
        <br/>
        
        {loading
          ? 
            utils.Loading()
          :
          <AccountProcTable AccountProcInfoList={data} paging={paging} setPaging={setPaging} />
        }
      </div>
    </div>
  );

};

export default AccountProcList;

import gql from 'graphql-tag';

// 그룹가맹점 마스터정보 조회
export const selectGroupMerchantMgmtList = gql`
  mutation selectGroupMerchantMgmtList(
    $inputGroupMerchantNo: String, 
    $inputGroupMerchantName: String
    $inputGubun: String,
    $inputMerchantNo: String,
    $inputCancelYn: String
    ) {
      selectGroupMerchantMgmtList (
        groupMerchantNo: $inputGroupMerchantNo, 
        groupMerchantName: $inputGroupMerchantName, 
        gubun: $inputGubun,
        merchantNo: $inputMerchantNo,
        cancelYn: $inputCancelYn
      ) {
        groupMerchantNo
        groupMerchantName
        gubun
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
    }
  }
`;

// 그룹가맹점+가맹점정보 상세목록조회
export const selectGroupMerchantMgmtDetailList = gql`
  mutation selectGroupMerchantMgmtDetailList(
    $inputGroupMerchantNo: String,
    $inputCancelYn: String
    ) {
      selectGroupMerchantMgmtDetailList (
      groupMerchantNo: $inputGroupMerchantNo,
      cancelYn: $inputCancelYn
      ) {
      receveSeq
      receveBaseDate
      groupMerchantNo
      groupMerchantName
      merchantNo
      merchantName
      registeredDate
      canceledDate
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      useYn
      gubun
      merchantInfo {
        receveSeq
        receveBaseDate
        registeredDate
        merchantNo
        merchantBizNo
        merchantName
        merchantEngName
        officerName
        businessCode
        businessSubCode
        telNo1
        telNo2
        telNo3
        terminateDate
        salesDivision
        edcMerchantYn
        settlementProcessCycle
        feeRate
        cardFeeRate
        branchGiroNo
        tradeBlockYn
        accountBankCode
        tradeBlockStartDate
        tradeBlockEndDate
        installmentMonth
        paymentAcno
        merchantAddressDivisionCode
        merchantRoadAddress01
        merchantRoadAddress02
        merchantRoadAddressManagementNo
        merchantPostNo
        merchantEtcAddress
        remarkDescription
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
      }
    }
  } 
`;

// 그룹가맹점 마스터 삽입
export const insertGroupMerchantMaster = gql`
  mutation insertGroupMerchantMaster($input: groupMerchantMgmtInputType) {
    insertGroupMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 마스터 수정
export const updateGroupMerchantMaster = gql`
  mutation updateGroupMerchantMaster($input: groupMerchantMgmtInputType) {
    updateGroupMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 마스터 삭제
export const deleteGroupMerchantMaster = gql`
  mutation deleteGroupMerchantMaster($input: groupMerchantMgmtInputType) {
    deleteGroupMerchantMaster(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 상세 신규
export const insertGroupMerchantMgmtDetail = gql`
  mutation insertGroupMerchantMgmtDetail($input: groupMerchantMgmtDetailInputType) {
    insertGroupMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 상세 수정
export const updateGroupMerchantMgmtDetail = gql`
  mutation updateGroupMerchantMgmtDetail($input: groupMerchantMgmtDetailInputType) {
    updateGroupMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 상세 삭제
export const deleteGroupMerchantMgmtDetail = gql`
  mutation deleteGroupMerchantMgmtDetail($input: groupMerchantMgmtDetailInputType) {
    deleteGroupMerchantMgmtDetail(input: $input) {
      success
      message
    }
  }
`;

// 그룹가맹점 엑셀업로드
export const groupMerchantMgmtExcelUpload = gql`
  mutation groupMerchantMgmtExcelUpload($inputList: [groupMerchangetMgmtExcelUploadInputType]) {
    groupMerchantMgmtExcelUpload(inputList: $inputList) {
      success
      message
    }
  }
`;


// 그룹가맹점번호 순번 Max+1 가져오기
export const selectGroupMerchantMgmtMaxNoGet = gql`
  mutation selectGroupMerchantMgmtMaxNoGet {
    selectGroupMerchantMgmtMaxNoGet {
      groupMerchantNoMax
    }
  }
`;
import React, { FC, ChangeEvent, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button
} from '@material-ui/core';
import { useDialog } from '../contexts/Dialog';
import { useToast } from '../contexts/Toast';

import { updUserInfo } from '../lib/cardApi';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = { 
  className?: string, 
  cardUserInfo: GetCardUserInfoReturn, 
  setCardUserInfo: React.Dispatch<React.SetStateAction<GetCardUserInfoReturn>>
}
const CustomerInfo: FC<Props> = ({ className, cardUserInfo, setCardUserInfo }) => {
  const classes = useStyles();  
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  //성별구분
  const sexDvsnCd = [
    {
      value: '1',
      label: '남'
    },
    {
      value: '2',
      label: '여'
    }
  ]

  //외국인구분
  const foreignerDvsnCd = [
    {
      value: '2',
      label: '내국인'
    },
    {
      value: '1',
      label: '외국인'
    }
  ];

  const isEmpty = (input: string) => {
    return input === '';
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardUserInfo({
      ...cardUserInfo,
      [e.target.name]: e.target.value
    });
  };

  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    //null인것도 체크해야 함-!


    const isValid = Object.values(cardUserInfo).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true)
    setIsValidForm(isValid);
  }

  useEffect(() => {
    checkFormValidation();
  // eslint-disable-next-line
  }, [cardUserInfo])

  //회원정보 변경
  const onCustomerChange = async () => {
    console.log(cardUserInfo);
    
    try{
      

      await updUserInfo({ 
        ProcClss: '1',
        AplcClss: '41', //제신고
        AffiFirmNo: '', //제휴업체번호
        CurCardNo: '',
        EngNm: cardUserInfo.custEngName,
        HgNm: cardUserInfo.custKorName,
        /*HomeDDDNo: '',
        HomeTelNo1: '',
        HomeTelNo2: '',*/
        HpNo1: cardUserInfo.phoneNo1,
        HpNo2: cardUserInfo.phoneNo2,
        HpNo3: cardUserInfo.phoneNo3,   

        //Email: cardUserInfo.emalAddr,
        HomeNwOldAddrClss: '2',
        HomeZpNo: cardUserInfo.zipCode,
        HomeAddr: cardUserInfo.homeNewAddress,
        HomeNwAddr2: cardUserInfo.homeNewDetailAddress
      });
    }catch(e){
      showToast(e.message, 'error')
      
      hideDialog();
    }    
  };

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="회원 정보"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="고객번호"
                margin="dense"
                name="csno"
                onChange={handleChange}
                required
                disabled
                value={cardUserInfo.csno}
                variant="outlined"                  
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="한글명"
                margin="dense"
                name="custKorName"
                onChange={handleChange}
                required
                value={cardUserInfo.custKorName}
                variant="outlined"                  
                error={isEmpty(cardUserInfo.custKorName)}
                helperText={isEmpty(cardUserInfo.custKorName) ? 'Required' : ''}             
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="영문명"
                margin="dense"
                name="custEngName"
                onChange={handleChange}
                required
                value={cardUserInfo.custEngName}
                variant="outlined"           
                error={isEmpty(cardUserInfo.custEngName)}
                helperText={isEmpty(cardUserInfo.custEngName) ? 'Required' : ''}                    
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="생년월일"
                margin="dense"
                name="birthDt"
                onChange={handleChange}
                required
                value={cardUserInfo.birthDt}
                variant="outlined"     
                error={isEmpty(cardUserInfo.birthDt)}
                helperText={isEmpty(cardUserInfo.birthDt) ? 'Required' : ''}             
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="성별 구분"
                margin="dense"
                name="sexDvsnCd"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={cardUserInfo.sexDvsnCd}
              >
                {sexDvsnCd.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="외국인 구분"
                margin="dense"
                name="foreignerDvsnCd"
                onChange={handleChange}
                required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={cardUserInfo.foreignerDvsnCd}
              >
                {foreignerDvsnCd.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>              
            </Grid>
          </Grid>
          
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={1}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호1"
                margin="dense"
                name="phoneNo1"
                onChange={handleChange}
                required
                value={cardUserInfo.phoneNo1}
                variant="outlined"     
                error={isEmpty(cardUserInfo.phoneNo1)}
                helperText={isEmpty(cardUserInfo.phoneNo1) ? 'Required' : ''}             
              />
            </Grid>            
            <Grid
              item
              md={1}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호2"                
                margin="dense"
                name="phoneNo2"
                onChange={handleChange}
                required
                value={cardUserInfo.phoneNo2}
                variant="outlined"     
                error={isEmpty(cardUserInfo.phoneNo2)}
                helperText={isEmpty(cardUserInfo.phoneNo2) ? 'Required' : ''}             
              />
            </Grid>            
            <Grid
              item
              md={1}
              xs={12}
            >
              <TextField
                fullWidth
                label="휴대폰번호3"
                margin="dense"
                name="phoneNo3"
                onChange={handleChange}
                required
                value={cardUserInfo.phoneNo3}
                variant="outlined"         
                error={isEmpty(cardUserInfo.phoneNo3)}
                helperText={isEmpty(cardUserInfo.phoneNo3) ? 'Required' : ''}                      
              />
            </Grid>
            <Grid
              item
              md={9}
              xs={12}
            >
              {/*<TextField
                fullWidth
                label="이메일주소"
                margin="dense"
                name="emalAddr"
                onChange={handleChange}
                required
                value={cardUserInfo.emalAddr}
                variant="outlined"             
                error={isEmpty(cardUserInfo.emalAddr)}
                helperText={isEmpty(cardUserInfo.emalAddr) ? 'Required' : ''}                         
              />*/}
            </Grid>            
          </Grid>

          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="우편번호"
                margin="dense"
                name="zipCode"
                onChange={handleChange}
                required
                value={cardUserInfo.zipCode}
                variant="outlined"     
                error={isEmpty(cardUserInfo.zipCode)}
                helperText={isEmpty(cardUserInfo.zipCode) ? 'Required' : ''}             
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="주소"
                margin="dense"
                name="homeNewAddress"
                onChange={handleChange}
                required
                value={cardUserInfo.homeNewAddress}
                variant="outlined"         
                error={isEmpty(cardUserInfo.homeNewAddress)}
                helperText={isEmpty(cardUserInfo.homeNewAddress) ? 'Required' : ''}                      
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="상세주소"
                margin="dense"
                name="homeNewDetailAddress"
                onChange={handleChange}
                required
                value={cardUserInfo.homeNewDetailAddress}
                variant="outlined"                  
                error={isEmpty(cardUserInfo.homeNewDetailAddress)}
                helperText={isEmpty(cardUserInfo.homeNewDetailAddress) ? 'Required' : ''}                      
              />
            </Grid>
          </Grid>

          <Grid
            container            
            spacing={1}
          >
             <Grid              
              item
              md={12}
              xs={12}
            >
              <Button 
                disabled={!isValidForm}
                variant="contained" 
                color="primary" 
                onClick={
                () => showDialog(
                  '회원정보 변경',
                  '회원정보 변경을 진행 하시겠습니까?',
                  () => onCustomerChange()
                )
              }>            
                회원정보 변경
              </Button>
            </Grid>
          </Grid>          
        </CardContent>          
      </form>
    </Card>
  );
};

export default CustomerInfo;

import React, {FC, useState}from 'react';
import {Card, CardContent, Grid, Button, TextField} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import { format, subMonths }from 'date-fns';

type searchInfo = {
  searchType: string;
  searchValue: string;
  startDate: string;
  endDate: string;
  salesDivisionCode: string;
  apprProcessStatusCode: string;
  cardApprNo: string;
  paymentId: string;
  adminErrorCode: string;
}

type Paging = {
  page: number,
  rowsPerPage: number
}

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<searchInfo>>,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  page: number,
  rowsPerPage: number
}

const OverseasApprListToolbar: FC<Props> = ({onSearch, setPaging, page, rowsPerPage}) => {

  const [searchForm, setSearchForm] = useState({
    searchType: chaiStringUtils.getSchParamGubun(),
    searchValue: chaiStringUtils.getSchParamSearchVal(chaiStringUtils.getSchParamGubun()),
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    salesDivisionCode: '',
    apprProcessStatusCode: '',
    cardApprNo: '',
    paymentId: '',
    adminErrorCode: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    chaiStringUtils.setSchParamGubun(searchForm.searchType);// 공통변수 저장하는 곳에 set!
    chaiStringUtils.setSchParamSearchVal(searchForm.searchType, searchForm.searchValue);
    const paging:Paging = {} as any;
    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);

    onSearch(searchForm);
  };

  const onResetClick = async () => {
    setSearchForm({
      ...searchForm,
      searchType: '1',
      searchValue: '',
      startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      salesDivisionCode: '',
      apprProcessStatusCode: '',
      cardApprNo: '',
      paymentId: '',
    });
    onSearchClick();
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }; 

  return (
      <Card>
        <form>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={2} xs={6}>
                <TextField
                  select 
                  fullWidth 
                  label="검색조건"
                  margin="dense"
                  variant="outlined"
                  name="searchType"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  onChange={handleChange}
                  value={searchForm.searchType}            
                >
                  <option value="1">핸드폰번호</option>
                  <option value="2">카드번호</option>
                  <option value="3">고객번호</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth 
                  label="검색값"
                  placeholder="검색값"
                  margin="dense"
                  variant="outlined"
                  name="searchValue"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.searchValue}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  required
                  fullWidth 
                  type="date"
                  label="승인시작일자"
                  margin="dense"
                  variant="outlined"
                  name="startDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.startDate}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  required
                  fullWidth 
                  type="date"
                  label="승인종료일자"
                  margin="dense"
                  variant="outlined"
                  name="endDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.endDate}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={2} xs={6}>
                <TextField
                  select
                  fullWidth
                  label="매출종류"
                  margin="dense"
                  variant="outlined"
                  name="salesDivisionCode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  value={searchForm.salesDivisionCode}
                  onChange={handleChange}
                >
                  <option value="">전체</option>
                  <option value="05">신판</option>
                  <option value="15">신판취소</option>
                  <option value="06">현금</option>
                  <option value="16">현금취소</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  select 
                  fullWidth 
                  label="처리구분"
                  margin="dense"
                  variant="outlined"
                  name="apprProcessStatusCode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}  
                  value={searchForm.apprProcessStatusCode}
                  onChange={handleChange}
                >
                  <option value="">전체</option>
                  <option value="1">정상</option>
                  <option value="2">오류</option>
                  <option value="9">처리중</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth 
                  label="카드승인번호"
                  placeholder="카드승인번호"
                  margin="dense"
                  variant="outlined"
                  name="cardApprNo"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.cardApprNo}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth 
                  label="머니승인번호"
                  placeholder="머니승인번호"
                  margin="dense"
                  variant="outlined"
                  name="paymentId"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.paymentId}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  fullWidth 
                  label="오류코드"
                  placeholder="오류코드"
                  margin="dense"
                  variant="outlined"
                  name="adminErrorCode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchForm.adminErrorCode}
                  onChange={handleChange}
                  onKeyPress={onKeyEnter}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginTop: '8px', marginRight: '8px'}}
                  onClick={onSearchClick}
                >
                  Search
                </Button>
                <Button
                  color= "default"
                  variant="contained"
                  style={{marginTop: '8px'}}
                  onClick={onResetClick}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
};

export default OverseasApprListToolbar;
import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';

type Props = {
  // index.tsx에서 받아온 prop를 사용하기 위해 props들을 Props로 묶어 type 지정
  onSearch: React.Dispatch<React.SetStateAction<CommonCodeInfo>>; // 검색 조회 시 수정되는 state를 변경
  onInitialize: React.Dispatch<React.SetStateAction<CommonCodeInfo>>; // 목록 초기화 버튼 클릭 시 검색된 리스트 초기화를 위한 state를 변경
};

const CodeToolbar: FC<Props> = ({ onSearch, onInitialize }) => {
  const [typeCode, setTypeCode] = useState(''); // 유형코드 검색어 입력 시 변경되는 state
  const [groupCode, setGroupCode] = useState(''); // 그룹코드 검색어 입력 시 변경되는 state
  const [codeName, setCodeName] = useState(''); // 코드명 검색어 입력 시 변경되는 state

  const typeCodeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 유형코드 검색창에 변화가 있을 때 onChange를 통해 해당 검색 값으로 typeCode state 변경
    setTypeCode(event.target.value);
    console.log(!!typeCode);
  };

  const groupCodeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 그룹코드 검색창에 변화가 있을 때 onChange를 통해 해당 검색 값으로 groupCode state 변경
    setGroupCode(event.target.value);
    console.log(!!groupCode);
  };

  const codeNameHandleChage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 코드명 검색창에 변화가 있을 때 onChange를 통해 해당 검색 값으로 codeName state 변경
    setCodeName(event.target.value);
    console.log(!!codeName);
  };

  const onSearchClick = async () => {
    // 조회 버튼 클릭 시 검색어 입력으로 변경된 state들을 commonCodeInfoSearch에 저장하여 index.tsx의 리스트 조회를 위한 fetch로 전송
    const commonCodeInfoSearch: CommonCodeInfo = {} as any;

    commonCodeInfoSearch.typeCode = typeCode;
    commonCodeInfoSearch.groupCode = groupCode;
    commonCodeInfoSearch.codeName = codeName;

    onSearch(commonCodeInfoSearch);
  };

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="유형코드"
              placeholder="유형코드"
              margin="dense"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="typeCode"
              onKeyPress={onPressEnter}
              onChange={typeCodeHandleChange}
              value={typeCode}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="그룹코드"
              placeholder="그룹코드"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              name="groupCode"
              onKeyPress={onPressEnter}
              onChange={groupCodeHandleChange}
              value={groupCode}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="코드명"
              placeholder="코드명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              name="codeName"
              onKeyPress={onPressEnter}
              onChange={codeNameHandleChage}
              value={codeName}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Button color="primary" variant="contained" onClick={onSearchClick} style={{ marginTop: '8px' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CodeToolbar;

import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as util from '../../common/utils';
import { MenuTitleToolbar } from '../../components';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    //minWidth: 2000
  }
}));
type Props = { 
  detailListPop: boolean,
  setDetailListPopOpen: React.Dispatch<React.SetStateAction<boolean>>,
  loading: boolean,
  list: OverseasPurchasedAggrDetailInfo[]
}

const OverseasPurchasedAggrDetailListPop: FC<Props> = ({ detailListPop, setDetailListPopOpen, loading, list }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };
 
  const handleClose = () => {
    setDetailListPopOpen(false);
    setPage(0);
    setRowsPerPage(10);
  };

  return (
    <Dialog
      open={detailListPop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <DialogTitle><MenuTitleToolbar title="해외매입집계내역 상세목록" /></DialogTitle>
        <DialogContent>
          {
            loading
            ? util.Loading()
            : 
            <Card>
              <CardContent className={classes.content}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No</TableCell>
                        <TableCell align="center">수신기준일자</TableCell>
                        <TableCell align="center">매출일자</TableCell>
                        <TableCell align="center">매출종류구분코드</TableCell>
                        <TableCell align="center">카드번호</TableCell>
                        <TableCell align="center">매입접수번호</TableCell>
                        <TableCell align="center">영문가맹점명</TableCell>
                        <TableCell align="center">MCC가맹점코드</TableCell>
                        <TableCell align="center">매입미화금액</TableCell>
                        <TableCell align="center">매입원화금액</TableCell>
                        <TableCell align="center">해외카드승인번호</TableCell>
                        <TableCell align="center">계정처리순번</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((data, index) => (
                        <TableRow hover key={index} >
                          <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.receve_base_date)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.sales_date)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.codeName('salesKindDivisionCode', data.sales_kind_division_code)}</TableCell>
                          <TableCell align="center">{data.card_no_masked}</TableCell>
                          <TableCell align="center">{data.purchased_process_no}</TableCell>
                          <TableCell align="center">{data.merchant_eng_name}</TableCell>
                          <TableCell align="center">{data.mcc_merchant_code}</TableCell>
                          <TableCell align="center">${chaiStringUtils.moneyFormat(data.purchased_usd_amount)}</TableCell>
                          <TableCell align="center">&#8361;{chaiStringUtils.moneyFormat(data.purchased_krw_amount)}</TableCell>
                          <TableCell align="center">{data.overseas_card_approval_no}</TableCell>
                          <TableCell align="center">{data.account_process_seq}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <CardActions className={classes.actions}>
                <TablePagination
                  component="div"
                  count={list.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </CardActions>
            </Card>
          }
        </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverseasPurchasedAggrDetailListPop;

const codeData = {
  //01:app 02:admin 03:asp 04:BC콜센터 05:ARS 06:기타
  'terminalMediaDivisionCode': [
    {
      'codeValue': '01',
      'codeNm': 'app'
    },
    {
      'codeValue': '02',
      'codeNm': 'admin'
    },
    {
      'codeValue': '03',
      'codeNm': 'asp'
    },
    {
      'codeValue': '04',
      'codeNm': 'BC콜센터'
    },
    {
      'codeValue': '06',
      'codeNm': '기타'
    },
    {
      'codeValue': '05',
      'codeNm': 'ARS'
    }
  ],
  // 변경구분코드
  'changeDivisionCode': [
    //01:한글성명 02:영문명 03:휴대번호 04:이메일 05:주소 06:수령지주소
    //07:이용내역서수령방법 08:주민등록번호 09:생년월일 15:수령지전화번호 
    //16:자택전화번호 17:직장전화번호 18:직장명 19:부서명 20:카드비밀번호 21:카드서비스유형
    {
      'codeValue': '01',
      'codeNm': '한글성명'
    },
    {
      'codeValue': '02',
      'codeNm': '영문명'
    },
    {
      'codeValue': '03',
      'codeNm': '휴대번호'
    },
    {
      'codeValue': '04',
      'codeNm': '이메일'
    },
    {
      'codeValue': '05',
      'codeNm': '주소'
    },
    {
      'codeValue': '06',
      'codeNm': '수령지주소'
    },
    {
      'codeValue': '07',
      'codeNm': '이용내역서수령방법'
    },
    {
      'codeValue': '08',
      'codeNm': '주민등록번호'
    },
    {
      'codeValue': '09',
      'codeNm': '생년월일'
    },
    {
      'codeValue': '15',
      'codeNm': '수령지전화번호'
    },
    {
      'codeValue': '16',
      'codeNm': '자택전화번호'
    },
    {
      'codeValue': '17',
      'codeNm': '직장전화번호'
    },
    {
      'codeValue': '18',
      'codeNm': '직장명'
    },
    {
      'codeValue': '19',
      'codeNm': '부서명'
    },
    {
      'codeValue': '20',
      'codeNm': '카드비밀번호'
    },
    {
      'codeValue': '21',
      'codeNm': '카드서비스유형'
    }
  ],

  // 이체처리상태코드
  'transferStatusCode': [
    {
      'codeValue': '1',
      'codeNm': '정상'
    },
    {
      'codeValue': '2',
      'codeNm': '계좌조회불능'
    },
    {
      'codeValue': '3',
      'codeNm': '입금불능'
    }
  ],
  // 입출금구분코드
  'inoutDivisionCode': [
    {
      'codeValue': '1',
      'codeNm': '입금'
    },
    {
      'codeValue': '2',
      'codeNm': '출금'
    }
  ],
  // 매출종류구분코드
  'salesKindDivisionCode': [
    {
      'codeValue': '05',
      'codeNm': '정상매출'
    },
    {
      'codeValue': '15',
      'codeNm': '취소매출'
    },
    {
      'codeValue': '95',
      'codeNm': '선거절'
    }
  ],
  // 공동이용매출구분코드
  'commonMerchantUseDivisionCode': [
    {
      'codeValue': '1',
      'codeNm': '비씨매출'
    },
    {
      'codeValue': '2',
      'codeNm': '가맹비씨'
    },
    {
      'codeValue': '3',
      'codeNm': '미가맹비씨'
    }
  ],
  // 체크매출구분코드
  'checkSalesDivisionCode': [
    {
      'codeValue': '0',
      'codeNm': '일반매출'
    },
    {
      'codeValue': '1',
      'codeNm': '체크신용매출'
    },
    {
      'codeValue': '2',
      'codeNm': '체크계좌매출'
    },
    {
      'codeValue': '3',
      'codeNm': 'BCGiftCard'
    },
    {
      'codeValue': '5',
      'codeNm': '부정사용강제취소'
    }    
  ],
  // 입출금처리코드
  'inoutProcessCode': [
    {
      'codeValue': '0',
      'codeNm': '미대상'
    },
    {
      'codeValue': '1',
      'codeNm': '입출금성공'
    },
    {
      'codeValue': '2',
      'codeNm': '미입출금처리'
    } 
  ],
  // 카드구분코드
  'cardTypeCode': [
    {
      'codeValue': '1',
      'codeNm': '체크카드'
    },
    {
      'codeValue': '2',
      'codeNm': '신용카드'
    }
  ],
  // 자료처리구분코드
  'dataProcessDivisionCode': [
    {
      'codeValue': '0',
      'codeNm': '수신자료생성'
    },
    {
      'codeValue': '1',
      'codeNm': '매입처리완료'
    },
    {
      'codeValue': '2',
      'codeNm': '일정산처리완료'
    }
  ],
  // 매입집계구분코드
  'aggregationDivisionCode': [
    {
      'codeValue': '1',
      'codeNm': '매입집계'
    },
    {
      'codeValue': '2',
      'codeNm': '정산집계'
    }
  ],
  // 카드계정구분코드
  'accountDivisionCode': [
    {
      'codeValue': '111',
      'codeNm': '일반매출'
    },
    {
      'codeValue': '114',
      'codeNm': 'SMS이용료'
    },
    {
      'codeValue': '115',
      'codeNm': '매출캐시백'
    },
    {
      'codeValue': '116',
      'codeNm': '포인트캐시백'
    },
    {
      'codeValue': '117',
      'codeNm': '승차권취소수수료'
    },
    {
      'codeValue': '118',
      'codeNm': '기타체크신용'
    },
    {
      'codeValue': '119',
      'codeNm': '강제캐시백'
    },
    {
      'codeValue': '120',
      'codeNm': '선정산'
    },
    {
      'codeValue': '125',
      'codeNm': '온라인영세사업자매출'
    },
    {
      'codeValue': '210',
      'codeNm': '총가맹점대금'
    },
    {
      'codeValue': '211',
      'codeNm': '일반매출'
    },
    {
      'codeValue': '214',
      'codeNm': 'SMS이용료'
    },
    {
      'codeValue': '216',
      'codeNm': '포인트캐시백'
    },
    {
      'codeValue': '217',
      'codeNm': '승차권취소수수료'
    },
    {
      'codeValue': '218',
      'codeNm': '기타체크신용'
    },
    {
      'codeValue': '219',
      'codeNm': '강제캐시백'
    },
    {
      'codeValue': '220',
      'codeNm': '선정산'
    },
    {
      'codeValue': '224',
      'codeNm': '비씨부담금'
    },
    {
      'codeValue': '225',
      'codeNm': '온라인영세사업자매출'
    },
    {
      'codeValue': '402',
      'codeNm': '민원보상금'
    },
    {
      'codeValue': '416',
      'codeNm': '가맹점수수료'
    },
    // 국제
    {
      'codeValue': '151',
      'codeNm': '해외신판매출'
    },
    {
      'codeValue': '152',
      'codeNm': '해외현금인출매출'
    },
    {
      'codeValue': '250',
      'codeNm': '해외총정산대금'
    },
    {
      'codeValue': '251',
      'codeNm': '해외신판정산대금'
    },
    {
      'codeValue': '252',
      'codeNm': '해외현금인출정산대금'
    },
    {
      'codeValue': '450',
      'codeNm': '해외발급사보존수수료'
    },
    {
      'codeValue': '451',
      'codeNm': '해외현금인출수수료'
    },
    {
      'codeValue': '452',
      'codeNm': '해외이용수수료'
    },
    {
      'codeValue': '453',
      'codeNm': '해외현금수수료'
    },
    {
      'codeValue': '454',
      'codeNm': '해외매입원화환차'
    },
    {
      'codeValue': '455',
      'codeNm': '해외정산원화환차'
    },
    {
      'codeValue': '900',
      'codeNm': '송금수수료'
    }
  ],
  // 카드상태코드
  'cardStatusCode': [
    {
      'codeValue': '0',
      'codeNm': '정지(카드배송)'
    },
    {
      'codeValue': '1',
      'codeNm': '정상'
    },
    {
      'codeValue': '2',
      'codeNm': '해지'
    },
    {
      'codeValue': '3',
      'codeNm': '폐기'
    }  
  ],
  // 사고등해제구분코드
  'accidentStatusDivisionCode': [
    {
      'codeValue': '0',
      'codeNm': '등재'
    },
    {
      'codeValue': '1',
      'codeNm': '해제'
    }
  ],
  // 사고코드
  'accidentCode': [
    {
      'codeValue': '011',
      'codeNm': '분실 / 도난'
    },
    {
      'codeValue': '007',
      'codeNm': '사용자 일시정지'
    },
    {
      'codeValue': '005',
      'codeNm': '반송'
    },
    {
      'codeValue': '006',
      'codeNm': '배송정지'
    },
    {
      'codeValue': '081',
      'codeNm': '카드해지'
    }
  ],
  // 접수채널코드
  'accidentReceiptDivisionCode': [
    {
      'codeValue': '01',
      'codeNm': '본사전화'
    },
    {
      'codeValue': '02',
      'codeNm': '방문'
    },
    {
      'codeValue': '03',
      'codeNm': '영업점전화'
    },
    {
      'codeValue': '04',
      'codeNm': 'FAX'
    },
    {
      'codeValue': '05',
      'codeNm': 'ARS'
    },
    {
      'codeValue': '06',
      'codeNm': '단말입력'
    },
    {
      'codeValue': '07',
      'codeNm': '고객사'
    },
    {
      'codeValue': '08',
      'codeNm': '정정'
    }
  ],
  // 카드발송유형코드
  'sndTpCode': [
    {
      'codeValue': '1',
      'codeNm': '본인수령'
    },
    {
      'codeValue': '2',
      'codeNm': '관리영업점송부'
    },
    {
      'codeValue': '3',
      'codeNm': '제3영업점송부'
    },
    {
      'codeValue': '4',
      'codeNm': '본인특송'
    }
  ],
  // 발송상태구분코드
  'issueStatusCode': [
    {
      'codeValue': '1',
      'codeNm': '발송'
    },
    {
      'codeValue': '2',
      'codeNm': '수령'
    },
    {
      'codeValue': '3',
      'codeNm': '반송'
    },
    {
      'codeValue': '4',
      'codeNm': '재발송'
    },
    {
      'codeValue': '5',
      'codeNm': '폐기'
    },
    {
      'codeValue': '6',
      'codeNm': '고객등록'
    },
    {
      'codeValue': '7',
      'codeNm': '반송입고'
    }
  ],
  // 배송업체코드
  'sndClssCode': [
    {
      'codeValue': '1',
      'codeNm': 'LMC본인'
    },
    {
      'codeValue': '3',
      'codeNm': '중국현지법인'
    },
    {
      'codeValue': '4',
      'codeNm': '국제본인'
    },
    {
      'codeValue': '5',
      'codeNm': '제니엘본인'
    },
    {
      'codeValue': '6',
      'codeNm': '국제영업점'
    },
    {
      'codeValue': '7',
      'codeNm': '제니엘영업점'
    },
    {
      'codeValue': '8',
      'codeNm': '우리영업점'
    },
    {
      'codeValue': '9',
      'codeNm': 'LMC영업점'
    },
    {
      'codeValue': 'A',
      'codeNm': '국제본인'
    },
    {
      'codeValue': 'B',
      'codeNm': '국제영업점'
    },
    {
      'codeValue': 'C',
      'codeNm': '제니엘본인'
    },
    {
      'codeValue': 'D',
      'codeNm': '제니엘영업점'
    },
    {
      'codeValue': 'E',
      'codeNm': '중국현지'
    },
    {
      'codeValue': 'F',
      'codeNm': '우리영업점'
    },
    {
      'codeValue': 'G',
      'codeNm': '한승본인'
    },
    {
      'codeValue': 'H',
      'codeNm': '한승영업점'
    },
    {
      'codeValue': 'I',
      'codeNm': '성우본인'
    },
    {
      'codeValue': 'J',
      'codeNm': '성우영업점'
    },
    {
      'codeValue': 'K',
      'codeNm': 'LMC본인'
    },
    {
      'codeValue': 'L',
      'codeNm': 'LMC영업점'
    }
  ],
  //본인과의 관계
  'rcpRelCode': [
    {
      'codeValue': '01',
      'codeNm': '본인'
    },
    {
      'codeValue': '02',
      'codeNm': '조부'
    },
    {
      'codeValue': '03',
      'codeNm': '조모'
    },
    {
      'codeValue': '04',
      'codeNm': '아버지'
    },
    {
      'codeValue': '05',
      'codeNm': '어머니'
    },
    {
      'codeValue': '06',
      'codeNm': '처'
    },
    {
      'codeValue': '07',
      'codeNm': '남편'
    },
    {
      'codeValue': '08',
      'codeNm': 'LMC영형업점'
    },
    {
      'codeValue': '09',
      'codeNm': '동생'
    },
    {
      'codeValue': '10',
      'codeNm': '언니'
    },
    {
      'codeValue': '11',
      'codeNm': '누나'
    },
    {
      'codeValue': '12',
      'codeNm': '아들'
    },
    {
      'codeValue': '13',
      'codeNm': '딸'
    },
    {
      'codeValue': '14',
      'codeNm': '손자'
    },
    {
      'codeValue': '15',
      'codeNm': '손녀'
    },
    {
      'codeValue': '16',
      'codeNm': '며느리'
    },
    {
      'codeValue': '17',
      'codeNm': '삼촌'
    },
    {
      'codeValue': '18',
      'codeNm': '사촌'
    },
    {
      'codeValue': '19',
      'codeNm': '친척'
    },
    {
      'codeValue': '20',
      'codeNm': '직장동료'
    },
    {
      'codeValue': '21',
      'codeNm': '상사'
    },
    {
      'codeValue': '22',
      'codeNm': '친구'
    },
    {
      'codeValue': '23',
      'codeNm': '선배'
    },
    {
      'codeValue': '24',
      'codeNm': '후배'
    },
    {
      'codeValue': '25',
      'codeNm': '은행원'
    },
    {
      'codeValue': '26',
      'codeNm': '배우자'
    },
    {
      'codeValue': '27',
      'codeNm': '자녀'
    },
    {
      'codeValue': '28',
      'codeNm': '부모'
    },
    {
      'codeValue': '29',
      'codeNm': '형제자매'
    },
    {
      'codeValue': '98',
      'codeNm': '우체국등기'
    },
    {
      'codeValue': '99',
      'codeNm': '기타'
    }
  ],
  // 반송사유코드
  'backRsonNoCode': [
    {
      'codeValue': '01',
      'codeNm': '수취인불명'
    },
    {
      'codeValue': '02',
      'codeNm': '이사불명'
    },
    {
      'codeValue': '03',
      'codeNm': '주소불명'
    },
    {
      'codeValue': '04',
      'codeNm': '장기부재'
    },
    {
      'codeValue': '05',
      'codeNm': '수취인거부'
    },
    {
      'codeValue': '06',
      'codeNm': '기타'
    },
    {
      'codeValue': '07',
      'codeNm': '기타'
    },
    {
      'codeValue': '08',
      'codeNm': '기타'
    },
    {
      'codeValue': '09',
      'codeNm': '기타'
    }
  ],
  // 발급진행정보코드
  'pgrsClssCode': [
    {
      'codeValue': '00',
      'codeNm': '보통 미발급시'
    },
    {
      'codeValue': '01',
      'codeNm': '원장생성'
    },
    {
      'codeValue': '02',
      'codeNm': '발급자료생성'
    },
    {
      'codeValue': '03',
      'codeNm': '발급완료'
    },
    {
      'codeValue': '04',
      'codeNm': '봉입완료'
    },
    {
      'codeValue': '05',
      'codeNm': '발송확정'
    },
    {
      'codeValue': '06',
      'codeNm': 'SMS전송'
    },
    {
      'codeValue': '07',
      'codeNm': '발송완료'
    },
    {
      'codeValue': '13',
      'codeNm': '반송'
    },
    {
      'codeValue': '14',
      'codeNm': '배송대기'
    },
    {
      'codeValue': '20',
      'codeNm': '등기발송'
    },
    {
      'codeValue': '41',
      'codeNm': '영업점배송완료'
    },
    {
      'codeValue': '51',
      'codeNm': '본인배송완료'
    },
    {
      'codeValue': '52',
      'codeNm': '회원직접수령'
    },
    {
      'codeValue': '53',
      'codeNm': '직원수령'
    },
    {
      'codeValue': '54',
      'codeNm': '영업점발송'
    },
    {
      'codeValue': '55',
      'codeNm': '등기수령'
    },
    {
      'codeValue': '56',
      'codeNm': '영업점배송완료'
    },
    {
      'codeValue': '57',
      'codeNm': '회원사발송'
    },
    {
      'codeValue': '91',
      'codeNm': '발급보류'
    },
    {
      'codeValue': '92',
      'codeNm': '발급보류해제'
    },
    {
      'codeValue': '93',
      'codeNm': '폐기'
    }
  ],
  // 처리결과코드Map
  'rsltClssCode': [
    {
      'codeValue': '1',
      'codeNm': '정상'
    },
    {
      'codeValue': '2',
      'codeNm': '불능'
    }
  ],
  // 회계계정코드명
  'accountNoCodeName': [
    {
      'codeValue': '27401',
      'codeNm': '원화체크카드기타예수금'
    },
    {
      'codeValue': '41300',
      'codeNm': '체크카드원화수입수수료'
    },
    {
      'codeValue': '13401',
      'codeNm': '체크카드관련가지급금'
    },
    {
      'codeValue': '83301',
      'codeNm': '체크카드캐시백(원화)'
    },
    {
      'codeValue': '12001',
      'codeNm': '체크카드캐시백취소미수금'
    },
    {
      'codeValue': '96001',
      'codeNm': '잡손실'
    },
    {
      'codeValue': '93001',
      'codeNm': '잡이익'
    },
    {
      'codeValue': '85300',
      'codeNm': '카드사고보상금'
    },
    {
      'codeValue': '43001067838',
      'codeNm': '가맹점대금BC계좌'
    }
  ],
  // 회계처리구분코드
  'accountingProcessDivisionCodeName': [
    {
      'codeValue': '1',
      'codeNm': '계좌'
    },
    {
      'codeValue': '2',
      'codeNm': '계정'
    }
  ],
  // 국내외구분코드
  'domesticCode': [
    {
      'codeValue': '1',
      'codeNm': '국내'
    },
    {
      'codeValue': '2',
      'codeNm': '국외'
    },
  ],
  // URL에 따른 제목
  'urlTitle': [
    {
      'codeValue': '/ProductManagement',
      'codeNm': '상품정보관리'
    },
    {
      'codeValue': '/CardBin',
      'codeNm': 'BIN정보관리'
    },
    {
      'codeValue': '/proxyFeeCodeManagement',
      'codeNm': '대행수수료 코드관리'
    },
    {
      'codeValue': '/ExchangeRate',
      'codeNm': '환율정보 조회'
    },
    {
      'codeValue': '/OverseasCsRefund',
      'codeNm': '해외민원환불'
    },
    {
      'codeValue': '/merchantList',
      'codeNm': '가맹점정보'
    },
    {
      'codeValue': '/MerchantGroupMgmt',
      'codeNm': '그룹 가맹점관리'
    },
    {
      'codeValue': '/MerchantMasterMgmt',
      'codeNm': '대표 가맹점관리'
    },
    {
      'codeValue': '/addcard',
      'codeNm': '카드 발급신청'
    },
    {
      'codeValue': '/cardMember',
      'codeNm': '회원정보 관리'
    },
    {
      'codeValue': '/aprvAgrg',
      'codeNm': '발급집계내역 조회'
    },
    {
      'codeValue': '/vipCardInfo',
      'codeNm': 'VIP 카드번호 관리'
    },
    {
      'codeValue': '/cardDeliveryInfo',
      'codeNm': '카드 발급/배송 조회'
    },
    {
      'codeValue': '/cardReturnManage',
      'codeNm': '반송카드관리'
    },
    {
      'codeValue': '/accidentMgmt',
      'codeNm': '사고관리'
    },
    {
      'codeValue': '/approvalList',
      'codeNm': '국내승인내역 조회'
    },
    {
      'codeValue': '/approvalAggr',
      'codeNm': '국내승인집계내역 조회'
    },
    {
      'codeValue': '/approvalHistoryLog',
      'codeNm': '국내승인대사내역 조회'
    },
    {
      'codeValue': '/purchasedTypeHistory',
      'codeNm': '이용내역 조회'
    },
    {
      'codeValue': '/OverseasApprList',
      'codeNm': '해외승인내역 조회'
    },
    {
      'codeValue': '/OverseasApprHistoryLogList',
      'codeNm': '해외승인대사내역 조회'
    },
    {
      'codeValue': '/purchasedHistory',
      'codeNm': '국내매입내역 조회'
    },
    {
      'codeValue': '/purchasedAggrHistory',
      'codeNm': '국내매입집계 조회'
    },
    {
      'codeValue': '/onlineBuismHistory',
      'codeNm': '온라인사업자재정산내역 조회'
    },
    {
      'codeValue': '/cancelEdiPayHistory',
      'codeNm': 'EDI취소선지급내역 조회'
    },
    {
      'codeValue': '/nonInoutInfo',
      'codeNm': '미입출금내역 조회'
    },
    {
      'codeValue': '/salesDetails',
      'codeNm': '매출내역 조회'
    },
    {
      'codeValue': '/settlement',
      'codeNm': '국내정산대금내역 조회'
    },
    {
      'codeValue': '/settlementAggrList',
      'codeNm': '국내정산집계 조회'
    },
    {
      'codeValue': '/settlementAuto',
      'codeNm': '대행수수료 정산내역 조회'
    },
    {
      'codeValue': '/SettlementProxyFee',
      'codeNm': '대행수수료 자동대사 조회'
    },
    {
      'codeValue': '/accountProcAggr',
      'codeNm': '계정처리집계 조회'
    },
    {
      'codeValue': '/accountProcList',
      'codeNm': '계정처리내역 조회'
    },
    {
      'codeValue': '/codeMgmt',
      'codeNm': '공통코드관리'
    },
    {
      'codeValue': '/batchInfo',
      'codeNm': '배치관리'
    },
    {
      'codeValue': '/foreignSndrvLogList',
      'codeNm': '대외송수신로그 조회'
    },
    {
      'codeValue': '/holidayInformation',
      'codeNm': '휴일정보관리'
    }
  ],
  // 국제정산상태구분코드
  'overseasSettlementProcessStatusCode' : [
    {
      'codeValue': '0',
      'codeNm': '데이터생성'
    },
    {
      'codeValue': '2',
      'codeNm': '정산금액최종확정'
    },
    {
      'codeValue': '9',
      'codeNm': '최종완료'
    }
  ],
  // 국제매입처리상태구분코드
  'overseasPurchasedProcessDivisionCode' : [
    {
      'codeValue': '0',
      'codeNm': '일반매입'
    },
    {
      'codeValue': '1',
      'codeNm': '부분매입'
    },
    {
      'codeValue': '2',
      'codeNm': '추가매입'
    },
    {
      'codeValue': '3',
      'codeNm': '강제매입'
    }
  ],
  // 매출종류구분코드
  'overseasSalesKindDivisionCode': [
    {
      'codeValue': '05',
      'codeNm': '신판'
    },
    {
      'codeValue': '06',
      'codeNm': '현금'
    },
    {
      'codeValue': '15',
      'codeNm': '신판취소'
    },
    {
      'codeValue': '16',
      'codeNm': '현금취소'
    },
    {
      'codeValue': '35',
      'codeNm': '신판RVS'
    },
    {
      'codeValue': '36',
      'codeNm': '현금RVS'
    },
    {
      'codeValue': '65',
      'codeNm': '신판취소RVS'
    },
    {
      'codeValue': '95',
      'codeNm': '선거절'
    }
  ],  
}

export default {
  codeData
};

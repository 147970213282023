import gql from 'graphql-tag';

// 해외민원환불처리
// 해외민원전금내역 목록 조회 
export const overseasCsRefundPlenum = gql`
    mutation overseasCsRefundPlenumList(
        $trmDate: String,
        $overseasCsReceveDate: String,
        $overseasCsEndDate: String,
        $cardNo: String,
        $userKorName: String,
        $cardSeq: String,
        $rewardProgressStatusCode: String,
        $purchasedProcessNo: String
    ) {
        overseasCsRefundPlenumList(
            trmDate: $trmDate,
            overseasCsReceveDate: $overseasCsReceveDate,
            overseasCsEndDate: $overseasCsEndDate,
            cardNo: $cardNo,
            userKorName: $userKorName,
            cardSeq: $cardSeq,
            rewardProgressStatusCode: $rewardProgressStatusCode,
            purchasedProcessNo: $purchasedProcessNo
        ){
            overseasCsTrmSeq
            trmDate
            trmStatusCode
            trmUsdAmount
            trmKrwAmount
            trmApplyExchangeRate
            trmUsdProcessAmount
            exchangeRatePflsDivisionCode
            exchangeRateDiffAmount
            accountProcessSeq
            remarkDescription
            firstOperateAdminId
            firstOperateDetailDate
            firstOperateTradeCode
            lastOperateAdminId
            lastOperateDetailDate
            lastOperateTradeCode
        }
    }
`;

// 민원보상대상 전체내역 목록 조회 
export const overseasCsRefundWhole = gql`
    mutation overseasCsRefundWholeList(
        $trmDate: String,
        $overseasCsReceveDate: String,
        $overseasCsEndDate: String,
        $cardNo: String,
        $userKorName: String,
        $cardSeq: String,
        $rewardProgressStatusCode: String,
        $purchasedProcessNo: String
    ) {
        overseasCsRefundWholeList(
            trmDate: $trmDate,
            overseasCsReceveDate: $overseasCsReceveDate,
            overseasCsEndDate: $overseasCsEndDate,
            cardNo: $cardNo,
            userKorName: $userKorName,
            cardSeq: $cardSeq,
            rewardProgressStatusCode: $rewardProgressStatusCode,
            purchasedProcessNo: $purchasedProcessNo
        ){
            overseasCsRewardSeq
            overseasCsRewardProcessDate
            rewardProgressStatusCode
            rewardSalesKrwAmount
            overseasCsTrmSeq
            trmDate
            trmStatusCode
            trmUsdAmount
            trmApplyExchangeRate
            overseasCsReceveDate
            overseasCsEndDate
            cardNo
            salesKindDivisionCode
            purchasedProcessNo
            userKorName
            merchantEngName
            overseasPaymentUsdAmount
            rewardCashFeeKrwAmount
            trmUsdProcessAmount
        }
    }
`;

// 민원선정대상 선정 업데이트
export const overseasCsRefundUpdate = gql`
    mutation overseasCsRefundUpdateYN(
        $checkedItemSearch: [checkedItemSearchType],
        $overseasCsReceveDate: String,
        $overseasCsEndDate: String
    ) {
        overseasCsRefundUpdateYN(
            checkedItemSearch: $checkedItemSearch,
            overseasCsReceveDate: $overseasCsReceveDate,
            overseasCsEndDate: $overseasCsEndDate
        ){
            success
            message
        }
    }
`;

// 민원선정대상 목록 조회
export const overseasCsRefundSelect = gql`
    mutation overseasCsRefundSelectList(
        $trmDate: String,
        $overseasCsReceveDate: String,
        $overseasCsEndDate: String,
        $cardNo: String,
        $userKorName: String,
        $cardSeq: String,
        $rewardProgressStatusCode: String,
        $purchasedProcessNo: String
    ) {
        overseasCsRefundSelectList(
            trmDate: $trmDate,
            overseasCsReceveDate: $overseasCsReceveDate,
            overseasCsEndDate: $overseasCsEndDate,
            cardNo: $cardNo,
            userKorName: $userKorName,
            cardSeq: $cardSeq,
            rewardProgressStatusCode: $rewardProgressStatusCode,
            purchasedProcessNo: $purchasedProcessNo
        ){
            rewardProgressStatusCode
            overseasCsRewardProcessDate
            csTargetConfirmDate
            applyExchangeRate
            trmUsdProcessAmount
            rewardSalesKrwAmount
            rewardKrwProfitFee
            rewardOverseasUseKrwFee
            rewardCashFeeKrwAmount
            exchangeRatePflsDivisionCode
            exchangeRateDiffAmount
            csRewardKrwTotalAmount
            overseasCsRewardSeq
            overseasCsReceveDate
            overseasCsTrmSeq
            trmDate
        }
    }
`;    

// 민원선정대상 최종확정 처리
export const overseasCsDecide = gql`
    mutation overseasCsDecideYN(
        $decideCheckedItemV: [decideCheckedItemVType]
    ) {
        overseasCsDecideYN(
            decideCheckedItemV: $decideCheckedItemV
        ){
            success
            message
        }
    }
`;

// 수기종결 처리 
export const overseasCsWrite = gql`
    mutation overseasCsWriteYN(
        $decideCheckedItemV: [decideCheckedItemVType]
    ) {
        overseasCsWriteYN(
            decideCheckedItemV: $decideCheckedItemV
        ){
            success
            message
        }
    }
`;

// 수기종결 완료 후 전금내역 update
export const handleCloseTrmUpdate = gql`
  mutation handleCloseTrmUpdateYN($input : refundProcessLastInsertResultType) {
    handleCloseTrmUpdateYN (input : $input) {
        success
        message
    }
  }
`;

// Pop 목록 조회 
export const overseasCsRefundSelectPop = gql`
    mutation overseasCsRefundSelectPopList(
        $trmDate: String,
        $overseasCsReceveDate: String,
        $overseasCsEndDate: String,
        $cardNo: String,
        $userKorName: String,
        $cardSeq: String,
        $rewardProgressStatusCode: String,
        $purchasedProcessNo: String
    ) {
        overseasCsRefundSelectPopList(
            trmDate: $trmDate,
            overseasCsReceveDate: $overseasCsReceveDate,
            overseasCsEndDate: $overseasCsEndDate,
            cardNo: $cardNo,
            userKorName: $userKorName,
            cardSeq: $cardSeq,
            rewardProgressStatusCode: $rewardProgressStatusCode,
            purchasedProcessNo: $purchasedProcessNo
        ){
            rewardProgressStatusCode
            overseasCsRewardProcessDate
            csTargetConfirmDate
            applyExchangeRate
            trmUsdProcessAmount
            rewardSalesKrwAmount
            rewardKrwProfitFee
            rewardOverseasUseKrwFee
            rewardCashFeeKrwAmount
            exchangeRatePflsDivisionCode
            exchangeRateDiffAmount
            csRewardKrwTotalAmount
            overseasCsRewardSeq
            overseasCsReceveDate
            merchantEngName
            salesKindDivisionCode
            overseasCsTrmSeq
            trmDate
            userId
            cardNo
            cardSeq
            accountProcessSeq
            inoutProcessSeq
        }
    }
`;

// 환불(입금)처리
export const refundProcess = gql`
  mutation refundProcessResult($input : refundProcessResultType) {
    refundProcessResult (input : $input) {
      result
    }
  }
`;

// 환불(입금)처리 완료 후 환차처리
export const refundProcessLastInsert = gql`
  mutation refundProcessLastInsertResult($input : refundProcessLastInsertResultType) {
    refundProcessLastInsertResult (input : $input) {
      result
    }
  }
`;

// 미입출금내역 등록
export const insertNonInOutInfo = gql`
  mutation insertNonInOutInfoResult($input : refundProcessResultNonType) {
    insertNonInOutInfoResult (input : $input) {
      result
    }
  }
`;
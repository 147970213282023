import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const StyledTableCell = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = { className?: string; merchantInfo: GetMerchantDetail };
const CustomerInfo: FC<Props> = ({ className, merchantInfo }) => {
  const classes = useStyles();

  if (merchantInfo === undefined) {
    return (
      <Card className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="가맹점 상세 정보" />
          <Divider />
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>선택한 가맹점이 없습니다.</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Divider />
        </form>
      </Card>
    );
  }

  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <form>
          <CardHeader title="가맹점 상세 정보 - 체크(비씨)" />
          <Divider />
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>등록일자</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.dateFormat(merchantInfo.registeredDate, null)}</StyledTableCell>
                <StyledTableCell>가맹점번호</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantNo}</StyledTableCell>
                <StyledTableCell>사업자등록번호</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.bizNoFormat(merchantInfo.merchantBizNo)}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>가맹점명</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantName}</StyledTableCell>
                <StyledTableCell>영문가맹점명</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantEngName}</StyledTableCell>
                <StyledTableCell>대표자명</StyledTableCell>
                <StyledTableCell>{merchantInfo.officerName}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>업종코드</StyledTableCell>
                <StyledTableCell>{merchantInfo.businessCode}</StyledTableCell>
                <StyledTableCell>업종부코드</StyledTableCell>
                <StyledTableCell>{merchantInfo.businessSubCode}</StyledTableCell>
                <StyledTableCell>가맹점전화번호</StyledTableCell>
                <StyledTableCell>
                  {merchantInfo.telNo1} {merchantInfo.telNo2} {merchantInfo.telNo3}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>해지일자</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.dateFormat(merchantInfo.terminateDate, null)}</StyledTableCell>
                <StyledTableCell>판매구분</StyledTableCell>
                <StyledTableCell>{merchantInfo.salesDivision}</StyledTableCell>
                <StyledTableCell>EDC가맹점여부</StyledTableCell>
                <StyledTableCell>{merchantInfo.edcMerchantYn}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>대금지급주기</StyledTableCell>
                <StyledTableCell>{merchantInfo.settlementProcessCycle} 일</StyledTableCell>
                <StyledTableCell>수수료율</StyledTableCell>
                <StyledTableCell>{merchantInfo.feeRate} %</StyledTableCell>
                <StyledTableCell>체크카드수수료율</StyledTableCell>
                <StyledTableCell>{merchantInfo.cardFeeRate} %</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>지점지로번호</StyledTableCell>
                <StyledTableCell>{merchantInfo.branchGiroNo}</StyledTableCell>
                <StyledTableCell>거래제한여부</StyledTableCell>
                <StyledTableCell>{merchantInfo.tradeBlockYn}</StyledTableCell>
                <StyledTableCell>계좌은행코드</StyledTableCell>
                <StyledTableCell>{merchantInfo.accountBankCode}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>거래제한등재일자</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.dateFormat(merchantInfo.tradeBlockStartDate, null)}</StyledTableCell>
                <StyledTableCell>거래제한해제일자</StyledTableCell>
                <StyledTableCell>{chaiStringUtils.dateFormat(merchantInfo.tradeBlockEndDate, null)}</StyledTableCell>
                <StyledTableCell>할부월수</StyledTableCell>
                <StyledTableCell>{merchantInfo.installmentMonth}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>결제계좌번호</StyledTableCell>
                <StyledTableCell>{merchantInfo.paymentAcno}</StyledTableCell>
                <StyledTableCell>사업장신구주소구분코드</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantAddressDivisionCode}</StyledTableCell>
                <StyledTableCell>사업장동이상신규주소</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantRoadAddress01}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>사업장동이하신규주소</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantRoadAddress02}</StyledTableCell>
                <StyledTableCell>사업장도로명관리번호</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantRoadAddressManagementNo}</StyledTableCell>
                <StyledTableCell>가맹점우편번호</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantPostNo}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>가맹점기타주소</StyledTableCell>
                <StyledTableCell>{merchantInfo.merchantEtcAddress}</StyledTableCell>
                <StyledTableCell>비고내용</StyledTableCell>
                <StyledTableCell>{merchantInfo.remarkDescription}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Divider />
        </form>
      </Card>
    </>
  );
};

export default CustomerInfo;

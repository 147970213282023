import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  thStyle: {
    backgroundColor: '#fafafa'
  },
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '120px'
  }
}));

type Props = { 
  className?: string,
  detailInfo: PurchasedAggrHistoryDetailInfo 
}

const ViewInfo: FC<Props> = ({ className, detailInfo }) => {
  const classes = useStyles();

  if(detailInfo === undefined) {
    return (
      <Card className={clsx(classes.root, className)} ></Card>
    );
  }

  return (
    <Card className={clsx(classes.root, className)}  >
        <Divider />
        <Table size="small" >
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>출금건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.withdrawalCount)} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>입금건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.depositCount)} 건</TableCell>
                <TableCell align="center" className={classes.thStyle}>총건수</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.tradeProcessCount)} 건</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.thStyle}>출금금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.withdrawalTotalAmount)} 원</TableCell>
                <TableCell align="center" className={classes.thStyle}>입금금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.depositTotalAmount)} 원</TableCell>
                <TableCell align="center" className={classes.thStyle}>총금액</TableCell>
                <TableCell align="right" className={classes.tdStyle}>{chaiStringUtils.moneyFormat(detailInfo.tradeTotalAmount)} 원</TableCell>
              </TableRow>
            </TableBody>          
          </Table>
    </Card>
  );
};

export default ViewInfo;



import client from './client';

export default async (cardNo: string, password: string) => {
  return client
    .post(`/root/dips/card/passwordChange`, getBody(cardNo, password))
    .then(res => {
      console.log('비밀번호 변경할 카드번호:' + cardNo);
      console.log('변경할 비밀번호:' + password);
      /*const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '00') {
      throw new Error(`비밀번호 변경 실패 - 알수없는 에러`)
    }*/

      //return res.data.body;
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const getBody = (cardNo: string, password: string) => {
  return {
    CurCardNoEnc: cardNo,
    passWord: password,
  };
};

import React, { FC, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
// import {format, subMonths} from 'date-fns'
import apolloClient from '../../lib/apolloClient';
import {approvalAggrMutation} from '../../lib/apolloClient/mutations';

type ProductInfo = {
  cardProductCode :string;
  cardProductCodeName :string;
}

type Props = {
  onSearch: (searchForm: { 
    type                      :string;  //list, detail
    userId                    :string;  // 고객번호
    cardProductCode           :string;  // 카드상품코드
    vipCardNo                 :string;  // VIP카드번호
    cardNoStatusDivisionCode  :string;  // 카드발급여부
  }) => void;
}

const VipCardToolbar: FC<Props> = ({onSearch}) => {  
  
  const [productList, setProductList] = useState<ProductInfo[]>([]);
  const [searchForm, setSearchForm] = useState({
    type: '',
    userId: '',
    cardProductCode: '',
    vipCardNo: '',
    cardNoStatusDivisionCode: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // 카드상품 목록 조회
  useEffect(() => {
    const fetch = async () => {
      await apolloClient.mutate({
        variables: { useYn: 'Y' },
        mutation: approvalAggrMutation.selectProductList
      }).then(res => {
        setProductList(res.data.selectProductList);
      }).catch(console.error)}
    fetch()
  }, []);

  const onSearchClick = async () => {
    onSearch(searchForm);
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };
  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="카드상품"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardProductCode"
                value={searchForm.cardProductCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                {productList.map(item => (
                  <option
                    key={item.cardProductCode}
                    value={item.cardProductCode}
                  >
                    {item.cardProductCodeName}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                label="고객번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="userId"
                value={searchForm.userId}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                label="VIP카드번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="vipCardNo"
                value={searchForm.vipCardNo}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="카드발급여부"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardNoStatusDivisionCode"
                value={searchForm.cardNoStatusDivisionCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="1">발급완료</option>
                <option value="0">발급전</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default VipCardToolbar;

import client from './client';

export default async (cardSeq: string) => {  
  return client.post(`/root/card/vipCardDelete`, getBody(cardSeq)).then(res => {
    console.log('삭제할 VIP 카드번호 SEQ:'+cardSeq);
    console.log(res);
    const statusCode = res.status;
    if (!statusCode || statusCode !== 200) {
      throw new Error(`VIP 카드번호 삭제 실패-!`)
    }    
    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (cardSeq: string) => {
  return {
    cardSeq: cardSeq
  }
}
import client from './client';

export default async (cardNo: string) => {
  return client
    .post(`/root/dips/card/passwordErrorReset`, getBody(cardNo))
    .then(res => {
      console.log('비밀번호 오류횟수 초기화할 카드번호:' + cardNo);

      /*const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '00') {
      throw new Error(`비밀번호 오류횟수 초기화 실패 - 알수없는 에러`)
    }*/

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const getBody = (cardNo: string) => {
  return {
    cardNoEnc: cardNo,
  };
};

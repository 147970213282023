import React, { FC, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    //minWidth: 2000
  }
}));

type Props = { 
  dataList: CancelEdiPayHistoryList[]
}

const CancelEdiPayHistoryTable: FC<Props> = ({ dataList }) => {
  const classes = useStyles();

  const default_page = 0;
  const default_rowsPerPage = 100;
  const [rowsPerPage, setRowsPerPage] = useState(default_rowsPerPage);
  const [page, setPage] = useState(default_page);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  useEffect(() => {
    setPage(default_page);
    setRowsPerPage(default_rowsPerPage);
  }, [dataList])

  return (
    <Card>
      <CardContent className={classes.content}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Chai정산일자</TableCell>
                <TableCell align="center">카드번호</TableCell>
                <TableCell align="center">거래일자</TableCell>
                <TableCell align="center">매출합계금액</TableCell>
                <TableCell align="center">입금일자</TableCell>
                <TableCell align="center">입금시간</TableCell>
                <TableCell align="center">입금금액</TableCell>
                <TableCell align="center">이체처리상태</TableCell>
                <TableCell align="center">선지급결과</TableCell>
                <TableCell align="center">전표매입여부</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((data, index) => (
                <TableRow hover key={index} >
                  <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.receveBaseDate)}</TableCell>
                  <TableCell align="center">{data.cardNoMasked}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.salesDate)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.salesTotalAmount)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateFormatDefault(data.depositDate)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.dateTimeFormat(data.depositTime)}</TableCell>
                  <TableCell align="right">{chaiStringUtils.moneyFormat(data.depositAmount)}</TableCell>
                  <TableCell align="center">{chaiStringUtils.codeName('transferStatusCode', data.transferStatusCode)}</TableCell>
                  <TableCell align="center">{data.prepayResultCode}</TableCell>
                  <TableCell align="center">{data.slipPurchaseYn}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
    </Card>
  );
};

export default CancelEdiPayHistoryTable;

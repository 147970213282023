import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer',
  },
}));

type Props = {
  uploadList: any[];
};

const UploadList: FC<Props> = ({ uploadList }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  if (uploadList.length === 0) {
    return (
      <Card>
        <CardHeader title="업로드 목록" />
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>내용이 없습니다.</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="업로드 목록" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">그룹가맹점번호</TableCell>
                  <TableCell align="center">그룹가맹점명</TableCell>
                  <TableCell align="center">제휴대상번호</TableCell>
                  <TableCell align="center">제휴대상명</TableCell>
                  <TableCell align="center">해지일자</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((obj, index) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">{obj.그룹가맹점번호}</TableCell>
                    <TableCell align="center">{obj.그룹가맹점명}</TableCell>
                    <TableCell align="center">{obj.제휴대상번호}</TableCell>
                    <TableCell align="center">{obj.제휴대상명}</TableCell>
                    <TableCell align="center">{obj.해지일자}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={uploadList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default UploadList;

import React, { useState, FC } from 'react';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles, Button } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

import { deleteVipCard } from '../../lib/cardApi';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { list: VipCardInfo[]; refreshVipCardList: () => Promise<void> };
const VipCardTable: FC<Props> = ({ list, refreshVipCardList }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  //해당 VIP 카드번호 삭제
  const setVipCardDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const vipCardSeq = event.currentTarget.value;

    showDialog('VIP 카드번호 삭제', '선택한 VIP 카드번호를 삭제 하시겠습니까?', () => {
      try {
        console.log(vipCardSeq + ' 삭제!!');
        deleteVipCard(vipCardSeq).then(res => {
          showToast('VIP 카드삭제가 정상적으로 완료되었습니다.', 'info');

          //삭제 후, 리스트 RELOAD
          refreshVipCardList();

          hideDialog();
        });
      } catch (e) {
        showToast(e.message, 'error');
        hideDialog();
      }
    });
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">고객번호</TableCell>
                  <TableCell align="center">카드상품코드</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">카드발급여부</TableCell>
                  <TableCell align="center">등록일자</TableCell>
                  {/*<TableCell align="center">발급일자</TableCell>*/}
                  <TableCell align="center">삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                  <TableRow hover key={item.cardSeq + '' + item.cardProductCode}>
                    <TableCell align="center">{rowsPerPage * (page + 1) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{item.userId}</TableCell>
                    <TableCell align="center">{item.cardProductCode}</TableCell>
                    <TableCell align="center">
                      {item.cardNoMasked}
                      {/** vip 카드번호는 일련번호 9자리를 본인이 세팅하므로(=확인해야 하므로) 일단 마스킹처리를 안한다. */}
                      {/*chaiStringUtils.cardNoMaskFormat(chaiStringUtils.decrypt(item.cardNo))*/}
                    </TableCell>
                    <TableCell align="center">
                      {item.cardNoStatusDivisionCode === '1' ? '발급완료' : '미발급'}
                    </TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormat(item.firstOperateDetailDate, 'yyyy.MM.dd')}
                    </TableCell>
                    {/*<TableCell align="center">
                      {chaiStringUtils.dateFormat(item.issueDate, 'yyyy.MM.dd')}
                </TableCell>*/}
                    <TableCell align="center">
                      {item.cardNoStatusDivisionCode === '0' && (
                        <Button color="secondary" variant="contained" onClick={setVipCardDelete} value={item.cardSeq}>
                          Remove
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default VipCardTable;

import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Theme,
    makeStyles,
    Card,
    CardHeader,
    Divider,
    CardActions,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    dialogContent: {
      minWidth: '400px',
    },
    icon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    inner: {
      minWidth: 1050
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    isFinalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    status: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
    },
    cursor_pointer: {
      cursor: 'pointer'
    },
    thStyle: {
      backgroundColor: '#fafafa',
    },
    thSumStyle: {
      backgroundColor: '#eeeeee',
    }, 
}));

type Props = {
    title: string,
    calculateBreakdownList: overseasCalculateBreakdownList[]
};

const OverseasCalculateTable: FC<Props> = ({
    title,
    calculateBreakdownList
}) => {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    };

    return (
        <Card>
          { !!title &&
            <CardHeader title={title}/>
          }
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" rowSpan={2}>NO</TableCell>
                      <TableCell align="center" rowSpan={2}>정산일자</TableCell>
                      <TableCell align="center" rowSpan={2}>카드계정구분</TableCell>
                      <TableCell align="center" colSpan={3}>정산미화금액(달러)</TableCell>
                      <TableCell align="center" colSpan={3}>정산원화금액(원)</TableCell>
                      <TableCell align="center" colSpan={2}>환율차이총액(원)</TableCell>
                      <TableCell align="center" colSpan={2}>환율정보</TableCell>
                      <TableCell align="center" rowSpan={2}>정산환율적용금액</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">출금총액</TableCell>
                      <TableCell align="center">입금총액</TableCell>
                      <TableCell align="center">거래총액</TableCell>
                      <TableCell align="center">출금총액</TableCell>
                      <TableCell align="center">입금총액</TableCell>
                      <TableCell align="center">거래총액</TableCell>
                      <TableCell align="center">매입시점</TableCell>
                      <TableCell align="center">정산시점</TableCell>
                      <TableCell align="center">매입시점</TableCell>
                      <TableCell align="center">정산시점</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {calculateBreakdownList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((calculateBreakdown, index)=>(
                        <TableRow 
                        hover 
                        key={index}
                        >
                          <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                          <TableCell align="center">{chaiStringUtils.dateFormatDefault(calculateBreakdown.settlementDate)}</TableCell>
                          <TableCell align="center">{calculateBreakdown.accountDivisionCode}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementUsdWithdrawalTotalAmount)} $</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementUsdDepositTotalAmount)} $</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementUsdTradeTotalAmount)} $</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementKrwWithdrawalTotalAmount)} 원</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementKrwDepositTotalAmount)} 원</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementKrwTradeTotalAmount)} 원</TableCell>
                          <TableCell align="center"><span style={{color:'blue'}}>{chaiStringUtils.moneyFormat(calculateBreakdown.purchasedExchangeRateDiffTotalAmount)} 원</span></TableCell>
                          <TableCell align="center"><span style={{color:'red'}}>{chaiStringUtils.moneyFormat(calculateBreakdown.settlementExchangeRateDiffTotalAmount)} 원</span></TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.dealBaseExchangeRate)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementBaseExchangeRate)}</TableCell>
                          <TableCell align="center">{chaiStringUtils.moneyFormat(calculateBreakdown.settlementExchangeRateApplyAmount)} 원</TableCell>
                       </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>     
          <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={calculateBreakdownList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
          </CardActions>  
        </Card>
    );
}

export default OverseasCalculateTable;
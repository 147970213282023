import gql from 'graphql-tag';

export const decryptCardNo = gql`
  mutation decryptCardNo($cardNo: String!) {
    decryptCardNo(cardNo: $cardNo) {
      cardNo
    }
  }
`;

export default decryptCardNo;

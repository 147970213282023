import React, { FC } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const StyledTableCell = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F4F6F8',
    },
    margin: 5,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   // backgroundColor: theme.palette.background.default,
    //   backgroundColor: '#F4F6F8',
    // },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = {
    className?:string,
    title: string,
    cardUserInfo: AccidentMgmtCardMemberInfoReturn
}
const UserDetailInfo:FC<Props> = ({ className, title, cardUserInfo }) => {
    const classes = useStyles();

    if(cardUserInfo === undefined || cardUserInfo === null) {
        return (
            <Card
            className={clsx(classes.root, className)}
        >
            <form>
            { !!title &&
              <CardHeader title={title}/>
            }
            <Divider />
            <Table>
                <TableBody>
                  <StyledTableRow >
                      <StyledTableCell>조회된 회원정보가 없습니다.</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
            </Table>
            <Divider />
            </form>
        </Card>
        );
    }

    return(
        <div>
            <Card
            className={clsx(classes.root, className)}
            >
            <form>
              { !!title &&
                <CardHeader title={title}/>
              }
                <Divider />
                <Table>
                  <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>고객번호</StyledTableCell>
                        <StyledTableCell>{cardUserInfo.userId}</StyledTableCell>
                        <StyledTableCell>한글명</StyledTableCell>
                        <StyledTableCell>{cardUserInfo.userKorName}</StyledTableCell>
                        <StyledTableCell>영문명</StyledTableCell>
                        <StyledTableCell>{cardUserInfo.userEngName}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>생년월일</StyledTableCell>
                        <StyledTableCell>{cardUserInfo.birthYm}</StyledTableCell>
                        <StyledTableCell>성별</StyledTableCell>
                        <StyledTableCell>{(cardUserInfo.sexDivisionCode === '1') ? '남자' : '여자'}</StyledTableCell>
                        <StyledTableCell>외국인구분</StyledTableCell>
                        <StyledTableCell>{(cardUserInfo.foreignerDivisionCode === '1') ? '외국인' : '내국인'}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>휴대폰번호</StyledTableCell>
                        <StyledTableCell>{chaiStringUtils.hpFormat(cardUserInfo.phoneNo1+''+cardUserInfo.phoneNo2+''+cardUserInfo.phoneNo3)}</StyledTableCell>
                        <StyledTableCell>이메일주소</StyledTableCell>
                        <StyledTableCell>{cardUserInfo.emailAddress}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>배송지주소</StyledTableCell>
                        <StyledTableCell colSpan={5}>{cardUserInfo.homeZipCode} {cardUserInfo.homeNewAddress} {cardUserInfo.homeNewDetailAddress}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <Divider />
            </form>
            </Card>
        </div>
    );
}

export default UserDetailInfo;
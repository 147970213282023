import React, { FC, useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  // CardActions,
  Divider,
  Grid,
  // Table,
  // TableBody,
  Button,
  TextField,
  // withStyles, Theme, TableCell, TableRow
} from '@material-ui/core';

import chaiStringUtils from '../../common/chaiStringUtils.js';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

import apolloClient from '../../lib/apolloClient';
import { cardBinNoMutation } from '../../lib/apolloClient/mutations';
import { insertVipCard, generateVipCard, createUserId } from '../../lib/cardApi';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
}));

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: '#F4F6F8',
    },
  },
}))(TableRow);
**/

const isEmpty = (input: string) => {
  return input === '';
};

type BinCardInfo = {
  binNo: string;
  cardProductCode: string;
};

type Props = {
  vipCardInfo: VipCardInfo;
  setVipCardInfo: React.Dispatch<React.SetStateAction<VipCardInfo>>;
  updateClick: boolean;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  refreshVipCardList: () => Promise<void>;
};
const VipCardInfo: FC<Props> = ({ vipCardInfo, updateClick, setUpdateClick, refreshVipCardList }) => {
  // const classes = useStyles();
  // const [approvalOpen, setApprovalOpen] = useState(false);
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  const [binNoList, setBinNoList] = useState<BinCardInfo[]>([]);
  const [vipCardForm, setVipCardForm] = useState({
    userHp: '',
    userId: '',
    //userName: '',
    sltBinNo: '',
    binNo: '',
    cardProductCode: '',
    chkCardNo: '',
    vipCardNo: '',
  });

  //초기화
  const initTextFields = () => {
    console.log('initTextFields');
    setIsUserInfoDisabled(false);
    setIsCardInfoDisabled(false);

    setVipCardForm({
      ...vipCardForm,
      userHp: '',
      userId: '',
      //userName: '',
      sltBinNo: '', //selectBox
      binNo: '',
      cardProductCode: '',
      chkCardNo: '',
      vipCardNo: '',
    });
    //setIsEmptyForm(false);
    setUpdateClick(!updateClick);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'userId') {
      /**회원번호 수정 시 */
      //회원정보 세팅 초기화
      setIsUserInfo(false);
      setVipCardForm({
        ...vipCardForm,
        userId: e.target.value,
      });
    } else if (e.target.name === 'sltBinNo') {
      //카드정보 세팅 초기화
      setIsCardInfo(false);

      const value = e.target.value;
      const arr = value.split(',');

      console.log('arr : ' + arr);

      //cardProductCode값 세팅
      setVipCardForm({
        ...vipCardForm,
        sltBinNo: value,
        binNo: arr[0],
        cardProductCode: arr[1],
      });
    } else if (e.target.name === 'vipCardNo') {
      //카드정보 세팅 초기화
      setIsCardInfo(false);
      setVipCardForm({
        ...vipCardForm,
        vipCardNo: e.target.value,
      });
    } else {
      setVipCardForm({
        ...vipCardForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [isValidForm, setIsValidForm] = React.useState(false);
  const [isUserInfo, setIsUserInfo] = React.useState(false);
  const [isCardInfo, setIsCardInfo] = React.useState(false);

  const [isUserInfoDisabled, setIsUserInfoDisabled] = React.useState(false);
  const [isCardInfoDisabled, setIsCardInfoDisabled] = React.useState(false);

  const checkFormValidation = () => {
    const isValid = Object.values(vipCardForm).reduce((acc, value) => {
      return !isEmpty(value) && acc;
    }, true);
    setIsValidForm(isValid);
  };

  useEffect(() => {
    checkFormValidation();
    // eslint-disable-next-line
  }, [vipCardForm]);

  // 카드BIN 목록 조회
  useEffect(() => {
    const fetch = async () => {
      await apolloClient
        .mutate({
          variables: { useYn: 'Y' },
          mutation: cardBinNoMutation.selectCardBinList,
        })
        .then(res => {
          setBinNoList(res.data.selectCardBinList);
        })
        .catch(console.error);
    };
    fetch();
  }, []);

  // 회원정보 검색
  const setUserInfo = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('==회원정보 검색 start==');

    //휴대폰번호로 회원정보 채번 API 호출
    try {
      console.log('휴대폰번호 : ' + vipCardForm.userHp);
      const userId = await createUserId(vipCardForm.userHp);
      console.log('회원ID : ' + userId);

      //회원id setting
      setVipCardForm({
        ...vipCardForm,
        userId: userId,
      });

      //회원번호 disabled 처리
      setIsUserInfoDisabled(true);
    } catch (e) {
      showToast(e.message, 'error');
      return;
    }

    setIsUserInfo(true); //회원정보 세팅 정상
  };

  // Chk genarate 채번
  const setGenerateVipCard = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      //validation chk
      if (vipCardForm.binNo === '') {
        showToast('BIN번호를 선택해 주시기 바랍니다.', 'error');
        return;
      } else if (vipCardForm.vipCardNo === '' || vipCardForm.vipCardNo.length !== 9) {
        showToast('일련번호 9자리를 정확히 입력해 주시기 바랍니다.', 'error');
        return;
      }

      generateVipCard({
        binNo: vipCardForm.binNo,
        vipCardNo: vipCardForm.vipCardNo,
      }).then(res => {
        console.log(res);
        console.log(res.cardNo);
        if (res.check) {
          console.log('true');
          //채번 후 chk cardNo setting
          const chkCardNo = res.cardNo.substring(15, 16);
          console.log('chk CardNo' + chkCardNo);
          //vipCardForm.chkCardNo = chkCardNo;
          setVipCardForm({
            ...vipCardForm,
            chkCardNo: chkCardNo,
          });

          //카드번호 disabled 처리
          setIsCardInfoDisabled(true);
        } else {
          console.log('false');
          //vipCardForm.chkCardNo = '';
          setVipCardForm({
            ...vipCardForm,
            chkCardNo: '',
          });

          showToast(
            'chk VIP 카드번호가 정상적으로 채번되지 않았습니다. 다른 일련번호 입력 후 재시도 바랍니다.',
            'error'
          );
          return;
        }
      });

      setIsCardInfo(true); //카드정보 세팅 정상
    } catch (e) {
      showToast(e.message, 'error');
      hideDialog();
    }

    console.log();
  };

  // VIP 카드번호 등록
  const setVipCardInsert = async (event: React.MouseEvent<HTMLButtonElement>) => {
    //validation chk
    if (!isUserInfo) {
      showToast(
        '회원정보가 올바르게 세팅되지 않았습니다. 회원번호 입력 후 검색버튼을 눌러 회원정보 세팅을 해주시기 바랍니다.',
        'error'
      );
      return;
    }

    if (!isCardInfo) {
      showToast(
        '카드정보가 올바르게 세팅되지 않았습니다. BIN번호, 일련번호를 입력 후 채번버튼을 눌러 카드정보 세팅을 해주시기 바랍니다.',
        'error'
      );
      return;
    }

    showDialog('VIP 카드번호 등록', '입력한 VIP 카드번호로 등록 하시겠습니까?', () => {
      try {
        console.log('==insert start==');
        console.log(vipCardForm);
        console.log('==insert end==');

        insertVipCard({
          userId: vipCardForm.userId,
          cardProductCode: vipCardForm.cardProductCode,
          cardNo: chaiStringUtils.encrypt(vipCardForm.binNo + vipCardForm.vipCardNo + vipCardForm.chkCardNo),
        }).then(res => {
          //등록 후 카드등록정보 초기화
          initTextFields();

          showToast('VIP 카드등록이 정상적으로 완료되었습니다.', 'info');

          //등록 후, 리스트 RELOAD
          refreshVipCardList();

          hideDialog();
        });
      } catch (e) {
        showToast(e.message, 'error');
        hideDialog();
      }
    });
  };

  return (
    <Card>
      <form>
        <CardHeader title="VIP 카드번호 등록정보" />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                label="회원휴대폰번호"
                //name="userId"
                name="userHp"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isUserInfoDisabled}
                margin="dense"
                value={vipCardForm.userHp}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                onClick={setUserInfo}
                disabled={isUserInfoDisabled}
                style={{ marginLeft: 10, marginTop: '8px' }}
              >
                Search
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                label="회원번호"
                name="userId"
                margin="dense"
                disabled
                variant="outlined"
                value={vipCardForm.userId}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <TextField
                select
                required
                fullWidth
                label="BIN번호"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="sltBinNo"
                value={vipCardForm.sltBinNo}
                disabled={isCardInfoDisabled}
                onChange={handleChange}
              >
                <option key="" value="">
                  선택
                </option>
                {binNoList.map(item => (
                  <option key={item.binNo + ',' + item.cardProductCode} value={item.binNo + ',' + item.cardProductCode}>
                    {item.binNo}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                label="일련번호"
                name="vipCardNo"
                margin="dense"
                disabled={isCardInfoDisabled}
                value={vipCardForm.vipCardNo}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                label="chk디짓"
                name="chkCardNo"
                margin="dense"
                value={vipCardForm.chkCardNo}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                disabled={isCardInfoDisabled}
                onClick={setGenerateVipCard}
                //value={item.vipCardSeq}
                style={{ marginLeft: 10, marginTop: '8px' }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={10} xs={12}></Grid>
            <Grid item md={2} xs={12}>
              <Button variant="contained" onClick={initTextFields} style={{ marginLeft: 10 }}>
                Reset
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={setVipCardInsert}
                disabled={!isValidForm}
                style={{ marginLeft: 10, marginTop: '8px' }}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default VipCardInfo;

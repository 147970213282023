import React, { useState, useEffect, FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { masterMerchantMgmtMutation } from '../../lib/apolloClient/mutations';
import * as utils from '../../common/utils';
import MasterMerchantMgmtToolbar from './MasterMerchantMgmtToolbar';
import MasterMerchantMgmtListTable from './MasterMerchantMgmtListTable';
import MasterMerchantMgmtDetailListTable from './MasterMerchantMgmtDetailListTable';
import { MenuTitleToolbar } from '../../components';
import ExcelUploadPop from './ExcelUploadPop';
import ExportIntoExcel from './ExportIntoExcel';

import {
  Button,
} from '@material-ui/core';
import { ExportMasterMerchantMgmtListTableInfoExcel } from './ExportMasterMerchantMgmtListTableInfoExcel';
import { ExportMasterMerchantMgmtDetailListTableInfoExcel } from './ExportMasterMerchantMgmtDetailListTableInfoExcel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
}));

type SearchInfo = {
  inputMasterMerchantNo: string,     // 대표가맹점번호
  inputMasterMerchantName: string,   // 대표가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string         // 가맹점명  
  inputCancelYn: string             // 해지여부
}

type Props ={ className?:string, masterMerchantDetail: MasterMerchantMgmtDetail }
const MasterMerchantMgmt: FC<Props> = ({className, masterMerchantDetail}) => {
  const classes = useStyles();
  const [masterList, setMasterList] = useState([]);
  const [loading, setLoading] = useState(false);

  // 검색조건
  const [searchForm, setSearchForm] = useState({
    inputMasterMerchantNo: '',     // 대표가맹점번호
    inputMasterMerchantName: '',   // 대표가맹점명
    inputMerchantNo: '',          // 가맹점번호
    inputMerchantName: '',        // 가맹점명    
    inputCancelYn: ''             // 해지여부
  });

  useEffect(() => {
    fetch(searchForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 대표가맹점 목록 조회 로직
  const fetch = async (searchFormInput: SearchInfo) => {
    console.log('대표가맹점목록 조회');

    // 상세목록 초기화 
    setInputDetailMasterMerchant({} as MasterMerchantMgmtDetail);

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        inputMasterMerchantNo: searchFormInput.inputMasterMerchantNo, 
        inputMasterMerchantName: searchFormInput.inputMasterMerchantName,
        inputMerchantNo: searchFormInput.inputMerchantNo,        
        inputCancelYn: searchFormInput.inputCancelYn
      },
      mutation: masterMerchantMgmtMutation.selectMasterMerchantMgmtList
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectMasterMerchantMgmtList);
      setMasterList(res.data.selectMasterMerchantMgmtList);
    }).catch(console.error);

    setLoading(false);
  };

  // 가맹점 목록 조회
  const [inputDetailMasterMerchant, setInputDetailMasterMerchant] = useState({} as MasterMerchantMgmtDetail);
  const [loading2, setLoading2] = useState(false);
  const [detailMerchantList, setDetailMerchantList] = useState([]);
  useEffect(() => {
    console.log('가맹점 목록 조회 INPUT: ', inputDetailMasterMerchant.masterMerchantNo);
    detailFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDetailMasterMerchant]);

  // 가맹점 목록 조회 fetch
  const detailFetch = async () => {
    // 이미 로딩 중이면 리턴
    if(loading2) {
      return;
    }
    
    setLoading2(true);

    await apolloClient.mutate({
      variables: {
        inputMasterMerchantNo: inputDetailMasterMerchant.masterMerchantNo,
        inputCancelYn: searchForm.inputCancelYn
      },
      mutation: masterMerchantMgmtMutation.selectMasterMerchantMgmtDetailList
    }).then(res => {
      console.log('목록 조회 결과 : ', res.data.selectMasterMerchantMgmtDetailList);
      setDetailMerchantList(res.data.selectMasterMerchantMgmtDetailList);
    }).catch(console.error);

    setLoading2(false);
  }

  // 엑셀업로드 팝업
  const [uploadPop, setUploadPop] = useState(false);
  const handleUploadPop = () => {
    // 팝업 오픈
    setUploadPop(true);
  };

  // 엑셀양식다운로드
  const [clicked, setClicked] = useState(false); // 데이터 excel 출력을 위한 버튼 클릭 확인 state

  // 대표가맹점마스터목록 엑셀 다운로드
  const [listExcelClicked, setListExcelClicked] = useState(false);

  // 대표가맹점목록 엑셀 다운로드
  const [detailListExcelClicked, setDetailListExcelClicked] = useState(false);
  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="대표가맹점관리" />
      <MasterMerchantMgmtToolbar  fetch={fetch} searchForm={searchForm} setSearchForm={setSearchForm}/>
      
      <ExcelUploadPop uploadPop={uploadPop} setUploadPop={setUploadPop} searchForm={searchForm} fetch={fetch}/>
      
      <ExportMasterMerchantMgmtListTableInfoExcel 
        className={classes.registerButton}
        csvData={masterList}
        clicked={listExcelClicked}
        setClicked={setListExcelClicked}
      />

      <Button className={classes.registerButton} 
        color="primary" 
        variant="contained"
        onClick={handleUploadPop}
      >            
        엑셀업로드POP
      </Button>

      <ExportIntoExcel
        className={classes.registerButton}
        clicked={clicked}
        setClicked={setClicked}
      />

      <br/>
      <br/>

      <div className={classes.content}>
        {loading
          ? 
            utils.Loading()
          :
            <MasterMerchantMgmtListTable masterMerchantList={masterList} setInputDetailMasterMerchant={setInputDetailMasterMerchant} searchForm={searchForm} fetch={fetch} />
        }
        
        <ExportMasterMerchantMgmtDetailListTableInfoExcel 
          className={classes.registerButton}
          csvData={detailMerchantList}
          clicked={detailListExcelClicked}
          setClicked={setDetailListExcelClicked}
        />
        <br/>
        <br/>
        <br/>
        {loading2
          ? utils.Loading()
          : <MasterMerchantMgmtDetailListTable detailMerchantList={detailMerchantList} searchForm={searchForm} fetch={fetch} inputDetailMasterMerchant={inputDetailMasterMerchant}/>
        }
      </div>
    </div>
  );
}

export default MasterMerchantMgmt;
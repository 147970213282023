import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  CardHeader,
  Divider,
  Tooltip
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import MasterMerchantMgmtDetailInsertPop from './MasterMerchantMgmtDetailInsertPop';
import MasterMerchantMgmtDetailUpdatePop from './MasterMerchantMgmtDetailUpdatePop';
import * as utils from '../../common/utils';
import {format} from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  registerButton: {
    margin: theme.spacing(1),
    float: 'right'
  },
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
  cancelRow: {
    cursor: 'pointer',
    textDecoration: 'line-through red'
  }
}));

type SearchInfo = {
  inputMasterMerchantNo: string,     // 대표가맹점번호
  inputMasterMerchantName: string,   // 대표가맹점명
  inputMerchantNo: string,          // 가맹점번호
  inputMerchantName: string,         // 가맹점명  
  inputCancelYn: string             // 해지여부
}

type Props = {
  detailMerchantList: MasterMerchantMgmtDetailList[],
  searchForm: SearchInfo,
  fetch: any,
  inputDetailMasterMerchant: MasterMerchantMgmtDetail
}

const MasterMerchantMgmtDetailListTable: FC<Props> = ({ detailMerchantList, searchForm, fetch, inputDetailMasterMerchant }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 신규등록 팝업
  const [insertPop, setInsertPop] = useState(false);
  const handleInsertPop = () => {
    // 팝업 오픈
    setInsertPop(true);
  };

  // 더블클릭 시 수정 팝업
  const [updatePop, setUpdatePop] = useState(false);
  const [masterMerchantDetailInfo, setMasterMerchantDetailInfo] = useState({
    masterMerchantNo: '',
    masterMerchantName: '',
    merchantNo: '',
    merchantName: '',
    canceledDate: '',
    useYn: ''
  });
  const handleRowClick = async (obj: MasterMerchantMgmtDetailList) => {
    console.log('수정팝업: ', obj);
    
    await setMasterMerchantDetailInfo({
      masterMerchantNo: obj.masterMerchantNo,
      masterMerchantName: obj.masterMerchantName,
      merchantNo: obj.merchantNo,
      merchantName: obj.merchantName,
      canceledDate: obj.canceledDate,
      useYn: obj.useYn
    });
    setUpdatePop(true);
  };

  if(detailMerchantList.length === 0) {
    return (
      <div>
      <Card>
        <CardHeader title="상세목록"/>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>조회된 내용이 없습니다.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {!utils.isNullOrBlank(inputDetailMasterMerchant.masterMerchantNo)
          ? 
          <Button className={classes.registerButton}
            color="primary"
            variant="contained"
            onClick={() => handleInsertPop()}
          >            
            NEW
          </Button>
          : ''
        }
      </Card>
      
      <MasterMerchantMgmtDetailInsertPop insertPop={insertPop} setInsertPop={setInsertPop} fetch={fetch} searchForm={searchForm} inputDetailMasterMerchantNo={inputDetailMasterMerchant.masterMerchantNo}/>
      </div>
    );
  }

  return (
    <div>
      <Card>
        <CardHeader title="상세목록">
        </CardHeader>

        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>                    
                    <TableCell>대표가맹점번호</TableCell>
                    <TableCell>대표가맹점명</TableCell>
                    <TableCell>가맹점번호</TableCell>
                    <TableCell>가맹점명</TableCell>
                    <TableCell>사업자번호</TableCell>
                    <TableCell>대표자명</TableCell>
                    <TableCell>등록일자</TableCell>
                    <TableCell>해지일자</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailMerchantList.slice((rowsPerPage*page), rowsPerPage*(page+1)).map((masterMerchant, index) => (
                    <Tooltip title="더블클릭 상세수정" key={index}>
                      <TableRow
                        hover
                        key={masterMerchant.masterMerchantNo+''+masterMerchant.merchantNo}
                        className={
                            masterMerchant.canceledDate < format(new Date(), 'yyyyMMdd')
                            ? classes.cancelRow
                            : classes.cursor_pointer
                        }
                        onDoubleClick={() => handleRowClick(masterMerchant)}
                      >                        
                        <TableCell>{masterMerchant.masterMerchantNo}</TableCell>
                        <TableCell>{masterMerchant.masterMerchantName}</TableCell>
                        <TableCell>{masterMerchant.merchantNo}</TableCell>
                        <TableCell>{masterMerchant.merchantName}</TableCell>
                        <TableCell>
                          {
                            masterMerchant.merchantInfo === null
                            ? ''
                            : chaiStringUtils.bizNoFormat(masterMerchant.merchantInfo.merchantBizNo)
                          }
                        </TableCell>
                        <TableCell>
                          {
                            masterMerchant.merchantInfo === null
                            ? ''
                            : masterMerchant.merchantInfo.officerName
                          }
                        </TableCell>
                        <TableCell>{chaiStringUtils.dateFormat(masterMerchant.registeredDate, null)}</TableCell>
                        <TableCell>{chaiStringUtils.dateFormat(masterMerchant.canceledDate, null)}</TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={detailMerchantList.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
        {!utils.isNullOrBlank(inputDetailMasterMerchant.masterMerchantNo) 
          ? 
          <Button className={classes.registerButton}
            color="primary"
            variant="contained"
            onClick={() => handleInsertPop()}
          >            
            NEW
          </Button>
          : ''
        }
      </Card>
      
      <MasterMerchantMgmtDetailInsertPop 
        insertPop={insertPop} 
        setInsertPop={setInsertPop} 
        fetch={fetch} 
        searchForm={searchForm} 
        inputDetailMasterMerchantNo={inputDetailMasterMerchant.masterMerchantNo}
      />

      <MasterMerchantMgmtDetailUpdatePop 
        updatePop={updatePop} 
        setUpdatePop={setUpdatePop} 
        masterMerchantDetail={masterMerchantDetailInfo} 
        setMasterMerchantDetailInfo={setMasterMerchantDetailInfo} 
        fetch={fetch} 
        searchForm={searchForm}
      />
    </div>
  );
}

export default MasterMerchantMgmtDetailListTable;

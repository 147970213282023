import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';

import apolloClient from '../../lib/apolloClient';
import { aprvAgrgListMutation } from '../../lib/apolloClient/mutations';

import AprvAgrgTableDetailPop from './AprvAgrgTableDetailPop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
}));

type Props = { aprvs: GetAprvAgrgList[] }
const ApprovalTable: FC<Props> = ({ aprvs }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  // 상세팝업 오픈여부
  const [detailListPop, setDetailListPopOpen] = useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  // 더블클릭 시 상세 팝업
  const handleRowClick = (tradeDate: String, cardProductCode: String) => {
    // 조회
    fetch(tradeDate, cardProductCode);

    // 팝업 오픈
    setDetailListPopOpen(true);
  }

  // 발급집계 상세내역 조회 
  const fetch = async (tradeDate: String, cardProductCode: String) => {
    console.log('발급집계 상세내역 목록 조회 - INPUT: ', tradeDate, cardProductCode);

    // 이미 로딩 중이면 리턴
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        tradeDate: tradeDate,
        cardProductCode: cardProductCode
      },
      mutation: aprvAgrgListMutation.selectAprvAgrgDetail
    }).then(res => {
      console.log('발급집계 상세내역 목록 조회 결과: ', res.data.selectAprvAgrgDetail);
      setData(res.data.selectAprvAgrgDetail);
    }).catch(console.error);

    setLoading(false);
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
              <TableRow>
                  <TableCell align="center">NO.</TableCell>
                  <TableCell align="center">거래일자</TableCell>
                  <TableCell align="center">상품명</TableCell>
                  <TableCell align="center">신규 발급건수</TableCell>
                  <TableCell align="center">훼손 재발급건수</TableCell>
                  <TableCell align="center">분실 재발급건수</TableCell>
                  <TableCell align="center">신규 최초승인 카드건수</TableCell>
                  {/*<TableCell align="center">신규카드활성화율</TableCell>*/}                  
                </TableRow>
              </TableHead>
              <TableBody>
                {aprvs.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                  <Tooltip title="더블클릭 상세보기" key={index}> 
                    <TableRow
                      hover
                      key={index}
                      className={classes.cursor_pointer}
                      onDoubleClick={() => handleRowClick(item.tradeDate, item.cardProductCode)}
                    >
                      <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>                    
                      <TableCell align="center">
                        {item.tradeDate.substr(0,4)}.
                        {item.tradeDate.substr(4,2)}.
                        {item.tradeDate.substr(6,2)}
                      </TableCell>
                      <TableCell align="center">{item.cardProductCodeName}</TableCell>
                      <TableCell align="center">{isNullOrUndefined(item.approvalCount) ? 0 : item.approvalCount }</TableCell>
                      <TableCell align="center">{isNullOrUndefined(item.approvalReCount) ? 0 : item.approvalReCount }</TableCell>
                      <TableCell align="center">{isNullOrUndefined(item.approvalLostReCount) ? 0 : item.approvalLostReCount }</TableCell>
                      <TableCell align="center">{isNullOrUndefined(item.approvalAmount) ? 0 : item.approvalAmount }</TableCell>
                      {/*<TableCell align="center">
                        {isNaN(Math.floor(item.approvalAmount / item.approvalCount)) ? '0' : Math.floor((item.approvalAmount / item.approvalCount) * 100)} %
                        {/** 활성화율 계산 식 (활성건수/발급건수)*100 (소수점 버림)
                        * : 카카오페이 참조 
                      </TableCell>*/}
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={aprvs.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>      
      <AprvAgrgTableDetailPop detailListPop={detailListPop} setDetailListPopOpen={setDetailListPopOpen} loading={loading} data={data}/>
    </Card>
  );
};

export default ApprovalTable;

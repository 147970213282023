import React , {useState,FC} from 'react';
import{
    Card,
    CardHeader,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

/**
const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
**/

type Props ={
    setCardBinNo: React.Dispatch<React.SetStateAction<CardBinNo>>,
    cardBinNo : CardBinNo[],
    setUpdateClick : React.Dispatch<React.SetStateAction<boolean>>,
    setHistoryInput : React.Dispatch<React.SetStateAction<any>>
}

const CardBinList : FC<Props> =({cardBinNo, setCardBinNo, setUpdateClick, setHistoryInput}) =>{

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
      };
    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(Number(event.target.value));
      };
      function fnGetHistory(item: CardBinNo) {
        setHistoryInput(item);
      }
          
    const brandDivisionCodeMap = new Map<String, String>();
    brandDivisionCodeMap.set('0', 'GLOBAL BC');
    brandDivisionCodeMap.set('1', 'LOCAL');
    brandDivisionCodeMap.set('2', 'MC');
    brandDivisionCodeMap.set('3', 'VISA');
    brandDivisionCodeMap.set('4', 'JCB');

    return(
            <Card>
                <CardHeader title="<BIN목록>"/>
                <PerfectScrollbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>NO.</TableCell>
                        <TableCell>BIN번호</TableCell>
                        <TableCell>상품코드</TableCell>
                        <TableCell>브랜드</TableCell>
                        <TableCell>시작일련번호</TableCell>
                        <TableCell>종료일련번호</TableCell>
                        <TableCell>현재일련번호</TableCell>
                        <TableCell>사용여부</TableCell>
                        <TableCell>종료일자</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {cardBinNo.slice(page * rowsPerPage, (page + 1) * (rowsPerPage)).map((item, idx) => (

                    <TableRow
                    onClick={()=> {setCardBinNo(item); setUpdateClick(true); fnGetHistory(item);}}
                    hover
                    key={item.binNo+''+item.cardProductCode+''+item.endCardMediaSeq}>
                        <TableCell>{(page * rowsPerPage) + idx+1}</TableCell>
                        <TableCell>{item.binNo}</TableCell>
                        <TableCell>{item.cardProductCode}</TableCell>
                        <TableCell>{brandDivisionCodeMap.get(item.brandDivisionCode)}</TableCell>
                        <TableCell>{item.startCardMediaSeq}</TableCell>
                        <TableCell>{item.endCardMediaSeq}</TableCell>
                        <TableCell>{item.nowCardMediaSeq}</TableCell>
                        <TableCell>{item.useYn}</TableCell>
                        <TableCell>{item.endDate}</TableCell>
                    </TableRow>
                ))}    
                </TableBody>
            </Table>
            </PerfectScrollbar>
            <TablePagination
            component="div"
            count={cardBinNo.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
        />
            </Card>

    );
};

export default CardBinList;
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import apolloClient from '../../lib/apolloClient';
import { cardProductMutation } from '../../lib/apolloClient/mutations';


const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const CardProduct = () => {
  const classes = useStyles();
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error] = useState<Error>()

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      
      await apolloClient.mutate({
        mutation: cardProductMutation.selectCardProductList
      }).then(res => {        
        setData(res)
      }).catch(console.error);

      setLoading(false)

    }
    fetch()
  }, [])

  return (
    <div className={classes.root}>
      {  error    ? error.message
       : loading  ? 'Loading...'
       : data     ? JSON.stringify(data)
       : ''}
    </div>
  );
};

export default CardProduct;

import client from '../../../lib/cardApi/client';

export default async (cardNo: string): Promise<any> => {
  console.log('반송카드 재배송요청 api 호출', getBody(cardNo));

  return client.post('/root/cardReturnManage/reIssueRequest', getBody(cardNo)).then(res => {
    return res.data;
  }).catch(e => {
    throw Error(e);
  });
}

const getBody = (cardNo: string): any => {
  let params = {
    cardNo: cardNo
  }

  return params;
}
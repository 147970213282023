import gql from 'graphql-tag';

//해외정산집계내역 Detail 
export const selectOvrsSettlementAggrListDetail = gql`
    mutation selectOvrsSettlementAggrListDetail ($searchDate : String) {
        selectOvrsSettlementAggrListDetail(searchDate : $searchDate){
            aggregationDate
            accountDivisionCode
            settlementUsdWithdrawalCount
            settlementUsdDepositCount
            settlementUsdWithdrawalTotalAmount
            settlementUsdDepositTotalAmount
            settlementUsdTradeTotalAmount
            settlementKrwWithdrawalTotalAmount
            settlementKrwDepositTotalAmount
            settlementKrwTradeTotalAmount
            settlementExchangeRateDiffTotalAmount
            purchasedExchangeRateDiffTotalAmount
            settlementExchangeRateApplyAmount
            accountProcessSeq
            dealBaseExchangeRate
            settlementBaseExchangeRate
            lastRemitKrwAmount
            settlementProcessStatusCode
            settlementProcessDate
            settlementDate
            settlementCycleDivisionCode
        }
    }
`;


//해외정산집계내역 Entire 
export const selectOvrsSettlementAggrListEntire = gql`
    mutation selectOvrsSettlementAggrListEntire ($searchDate : String) {
        selectOvrsSettlementAggrListEntire(searchDate : $searchDate){
            aggregationDate
            accountDivisionCode
            settlementUsdWithdrawalCount
            settlementUsdDepositCount
            settlementUsdWithdrawalTotalAmount
            settlementUsdDepositTotalAmount
            settlementUsdTradeTotalAmount
            settlementKrwWithdrawalTotalAmount
            settlementKrwDepositTotalAmount
            settlementKrwTradeTotalAmount
            settlementExchangeRateDiffTotalAmount
            purchasedExchangeRateDiffTotalAmount
            settlementExchangeRateApplyAmount
            accountProcessSeq
            dealBaseExchangeRate
            settlementBaseExchangeRate
            lastRemitKrwAmount
            settlementProcessStatusCode
            settlementProcessDate
            settlementDate
            settlementCycleDivisionCode
        }
    }
`;


import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import apolloClient from '../../lib/apolloClient';
import { overseasPurchasedHistoryMutation } from '../../lib/apolloClient/mutations';
import OverseasPurchasedHistoryPop from './OverseasPurchasedHistoryPop';

import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    //minWidth: 2000
  },
  inner: {
    minWidth: 1050
  }
}));

type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}


type Props = {
  ovrsPurcahsedHistList : ovrsPurchasedHistoryList[]
}

const OverseasPurchasedHistoryTable : FC<Props> = ({ovrsPurcahsedHistList}) => {
  
  console.log('@ ovrsPurcahsedHistList : ' , ovrsPurcahsedHistList);

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [popInfo, setPopInfo] = useState('');
  const [isPopYn, setIsPopYn] = useState(false);

  const handlePageChange = (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => {
      setPage(page);
  };

  const handleRowsPerPageChange = (event : React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(Number(event.target.value));
  };
  
  const handleDetailClick = (receveBaseDate : String, receveSeq : number) => {
    fetch(receveBaseDate, receveSeq);
    setIsPopYn(true);
  }

  const fetch = async (receveBaseDate : String, receveSeq : number) => {
    await apolloClient.mutate({
      mutation : overseasPurchasedHistoryMutation.selectOvrsPurchasedHistoryPop,
        variables : {input : {
          receveBaseDate : receveBaseDate,
          receveSeq : receveSeq
        }
      }
    }).then(res => {
      setPopInfo(res.data.selectOvrsPurchasedHistoryPop)
    }).catch(console.error);
  }



  return(
      <Card>
          <CardContent className={classes.content} style={{paddingBottom: '0'}}>
          <PerfectScrollbar>
              <div className={classes.inner}>
              <Table>
              <TableHead>
                  <TableRow>
                  <TableCell align='center' rowSpan={2}>No</TableCell> 
                  <TableCell align='center' rowSpan={2}>처리일자</TableCell> 
                  <TableCell align='center' rowSpan={2}>카드계정구분</TableCell>
                  <TableCell align='center' rowSpan={2}>매출종류구분</TableCell>
                  <TableCell align='center' rowSpan={2}>카드번호</TableCell>
                  <TableCell align='center' rowSpan={2}>매입접수번호</TableCell>
                  <TableCell align='center' rowSpan={2}>가맹점영문명</TableCell>
                  <TableCell align='center' colSpan={3}>신판정산대금</TableCell>
                  <TableCell align='center' colSpan={3}>현금정산대금</TableCell>
                  <TableCell align='center' rowSpan={2}>정산미화금액</TableCell>
                  <TableCell align='center' rowSpan={2}>거래유형</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align='center'>해외신판미화금액 </TableCell>
                  <TableCell align='center'>해외신판수수료미화금액 </TableCell>
                  <TableCell align='center'>해외신판수수료원화금액 </TableCell>
                  <TableCell align='center'>해외현금미화금액 </TableCell>
                  <TableCell align='center'>해외현금수수료미화금액 </TableCell>
                  <TableCell align='center'>해외현금수수료원화금액 </TableCell>
                  </TableRow>     
              </TableHead>
              <TableBody>
                  {ovrsPurcahsedHistList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                  <TableRow hover key={index} >   
                    <TableCell align='center'>{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.dateFormatDefault(item.overseasProcessDate)}</TableCell>
                    <TableCell align='center'>{chaiStringUtils.codeName('accountDivisionCode', item.accountDivisionCode)}({item.accountDivisionCode})</TableCell>
                    <TableCell align='center'>{chaiStringUtils.codeName('salesKindDivisionCode', item.salesKindDivisionCode)}({item.salesKindDivisionCode})</TableCell>
                    <TableCell align='center'>{item.cardNoMasked}</TableCell>
                    <TableCell align='center'>
                      <Link component="button" variant="body1" onClick={() => { handleDetailClick(item.receveBaseDate, item.receveSeq); }}>
                        {item.purchasedProcessNo} 
                      </Link>
                    </TableCell>
                    <TableCell align='center'>{item.merchantEngName}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCdslUsdAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCdslFeeUsdAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCdslFeeKrwAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCashUsdAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCashFeeUsdAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasCashFeeKrwAmount)}</TableCell>
                    <TableCell align='right'>{chaiStringUtils.moneyFormat(item.overseasSettlementUsdAmount)}</TableCell>
                    <TableCell align='center'>{item.tradeProcessCode}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>            
              </Table>
              </div>  
          </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
          <TablePagination
              component="div"
              count={ovrsPurcahsedHistList.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 100]}
          />
          </CardActions>  
          <OverseasPurchasedHistoryPop popInfo={popInfo} isPopYn={isPopYn} setIsPopYn={setIsPopYn} />
      </Card>
  );

};


export default OverseasPurchasedHistoryTable;

import React, {FC} from 'react';
import { CardContent, Table, TableCell, Card, CardHeader, TableBody, TableRow, makeStyles, Theme } from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
      padding: 0
    },
    inner: {
        width: '50%'
    },
    thStyle: {
      backgroundColor: '#fafafa',
      border: '1px solid #dbdbdb'
    },
    tdStyle: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #dbdbdb',
      minWidth: '120px',
    }
}));


type Props = {
    detailInfo : ovrsSettlementAggrListDetail[]
}

const OverseasSettlementAggrListFinalAmount : FC<Props> = ({detailInfo}) => {

    const classes = useStyles();
    
    let krwAmount = 0;
    let usdAmoung = 0;

    for(let index=1; index<detailInfo.length; index++) {
        if('250' === detailInfo.slice(0, index).map(a=>a.accountDivisionCode).toString()) {
            console.log('@ 250 -> :  ', detailInfo.slice(0, index).map(aa=>aa.accountDivisionCode));
            console.log('@ krwAmount -> :  ', chaiStringUtils.moneyFormat((detailInfo.slice(0, index).map(aa=>aa.settlementExchangeRateApplyAmount))));
            console.log('@ usdAmoung -> :  ', chaiStringUtils.moneyFormat((detailInfo.slice(0, index).map(aa=>aa.settlementUsdTradeTotalAmount))));
            krwAmount = chaiStringUtils.moneyFormat((detailInfo.slice(0, index).map(aa=>aa.settlementExchangeRateApplyAmount)));
            usdAmoung = chaiStringUtils.moneyFormat((detailInfo.slice(0, index).map(aa=>aa.settlementUsdTradeTotalAmount)));
        }
    }

    const reference = <div> * 일정산대금 : 신판정산대금 + (현금인출정산대금+현금수수료) <br /> * 최종송금원화금액 : 정산환율적용금액 (정산금액이 확정처리된 후 송금수수료 포함한 금액) <br /> * 최종송금미화금액 : 미화차액(BC최종 송금 미화금액) </div>;
    
    return(
        <Card>
            <CardHeader subheader={reference} />
            <CardContent>
                <div className={classes.inner}>
                    <Table size="small">
                        <TableBody>
                        <TableRow> 
                            <TableCell align="center" className={classes.thStyle}> 최종송금원화금액 </TableCell>
                            <TableCell align="right" className={classes.tdStyle}> {krwAmount} 원</TableCell>
                            <TableCell align="center" className={classes.thStyle}> 최종송금미화금액 </TableCell>
                            <TableCell align="right" className={classes.tdStyle}> {usdAmoung} $ </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>
    );

}

export default OverseasSettlementAggrListFinalAmount;
import React, { useState, useEffect } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { settlementAggrListMutation } from '../../lib/apolloClient/mutations';
import SettlementAggrListToolbar from './SettlementAggrListToolbar';
import SettlementAggrTable from './SettlementAggrTable';
import { MenuTitleToolbar } from '../../components';
import {format, subMonths} from 'date-fns';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SettlementAggrList = () => {
  const classes = useStyles();
  const [settlementAggrList, setSettlementAggrList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState({
    startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  const [isExcelDown, setExcelDown] = useState(false);
  const [isFlag, setFlag] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {input : {
          startDate: inputSearch.startDate.replace(/-/g,''),
          endDate: inputSearch.endDate.replace(/-/g,'')
        }
        },
        mutation: settlementAggrListMutation.selectSettlementAggrList     
      }).then(res => {
        // console.log(res.data); 
        if(isExcelDown){
          setExcelDown(false);
          excelDown(res.data.selectSettlementAggrList);
        }
        else {
          setSettlementAggrList(res.data.selectSettlementAggrList);
        }
      }).catch(console.error);

      // setLoading(false)

    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, isFlag])

  const excelClick = () => {
    setFlag(!isFlag);
    setExcelDown(true);
  }

  const excelDown = (csvData: SettlementAggrInfo[]) => {
    let rowData = []
    for (let i = 0; i < csvData.length; i++) {
      rowData.push({
          '집계일자': `${csvData[i].aggregationDate === '99999999' ? '합계' : chaiStringUtils.dateFormatDefault(csvData[i].aggregationDate)}`,
          '항목': `${csvData[i].accountDivisionCode === '000' ? '소계' : chaiStringUtils.codeName('accountDivisionCode', csvData[i].accountDivisionCode)}`,
          '가맹점대금 BC지급 건수': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalCount)}`,
          '가맹점대금 BC지급 금액': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalTotalAmount)}`,
          '가맹점대금 당사입금 건수': `${chaiStringUtils.moneyFormat(csvData[i].depositCount)}`,
          '가맹점대금 당사입금 금액': `${chaiStringUtils.moneyFormat(csvData[i].depositTotalAmount)}`,
          '가맹점수수료 수익발생 건수': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalFeeCount)}`,
          '가맹점수수료 수익발생 금액': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalMerchantFeeTotalAmount)}`,
          '가맹점수수료 수익취소 건수': `${chaiStringUtils.moneyFormat(csvData[i].feeDepositCount)}`,  
          '가맹점수수료 수익취소 금액': `${chaiStringUtils.moneyFormat(csvData[i].merchantFeeDepositTotalAmount)}`,
          'BC대행수수료 비용발생 건수': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalFeeCount)}`,
          'BC대행수수료 비용발생 금액': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalProxyFeeTotalAmount)}`,
          'BC대행수수료 비용취소 건수': `${chaiStringUtils.moneyFormat(csvData[i].feeDepositCount)}`,  
          'BC대행수수료 비용취소 금액': `${chaiStringUtils.moneyFormat(csvData[i].proxyFeeDepositTotalAmount)}`,                    
          '차이매출 매출발생 건수': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalFeeCount)}`,
          '차이매출 매출발생 금액': `${chaiStringUtils.moneyFormat(csvData[i].withdrawalFeeTotalAmount)}`,
          '차이매출 매출취소 건수': `${chaiStringUtils.moneyFormat(csvData[i].feeDepositCount)}`,  
          '차이매출 매출취소 금액': `${chaiStringUtils.moneyFormat(csvData[i].feeDepositTotalAmount)}`,
          '차액': `${chaiStringUtils.moneyFormat(csvData[i].tradeTotalAmount)}`          
      });
    }

    utils.exportToCSV("국내정산집계", rowData);
  }

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="국내정산집계" />
      <SettlementAggrListToolbar onSearch={setInputSearch}/>    
      <div className={classes.content}>
        <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
        <SettlementAggrTable settlementAggrList={settlementAggrList}/>
      </div>
    </div>
  );

};

export default SettlementAggrList;

import React, { FC, ChangeEvent, useState } from 'react';
import {
  Theme,
  makeStyles,
  Button,
  TextField,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';

// 대행수수료코드검색

type Props = {
  setFeeCodeInfoSearch: React.Dispatch<React.SetStateAction<FeeCodeSearchReturn>>;
  feeCodeInfoSearch: FeeCodeSearchReturn;
};

const ProxyFeeCodeToolbar: FC<Props> = ({
  setFeeCodeInfoSearch,
  feeCodeInfoSearch
}) => {

    const classes = useStyles(); // // className을 통한 style 설정을 위한 상수 선언

    const [searchForm, setSearchForm] = useState({
        feeInfoDivisionCode: '',
        deleteYn: '',
        proxyFeeCodeNo: '',
        proxyFeeNm: '',
        proxyFeeGroupCode: '',
        unitFeeCodeNo: '',
        unitFeeName: ''
    })

  const searchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
        ...searchForm,
        [event.target.name]: event.target.value
    });
  }

  const onSearch = async () => {
    await setFeeCodeInfoSearch(searchForm);
  };

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <TextField
              fullWidth
              label="수수료 정보 구분"
              margin="dense"
              name="feeInfoDivisionCode"
              onChange={searchHandleChange}
              variant="outlined"
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true
              }}
              onKeyPress={onPressEnter}>
              <option value="">전체</option>
              <option value="1">위임</option>
              <option value="2">단위</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              label="대행 수수료 그룹"
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              name="proxyFeeGroupCode"
              onChange={searchHandleChange}
              select
              SelectProps={{ native: true }}
              onKeyPress={onPressEnter}>
              <option value="">전체</option>
              <option value="1">승인</option>
              <option value="2">회원</option>
              <option value="3">발급카드</option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              fullWidth
              label="삭제 여부"
              margin="dense"
              name="deleteYn"
              onChange={searchHandleChange}
              variant="outlined"
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true
              }}
              value={searchForm.deleteYn}
              onKeyPress={onPressEnter}>
              <option value="">전체</option>
              <option value="N">미삭제</option>
              <option value="Y">삭제</option>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={3} xs={6}>
            <TextField
              label="서비스 수수료 코드"
              fullWidth
              placeholder="서비스 수수료 코드"
              onChange={searchHandleChange}
              name="proxyFeeCodeNo"
              value={searchForm.proxyFeeCodeNo}
              margin="dense"
              onKeyPress={onPressEnter}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              label="서비스 수수료명"
              placeholder="서비스 수수료명"
              fullWidth
              onChange={searchHandleChange}
              name="proxyFeeNm"
              value={searchForm.proxyFeeNm}
              margin="dense"
              onKeyPress={onPressEnter}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid md={3} xs={6}>
            <Button
              variant="contained"
              color="primary"
              children="Search"
              onClick={onSearch}
              size="large"
              className={classes.searchBtn}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  root: {
    fontFamily: 'sans-serif',
    fontSize: 14
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  table_up: {
    borderBottom: '0px solid white',
    width: '30%'
  },
  table_down: {
    borderBottom: '0px solid white',
    width: '45%'
  },
  searchBtn: {
    marginTop: '8px', 
    marginLeft: '8px', 
    paddingTop: '8px', 
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px'
  }
}));

export default ProxyFeeCodeToolbar;

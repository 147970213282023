import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip
} from '@material-ui/core';
import apolloClient from '../../lib/apolloClient';
import { overseasPurchasedAggrHistoryMutation } from '../../lib/apolloClient/mutations';
import OverseasPurchasedAggrDetailListPop from './OverseasPurchasedAggrDetailListPop';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
}));


type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = { 
  opais: OverseasPurchasedAggrHistoryListReturnType,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
 }

const OverseasPurchasedAggrHistoryTable: FC<Props> = ({ opais, paging, setPaging }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [detailList, setDetailList] = useState([]);
  const [detailListPop, setDetailListPopOpen] = useState(false);

  let list = (opais === undefined)? []:opais["list"];
  let totalCount = (opais === undefined)? 0:opais["totalCount"];

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    setPaging({
      ...paging,
      page: inPage
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
  }; 


  const handleDetailClick = (aggregation_date: String, account_division_code: String, substr_sales_kind_division_code: String) => {
    fetch(aggregation_date, account_division_code, substr_sales_kind_division_code);
    setDetailListPopOpen(true);
  }

  const fetch = async (aggregation_date: String, account_division_code: String, substr_sales_kind_division_code: String ) => {    
    if(loading) {
      return;
    }

    setLoading(true);

    await apolloClient.mutate({
      variables: {
        aggregation_date: aggregation_date,
        account_division_code: account_division_code,
        substr_sales_kind_division_code: substr_sales_kind_division_code
      },
      mutation: overseasPurchasedAggrHistoryMutation.selectOverseasPurchasedAggrDetailList
    }).then(res => {
      setDetailList(res.data.selectOverseasPurchasedAggrDetailList);
    }).catch(console.error);

    setLoading(false);
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
        <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                    <TableCell align="center" rowSpan={2}>No</TableCell>
                    <TableCell align="center" rowSpan={2}>집계일자</TableCell>
                    <TableCell align="center" rowSpan={2}>카드계정구분</TableCell>
                    <TableCell align="center" rowSpan={2}>카드구분</TableCell>
                    <TableCell align="center" rowSpan={2}>정산일자</TableCell>
                    <TableCell align="center" colSpan={5}>정산미화금액</TableCell>
                    <TableCell align="center" colSpan={3}>정산원화금액</TableCell>
                    <TableCell align="center" colSpan={3}>정산미화수수료</TableCell>
                    <TableCell align="center" colSpan={3}>정산원화수수료</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">출금건수</TableCell>
                    <TableCell align="center">입금건수</TableCell>
                    <TableCell align="center">출금총액</TableCell>
                    <TableCell align="center">입금총액</TableCell>
                    <TableCell align="center">거래총액</TableCell>
                    <TableCell align="center">출금총액</TableCell>
                    <TableCell align="center">입금총액</TableCell>
                    <TableCell align="center">거래총액</TableCell>
                    <TableCell align="center">출금총액</TableCell>
                    <TableCell align="center">입금총액</TableCell>
                    <TableCell align="center">거래총액</TableCell>
                    <TableCell align="center">출금총액</TableCell>
                    <TableCell align="center">입금총액</TableCell>
                    <TableCell align="center">거래총액</TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
              {list.slice(paging.rowsPerPage * paging.page, paging.rowsPerPage * (paging.page + 1)).map((item, index) => (                  
                  <TableRow
                    hover
                    key={index}
                    className={classes.cursor_pointer}
                  >
                    <TableCell align="center">{(paging.rowsPerPage * (paging.page + 1)) - (paging.rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.aggregation_date)}</TableCell>     
                    <TableCell align="center">{item.account_division_code}</TableCell> 
                    <TableCell align="center">{item.card_type_code}</TableCell>     
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.settlement_date)}</TableCell>     
                    <TableCell align="center">
                    <Tooltip title="출금건수 상세목록">
                      <a href="#!" onClick={() => handleDetailClick(item.aggregation_date, item.account_division_code, '0')}>{chaiStringUtils.moneyFormat(item.settlement_usd_withdrawal_count)} 건</a>
                    </Tooltip>         
                    </TableCell>
                    <TableCell align="center">
                    <Tooltip title="입금건수 상세목록">
                      <a href="#!" onClick={() => handleDetailClick(item.aggregation_date, item.account_division_code, '1')}>{chaiStringUtils.moneyFormat(item.settlement_usd_deposit_count)} 건</a>
                    </Tooltip>         
                    </TableCell>       
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_withdrawal_total_amount)}</TableCell>             
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_deposit_total_amount)}</TableCell>
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_trade_total_amount)}</TableCell>             
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_withdrawal_total_amount)}</TableCell>
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_deposit_total_amount)}</TableCell>
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_trade_total_amount)}</TableCell>
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_fee_withdrawal_total_amount)}</TableCell>
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_fee_deposit_total_amount)}</TableCell>
                    <TableCell align="center">$ {chaiStringUtils.moneyFormat(item.settlement_usd_fee_trade_total_amount)}</TableCell>
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_fee_withdrawal_total_amount)}</TableCell>
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_fee_deposit_total_amount)}</TableCell>
                    <TableCell align="center">&#8361; {chaiStringUtils.moneyFormat(item.settlement_krw_fee_trade_total_amount)}</TableCell>
                    </TableRow>	
                ))}
              </TableBody>
            </Table>
         </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
      <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>   
      <OverseasPurchasedAggrDetailListPop detailListPop={detailListPop} setDetailListPopOpen={setDetailListPopOpen} loading={loading} list={detailList}/>
    </Card>
  );
};

export default OverseasPurchasedAggrHistoryTable;

import gql from 'graphql-tag';



export const selectCardProductList = gql`
  mutation cardProduct {
    selectCardProductList {
      cardProductGroupCode
      cardProductCode
      cardProductGroupName
      cardProductCodeName
      useYn
      remarkText
      cardIssueFeeYn
      cardIssueFee
      trafficFunctionDivisionCode
      brandDivisionCode
      annualFeeClaimYn
      localProductYearlyDues
      visaProductYearlyDues
      masterProductYearlyDues
      unionpayProductYearlyDues
      etcProductYearlyDues
      productCashbackDivisionCode
      productCashbackPaymentDate
      productTypeDivisionCode
      operateAdminId
      operateDetailDate
      operateTradeCode
    }
  }
`;




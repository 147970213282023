import React, { FC, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
// import {format} from 'date-fns';
// import apolloClient from '../../lib/apolloClient';
// import { SearchInput } from '../../components';
// import { holidayInformationMutation } from '../../lib/apolloClient/mutations';
// import HolidayInformation from '.';
import * as utils from '../../common/utils';

type SearchInfo = {
    baseDate:string,        // 기준시작일자
    baseDateEnd:string,     // 기준종료일자
    holidayCode:string      // 휴일구분
}

type holidayCode = {
    holidayCode:string;     // 휴일구분
}

type Props ={
    onSearch: React.Dispatch<React.SetStateAction<any>>,
    searchForm: SearchInfo,
    setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
};

const HolidayInformationToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm }) => {

    const [holidayCodeList, setHolidayCodeList] = useState<CommonCodeInfo[]>([]);

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForm({
            ...searchForm,
            [e.target.name]: e.target.value
        });
    };

    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
        if (event.key === "Enter"){
            onSearchClick();
        }
    }

    // 목록 조회
    const onSearchClick = async () => {
        onSearch(searchForm);
    }

    // 휴일구분 조회
    useEffect(() => {
        const fetch = async() => {
                setHolidayCodeList(await utils.getCodeList('BASIC','HOLIDAY','Y'));
        }
        fetch()
    }, []);

    return(
     <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="기준시작일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="baseDate"
              value={searchForm.baseDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="기준종료일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="baseDateEnd"
              value={searchForm.baseDateEnd}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              select
              fullWidth
              label="휴일구분"
              placeholder="휴일구분"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              name="holidayCode"
              value={searchForm.holidayCode}
              onChange={handleChange}
              >
              <option key="" value="">전체</option>
              { holidayCodeList.map(item => (
                <option
                  key={item.codeValue}
                  value={item.codeId}
                >
                  {item.codeValue}
                </option> 
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
      </CardContent>
     </Card>

    )
  }

export default HolidayInformationToolbar;
import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {format, subDays} from 'date-fns';

type Props = {
  page: number
  rowsPerPage: number
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  onSearch: React.Dispatch<React.SetStateAction<ApprovalHistoryLogSearch>>
}

const ApprovalHistoryLogListToolbar: FC<Props> = ({page, rowsPerPage, setPaging, onSearch}) => {

  const [approvalHistoryLogSearch, setApprovalHistoryLogSearch] = useState({
    salesKindDivisionCode: '',
    dataDivisionCode: '',
    approvalDate: format(subDays(new Date(), 1), 'yyyy-MM-dd')
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApprovalHistoryLogSearch({
      ...approvalHistoryLogSearch,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    const paging:Paging = {} as any;

    paging.page = page;
    paging.rowsPerPage = rowsPerPage;
    setPaging(paging);
    
    onSearch(approvalHistoryLogSearch);
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >              
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="승인구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="salesKindDivisionCode"
                value={approvalHistoryLogSearch.salesKindDivisionCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="05">정상매출</option>
                <option value="15">취소매출</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="자료구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="dataDivisionCode"
                value={approvalHistoryLogSearch.dataDivisionCode}
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="1">BC 일치</option>
                <option value="2">BC 불일치</option>
                <option value="3">Money 불일치</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="승인일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="approvalDate"
                value={approvalHistoryLogSearch.approvalDate}
                onChange={handleChange}
              />
            </Grid>              
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default ApprovalHistoryLogListToolbar;

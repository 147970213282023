import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

/**
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));
**/

type SearchInfo = {
  merchantNo: string,
  merchantBizNo: string
}

type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = {
  fetch: any,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
}

const MerchantInfoListToolbar: FC<Props> = ({ 
    fetch,
    searchForm,
    setSearchForm,
    paging,
    setPaging
  }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  }

  // 목록 조회
  const onSearchClick = async () => {
    setPaging({
      ...paging,
      page: 0
    });
    fetch();
  };

  // 엔터 이벤트 처리
  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            md={4}
            xs={10}
          >
            <TextField
              fullWidth
              label="가맹점번호 또는 가맹점명"
              placeholder="가맹점번호 또는 가맹점명"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="merchantNo"
              value={searchForm.merchantNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={10}
          >
            <TextField
              fullWidth
              label="사업자등록번호"
              placeholder="사업자등록번호"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="merchantBizNo"
              value={searchForm.merchantBizNo}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchClick}
              style = {{
                marginTop: '8px'
              }}
            >
              Search 
            </Button>
          </Grid>
        </Grid>
        
        <Grid
          container
          spacing={1}
        >
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MerchantInfoListToolbar;
import React, { FC } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    TextField
} from '@material-ui/core';

type SearchInfo = {
  criteriaYn: string        // 기준년월
}

type Props ={
  onSearch: React.Dispatch<React.SetStateAction<any>>,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>
}

const SalesDetailsToolbar: FC<Props> = ({ onSearch, searchForm, setSearchForm}) => {

    // 변경이벤트 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchForm({
          ...searchForm,
          [e.target.name]: e.target.value
      });
    };

    // 목록 조회 버튼 클릭
    const onSearchClick = async () => {
      onSearch(searchForm);
    }

    // 엔터 이벤트 처리
    const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) =>{
      if (event.key === "Enter"){
          onSearchClick();
      }
    }

    return(
        <Card>
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="month"
                label="기준년월"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="criteriaYn"
                value={searchForm.criteriaYn}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
       </Card>
    )
  }

export default SalesDetailsToolbar;
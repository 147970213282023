import React, 
{ 
  // useState,
   FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
// import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = { 
  AccountProcInfoList: AccountProcListReturnType,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
 }
const AccountProcTable: FC<Props> = ({ AccountProcInfoList, paging, setPaging }) => {
  const classes = useStyles();

  let list = (AccountProcInfoList === undefined)? []:AccountProcInfoList["list"];
  let totalCount = (AccountProcInfoList === undefined)? 0:AccountProcInfoList["totalCount"];

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, inPage: number) => {
    setPaging({
      ...paging,
      page: inPage
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
  };

  // 계정구분코드Map
  const accountDivisionCode = new Map<String, String>();
  accountDivisionCode.set('111', '일반매출');
  accountDivisionCode.set('113', 'EDI취소선지급');
  accountDivisionCode.set('114', 'SMS이용료');
  accountDivisionCode.set('115', '매출캐시백');
  accountDivisionCode.set('116', '포인트캐시백');
  accountDivisionCode.set('117', '승차권취소수수료');
  accountDivisionCode.set('118', '기타체크신용');
  accountDivisionCode.set('119', '강제캐시백');
  accountDivisionCode.set('120', '국세');
  accountDivisionCode.set('121', '월캐시백');
  accountDivisionCode.set('122', '연회비');
  accountDivisionCode.set('210', '총가맹점대금');
  accountDivisionCode.set('211', '일반매출');
  accountDivisionCode.set('212', 'EDI취소선지급(BC)');
  accountDivisionCode.set('213', 'EDI취소선지급(회원사)');
  accountDivisionCode.set('214', 'SMS이용료');
  accountDivisionCode.set('216', '포인트캐시백');
  accountDivisionCode.set('217', '승차권취소수수료');
  accountDivisionCode.set('218', '기타체크신용');
  accountDivisionCode.set('219', '강제캐시백');
  accountDivisionCode.set('220', '국세');
  accountDivisionCode.set('221', '월캐시백');
  accountDivisionCode.set('416', '발급사보존수수료');

  // 입출구분코드Map
  const inoutDivisionCodeMap = new Map<String, String>();
  inoutDivisionCodeMap.set('1', '입금');
  inoutDivisionCodeMap.set('2', '출금');

  // 회계계정코드명
  const accountNoCodeName = new Map<String, String>();
  accountNoCodeName.set('27401', '원화체크카드기타예수금');
  accountNoCodeName.set('41300', '체크카드원화수입수수료');
  accountNoCodeName.set('13401', '체크카드관련가지급금');
  accountNoCodeName.set('83301', '체크카드캐시백(원화)');
  accountNoCodeName.set('12001', '체크카드캐시백취소미수금');
  accountNoCodeName.set('96001', '잡손실');
  accountNoCodeName.set('93001', '잡이익');
  accountNoCodeName.set('85300', '카드사고보상금');
  accountNoCodeName.set('43001067838', '가맹점대금BC계좌');

  // 회계처리구분코드
  const accountingProcessDivisionCodeName = new Map<String, String>();
  accountingProcessDivisionCodeName.set('1', '계좌');
  accountingProcessDivisionCodeName.set('2', '계정');

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">계정처리일련번호</TableCell>
                  <TableCell align="center">상세일련번호</TableCell>
                  <TableCell align="center">카드계정구분</TableCell>
                  <TableCell align="center">입출구분</TableCell>
                  <TableCell align="center">계정처리일자</TableCell>
                  <TableCell align="center">계정처리시간</TableCell>
                  <TableCell align="center">입출금액</TableCell>
                  <TableCell align="center">회계처리구분</TableCell>
                  <TableCell align="center">가맹점명</TableCell>
                  <TableCell align="center">계좌(계정)번호</TableCell>
                  {/* <TableCell align="center">계정처리적요유형</TableCell> */}
                  <TableCell align="center">처리취소여부</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, index) => (
                  <TableRow
                    hover
                    key={item.accountProcessSeq+''+item.accountProcessDetailSeq}
                  >
                    <TableCell align="center">{(paging.rowsPerPage * (paging.page + 1)) - (paging.rowsPerPage - (index + 1))}</TableCell>
                    <TableCell align="center">{item.accountProcessSeq}</TableCell>
                    <TableCell align="center">{item.accountProcessDetailSeq}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('accountDivisionCode',item.accountDivisionCode)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('inoutDivisionCode',item.inoutDivisionCode)}</TableCell>                   
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.accountProcessDate)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateTimeFormat(item.accountProcessTime)}</TableCell>
                    <TableCell align="right">{item.inoutAmount && item.inoutAmount.toLocaleString()}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('accountingProcessDivisionCodeName',item.accountingProcessDivisionCode)}</TableCell>                   
                    <TableCell align="center">{item.merchantName}</TableCell>
                     <TableCell align="center">{item.accountNo} {chaiStringUtils.codeName('accountNoCodeName',item.accountNo)=== undefined ? '' : '('+chaiStringUtils.codeName('accountNoCodeName',item.accountNo)+')'}</TableCell>                    
                    {/* <TableCell align="center">{item.summaryType}</TableCell> */}
                    <TableCell align="center">{item.processCancelYn}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default AccountProcTable;

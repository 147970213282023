import gql from 'graphql-tag';

export const selectGroupMerchantInfoList = gql`
  mutation selectGroupMerchantInfoList(
    $inputGroupMerchantNo: String, 
    $inputGroupMerchantName: String, 
    $inputMerchantNo: String, 
    $inputMerchantName: String,
    $inputGubun: String,
    $inputUseYn: String
    ) {
    selectGroupMerchantInfoList (
      groupMerchantNo: $inputGroupMerchantNo, 
      groupMerchantName: $inputGroupMerchantName, 
      merchantNo: $inputMerchantNo, 
      merchantName: $inputMerchantName,
      gubun: $inputGubun,
      useYn: $inputUseYn
      ) {
      receveSeq
      receveBaseDate
      groupMerchantNo
      groupMerchantName
      merchantNo
      merchantName
      registeredDate
      canceledDate
      firstOperateAdminId
      firstOperateDetailDate
      firstOperateTradeCode
      useYn
      gubun
      merchantInfo {
        receveSeq
        receveBaseDate
        registeredDate
        merchantNo
        merchantBizNo
        merchantName
        merchantEngName
        officerName
        businessCode
        businessSubCode
        telNo1
        telNo2
        telNo3
        terminateDate
        salesDivision
        edcMerchantYn
        settlementProcessCycle
        feeRate
        cardFeeRate
        branchGiroNo
        tradeBlockYn
        accountBankCode
        tradeBlockStartDate
        tradeBlockEndDate
        installmentMonth
        paymentAcno
        merchantAddressDivisionCode
        merchantRoadAddress01
        merchantRoadAddress02
        merchantRoadAddressManagementNo
        merchantPostNo
        merchantEtcAddress
        remarkDescription
        firstOperateAdminId
        firstOperateDetailDate
        firstOperateTradeCode
        lastOperateAdminId
        lastOperateDetailDate
        lastOperateTradeCode
      }
    }
  } 
`;

export const insertGroupMerchantInfo = gql`
  mutation insertGroupMerchantInfo($input: groupMerchantInputType) {
    insertGroupMerchantInfo(input: $input) {
      success
      message
    }
  }
`;

export const updateGroupMerchantInfo = gql`
  mutation updateGroupMerchantInfo($input: groupMerchantInputType) {
    updateGroupMerchantInfo(input: $input) {
      success
      message
    }
  }
`
import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import ApprovalHistoryLogRefund from './ApprovalHistoryLogRefund';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = { 
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>,
  refundCount: number,
  setRefundCount: React.Dispatch<React.SetStateAction<number>>,
  approvalCompareList: ApprovalCompareInfo[]
}

const ApprovalHistoryLogInfoTable: FC<Props> = ({ paging, setPaging, refundCount, setRefundCount, approvalCompareList}) => {
  const classes = useStyles();
  let totalCount = 0;
  const [refundReqInfo, setRefundReqInfo] = useState({
    cardNo: '',
    cardApprovalNo: '',
    approvalDate: '',
    salesKindDivisionCode: ''
  });
  const [approvalCompareRefundOpen, setApprovalCompareRefundOpen] = React.useState(false);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value)
    });
  };

  const setTotalCount = (value: number) => {
    totalCount = value;
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>NO</TableCell>
                  <TableCell align="center" rowSpan={2}>승인일시</TableCell>
                  <TableCell align="center" rowSpan={2}>승인구분</TableCell>
                  <TableCell align="center" rowSpan={2}>매출구분</TableCell>
                  <TableCell align="center" rowSpan={2}>카드번호</TableCell>
                  <TableCell align="center" rowSpan={2}>승인번호</TableCell>
                  <TableCell align="center" colSpan={3}>승인금액</TableCell>
                  <TableCell align="center" colSpan={3}>응답결과</TableCell>
                  <TableCell align="center" rowSpan={2}>상세조회 / 환불요청</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell> 
                  <TableCell align="center">차이머니</TableCell> 
                  <TableCell align="center">차이카드</TableCell>
                  <TableCell align="center">비씨</TableCell>   
                  <TableCell align="center">차이머니</TableCell>                                
                </TableRow>
              </TableHead>
              <TableBody>
                {approvalCompareList.slice(0, approvalCompareList.length).map((item, index) => (
                    <TableRow
                    hover
                    key={item.approvalDate+''+item.salesKindDivisionCode+''+item.cardNo+''+item.cardApprovalNo}
                    >
                    <TableCell align="center">{setTotalCount(item.totalCount)}{(index+1)+(paging.page* paging.rowsPerPage)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate.substring(0,8))+' '+chaiStringUtils.dateTimeFormat(item.approvalDate.substring(8,14))}</TableCell>
                    <TableCell align="center">{item.dataDivisionCode === '05' ? '승인' : '승인취소'}</TableCell>
                    <TableCell align="center">{chaiStringUtils.codeName('salesKindDivisionCode',item.salesKindDivisionCode)}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                    <TableCell align="center">{item.cardApprovalNo}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.chaiCardApprovalAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.bcCardApprovalAmount)}</TableCell>
                    <TableCell align="right">{chaiStringUtils.moneyFormat(item.chaiMoneyPaymentAmount)}</TableCell>
                    <TableCell align="center">{item.chaiCardApprovalResponseCode}</TableCell>
                    <TableCell align="center">{item.bcCardApprovalResponseCode}</TableCell>
                    <TableCell align="center">{item.chaiMoneyResultCode}</TableCell>
                    <TableCell align="center">                      
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {setApprovalCompareRefundOpen(true); setRefundReqInfo({cardNo: item.cardNo, cardApprovalNo: item.cardApprovalNo, approvalDate: item.approvalDate.substring(0,8), salesKindDivisionCode : item.salesKindDivisionCode});}}
                      >
                         상세조회 / 환불요청  
                      </Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>
      <ApprovalHistoryLogRefund refundReqInfo={refundReqInfo} refundCount={refundCount} setRefundCount={setRefundCount} approvalCompareRefundOpen={approvalCompareRefundOpen} setApprovalCompareRefundOpen={setApprovalCompareRefundOpen}/>
    </Card>
  );
};

export default ApprovalHistoryLogInfoTable;

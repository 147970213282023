import chaiStringUtils from '../../common/chaiStringUtils';
import client from './client';

export default async (input: IssueCardInput) => {
  return client
    .post(`/root/dips/card/issue`, {
      ...input,
      CurCardNoEnc: Number.isNaN(Number(input.CurCardNo)) ? input.CurCardNo : chaiStringUtils.encrypt(input.CurCardNo)
    })
    .then(res => {
      return res.data;
    })
    .catch(e => {
      return e.response.body;
    });
};

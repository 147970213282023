import React, { useEffect, useState } from 'react';
import store from 'store';
import { Route, RouteComponentProps, Redirect, useLocation } from 'react-router-dom';
import apolloClient, { authMutation } from '../../lib/apolloClient';

type Props = {
  component: any;
  layout: any;
  needAdminPermission?: boolean;
  // all others
  [x: string]: any;
};

const RouteWithLayout = (props: Props) => {
  const { pathname } = useLocation();

  const { layout: Layout, component: Component, ...rest } = props;
  const isAuthed =
    !!store.get('admin') ||
    pathname === '/login' ||
    pathname === '/login/done' ||
    !process.env.REACT_APP_ENV ||
    process.env.REACT_APP_ENV === 'development';

  const [authChecked, setAuthChecked] = useState<string>('loading');

  useEffect(() => {
    if (!props.needAdminPermission) {
      return;
    }
    apolloClient
      .query({
        query: authMutation.authPermissionCheckQuery,
      })
      .then((result: any) => {
        if (result?.data?.authPermissionCheck) {
          setAuthChecked('ok');
        } else if (!result?.loading) {
          setAuthChecked('failed');
        }
      })
      .catch(() => {
        setAuthChecked('failed');
      });
  }, [props.needAdminPermission, pathname]);

  if (!isAuthed || authChecked === 'failed') {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (props.needAdminPermission) {
    if (authChecked === 'loading') {
      return <Route {...rest} render={(props: RouteComponentProps) => <div></div>} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;

import React, { useState, useEffect } from 'react';
import { useToast } from '../../contexts/Toast';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { aprvAgrgListMutation } from '../../lib/apolloClient/mutations';

import { MenuTitleToolbar } from '../../components';
import AprvAgrgToolbar from './AprvAgrgToolbar';
import AprvAgrgTable from './AprvAgrgTable';
import AprvAgrgTotData from './AprvAgrgTotData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AprvAgrgList = () => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [data, setData] = useState([]);  
  const [totData, setTotData] = useState({
    approvalTotCount: 0,
    approvalTotAmount: 0,
    approvalReTotCount: 0,
    approvalLostReTotCount: 0
  });
  const [searchForm, setSearchForm] = useState({
    cardProductCode: '',
    startDate: '',
    endDate: ''
  });
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {      
      // setLoading(true);

      if(searchForm.startDate > searchForm.endDate){
        showToast('시작일자는 종료일자보다 클 수 없습니다.', 'error');
        return;
      }

      await apolloClient.mutate({
        variables: {
          cardProductCode: searchForm.cardProductCode,
          startDate: searchForm.startDate,
          endDate: searchForm.endDate
        },
        mutation: aprvAgrgListMutation.selectAprvAgrgList
      }).then(res => {
        const aprvAgrgs = res.data.selectAprvAgrgList;
        console.log('data : '+JSON.stringify(aprvAgrgs));
        
        setData(aprvAgrgs);
      }).catch(console.error);
      // setLoading(false);
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm]);

  useEffect(() => {
    const fetch = async () => {
      // setLoading(true);

      await apolloClient.mutate({
        variables: {
          cardProductCode: searchForm.cardProductCode,
          startDate: searchForm.startDate,
          endDate: searchForm.endDate
        },
        mutation: aprvAgrgListMutation.selectAprvAgrgTotData
      }).then(res => {
        const aprvAgrgTotData = res.data.selectAprvAgrgTotData;
        console.log('data : '+JSON.stringify(aprvAgrgTotData));
        
        setTotData(aprvAgrgTotData);
      }).catch(console.error);

      // setLoading(false)

    }
    fetch()
  }, [searchForm])

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="발급집계내역 조회" />
      <AprvAgrgToolbar onSearch={setSearchForm}/>

      <div className={classes.content}>
        <AprvAgrgTotData setTotData={totData}/>
      </div>
      <div className={classes.content}>
        <AprvAgrgTable aprvs={data}/>
      </div>
    </div>
  );

};

export default AprvAgrgList;

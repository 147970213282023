import client from '../../../lib/cardApi/client';

export default async (input: AccidentMgmtInput): Promise<any> => {
  console.log('3010', getBody(input));

  return client
    .post(`/root/dips/cardAcdt/send`, getBody(input))
    .then(res => {
      return res.data;
    })
    .catch(e => {
      throw Error(e);
    });
};

const getBody = (input: AccidentMgmtInput): any => {
  let params = {
    cardNoEnc: input.cardNo,
    RegAcciCode: input.RegAcciCode,
    LossDate: ''
  };

  if (input.RegAcciCode === '0111') {
    params = {
      ...params,
      LossDate: input.LossDate
    };
  }

  return params;
};

import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  Link,
  Button,
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils';
import OverseasApprDetailPop from './OverseasApprDetailPop';
import OverseasApprPaymentIdPop from './OverseasApprPaymentIdPop';
import OverseasApprRefundPop from './OverseasApprRefundPop';

type Paging = {
  page: number;
  rowsPerPage: number;
};

type memberInfo = {
  userKorName: string;
  userId: string;
};

type overseasApprInfo = {
  overseasCardApprovalNo: string;
  overseasApprovalUseNo: string;
  paymentId: string;
  approvalDate: string;
  approvalTime: string;
  tradeDate: string;
  cardNo: string;
  cardNoMasked: string;
  merchantEngName: string;
  overseasMerchantNo: string;
  salesKindDivisionCode: string;
  requestWithdrawalUsdAmount: number;
  realWithdrawalKrwAmount: number;
  approvalProcessStatusCode: string;
  slipPurchaseYn: string;
  tradeApprovalSeq: number;
  adminErrorCode: string;
  refundPaymentId: string;
  textApprovalRequestUsdAmount: number;
  inoutProcessSeq: string;
  accountProcessSeq: number;
  refundProcessCode: string;
  nonInoutRequestYn: string;
  cancelAfterUsdBalance: number;
  approvalCancelStatusDivisionCode: string;
  originCancelApplyUsdBalance: number;
  originCancelApplyKrwBalance: number;
  cardApprovalResponseCode: string;
  _member: memberInfo;
};

type Props = {
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
  totalCount: number;
  list: overseasApprInfo[];
  refundCount: number;
  setRefundCount: React.Dispatch<React.SetStateAction<number>>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

function getErrorMessage(code: string) {
  switch (code) {
    case '05':
      return '기승인';
    case '14':
      return '미등록카드';
    case '62':
    case '04':
      return '사고등재';
    case '78':
      return '기취소';
    case '91':
      return '내부 시스템오류';
    case '95':
      return 'DCC 제한';
    default:
      return '';
  }
}

const OverseasApprListTable: FC<Props> = ({ paging, setPaging, totalCount, list, refundCount, setRefundCount }) => {
  const classes = useStyles();

  const [isOverseasApprDetailPopOpen, setOverseasApprDetailPopOpen] = useState(false);
  const [isOverseasApprPaymentIdPopOpen, setOverseasApprPaymentIdPopOpen] = useState(false);
  const [isOverseasApprRefundPopOpen, setOverseasApprRefundPopOpen] = useState(false);
  const [tradeDate, setTradeDate] = useState('');
  const [tradeApprovalSeq, setTradeApprovalSeq] = useState(0);
  const [refundReqInfo, setRefundReqInfo] = useState({
    tradeDate: '',
    tradeApprovalSeq: 0,
    salesKindDivisionCode: '',
    cardNo: '',
    approvalDate: '',
    approvalTime: '',
    overseasCardApprovalNo: '',
    requestWithdrawalUsdAmount: 0,
    realWithdrawalKrwAmount: 0,
    overseasMerchantNo: '',
    merchantEngName: '',
    approvalProcessStatusCode: '',
    userId: '',
    amdinErrorCode: '',
    originTradeDate: '',
    originTradeApprovalSeq: 0,
    originApprovalProcessStatusCode: '',
    inoutProcessSeq: '',
    paymentId: '',
    accountProcessSeq: 0,
    cancelAfterUsdBalance: 0,
    approvalCancelStatusDivisionCode: '',
    originCancelApplyUsdBalance: 0,
    originCancelApplyKrwBalance: 0,
  });

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement> | null, inpage: number) => {
    setPaging({
      ...paging,
      page: inpage,
    });
  };
  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(e.target.value),
    });
  };

  // 승인 => 상태코드(''), 상태코드('2')&어드민오류코드값: ETIMEOUT => 환불가능
  // 승인취소 => 상태코드('') => 환불가능
  const displayProcessCode = (item: overseasApprInfo) => {
    const sockErrors = ['ESOCKETTIM', 'ETIMEDOUT', 'ECONNRESET'];

    if (item.approvalProcessStatusCode === '1') return '정상';
    else if (
      (item.salesKindDivisionCode === '05' || item.salesKindDivisionCode === '06') &&
      item.approvalProcessStatusCode === '2' &&
      (sockErrors.indexOf(item.adminErrorCode) < 0 ||
        item.refundProcessCode === '0' ||
        item.refundProcessCode === '1' ||
        item.nonInoutRequestYn === 'Y')
    )
      return '오류';
    else if (
      item.salesKindDivisionCode !== '05' &&
      item.salesKindDivisionCode !== '06' &&
      item.approvalProcessStatusCode === '2'
    )
      return '오류';
    else {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOverseasApprRefundPopOpen(true);
            setRefundReqInfo({
              ...refundReqInfo,
              tradeDate: item.tradeDate,
              tradeApprovalSeq: item.tradeApprovalSeq,
              salesKindDivisionCode: item.salesKindDivisionCode,
              cardNo: item.cardNo,
              approvalDate: item.approvalDate,
              approvalTime: item.approvalTime,
              overseasCardApprovalNo: item.overseasCardApprovalNo,
              requestWithdrawalUsdAmount: item.requestWithdrawalUsdAmount,
              realWithdrawalKrwAmount: item.realWithdrawalKrwAmount,
              overseasMerchantNo: item.overseasMerchantNo,
              merchantEngName: item.merchantEngName,
              userId: item._member.userId,
              approvalProcessStatusCode: item.approvalProcessStatusCode,
              amdinErrorCode: item.adminErrorCode,
              originTradeDate: item.tradeDate,
              originTradeApprovalSeq: item.tradeApprovalSeq,
              originApprovalProcessStatusCode: item.approvalProcessStatusCode,
              inoutProcessSeq: item.inoutProcessSeq,
              paymentId: item.paymentId,
              accountProcessSeq: item.accountProcessSeq,
              cancelAfterUsdBalance: item.cancelAfterUsdBalance,
              approvalCancelStatusDivisionCode: item.approvalCancelStatusDivisionCode,
              originCancelApplyUsdBalance: item.originCancelApplyUsdBalance,
              originCancelApplyKrwBalance: item.originCancelApplyKrwBalance,
            });
          }}
        >
          상태확인
        </Button>
      );
    }
  };

  const displayPaymentId = (item: overseasApprInfo) => {
    if (item.paymentId) {
      return item.paymentId;
    } else {
      // (승인, 정상) 인데 머니번호가 없으면....
      if (
        (item.salesKindDivisionCode === '05' || item.salesKindDivisionCode === '06') &&
        item.approvalProcessStatusCode === '1'
      ) {
        return (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setTradeDate(item.tradeDate);
              setTradeApprovalSeq(item.tradeApprovalSeq);
              setOverseasApprPaymentIdPopOpen(true);
            }}
          >
            paymentId 값 셋팅
          </Button>
        );
      } else return item.paymentId;
    }
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">카드승인번호</TableCell>
                  <TableCell align="center">승인이용번호</TableCell>
                  <TableCell align="center">머니승인번호</TableCell>
                  <TableCell align="center">승인일시</TableCell>
                  <TableCell align="center">거래일자</TableCell>
                  <TableCell align="center">고객번호</TableCell>
                  <TableCell align="center">성명</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">영문가맹점명</TableCell>
                  <TableCell align="center">매출종류</TableCell>
                  <TableCell align="center">요청인출미화금액</TableCell>
                  <TableCell align="center">실인출원화금액</TableCell>
                  <TableCell align="center">처리구분</TableCell>
                  <TableCell align="center">매입여부</TableCell>
                  <TableCell align="center">오류내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(0, list.length).map((item, index) => (
                  <TableRow hover key={item.tradeDate + '' + item.tradeApprovalSeq}>
                    <TableCell align="center">{paging.rowsPerPage * paging.page + (index + 1)}</TableCell>
                    <TableCell align="center">
                      <Link
                        component="button"
                        variant="body1"
                        onClick={() => {
                          setTradeDate(item.tradeDate);
                          setTradeApprovalSeq(item.tradeApprovalSeq);
                          setOverseasApprDetailPopOpen(true);
                        }}
                      >
                        {item.overseasCardApprovalNo}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        component="button"
                        variant="body1"
                        onClick={() => {
                          setTradeDate(item.tradeDate);
                          setTradeApprovalSeq(item.tradeApprovalSeq);
                          setOverseasApprDetailPopOpen(true);
                        }}
                      >
                        {item.overseasApprovalUseNo}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{displayPaymentId(item)}</TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.dateFormatDefault(item.approvalDate) +
                        ' ' +
                        chaiStringUtils.dateTimeFormat(item.approvalTime)}
                    </TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.tradeDate)}</TableCell>
                    <TableCell align="center">{item._member ? item._member.userId : ''}</TableCell>
                    <TableCell align="center">{item._member ? item._member.userKorName : ''}</TableCell>
                    <TableCell align="center">{item.cardNoMasked}</TableCell>
                    <TableCell align="center">{item.merchantEngName}</TableCell>
                    <TableCell align="center">
                      {chaiStringUtils.codeName('overseasSalesKindDivisionCode', item.salesKindDivisionCode)}
                    </TableCell>
                    <TableCell align="center">{chaiStringUtils.moneyFormat(item.requestWithdrawalUsdAmount)}</TableCell>
                    <TableCell align="center">{chaiStringUtils.moneyFormat(item.realWithdrawalKrwAmount)}</TableCell>
                    <TableCell align="center">{displayProcessCode(item)}</TableCell>
                    <TableCell align="center">{item.slipPurchaseYn === 'Y' ? '매입' : ''}</TableCell>
                    <TableCell align="center">{getErrorMessage(item.cardApprovalResponseCode)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <OverseasApprDetailPop
        isOverseasApprDetailPopOpen={isOverseasApprDetailPopOpen}
        setOverseasApprDetailPopOpen={setOverseasApprDetailPopOpen}
        tradeDate={tradeDate}
        tradeApprovalSeq={tradeApprovalSeq}
      />
      <OverseasApprPaymentIdPop
        tradeDate={tradeDate}
        tradeApprovalSeq={tradeApprovalSeq}
        refundCount={refundCount}
        setRefundCount={setRefundCount}
        isOverseasApprPaymentIdPopOpen={isOverseasApprPaymentIdPopOpen}
        setOverseasApprPaymentIdPopOpen={setOverseasApprPaymentIdPopOpen}
      />
      <OverseasApprRefundPop
        refundReqInfo={refundReqInfo}
        refundCount={refundCount}
        setRefundCount={setRefundCount}
        isOverseasApprRefundPopOpen={isOverseasApprRefundPopOpen}
        setOverseasApprRefundPopOpen={setOverseasApprRefundPopOpen}
      />
    </Card>
  );
};

export default OverseasApprListTable;

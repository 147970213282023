import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { approvalAggrMutation } from '../../lib/apolloClient/mutations';
import ApprovalAggrToolbar from './ApprovalAggrToolbar';
import ApprovalAggrTable from './ApprovalAggrTable';
import ApprovalAggrSumTable from './ApprovalAggrSumTable';
import { MenuTitleToolbar } from '../../components';
import * as utils from '../../common/utils';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ApprovalAggr = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [searchForm, setSearchForm] = useState({
    cardProductCode: '',
    startDt: '',
    endDt: ''
  });
  const [sumInfo, setSumInfo] = useState({
    approvalCount	: 0, 
    approvalAmount	: 0, 
    cancelCount	: 0, 
    cancelAmount	: 0, 
    inabledCount	: 0, 
    inabledAmount	: 0
  });
  const [loading, setLoading] = useState(false);
  const [aggrLoading, setAggrLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      // 이미 로딩 중이면 리턴
      if(loading) {
        return;
      }
      
      setLoading(true);

      await apolloClient.mutate({
        variables: {
          aggregationDivisionCode: '1',  /* 집계구분 - 1:승인집계 */
          cardProductCode: searchForm.cardProductCode,
          startDt: searchForm.startDt,
          endDt: searchForm.endDt
        },
        mutation: approvalAggrMutation.selectApprovalAggr
      }).then(res => {
        setData(res.data.selectApprovalAggr);
      }).catch(console.error);

      setLoading(false);
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm]);

  useEffect(() => {
    const fetch = async () => {
      // 이미 로딩 중이면 리턴
      if(aggrLoading) {
        return;
      }
      
      setAggrLoading(true);

      await apolloClient.mutate({
        variables: {
          aggregationDivisionCode: '1',  /* 집계구분 - 1:승인집계 */
          cardProductCode: searchForm.cardProductCode,
          startDt: searchForm.startDt,
          endDt: searchForm.endDt
        },
        mutation: approvalAggrMutation.selectApprovalAggrSum
      }).then(res => {
        setSumInfo(res.data.selectApprovalAggrSum);
      }).catch(console.error);

      setAggrLoading(false);
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm]);

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="승인집계내역" />
      <ApprovalAggrToolbar onSearch={setSearchForm}/>
      <div className={classes.content}>
        <ApprovalAggrSumTable sumInfo={sumInfo}/>
      </div>      
      <div className={classes.content}>
        {loading
          ? 
            utils.Loading()
          :
          <ApprovalAggrTable list={data} />
        }
      </div>
    </div>
  );

};

export default ApprovalAggr;

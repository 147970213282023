import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useAuth, IAuthSignIn } from '../../Auth';
import { parse } from 'qs';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const LoginResult = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const urlParams = parse(document.location.search.replace('?', ''));
    setLoading(true);

    // 테스트용 로그인 세션 생성
    const variables: IAuthSignIn =
      urlParams && urlParams.local_login
        ? {
            token: 'local',
            localLoginType: urlParams.admin_type
          }
        : {
            token: urlParams.token
          };

    auth
      .signIn(variables)
      .then(() => {
        setData('로그인에 성공하였습니다.');
      })
      .catch(e => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div>
        {error
          ? error.message
          : loading
          ? 'Loading...'
          : data
          ? JSON.stringify(data)
          : ''}
      </div>
      <br />
      {error && (
        <Link to={{ pathname: '/login' }}>
          <Button color="primary" size="medium" variant="outlined">
            재시도
          </Button>
        </Link>
      )}
      {!error && (
        <div>
          <Link to={{ pathname: '/' }}>
            <Button color="primary" size="medium" variant="outlined">
              확인
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default LoginResult;

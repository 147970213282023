import gql from 'graphql-tag';

// 휴일정보관리 목록 조회
export const selectHolidayInformation = gql`
    mutation selectHolidayInformationList(
        $baseDate: String,
        $baseDateEnd: String,
        $holidayCode: String
    ) {
        selectHolidayInformationList(
            baseDate: $baseDate,
            baseDateEnd: $baseDateEnd,
            holidayCode: $holidayCode
        ){
            baseDate
            dayOfWeekCode
            holidayCode
            dayOfWeekCodeName
            holidayCodeName
            businessDay
            nextBusinessDay
        }
    }
`;

// 휴일구분 목록 조회
export const selectHolidayCode = gql`
    mutation selectHolidayCodeList{
        selectHolidayCodeList{
            holidayCode
        }
    }
`;

// 휴일정보관리 엑셀업로드
export const holidayInformationExcelUpload = gql`
  mutation holidayInformationExcelUpload($inputList: [holidayInformationExcelUploadInputType]) {
    holidayInformationExcelUpload(inputList: $inputList) {
      success
      message
    }
  }
`;

// 휴일정보 신규 등록
export const insertHolidayInformation = gql`
  mutation insertHolidayInformation($input: holidayInformationType ) {
    insertHolidayInformation(input: $input) {
      success
      message
    }
  }
`;

// 휴일정보 수정
export const updateHolidayInformation = gql`
  mutation updateHolidayInformation($input: holidayInformationType ) {
    updateHolidayInformation(input: $input) {
      success
      message
    }
  }
`;

// 휴일정보 삭제
export const deleteHolidayInformation = gql`
  mutation deleteHolidayInformation($input: holidayInformationType ) {
    deleteHolidayInformation(input: $input) {
      success
      message
    }
  }
`;
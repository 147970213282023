import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

type SearchInfo = {
  inquiryDate           :string;  // 조회날짜
  totalCount            :number; //총 행 개수
}


type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = {
  onSearch: any,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
}

const OverseasPurchasedAggrHistoryToolbar: FC<Props> = ({onSearch, searchForm, setSearchForm, paging, setPaging}) => {  
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  const onSearchClick = async () => {
    setPaging({
      ...paging,
      page: 0
    });
    onSearch();
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
      <CardContent>
          <Grid
            container
            spacing={1}
          >            
                        
                        <Grid
            item
            md={2}
            xs={6}
          >
            <TextField
              required
              fullWidth
              type="date"
              label="집계일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="inquiryDate"
              value={searchForm.inquiryDate}
              onChange={handleChange}
              onKeyPress={onKeyEnter}
            />
          </Grid>
            
            <Grid
              item
              md={2}
              xs={6}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
            <Grid
              item
              md={1}
              xs={6}
            >
                         
            </Grid>
            <Grid
              item
              md={1}
              xs={6}
            >
            </Grid>
          </Grid>  
          <Grid
            container
            spacing={1}
          >
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default OverseasPurchasedAggrHistoryToolbar;

import {
  Card,
  CardActions,
  CardContent,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import ApprovalDetailPop from './ApprovalDetailPop';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  paging: Paging;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
  totalCount: number;
  refundCount: number;
  setRefundCount: React.Dispatch<React.SetStateAction<number>>;
  list: HanaApprovalInfo[];
};

const ApprovalTable: FC<Props> = ({ paging, setPaging, totalCount, refundCount, setRefundCount, list }) => {
  const classes = useStyles();

  const [approvalDetailPopOpen, setApprovalDetailPopOpen] = React.useState(false);
  const [detailData, setDetailData] = React.useState({});

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPaging({
      ...paging,
      page: page,
    });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaging({
      ...paging,
      rowsPerPage: Number(event.target.value),
    });
  };

  const displayProcessStatus = (status: string) => {
    switch (status) {
      case 'confirm':
        return '정상';
      case 'canceled':
        return '취소';
    }
    return '알 수 없음';
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">No.</TableCell>
                  <TableCell align="center">카드승인번호</TableCell>
                  <TableCell align="center">머니승인번호</TableCell>
                  <TableCell align="center">승인일시</TableCell>
                  <TableCell align="center">고객번호</TableCell>
                  <TableCell align="center">성명</TableCell>
                  <TableCell align="center">카드번호</TableCell>
                  <TableCell align="center">가맹점명</TableCell>
                  <TableCell align="center">승인금액</TableCell>
                  <TableCell align="center">승인KRW금액</TableCell>
                  <TableCell align="center">승인USD금액</TableCell>
                  <TableCell align="center">
                    해외브랜드미화
                    <br />
                    수수료(면제전)
                  </TableCell>
                  <TableCell align="center">처리구분</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(0, list.length).map((item, index) => (
                  <TableRow hover key={`hana-approval-row-${item.approvalNumber}`}>
                    <TableCell align="center">{index + 1 + paging.page * paging.rowsPerPage}</TableCell>
                    <TableCell align="center">
                      <Link
                        component="button"
                        variant="body1"
                        onClick={() => {
                          setDetailData(item);
                          setApprovalDetailPopOpen(true);
                        }}
                      >
                        {item.approvalNumber}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{item.paymentId}</TableCell>
                    <TableCell align="center">{chaiStringUtils.dateFormatDefault(item.approvalDate)}</TableCell>
                    <TableCell align="center">{item.cardUserId}</TableCell>
                    <TableCell align="center">{item._user?.user_kor_name ?? '-'}</TableCell>
                    <TableCell align="center">****-****-****-{item.cardLastNumber}</TableCell>
                    <TableCell align="center">{item._merchant?.name ?? '-'}</TableCell>
                    <TableCell align="right">{item.amount?.toLocaleString() ?? '-'}</TableCell>
                    <TableCell align="right">{item.amountKRW?.toLocaleString() ?? '-'}</TableCell>
                    <TableCell align="right">{item.amountUSD?.toLocaleString() ?? '-'}</TableCell>
                    <TableCell align="right">{item.brandFee ?? '-'}</TableCell>
                    <TableCell align="right">{displayProcessStatus(item.type)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={paging.page}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <ApprovalDetailPop
        detailData={detailData}
        approvalDetailPopOpen={approvalDetailPopOpen}
        setApprovalDetailPopOpen={setApprovalDetailPopOpen}
      />
    </Card>
  );
};

export default ApprovalTable;

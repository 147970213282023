import React, { useState, useEffect } from 'react';
import { Theme, makeStyles, Button } from '@material-ui/core';

import apolloClient from '../../lib/apolloClient';
import { overseasExchRateDiffProcMutation } from '../../lib/apolloClient/mutations'; 
import { MenuTitleToolbar } from '../../components';
import OverseasExchRateDiffToolBar from './OverseasExchRateDiffToolBar';
import OverseasExchRateDiffTable from './OverseasExchRateDiffTable'; 
import chaiStringUtils from '../../common/chaiStringUtils.js';
import {format, subMonths} from 'date-fns';
import * as utils from '../../common/utils';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
}));


const OverseasExchRateDiffHistory = () => {

    const classes = useStyles();

    const [searchForm, setSearchForm] = useState({
        startDt : format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
        endDt : format(new Date(), 'yyyy-MM-dd')
    });

    const [data, setData] = useState([]);
    const [isFlag, setFalg] = useState(true);
    const [isExcelDown, setExcelDown] = useState(false);

    useEffect(() => {
        console.log(' @ useEffect --- >>')
        const fetch = async () => {

            await apolloClient.mutate({
                variables : {input : {
                    startDt : searchForm.startDt.replace(/-/g, ''), 
                    endDt : searchForm.endDt.replace(/-/g, '')}
                },
                mutation : overseasExchRateDiffProcMutation.selectOvrsExchRateDiffProcHist 
            }).then(res => {
                if(isExcelDown){
                    setExcelDown(false);
                    excelDown(res.data.selectOvrsExchRateDiffProcHist);
                } else {
                    setData(res.data.selectOvrsExchRateDiffProcHist);
                }
            }).catch(console.error);
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchForm, isFlag])

    const excelClick = () => {
        setFalg(!isFlag);
        setExcelDown(true);
    }

    const excelDown = (excelData : OvrsExchRateDiffHistoryInfo[]) => {
        let rowData = [];
        for(let i=0; i<excelData.length; i++){
            rowData.push({
                '정산일자' : `${excelData[i].aggregationDate === '99999999' ? '합계' : chaiStringUtils.dateFormatDefault(excelData[i].aggregationDate)}`,
                '정산미화출금총액(달러)' : `${chaiStringUtils.moneyFormat(excelData[i].setUsdWdTotalAmount)}`,
                '정산미화입금총액(달러)' : `${chaiStringUtils.moneyFormat(excelData[i].setUsdDpTotalAmount)}`, 
                '정산미화거래총액(달러)' : `${chaiStringUtils.moneyFormat(excelData[i].setUsdTdTotalAmount)}`, 
                '정산원화출금총액(원)' : `${chaiStringUtils.moneyFormat(excelData[i].setKrwWdTotalAmount)}`, 
                '정산원화입금총액(원)' : `${chaiStringUtils.moneyFormat(excelData[i].setKrwDpTotalAmount)}`,
                '정산원화거래총액(원)' : `${chaiStringUtils.moneyFormat(excelData[i].setKrwTdTotalAmount)}`,
                '환율차이총액(원)매입시점' : `${chaiStringUtils.moneyFormat(excelData[i].setExRtDifTotalAmount)}`, 
                '환율차이총액(원)정산시점' : `${chaiStringUtils.moneyFormat(excelData[i].purExRtDifTotalAmount)}`,
                '환율정보매입시점(원)' : `${chaiStringUtils.moneyFormat(excelData[i].dealBaseExchangeRate)}`,
                '환율정보정산시점(원)' : `${chaiStringUtils.moneyFormat(excelData[i].settlementBaseExchangeRate)}`,
                '정산환율적용금액(원)' : `${chaiStringUtils.moneyFormat(excelData[i].setExRtApplyAmount)}`,
                '최종환율손익금액(원)' : `${chaiStringUtils.moneyFormat(excelData[i].setExRtDifTotalAmount + excelData[i].purExRtDifTotalAmount)}`, 
                '최종환율손익율(%)' : `${chaiStringUtils.moneyFormat(((excelData[i].setExRtDifTotalAmount + excelData[i].purExRtDifTotalAmount)/(excelData[i].setKrwTdTotalAmount)*100).toFixed(2))}`
            });
        }
        utils.exportToCSV("해외원화환차손집계내역", rowData);
    }

    return (
        <div className={classes.root}>
            <MenuTitleToolbar title="해외원화 환차손집계내역"/>
            <OverseasExchRateDiffToolBar onSearch={setSearchForm}/>
            <div className={classes.content}>
            <Button variant="contained" color="primary" style={{marginLeft: 'auto', marginBottom: 10}} onClick={(event) => {excelClick(); }} >Excel</Button>
                <OverseasExchRateDiffTable overseasExchRateDiffList={data}/>
            </div>
        </div>
    );

};

export default OverseasExchRateDiffHistory; 


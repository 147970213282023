import client from './client';

export default async (userHp: string) => {  
  return client.post(`/root/card/userIdCreate`, getBody(userHp)).then(res => {
    console.log('조회할 휴대전화번호 :'+userHp);
    console.log(res);
    const statusCode = res.status;
    if (!statusCode || statusCode !== 200) {
      throw new Error(`user_id 조회 실패-!`)
    }
    console.log('-------');
    console.log(res.data.userId);
    console.log('-------');

    const code = res.data.code;
    if(code !== '00'){
      console.log(res.data.message);
      throw new Error(res.data.message)
    }
    
    return res.data.userId;
  }).catch(e => {
    throw e 
  });
}

const getBody = (userHp: string) => {
  return {
    phoneNumber: userHp
  }
}
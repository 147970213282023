import gql from 'graphql-tag';

export const selectApprovalCompareList = gql`
  mutation selectApprovalCompareList($input: ApprovalCompareSearch) {
    selectApprovalCompareList(input: $input) {
      totalCount
      dataDivisionCode
      approvalDate
      salesKindDivisionCode
      cardNo
      cardNoMasked
      cardApprovalNo
      chaiCardApprovalAmount
      bcCardApprovalAmount
      slipPurchaseYn
      chaiCardApprovalResponseCode
      bcCardApprovalResponseCode
      chaiMoneyPaymentAmount
      chaiMoneyResultCode
    }
  }
`;

export const selectApprovalCompareAggregation = gql`
  mutation selectApprovalCompareAggregation($input: ApprovalCompareSearch) {
    selectApprovalCompareAggregation(input: $input) {
      normalApprovalAmountSum
      normalApprovalCount
      abnormalApprovalAmountSum
      abnormalApprovalCount
      normalApprovalCancelAmountSum
      normalApprovalCancelCount
      abnormalApprovalCancelAmountSum
      abnormalApprovalCancelCount
      chaimoneyNormalApprovalAmountSum
      chaimoneyNormalApprovalCount
      chaimoneyAbnormalApprovalAmountSum
      chaimoneyAbnormalApprovalCount
      chaimoneyNormalApprovalCancelAmountSum
      chaimoneyNormalApprovalCancelCount
      chaimoneyAbnormalApprovalCancelAmountSum
      chaimoneyAbnormalApprovalCancelCount
      bcNormalApprovalAmountSum
      bcNormalApprovalCount
      bcAbnormalApprovalAmountSum
      bcAbnormalApprovalCount
      bcNormalApprovalCancelAmountSum
      bcNormalApprovalCancelCount
      bcAbnormalApprovalCancelAmountSum
      bcAbnormalApprovalCancelCount
    }
  }
`;

export const selectApprovalCompareRefundInfo = gql`
  mutation selectApprovalCompareRefundInfo($input: ApprovalCompareRefundSearch) {
    selectApprovalCompareRefundInfo(input: $input) {
      tradeDate
      tradeApprovalSeq
      cardSeq
      salesKindDivisionCode
      approvalDate
      cardApprovalNo
      originApprovalCancelDivisionCode
      originApprovalCancelDate
      cardMemberNo
      cardApprovalAmount
      salesAmount
      taxAmount
      serviceFee
      merchantNo
      merchantName
      approvalTime
      approvalProcessStatusCode
      cardApprovalResponseCode
      accountProcessSeq
      cardNo
      cardNoMasked
      paymentAcno
      accountProductCode
      inoutDate
      inoutProcessCode
      inoutProcessSeq
      paymentId
      refundDate
      refundProcessCode
      refundProcessSeq
      refundPaymentId
      memberinfo {
        userKorName
        phoneNo1
        phoneNo2
        phoneNo3
      }
      cardinfo {
        cardSeq
        cardNo
        cardProductCode
      }
      cancelinfo {
        tradeDate
        tradeApprovalSeq
        cardNo
        salesKindDivisionCode
        approvalDate
        cardApprovalNo
        cardApprovalAmount
        approvalProcessStatusCode
        cardApprovalResponseCode
        accountProcessSeq
        nonInoutRequestYn
        nonInoutRegisteredDate
        nonInoutSeq
        inoutDate
        inoutProcessCode
        inoutProcessSeq
        paymentId
        refundDate
        refundProcessCode
        refundProcessSeq
        refundPaymentId
      }
      productinfo {
        cardProductCodeName
      }
      chaimoneyinfo {
        tradeDate
        idempotencyKey
        paymentId
        paymentAmount
      }
      chaimoneycnclinfo {
        tradeDate
        idempotencyKey
        paymentId
        paymentAmount
      }
    }
  }
`;

export const updateApprovalCompare = gql`
  mutation updateApprovalCompare($input: ApprovalCompareUpdInfo) {
    updateApprovalCompare(input: $input) {
      result
    }
  }
`;

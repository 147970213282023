import client from '../../../lib/cardApi/client';

export default async (cardNo: string): Promise<any> => {
  console.log('반송카드관리 카드목록 api 호출', getBody(cardNo))

  return client.post(`/root/cardReturnManage/issueReturnHistoryList`, getBody(cardNo)).then(res => {
    return res.data;
  }).catch(e => {
    throw Error(e);
  });
}

const getBody = (cardNo: string): any => {
  let params = {
    cardNo: cardNo
  }

  return params;
}
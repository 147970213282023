import gql from 'graphql-tag';

export const selectCancelEdiPayHistoryList = gql`
  mutation selectCancelEdiPayHistoryList($startReceveBaseDate: String, $endReceveBaseDate: String, $slipPurchaseYn: String, $cardNo: String) {
    selectCancelEdiPayHistoryList(startReceveBaseDate: $startReceveBaseDate, endReceveBaseDate: $endReceveBaseDate, slipPurchaseYn: $slipPurchaseYn, cardNo: $cardNo) {
      receveSeq
      receveBaseDate
      requestTradeDate
      cardSeq
      cardNo
      cardNoMasked
      cardMemberNo
      userId
      cardApprovalNo
      salesDate
      salesTotalAmount
      purchasedProcessNo
      originReceveNo
      accountNo
      depositDate
      depositAmount
      depositTime
      transferStatusCode
      prepayResultCode
      slipPurchaseYn
      dataProcessDivisionCode
      fileName
      inoutProcessCode
      inoutProcessSeq
      paymentId
      accountProcessSeq
    }
  }
`;

export const selectCancelEdiPayHistoryInfo = gql`
  mutation selectCancelEdiPayHistoryInfo($startReceveBaseDate: String, $endReceveBaseDate: String, $slipPurchaseYn: String, $cardNo: String) {
    selectCancelEdiPayHistoryInfo(startReceveBaseDate: $startReceveBaseDate, endReceveBaseDate: $endReceveBaseDate, slipPurchaseYn: $slipPurchaseYn, cardNo: $cardNo) {
      receveSeq
      receveBaseDate
      requestTradeDate
      cardSeq
      cardNo
      cardNoMasked
      cardMemberNo
      userId
      cardApprovalNo
      salesDate
      salesTotalAmount
      purchasedProcessNo
      originReceveNo
      accountNo
      depositDate
      depositAmount
      depositTime
      transferStatusCode
      prepayResultCode
      slipPurchaseYn
      dataProcessDivisionCode
      fileName
      inoutProcessCode
      inoutProcessSeq
      paymentId
      accountProcessSeq
    }
  }
`;




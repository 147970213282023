import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableRow,
  TableCell,
  Button,
  Theme,
  makeStyles,
  TableHead,
  TableBody,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import apolloClient from '../../lib/apolloClient';
import { commonCodeMutation } from '../../lib/apolloClient/mutations';
import { TypeCodeList, GroupCodeList } from './PopCodeList';
import { useToast } from '../../contexts/Toast';
import { useDialog } from '../../contexts/Dialog';

type Props = {
  className?: string; // index.tsx에서 지정한 Style을 사용하기 위한 props
  commonCodeInfo: CommonCodeInfo; // codeList.tsx에서 선택된 코드 정보를 updateCode.tsx의 textField로 받아오기 위한 props
  setCommonCodeInfo: React.Dispatch<React.SetStateAction<CommonCodeInfo>>; // updateCode.tsx에서 신규, 수정 기능 수행을 위해 index.tsx의 state를 변경하기 위한 props
  updateClick: boolean; // updateCode.tsx에서 index.tsx의 state에 접근하여 rerendering을 시키기 위한 props
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>; // updateCode.tsx에서 index.tsx의 state에 접근하여 rerendering을 시키기 위한 props
  checkFormValidation: VoidFunction; // 신규, 수정 버튼의 활성화를 확인하기 위해 index.tsx에서 받아오는 props
  isValidForm: boolean; // 필수 입력값들이 있는지 여부를 받아와 신규, 수정 버튼 활성화에 적용 시키기 위한 props
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>; // textField를 사용자가 직접 작성할 때의 변경을 확인하여 신규, 수정 버튼 활성화
  setIsEmptyForm: React.Dispatch<React.SetStateAction<boolean>>; // 입력값이 있는 textField가 있는지 여부를 받아와 입력 초기화 버튼 활성화에 적용 시키기 위한 props
  isEmptyForm: boolean; // textField를 사용자가 직접 작성할 때의 변경을 확인하여 입력 초기화 버튼 활성화
  checkFormEmpty: VoidFunction; // textField를 사용자가 직접 작성할 때의 변경을 확인하여 신규, 수정 버튼 활성화
  isNew: boolean;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
};

const CodeDatails: FC<Props> = ({
  className,
  commonCodeInfo,
  setCommonCodeInfo,
  setUpdateClick,
  updateClick,
  checkFormValidation,
  isValidForm,
  setIsValidForm,
  setIsEmptyForm,
  isEmptyForm,
  checkFormEmpty,
  isNew,
  setIsNew,
}) => {
  const classes = useStyles(); // className을 통한 style 설정을 위한 상수 선언

  const { showToast } = useToast(); // Toast 메세징 기능을 위한 함수 선언
  const { showDialog, hideDialog } = useDialog(); // Dialog 팝업창을 띄우기 위한 함수 선언

  const [typeData, setTypeData] = useState([]); // 유형코드 목록보기에서 유형코드 목록을 가져올 state
  const [groupData, setGroupData] = useState([]); // 그룹코드 목록보기에서 그룹코드 목록을 가져올 state

  // textField의 onChange로 상태가 변하면 commonCodeInfo에 넣어서 추후 신규, 수정 기능 시 정보로 받음
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCommonCodeInfo({
      ...commonCodeInfo,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      // 유형코드와 그룹코드 목록 로드를 위한 함수
      await apolloClient
        .mutate({
          // 유형코드 목록 버튼의 Dialog에 유형코드를 받기 위한 함수
          variables: {}, // 변수 없이 모든 유형코드를 받아옴
          mutation: commonCodeMutation.selectTypeCodeInfoList,
        })
        .then(res => {
          setTypeData(res.data.selectTypeCodeInfoList); // 받은 데이터를 typeData state에 저장
        })
        .catch(console.error);

      await apolloClient
        .mutate({
          // 그룹코드 목록 버튼의 Dialog에 그룹코드를 받기 위한 함수
          variables: {}, // 변수 없이 모든 그룹코드를 받아옴
          mutation: commonCodeMutation.selectGroupCodeInfoList,
        })
        .then(res => {
          setGroupData(res.data.selectGroupCodeInfoList); // 받은 데이터를 groupData state에 저장
        })
        .catch(console.error);
    };

    fetch();
    checkFormValidation();
    checkFormEmpty();
    // eslint-disable-next-line
  }, [updateClick]); // setUpdateClick 함수가 동작할 때마다 useEffect 내부 실행

  const handleAddCode = async () => {
    // 신규 버튼 클릭시 textField 값들을 db에 삽입
    let check = false; // PK인 typeCode, goupCode, codeId가 중복이 되면 card-graph 서버에서 에러코드로써 codeId의 값 'xxx'를 송출, 에러 감지시 check 변수 활성화
    console.log('#############    Add Common Code');

    await apolloClient
      .mutate({
        variables: {
          input: {
            // textField의 값들을 변수로 지정하여 삽입
            codeId: commonCodeInfo.codeId,
            codeName: commonCodeInfo.codeName,
            typeCode: commonCodeInfo.typeCode,
            groupCode: commonCodeInfo.groupCode,
            codeValue: commonCodeInfo.codeValue,
            useYn: commonCodeInfo.useYn,
            mapCode: commonCodeInfo.mapCode,
            codeDescription: commonCodeInfo.codeDescription,
          },
        },
        mutation: commonCodeMutation.insertCommonCodeInfo,
      })
      .then(res => {
        if (res.data.insertCommonCodeInfo.codeId === `xxx`) {
          // card-graph에서 insert에 에러 발생 시 codeId 값을 'xxx'로 송출할 떄 check 변수 활성화
          check = true;
        }
        if (check) {
          // 오류 발생 시의 Dialog 메세징
          hideDialog();
          showDialog('공통코드정보', '동일한 값은 저장할 수 없습니다.\n\n', () => {
            hideDialog();
          });
          return;
        } else {
          // 오류 미발생 시의 Dialog 메세징
          hideDialog();
          showDialog('공통코드정보', '저장되었습니다.\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(error => {
        console.log(error);
        hideDialog();
        showToast(error.message, 'Error');
      });

    check = false;
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsValidForm(false); // 삽입 후 신규, 수정 버튼 비활성화
    setIsNew(false);
  };

  const handleUpdateCode = async () => {
    // 수정 버튼 클릭 시 textField 값들을 db에서 찾아 수정

    console.log('###### Update Common Code');

    await apolloClient
      .mutate({
        //  textField 값들을 변수로 지정하여 수정
        variables: {
          input: {
            codeId: commonCodeInfo.codeId,
            codeName: commonCodeInfo.codeName,
            typeCode: commonCodeInfo.typeCode,
            groupCode: commonCodeInfo.groupCode,
            codeValue: commonCodeInfo.codeValue,
            useYn: commonCodeInfo.useYn,
            mapCode: commonCodeInfo.mapCode,
            codeDescription: commonCodeInfo.codeDescription,
          },
        },
        mutation: commonCodeMutation.updateCommonCodeInfo,
      })
      .then(res => {
        if (res.data.updateCommonCodeInfo.codeId === 'xxx') {
          hideDialog();
          showDialog('공통코드정보', '존재하지 않는 코드키 값은 수정할 수 없습니다.\n\n', () => {
            hideDialog();
          });
        } else {
          hideDialog();
          showDialog('공통코드정보', '수정되었습니다\n\n', () => {
            hideDialog();
          });
        }
      })
      .catch(error => {
        console.log(error);
        hideDialog();
        showToast(error.message, 'Error');
      });
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsValidForm(false); // 삽입 후 신규, 수정 버튼 비활성화
    setUpdateClick(!updateClick); // updateClick을 2번째 argument로 갖는 useEffect 실행
    setIsNew(false);
  };

  const isEmpty = (input: string) => {
    // 입력 초기화 버튼을 위한 textField 공백 확인 함수
    return input === '';
  };

  const initTextFields = () => {
    // 입력 초기화 버튼 클릭 시 textField 값들을 모두 공백으로 변경
    setCommonCodeInfo({
      ...commonCodeInfo,
      codeName: '',
      codeId: '',
      typeCode: '',
      groupCode: '',
      codeValue: '',
      mapCode: '',
      codeDescription: '',
      useYn: '',
    });

    setIsEmptyForm(true); // 입력 초기화 버튼 비활성화
    setIsValidForm(false); // 신규, 수정 버튼 비활성화
    setUpdateClick(!updateClick);
    setIsNew(false);
  };

  return (
    <div>
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.header}>
                  코드유형{' '}
                  <a href="#!" style={{ color: 'red' }}>
                    *
                  </a>
                </TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    style={{ width: '60%' }}
                    size="small"
                    label="코드유형"
                    name="typeCode"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.typeCode}
                    variant="outlined"
                    error={isEmpty(commonCodeInfo.typeCode)}
                    helperText={isEmpty(commonCodeInfo.typeCode) ? 'Required' : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TypeCodeList
                    updateClick={updateClick}
                    setUpdateClick={setUpdateClick}
                    setCommonCodeInfo={setCommonCodeInfo}
                    typeCodeList={typeData}
                    commonCodeInfo={commonCodeInfo}
                    checkFormValidation={checkFormValidation}
                  />
                </TableCell>
                <TableCell className={classes.header}>
                  코드그룹{' '}
                  <a href="#!" style={{ color: 'red' }}>
                    *
                  </a>
                </TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    style={{ width: '60%' }}
                    size="small"
                    label="코드그룹"
                    name="groupCode"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.groupCode}
                    variant="outlined"
                    error={isEmpty(commonCodeInfo.groupCode)}
                    helperText={isEmpty(commonCodeInfo.groupCode) ? 'Required' : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <GroupCodeList
                    updateClick={updateClick}
                    setUpdateClick={setUpdateClick}
                    setCommonCodeInfo={setCommonCodeInfo}
                    groupCodeList={groupData}
                    commonCodeInfo={commonCodeInfo}
                    checkFormValidation={checkFormValidation}
                  />
                </TableCell>
                <TableCell className={classes.header}>
                  코드키{' '}
                  <a href="#!" style={{ color: 'red' }}>
                    *
                  </a>
                </TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    fullWidth
                    size="small"
                    label="코드키"
                    name="codeId"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.codeId}
                    variant="outlined"
                    error={isEmpty(commonCodeInfo.codeId)}
                    helperText={isEmpty(commonCodeInfo.codeId) ? 'Required' : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.header}>
                  코드명
                  <a href="#!" style={{ color: 'red' }}>
                    *
                  </a>
                </TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    fullWidth
                    size="small"
                    label="코드명"
                    name="codeName"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.codeName}
                    variant="outlined"
                    error={isEmpty(commonCodeInfo.codeName)}
                    helperText={isEmpty(commonCodeInfo.codeName) ? 'Required' : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell className={classes.header}>코드값</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    fullWidth
                    size="small"
                    label="코드값"
                    name="codeValue"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.codeValue}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell className={classes.header}>사용여부</TableCell>
                <TableCell className={classes.value}>
                  <TextField
                    select
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    label="사용여부"
                    name="useYn"
                    value={commonCodeInfo.useYn}
                    onChange={event => handleChange(event)}
                  >
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.header}>상세 코드설명</TableCell>
                <TableCell colSpan={5}>
                  <TextField
                    size="small"
                    fullWidth
                    label="상세 코드 설명"
                    name="codeDescription"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.codeDescription}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.header}>매핑코드</TableCell>
                <TableCell colSpan={3}>
                  <TextField
                    size="small"
                    label="매핑코드"
                    style={{ width: '25%' }}
                    name="mapCode"
                    onChange={event => {
                      handleChange(event);
                      checkFormValidation();
                      checkFormEmpty();
                    }}
                    value={commonCodeInfo.mapCode}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell colSpan={2} style={{ textAlign: 'right' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 20, marginRight: 10 }}
                    onClick={() => showDialog('공통코드정보', '해당 정보로 저장하시겠습니까?', handleAddCode)}
                    children="Add"
                    disabled={!isValidForm}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    children="Edit"
                    onClick={() => showDialog('공통코드정보', '해당 정보로 수정하시겠습니까?', handleUpdateCode)}
                    disabled={!isNew}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    children="Reset"
                    onClick={event => {
                      initTextFields();
                    }}
                    disabled={isEmptyForm}
                    style={{ marginLeft: 10 }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // className을 통한 스타일 설정을 위해 선언
  context: {
    textAlign: 'right',
  },
  root: {},
  dialogContent: {
    minWidth: '400px',
  },
  buttons: {
    marginTop: '8px',
    marginRight: '8px',
    float: 'right',
  },
  header: {
    backgroundColor: '#F4F6F8',
    width: '8%',
  },
  value: {
    width: '14%',
  },
}));

export default CodeDatails;

import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  title: string,
  changeInfo: CardUserChangeInfo[],      //회원정보 변경이력 목록 

  //isCardDetailsVisible: boolean,
  //setIsCardDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  //refreshCardInfo: (cardNumber: string) => Promise<void>
}
const ChangeList: FC<Props> = ({
  title,
  changeInfo,
  
  //isCardDetailsVisible,
  //setIsCardDetailsVisible,
  //refreshCardInfo, 
}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    console.log('page::'+page)
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>단말매체구분</TableCell>
                  <TableCell>변경구분</TableCell>
                  <TableCell>변경전내용</TableCell>
                  <TableCell>변경후내용</TableCell>
                  <TableCell>변경접수일자</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                {changeInfo.slice(rowsPerPage*page, rowsPerPage*(page+1)).map((item, index) => (
                  <TableRow
                    hover
                    key={item.changeReceveSeq}
                  >
                    <TableCell align="center">{(rowsPerPage * (page + 1)) - (rowsPerPage - (index + 1))}</TableCell>
                    <TableCell>{chaiStringUtils.codeName('terminalMediaDivisionCode', item.terminalMediaDivisionCode)}</TableCell>                  
                    <TableCell>{chaiStringUtils.codeName('changeDivisionCode', item.changeDivisionCode)}</TableCell>                  
                    <TableCell>{item.preChangeDesc}</TableCell>
                    <TableCell>{item.afterChangeDesc}</TableCell>
                    <TableCell>{chaiStringUtils.dateFormat(item.changeReceveDate, 'yyyy.MM.dd')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={changeInfo.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default ChangeList;

import client from '../../../lib/cardApi/client';

export default async (input: CardDeliveryInput): Promise<any> => {
  console.log('카드 발급/배송조회 DIPS API 호출', getBody(input));

  return client
    .post(`/root/dips/card/deliverySelect`, getBody(input))
    .then(res => {
      return res.data;
    })
    .catch(e => {
      throw Error(e);
    });
};

const getBody = (input: CardDeliveryInput): any => {
  let params = {
    AuditClss: input.AuditClss, // 조회KEY 구분 (1:주민등록번호 2:카드번호 3:사업자등록번호 4:발급일자+발급번호)
    AuditKeyEnc: input.AuditKey, // 조회 KEY TEXT(주민등록번호일 경우 SEED)
    NextKey: input.NextKey // 연속조회코드 (00 에 1씩 증가)
  };

  return params;
};

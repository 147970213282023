import React, { useState, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme, makeStyles } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core';
import chaiStringUtils from '../../common/chaiStringUtils.js';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
  thStyle: {
    backgroundColor: '#eeeeee',
  }, 
  tdStyle: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeeee',
    minWidth: '300px',
  },
}));

type Props = { 
  settlementList: SettlementInfo[], 
}

const SettlementTable: FC<Props> = ({ settlementList }) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const getThStyle = (item: SettlementInfo) => {

    if (item.aggregationDate === '99999999') {
      return classes.thStyle;
    }
    else {
      return '';
    }
  }; 

  return (
    <Card>
      <CardContent className={classes.content} style={{paddingBottom: '0'}}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' rowSpan={2}>집계일자</TableCell> 
                <TableCell align='center' colSpan={3}>매출액 대비 정산금액 <span style={{color:'blue'}}>( A = B + C )</span></TableCell>
                <TableCell align='center' colSpan={3}>정산금액 상세내역 <span style={{color:'blue'}}>( B = D + E )</span> </TableCell>
                <TableCell align='center' colSpan={2}>Chai매출 상세내역 <span style={{color:'blue'}}>( C = H - I - F )</span> </TableCell>
                <TableCell align='center' colSpan={3}>Chai매출/부가세</TableCell>                            
              </TableRow>
              <TableRow>
                <TableCell align='center'>매출금액 <span style={{color:'blue'}}>(A)</span></TableCell>
                <TableCell align='center'>BC정산금액 <span style={{color:'blue'}}>(B)</span></TableCell>   
                <TableCell align='center'>Chai매출 <span style={{color:'blue'}}>(C)</span></TableCell>  
                <TableCell align='center'>일반가맹점 정산금액 <span style={{color:'blue'}}>(D)</span></TableCell> 
                <TableCell align='center'>국세/영세 선정산액 <span style={{color:'blue'}}>(E)</span></TableCell>
                <TableCell align='center'>온라인사업자 재정산액 <span style={{color:'blue'}}>(F)</span></TableCell>   
                <TableCell align='center'>가맹점수수료 <span style={{color:'blue'}}>(H)</span></TableCell>  
                <TableCell align='center'>BC대행수수료 <span style={{color:'blue'}}>(I)</span></TableCell>                   
                <TableCell align='center'>Chai매출 <span style={{color:'blue'}}>(C)</span></TableCell>     
                <TableCell align='center'>부가세 <span style={{color:'blue'}}>(G)</span></TableCell>                          
              </TableRow>                                   
            </TableHead>
            <TableBody>
              {settlementList.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map((item, index) => (
                <TableRow
                  hover
                  key={item.aggregationDate}
                  className={getThStyle(item)}
                >               
                  <TableCell align='center'>{item.aggregationDate === '99999999' ? '합계' : chaiStringUtils.dateFormatDefault(item.aggregationDate)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.total210Amount+item.total220Amount+item.totalFeeAmount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.total210Amount+item.total220Amount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.totalFeeAmount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.total210Amount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.total220Amount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.total225Amount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.totalMerchantFeeAmount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.totalProxyFeeAmount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.totalFeeAmount)}</TableCell>
                  <TableCell align='right'>{chaiStringUtils.moneyFormat(item.totalFeeTaxAmount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>            
          </Table>
          </div>  
      </PerfectScrollbar>
    </CardContent>
    <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={settlementList.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </CardActions>    
  </Card>  
  );
};

export default SettlementTable;

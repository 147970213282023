import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import chaiStringUtils from '../../common/chaiStringUtils.js';
import * as utils from '../../common/utils';
import { useDialog } from '../../contexts/Dialog';
import { useToast } from '../../contexts/Toast';
import apolloClient from '../../lib/apolloClient';
import { holidayInformationMutation } from '../../lib/apolloClient/mutations';

type SearchInfo = {
  baseDate: string; // 기준시작일자
  baseDateEnd: string; // 기준종료일자
  holidayCode: string; // 휴일구분
};

type Props = {
  holidayInformation: holidayInformation;
  setHolidayInformation: React.Dispatch<React.SetStateAction<holidayInformation>>;
  updateClick: boolean;
  setUpdateClick: React.Dispatch<React.SetStateAction<boolean>>;
  HolidayInformationList: holidayInformation[];
  searchForm: SearchInfo;
  fetch: any;
};

const HolidayInformationToolbarCud: FC<Props> = ({
  fetch,
  searchForm,
  holidayInformation,
  setHolidayInformation,
  setUpdateClick,
  updateClick,
  HolidayInformationList,
}) => {
  const [holidayCodeList, setHolidayCodeList] = useState<CommonCodeInfo[]>([]);
  const { showToast } = useToast();
  const { showDialog, hideDialog } = useDialog();

  // 해당날짜 요일 가져오기
  const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  const day = new Date(chaiStringUtils.dateFormatDefault(holidayInformation.baseDate)).getDay();
  const selectDay = chaiStringUtils.nvlDefault(week[day]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHolidayInformation({
      ...holidayInformation,
      [e.target.name]: e.target.value,
    });
  };

  // eslint-disable-next-line
  const [isValidForm, setIsValidForm] = React.useState(false);

  const checkFormValidation = () => {
    if ((isEmpty(holidayInformation.baseDate) || isEmpty(holidayInformation.baseDate)) === false) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
    setUpdateClick(updateClick);
  };

  // eslint-disable-next-line
  const [isEmptyForm, setIsEmptyForm] = useState(true);

  const checkFormEmpty = () => {
    if (
      (isEmpty(holidayInformation.baseDate) &&
        isEmpty(holidayInformation.dayOfWeekCode) &&
        isEmpty(holidayInformation.holidayCode)) === true
    ) {
      setIsEmptyForm(true);
    } else {
      setIsEmptyForm(false);
    }
  };

  const isEmpty = (input: string) => {
    return input === '';
  };

  // 휴일구분 조회
  useEffect(() => {
    const fetch = async () => {
      setHolidayCodeList(await utils.getCodeList('BASIC', 'HOLIDAY', 'Y'));
    };
    fetch();
  }, []);

  // 신규 등록시 요일 숫자로 변환
  let inPutDay = '';

  switch (selectDay) {
    case '일요일':
      inPutDay = '1';
      break;
    case '월요일':
      inPutDay = '2';
      break;
    case '화요일':
      inPutDay = '3';
      break;
    case '수요일':
      inPutDay = '4';
      break;
    case '목요일':
      inPutDay = '5';
      break;
    case '금요일':
      inPutDay = '6';
      break;
    case '토요일':
      inPutDay = '7';
      break;
    default:
      break;
  }

  // 신규 등록
  const handleInsertAction = async () => {
    console.log('test 등록 : ' + holidayInformation.baseDate);
    console.log('test 등록 휴일구분 : ' + holidayInformation.holidayCode);

    if (
      utils.isNullOrBlank(holidayInformation.baseDate) ||
      holidayInformation.baseDate.replace(/[^0-9]/g, '').length !== 8
    ) {
      showToast('날짜정보가 올바르지 않습니다.', 'error');
      hideDialog();
      return;
    }

    if (utils.isNullOrBlank(holidayInformation.holidayCode)) {
      showToast('휴일구분 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          input: {
            baseDate: holidayInformation.baseDate, // 기준요일
            dayOfWeekCode: inPutDay, // 요일구분
            holidayCode: holidayInformation.holidayCode, // 휴일구분
          },
        },
        mutation: holidayInformationMutation.insertHolidayInformation,
      })
      .then(res => {
        hideDialog();
        console.log('결과: ', res.data.insertHolidayInformation);

        if (res.data.insertHolidayInformation.success === 'Y') {
          showToast(res.data.insertHolidayInformation.message);

          // 목록 리로드
          fetch(searchForm);
        } else {
          showToast(res.data.insertHolidayInformation.message, 'error');
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
  };

  // 수정
  const handleUpdateAction = async () => {
    console.log('@@수정@@');
    console.log('test 수정 : ', holidayInformation);
    if (
      utils.isNullOrBlank(holidayInformation.baseDate) ||
      holidayInformation.baseDate.replace(/[^0-9]/g, '').length !== 8
    ) {
      showToast('날짜정보가 올바르지 않습니다.', 'error');
      hideDialog();
      return;
    }

    if (utils.isNullOrBlank(holidayInformation.holidayCode)) {
      showToast('휴일구분 입력해주세요.', 'error');
      hideDialog();
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          input: {
            baseDate: holidayInformation.baseDate, // 기준요일
            dayOfWeekCode: inPutDay, // 요일구분
            holidayCode: holidayInformation.holidayCode, // 휴일구분
          },
        },
        mutation: holidayInformationMutation.updateHolidayInformation,
      })
      .then(res => {
        hideDialog();
        console.log('결과: ', res.data.updateHolidayInformation);

        if (res.data.updateHolidayInformation.success === 'Y') {
          showToast(res.data.updateHolidayInformation.message);

          // 목록 리로드
          fetch(searchForm);
        } else {
          showToast(res.data.updateHolidayInformation.message, 'error');
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
  };

  // 삭제
  const handleDeleteAction = async () => {
    console.log('@@삭제@@');
    console.log('test 삭제 : ', holidayInformation);
    if (
      utils.isNullOrBlank(holidayInformation.baseDate) ||
      holidayInformation.baseDate.replace(/[^0-9]/g, '').length !== 8
    ) {
      showToast('날짜정보가 올바르지 않습니다.', 'error');
      hideDialog();
      return;
    }

    await apolloClient
      .mutate({
        variables: {
          input: {
            baseDate: holidayInformation.baseDate, // 기준요일
          },
        },
        mutation: holidayInformationMutation.deleteHolidayInformation,
      })
      .then(res => {
        hideDialog();
        console.log('결과: ', res.data.deleteHolidayInformation);

        if (res.data.deleteHolidayInformation.success === 'Y') {
          showToast(res.data.deleteHolidayInformation.message);

          // 목록 리로드
          fetch(searchForm);
        } else {
          showToast(res.data.deleteHolidayInformation.message, 'error');
        }
      })
      .catch(e => {
        console.log(e);
        hideDialog();
        showToast(e.message, 'error');
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <TextField
              onChange={e => {
                handleChange(e);
                checkFormValidation();
                checkFormEmpty();
              }}
              required
              fullWidth
              type="date"
              label="기준일자"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="baseDate"
              value={chaiStringUtils.dateFormatDefault(holidayInformation.baseDate)}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              onChange={e => {
                handleChange(e);
                checkFormValidation();
                checkFormEmpty();
              }}
              required
              fullWidth
              label="요일"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="dayOfWeekCode"
              value={selectDay}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              onChange={e => {
                handleChange(e);
                checkFormValidation();
                checkFormEmpty();
              }}
              select
              fullWidth
              label="휴일구분"
              placeholder="휴일구분"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              name="holidayCode"
              value={holidayInformation.holidayCode}
            >
              {holidayCodeList.map(item => (
                <option key={item.codeValue} value={item.codeId}>
                  {item.codeValue}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={2} xs={6}>
            <Button // 신규
              color="primary"
              variant="contained"
              onClick={() => showDialog('휴일정보 신규등록', '해당 정보를 등록 하시겠습니까?', handleInsertAction)}
              style={{
                marginTop: '8px',
                marginLeft: 5,
                marginRight: 10,
              }}
            >
              NEW
            </Button>
            <Button // 수정
              color="primary"
              variant="contained"
              onClick={() => showDialog('휴일정보 수정', '해당 정보로 수정하시겠습니까?', handleUpdateAction)}
              style={{
                marginTop: '8px',
                marginLeft: 5,
                marginRight: 10,
              }}
            >
              EDIT
            </Button>
            <Button // 삭제
              color="primary"
              variant="contained"
              onClick={() => showDialog('휴일정보 삭제', '해당 정보를 삭제하시겠습니까?', handleDeleteAction)}
              style={{
                marginTop: '8px',
                marginLeft: 5,
                marginRight: 10,
              }}
            >
              DELETE
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default HolidayInformationToolbarCud;

import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

type SearchInfo = {
  startDt                       :string; // 시작일자
  endDt                         :string; // 종료일자
  cardNo                        :string; // 카드번호
  accountProcessSeq             :string; // 계정처리일련번호
  accountingProcessDivisionCode :string; // 회계처리구분
}

type Paging = {
  page	:number;         
  rowsPerPage	:number;       
}

type Props = {
  onSearch: any,
  searchForm: SearchInfo,
  setSearchForm: React.Dispatch<React.SetStateAction<SearchInfo>>,
  paging: Paging,
  setPaging: React.Dispatch<React.SetStateAction<Paging>>
}

const AccountProcToolbar: FC<Props> = ({onSearch, searchForm, setSearchForm, paging, setPaging}) => {  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchForm({
      ...searchForm,
      [e.target.name]: e.target.value
    });
  };

  // @TODO 공통코드 사용 시 
  // const [codeList, setCodeList] = useState<CommonCodeInfo[]>([]); 
  // useEffect(() => {
  //   const fetch = async () => {
  //     // 코드목록 조회 - 회계처리구분코드
  //     // const code1 = await getCodeList('BNG', '0001', 'Y');  // @todo 코드 등록되면 유형코드, 그룹코드 변경 
  //     // setCodeList(code1);

  //     // 코드목록 조회 - 계정구분코드
  //     // const code2 = await getCodeList('BNG', '0001', 'N');
  //   }
  //   fetch()
  // }, []);

  const onSearchClick = async () => {
    setPaging({
      ...paging,
      page: 0
    });
    onSearch();
  };

  const onKeyEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchClick();
    }
  };

  return (
    <Card>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="시작일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="startDt"
                value={searchForm.startDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                required
                fullWidth
                type="date"
                label="종료일자"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="endDt"
                value={searchForm.endDt}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                select
                fullWidth
                label="회계처리구분"
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="accountingProcessDivisionCode"
                value={searchForm.accountingProcessDivisionCode}
                onChange={handleChange}
              >
                <option key="" value="">전체</option>
                <option key="1" value="1">계좌</option>
                <option key="2" value="2">계정</option>
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="카드번호"
                placeholder="카드번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="cardNo"
                value={searchForm.cardNo}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <TextField
                fullWidth
                label="계정처리일련번호"
                placeholder="계정처리일련번호"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="accountProcessSeq"
                value={searchForm.accountProcessSeq}
                onChange={handleChange}
                onKeyPress={onKeyEnter}
              />
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={onSearchClick}
                style = {{
                  marginTop: '8px'
                }}
              >
                Search 
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default AccountProcToolbar;

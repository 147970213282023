import React, { useState } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

import CardList from './CardList';
import { default as merchants } from '../MerchantList/merchants';

import { getCardList, getApprovalList, getAccidentList } from '../../lib/cardApi';

import { MenuTitleToolbar } from '../../components';
import CardMemberToolbar from './CardMemberToolbar';
import CardMemberTable from './CardMemberTable';
import CardInfoTable from './CardInfoTable';
import ChangeList from './CustomerChangeList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type MatchParams = { inputSearchGubun: string; inputSearchVal: string };
const Customer: React.SFC<RouteComponentProps<MatchParams>> = ({ match }) => {
  //const { searchName, searchValue } = match.params;
  // eslint-disable-next-line
  const [inputSearchGubun, setInputSearchGubun] = useState(''); // set [조회구분]
  // eslint-disable-next-line
  const [inputSearchVal, setInputSearchVal] = useState(''); // set [조회값]

  //회원정보
  const [cardUserInfo, setCardUserInfo] = useState({
    csno: '',
    cardMemberNo: '',
    custKorName: '',
    custEngName: '',
    birthDt: '',
    sexDvsnCd: '',
    foreignerDvsnCd: '',
    homeNo1: '',
    homeNo2: '',
    homeNo3: '',
    phone: '',
    phoneNo1: '',
    phoneNo2: '',
    phoneNo3: '',
    //emalAddr: '',
    addr: '',
    homeNewAddress: '',
    homeNewDetailAddress: '',
    zipCode: '',
    cardProductCode: '',
    curCardNo: '',
  });

  //회원정보 변경이력 목록
  const [changeInfo, setChangeInfo] = useState<CardUserChangeInfo[]>([]);

  //보유카드목록
  const [cards, setCards] = useState<CardInfo[]>([]);

  const [isAccountDetailsVisible, setIsAccountDetailsVisible] = useState(false); //회원정보 노출여부
  const [isCardDetailsVisible, setIsCardDetailsVisible] = useState(false); //카드상세정보 노출여부

  //카드상세정보
  const [cardInfo, setCardInfo] = useState({
    cardNo: '',
    prdtName: '',
    valdTermYm: '',
    isucDt: '',
    cardStat: '',
    lastCardYn: '',
    acdtStatDvsnCd: '',
    /**getCardList.d.ts 인터페이스에 선언한대로 초기화해주어야함-! */
    userId: '',
    cardOwnrName: '',
    terminateDate: '',
  });

  // eslint-disable-next-line
  const [approvals, setApprovals] = useState<GetApprovalListReturn[]>([]);
  // eslint-disable-next-line
  const [accidents, setAccidents] = useState<GetAccidentListReturn[]>([]);

  const refreshCardInfo = async (cardNumber: string) => {
    const cardInfoResult = await getCardList({ type: 'detail', cardNo: cardNumber });
    setCardInfo({
      ...cardInfo,
      ...cardInfoResult,
    });
  };

  const refreshApprovals = async (cardNumber: string) => {
    const approvalListResult = await getApprovalList({
      cardNo: cardNumber,
      fromApvlDt: '19000101', //승인내역 시작일자
      toApvlDt: '99991231', //승인내역 종료일자
    });
    setApprovals(approvalListResult);
  };

  const refreshAccidents = async (cardNumber: string) => {
    const accidentListResult = await getAccidentList({
      cardNo: cardNumber,
    });
    setAccidents(accidentListResult);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuTitleToolbar title="회원정보 관리" />

      <CardMemberToolbar
        /**검색조건 */
        //sNm={searchName}
        //sVl={searchValue}
        //onSearch={setSearchForm}

        setInputSearchGubun={setInputSearchGubun}
        setInputSearchVal={setInputSearchVal}
        cardUserInfo={cardUserInfo}
        setCardUserInfo={setCardUserInfo}
        changeInfo={changeInfo}
        setChangeInfo={setChangeInfo}
        cards={cards}
        setCards={setCards}
        isAccountDetailsVisible={isAccountDetailsVisible}
        setIsAccountDetailsVisible={setIsAccountDetailsVisible}
        isCardDetailsVisible={isCardDetailsVisible}
        setIsCardDetailsVisible={setIsCardDetailsVisible}
      />

      {isAccountDetailsVisible && (
        <div className={classes.content}>
          <Grid container spacing={4}>
            {/**회원정보START_기존만들어져있는거 사용 */}
            <Grid item md={12} xs={12}>
              <CardMemberTable cardUserInfo={cardUserInfo} setCardUserInfo={setCardUserInfo} />
            </Grid>
            {/**회원정보END */}

            {/**보유카드목록START_새로만듬 */}
            <Grid item md={12} xs={12}>
              <CardList
                cards={cards}
                setCards={setCards}
                title="보유 카드"
                isCardDetailsVisible={isCardDetailsVisible}
                setIsCardDetailsVisible={setIsCardDetailsVisible}
                refreshCardInfo={refreshCardInfo}
              />
            </Grid>
            {/**보유카드목록END */}

            {/**회원정보 변경이력목록START_새로만듬 */}
            <Grid item md={12} xs={12}>
              <ChangeList changeInfo={changeInfo} title="회원정보 변경이력" /*refreshCardInfo={refreshCardInfo}*/ />
            </Grid>
            {/**회원정보 변경이력목록END */}
          </Grid>
        </div>
      )}

      {isCardDetailsVisible && (
        <div className={classes.content}>
          {/**카드상세정보START_기존만들어져있는거 사용 */}
          <Grid item md={12} xs={12}>
            <CardInfoTable
              cardInfo={cardInfo}
              setCardInfo={setCardInfo}
              merchants={merchants}
              cardUserInfo={cardUserInfo}
              refreshCardInfo={refreshCardInfo}
              refreshApprovals={refreshApprovals}
              refreshAccidents={refreshAccidents}
            />
          </Grid>
          {/**카드상세정보END_refreshApprovals, refreshAccidents 있는 이유는 분실신고 및 승인 팝업띄워서 처리 후 refresh처리가 필요하기 때문-!
           * 실제 리스트(사고내역 및 승인내역)는 index.tsx 파일에서 <CardInfo ~> 하위에 기재하면 됨-!
           */}
        </div>
      )}
    </div>
  );
};

export default Customer;
